import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_DIMENSION_VALUES_BASE = `${KA_API_URL}/products/dimensions/values`;
export const GET_RAW_DIMENSION_VALUES_BASE = `${KA_API_URL}/products/dimensions/raw/values`;

/**
 * Requests
 */

// TODO: This is duplicated in the DimensionsPage, plus a Hook
const fetchDimensionValues = includeAllValues =>
  axios.get(GET_DIMENSION_VALUES_BASE, {
    params: { include_all_values: includeAllValues ? 'true' : 'false' },
  });

const fetchRawDimensionValues = () => axios.get(GET_RAW_DIMENSION_VALUES_BASE);

/**
 * Hooks
 */

const DIMENSION_VALUES_QUERY_KEY = ['dimensionValues'];
const RAW_DIMENSION_VALUES_QUERY_KEY = ['rawDimensionValues'];

const COMMON_QUERY_CONFIG = { placeholderData: [] };

export const useDimensionValues = (includeAllValues = false) =>
  useQuery(
    [...DIMENSION_VALUES_QUERY_KEY, includeAllValues],
    async () => {
      const response = await fetchDimensionValues(includeAllValues);
      const dimensionValues = response.data ?? [];

      return dimensionValues;
    },
    { ...COMMON_QUERY_CONFIG },
  );

export const useRawDimensionValues = () =>
  useQuery(RAW_DIMENSION_VALUES_QUERY_KEY, async () => {
    const response = await fetchRawDimensionValues();
    const rawDimensionValues = response.data ?? [];

    return rawDimensionValues;
  });
