/* eslint-disable import/prefer-default-export */
import React from 'react';
import DimValueSetting from './DimValueSetting';
import { gaEmitEditDimensionValueCheckmarkClick } from '../../../google-analytics/dataSettings';

const replaceValue = (updatedDimensionValue, updatedDimensionValues) => {
  const indexOfOldValue = updatedDimensionValues.findIndex(dimValue => dimValue.id === updatedDimensionValue.id);
  updatedDimensionValues.splice(indexOfOldValue, 1, updatedDimensionValue);
};

const createDimValuesSettings = dimensionValues => {
  return dimensionValues
    .map(dimValue => new DimValueSetting(dimValue))
    .sort((a, b) => (a.getValue().toLocaleLowerCase() > b.getValue().toLocaleLowerCase() ? 1 : -1));
};

/**
 * Provides an API for managing the state of dimension value "row" objects present in the Table.
 */
export const useDimValueRowApi = (dimensionValues, selectedDimension) => {
  const [tableDimValues, setTableDimValues] = React.useState(() => createDimValuesSettings(dimensionValues));

  React.useEffect(() => {
    const rows = createDimValuesSettings(dimensionValues);
    setTableDimValues(rows);
  }, [dimensionValues, selectedDimension]);

  const createdValues = React.useMemo(() => tableDimValues.filter(dimValue => dimValue.isNew()), [tableDimValues]);
  const editedValues = React.useMemo(
    () => tableDimValues.filter(dimValue => !dimValue.isNew() && dimValue.isValueEdited()),
    [tableDimValues],
  );
  const deletedValues = React.useMemo(() => tableDimValues.filter(dimValue => dimValue.isDeleted()), [tableDimValues]);

  const stagedChangesValues = { createdValues, editedValues, deletedValues };

  const setIsEditing = (dimensionValue, isEditing) => {
    const updatedDimensionValues = [...tableDimValues];
    const updatedDimensionValue = DimValueSetting.from(dimensionValue);

    if (isEditing) {
      const initialValue = dimensionValue.getEditedValue() ?? dimensionValue.value;

      updatedDimensionValue.setIsEditing(true);
      updatedDimensionValue.setEditedValue(initialValue);
    } else {
      updatedDimensionValue.setIsEditing(false);
    }

    replaceValue(updatedDimensionValue, updatedDimensionValues);
    setTableDimValues(updatedDimensionValues);
  };

  const updateValue = (dimensionValue, updatedText) => {
    gaEmitEditDimensionValueCheckmarkClick();
    const updatedDimensionValues = [...tableDimValues];
    const updatedDimensionValue = DimValueSetting.from(dimensionValue);

    updatedDimensionValue.setEditedValue(updatedText);

    replaceValue(updatedDimensionValue, updatedDimensionValues);
    setTableDimValues(updatedDimensionValues);
  };

  const setValueAsDeleted = dimensionValue => {
    let updatedDimensionValues = [...tableDimValues];
    const updatedDimensionValue = DimValueSetting.from(dimensionValue);

    if (dimensionValue.isNew()) {
      // Don't mark as "staged" for deletion; just remove it from the table altogether
      updatedDimensionValues = updatedDimensionValues.filter(dimValue => dimValue.id !== dimensionValue.id);
    } else {
      updatedDimensionValue.setDeleted(true);
      replaceValue(updatedDimensionValue, updatedDimensionValues);
    }

    setTableDimValues(updatedDimensionValues);
  };

  const resetValueToOriginal = dimensionValue => {
    const updatedDimensionValues = [...tableDimValues];
    const updatedDimensionValue = DimValueSetting.from(dimensionValue);

    updatedDimensionValue.setIsEditing(false);
    updatedDimensionValue.setDeleted(false);
    updatedDimensionValue.clearEditedValue();

    replaceValue(updatedDimensionValue, updatedDimensionValues);
    setTableDimValues(updatedDimensionValues);
  };

  const createNewDimValue = ({ value, dimensionId }) => {
    // We use a placeholder unique id here since the value hasn't been inserted into the database yet
    const uniquePlaceholderId = Date.now();

    const newDimensionValue = new DimValueSetting({ id: uniquePlaceholderId, dimensionId, value });
    newDimensionValue.setIsNew();

    const updatedDimensionValues = [...tableDimValues, newDimensionValue];
    updatedDimensionValues.sort((a, b) =>
      a.getValue().toLocaleLowerCase() > b.getValue().toLocaleLowerCase() ? 1 : -1,
    );
    setTableDimValues(updatedDimensionValues);
  };

  return {
    tableDimValues,
    createNewDimValue,
    resetValueToOriginal,
    setIsEditing,
    setValueAsDeleted,
    updateValue,
    stagedChangesValues,
  };
};
