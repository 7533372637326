import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import GenericErrorBoundaryPage from './GenericErrorBoundaryPage';
import {
  APP_PROGRESS_STAGE,
  APP_PROGRESS_STEP,
  DISPLAYABLE_PROGRESS_STAGES,
} from '../../batchProgress/batchProgressConstants';
import { useListenForBatchProgress } from '../../batchProgress/batchProgressEventStream';
import ProfitRoverSpinner from '../../spinner/ProfitRoverSpinner';
import popcorn from '../../../images/popcorn.png';
import './generic-error-boundary.scss';

const PopcornScreen = ({ batch, workflowId, resetErrorBoundary }) => {
  const progressStage = useListenForBatchProgress(workflowId, batch);

  React.useEffect(() => {
    if (progressStage.stage === APP_PROGRESS_STAGE.COMPLETE || progressStage.canShowDashboard === true) {
      resetErrorBoundary();
    }
  }, [progressStage, resetErrorBoundary]);

  return (
    <GenericErrorBoundaryPage>
      <div className="popcorn-screen-container">
        <p className="error-description">Grab some popcorn and hang tight while our engines work.</p>
        <img className="img animation" src={popcorn} alt="" />
        <p className="error-description">Your insights will be ready soon!</p>
        <div className="progress-stage-loaders">
          {DISPLAYABLE_PROGRESS_STAGES.map(({ value }, index) => {
            const isInProgress = progressStage.stage === value;
            const showCompletionPct = isInProgress && value === APP_PROGRESS_STAGE.UPDATING_RESULTS;
            const isFinished = progressStage.stage > value;

            return (
              <>
                <div className="progress-stage-loader-container" key={value}>
                  {isInProgress ? (
                    <>
                      <ProfitRoverSpinner className="loading-stage" />
                      {showCompletionPct && <span className="completion-pct">{progressStage.completionPct ?? 0}%</span>}
                    </>
                  ) : isFinished ? (
                    <div className="finished-stage">
                      <FontAwesomeIcon className="icon" icon={faCheck} />
                    </div>
                  ) : (
                    <div className="pending-stage" />
                  )}
                </div>
                {index < DISPLAYABLE_PROGRESS_STAGES.length - 1 && <div className="progress-stage-divider" />}
              </>
            );
          })}
        </div>
        <div className="progress-stage-labels">
          {DISPLAYABLE_PROGRESS_STAGES.map(({ value, label }) => (
            <span className="progress-stage-label" key={value}>
              {label}
            </span>
          ))}
        </div>
        <div className="progress-steps">
          {Object.entries(APP_PROGRESS_STEP).map(([step, label]) => {
            const isFinished =
              progressStage.stage > APP_PROGRESS_STAGE.PREPARING_DATA || progressStage.step > Number(step);
            const isInProgress =
              progressStage.stage === APP_PROGRESS_STAGE.PREPARING_DATA && progressStage.step === Number(step);

            return (
              <>
                <div className="progress-step-container" key={step}>
                  {isInProgress ? (
                    <ProfitRoverSpinner className="loading-step" />
                  ) : isFinished ? (
                    <FontAwesomeIcon className="finised-step" icon={faCheck} />
                  ) : (
                    <div className="pending-step" />
                  )}
                  <span className="progress-step-label">{label}</span>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </GenericErrorBoundaryPage>
  );
};

export default PopcornScreen;
