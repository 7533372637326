import React from 'react';
import classnames from 'classnames';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faTrash, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import DeleteUserModal from './DeleteUserModal';
import ProfitRoverTooltip from '../../generic/ProfitRoverTooltip';
import { FONT_WHITE, MID_GREEN, LIGHT_GREEN } from '../../../colors';
import { isValidEmailAddress } from '../../../utils/validators';
import './user-management.scss';

const Header = ({ headerName, id, sortObject }) => {
  return (
    <div>
      {sortObject[id]?.is_sorted ? (
        <div className="d-flex justify-content-center">
          <div className="event-div" style={{ visibility: 'hidden' }}>
            <FontAwesomeIcon className="icon" name="editClose" icon={faArrowUp} />
          </div>
          <div className="column-header column-header-align">{headerName}</div>
          <div className="event-div">
            {sortObject[id]?.direction === 'ASC' ? (
              <FontAwesomeIcon className="icon" name="editClose" icon={faArrowUp} />
            ) : (
              <FontAwesomeIcon className="icon" name="editClose" icon={faArrowDown} />
            )}
          </div>
        </div>
      ) : (
        <div className="column-header">{headerName}</div>
      )}
    </div>
  );
};

const ActionComponent = ({ rowData, userId, deleteSingle, resendInvite, updateDisabled }) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const onDeleteClick = () => {
    if (!updateDisabled) {
      setModalOpen(true);
    }
  };

  const onResendClick = () => {
    if (!updateDisabled) {
      resendInvite(rowData.value.user_id);
    }
  };

  const onDeleteAccept = () => {
    setModalOpen(false);
    deleteSingle(rowData);
  };

  return (
    <div>
      <DeleteUserModal show={modalOpen} onContinue={onDeleteAccept} onHide={() => setModalOpen(false)} />
      <div className="d-flex w-100 justify-content-center">
        {rowData.value.status === 'Pending' && (
          <ProfitRoverTooltip
            shouldDisplayTooltip
            placement="bottom"
            tooltipText="Resend Invite"
            delay={{ show: 200, hide: 100 }}
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              className={classnames('icon', { disabled: updateDisabled })}
              onClick={onResendClick}
            />
          </ProfitRoverTooltip>
        )}
        {userId.current !== `${rowData.value.user_id}` && (
          <ProfitRoverTooltip
            shouldDisplayTooltip
            placement="bottom"
            tooltipText="Delete User"
            delay={{ show: 200, hide: 100 }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              className={classnames('icon', { disabled: updateDisabled })}
              onClick={onDeleteClick}
            />
          </ProfitRoverTooltip>
        )}
      </div>
    </div>
  );
};
ActionComponent.displayName = 'Action';

const NewUserActionComponent = ({ footerOpen, createNewUser, cancelNewUser, updateDisabled, newUserComplete }) => {
  const onInviteSendClick = () => {
    if (!updateDisabled && newUserComplete) {
      createNewUser();
    }
  };

  const onCancelClick = () => {
    if (!updateDisabled) {
      cancelNewUser();
    }
  };

  return (
    <>
      {footerOpen && (
        <div>
          <ProfitRoverTooltip
            shouldDisplayTooltip
            placement="bottom"
            tooltipText={newUserComplete ? 'Send Invite' : 'Please fill all fields'}
            delay={{ show: 200, hide: 100 }}
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              color={LIGHT_GREEN}
              className={classnames('icon', { disabled: updateDisabled || !newUserComplete })}
              onClick={onInviteSendClick}
            />
          </ProfitRoverTooltip>
          <ProfitRoverTooltip
            shouldDisplayTooltip
            placement="bottom"
            tooltipText="Cancel"
            delay={{ show: 200, hide: 100 }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              className={classnames('icon', { disabled: updateDisabled })}
              onClick={onCancelClick}
            />
          </ProfitRoverTooltip>
        </div>
      )}
    </>
  );
};
NewUserActionComponent.displayName = 'Action';

const EditableFieldComponent = ({ rowData, field, updateUser, userUpdateLoading, updateDisabled, setUpdateError }) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [value, setValue] = React.useState(rowData.value[field]);
  const timeoutRef = React.useRef(null);
  const inputRef = React.useRef(null);

  const onEditClick = () => {
    setValue(rowData.value[field]);
    setIsEditing(true);
  };

  const stopEditing = () => {
    setIsEditing(false);
  };

  const onChange = event => {
    const {
      target: { value: newValue },
    } = event;
    setValue(newValue);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (newValue != null && newValue.length > 0) {
      timeoutRef.current = setTimeout(() => {
        if (field === 'email_address' && !isValidEmailAddress(newValue)) {
          setUpdateError('Email is already in use or invalid.');
          return;
        }

        updateUser({ id: rowData.value.user_id, data: { [field]: newValue } });
      }, 800);
    }
  };

  const onEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (value != null && value.length > 0) {
      if (field === 'email_address' && !isValidEmailAddress(value)) {
        setUpdateError('Email already in use or invalid.');
        return;
      }

      updateUser({ id: rowData.value.user_id, data: { [field]: value } });
    }

    stopEditing();
  };

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        stopEditing();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <>
      {isEditing ? (
        <input
          ref={inputRef}
          type="text"
          value={value}
          onChange={onChange}
          onBlur={stopEditing}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onEnter();
            }
          }}
          disabled={updateDisabled}
          className={classnames('field', { disabled: updateDisabled })}
        />
      ) : (
        <button
          type="button"
          disabled={updateDisabled}
          onClick={onEditClick}
          className={classnames('field', { disabled: updateDisabled })}
        >
          {userUpdateLoading ? value : rowData.value[field]}
        </button>
      )}
    </>
  );
};

const SelectorFieldComponent = ({
  rowData,
  field,
  updateField,
  updateFieldLoading,
  updateDisabled,
  options,
  userId,
}) => {
  const rowValue = rowData.value[field] ?? 'team_member';
  const [value, setValue] = React.useState(rowValue);

  const onChange = event => {
    let {
      target: { value: newValue },
    } = event;
    setValue(newValue);

    if (event.target.name === 'role_id') {
      newValue = newValue === 'team_member' ? null : parseInt(newValue, 10);
    }

    updateField({ id: rowData.value.user_id, data: { [field]: newValue } });
  };

  return (
    <>
      {userId.current !== `${rowData.value.user_id}` ? (
        <select
          name={field}
          disabled={updateDisabled}
          className={classnames('select', { disabled: updateDisabled })}
          onChange={onChange}
          value={updateFieldLoading ? value : rowValue}
        >
          {options.map(option => (
            <option key={option[field]} value={option[field]}>
              {option.description}
            </option>
          ))}
        </select>
      ) : (
        <div>{options.find(o => o[field] === value)?.description ?? '-'}</div>
      )}
    </>
  );
};

const UserTable = ({
  data,
  userId,
  roleOptions,
  newRoleValue,
  dataAvailable,
  sortClick,
  sortObject,
  createNewUser,
  footerOpen,
  handleNewUserChange,
  deleteUser,
  updateUser,
  userUpdateLoading,
  updateUserRole,
  updateUserRoleLoading,
  resendInvite,
  cancelNewUser,
  updateDisabled,
  setUpdateError,
  newUserComplete,
}) => {
  const headerStyleObj = {
    fontSize: '14px',
    height: '40px',
    background: MID_GREEN,
    color: FONT_WHITE,
    fontWeight: 'bold',
    boxSizing: 'border-box',
  };

  const deleteSingle = rowData => {
    deleteUser(rowData.value.user_id);
  };

  return (
    <ReactTable
      data={data}
      columns={[
        {
          Header: <Header headerName="First Name" id="first_name" sortObject={sortObject} />,
          headerStyle: headerStyleObj,
          id: 'first_name',
          style: { whiteSpace: 'unset' },
          accessor: d => d,
          Cell: rowData => (
            <EditableFieldComponent
              rowData={rowData}
              field="first_name"
              updateUser={updateUser}
              userUpdateLoading={userUpdateLoading}
              updateDisabled={updateDisabled}
              setUpdateError={setUpdateError}
            />
          ),
          sortable: true,
          Footer: (
            <>
              {footerOpen && (
                <input
                  name="first_name"
                  className={classnames('field', { disabled: updateDisabled })}
                  onChange={handleNewUserChange}
                  disabled={updateDisabled}
                />
              )}
            </>
          ),
        },
        {
          Header: <Header headerName="Last Name" id="last_name" sortObject={sortObject} />,
          headerStyle: headerStyleObj,
          style: { whiteSpace: 'unset' },
          id: 'last_name',
          accessor: d => d,
          Cell: rowData => (
            <EditableFieldComponent
              rowData={rowData}
              field="last_name"
              updateUser={updateUser}
              userUpdateLoading={userUpdateLoading}
              updateDisabled={updateDisabled}
              setUpdateError={setUpdateError}
            />
          ),
          sortable: true,
          Footer: (
            <>
              {footerOpen && (
                <input
                  name="last_name"
                  className={classnames('field', { disabled: updateDisabled })}
                  onChange={handleNewUserChange}
                  disabled={updateDisabled}
                />
              )}
            </>
          ),
        },
        {
          Header: <Header headerName="Email" id="email_address" sortObject={sortObject} />,
          headerStyle: headerStyleObj,
          id: 'email_address',
          style: { whiteSpace: 'unset' },
          accessor: d => d,
          Cell: rowData => (
            <EditableFieldComponent
              rowData={rowData}
              field="email_address"
              updateUser={updateUser}
              userUpdateLoading={userUpdateLoading}
              updateDisabled={updateDisabled}
              setUpdateError={setUpdateError}
            />
          ),
          sortable: true,
          Footer: (
            <>
              {footerOpen && (
                <input
                  name="email_address"
                  className={classnames('field', { disabled: updateDisabled })}
                  onChange={handleNewUserChange}
                  disabled={updateDisabled}
                />
              )}
            </>
          ),
        },
        {
          Header: <div className="column-header">Role</div>,
          headerStyle: headerStyleObj,
          id: 'role_id',
          style: { whiteSpace: 'unset' },
          accessor: d => d,
          width: 180,
          Cell: rowData => (
            <SelectorFieldComponent
              field="role_id"
              options={roleOptions}
              rowData={rowData}
              updateField={updateUserRole}
              updateFieldLoading={updateUserRoleLoading}
              updateDisabled={updateDisabled}
              userId={userId}
            />
          ),
          sortable: false,
          Footer: (
            <>
              {footerOpen && (
                <select
                  name="role_id"
                  className={classnames('select', { disabled: updateDisabled })}
                  onChange={handleNewUserChange}
                  disabled={updateDisabled}
                  value={newRoleValue}
                >
                  {roleOptions.map(role => (
                    <option key={role.role_id} value={role.role_id}>
                      {role.description}
                    </option>
                  ))}
                </select>
              )}
            </>
          ),
        },
        {
          Header: <Header headerName="Status" id="status" sortObject={sortObject} />,
          headerStyle: headerStyleObj,
          id: 'status',
          style: { whiteSpace: 'unset' },
          accessor: d => d,
          width: 120,
          Cell: row => <div>{row.value.status}</div>,
          sortable: true,
        },
        {
          Header: <div className="column-header">Actions</div>,
          headerStyle: headerStyleObj,
          id: 'actions',
          accessor: d => d,
          width: 100,
          sortable: false,
          Cell: rowData => (
            <ActionComponent
              rowData={rowData}
              userId={userId}
              deleteSingle={deleteSingle}
              resendInvite={resendInvite}
              updateDisabled={updateDisabled}
            />
          ),
          Footer: (
            <NewUserActionComponent
              footerOpen={footerOpen}
              createNewUser={createNewUser}
              cancelNewUser={cancelNewUser}
              updateDisabled={updateDisabled}
              newUserComplete={newUserComplete}
            />
          ),
        },
      ]}
      getTheadThProps={(state, rowInfo, column) => {
        return {
          onClick: () => {
            if (column.sortable) {
              sortClick(column.id);
            }
          },
        };
      }}
      style={{ background: 'white' }}
      noDataText={dataAvailable ? 'Loading Data' : 'No Users Found'}
      defaultPageSize={10}
      pageSizeOptions={[10]}
      minRows={1}
      showPaginationBottom={data.length > 10}
      showFooter={footerOpen}
    />
  );
};

export default UserTable;
