import React from 'react';
import { Spinner } from 'react-bootstrap';

const SPINNER_SIZE = 5;
const SPINNER_SPACING = 7;

const LoadingIndicator = ({ pull }) => {
  let justificationDirection = 'justify-content-start';
  if (pull === 'center') {
    justificationDirection = 'justify-content-center';
  } else if (pull === 'right') {
    justificationDirection = 'justify-content-end';
  }

  return (
    <span className={`d-flex align-items-center ${justificationDirection}`} style={{ minHeight: 20 }}>
      <Spinner
        as="span"
        animation="grow"
        style={{ width: SPINNER_SIZE, height: SPINNER_SIZE, marginRight: SPINNER_SPACING }}
      />
      <Spinner
        as="span"
        animation="grow"
        style={{ width: SPINNER_SIZE, height: SPINNER_SIZE, marginRight: SPINNER_SPACING }}
      />
      <Spinner
        as="span"
        animation="grow"
        style={{ width: SPINNER_SIZE, height: SPINNER_SIZE, marginRight: SPINNER_SPACING }}
      />
    </span>
  );
};

export default LoadingIndicator;
