import React from 'react';
import Select from 'react-select';
import { ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';

const filters = props => {
  const {
    locationValue,
    locationOptions,
    productValue,
    productOptions,
    priceRuleValue,
    priceRuleOptions,
    handleFilterChange,
    applyFilters,
  } = props;
  return (
    <div>
      <div className="row pb-1 filters-main">
        <div className="col-1" />
        <div className="col-3">
          <p className="filter-labels">Location / Business Unit</p>
          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={locationValue}
            options={locationOptions}
            onChange={selectedOption => handleFilterChange(selectedOption, 'selectedLocations')}
          />
        </div>
        <div className="col-3">
          <p className="filter-labels">Product</p>
          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={productValue}
            options={productOptions}
            onChange={selectedOption => handleFilterChange(selectedOption, 'selectedProducts')}
          />
        </div>

        <div className="col-3">
          <p className="filter-labels">Rule</p>
          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={priceRuleValue}
            options={priceRuleOptions}
            onChange={selectedOption => handleFilterChange(selectedOption, 'selectedPriceRules')}
          />
        </div>

        <div className="col-1">
          <p className="filter-labels" id="hide-filter-p">
            Hide
          </p>
          <ProfitRoverSecondaryButton onClick={applyFilters}> Apply </ProfitRoverSecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default filters;
