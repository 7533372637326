import React from 'react';
import NavigationMenu from '../dashboards/eo/revenueUplift/NavigationMenu';
import NavigationToolbar from '../dashboards/eo/revenueUplift/NavigationToolbar';
import { useDimensions } from '../../data-access/query/dimensions';
import { useWorkflows } from '../../data-access/query/workflows';
import './settings-navigation-menu.scss';

const SettingsNavigationMenu = ({ label, tabsId }) => {
  const { data: dimensions = [] } = useDimensions(true);
  const { data: workflows = [] } = useWorkflows();

  const [isOpen, setIsOpen] = React.useState(false);

  const openNavigationMenu = () => {
    setIsOpen(true);
  };

  return (
    <div className="toolbar">
      <NavigationMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dimensions={dimensions}
        workflows={workflows}
        tabsId={tabsId}
      />
      <NavigationToolbar label={label} openNavigationMenu={openNavigationMenu} />
    </div>
  );
};

export default SettingsNavigationMenu;
