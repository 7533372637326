/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faLock } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import Select, { components } from 'react-select';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
} from '@stripe/react-stripe-js';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';
import { Country, getRegionSpecificData } from '../../generic/countryConstants';
import { ProfitRoverModalFooter } from '../../generic/ProfitRoverCard';
import { ReactComponent as VisaLogo } from '../../images/payment-icons/Visa.svg';
import { ReactComponent as MastercardLogo } from '../../images/payment-icons/Mastercard.svg';
import { ReactComponent as AmexLogo } from '../../images/payment-icons/AMEX.svg';
import { ReactComponent as DiscoverLogo } from '../../images/payment-icons/Discover.svg';
import { useStripePromise } from '../../setup/useStripePromise';
import { CenteredProfitRoverSpinner } from '../../spinner/ProfitRoverSpinner';
import { countryDropdownOptions } from '../../util/countryOptions';
import { DARK_GREEN } from '../../../colors';
import { setBillingInfo } from '../../../data-access/mutation/stripe';
import { createNewSetupIntent } from '../../../data-access/mutation/subscriptions';
import {
  BILLING_INFO_QUERY_KEY_BASE,
  fetchDefaultPaymentMethod,
  useBillingInfo,
} from '../../../data-access/query/subscriptions';
import {
  gaEmitAddPaymentMethodButtonClick,
  gaEmitEditPaymentMethodButtonClick,
} from '../../../google-analytics/subscriptionSettings';

const ONE_S = 1000;
const THREE_S = 3000;
const SIX_6 = 12000;

const DELAYS = [ONE_S, ONE_S, ONE_S, THREE_S, THREE_S, THREE_S, SIX_6];

const delay = async delayLength => new Promise(resolve => setTimeout(() => resolve(), delayLength));

/**
 * After a user changes their payment method, we'll want to keep them on the modal in "submitting" state
 * while we poll our API to see if the default payment's id has changed.
 */
const usePollForUpdatedPaymentMethod = (subscriptionId, existingCardId) => {
  const [isPolling, setIsPolling] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();

  const pollForCardIdChange = async () => {
    const delays = [...DELAYS];

    let delayLength = delays.shift();

    let isNewId = false;
    while (!isNewId && delays.length > 0) {
      // eslint-disable-next-line no-await-in-loop
      await delay(delayLength);

      try {
        // eslint-disable-next-line no-await-in-loop
        const defaultPaymentMethod = await fetchDefaultPaymentMethod(subscriptionId);

        const defaultPaymentMethodId = defaultPaymentMethod?.data?.default_payment_method?.id;
        isNewId = defaultPaymentMethodId !== existingCardId;
      } catch {
        _.noop();
      } finally {
        delayLength = delays.shift();
      }

      const noMoreTriesRemaining = delays.length === 0;
      if (!isNewId && noMoreTriesRemaining) {
        throw new Error(`Unable to retrieve new payment method after ${DELAYS.length} attempts.`);
      }
    }
  };

  const beginPolling = async () => {
    setIsPolling(true);

    try {
      await pollForCardIdChange();
    } catch (error) {
      setErrorMessage('Something went wrong. Please reach out to our support team or try again later.');
      throw error;
    } finally {
      setIsPolling(false);
    }
  };

  return { isPolling, beginPolling, errorMessage };
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faCaretDown} style={{ color: '#000000' }} />
    </components.DropdownIndicator>
  );
};

const dropdownStyles = {
  container: (provided, state) => ({
    border: '1px solid #cccccc',
    borderRadius: '4px',
    boxShadow: state.isFocused ? '0px 1px 6px #f6c50cd8' : '0px 1px 4px #0000002e',
    padding: '5px',
    color: DARK_GREEN,
    fontSize: '14px',
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    minHeight: '0px',
    maxHeight: '21px',
    boxShadow: 'none',
  }),
  menu: provided => ({
    ...provided,
    top: '30px',
    left: '0px',
  }),
  dropdownIndicator: () => ({
    paddingRight: '5px',
    paddingBottom: '5px',
  }),
  indicatorSeparator: () => ({}),
  valueContainer: () => ({ boxShadow: 'none' }),
  input: () => ({ paddingRight: '5px', paddingBottom: '5px', boxShadow: 'none' }),
};

const NotificationToDisplay = props => {
  const { stripeError } = props;

  let notificationMessage;

  switch (stripeError.type) {
    case 'card_error':
      // A declined card error
      notificationMessage = stripeError.message;
      break;
    default:
      // Handle any other types of unexpected errors
      notificationMessage = 'An unexpected error occurred. Please reach out to our support team.';
      break;
  }

  return <p className="card-error-message">{notificationMessage}</p>;
};

const PaymentMethodChangeModal = ({ show, subscriptionId, onHide, onSuccess, onFailure }) => {
  const { data: billingInfo, isFetching } = useBillingInfo(subscriptionId, 'defaultPaymentMethod');
  const { default_payment_method: defaultPaymentMethod } = billingInfo ?? {};
  const hasPaymentMethod = defaultPaymentMethod != null;
  const paymentMethodButtonText = hasPaymentMethod ? 'Save Changes' : 'Add Card';

  const existingCardId = billingInfo?.default_payment_method?.id;

  const stripe = useStripe();
  const elements = useElements();

  const [billingName, setBillingName] = React.useState('');
  const [billingAddress1, setBillingAddress1] = React.useState('');
  const [billingAddress2, setBillingAddress2] = React.useState('');
  const [billingCity, setBillingCity] = React.useState('');
  const [billingRegion, setBillingRegion] = React.useState('');
  const [billingZip, setBillingZip] = React.useState('');
  const [billingCountry, setBillingCountry] = React.useState();
  const [cardNumberIsComplete, setCardNumberIsComplete] = React.useState(false);
  const [cardCvcIsComplete, setCardCvcIsComplete] = React.useState(false);
  const [cardExpiryIsComplete, setCardExpiryIsComplete] = React.useState(false);

  const formIsComplete = _.every([
    billingName,
    billingAddress1,
    billingCity,
    billingRegion,
    billingZip,
    billingCountry,
    cardNumberIsComplete,
    cardCvcIsComplete,
    cardExpiryIsComplete,
  ]);

  const countryIsCanada = billingCountry?.value === Country.CA;
  const { regionLabel, regionDropdownOptions, postcodeLabel } = getRegionSpecificData(countryIsCanada);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [stripeError, setStripeError] = React.useState();
  const { isPolling, beginPolling, errorMessage } = usePollForUpdatedPaymentMethod(subscriptionId, existingCardId);

  const hasSubmitted = isSubmitting || isPolling;
  const buttonIsEnabled = !isFetching && formIsComplete && !hasSubmitted;

  const queryClient = useQueryClient();

  const handleSubmit = async e => {
    if (hasPaymentMethod) {
      gaEmitEditPaymentMethodButtonClick();
    } else {
      gaEmitAddPaymentMethodButtonClick();
    }

    e.preventDefault();
    const userEmail = localStorage.getItem('email');

    // Only handle the payment submit if Stripe.js and the Stripe.js elements have been loaded
    if (stripe && elements) {
      setIsSubmitting(true);

      const enterErrorState = error => {
        setStripeError(error);
        setIsSubmitting(false);
      };

      await setBillingInfo(
        userEmail,
        billingCity,
        billingAddress1,
        billingAddress2,
        billingZip,
        billingRegion.value,
        billingCountry.value,
        billingName,
      );

      const cardNumberElement = elements.getElement(CardNumberElement);
      let setupIntentClientSecret;
      try {
        const setupIntent = await createNewSetupIntent();
        setupIntentClientSecret = setupIntent.data;
      } catch (error) {
        enterErrorState(error);
        return;
      }

      const newCardDetails = {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: billingName,
            address: {
              city: billingCity,
              line1: billingAddress1,
              line2: billingAddress2,
              postal_code: billingZip,
              state: billingRegion.value,
              country: billingCountry.value,
            },
          },
        },
      };

      let confirmResult;
      try {
        confirmResult = await stripe.confirmCardSetup(setupIntentClientSecret, newCardDetails);
      } catch (error) {
        enterErrorState(error);
        return;
      }

      if (confirmResult.error) {
        enterErrorState(confirmResult.error);
      } else {
        try {
          await beginPolling();

          if (_.isFunction(onSuccess)) {
            await onSuccess();
          } else {
            // "Default behavior" if no onSuccess() is defined by the parent Component
            await queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId]);

            await onHide();
          }
        } catch {
          if (_.isFunction(onFailure)) {
            await onFailure();
          }
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };

  const handleChange = e => {
    switch (e.elementType) {
      case 'cardNumber':
        setCardNumberIsComplete(e.complete);
        break;
      case 'cardCvc':
        setCardCvcIsComplete(e.complete);
        break;
      case 'cardExpiry':
        setCardExpiryIsComplete(e.complete);
        break;
      default:
        break;
    }
  };

  const stripeElementOptions = {
    placeholder: '',
    style: {
      base: {
        fontSize: '14px',
        fontFamily: 'Arial',
        fontWeight: '500',
        color: '#747474',
      },
    },
  };

  return (
    <Modal className="change-payment-method-modal" show={show} onHide={onHide} animation={false} centered>
      <Modal.Header>
        <Modal.Title>Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="user-payment-disclaimer">
          <div className="user-payment-disclaimer-text">
            <FontAwesomeIcon icon={faLock} className="user-payment-disclaimer-icon" viewBox="0 50 448 512" />
            This is a secure TLS (SSL) Encrypted Payment
          </div>
          <div className="user-payment-accepted-methods">
            <VisaLogo /> <MastercardLogo /> <AmexLogo style={{ marginRight: '2px' }} />
            <DiscoverLogo />
          </div>
        </div>

        <div>
          <p className="payment-details-section-title">Card Details</p>
          <label htmlFor="payment-details-name" className="payment-details-name-label">
            <span className="input-field-dynamic-label">Name on Card</span>
            <input
              type="text"
              id="payment-details-name"
              className="payment-details-name"
              onChange={e => setBillingName(e.target.value)}
            />
          </label>
          <div className="user-payment-row">
            <label htmlFor="payment-details-cardnumber" className="payment-details-cardnumber-label">
              <span className="input-field-dynamic-label">Card Number</span>
              <CardNumberElement
                className="payment-details-cardnumber"
                options={stripeElementOptions}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="user-payment-row">
            <label htmlFor="payment-details-expiration" className="payment-details-expiration-label">
              <span className="input-field-dynamic-label" placeholder="">
                Expiration Date
              </span>
              <CardExpiryElement
                className="payment-details-expiration"
                options={stripeElementOptions}
                onChange={handleChange}
              />
            </label>
            <label htmlFor="payment-details-cvc" className="payment-details-cvc-label">
              <span className="input-field-dynamic-label" placeholder="">
                Security Code
              </span>
              <CardCvcElement className="payment-details-cvc" options={stripeElementOptions} onChange={handleChange} />
            </label>
          </div>
          <p className="payment-details-section-title">Billing Info</p>

          <label htmlFor="billing-info-country" className="billing-info-country-label">
            <span className="input-field-dynamic-label" style={{ zIndex: 1 }}>
              Country
            </span>
            <Select
              components={{ DropdownIndicator }}
              value={billingCountry}
              className="billing-info-country"
              styles={dropdownStyles}
              options={countryDropdownOptions}
              maxMenuHeight={200}
              onChange={country => {
                if (country.value !== billingCountry?.value) {
                  setBillingCountry(country);
                  setBillingRegion(null);
                }
              }}
            />
          </label>
          <label htmlFor="billing-info-address1" className="billing-info-address1-label">
            <span className="input-field-dynamic-label">Address Line 1</span>
            <input
              type="text"
              id="billing-info-address1"
              className="billing-info-address1"
              onChange={e => setBillingAddress1(e.target.value)}
            />
          </label>
          <label htmlFor="billing-info-address2" className="billing-info-address2-label">
            <span className="input-field-dynamic-label">Address Line 2 (optional)</span>
            <input
              type="text"
              id="billing-info-address2"
              className="billing-info-address2"
              onChange={e => setBillingAddress2(e.target.value)}
            />
          </label>
          <div className="user-payment-row">
            <label htmlFor="billing-info-city" className="billing-info-city-label">
              <span className="input-field-dynamic-label" placeholder="">
                City
              </span>
              <input
                type="text"
                id="billing-info-city"
                className="billing-info-city"
                onChange={e => setBillingCity(e.target.value)}
              />
            </label>
            <label htmlFor="billing-info-region" className="billing-info-region-label">
              <span className="input-field-dynamic-label" placeholder="">
                {regionLabel}
              </span>
              <Select
                components={{ DropdownIndicator }}
                className="billing-info-region user-payment-container"
                value={billingRegion}
                styles={dropdownStyles}
                options={regionDropdownOptions}
                maxMenuHeight={200}
                onChange={setBillingRegion}
                onInputChange={inputValue => (inputValue.length <= 2 ? inputValue : inputValue.substr(0, 2))}
              />
            </label>
            <label htmlFor="billing-info-zip" className="billing-info-zip-label">
              <span className="input-field-dynamic-label" placeholder="">
                {postcodeLabel}
              </span>
              <input type="zip" className="billing-info-zip" onChange={e => setBillingZip(e.target.value)} />
            </label>
          </div>
        </div>
        <div className="payment-update-notifications">
          {stripeError && <NotificationToDisplay stripeError={stripeError} />}
          {errorMessage != null && errorMessage}
        </div>
      </Modal.Body>
      <ProfitRoverModalFooter className="d-flex justify-content-end">
        <ProfitRoverSecondaryButton onClick={onHide}>Cancel</ProfitRoverSecondaryButton>
        <ProfitRoverPrimaryButton onClick={handleSubmit} disabled={!buttonIsEnabled}>
          {isSubmitting ? (
            <CenteredProfitRoverSpinner />
          ) : (
            <>
              <FontAwesomeIcon icon={faLock} className="button-icon" viewBox="0 50 448 512" />
              {paymentMethodButtonText}
            </>
          )}
        </ProfitRoverPrimaryButton>
      </ProfitRoverModalFooter>
    </Modal>
  );
};

const UpdatePaymentMethodModalWrapper = props => {
  const stripePromise = useStripePromise();

  return (
    <Elements stripe={stripePromise}>
      <PaymentMethodChangeModal {...props} />
    </Elements>
  );
};

export default UpdatePaymentMethodModalWrapper;
