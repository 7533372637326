let GA_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
const TESTING_GOOGLE_ANALYTICS = process.env.REACT_APP_TESTING_GOOGLE_ANALYTICS;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

// In non-production deployments the tracking ID will not be set and we will be in "test" mode
// In this case we set the tracking ID to something so ReactGA.initialize() does not complain
if (!GA_TRACKING_ID) {
  GA_TRACKING_ID = 'no-tracking';
}

module.exports = {
  GA_TRACKING_ID,
  TESTING_GOOGLE_ANALYTICS,
  RECAPTCHA_SITE_KEY,
};
