/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import NotificationCenterContext from './NotificationCenterContext';
import { PAYLOAD_TYPE } from './NotificationCenterConstants';
import {
  useNavigateToDataCenter,
  useNavigateToMapping,
  useNavigateToSubscriptionManagement,
} from './notificationCenterHooks';
import { DATASET_FIELD_DISPLAY } from '../generic/datasetFieldDisplay';
import { updateNotifications } from '../../data-access/mutation/notifications';
import { useNavigateToDashboard } from '../../hooks/navigationHooks';
import { hideMenu, toggleNotificationStatus } from '../../redux/notificationCenter/actions';
import { timestampTextRelativeToNow } from '../../utils/date-handling';

const DELETED_DATASET_CONFIG_NOTIFICATION_BODY =
  'There was a dataset configuration problem. That configuration has since been deleted.';
const DELETED_DATASET_NOTIFICATION_BODY = 'There was a dataset problem. That dataset has since been deleted.';

const NotificationTemplate = ({ children, notification, onClick }) => {
  const { created_at: createdAt, read: isRead, id } = notification;
  const timestampAgo = timestampTextRelativeToNow(createdAt);

  const dispatch = useDispatch();

  const toggleNotification = notificationId => dispatch(toggleNotificationStatus(notificationId));

  const toggleNotificationReadStatus = async () => {
    toggleNotification(id);
    try {
      await updateNotifications([id]);
    } catch {
      toggleNotification(id);
    }
  };

  const hasOnClickHandler = _.isFunction(onClick);

  const onClickNotification = async () => {
    if (!isRead) {
      toggleNotificationReadStatus();
    }

    if (hasOnClickHandler) {
      await onClick();
      dispatch(hideMenu()); // Assumes that all onClicks will want to hide the Notification Center
    }
  };

  return (
    <div
      className={classnames('notification', isRead ? 'read' : 'unread', { clickable: hasOnClickHandler })}
      onClick={onClickNotification}
    >
      <div className="notification-details float-right">
        <div className="notification-timestamp">{timestampAgo}</div>
        <button
          id={id}
          type="submit"
          className="notification-read-status-container"
          onClick={async e => {
            e.stopPropagation(); // Don't let this bubble up to the onClickNotification handler
            await toggleNotificationReadStatus();
          }}
        >
          {!isRead && <div className="notification-read-indicator" />}
        </button>
      </div>
      {children}
    </div>
  );
};

const NotificationTitle = ({ children }) => <div className="notification-title">{children}</div>;

const NotificationBody = ({ children }) => <div className="notification-body">{children}</div>;

const DatasetDoesNotSpecifySourceNotification = ({ notification }) => {
  const { datasetsById } = React.useContext(NotificationCenterContext);
  const onClick = useNavigateToDataCenter();

  const { dataset_id: datasetId, fields } = notification?.payload;
  const fieldsText = fields.map(field => DATASET_FIELD_DISPLAY[field]);
  const dataset = datasetsById[datasetId] ?? {};
  const { data_configuration: datasetConfigName } = dataset;
  const textValue =
    datasetConfigName != null
      ? `Setup is incomplete for fields ${fieldsText.join(', ')} of dataset configuration 
          ${datasetConfigName}. Datasets associated with this configuration will be ignored.`
      : DELETED_DATASET_CONFIG_NOTIFICATION_BODY;

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>Dataset Configuration Incomplete</NotificationTitle>
      <NotificationBody>{textValue}</NotificationBody>
    </NotificationTemplate>
  );
};

const DatasetNoLocationNotification = ({ notification }) => {
  const { datasetsById } = React.useContext(NotificationCenterContext);
  const onClick = useNavigateToDataCenter();

  const { dataset_id: datasetId, column: locationCol } = notification?.payload;
  const dataset = datasetsById[datasetId] ?? {};
  const { file_name: datasetName, data_configuration: datasetConfigName } = dataset;
  const notificationText =
    datasetConfigName != null
      ? datasetName != null
        ? `The dataset configuration ${datasetConfigName} specifies the location column ${locationCol} 
          which is not present in the dataset ${datasetName}. This dataset will be ignored.`
        : DELETED_DATASET_NOTIFICATION_BODY
      : DELETED_DATASET_CONFIG_NOTIFICATION_BODY;

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>Dataset is Missing the Location Column</NotificationTitle>
      <NotificationBody>{notificationText}</NotificationBody>
    </NotificationTemplate>
  );
};

const DatasetLocationDoesNotExistNotification = ({ notification }) => {
  const { datasetsById } = React.useContext(NotificationCenterContext);
  const onClick = useNavigateToDataCenter();

  const { dataset_id: datasetId, location_id: locationId } = notification?.payload;
  const dataset = datasetsById[datasetId] ?? {};
  const { data_configuration: datasetConfigName } = dataset;
  const notificationText =
    datasetConfigName != null
      ? `The dataset configuration ${datasetConfigName} specifies a fixed value of ${locationId} for the location, 
          however there is no location corresponding to that value.`
      : DELETED_DATASET_CONFIG_NOTIFICATION_BODY;

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>Dataset Configuration Error</NotificationTitle>
      <NotificationBody>{notificationText}</NotificationBody>
    </NotificationTemplate>
  );
};

const DatasetNoLocationIdsExistNotification = ({ notification }) => {
  const { datasetsById } = React.useContext(NotificationCenterContext);
  const onClick = useNavigateToDataCenter();

  const { dataset_id: datasetId } = notification?.payload;
  const dataset = datasetsById[datasetId] ?? {};
  const { file_name: datasetName } = dataset;
  const notificationText =
    datasetName != null
      ? `The dataset ${datasetName} does not contain any location identifiers matching any of your locations.`
      : DELETED_DATASET_NOTIFICATION_BODY;

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>Dataset Does Not Have Matching Locations</NotificationTitle>
      <NotificationBody>{notificationText}</NotificationBody>
    </NotificationTemplate>
  );
};

const DatasetMissingLocationNotification = ({ notification }) => {
  const { datasetsById } = React.useContext(NotificationCenterContext);
  const onClick = useNavigateToDataCenter();

  const { dataset_id: datasetId } = notification?.payload;
  const dataset = datasetsById[datasetId] ?? {};
  const { data_configuration: datasetConfigName } = dataset;
  const notificationText =
    datasetConfigName != null
      ? `There are multiple locations in your setup, however the dataset configuration 
          ${datasetConfigName} does not specify a location field.`
      : DELETED_DATASET_CONFIG_NOTIFICATION_BODY;

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>Dataset Configuration Incomplete</NotificationTitle>
      <NotificationBody>{notificationText}</NotificationBody>
    </NotificationTemplate>
  );
};

const DatasetMissingColumnsNotification = ({ notification }) => {
  const { datasetsById } = React.useContext(NotificationCenterContext);
  const onClick = useNavigateToDataCenter();

  const { dataset_id: datasetId, fields } = notification?.payload;
  const dataset = datasetsById[datasetId] ?? {};
  const { data_configuration: datasetConfigName, file_name: datasetName } = dataset;
  const notificationText =
    datasetConfigName != null
      ? datasetName != null
        ? `The dataset configuration ${datasetConfigName} specifies the following columns which are not
         present in the dataset ${datasetName}: ${fields.join(', ')}. This dataset will be ignored.`
        : DELETED_DATASET_NOTIFICATION_BODY
      : DELETED_DATASET_CONFIG_NOTIFICATION_BODY;

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>Dataset is Missing Columns</NotificationTitle>
      <NotificationBody>{notificationText}</NotificationBody>
    </NotificationTemplate>
  );
};

const DatasetMissingRequiredFieldsNotification = ({ notification }) => {
  const { datasetsById } = React.useContext(NotificationCenterContext);
  const onClick = useNavigateToDataCenter();

  const { dataset_id: datasetId, fields } = notification?.payload;
  const fieldsText = fields.map(field => DATASET_FIELD_DISPLAY[field]);
  const dataset = datasetsById[datasetId] ?? {};
  const { data_configuration: datasetConfigName } = dataset;
  const notificationText =
    datasetConfigName != null
      ? `The dataset configuration ${datasetConfigName} is missing the following required fields: 
          [${fieldsText.join(', ')}]. Datasets associated with this configuration will be ignored.`
      : DELETED_DATASET_CONFIG_NOTIFICATION_BODY;

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>Dataset Configuration Incomplete</NotificationTitle>
      <NotificationBody>{notificationText}</NotificationBody>
    </NotificationTemplate>
  );
};

const DatasetValueBadFormatNotification = ({ notification }) => {
  const { datasetsById } = React.useContext(NotificationCenterContext);
  const onClick = useNavigateToDataCenter();

  const { dataset_id: datasetId, column, value } = notification?.payload;
  const dataset = datasetsById[datasetId] ?? {};
  const { file_name: datasetName } = dataset;
  const notificationText =
    datasetName != null
      ? `The dataset ${datasetName} contains at least one bad value ${value} in column ${column}.`
      : DELETED_DATASET_NOTIFICATION_BODY;

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>Dataset Value Error</NotificationTitle>
      <NotificationBody>{notificationText}</NotificationBody>
    </NotificationTemplate>
  );
};

const DatasetDimValuesNotification = ({ notification }) => {
  const { datasetsById, nonDerivedDimensions } = React.useContext(NotificationCenterContext);

  const { dataset_ids: datasetIds = [], new_values: newValues = [], count = {} } = notification?.payload;

  const { new: numNewValues } = count;

  // Note that if there are new values for more than one dimension we will simply use the first
  // non-derived one for navigation (also note that currently we are ignoring the array of new values themselves
  // but they can be used in the future to help the user understand exactly what is new!)
  const { dimension_id: dimensionId } = newValues.find(e => nonDerivedDimensions.includes(e.dimension_id)) ?? {};

  const onClick = useNavigateToMapping(dimensionId);

  let datasetText;
  if (datasetIds.length > 1) {
    datasetText = 'multiple datasets';
  } else if (datasetIds.length === 1) {
    const [datasetId] = datasetIds;
    const dataset = datasetsById[datasetId] ?? {};
    const { data_configuration: dataConfiguration, file_name: datasetFileName } = dataset;

    datasetText = datasetFileName ? `dataset ${datasetFileName}` : dataConfiguration || 'dataset (deleted)';
  } else {
    datasetText = 'unknown dataset';
  }

  const notificationText = numNewValues > 0 ? `${numNewValues} values from ${datasetText}` : 'no new values';

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>New Dataset Values</NotificationTitle>
      <NotificationBody>{notificationText}</NotificationBody>
    </NotificationTemplate>
  );
};

const WorkflowCompleteErrorNotification = ({ notification }) => {
  const onClick = useNavigateToDataCenter();

  const notificationText = `Datasets were processed, however there was at least one with errors.`;

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>Your Analysis Refresh Completed with Errors</NotificationTitle>
      <NotificationBody>{notificationText}</NotificationBody>
    </NotificationTemplate>
  );
};

const WorkflowCompleteAbortNotification = ({ notification }) => {
  const onClick = useNavigateToDataCenter();

  const notificationText = `There are no valid datasets to process.`;

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>Your Analysis Refresh has been Aborted</NotificationTitle>
      <NotificationBody>{notificationText}</NotificationBody>
    </NotificationTemplate>
  );
};

const WorkflowCompleteNotification = ({ notification }) => {
  const { workflowsById } = React.useContext(NotificationCenterContext);

  const { workflow_id: workflowId } = notification?.payload;
  const workflow = workflowsById[workflowId];
  const workflowName = workflow?.description;

  const onClick = useNavigateToDashboard(workflow);

  const titleText = workflowName != null ? `Your Analysis is Updated!` : `(Analysis Deleted)`;
  const bodyText = workflowName != null ? `Check out your new insights.` : null;

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>{titleText}</NotificationTitle>
      <NotificationBody>{bodyText}</NotificationBody>
    </NotificationTemplate>
  );
};

const SubscriptionActivatedNotification = ({ notification }) => {
  const onClick = useNavigateToSubscriptionManagement();

  return (
    <NotificationTemplate notification={notification} onClick={onClick}>
      <NotificationTitle>Your Subscription is Now Active</NotificationTitle>
      <NotificationBody>
        Congratulations! Activate more locations now to unlock more insights with ProfitRover!
      </NotificationBody>
    </NotificationTemplate>
  );
};

const ErrorNotification = ({ notification }) => {
  const { message: errorMessage } = notification?.payload;

  return (
    <NotificationTemplate notification={notification}>
      <NotificationTitle>An error has occurred with an analysis</NotificationTitle>
      <NotificationBody>{errorMessage}</NotificationBody>
    </NotificationTemplate>
  );
};

const COMPONENT_FOR_PAYLOAD_TYPE = {
  [PAYLOAD_TYPE.WORKFLOW_COMPLETE]: WorkflowCompleteNotification,
  [PAYLOAD_TYPE.DATASET_DOES_NOT_SPECIFY_SOURCE]: DatasetDoesNotSpecifySourceNotification,
  [PAYLOAD_TYPE.DATASET_NO_LOCATION]: DatasetNoLocationNotification,
  [PAYLOAD_TYPE.DATASET_LOCATION_DOES_NOT_EXIST]: DatasetLocationDoesNotExistNotification,
  [PAYLOAD_TYPE.DATASET_NO_LOCATION_IDS_EXIST]: DatasetNoLocationIdsExistNotification,
  [PAYLOAD_TYPE.DATASET_MISSING_LOCATION]: DatasetMissingLocationNotification,
  [PAYLOAD_TYPE.DATASET_MISSING_COLUMNS]: DatasetMissingColumnsNotification,
  [PAYLOAD_TYPE.DATASET_MISSING_REQUIRED_FIELDS]: DatasetMissingRequiredFieldsNotification,
  [PAYLOAD_TYPE.DATASET_VALUE_BAD_FORMAT]: DatasetValueBadFormatNotification,
  [PAYLOAD_TYPE.DATASET_DIMENSION_VALUES]: DatasetDimValuesNotification,
  [PAYLOAD_TYPE.WORKFLOW_COMPLETE_ERROR]: WorkflowCompleteErrorNotification,
  [PAYLOAD_TYPE.WORKFLOW_COMPLETE_ABORT]: WorkflowCompleteAbortNotification,
  [PAYLOAD_TYPE.SUBSCRIPTION_BILLING_ACTIVATED]: SubscriptionActivatedNotification,
  [PAYLOAD_TYPE.ERROR]: ErrorNotification,
};

export const Notification = ({ notification }) => {
  const { payload_type: payloadType } = notification;

  const NotificationToDisplay = COMPONENT_FOR_PAYLOAD_TYPE[payloadType];
  if (NotificationToDisplay == null) {
    return null;
  }

  return <NotificationToDisplay notification={notification} />;
};
