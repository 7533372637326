/* eslint-disable react/no-danger */
/* eslint-disable react/display-name */
import React, { Fragment } from 'react';
import ReactTable from 'react-table';
import { formatCurrency, formatNumber, formatPercent } from '../../../util/format';

const priceLevelRecommendationDetailsSection = props => {
  const {
    recommendationDetailsPriceLevel,
    recommendationDetails,
    recommendationDetailsFooter,
    recommendationDetailsFooterShow,
  } = props;
  return (
    <Fragment>
      <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>{recommendationDetailsPriceLevel} Recommendation Details</h6>
      <ReactTable
        data={recommendationDetails}
        columns={[
          {
            Header: <div>Metric</div>,
            id: 'product',
            headerStyle: {
              background: '#ffc500',
              height: '55px',
              paddingTop: '15px',
              fontWeight: 'bold',
              border: '1px solid white',
            },

            accessor: d => (
              <div
                style={{
                  height: '45px',
                  fontWeight: 'bold',
                  paddingTop: d.metric === 'Price' ? '10px' : '0px',
                }}
                dangerouslySetInnerHTML={{
                  __html: d.metric !== 'Price' ? `Remaining <br/>${d.metric}` : d.metric,
                }}
              />
            ),
          },
          {
            Header: (
              <div>
                Current
                <br /> Price
              </div>
            ),
            headerStyle: {
              background: '#ffc500',
              height: '55px',
              fontWeight: 'bold',
              border: '1px solid white',
            },
            id: 'cur_price',
            accessor: d => (
              <div
                style={{
                  paddingTop: '10px',
                }}
                dangerouslySetInnerHTML={{
                  __html: d.metric === 'Demand' ? formatNumber(d.current) : formatCurrency(d.current, true),
                }}
              />
            ),
          },
          {
            Header: <div>KaizenPrice</div>,
            headerStyle: {
              background: '#ffc500',
              height: '55px',
              paddingTop: '15px',
              fontWeight: 'bold',
              border: '1px solid white',
            },
            id: 'rec_price',
            accessor: d => (
              <div
                style={{
                  paddingTop: '10px',
                }}
                dangerouslySetInnerHTML={{
                  __html: d.metric === 'Demand' ? formatNumber(d.kaizen) : formatCurrency(d.kaizen, true),
                }}
              />
            ),
          },
          {
            Header: <div>Change</div>,
            headerStyle: {
              background: '#ffc500',
              height: '55px',
              paddingTop: '15px',
              fontWeight: 'bold',
              border: '1px solid white',
            },
            id: 'uplift',

            accessor: d => (
              <div
                style={{
                  paddingTop: '10px',
                  color: d.change > 0 ? '#00af4f' : d.change < 0 ? 'red' : 'black',
                }}
                dangerouslySetInnerHTML={{
                  __html: formatPercent(d.change),
                }}
              />
            ),
          },
        ]}
        getTdProps={() => {
          return {
            style: {
              borderRight: '1px solid white',
            },
          };
        }}
        getTrProps={(state, rowInfo) => {
          if (typeof rowInfo !== 'undefined') {
            return {
              style: {
                background: '#e8eef6',
              },
            };
          }
          return {
            onClick: (e, handleOriginal) => {
              if (handleOriginal) {
                handleOriginal();
              }
            },
            style: {
              background: '#e8eef6',
            },
          };
        }}
        sortable={false}
        getNoDataProps={() => {
          return { style: { marginTop: '40px' } };
        }}
        noDataText="Loading Recommendation Details"
        defaultPageSize={3}
        showPagination={false}
        className="-highlight"
      />

      <br />
      {recommendationDetailsFooterShow ? (
        <div>
          <h6 style={{ fontSize: '16px' }}>
            Tickets Sold to Date: {formatNumber(recommendationDetailsFooter.quantity_sold)}
          </h6>
          <h6 style={{ fontSize: '16px' }}>Total Capacity: {formatNumber(recommendationDetailsFooter.capacity)}</h6>
          <h6 style={{ fontSize: '16px' }}>
            Remaining Capacity: {formatNumber(recommendationDetailsFooter.remaining_capacity)}
          </h6>
        </div>
      ) : (
        <div />
      )}
      <hr style={{ border: '0.5px solid' }} />
    </Fragment>
  );
};

export default priceLevelRecommendationDetailsSection;
