import React from 'react';

const cfbFooter = props => {
  const { maxTimeStamp, maxTransaction, timeZoneAbbr } = props;
  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: 'bold',
        }}
      >
        Results last refreshed: {maxTimeStamp} {timeZoneAbbr}
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: 'bold',
        }}
      >
        Results based on sales through: {maxTransaction}
      </div>
    </div>
  );
};

export default cfbFooter;
