import React, { useRef } from 'react';
import * as d3 from 'd3';
import { FONT_BLACK } from '../../../../../colors';

const PriceChangePlot = ({ data, width, height, formatNumber, barColors }) => {
  const svgRef = useRef();

  React.useLayoutEffect(() => {
    const svg = d3.select(svgRef.current);

    const maxValue = d3.max(data.map(d => d.value));
    const minValue = d3.min(data.map(d => d.value));

    // Clear previous plot
    svg.selectAll('*').remove();

    const yScale = d3
      .scaleLinear()
      .domain([minValue, maxValue])
      .range([height, 20]);

    const xScale = d3
      .scaleBand()
      .domain(data.map(d => d.key))
      .range([0, width])
      .padding(0.7);

    const g = svg.append('g');

    const yValues = yScale.ticks(4);
    g.selectAll('.horizontal-line')
      .data(yValues)
      .enter()
      .append('line')
      .attr('class', 'horizontal-line')
      .attr('x1', 0)
      .attr('y1', d => yScale(d))
      .attr('x2', width)
      .attr('y2', d => yScale(d))
      .style('stroke', '#ccc')
      .style('stroke-width', 1);

    const bars = g
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'bar')
      .attr('transform', d => `translate(${xScale(d.key)}, ${yScale(d.value)})`);

    bars
      .append('rect')
      .attr('width', xScale.bandwidth())
      .attr('height', d => Math.abs(yScale(d.value) - yScale(0)))
      .style('fill', d => barColors[d.key]);

    bars
      .append('text')
      .attr('y', 0)
      .attr('dy', '-0.35em')
      .attr('x', xScale.bandwidth() / 2)
      .style('text-anchor', 'middle')
      .text(d => formatNumber(d.value))
      .style('fill', FONT_BLACK)
      .style('font-family', 'URWDIN')
      .style('font-weight', '400')
      .style('font-size', '14px');
  }, [data, width, height, formatNumber, barColors]);

  return (
    <div style={{ position: 'relative', overflow: 'visible' }}>
      <svg ref={svgRef} width={width} height={height} />
    </div>
  );
};

export default PriceChangePlot;
