import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { SimpleRadioButton } from '../../../forms/GenericFormComponents';
import { ProfitRoverCancelButton, ProfitRoverPrimaryButton } from '../../../forms/ProfitRoverButtons';
import { ProfitRoverModalCard, ProfitRoverModalFooter, ProfitRoverModalMain } from '../../../generic/ProfitRoverCard';
import { SubscriptionType } from '../../../generic/subscriptions/SubscriptionConstants';
import './pick-location-to-activate-modal.scss';

const SelectLocationTable = ({ locations, selectedLocation, setSelectedLocation }) => {
  const locationOptions = locations.map(location => {
    const locationId = location.location_id;
    let locationName = location.location_description ?? location.location_identifier;
    if (!locationName) {
      // This should never happen, but this fallback is used as a last resort for debugging purposes
      locationName = `Location id: ${locationId}`;
    }
    const isSelected = selectedLocation?.location_id === locationId;

    return (
      <SimpleRadioButton
        divClassName="location-option-row"
        buttonClassName="location-option-radio"
        key={locationId}
        selected={isSelected}
        onClick={() => setSelectedLocation(location)}
      >
        {locationName}
      </SimpleRadioButton>
    );
  });

  return (
    <div className="select-location-table">
      <div className="table-header">
        <span>Select</span>
        <span>Location</span>
      </div>
      <div className="table-content">{locationOptions}</div>
    </div>
  );
};

// eslint-disable-next-line max-len
const EXPLANATION = `We have automatically added all of your locations we found in your data to your account so that you can easily activate them to your subscription. If you want more insights at your locations, you add more locations to your subscription via the subscription management page.`;

const PickLocationToActivateModal = props => {
  const {
    locations,
    selectedLocation,
    setSelectedLocation,
    onSelectLocation,
    subscriptionType = SubscriptionType.TRIAL_PERIOD,
  } = props;

  const history = useHistory();
  const onHide = () => history.push('/welcome');

  const isButtonEnabled = selectedLocation != null;

  let topLocationText = 'location';
  let bottomLocationText = 'Active Location';
  let warning;

  if (subscriptionType === SubscriptionType.FIRST_FREE) {
    topLocationText = <strong>free location</strong>;
    bottomLocationText = 'Free Location';
    warning = (
      <i>
        You <strong>cannot</strong> change this after you make your selection!
      </i>
    );
  }

  if (subscriptionType === SubscriptionType.TRIAL_PERIOD) {
    topLocationText = <strong>free trial location</strong>;
    bottomLocationText = 'Trial Location';
  }

  return (
    <>
      <Modal show centered dialogClassName="pick-location-to-activate-modal-dialog">
        <ProfitRoverModalCard>
          <ProfitRoverModalMain>
            <div className="pick-location-to-activate-modal-container">
              <div className="header-instructions">
                <span>Which {topLocationText} do you want to get started with?&nbsp;</span>
                {warning}
              </div>
              <SelectLocationTable
                locations={locations}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
              />
              <div className="explanation">{EXPLANATION}</div>
            </div>
          </ProfitRoverModalMain>
          <ProfitRoverModalFooter>
            <div className="pick-location-to-activate-modal-footer">
              <ProfitRoverCancelButton onClick={onHide}>Cancel</ProfitRoverCancelButton>
              <ProfitRoverPrimaryButton onClick={onSelectLocation} disabled={!isButtonEnabled}>
                Confirm My {bottomLocationText}
              </ProfitRoverPrimaryButton>
            </div>
          </ProfitRoverModalFooter>
        </ProfitRoverModalCard>
      </Modal>
    </>
  );
};

export default PickLocationToActivateModal;
