import React from 'react';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { SubscriptionBannerButton } from './SubscriptionConstants';
import ManagePaymentMethodModal from '../../accountSettings/subscriptionManagement/ManagePaymentMethodModal';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';

const DEFAULT_BANNER_TEXT = 'Ready to unlock insights?';

const StartPlanBanner = ({
  bannerText = DEFAULT_BANNER_TEXT,
  bannerButton = SubscriptionBannerButton.NONE,
  subscriptionId,
  trialHasExpired = false,
}) => {
  const history = useHistory();
  const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] = React.useState(false);
  const onHide = () => setShowUpdatePaymentMethodModal(false);

  const goToEndTrialEarly = () =>
    history.push({
      pathname: '/subscription-management',
      state: {
        requestEndTrialEarly: true,
      },
    });

  return (
    <div className={`${trialHasExpired ? 'trial-has-expired' : 'end-trial-early-box'}`}>
      <ManagePaymentMethodModal show={showUpdatePaymentMethodModal} subscriptionId={subscriptionId} onHide={onHide} />
      <span className="unlock-text">{bannerText}</span>
      {bannerButton === SubscriptionBannerButton.START_NOW ? (
        <ProfitRoverPrimaryButton className="end-trial-early-button" onClick={goToEndTrialEarly}>
          Start My Plan Today <FontAwesomeIcon icon={faCaretRight} style={{ marginLeft: '0.5rem' }} />
        </ProfitRoverPrimaryButton>
      ) : bannerButton === SubscriptionBannerButton.ADD_PAYMENT ? (
        <ProfitRoverPrimaryButton
          className="end-trial-early-button"
          onClick={() => setShowUpdatePaymentMethodModal(true)}
        >
          Add Payment Method
        </ProfitRoverPrimaryButton>
      ) : (
        <></>
      )}
    </div>
  );
};

export default StartPlanBanner;
