import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { LOCATIONS_QUERY_KEY } from './locations';
import { KA_API_URL } from '../../config/baseUrl';
import { KaizenProduct, SubscriptionUpdateAction } from '../../components/generic/subscriptions/SubscriptionConstants';

/**
 * URIs
 */
export const GET_SUBSCRIPTIONS = `${KA_API_URL}/subscription`;

/**
 * Requests
 */

export const fetchSubscriptions = async () => axios.get(GET_SUBSCRIPTIONS);

const fetchBillingInfo = async (subscriptionId, billingInfoToRequest, paymentChannel = 'STRIPE') => {
  let billingInfoParameter;

  if (billingInfoToRequest === 'customerInfo') {
    billingInfoParameter = 'customer_info';
  } else if (billingInfoToRequest === 'defaultPaymentMethod') {
    billingInfoParameter = 'default_payment_method';
  } else if (billingInfoToRequest === 'nextBillingInfo') {
    billingInfoParameter = 'next_billing_info';
  } else if (billingInfoToRequest === 'planInfo') {
    billingInfoParameter = 'plan_info';
  }

  const params = { payment_channel: paymentChannel };
  if (billingInfoParameter != null) {
    params.billing_info = billingInfoParameter;
  }

  return axios.get(`${KA_API_URL}/subscription/${subscriptionId}/billinginfo`, {
    params,
  });
};

const fetchShopifyBillingInfo = async subscriptionId => fetchBillingInfo(subscriptionId, null, 'SHOPIFY');

export const fetchDefaultPaymentMethod = subscriptionId => fetchBillingInfo(subscriptionId, 'defaultPaymentMethod');

const fetchSubscriptionInvoices = async kaizenProduct =>
  axios.get(`${KA_API_URL}/subscription/invoices`, {
    params: {
      kaizen_product: kaizenProduct,
    },
  });

const fetchSubscriptionPreviewInvoice = async (subscriptionId, locationIdsToActivate, locationIdsToDeactivate) =>
  axios.put(`${KA_API_URL}/subscription/${subscriptionId}/invoice_preview`, {
    preview_type: SubscriptionUpdateAction.CHANGE_LOCATION_STATUS,
    preview_params: {
      locations_to_activate: locationIdsToActivate,
      locations_to_deactivate: locationIdsToDeactivate,
    },
  });

const fetchEndTrialEarlyPreviewInvoice = async (subscriptionId, stripePriceId) =>
  axios.put(`${KA_API_URL}/subscription/${subscriptionId}/invoice_preview`, {
    preview_type: SubscriptionUpdateAction.END_TRIAL_EARLY,
    preview_params: {
      trial_end_time: 'now',
      stripe_price_id: stripePriceId,
    },
  });

const fetchSwitchToAnnualPlanPreviewInvoice = async (subscriptionId, annualPlanPriceId) =>
  axios.put(`${KA_API_URL}/subscription/${subscriptionId}/invoice_preview`, {
    preview_type: SubscriptionUpdateAction.CHANGE_PLAN_INTERVAL,
    preview_params: {
      new_interval_price_id: annualPlanPriceId,
    },
  });

/**
 * Hooks
 */

const BILLING_INFO_CACHE_STALETIME = 1000 * 60 * 5; // five minutes

export const SUBSCRIPTIONS_QUERY_KEY = ['subscriptions'];
const SUBSCRIPTION_INVOICES_QUERY_KEY_BASE = ['subscription_invoices'];
export const SUBSCRIPTION_PREVIEW_INVOICE_QUERY_KEY_BASE = ['subscription_preview_invoice'];
export const SUBSCRIPTION_TRIAL_TO_PAID_PREVIEW_INVOICE_QUERY_KEY_BASE = [
  'subscription_end_trial_early_preview_invoice',
];
const SUBSCRIPTION_SWITCH_TO_ANNUAL_PLAN_PREVIEW_INVOICE_QUERY_KEY_BASE = [
  'subscription_switch_to_annual_plan_preview_invoice',
];
export const BILLING_INFO_QUERY_KEY_BASE = ['billingInfo'];
export const SHOPIFY_BILLING_INFO_QUERY_KEY_BASE = 'shopifyBillingInfo';

export const useSubscriptions = queryConfig =>
  useQuery(
    SUBSCRIPTIONS_QUERY_KEY,
    async () => {
      const response = await fetchSubscriptions();
      const subscriptions = response?.data ?? [];

      return subscriptions;
    },
    queryConfig,
  );

export const useBillingInfo = (subscriptionId, billingInfoToRequest, queryConfig) =>
  useQuery(
    [...BILLING_INFO_QUERY_KEY_BASE, subscriptionId, billingInfoToRequest],
    async () => {
      const response = await fetchBillingInfo(subscriptionId, billingInfoToRequest);
      return response.data;
    },
    {
      staleTime: BILLING_INFO_CACHE_STALETIME,
      retry: 1,
      ...queryConfig,
    },
  );

export const useShopifyBillingInfo = (subscriptionId, queryConfig) =>
  useQuery(
    [SHOPIFY_BILLING_INFO_QUERY_KEY_BASE, subscriptionId],
    async () => {
      const response = await fetchShopifyBillingInfo(subscriptionId);
      return response.data;
    },
    {
      /**
       * The GraphQL query behind-the-scenes is rate-limited by Shopify, so this helps
       * mitigate points exhaustion and thus reduces the likelihood of 429s by default.
       */
      retry: 1,
      ...queryConfig,
    },
  );

export const useSubscriptionInvoices = (kaizenProduct = KaizenProduct.KP, queryConfig) =>
  useQuery(
    [...SUBSCRIPTION_INVOICES_QUERY_KEY_BASE, kaizenProduct],
    async () => {
      const response = await fetchSubscriptionInvoices(kaizenProduct);
      return response?.data ?? [];
    },
    queryConfig,
  );

export const useLocationStatusChangePreviewInvoice = (subscriptionId, locationIdsToActivate, locationIdsToDeactivate) =>
  useQuery(
    [...SUBSCRIPTION_PREVIEW_INVOICE_QUERY_KEY_BASE, subscriptionId, locationIdsToActivate, locationIdsToDeactivate],
    async () => {
      const response = await fetchSubscriptionPreviewInvoice(
        subscriptionId,
        locationIdsToActivate,
        locationIdsToDeactivate,
      );
      return response?.data;
    },
    {
      retry: 1,
    },
  );

export const useEndTrialEarlyPreviewInvoice = (subscriptionId, stripePriceId) =>
  useQuery(
    [...SUBSCRIPTION_TRIAL_TO_PAID_PREVIEW_INVOICE_QUERY_KEY_BASE, subscriptionId, stripePriceId],
    async () => {
      const response = await fetchEndTrialEarlyPreviewInvoice(subscriptionId, stripePriceId);
      return response?.data;
    },
    {
      retry: 1,
    },
  );

export const useSwitchToAnnualPlanPreviewInvoice = (subscriptionId, annualPlanPriceId) =>
  useQuery(
    [...SUBSCRIPTION_SWITCH_TO_ANNUAL_PLAN_PREVIEW_INVOICE_QUERY_KEY_BASE, subscriptionId, annualPlanPriceId],
    async () => {
      const response = await fetchSwitchToAnnualPlanPreviewInvoice(subscriptionId, annualPlanPriceId);
      return response?.data;
    },
    {
      retry: 1,
    },
  );

export const useRefreshSubscriptionData = subscriptionId => {
  const queryClient = useQueryClient();

  const refreshSubscriptionData = async () =>
    Promise.all([
      queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId]),
      queryClient.refetchQueries(SUBSCRIPTIONS_QUERY_KEY),
      queryClient.refetchQueries(LOCATIONS_QUERY_KEY),
    ]);

  return refreshSubscriptionData;
};
