import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { DataSource } from './dataSourceConstants';
import { ALinkWithDirectionalArrow } from '../generic/LinkWithDirectionalArrow';
import CloverExportSettings from '../../images/clover-instructions/clover-export-settings.png';
import CloverReportingItemSales from '../../images/clover-instructions/clover-reporting-item-sales.png';
import EtsyDownloadShopData from '../../images/etsy-instructions/etsy-download-shop-data.png';
import EtsySettingsOptions from '../../images/etsy-instructions/etsy-settings-options.png';
import EtsyShopManager from '../../images/etsy-instructions/etsy-shop-manager.png';
import ShopifyColumnSelectorImgSrc from '../../images/shopify-instructions/shopify-column-selector.png';
import ShopifyNoComparisonImgSrc from '../../images/shopify-instructions/shopify-comparison.png';
import ShopifyCreateCustomReportSrc from '../../images/shopify-instructions/shopify-create-custom-report.png';
import ShopifyCustomReportButtonImgSrc from '../../images/shopify-instructions/shopify-custom-report-button.png';
import ShopifyDailyPeriodImgSrc from '../../images/shopify-instructions/shopify-daily-period.png';
import ShopifyDateRangeImgSrc from '../../images/shopify-instructions/shopify-date-range.png';
import ShopifyExportImgSrc from '../../images/shopify-instructions/shopify-export.png';
import ShopifyExportReportImgSrc from '../../images/shopify-instructions/shopify-export-report.png';
import ShopifyGrossSalesImgSrc from '../../images/shopify-instructions/shopify-gross-sales.png';
import ShopifyNetQuantityImgSrc from '../../images/shopify-instructions/shopify-net-quantity.png';
import ShopifyOrderIdImgSrc from '../../images/shopify-instructions/shopify-order-id.png';
import ShopifyProductTitleImgSrc from '../../images/shopify-instructions/shopify-product-title.png';
import ShopifyReports1ImgSrc from '../../images/shopify-instructions/shopify-reports-1.png';
import ShopifyReports2ImgSrc from '../../images/shopify-instructions/shopify-reports-2.png';
import ShopifyStoreSelectionImgSrc from '../../images/shopify-instructions/shopify-store-selection.png';
import ShopifyTotalSalesImgSrc from '../../images/shopify-instructions/shopify-total-sales.png';

export const SetupFlow = {
  GUIDED_SETUP: 'GUIDED_SETUP',
  ADD_NEW_DATA_SOURCE: 'ADD_NEW_DATA_SOURCE',
};

const BulletWithLayout = ({ children, className }) => {
  return (
    <li>
      <div className={classNames('image-layout flex-column justify-content-center', className)}>{children}</div>
    </li>
  );
};

const CloverInstructions = () => (
  <>
    <BulletWithLayout>
      <p>
        Login to your Clover account.{' '}
        <ALinkWithDirectionalArrow
          direction="right"
          arrowRelativeToText="right"
          className="ml-1"
          href="https://clover.com"
          target="_blank"
          rel="noreferrer"
        >
          Go to clover.com
        </ALinkWithDirectionalArrow>
      </p>
    </BulletWithLayout>
    <BulletWithLayout>
      On the left panel of the dashboard, choose &quot;Reporting&quot; then &quot;Item Sales.&quot;
      <div>
        <img width="200px" alt="Item Sales list item" src={CloverReportingItemSales} />
      </div>
    </BulletWithLayout>
    <BulletWithLayout>
      <div>
        Select your chosen <strong>date range</strong> and set the &apos;View by&apos; as &quot;Items&quot;. Click
        &quot;EXPORT&quot; on the top right hand corner to save the .csv file.
      </div>
      <img width="600px" alt="Select date range, set view, and click export" src={CloverExportSettings} />
    </BulletWithLayout>
  </>
);

const EtsyInstructions = () => (
  <>
    <BulletWithLayout>
      <p>
        Sign into your Etsy page.{' '}
        <ALinkWithDirectionalArrow
          direction="right"
          arrowRelativeToText="right"
          className="ml-1"
          href="https://etsy.com"
          target="_blank"
          rel="noreferrer"
        >
          Go to etsy.com
        </ALinkWithDirectionalArrow>
      </p>
    </BulletWithLayout>
    <BulletWithLayout>
      Click on the Shop Manager icon on the top right-hand corner.
      <img width="600px" alt="Location of Shop Manager icon" src={EtsyShopManager} />
    </BulletWithLayout>
    <BulletWithLayout>
      On the left panel of your Shop Manager page, choose &quot;Settings&quot; then &quot;Options&quot;.
      <div>
        <img width="200px" alt="Select Options in the Settings menu" src={EtsySettingsOptions} />
      </div>
    </BulletWithLayout>
    <BulletWithLayout>
      <p>
        Open the &quot;Download Data&quot; tab. In the &quot;Orders&quot; section, choose the year for the data you want
        to upload and click <strong>Download CSV</strong>.
      </p>
      <img width="320px" alt="Download orders data as CSV" src={EtsyDownloadShopData} />
    </BulletWithLayout>
  </>
);

const ShopifyInstructions = ({ setupFlow }) => {
  const freeLocationMessage = setupFlow === SetupFlow.GUIDED_SETUP ? ' You can choose one as your free location.' : '';

  return (
    <>
      <BulletWithLayout>
        <p>
          Login to your Shopify account.{' '}
          <ALinkWithDirectionalArrow
            direction="right"
            arrowRelativeToText="right"
            className="ml-1"
            href="https://accounts.shopify.com/store-login"
            target="_blank"
            rel="noreferrer"
          >
            Go to shopify.com
          </ALinkWithDirectionalArrow>
        </p>
      </BulletWithLayout>
      <BulletWithLayout>
        Select the store/location you want to upload data for.{freeLocationMessage}
        <img width="400px" alt="Select a store" src={ShopifyStoreSelectionImgSrc} />
      </BulletWithLayout>
      <BulletWithLayout>
        On the left panel of your admin page, choose &quot;Analytics&quot; then &quot;Reports&quot;.
        <div className="d-flex">
          <img width="200px" alt="Analytics list item" src={ShopifyReports1ImgSrc} />
          <div className="d-flex align-items-center justify-content-center mx-5">
            <FontAwesomeIcon icon={faArrowRight} size="2x" />
          </div>
          <img width="200px" alt="Reports sub list item" src={ShopifyReports2ImgSrc} />
        </div>
      </BulletWithLayout>
      <BulletWithLayout>
        Select the &quot;Create custom report&quot; button on the top right corner of the admin page.
        <img width="100%" alt="Create a custom report button" src={ShopifyCustomReportButtonImgSrc} />
      </BulletWithLayout>
      <BulletWithLayout>
        Select &quot;Sales over time&quot; under Report template and give a title for your report. Then click
        &quot;Create Custom report&quot;.
        <img width="400px" alt="Create a custom report modal" src={ShopifyCreateCustomReportSrc} />
      </BulletWithLayout>
      <BulletWithLayout>
        <div>
          In the date selection, choose the <strong>date range</strong> you want to use and select &quot;Apply&quot;.
        </div>
        <img width="100%" alt="Choose a date range" src={ShopifyDateRangeImgSrc} />
      </BulletWithLayout>
      <BulletWithLayout>
        <div>
          In the Comparison dropdown, choose <strong>No comparison</strong> in the top left corner and select
          &quot;Apply&quot;.
        </div>
        <img width="100%" alt="Choose No comparison from Compare dropdown" src={ShopifyNoComparisonImgSrc} />
      </BulletWithLayout>
      <BulletWithLayout>
        Choose &quot;Daily&quot; for period.
        <img width="100%" alt="Choose Daily period" src={ShopifyDailyPeriodImgSrc} />
      </BulletWithLayout>
      <BulletWithLayout>
        <div>
          Scroll down to the report, and on the right hand corner, select the <strong>column selector icon</strong>.
        </div>
        <img width="100%" alt="Click the column selector icon" src={ShopifyColumnSelectorImgSrc} />
      </BulletWithLayout>
      <BulletWithLayout>
        <div>Use the filter to search and check the checkboxes for the following columns:</div>
        <div className="d-flex justify-content-between mt-2 labeled-image">
          <div className="d-flex flex-column">
            <strong>Order ID</strong>
            <img width="90%" alt="Check order ID" src={ShopifyOrderIdImgSrc} />
          </div>
          <div className="d-flex flex-column">
            <strong>Product title</strong>
            <img width="90%" alt="Check product title" src={ShopifyProductTitleImgSrc} />
          </div>
          <div className="d-flex flex-column">
            <strong>Net quantity</strong>
            <img width="90%" alt="Check net quantity" src={ShopifyNetQuantityImgSrc} />
          </div>
          <div className="d-flex flex-column">
            <strong>Gross sales</strong>
            <img width="90%" alt="Check gross sales" src={ShopifyGrossSalesImgSrc} />
          </div>
        </div>
      </BulletWithLayout>
      <BulletWithLayout>
        <div>
          Use the filter to uncheck <strong>Total sales</strong>.
        </div>
        <img width="140px" alt="Uncheck total sales" src={ShopifyTotalSalesImgSrc} />
      </BulletWithLayout>
      <BulletWithLayout>
        Scroll to the top of the report page and select &quot;Export&quot;.
        <img width="100%" alt="Click Export tab" src={ShopifyExportImgSrc} />
      </BulletWithLayout>
      <BulletWithLayout>
        Click &quot;Export&quot; (do not select the checkbox).
        <img width="350px" alt="Click Export button to produce the file to upload" src={ShopifyExportReportImgSrc} />
      </BulletWithLayout>
    </>
  );
};

export const DataSourceToInstructions = {
  [DataSource.CLOVER]: CloverInstructions,
  [DataSource.ETSY]: EtsyInstructions,
  [DataSource.SHOPIFY]: ShopifyInstructions,
};

export const dataSourceSupportsDedicatedFileUpload = dataSource =>
  Object.keys(DataSourceToInstructions).includes(dataSource);

export const slugToDataSource = slug => _.upperCase(_.snakeCase(slug));
export const dataSourceNameToSlug = dataSourceName => dataSourceName?.toLocaleLowerCase() ?? '';

export const slugToSetupFlow = slug => {
  if (slug === 'add-new') {
    return SetupFlow.ADD_NEW_DATA_SOURCE;
  }

  if (slug === 'guided-setup') {
    return SetupFlow.GUIDED_SETUP;
  }

  return null;
};
