import axios from 'axios';
import { useMutation } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const createDataset = async dataset => axios.post(`${KA_API_URL}/datasets`, dataset);

export const updateDataset = async (datasetId, dataset) => axios.put(`${KA_API_URL}/datasets/${datasetId}`, dataset);

export const deleteDataset = async datasetId => axios.delete(`${KA_API_URL}/datasets/${datasetId}`);

export const messageDatasetRemoveData = async datasetId =>
  axios.post(`${KA_API_URL}/datasets/message/${datasetId}`, { message: 'remove_processed_data' });

/**
 * Hooks
 */

export const useCreateDataset = mutationConfig =>
  useMutation(async dataset => {
    const response = await createDataset(dataset);

    return response.data;
  }, mutationConfig);

export const useUpdateDataset = mutationConfig =>
  useMutation(async ({ datasetId, dataset }) => {
    const response = await updateDataset(datasetId, dataset);

    return response.data;
  }, mutationConfig);

export const useDeleteDataset = mutationConfig =>
  useMutation(async datasetId => {
    const response = await deleteDataset(datasetId);

    return response?.data;
  }, mutationConfig);

export const useMessageDatasetRemoveData = mutationConfig =>
  useMutation(async datasetId => {
    const response = await messageDatasetRemoveData(datasetId);

    return response?.data;
  }, mutationConfig);
