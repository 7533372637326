import _ from 'lodash';
import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { useResizeDetector } from 'react-resize-detector';
import AnomalyAccordion, { ColumnHeaders } from './AnomalyAccordion';
import { AnomalyDetectionProvider } from './AnomalyDetectionContext';
import { useRefreshResults } from './anomalyDetectionEventStream';
import {
  useAnomalyHistoryMeta,
  useAnomalyStats,
  useAnomalyStatsMeta,
  useProcessedHistoryRowStatusState,
} from './anomalyDetectionHooks';
import { ProcessChangesButton, RefreshResultsButton, UpdatesInProgressIndicator } from './ToolbarComponents';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import ProfitRoverTabContent from '../../generic/ProfitRoverTabContent';
import Header from '../../header/header';
import { getWorkflowTitle } from '../../workflow/workflowFunctions';
import { useDimensions } from '../../../data-access/query/dimensions';
import { useWorkflow } from '../../../data-access/query/workflows';
import './anomaly-detection-dashboard.scss';

const metricColumnIsValid = metricColumn => ![null, ''].includes(metricColumn);

/**
 * This method is just a backup technique. It relies on Arcturus saving the processed history column
 * names in snake_case. It won't always work, but at least prevents some errors from occurring.
 */
const backupFormatColumnName = fromColumn => _.snakeCase(fromColumn);

const useAnomalyHistoryLegend = (columns, metricColumn) => {
  return React.useMemo(() => {
    const columnDefinitions = columns.map(
      ({ field, primary_key: primaryKey, output_name: outputName, from_column: fromColumn }) => {
        return {
          // "The key the Grid will use to index into a row's data"
          columnName: outputName ?? backupFormatColumnName(fromColumn),
          primaryKey,
          field,
        };
      },
    );

    const [primaryColumns, otherColumns] = _.partition(
      columnDefinitions,
      def => def.primaryKey != null || def.columnName === metricColumn,
    );

    // Ensure the anomaly target metric is first in the list of pinned columns
    const sortedPrimaryColumns = _.sortBy(primaryColumns, ({ columnName }) => (columnName === metricColumn ? 0 : 1));
    // Ensure the transaction date is first in the list of non-pinned columns
    const sortedOtherColumns = _.sortBy(otherColumns, ({ field }) => (field === 'TRAN' ? 0 : 1));

    return {
      columnDefinitions,
      primaryColumns: sortedPrimaryColumns,
      otherColumns: sortedOtherColumns,
      columnsKeyedByColumnName: _.keyBy(columnDefinitions, 'columnName'),
    };
  }, [columns, metricColumn]);
};

const Dashboard = ({ workflowId }) => {
  const workflow = useWorkflow(workflowId);
  const { data: workflowData = {}, isLoading: isWorkflowLoading } = workflow;
  const { location_group_id: locationGroupId } = workflowData;

  const anomalyMeta = useAnomalyStatsMeta(workflowId);
  const { data: { anomaly_target: metricColumn, tran_field: dateColumn } = {} } = anomalyMeta;

  const anomalyHistoryMeta = useAnomalyHistoryMeta(workflowId, locationGroupId);
  const { columns = [] } = anomalyHistoryMeta?.data ?? {};

  const anomalyHistoryLegend = useAnomalyHistoryLegend(columns, metricColumn);

  const dimensions = useDimensions(true);
  dimensions.data = React.useMemo(() => {
    if (!workflow) {
      return dimensions.data;
    }

    const workflowDimIds = workflow?.data?.dimensions ?? [];

    return (dimensions?.data ?? []).filter(dim => workflowDimIds.includes(dim.product_dimension_id));
  }, [dimensions.data, workflow]);

  const anomalyStats = useAnomalyStats(workflowId, anomalyMeta?.data ?? {}, {
    enabled: workflowId != null && anomalyMeta.isSuccess && metricColumnIsValid(metricColumn),
  });

  const rowStatusState = useProcessedHistoryRowStatusState(workflowId);
  const { changesAreInProgress, numStagedForExclusion, transitionFromStagedToInProgress } = rowStatusState;

  const { hasNewResults, onReloadDashboard } = useRefreshResults(workflowId);

  /**
   * We measure the element in the header and use that width for all <AnomalyAccordion>s' label truncation
   * to minimize the number of ResizeObservers registered while on this page
   */
  const { width: dimValuesMaxTextWidth, ref: dimValuesTextRef } = useResizeDetector({
    refreshMode: 'throttle',
    refreshRate: 200,
  });

  const contextValue = {
    workflowId,
    locationGroupId,
    anomalyStats,
    anomalyHistoryMeta,
    anomalyHistoryLegend,
    dimensions,
    workflow,
    metricColumn,
    dateColumn,
    rowStatusState,
    dimValuesTextRef,
    dimValuesMaxTextWidth,
  };

  const { data: { rows = [] } = { rows: [] } } = anomalyStats;

  const shouldRenderRows = !isWorkflowLoading && !anomalyMeta.isLoading;

  const workflowTitle = isWorkflowLoading ? null : getWorkflowTitle(workflowData);

  return (
    <AnomalyDetectionProvider value={contextValue}>
      <HeaderAwarePage>
        <Header headerSubText={workflowTitle} />
        <Tab.Container defaultActiveKey="anomalies">
          <ProfitRoverTabContent className="anomaly-detection-dashboard-container">
            <Nav className="toolbar">
              <Nav.Item>
                <Nav.Link eventKey="anomalies">Anomalies</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="data-view">Data View</Nav.Link>
              </Nav.Item> */}

              <div className="h-100 flex-grow-1 d-flex align-items-center justify-content-end">
                {changesAreInProgress && !hasNewResults && <UpdatesInProgressIndicator />}
                {hasNewResults && <RefreshResultsButton onReloadDashboard={onReloadDashboard} />}

                <ProcessChangesButton
                  workflowId={workflowId}
                  numStagedForExclusion={numStagedForExclusion}
                  transitionFromStagedToInProgress={transitionFromStagedToInProgress}
                />
              </div>
            </Nav>
            <Tab.Pane eventKey="anomalies" className="anomaly-list">
              {!anomalyMeta.isLoading && <ColumnHeaders metricColumnLabel={metricColumn} />}
              {shouldRenderRows && rows.map((row, i) => <AnomalyAccordion key={i.toString()} row={row} />)}
            </Tab.Pane>
          </ProfitRoverTabContent>
        </Tab.Container>
      </HeaderAwarePage>
    </AnomalyDetectionProvider>
  );
};

const AnomalyDetectionDashboard = props => {
  const { match } = props;

  let { workflowId } = match?.params;
  workflowId = +workflowId;

  return (
    <ErrorBoundary fallbackRender="An error has occurred">
      <Dashboard workflowId={workflowId} />
    </ErrorBoundary>
  );
};

export default AnomalyDetectionDashboard;
