/* eslint-disable jsx-a11y/no-onchange */
import classnames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import {
  ProfitRoverPrimaryButton,
  ProfitRoverCancelButton,
  ProfitRoverSecondaryButton,
} from '../forms/ProfitRoverButtons';

const StyledDiv = styled.div`
  color: ${styledProps => (styledProps.isError ? 'red' : 'black')};
  font-size: 12px;
  text-align: center;
  padding: 6px;
`;

/**
 * Deprecated
 */
const FileUpload = props => {
  let modalMessage = '';
  const {
    step,
    openModal,
    loadingFile,
    modalError,
    onFileUploadClick,
    onFileUploadChange,
    onSubmitFile,
    onOpenModalButtonClick,
    dataConfigurations,
    onCreateNewDataConfiguration,
    onDataConfigurationSave,
    onDataConfigurationSelectChange,
    onCancelConfigStep,
  } = props;
  if (loadingFile || modalError) {
    modalMessage = (
      <StyledDiv isError={props.modalError}>
        <h5>{props.modalMessage}</h5>
      </StyledDiv>
    );
  }

  const history = useHistory();

  const navigateToIntegrationSetup = () => {
    history.push('/integration/help');
  };

  let modal = (
    <div>
      <div className="file-upload-modal-header">
        <h4>Add New Data File</h4>
      </div>

      <br />

      <div className="row">
        <span className="col-3" id="file-upload-column">
          File:
        </span>
        <span className="col-8">
          <input
            type="file"
            id="txtUploadTicketsData"
            accept=".csv,.xls,.xlsx"
            onClick={onFileUploadClick}
            onChange={onFileUploadChange}
          />
        </span>
      </div>

      <br />
      {modalMessage}
      <div className="file-upload-modal-buttons">
        <div className="datacenter-file-upload-button-bar">
          <ProfitRoverPrimaryButton onClick={onSubmitFile}>Upload</ProfitRoverPrimaryButton>
          <ProfitRoverCancelButton onClick={onOpenModalButtonClick}>Close</ProfitRoverCancelButton>
        </div>
      </div>
    </div>
  );

  if (step === 2) {
    const createNewDataConfigPanel = (
      <div className={classnames('file-upload-modal-buttons', 'datacenter-file-upload-button-bar')}>
        <ProfitRoverSecondaryButton onClick={onCreateNewDataConfiguration}>
          Create New Data Configuration
        </ProfitRoverSecondaryButton>
        <ProfitRoverCancelButton onClick={onCancelConfigStep}>Cancel</ProfitRoverCancelButton>
      </div>
    );

    const dataConfigPanel = (
      <>
        <div className="row pb-1">
          <span className="col-4 data-config-select-span">
            <p>Select Existing Data</p>
            <p>Configuration</p>
          </span>
          <span className="col-5">
            <select className="userSelect" name="datasetConfigId" onChange={onDataConfigurationSelectChange}>
              {dataConfigurations.map(configuration => (
                <option key={configuration.value} value={configuration.value}>
                  {configuration.label}
                </option>
              ))}
            </select>
          </span>
          <span className="col-2">
            <ProfitRoverPrimaryButton onClick={onDataConfigurationSave}>Save</ProfitRoverPrimaryButton>
          </span>
        </div>
        <div>
          <h4 className="file-upload-modal-buttons">OR</h4>
        </div>
        {createNewDataConfigPanel}
      </>
    );

    const doConfigurationsExist = dataConfigurations.length > 0;
    modal = (
      <div>
        <div className="file-upload-modal-header">
          <h4>Select Data Configuration</h4>
        </div>
        <br />
        {doConfigurationsExist ? dataConfigPanel : createNewDataConfigPanel}
      </div>
    );
  }

  return (
    <div className="datacenter-file-upload-button-bar">
      <ProfitRoverSecondaryButton onClick={onOpenModalButtonClick}>Upload Data</ProfitRoverSecondaryButton>
      <ProfitRoverSecondaryButton onClick={navigateToIntegrationSetup}>Set Up Integration</ProfitRoverSecondaryButton>

      <Modal show={openModal} className="file-upload-modal">
        {modal}
      </Modal>
    </div>
  );
};

export default FileUpload;
