import React from 'react';
import ProfitRoverSpinner from '../spinner/ProfitRoverSpinner';
import HeaderAwarePage from './HeaderAwarePage';
import Header from '../header/header';

const GenericLoadingPage = ({ Spinner = ProfitRoverSpinner }) => (
  <HeaderAwarePage scrollable={false}>
    <Header />
    <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '100%' }}>
      <Spinner />
    </div>
  </HeaderAwarePage>
);

export default GenericLoadingPage;
