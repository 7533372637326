import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import CreatePassword from './components/auth/CreatePassword';
import Forgot from './components/auth/forgot';
import Login from './components/auth/Login';
import ResetPassword from './components/auth/resetPassword';
import home from './components/home/home';
import RegistrationContainer from './components/registration/CustomerRegistration';
import NotificationCenter from './components/notificationCenter/NotificationCenter';
import ProtectedRoutes from './components/routing/ProtectedRoutes';
import RouteChangeTracker from './components/routing/RouteChangeTracker';
import ShopifyRedirect from './components/shopifyRedirect/ShopifyRedirect';
import { GA_TRACKING_ID, TESTING_GOOGLE_ANALYTICS } from './config/google';
import queryClient from './queryClient';
import { attemptToReloadExistingUserSession } from './redux/auth/actions';
import { EventStreamContextProvider } from './server-sent-events/eventStreamContext';
import store from './store';
import './colors.css';
import './app.scss';

// Emit Google Analytics data only if we're in prod or if we're specifically trying to test Google Analytics
if (process.env.NODE_ENV === 'production' || TESTING_GOOGLE_ANALYTICS === 'true') {
  ReactGA.initialize(GA_TRACKING_ID);
} else {
  ReactGA.initialize(GA_TRACKING_ID, { testMode: true });
}

const App = () => {
  useEffect(() => {
    /**
     * If the user navigates back to the app without having explicitly logged out,
     * this will attempt to recover the session via LocalStorage to prevent the need
     * for manual re-authentication.
     */
    store.dispatch(attemptToReloadExistingUserSession());
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <EventStreamContextProvider>
          <BrowserRouter>
            <RouteChangeTracker />
            <NotificationCenter />
            <Switch>
              <Route exact path="/" component={home} />
              <Route exact path="/login" component={Login} />

              <Route
                exact
                path="/user-registration"
                // The old route redirects to the new route (and preserves any query params)
                component={props => (
                  <Redirect to={{ pathname: '/customer-registration', search: props?.location?.search }} />
                )}
              />
              <Route exact path="/customer-registration" component={RegistrationContainer} />
              <Route exact path="/shopify/merchant-redirect" component={ShopifyRedirect} />

              {/* Promotional routes disabled for now */}
              {/* <Route exact path="/launchparty" component={RegistrationContainer} /> */}
              {/* matches any path starting with 'promo'; these paths correspond to Stripe prices */}
              {/* <Route path="/promo*" component={RegistrationContainer} /> */}

              <Route exact path="/forgot" component={Forgot} />
              <Route exact path="/resetpassword" component={ResetPassword} />
              <Route exact path="/createpassword" component={CreatePassword} />
              <ProtectedRoutes />
            </Switch>
          </BrowserRouter>
        </EventStreamContextProvider>
      </ReduxProvider>
    </QueryClientProvider>
  );
};

export default App;
