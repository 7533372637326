/* Use this method instead of adding the md5 package as a dependency.
 * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 */
async function digestString(data, algorithm = 'SHA-256') {
  const uint8data = new TextEncoder().encode(data);
  const hashBuffer = await crypto.subtle.digest(algorithm, uint8data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashString = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

  return hashString;
}

export default digestString;
