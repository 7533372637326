import { useContext, useCallback } from 'react';
import { DashboardContext } from '../revenueUpliftContexts';

const useLocationName = () => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error('useLocationName must be used within a DashboardProvider');
  }

  const { locationsApi } = context;
  const { locationOptions } = locationsApi;

  const getLocationName = useCallback(
    id => {
      const location = locationOptions.find(loc => loc.value === Number(id));
      return location ? location.label : id;
    },
    [locationOptions],
  );

  return { getLocationName };
};

export default useLocationName;
