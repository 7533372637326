import React, { Component } from 'react';
import * as d3 from 'd3';

const width = 420;
const height = 50;

class Legend extends Component {
  componentDidMount() {
    this.renderText();
  }

  componentDidUpdate() {
    d3.selectAll('.legendtext').remove();
    this.renderText();
  }

  renderText = () => {
    d3.select(`#legend`)
      .append('text')
      .attr('x', 120)
      .attr('y', 30)
      .attr('class', 'legendtext')
      .style('font-size', 16)
      .style('font-family', 'sans-serif')
      .text('KaizenPrice');

    d3.select(`#legend`)
      .append('text')
      .attr('x', 270)
      .attr('y', 30)
      .attr('class', 'legendtext')
      .style('font-size', 16)
      .style('font-family', 'sans-serif')
      .text('Current Price');
  }

  render() {
    return (
      <svg id="legend" width={width} height={height}>
        <circle cx="100" cy="25" r="7" fill="none" stroke="#00af4f" />
        <circle cx="250" cy="25" r="7" fill="none" stroke="#ff8c00" />
      </svg>
    );
  }
}

export default Legend;
