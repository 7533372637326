/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import Calendar from 'react-calendar';
import Select from 'react-select';
import { DateRangePicker } from 'react-dates';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import lodash from 'lodash';
import axios from '../../../utils/axios-client';
import Header from '../../header/header';
import { KA_API_URL } from '../../../config/baseUrl';
import { formatNumber, arrayParamSerialization } from '../../util/format';
import Bar from '../../charts/barChart';
import pagination from './eoPagination';
import {
  calculateProductsPane,
  calculateUpliftData,
  calculateDailyTableData,
  calculateDetailTableData,
} from './utils/attendanceHeatMapCalculations';
import { calculateDemandData, calculateDailySummaryDemandData } from './utils/commonForecastGraphCalculations';
import { CenteredProfitRoverSpinner } from '../../spinner/ProfitRoverSpinner';
import getFiltersData from '../../util/commonFilterCalculations';
import {
  yearLevelCalculateCalendarData,
  monthLevelCalculateCalendarData,
} from './utils/attendanceCalendarCalculations';
import { getHolidayMap, getWeatherMap } from './utils/kdl';
import './eoKaizenPrice.css';
import './calendarHeatmap.css';
import Donut from '../../charts/donutChart';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';

const moment = require('moment');
const momentTZ = require('moment-timezone');
const jstz = require('jstimezonedetect');

class EOKaizenAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allLocations: [],
      locationHolidays: null,
      locationWeather: null,
      selectedLocation: null,
      allProducts: [],
      selectedProduct: null,
      startDate: null,
      endDate: null,
      focusedInput: null,
      locationUplift: [],
      productUplift: [],
      monthsUplift: [],
      locationDonutData: [],
      productDonutData: [],
      calendarView: 'year',
      yearLevelDataAttendanceArray: [],
      monthLevelDataAttendanceArray: [],
      colors: [
        'rgb(78,175,49)',
        'rgb(248,183,10)',
        'rgb(97,73,198)',
        'rgb(159,129,112)',
        'rgb(225,186,165)',
        'rgb(106,228,206)',
        'rgb(140,119,180)',
        'rgb(165,165,165)',
        'rgb(221,203,135)',
        'rgb(140,119,180)',
        'rgb(105,105,105)',
      ],
      productColors: [
        'rgb(248,183,10)',
        'rgb(78,175,49)',
        'rgb(97,73,198)',
        'rgb(159,129,112)',
        'rgb(225,186,165)',
        'rgb(106,228,206)',
        'rgb(140,119,180)',
        'rgb(165,165,165)',
        'rgb(221,203,135)',
        'rgb(140,119,180)',
        'rgb(105,105,105)',
      ],
      dataLoaded: false,
      showCalendar: false,
      date: new Date(),
      currentYear: '',
      forecast: [],
      dailySummary: [],
      statusMessage: 'Loading Data . . .',
      selectedDetailedVisit: '',
      forecastGraphData: [],
      graphDetails: [],
      showForecasts: false,
      permanentAllProducts: [],
      calendarStartDate: new Date(),
      calendarEndDate: new Date(),
      noResults: false,
      noDataMessage: '',
      view: 'daily',
      selectedDailyDate: '',
      detailPageNumber: 1,
      dailyPageNumber: 1,
      detailRecords: 100,
      dailyRecords: 100,
      dailySortObject: {
        location: {
          is_sorted: false,
          direction: 'ASC',
        },
        product_dimension_value: {
          is_sorted: false,
          direction: 'ASC',
        },
        start_datetime: {
          is_sorted: false,
          direction: 'ASC',
        },
        attendance_uplift: {
          is_sorted: false,
          direction: 'ASC',
        },
      },
      dailyCurrentSortProp: '',
      detailSortObject: {
        location: {
          is_sorted: false,
          direction: 'ASC',
        },
        product_dimension_value: {
          is_sorted: false,
          direction: 'ASC',
        },
        start_datetime: {
          is_sorted: false,
          direction: 'ASC',
        },
        forecast_quantity: {
          is_sorted: false,
          direction: 'ASC',
        },
      },
      detailCurrentSortProp: '',
      sortClicked: false,
      detailSortClicked: false,
      maxPayDate: '',
      maxTimeStamp: '',
      timeZoneAbbr: '',
      nonFilteredEndDate: '',
      nonFilteredStartDate: '',
      recommendationDetailsFooterShow: false,
      finalProductsArray: [],
      defaultSortArray: ['location', 'product_dimension_value', 'start_datetime'],
    };
    this.getTileClass = this.getTileClass.bind(this);
  }

  async componentDidMount() {
    const subscriptionValidThruDate = localStorage.getItem('subscription_valid_thru_date');

    try {
      let { workflowId } = this.props?.match?.params;
      workflowId = parseInt(workflowId, 10);

      await this.setState({
        workflowId,
      });

      const filtersData = await getFiltersData('forecast', 'EO', workflowId);
      const locationHolidays = await getHolidayMap(filtersData.distinctLocationsArray, subscriptionValidThruDate);
      const locationWeather = await getWeatherMap(filtersData.distinctLocationsArray);
      const date = new Date();
      const currentYear = date.getFullYear().toString();
      const datasets = await axios.get(`${KA_API_URL}/datasets`);
      const eoAdmitDatasets = datasets.data.filter(
        dataset => dataset.industry === 'EO' && dataset.data_type === 'ADMIT',
      );
      const maxEventDate =
        eoAdmitDatasets.length === 0
          ? 'Unknown'
          : new Date(Math.max(...eoAdmitDatasets.map(dataset => new Date(dataset.event_max_date))));

      const maxTimeStampResponse = await axios.get(`${KA_API_URL}/productvisit/stats/maxrundate`, {
        params: { objective: 'ATTENDFORECAST', industry: 'EO', workflow_id: workflowId },
      });

      const maxTimeStamp = maxTimeStampResponse.data === '' ? 'unknown' : new Date(maxTimeStampResponse.data);
      const nonFilteredStartDate = moment(new Date()).format('YYYY-MM-DD');
      const nonFilteredEndDate = new Date(localStorage.getItem('subscription_valid_thru_date'));

      const allProducts = filtersData.distinctProductsArray;
      const permanentAllProducts = lodash.cloneDeep(filtersData.distinctProductsArray);
      const allLocations = filtersData.distinctLocationsArray;
      const permanentAllLocations = lodash.cloneDeep(filtersData.distinctLocationsArray);
      await this.setState({
        allLocations,
        permanentAllLocations,
        allProducts,
        permanentAllProducts,
        locationHolidays,
        locationWeather,
        currentYear,
        maxTimeStamp,
        nonFilteredStartDate,
        nonFilteredEndDate,
        recommendationDetailsFooterShow: true,
        finalProductsArray: filtersData.allProductsArray,
        locationsDescriptionMap: filtersData.locationsDescriptionmap,
        calendarEndDate: nonFilteredEndDate,
      });

      await this.calculateHeatMapsData();
      const { productUplift } = this.state;
      await this.calculateAggregatedProductUplift(productUplift);
      await this.renderLocationDonutChart();
      await this.renderProductDonutChart();
      const { monthsUplift } = this.state;
      await this.calculateCalendarData(monthsUplift);
      await this.applyFilters();
      this.renderMaxTransactions(maxEventDate, maxTimeStamp);

      const { locationUplift } = this.state;
      if (locationUplift.length > 0) {
        await this.setState({
          date,
          dataLoaded: true,
          showCalendar: true,
        });
      } else {
        await this.setState({
          noResults: true,
          noDataMessage: 'No data found',
        });
      }
    } catch (err) {
      await this.setState({
        noResults: true,
        noDataMessage: 'Unknown error',
      });
    }
  }

  getTileClass({ date, view }) {
    const { yearLevelDataAttendanceArray, currentYear, monthLevelDataAttendanceArray } = this.state;
    if (view === 'year') {
      const data = yearLevelDataAttendanceArray.filter(d => d.year === parseInt(currentYear, 10));
      const day = moment(date).format('YYYY-MM-DD');
      const tileDate = data.filter(d => d.month === parseInt(day.substring(5, 7), 10));
      if (tileDate.length > 0) {
        if (tileDate[0].color.toString() === '1.00') {
          return 'HeatMap100';
        }
        const classAssignment = tileDate[0].color.toString().split('.')[1];
        return `HeatMap${classAssignment}`;
      }
    }
    if (view === 'month') {
      const day = moment(date)
        .format('YYYY-MM-DD')
        .substring(8, 10);
      const tileDate = monthLevelDataAttendanceArray.filter(d => d.day === parseInt(day, 10));

      if (tileDate.length > 0) {
        if (tileDate[0].color.toString() === '1.00') {
          return 'HeatMap100';
        }
        const classAssignment = tileDate[0].color.toString().split('.')[1];
        return `HeatMap${classAssignment}`;
      }
    }
    return '';
  }

  calculateCurrentSelections = async () => {
    const {
      selectedLocation,
      allLocations,
      selectedProduct,
      allProducts,
      finalProductsArray,
      nonFilteredStartDate,
      nonFilteredEndDate,
    } = this.state;

    const locations = selectedLocation !== null && selectedLocation.length !== 0 ? selectedLocation : allLocations;

    const products = selectedProduct !== null && selectedProduct.length !== 0 ? selectedProduct : allProducts;

    let { startDate } = this.state;
    let { endDate } = this.state;

    let locationIds = [];
    let productIds = [];

    const productClasses = ['GEN'];

    const productsArray = [];
    locations.map(location => {
      products.map(product => {
        finalProductsArray.map(finalProducts => {
          if (finalProducts.description === product.label && finalProducts.customer_entity_id === location.value) {
            productsArray.push({
              label: finalProducts.description,
              value: finalProducts.product_id,
            });
          }
        });
      });
    });

    locations.map(location => locationIds.push(parseInt(location.value, 10)));
    productsArray.map(product => productIds.push(parseInt(product.value, 10)));

    // this means an invalid combination, so pass in [-1] so that the select clause executes
    productIds = productIds.length > 0 ? productIds : [-1];
    locationIds = locationIds.length > 0 ? locationIds : [-1];

    if (startDate !== null) {
      startDate = moment(startDate._d).format('YYYY-MM-DD');
    } else if (startDate === null && endDate !== null) {
      startDate = moment(endDate._d).format('YYYY-MM-DD');
    } else {
      startDate = '';
    }

    if (endDate !== null) {
      endDate = moment(endDate._d).format('YYYY-MM-DD');
    } else if (startDate !== '' && endDate === null) {
      endDate = moment(startDate).format('YYYY-MM-DD');
    } else {
      endDate = '';
    }

    if (startDate === '' && endDate === '') {
      startDate = moment(nonFilteredStartDate).format('YYYY-MM-DD');
      endDate = moment(nonFilteredEndDate).format('YYYY-MM-DD');
    }

    return { locationIds, productIds, productClasses, startDate, endDate };
  };

  calculateHeatMapsData = async () => {
    const { workflowId } = this.state;
    const currentSelections = await this.calculateCurrentSelections();

    const upliftData = await calculateUpliftData(
      workflowId,
      currentSelections.locationIds,
      currentSelections.productIds,
      currentSelections.startDate,
      currentSelections.endDate,
    );
    if (upliftData.locationUplift.length === 0) {
      await this.setState({
        noResults: true,
        locationUplift: [],
        productUplift: [],
        monthsUplift: [],
        graphDetails: [],
        forecastGraphData: [],
        forecast: [],
        dailySummary: [],
        selectedDailyDate: '',
        selectedDetailedVisit: '',
      });
    } else {
      await this.setState({
        locationUplift: upliftData.locationUplift,
        productUplift: upliftData.productUplift,
        monthsUplift: upliftData.monthsUplift,
      });
    }
  };

  calculateAggregatedProductUplift = async productUplift => {
    const data = await calculateProductsPane(productUplift);
    await this.setState({
      productAttendanceArray: data.productAttendanceArray,
    });
  };

  renderLocationDonutChart = async () => {
    const { locationUplift, colors } = this.state;
    const locationDonutData = [];
    let total = 0;
    let othersTotal = 0;
    locationUplift.sort((a, b) => b.attendance_uplift - a.attendance_uplift);

    for (let i = 0; i < locationUplift.length; i++) {
      if (i < process.env.REACT_APP_DONUT_CHART_MAX_LOCATION) {
        const locationObj = {};
        total += locationUplift[i].attendance_uplift;
        locationObj.name = locationUplift[i].location;
        locationObj.uplift = locationUplift[i].attendance_uplift;
        locationObj.upliftDisplay = formatNumber(Math.round(locationUplift[i].attendance_uplift));
        locationDonutData.push(locationObj);
      } else {
        othersTotal += locationUplift[i].attendance_uplift;
      }
    }

    if (othersTotal > 0) {
      const locationObj = {};
      locationObj.name = 'Other';
      locationObj.uplift = othersTotal;
      locationObj.upliftDisplay = formatNumber(Math.round(othersTotal));
      total += othersTotal;
      locationDonutData.push(locationObj);
    }

    for (let i = 0; i < locationDonutData.length; i++) {
      locationDonutData[i].total = formatNumber(Math.round(total));
      locationDonutData[i].color = colors[i];
    }

    await this.setState({
      locationDonutData,
    });
  };

  renderProductDonutChart = async () => {
    const { productAttendanceArray, productColors } = this.state;
    const productDonutData = [];
    let total = 0;
    let othersTotal = 0;

    productAttendanceArray.sort((a, b) => b.attendance_uplift - a.attendance_uplift);

    for (let i = 0; i < productAttendanceArray.length; i++) {
      if (i < process.env.REACT_APP_DONUT_CHART_MAX_LOCATION) {
        const productObj = {};
        total += productAttendanceArray[i].attendance_uplift;
        productObj.name = productAttendanceArray[i].product_dimension_value;
        productObj.uplift = productAttendanceArray[i].attendance_uplift;
        productObj.upliftDisplay = formatNumber(Math.round(productAttendanceArray[i].attendance_uplift));
        productDonutData.push(productObj);
      } else {
        othersTotal += productAttendanceArray[i].attendance_uplift;
      }
    }

    if (othersTotal > 0) {
      const productObj = {};
      productObj.name = 'Other';
      productObj.uplift = othersTotal;
      productObj.upliftDisplay = formatNumber(Math.round(othersTotal));
      total += othersTotal;
      productDonutData.push(productObj);
    }

    for (let i = 0; i < productDonutData.length; i++) {
      productDonutData[i].total = formatNumber(Math.round(total));
      productDonutData[i].color = productColors[i];
    }

    await this.setState({
      productDonutData,
    });
  };

  handleLocationChange = async selectedOption => {
    await this.setState({ selectedLocation: selectedOption });
    await this.modifyFilters();
  };

  handleProductChange = async selectedOption => {
    await this.setState({ selectedProduct: selectedOption });
    await this.modifyFilters();
  };

  modifyFilters = async () => {
    const {
      selectedProduct: selectedProductState,
      selectedLocation: selectedLocationState,
      locationsDescriptionMap,
      permanentAllLocations,
      permanentAllProducts,
    } = this.state; // selected values can be null or empty array based on how user selects/deselects, standardizing the selections here
    const selectedProduct =
      selectedProductState !== null && selectedProductState.length !== 0 ? selectedProductState : [];

    const selectedLocation =
      selectedLocationState !== null && selectedLocationState.length !== 0 ? selectedLocationState : [];

    const { finalProductsArray } = this.state;

    // Arrays and sets to store final products, locations, productClasses
    const allProducts = [];
    const allLocations = [];

    const allLocationsSet = new Set();
    const allProductsSet = new Set();

    // Arrays and Sets to store products based on Location selections
    const allProductsLocation = [];
    const allProductsLocationSet = new Set();

    // Arrays and Sets to store locations based on Products selections
    const allLocationsProduct = [];
    const allLocationsProductSet = new Set();

    // if there are selections
    if (selectedProduct.length !== 0 || selectedLocation.length !== 0) {
      // calculating products classes and products based on selections
      if (selectedLocation.length !== 0) {
        const selectedLocationsSet = new Set();
        selectedLocation.map(location => selectedLocationsSet.add(location.value));

        finalProductsArray.map(product => {
          if (selectedLocationsSet.has(product.customer_entity_id)) {
            if (!allProductsLocationSet.has(product.product_id)) {
              allProductsLocation.push({
                value: product.product_id,
                label: product.description,
              });

              allProductsLocationSet.add(product.product_id);
            }
          }
        });
      }

      // calculating locations based on product selections
      if (selectedProduct.length !== 0) {
        const selectedProductsSet = new Set();
        selectedProduct.map(product => selectedProductsSet.add(product.label));

        finalProductsArray.map(product => {
          if (selectedProductsSet.has(product.description)) {
            if (!allLocationsProductSet.has(product.customer_entity_id)) {
              allLocationsProduct.push({
                value: product.customer_entity_id,
                label: locationsDescriptionMap.get(product.customer_entity_id).location_name,
              });
              allLocationsProductSet.add(product.customer_entity_id);
            }
          }
        });
      }

      if (allLocationsProduct.length > 0) {
        allLocationsProduct.map(location => {
          if (!allLocationsSet.has(location.value)) {
            allLocations.push(location);
            allLocationsSet.add(location.value);
          }
        });
      }

      if (allProductsLocation.length > 0) {
        allProductsLocation.map(product => {
          if (!allProductsSet.has(product.label)) {
            allProducts.push({
              value: product.label,
              label: product.label,
            });
            allProductsSet.add(product.label);
          }
        });
      }

      allLocations.sort((a, b) => (a.label > b.label ? 1 : -1));
      allProducts.sort((a, b) => (a.label > b.label ? 1 : -1));

      // set state and if the state not affected set to default state
      await this.setState({
        allLocations: allLocations.length > 0 ? allLocations : permanentAllLocations,
        allProducts: allProducts.length > 0 ? allProducts : permanentAllProducts,
      });
    } else {
      // if all selections are empty, roll back to default state
      await this.setState({
        allProducts: permanentAllProducts,
        allLocations: permanentAllLocations,
      });
    }
  };

  handleDatesChange = async ({ startDate, endDate }) => {
    await this.setState({
      startDate,
      endDate,
    });
  };

  handleFocusedInput = async focusedInput => {
    await this.setState({
      focusedInput,
    });
  };

  renderDailyTableData = async paginatedResult => {
    const {
      dailyCurrentSortProp,
      dailySortObject,
      defaultSortArray,
      dailyPageNumber,
      dailySummary,
      workflowId,
    } = this.state;
    const currentSelections = await this.calculateCurrentSelections();
    const sort = [];
    const sortDirection = [];

    if (dailyCurrentSortProp !== '') {
      sort.push(dailyCurrentSortProp);
      sortDirection.push(dailySortObject[dailyCurrentSortProp].direction);

      const clonedSortArray = lodash.cloneDeep(defaultSortArray);
      const modifiedDefaultSortArray = clonedSortArray.filter(d => d !== dailyCurrentSortProp);

      modifiedDefaultSortArray.map(d => {
        sort.push(d);
      });
    } else {
      defaultSortArray.map(d => sort.push(d));
    }

    const tableStats = await calculateDailyTableData(
      workflowId,
      currentSelections.locationIds,
      currentSelections.productIds,
      currentSelections.startDate,
      currentSelections.endDate,
      dailyPageNumber,
      sort,
      sortDirection,
    );

    await this.setState({
      sortClicked: false,
    });
    if (paginatedResult) {
      const data = dailySummary;
      const dailySummaryData = [];
      data.map(d => dailySummaryData.push(d));
      tableStats.map(d => dailySummaryData.push(d));
      await this.setState({
        dailySummary: dailySummaryData,
      });
    } else {
      await this.setState({
        dailySummary: [],
      });
      await this.setState({
        dailySummary: tableStats,
      });
    }
    const { dailySummary: summary } = this.state;
    if (summary.length > 0) {
      await this.setState({
        dataLoaded: true,
      });
    } else {
      await this.setState({
        noResults: true,
        noDataMessage: 'No data found. Try changing the filter criteria',
      });
    }
  };

  renderDetailTableData = async paginatedResult => {
    const {
      detailCurrentSortProp,
      detailSortObject,
      defaultSortArray,
      detailPageNumber,
      forecast,
      workflowId,
    } = this.state;
    const currentSelections = await this.calculateCurrentSelections();
    const sort = [];
    const sortDirection = [];

    if (detailCurrentSortProp !== '') {
      sort.push(detailCurrentSortProp);
      sortDirection.push(detailSortObject[detailCurrentSortProp].direction);

      const clonedSortArray = lodash.cloneDeep(defaultSortArray);
      const modifiedDefaultSortArray = clonedSortArray.filter(d => d !== detailCurrentSortProp);

      modifiedDefaultSortArray.map(d => {
        sort.push(d);
      });
    } else {
      defaultSortArray.map(d => sort.push(d));
    }

    const tableStats = await calculateDetailTableData(
      workflowId,
      currentSelections.locationIds,
      currentSelections.productIds,
      currentSelections.startDate,
      currentSelections.endDate,
      detailPageNumber,
      sort,
      sortDirection,
    );

    this.setState({
      detailSortClicked: false,
    });

    if (paginatedResult) {
      const data = forecast;
      const forecastData = [];
      data.map(d => forecastData.push(d));
      tableStats.map(d => forecastData.push(d));
      await this.setState({
        forecast: forecastData,
      });
    } else {
      await this.setState({
        forecast: [],
      });
      await this.setState({
        forecast: tableStats,
      });
    }
  };

  applyFilters = async e => {
    if (e) {
      e.preventDefault();
    }

    await this.setState({
      graphDetails: [],
      forecastGraphData: [],
      selectedDetailedVisit: '',
      selectedDailyDate: '',
      showForecasts: false,
      dataLoaded: false,
      calendarView: 'year',
      noResults: false,
      dailyRecords: 100,
      dailyPageNumber: 1,
      detailRecords: 100,
      detailPageNumber: 1,
    });

    await this.calculateHeatMapsData();
    await this.renderLocationDonutChart();
    const { productUplift } = this.state;
    await this.calculateAggregatedProductUplift(productUplift);
    await this.renderProductDonutChart();
    const { monthsUplift } = this.state;
    await this.calculateCalendarData(monthsUplift);

    const { startDate, endDate, nonFilteredEndDate } = this.state;
    if (startDate !== null && endDate !== null) {
      await this.setState({
        calendarStartDate: startDate._d,
        calendarEndDate: endDate._d,
      });
    } else if (startDate !== null && endDate === null) {
      await this.setState({
        calendarStartDate: startDate._d,
        calendarEndDate: startDate._d,
      });
    } else if (startDate === null && endDate !== null) {
      await this.setState({
        calendarStartDate: endDate._d,
        calendarEndDate: endDate._d,
      });
    } else {
      await this.setState({
        calendarStartDate: new Date(),
        calendarEndDate: nonFilteredEndDate,
      });
    }
    await this.manipulateCalendar();
    await this.renderDailyTableData(false);
    await this.renderDetailTableData(false);
  };

  manipulateCalendar = async () => {
    await this.setState({
      showCalendar: false,
    });

    await this.setState({
      showCalendar: true,
    });
  };

  calculateCalendarData = async data => {
    const { calendarView } = this.state;
    if (calendarView === 'year') {
      const yearLevelDataAttendanceArray = await yearLevelCalculateCalendarData(data);
      await this.setState({
        yearLevelDataAttendanceArray,
      });
    } else if (calendarView === 'month') {
      const monthLevelDataAttendanceArray = await monthLevelCalculateCalendarData(data);
      await this.setState({
        monthLevelDataAttendanceArray,
      });
    }
  };

  calculateDailySortData = async id => {
    const { dailyCurrentSortProp, dailySortObject } = this.state;
    if (dailyCurrentSortProp !== '' && dailyCurrentSortProp !== id) {
      dailySortObject[dailyCurrentSortProp].is_sorted = false;
    }
    if (dailySortObject[id].is_sorted === false) {
      dailySortObject[id].is_sorted = true;
      dailySortObject[id].direction = 'ASC';
    } else if (dailySortObject[id].direction === 'ASC') {
      dailySortObject[id].direction = 'DESC';
    } else {
      dailySortObject[id].direction = 'ASC';
    }

    await this.setState({
      graphDetails: [],
      forecastGraphData: [],
      selectedDetailedVisit: '',
      selectedDailyDate: '',
      showForecasts: false,
      dailySortObject,
      dailyCurrentSortProp: id,
      dailyPageNumber: 1,
      dailyRecords: 100,
    });

    await this.renderDailyTableData(false);
  };

  calculateDetailSortData = async id => {
    const { detailCurrentSortProp, detailSortObject } = this.state;
    if (detailCurrentSortProp !== '' && detailCurrentSortProp !== id) {
      detailSortObject[detailCurrentSortProp].is_sorted = false;
    }
    if (detailSortObject[id].is_sorted === false) {
      detailSortObject[id].is_sorted = true;
      detailSortObject[id].direction = 'ASC';
    } else if (detailSortObject[id].direction === 'ASC') {
      detailSortObject[id].direction = 'DESC';
    } else {
      detailSortObject[id].direction = 'ASC';
    }

    await this.setState({
      graphDetails: [],
      forecastGraphData: [],
      selectedDetailedVisit: '',
      showForecasts: false,
      selectedDailyDate: '',
      detailSortObject,
      detailCurrentSortProp: id,
      detailPageNumber: 1,
      detailRecords: 100,
    });

    await this.renderDetailTableData(false);
  };

  renderCalendar = () => {
    const { date, calendarView, calendarStartDate, calendarEndDate } = this.state;
    return (
      <Calendar
        activeStartDate={date}
        onActiveDateChange={value => this.handleDrill(value)}
        onDrillDown={value => this.handleDrill(value)}
        onDrillUp={value => this.handleDrill(value)}
        calendarType="US"
        view={calendarView}
        tileClassName={this.getTileClass}
        showNeighboringMonth={false}
        minDate={calendarStartDate}
        maxDate={calendarEndDate}
      />
    );
  };

  handleDrill = async value => {
    if (value.view === 'century' || value.view === 'decade') {
      return;
    }
    const currentYear = moment(value.activeStartDate)
      .format('YYYY-MM-DD')
      .substring(0, 4);

    await this.setState({
      currentYear,
      date: value.activeStartDate,
      calendarView: value.view,
    });

    if (value.view === 'year' && currentYear !== value.year) {
      await this.manipulateCalendar();
    }

    if (value.view === 'month') {
      this.calculateDaysUplift(value);
    }
  };

  getLastDateOfMonth = async (year, month) => {
    return moment(new Date(year, month, 0)).format('YYYY-MM-DD');
  };

  calculateDaysUplift = async value => {
    const currentSelections = await this.calculateCurrentSelections();
    const productClass = currentSelections.productClasses;
    const { locationIds, productIds, startDate, endDate } = currentSelections;

    const monthStartDate = moment(value.activeStartDate).format('YYYY-MM-DD');
    const currentMonth = parseInt(monthStartDate.substring(5, 7), 10);
    const currentYear = parseInt(monthStartDate.substring(0, 4), 10);

    const monthEndate = await this.getLastDateOfMonth(currentYear, currentMonth);
    let daysUplift = [];
    await this.calculateCalendarData(daysUplift, false);
    await this.manipulateCalendar(value);

    const queryStartDate = startDate !== '' && startDate > monthStartDate ? startDate : monthStartDate;
    const queryEndDate = endDate !== '' && endDate < monthEndate ? endDate : monthEndate;

    const params = arrayParamSerialization({
      locationIds,
      productIds,
      productClass,
    });

    const { workflowId } = this.state;
    try {
      daysUplift = await axios.get(`${KA_API_URL}/productvisit/attendance`, {
        params: {
          group_by: 'day',
          product_classes: params.productClass,
          location_ids: params.locationIds,
          product_ids: params.productIds,
          start_date: queryStartDate,
          end_date: queryEndDate,
          workflow_id: workflowId,
        },
      });

      if (daysUplift.status === 200) {
        daysUplift = daysUplift.data;
        await this.calculateCalendarData(daysUplift, false);
        await this.manipulateCalendar(value);
      }
    } catch (err) {
      await this.setState({
        noResults: true,
        noDataMessage: 'Problem loading months data. Please try again',
      });
    }
  };

  renderDemandGraphData = async selectedData => {
    const { locationWeather, locationHolidays, workflowId } = this.state;

    Promise.resolve(
      calculateDemandData(
        selectedData[0].start_datetime,
        selectedData[0].customer_entity_id,
        locationHolidays.get(selectedData[0].customer_entity_id),
        locationWeather.get(selectedData[0].customer_entity_id),
        selectedData[0].product_class,
        selectedData[0].product_id,
        workflowId,
      ),
    ).then(async data => {
      if (data !== null && data !== undefined) {
        if (data.demandData.length <= 1) {
          await this.setState({
            noResults: true,
            noDataMessage: 'No data available',
          });
        } else {
          await this.setState({
            forecastGraphData: data.demandData,
            graphDetails: data.graphDetails,
            noDataMessage: 'Please apply filters to view drivers',
          });
        }
      } else {
        await this.setState({
          noResults: true,
          noDataMessage: 'No data available',
        });
      }
    });
  };

  renderDailyDemandGraphData = async selectedData => {
    const { locationWeather, locationHolidays, workflowId } = this.state;
    const currentSelections = await this.calculateCurrentSelections();

    Promise.resolve(
      calculateDailySummaryDemandData(
        workflowId,
        currentSelections.productClasses,
        locationHolidays.get(selectedData.customer_entity_id),
        locationWeather.get(selectedData.customer_entity_id),
        selectedData.start_datetime,
        currentSelections.locationIds,
        currentSelections.productIds,
      ),
    ).then(async data => {
      if (data !== null && data !== undefined) {
        if (data.demandData.length <= 1) {
          await this.setState({
            noResults: true,
            noDataMessage: 'No data available',
          });
        } else {
          await this.setState({
            forecastGraphData: data.demandData,
            graphDetails: data.graphDetails,
            noDataMessage: 'Please apply filters to view drivers',
          });
        }
      } else {
        await this.setState({
          noResults: true,
          noDataMessage: 'No data available',
        });
      }
    });
  };

  getDailyTrProps = (state, rowInfo) => {
    const { dailyRecords, sortClicked, dailyPageNumber, selectedDailyDate } = this.state;
    if (rowInfo) {
      const maxPageNumber = dailyRecords / rowInfo.pageSize - 1;
      const index = maxPageNumber * rowInfo.pageSize;
      if (rowInfo.page === maxPageNumber && rowInfo.index === index && !sortClicked) {
        this.setState({
          dailyRecords: dailyRecords + 100,
          dailyPageNumber: dailyPageNumber + 1,
        });

        this.renderDailyTableData(true);
      }

      return {
        onClick: () => {
          this.setState({
            selectedDailyDate: rowInfo.original.start_datetime,
          });
          this.renderDailyDemandGraphData(rowInfo.original);
          this.setState({
            showForecasts: true,
          });
        },
        style: {
          cursor: 'pointer',
          background: rowInfo.original.start_datetime === selectedDailyDate ? '#c0c0c0' : null,
        },
      };
    }
    return {};
  };

  getTrProps = (state, rowInfo) => {
    const { detailRecords, detailSortClicked, detailPageNumber, selectedDetailedVisit } = this.state;
    if (rowInfo) {
      const maxPageNumber = detailRecords / rowInfo.pageSize - 1;
      const index = maxPageNumber * rowInfo.pageSize;
      if (rowInfo.page === maxPageNumber && rowInfo.index === index && !detailSortClicked) {
        this.setState({
          detailRecords: detailRecords + 100,
          detailPageNumber: detailPageNumber + 1,
        });

        this.renderDetailTableData(true);
      }
      return {
        onClick: () => {
          this.setState({
            selectedDetailedVisit: rowInfo.original.product_visit_id,
          });
          this.renderDemandGraphData([rowInfo.original]);
          this.setState({
            showForecasts: true,
          });
        },
        style: {
          cursor: 'pointer',
          background: rowInfo.original.product_visit_id === selectedDetailedVisit ? '#c0c0c0' : null,
        },
      };
    }
    return {};
  };

  handleKeyPress = () => {};

  renderTable = async (e, view) => {
    await this.setState({
      selectedDailyDate: '',
      selectedDetailedVisit: '',
      graphDetails: [],
      showForecasts: false,
      forecastGraphData: [],
      view,
    });
  };

  async renderMaxTransactions(maxPayDate, maxTimeStamp) {
    if (maxPayDate !== 'unknown') {
      maxPayDate = moment(maxPayDate).format('MM-DD-YYYY');
    }
    if (maxTimeStamp !== 'unknown') {
      maxTimeStamp += ` UTC`;
      maxTimeStamp = moment(maxTimeStamp).format('MM-DD-YYYY HH:mm:ss');
    }
    const timeZoneName = jstz.determine().name();
    const timeZoneAbbr = momentTZ()
      .tz(timeZoneName)
      .zoneAbbr();
    await this.setState({
      maxPayDate,
      maxTimeStamp,
      timeZoneAbbr,
    });
  }

  renderPage() {
    const {
      locationDonutData,
      productDonutData,
      showCalendar,
      view,
      forecast,
      detailSortObject,
      allLocations,
      statusMessage,
      minRows,
      dailySortObject,
      dailySummary,
      forecastGraphData,
      showForecasts,
      graphDetails,
    } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-sm-2" />
          <div style={{ marginLeft: '2vw', marginRight: '5vw' }}>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '18px',
                margin: '0px',
                marginBottom: '1vw',
                marginLeft: '6vw',
                marginTop: '2vw',
              }}
            >
              Locations
            </p>
            <Donut data={locationDonutData} id="locationDonut" classes="location" total="locationTotal" />
          </div>
          <div style={{ marginRight: '5vw' }}>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '18px',
                margin: '0px',
                marginBottom: '1vw',
                marginLeft: '6vw',
                marginTop: '2vw',
              }}
            >
              Products
            </p>
            <Donut data={productDonutData} id="productDonut" classes="product" total="productTotal" />
          </div>
          <div>
            <p style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '2vw', textAlign: 'center' }}>Dates</p>
            <div>{showCalendar ? this.renderCalendar() : <div />}</div>
          </div>
          <div className="col-sm-2" />
        </div>
        <br />
        <p style={{ textAlign: 'center' }}>
          <span
            className="btn btn-sm"
            style={{
              border: '1px solid black',
              backgroundColor: view === 'daily' ? 'rgb(0,175,79,0.7)' : 'white',
            }}
          >
            <span
              style={{
                cursor: 'pointer',
              }}
              role="button"
              tabIndex={0}
              onClick={e => {
                this.renderTable(e, 'daily');
              }}
              onKeyPress={() => {
                this.handleKeyPress();
              }}
            >
              <a
                style={{
                  color: 'black',
                }}
              >
                {' '}
                Daily Summary View
              </a>{' '}
            </span>{' '}
          </span>
          &nbsp;
          <span
            className="btn btn-sm"
            style={{
              border: '1px solid black',
              backgroundColor: view === 'detailed' ? 'rgb(0,175,79,0.7)' : 'white',
            }}
          >
            <span
              style={{ cursor: 'pointer' }}
              onClick={e => {
                this.renderTable(e, 'detailed');
              }}
              onKeyPress={() => {
                this.handleKeyPress();
              }}
              role="button"
              tabIndex={0}
            >
              <a
                style={{
                  color: 'black',
                }}
              >
                {' '}
                Detailed View
              </a>{' '}
            </span>
          </span>
        </p>

        <div className="row">
          <div className="col-sm-12">
            {view === 'detailed' ? (
              <ReactTable
                data={forecast}
                columns={[
                  {
                    Header: (
                      <div className="row">
                        <div className="col-4" />
                        <div
                          className="col-2"
                          style={{
                            marginTop: '5px',
                            marginLeft: '15px',
                          }}
                        >
                          {' '}
                          Location
                        </div>
                        <div className="col-2" style={{ marginLeft: '1px' }}>
                          {detailSortObject.location.is_sorted === true ? (
                            detailSortObject.location.direction === 'ASC' ? (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                            )
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    ),
                    headerStyle: {
                      fontSize: '16px',
                      height: '40px',
                      background: '#ffc500',
                      fontWeight: 'bold',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    },
                    id: 'location',
                    accessor: d => d.location,
                    Cell: row => (
                      <div style={{ cursor: 'pointer', textAlign: 'center' }}>
                        <span title={row.value}> {row.value}</span>
                      </div>
                    ),
                    sortable: allLocations.length > 1,
                    sortMethod: (a, b) => {
                      return a - b;
                    },
                    getFooterProps: () => {
                      return {
                        style: {
                          fontSize: '12px',
                        },
                      };
                    },
                  },
                  {
                    Header: (
                      <div className="row">
                        <div className="col-4" />
                        <div
                          className="col-2"
                          style={{
                            marginTop: '5px',
                            marginLeft: '15px',
                          }}
                        >
                          {' '}
                          Product
                        </div>
                        <div className="col-2" style={{ marginLeft: '' }}>
                          {detailSortObject.product_dimension_value.is_sorted === true ? (
                            detailSortObject.product_dimension_value.direction === 'ASC' ? (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                            )
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    ),
                    headerStyle: {
                      fontSize: '16px',
                      fontWeight: 'bold',
                      background: '#ffc500',
                      height: '40px',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    },
                    id: 'product_dimension_value',
                    accessor: d => d.product_dimension_value,
                    Cell: row => (
                      <div style={{ cursor: 'pointer', textAlign: 'center' }}>
                        <span title={row.value}>{row.value}</span>
                      </div>
                    ),
                    sortable: true,
                  },
                  {
                    Header: (
                      <div className="row">
                        <div className="col-4" />
                        <div
                          className="col-1"
                          style={{
                            marginTop: '5px',
                            marginLeft: '17px',
                          }}
                        >
                          {' '}
                          Date
                        </div>
                        <div className="col-2" style={{ marginLeft: '1px' }}>
                          {detailSortObject.start_datetime.is_sorted === true ? (
                            detailSortObject.start_datetime.direction === 'ASC' ? (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                            )
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    ),
                    headerStyle: {
                      background: '#ffc500',
                      paddingLeft: '0px',
                      height: '40px',
                      paddingRight: '0px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    },

                    id: 'start_datetime',
                    accessor: d => d.start_datetime,
                    Cell: row => (
                      <div style={{ textAlign: 'center', cursor: 'pointer' }}>{row.value.substring(0, 10)}</div>
                    ),
                    sortable: true,
                  },
                  {
                    Header: (
                      <div className="row">
                        <div className="col-4" />
                        <div
                          className="col-2"
                          style={{
                            marginTop: '5px',
                            marginLeft: '15px',
                          }}
                        >
                          {' '}
                          Attendance
                        </div>
                        <div className="col-2" style={{ marginLeft: '24px' }}>
                          {detailSortObject.forecast_quantity.is_sorted === true ? (
                            detailSortObject.forecast_quantity.direction === 'ASC' ? (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                            )
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    ),
                    headerStyle: {
                      background: '#ffc500',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      height: '40px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    },
                    id: 'forecast_quantity',
                    accessor: d => d.forecast_quantity,
                    Cell: row => (
                      <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                        {formatNumber(Math.round(row.value))}
                      </div>
                    ),
                    sortable: true,
                  },
                ]}
                getTheadThProps={(state, rowInfo, column) => {
                  return {
                    onClick: () => {
                      if (column.sortable) {
                        this.setState({
                          detailSortClicked: true,
                        });
                        this.calculateDetailSortData(column.id);
                      }
                    },
                  };
                }}
                getTrProps={this.getTrProps}
                noDataText={statusMessage}
                defaultPageSize={localStorage.getItem('pageSize') ? parseInt(localStorage.getItem('pageSize'), 10) : 10}
                pageSizeOptions={[4, 10, 20, 25, 50, 100]}
                PaginationComponent={pagination}
                minRows={minRows}
                className="-striped -highlight"
              />
            ) : (
              <div>
                <ReactTable
                  data={dailySummary}
                  columns={[
                    {
                      Header: (
                        <div className="row">
                          <div className="col-4" />
                          <div
                            className="col-2"
                            style={{
                              marginTop: '5px',
                              marginLeft: '15px',
                            }}
                          >
                            {' '}
                            Location
                          </div>
                          {dailySortObject.location.is_sorted === true ? (
                            dailySortObject.location.direction === 'ASC' ? (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                            )
                          ) : (
                            <div />
                          )}
                        </div>
                      ),
                      headerStyle: {
                        fontSize: '16px',
                        background: '#ffc500',
                        fontWeight: 'bold',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        height: '40px',
                      },
                      id: 'location',
                      accessor: d => d.location,
                      Cell: row => (
                        <div style={{ cursor: 'pointer', textAlign: 'center' }}>
                          <span title={row.value}> {row.value}</span>
                        </div>
                      ),
                      sortable: allLocations.length > 1,
                    },
                    {
                      Header: (
                        <div className="row">
                          <div className="col-4" />
                          <div
                            className="col-2"
                            style={{
                              marginTop: '5px',
                              marginLeft: '15px',
                            }}
                          >
                            {' '}
                            Product
                          </div>
                        </div>
                      ),
                      headerStyle: {
                        fontSize: '16px',
                        fontWeight: 'bold',
                        background: '#ffc500',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                      },
                      id: 'product_description',
                      accessor: d => (d.description === undefined ? 'All' : d.description),
                      Cell: row => (
                        <div style={{ cursor: 'pointer', textAlign: 'center' }}>
                          <span title={row.value}>{row.value}</span>
                        </div>
                      ),
                      sortable: false,
                    },
                    {
                      Header: (
                        <div className="row">
                          <div className="col-4" />
                          <div className="col-1" style={{ marginTop: '5px', marginLeft: '17px' }}>
                            {' '}
                            Date
                          </div>
                          <div className="col-2">
                            {dailySortObject.start_datetime.is_sorted === true ? (
                              dailySortObject.start_datetime.direction === 'ASC' ? (
                                <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                              ) : (
                                <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                              )
                            ) : (
                              <div />
                            )}
                          </div>
                        </div>
                      ),
                      headerStyle: {
                        background: '#ffc500',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },

                      id: 'start_datetime',
                      accessor: d => d.start_datetime,
                      Cell: row => (
                        <div style={{ cursor: 'pointer', textAlign: 'center' }}>
                          <span title={row.value}> {row.value.substring(0, 10)}</span>
                        </div>
                      ),
                      sortable: true,
                    },
                    {
                      Header: (
                        <div className="row">
                          <div className="col-4" />
                          <div
                            className="col-2"
                            style={{
                              marginTop: '5px',
                              marginLeft: '15px',
                            }}
                          >
                            {' '}
                            Attendance
                          </div>
                          <div className="col-2" style={{ marginLeft: '24px' }}>
                            {dailySortObject.attendance_uplift.is_sorted === true ? (
                              dailySortObject.attendance_uplift.direction === 'ASC' ? (
                                <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                              ) : (
                                <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                              )
                            ) : (
                              <div />
                            )}
                          </div>
                        </div>
                      ),
                      headerStyle: {
                        background: '#ffc500',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },
                      id: 'attendance_uplift',
                      accessor: d => d.attendance_uplift,
                      Cell: row => (
                        <div style={{ cursor: 'pointer', textAlign: 'center' }}>
                          {formatNumber(Math.round(row.value))}
                        </div>
                      ),
                      sortable: true,
                    },
                  ]}
                  getTheadThProps={(state, rowInfo, column) => {
                    return {
                      onClick: () => {
                        if (column.sortable) {
                          this.setState({
                            sortClicked: true,
                          });
                          this.calculateDailySortData(column.id);
                        }
                      },
                    };
                  }}
                  getTrProps={this.getDailyTrProps}
                  noDataText={statusMessage}
                  defaultPageSize={
                    localStorage.getItem('pageSize') ? parseInt(localStorage.getItem('pageSize'), 10) : 10
                  }
                  pageSizeOptions={[4, 10, 20, 25, 50, 100]}
                  PaginationComponent={pagination}
                  minRows={minRows}
                  className="-striped -highlight"
                />
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-1" />
          {showForecasts ? (
            <div className="col-sm-3" style={{ marginTop: '2vw' }}>
              <ReactTable
                data={graphDetails}
                columns={[
                  {
                    Header: <div>Driver</div>,
                    headerStyle: {
                      background: '#ffc500',
                      fontWeight: 'bold',
                      border: '1px solid white',
                      boxSizing: 'border-box',
                      fontSize: '14px',
                    },
                    id: 'cur_price',
                    accessor: d => d.driver,
                    Cell: row => <div style={{ textAlign: 'center' }}>{row.value}</div>,
                    sortMethod: (a, b) => {
                      return a.toUpperCase() > b.toUpperCase() ? -1 : 1;
                    },
                  },
                  {
                    Header: <div>Value</div>,
                    headerStyle: {
                      background: '#ffc500',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      border: '1px solid white',
                      boxSizing: 'border-box',
                    },

                    id: 'value',
                    accessor: d => d.value,
                    Cell: row => <div style={{ textAlign: 'center' }}>{row.value}</div>,
                  },
                ]}
                getTdProps={(state, rowInfo) => {
                  if (typeof rowInfo !== 'undefined') {
                    return {};
                  }
                  return {};
                }}
                getTrProps={() => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (handleOriginal) {
                        handleOriginal();
                      }
                    },
                    style: {
                      background: '#e8eef6',
                    },
                  };
                }}
                showPagination={false}
                pageSize={4}
                noDataText="Loading Data"
                className="-highlight"
              />
            </div>
          ) : (
            <div />
          )}
          <div className="col-sm-1" />
          {showForecasts ? (
            <div className="col-sm-6">
              <Bar data={forecastGraphData} />
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      selectedLocation,
      allLocations,
      selectedProduct,
      allProducts,
      startDate,
      endDate,
      focusedInput: focusedInputState,
      dataLoaded,
      noDataMessage,
      noResults,
      recommendationDetailsFooterShow,
      maxTimeStamp,
      maxPayDate,
      timeZoneAbbr,
    } = this.state;

    return (
      <React.Fragment>
        <Header />
        <div className="container-fluid common">
          <div />
          <div className="row">
            <div className="col-sm-1" />
            <div className="col-sm-4">
              <p className="filter-labels">Location</p>
              <Select
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedLocation}
                onChange={this.handleLocationChange}
                options={allLocations}
              />
            </div>
            <div className="col-sm-3">
              <p className="filter-labels">Product</p>
              <Select
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedProduct}
                onChange={this.handleProductChange}
                options={allProducts}
              />
            </div>
            <div className="">
              <p className="filter-labels">Date Range</p>
              <DateRangePicker
                startDate={startDate}
                startDateId="startDate"
                small
                endDate={endDate}
                endDateId="endDate"
                onDatesChange={this.handleDatesChange}
                focusedInput={focusedInputState}
                onFocusChange={focusedInput => this.handleFocusedInput(focusedInput)}
              />
            </div>
            <div className="col-sm-1">
              <p className="filter-labels" style={{ color: 'white' }}>
                .
              </p>
              <ProfitRoverSecondaryButton onClick={this.applyFilters}>Apply</ProfitRoverSecondaryButton>
            </div>
          </div>
          <div className="heading" style={{ margin: '0px' }}>
            <h4 style={{ margin: '0px', marginTop: '1vw' }}>
              <span id="heading-label">Dashboard: &nbsp;</span> <span>Attendance Forecast</span>
            </h4>
          </div>
          {dataLoaded ? (
            this.renderPage()
          ) : noResults ? (
            <div style={{ marginTop: '2vw' }}>
              <img
                alt="no-data"
                style={{ width: '100px', margin: 'auto', display: 'block' }}
                src="https://img.icons8.com/cute-clipart/64/000000/nothing-found.png"
              />
              <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '1vw', fontSize: '18px' }}>
                {noDataMessage}
              </p>
            </div>
          ) : (
            <CenteredProfitRoverSpinner />
          )}
        </div>
        {recommendationDetailsFooterShow && maxTimeStamp !== '' && maxPayDate !== '' ? (
          <div>
            <div
              style={{
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              Results last refreshed: {maxTimeStamp} {timeZoneAbbr}
            </div>
            <div
              style={{
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              Results based on ticket sales through: {maxPayDate}
            </div>
          </div>
        ) : (
          <div />
        )}
      </React.Fragment>
    );
  }
}

export default connect(null, {})(EOKaizenAttendance);
