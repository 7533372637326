/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useQueryClient } from 'react-query';
import _ from 'lodash';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Overlay, Tooltip } from 'react-bootstrap';
import { PaymentFailedError } from './checkoutErrors';
import { SubmissionErrorModal, UnableToMakeChangesModal } from './CheckoutModals';
import { useSwitchToAnnualPlan } from './CheckoutSwitchToAnnualPlanHooks';
import PaymentSuccessDetails, { ChargedPaymentSuccess } from './PaymentSuccessDetails';
import {
  BubbleLabel,
  CheckoutHeaderBar,
  ConfirmChangesButton,
  GoBackButton,
  PageTitle,
  PaymentFailedMessage,
  PaymentMethodSection,
  SpreadText,
} from './common/CommonSubscriptionComponents';
import ManagePaymentMethodModal from './ManagePaymentMethodModal';
import LoadingIndicator from '../common/LoadingIndicator';
import ProfitRoverTermsOfUseLink from '../../generic/ProfitRoverTermsOfUseLink';
import { ProfitRoverModalCard, ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';
import { StripeBillingScheme } from '../../generic/subscriptions/SubscriptionConstants';
import { useProductPrices } from '../../registration/customerRegistrationHooks';
import { formatCurrency, nullSafeFormatCurrency } from '../../util/format';
import { BACKGROUND_GRAY, LIGHT_GREEN } from '../../../colors';
import { usePayInvoice } from '../../../data-access/mutation/subscriptions';
import { useProfitRoverSubscriptionPrices } from '../../../data-access/query/stripe';
import {
  BILLING_INFO_QUERY_KEY_BASE,
  useBillingInfo,
  useSwitchToAnnualPlanPreviewInvoice,
} from '../../../data-access/query/subscriptions';
import { gaEmitPayAmountDuePlanButtonClick } from '../../../google-analytics/subscriptionSettings';

const DEACTIVATION_NOTICE = ` You are also receiving a credit for any locations that are scheduled for deactivation\
 at the end of your current billing cycle, and you will lose access to those locations immediately after payment.`;

const paymentDueTodayInfo = previewInvoice => {
  const dueToday = nullSafeFormatCurrency(previewInvoice?.amount_due / 100);
  const tax = nullSafeFormatCurrency(previewInvoice?.tax / 100);

  const { lines: lineItems = [] } = previewInvoice;

  const creditForRemainingTime = lineItems.find(lineItem => lineItem.price?.recurring?.interval === 'month');
  const creditApplied = nullSafeFormatCurrency(creditForRemainingTime?.amount / 100);

  return {
    creditApplied,
    dueToday,
    tax,
  };
};

const nextBillingDetailsInfo = (switchPreview, annualPrice, isTieredCustomer) => {
  const previewInvoice = switchPreview?.preview_invoice ?? {};
  const { current_quantity: currentQuantity } = switchPreview ?? {};
  let nextQuantity;
  if (isTieredCustomer) {
    const { lines: nextCycleLineItems = [] } = previewInvoice;
    const [nextCycle] = _.takeRight(nextCycleLineItems, 1);
    const nextCycleAmount = nextCycle?.amount;

    if (nextCycleAmount === 0) {
      /**
       * This case means we're switching to the annual plan when we already have scheduled deactivations that will take
       * us down to the free tier (i.e. we're scheduled to reduce the number of active locations to just the single
       * free location). If we do this we will immediately apply those scheduled deactivations and switch the customer
       * to the annual plan.
       */
      nextQuantity = 0;
      annualPrice = 0;
    } else {
      nextQuantity = nextCycle?.quantity;
    }
  } else {
    nextQuantity = switchPreview?.next_quantity;
  }

  annualPrice = parseInt(annualPrice, 10);
  const annualPriceToDisplay = nullSafeFormatCurrency(annualPrice);
  const nextBillingAmount = formatCurrency(nextQuantity * annualPrice, true);

  const { lines: lineItems = [] } = previewInvoice;

  const debitForAnnualPlan = lineItems.find(lineItem => lineItem.price?.recurring?.interval === 'year');
  let nextBillingDate = debitForAnnualPlan?.period?.end;
  nextBillingDate = moment.unix(nextBillingDate).format('MMM Do, YYYY');

  return {
    annualPriceToDisplay,
    currentQuantity,
    nextBillingAmount,
    nextBillingDate,
    nextQuantity,
    unproratedBillingAmount: nullSafeFormatCurrency(annualPrice * nextQuantity),
    unproratedBillingAmountNumber: annualPrice * nextQuantity,
  };
};

const SUCCESS_TITLE = 'Successfully Upgraded To Annual Plan';

const CheckoutSwitchToAnnualPlan = ({ subscriptionId, setCheckingOutAnnualPlan }) => {
  const tooltipRef = React.useRef(null);
  const [showCreditTooltip, setShowCreditTooltip] = React.useState(false);

  const queryClient = useQueryClient();

  const defaultPaymentMethodState = useBillingInfo(subscriptionId, 'defaultPaymentMethod');
  const { default_payment_method: defaultPaymentMethod } = defaultPaymentMethodState?.data ?? {};
  const hasPaymentMethod = defaultPaymentMethod != null;

  const billingInfoState = useBillingInfo(subscriptionId);
  const { data: billingInfo = {}, isFetching: billingInfoLoading } = billingInfoState;

  const { price_nickname: priceName, billing_scheme: billingScheme, coupon } = billingInfo?.plan_info ?? {};
  const isTieredCustomer = billingScheme === StripeBillingScheme.TIERED;

  const { data: priceMap = {}, isLoading: isLoadingPrices } = useProfitRoverSubscriptionPrices(priceName, {
    enabled: !!priceName,
  });
  const prices = priceMap[priceName];

  const { annualPrice } = useProductPrices(prices, coupon);

  const {
    data: switchPreview = {},
    isLoading: isLoadingPreviewInvoice,
    error: previewInvoiceError,
  } = useSwitchToAnnualPlanPreviewInvoice(subscriptionId, prices?.annual?.id);
  const previewInvoice = switchPreview?.preview_invoice ?? {};

  const {
    onConfirmChanges,
    isSubmitting,
    paymentCompletionDetails,
    setPaymentCompletionDetails,
    showPaymentFailed,
    setShowPaymentFailed,
    submissionError,
  } = useSwitchToAnnualPlan(subscriptionId, prices?.annual?.id);

  const onSwitchToAnnualPlan = () => {
    gaEmitPayAmountDuePlanButtonClick();
    onConfirmChanges();
  };

  const reattemptPaymentMutation = usePayInvoice({
    onSuccess: ({ payment_email_address: paymentEmailAddress, paid_invoice: paidInvoice }) =>
      setPaymentCompletionDetails(new ChargedPaymentSuccess({ paidInvoice, paymentEmailAddress })),
  });

  const reattemptPayment = async () =>
    reattemptPaymentMutation.mutateAsync({ subscriptionId, invoiceId: submissionError.invoiceId });

  const { creditApplied, dueToday, tax } = paymentDueTodayInfo(previewInvoice);
  const {
    annualPriceToDisplay,
    currentQuantity,
    nextBillingAmount,
    nextBillingDate,
    nextQuantity,
    unproratedBillingAmount: subtotal,
    unproratedBillingAmountNumber: subtotalNumber,
  } = nextBillingDetailsInfo(switchPreview, annualPrice, isTieredCustomer);

  const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] = React.useState(false);
  const onHide = () => setShowUpdatePaymentMethodModal(false);

  const blurStyles = { filter: showUpdatePaymentMethodModal ? 'blur(8px)' : undefined };

  const isLoading = isLoadingPreviewInvoice || isLoadingPrices || billingInfoLoading;
  const changesInProgress = isSubmitting || reattemptPaymentMutation.isLoading;
  const buttonDisabled = isLoading || changesInProgress || showPaymentFailed || !hasPaymentMethod;

  const locationsScheduledToBeDeactivated = !isLoading && currentQuantity !== nextQuantity;

  const quantityToDisplay = isTieredCustomer && nextQuantity >= 2 ? nextQuantity - 1 : nextQuantity;

  return (
    <>
      <CheckoutHeaderBar />
      <div
        className="checkout-subscription-changes monthly-to-annual"
        style={{ backgroundColor: BACKGROUND_GRAY, ...blurStyles }}
      >
        <UnableToMakeChangesModal show={previewInvoiceError != null} />
        <SubmissionErrorModal show={submissionError != null && !(submissionError instanceof PaymentFailedError)} />
        <ManagePaymentMethodModal
          show={showUpdatePaymentMethodModal}
          subscriptionId={subscriptionId}
          onHide={onHide}
          onSuccess={async () => {
            await queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId, 'defaultPaymentMethod']);
            setShowPaymentFailed(false);
            onHide();
          }}
        />
        <PageTitle>Switch To Annual Plan</PageTitle>
        <div className="section-layout">
          <div className="labeled-bubble-container">
            {paymentCompletionDetails != null ? (
              <PaymentSuccessDetails
                title={SUCCESS_TITLE}
                subscriptionId={subscriptionId}
                paymentCompletionDetails={paymentCompletionDetails}
              />
            ) : (
              <>
                <BubbleLabel>Payment Due Today</BubbleLabel>
                <ProfitRoverModalCard>
                  <ProfitRoverModalMain className="left-main">
                    {showPaymentFailed && (
                      <PaymentFailedMessage onClick={() => setShowUpdatePaymentMethodModal(true)} />
                    )}

                    <p className="ssl-text">
                      <FontAwesomeIcon icon={faLock} color={LIGHT_GREEN} />
                      This is a secure SSL Encrypted Payment
                    </p>
                    <PaymentMethodSection
                      defaultPaymentMethodState={defaultPaymentMethodState}
                      paymentFailed={showPaymentFailed}
                      onClick={() => setShowUpdatePaymentMethodModal(true)}
                    />
                    <h6 className="locations-count">Switching Plans</h6>
                    <div className="emphasis-area">
                      <SpreadText>
                        <p>Subtotal</p>
                        <p>{isLoadingPreviewInvoice ? <LoadingIndicator /> : subtotal}</p>
                      </SpreadText>
                      {coupon && (
                        <SpreadText>
                          <>
                            <p>Promo Applied</p>
                            <span>
                              {isLoadingPreviewInvoice ? (
                                <LoadingIndicator />
                              ) : (
                                <span>- {nullSafeFormatCurrency(subtotalNumber * (coupon.percent_off / 100))}</span>
                              )}
                            </span>
                          </>
                        </SpreadText>
                      )}
                      <SpreadText>
                        <div className="credit-applied-left">
                          <p>Credit Applied</p>
                          <div
                            className="credit-tooltip-icon"
                            ref={tooltipRef}
                            onClick={() => setShowCreditTooltip(!showCreditTooltip)}
                            role="button"
                          >
                            ?
                          </div>

                          <Overlay target={tooltipRef.current} show={showCreditTooltip} placement="bottom">
                            {props => (
                              <Tooltip className="credit-applied-tooltip" {...props}>
                                You are receiving a credit for the remainder of the month that you did not use with your
                                monthly plan.
                              </Tooltip>
                            )}
                          </Overlay>
                        </div>

                        <span>
                          {isLoadingPreviewInvoice ? (
                            <LoadingIndicator />
                          ) : (
                            <span className="credit-applied">{creditApplied}</span>
                          )}
                        </span>
                      </SpreadText>
                      <SpreadText className="sales-tax">
                        <p>Sales Tax</p>
                        <span>{isLoadingPreviewInvoice ? <LoadingIndicator /> : <span>{tax}</span>}</span>
                      </SpreadText>
                      <SpreadText className="due-today">
                        <p>Due Today</p>
                        <span>{isLoadingPreviewInvoice ? <LoadingIndicator /> : <span>{dueToday}</span>}</span>
                      </SpreadText>
                    </div>
                  </ProfitRoverModalMain>
                  <ProfitRoverModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                    <GoBackButton
                      onClick={() => setCheckingOutAnnualPlan(false)}
                      paymentFailed={showPaymentFailed}
                      subscriptionId={subscriptionId}
                      disabled={isSubmitting}
                    />
                    <ConfirmChangesButton
                      customerWillBeCharged
                      changesInProgress={changesInProgress}
                      disabled={buttonDisabled}
                      onConfirmChanges={onSwitchToAnnualPlan}
                      onTryAgain={reattemptPayment}
                      submissionError={submissionError}
                    />
                  </ProfitRoverModalFooter>
                </ProfitRoverModalCard>
              </>
            )}
          </div>
          <div className="labeled-bubble-container">
            <BubbleLabel>Next Billing Details</BubbleLabel>
            <ProfitRoverModalCard className="next-billing-details">
              <ProfitRoverModalMain className="right-main">
                <SpreadText>
                  <p>Annual Billing Per Location</p>
                  <p>{isLoading ? <LoadingIndicator /> : annualPriceToDisplay}</p>
                </SpreadText>
                <SpreadText>
                  <p>Paid Locations</p>
                  <p>{isLoading ? <LoadingIndicator /> : `x ${quantityToDisplay}`}</p>
                </SpreadText>

                <hr />

                <SpreadText className="next-billing-amount">
                  <p>
                    <strong>Next Billing Amount</strong>
                  </p>
                  {isLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <p style={{ marginRight: -29 }}>
                      <strong>{nextBillingAmount}</strong>
                      <span className="plus-tax-text"> + tax</span>
                    </p>
                  )}
                </SpreadText>
                <SpreadText className="scheduled-for">
                  <p>
                    <i>Scheduled for</i>
                  </p>
                  <p>
                    <i>{nextBillingDate}</i>
                  </p>
                </SpreadText>
              </ProfitRoverModalMain>
            </ProfitRoverModalCard>
          </div>
          {paymentCompletionDetails == null && (
            <div className="explanatory-text-container">
              <p>
                You are receiving a credit to your new total amount for any unused time on your monthly plan.
                {locationsScheduledToBeDeactivated && DEACTIVATION_NOTICE} Your new annual billing period will begin
                today.
              </p>
              <p>
                By updating your subscription, you authorize us to make regularly scheduled charges to your credit card.
                You will be charged each billing period for the amount due for that period. For more information
                regarding billing, please review the <ProfitRoverTermsOfUseLink>Terms of Use</ProfitRoverTermsOfUseLink>
                .
              </p>
              <p>
                You are switching to an annual plan. Your subscription will automatically renew exactly 1 year from
                today, unless you indicate otherwise. NOTE: You are paying ahead for an entire year, so you will not
                receive a credit or refund if you cancel before your next billing date.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckoutSwitchToAnnualPlan;
