/* eslint-disable import/prefer-default-export */
import React from 'react';
import DimValueMapping from '../dimensionValuesSettings/DimValueMapping';

const initializeDimValueMappings = rawDimensionValues =>
  rawDimensionValues.filter(({ value }) => value != null).map(rawDimValue => new DimValueMapping(rawDimValue));

const replaceValue = (updatedRawDimensionValue, updatedRawDimensionValues) => {
  const indexOfOldValue = updatedRawDimensionValues.findIndex(
    dimValue => dimValue.getValue() === updatedRawDimensionValue.getValue(),
  );
  updatedRawDimensionValues.splice(indexOfOldValue, 1, updatedRawDimensionValue);
};

/**
 * Provides an API for managing the state of raw dimension value "row" objects present in the Table.
 */
export const useRawDimValueRowApi = (rawDimensionValues, selectedDimensionId) => {
  const [tableRawDimValues, setTableRawDimValues] = React.useState(() =>
    initializeDimValueMappings(rawDimensionValues),
  );

  React.useEffect(() => {
    const rows = initializeDimValueMappings(rawDimensionValues);
    setTableRawDimValues(rows);
  }, [selectedDimensionId, rawDimensionValues]);

  const editedRawValues = tableRawDimValues.filter(rawValue => rawValue.isEdited());
  const changesPending = editedRawValues.length > 0;

  const setIsEditing = (rawDimensionValue, isEditing) => {
    const updatedDimensionValues = [...tableRawDimValues];
    const updatedDimensionValue = DimValueMapping.from(rawDimensionValue);

    updatedDimensionValue.setIsEditing(isEditing);

    replaceValue(updatedDimensionValue, updatedDimensionValues);
    setTableRawDimValues(updatedDimensionValues);
  };

  const updateMapping = (rawDimensionValue, masterDimensionValue) => {
    const updatedDimensionValues = [...tableRawDimValues];
    const updatedDimensionValue = DimValueMapping.from(rawDimensionValue);

    updatedDimensionValue.setDimensionValueId(masterDimensionValue.id);

    replaceValue(updatedDimensionValue, updatedDimensionValues);
    setTableRawDimValues(updatedDimensionValues);
  };

  const updateIsIgnored = (rawDimensionValue, isIgnored) => {
    const updatedDimensionValues = [...tableRawDimValues];
    const updatedDimensionValue = DimValueMapping.from(rawDimensionValue);

    updatedDimensionValue.setIsIgnore(isIgnored);

    replaceValue(updatedDimensionValue, updatedDimensionValues);
    setTableRawDimValues(updatedDimensionValues);
  };

  const resetToOriginal = rawDimensionValue => {
    const updatedDimensionValues = [...tableRawDimValues];
    const updatedDimensionValue = DimValueMapping.from(rawDimensionValue);

    updatedDimensionValue.resetToOriginal();

    replaceValue(updatedDimensionValue, updatedDimensionValues);
    setTableRawDimValues(updatedDimensionValues);
  };

  return { tableRawDimValues, setIsEditing, updateMapping, updateIsIgnored, resetToOriginal, changesPending };
};
