import React from 'react';
import _ from 'lodash';
import axios from '../../utils/axios-client';
import { KA_API_URL } from '../../config/baseUrl';
import { fetchDimensions, fetchDimensionTypes } from '../util/mapProductDimensions';

const fetchLocations = () => {
  return axios.get(`${KA_API_URL}/locations`);
};

/**
 * @deprecated
 */
export const useDimensions = (includeDerived = true) => {
  const [loading, setLoading] = React.useState(true);
  const [dimensions, setDimensions] = React.useState([]);

  const doFetch = React.useCallback(async () => {
    const data = await fetchDimensions();
    const dataArray = data?.data ?? [];

    if (!includeDerived) {
      const nonDerivedDimensions = dataArray.filter(dimension => dimension.derived === false);
      setDimensions(nonDerivedDimensions);
    } else {
      setDimensions(dataArray);
    }
    setLoading(false);
  }, [includeDerived]);

  React.useEffect(() => {
    doFetch();
  }, [doFetch]);

  return {
    dimensions,
    loading,
    refetchDimensions: doFetch,
  };
};

export const useCreatableDimensionTypes = () => {
  const [loading, setLoading] = React.useState(true);
  const [customerIndustries, setCustomerIndustries] = React.useState([]);
  const [dimensionTypes, setDimensionTypes] = React.useState([]);

  React.useEffect(() => {
    const getCustomerIndustries = async () => {
      const locations = await fetchLocations();

      const industries = _.uniqBy(locations.data ?? [], location => location.industry).map(
        location => location.industry,
      );

      setCustomerIndustries(industries);
    };

    getCustomerIndustries();
  }, []);

  React.useEffect(() => {
    if (customerIndustries.length > 0) {
      const doFetch = async () => {
        const fetchedDimTypes = await fetchDimensionTypes();

        const nonDerivedDimTypes = fetchedDimTypes.data.filter(dimType => dimType.derived === false);

        // Only show dimension types that exist in industries that the customer belongs to
        const industrySpecificDimTypes = nonDerivedDimTypes.filter(dimType =>
          customerIndustries.includes(dimType.industry),
        );

        const uniqueDimTypes = _.uniqBy(industrySpecificDimTypes, dimType => {
          return dimType.dimension_type;
        });

        setDimensionTypes(uniqueDimTypes);
        setLoading(false);
      };

      doFetch();
    }
  }, [customerIndustries]);

  return {
    loading,
    dimensionTypes,
  };
};

export const useCreatableDimensionTypesForIndustry = industry => {
  const [loading, setLoading] = React.useState(true);
  const [dimensionTypes, setDimensionTypes] = React.useState([]);

  React.useEffect(() => {
    const doFetch = async () => {
      const fetchedDimTypes = await fetchDimensionTypes();

      const nonDerivedDimTypes = fetchedDimTypes.data.filter(dimType => dimType.derived === false);

      // Only show dimension types for a specific industry
      const industrySpecificDimTypes = nonDerivedDimTypes.filter(dimType => dimType.industry === industry);

      const uniqueDimTypes = _.uniqBy(industrySpecificDimTypes, dimType => {
        return dimType.dimension_type;
      });

      setDimensionTypes(uniqueDimTypes);
      setLoading(false);
    };

    doFetch();
  }, [industry]);

  return {
    loading,
    dimensionTypes,
  };
};

/**
 * "customer_id" gets set in Local Storage via login.js. After the substantial refactor to remove the use of
 * "customer_id" from almost every API route, it is highly unlikely that this Hook is still necessary.
 *  This comment and Hook are kept here for historical record.
 */
export const useCustomerId = () => {
  return parseInt(localStorage.getItem('customer_id'), 10);
};
