import React from 'react';
import './home.css';
import Header from '../header/header';

const Home = props => {
  const {
    history,
    location: { search },
  } = props;
  history.push({
    pathname: '/login',
    search,
  });
  return (
    <React.Fragment>
      <Header />
      <div id="homepage" />
    </React.Fragment>
  );
};

export default Home;
