import { noop } from 'lodash';
import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import IdleTimer from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { ProfitRoverDestructiveButton, ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';

const TIMER_AUTO_LOGOUT_INACTIVITY_MILLISECONDS = parseInt(
  process.env.REACT_APP_AUTO_LOGOUT_TIMER_INACTIVITY_MILLI_SECS,
  10,
);
const TIMER_POPUP_WAIT_MILLISECONDS = parseInt(process.env.REACT_APP_AUTO_LOGOUT_TIMER_POPUP_WAIT_MILLI_SECS, 10);

const IdleTimerContainer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);

  const history = useHistory();

  const logMeOut = async () => {
    setModalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);

    history.push('/logout');
  };

  const onIdle = () => {
    setModalIsOpen(true);
    sessionTimeoutRef.current = setTimeout(logMeOut, TIMER_POPUP_WAIT_MILLISECONDS);
  };

  const stayActive = () => {
    setModalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
  };

  const minutesUntilTimeout = TIMER_POPUP_WAIT_MILLISECONDS / 1000 / 60;
  const formattedMinutes = minutesUntilTimeout ? minutesUntilTimeout.toFixed(0) : 'a few';

  return (
    <>
      <IdleTimer ref={idleTimerRef} timeout={TIMER_AUTO_LOGOUT_INACTIVITY_MILLISECONDS} onIdle={onIdle} />
      <Modal show={modalIsOpen} onHide={noop}>
        <Modal.Body style={{ fontSize: '16px' }}>
          <div>Your session will end in {formattedMinutes} minute(s) due to inactivity.</div>
          <div>Click &quot;Continue&quot; to continue your session.</div>
        </Modal.Body>
        <Modal.Footer>
          <ProfitRoverDestructiveButton onClick={logMeOut}>Logout</ProfitRoverDestructiveButton>
          <ProfitRoverPrimaryButton onClick={stayActive}>Continue</ProfitRoverPrimaryButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IdleTimerContainer;
