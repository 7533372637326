// eslint-disable-next-line max-len
const EMAIL_ADDRESS_REGEX = /^[-!#$%&'*+\\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

const PHONE_NUMBER_REGEX = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\\.]{0,1}[0-9]{3}[-\s\\.]{0,1}[0-9]{4}$/;

const WEBSITE_ADDRESS_REGEX = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]+)?/;

const ZIPCODE_REGEX = /^\d{5}$|^\d{5}-\d{4}$/;

// Canadian postal codes
const POSTAL_CODE_REGEX = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

export const isValidEmailAddress = emailAddress => EMAIL_ADDRESS_REGEX.test(emailAddress);

export const isValidPhoneNumber = phoneNumber => PHONE_NUMBER_REGEX.test(phoneNumber);

export const isValidWebsiteAddress = websiteAddress => WEBSITE_ADDRESS_REGEX.test(websiteAddress);

export const isValidZipCode = zipCode => ZIPCODE_REGEX.test(zipCode);

export const isValidPostalCode = postalCode => POSTAL_CODE_REGEX.test(postalCode);
