import React from 'react';
import { Modal } from 'react-bootstrap';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import { ProfitRoverModalCard, ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';

const UserManagementModal = ({ show, onHide, message, title }) => {
  return (
    <Modal centered size="md" show={show} onHide={onHide}>
      <ProfitRoverModalCard>
        <ProfitRoverModalMain className="pl-3 pb-5">
          <h3>{title}</h3>
          <p>{message}</p>
        </ProfitRoverModalMain>
        <ProfitRoverModalFooter className="w-100 d-flex justify-content-end">
          <ProfitRoverPrimaryButton onClick={onHide}>OK</ProfitRoverPrimaryButton>
        </ProfitRoverModalFooter>
      </ProfitRoverModalCard>
    </Modal>
  );
};

export default UserManagementModal;
