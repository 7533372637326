import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Pricing Tab';

const ACTIONS = {
  IMPACTS_FOR_NEXT_7_CLICK: 'Next 7 days dropdown click',
  IMPACTS_FOR_NEXT_30_CLICK: 'Next 30 days dropdown click',
  IMPACTS_FOR_NEXT_90_CLICK: 'Next 90 days dropdown click',
  IMPACTS_FOR_NEXT_365_CLICK: 'Next 365 days dropdown click',
  IMPACTS_FOR_CURRENT_MONTH_CLICK: 'Current month dropdown click',
  IMPACTS_FOR_CURRENT_QUARTER_CLICK: 'Current quarter dropdown click',
  IMPACTS_FOR_CURRENT_YEAR_CLICK: 'Current year dropdown click',
  IMPACTS_FOR_NEXT_MONTH_CLICK: 'Next month dropdown click',
  IMPACTS_FOR_NEXT_QUARTER_CLICK: 'Next quarter dropdown click',
  IMPACTS_FOR_NEXT_YEAR_CLICK: 'Next year dropdown click',

  PRICE_CHANGE_SUMMARY_TAB_CLICK: 'Price Change Summary tab click',

  BREAKDOWN_IMPACT_DROPDOWN_CLICK: 'Breakdown Impact dropdown click',
  BREAKDOWN_DIMENSION_DROPDOWN_CLICK: 'Breakdown Dimension dropdown click',

  AVERAGE_PRICE_CHANGE_DROPDOWN_CLICK: 'Average Price Change dropdown click',

  SEE_PRICING_FOR_LOCATION_DROPDOWN_CLICK: 'See Pricing for Location dropdown click',
  CHANGE_TIME_PERIOD_CLICK: 'Change time period click',
  DOWNLOAD_ROVER_RECS_BUTTON_CLICK: 'Download Rover Recommendations button click',
  SUMMARY_BUTTON_CLICK: 'Summary button click',
  DETAIL_BUTTON_CLICK: 'Detail button click',
};

const LABELS = {
  IMPACTS_DROPDOWN: 'Show Me Impacts for dropdown',
  IMPACT_SUMMARY_TAB: 'Impact Summary tab',
  PRICE_CHANGE_SUMMARY_TAB: 'Price Change Summary tab',
  PRICE_RECOMMENDATIONS_TABLE: 'Price Recommendations table',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitImpactsDropdownClick = dateRangeValue => {
  const dateRange = dateRangeValue
    .replace(/([a-zA-Z])([0-9])/g, '$1_$2')
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toUpperCase(); // e.g. 'next90' -> 'NEXT_90' | 'currentMonth' -> 'CURRENT_MONTH'
  const action = ACTIONS[`IMPACTS_FOR_${dateRange}_CLICK`];
  emitEvent({ action, label: LABELS.IMPACTS_DROPDOWN });
};

export const gaEmitPriceChangeSummaryTabClick = () => {
  emitEvent({ action: ACTIONS.PRICE_CHANGE_SUMMARY_TAB_CLICK });
};

export const gaEmitBreakdownImpactDropdownClick = () => {
  emitEvent({ action: ACTIONS.BREAKDOWN_IMPACT_DROPDOWN_CLICK, label: LABELS.IMPACT_SUMMARY_TAB });
};

export const gaEmitBreakdownDimensionDropdownClick = () => {
  emitEvent({ action: ACTIONS.BREAKDOWN_DIMENSION_DROPDOWN_CLICK, label: LABELS.IMPACT_SUMMARY_TAB });
};

export const gaEmitAveragePriceChangeDropdownClick = () => {
  emitEvent({ action: ACTIONS.AVERAGE_PRICE_CHANGE_DROPDOWN_CLICK, label: LABELS.PRICE_CHANGE_SUMMARY_TAB });
};

export const gaEmitSeePricingForLocationDropdownClick = () => {
  emitEvent({ action: ACTIONS.SEE_PRICING_FOR_LOCATION_DROPDOWN_CLICK, label: LABELS.PRICE_RECOMMENDATIONS_TABLE });
};

export const gaEmitChangeTimePeriodClick = () => {
  emitEvent({ action: ACTIONS.CHANGE_TIME_PERIOD_CLICK, label: LABELS.PRICE_RECOMMENDATIONS_TABLE });
};

export const gaEmitDownloadRoverRecsButtonClick = () => {
  emitEvent({ action: ACTIONS.DOWNLOAD_ROVER_RECS_BUTTON_CLICK, label: LABELS.PRICE_RECOMMENDATIONS_TABLE });
};

export const gaEmitSummaryButtonClick = () => {
  emitEvent({ action: ACTIONS.SUMMARY_BUTTON_CLICK, label: LABELS.PRICE_RECOMMENDATIONS_TABLE });
};

export const gaEmitDetailButtonClick = () => {
  emitEvent({ action: ACTIONS.DETAIL_BUTTON_CLICK, label: LABELS.PRICE_RECOMMENDATIONS_TABLE });
};
