import React from 'react';
import * as d3 from 'd3';
import ResizeDetector from './ResizeDetector';
import { FONT_GRAY, LIGHT_COOL_GRAY } from '../../../../../colors';
import './line-chart.scss';

const LineChart = ({ data, numberFormatter, height, width }) => {
  const svgRef = React.useRef();

  React.useLayoutEffect(() => {
    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .style('font-family', 'URWDIN');

    svg.selectAll('*').remove(); // Clear previous content

    const margin = { top: 10, right: 50, bottom: 10, left: 50 };

    const maxX = Math.max(
      d3.max(
        data
          .map(d => d.data)
          .flat()
          .map(d => d.x),
      ),
      1,
    );
    const maxY = Math.max(
      d3.max(
        data
          .map(d => d.data)
          .flat()
          .map(d => d.y),
      ),
      0,
    );

    const xScale = d3
      .scaleLinear()
      .domain([0, maxX])
      .range([margin.left, width - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([0, maxY])
      .range([height - margin.bottom, margin.top]);

    const lineBuilder = d3
      .line()
      .x(d => xScale(d.x))
      .y(d => yScale(d.y));

    const g = svg
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('font-family', 'URWDIN');

    const ticks = yScale.ticks(5);
    ticks.forEach(tick => {
      g.append('line')
        .attr('x1', margin.left)
        .attr('y1', yScale(tick))
        .attr('x2', width - margin.right)
        .attr('y2', yScale(tick))
        .attr('opacity', '0.7')
        .attr('stroke', LIGHT_COOL_GRAY);
    });

    const yAxis = g
      .append('g')
      .attr('transform', `translate(${margin.left}, 0)`)
      .attr('color', LIGHT_COOL_GRAY)
      .call(
        d3
          .axisLeft(yScale)
          .ticks(5)
          .tickSizeOuter(0)
          .tickFormat(numberFormatter),
      );

    yAxis
      .selectAll('path')
      .attr('stroke', LIGHT_COOL_GRAY)
      .attr('opacity', '0.7');

    yAxis
      .selectAll('text')
      .attr('fill', FONT_GRAY)
      .attr('dy', '0.35em');

    yAxis.selectAll('line').attr('opacity', '0.7');

    data.forEach(item => {
      if (item.dashed) {
        g.append('path')
          .attr('fill', 'transparent')
          .attr('stroke', item.color)
          .attr('stroke-width', '2px')
          .attr('d', lineBuilder(item.data))
          .attr('stroke-dasharray', '8, 6');
      } else {
        g.append('path')
          .attr('fill', 'transparent')
          .attr('stroke', item.color)
          .attr('stroke-width', '2px')
          .attr('d', lineBuilder(item.data));
      }
    });
  }, [data, numberFormatter, height, width, svgRef]);

  return <svg ref={svgRef} style={{ width, height }} />;
};

const ChartContainer = ({ data, numberFormatter }) => (
  <ResizeDetector>
    {({ height, width }) => (
      <div className="line-chart" style={{ height, width }}>
        <LineChart data={data} numberFormatter={numberFormatter} height={height} width={width} />
      </div>
    )}
  </ResizeDetector>
);

export default ChartContainer;
