import _ from 'lodash';
import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useNavigateToPOSGuidedSetupStep } from './commonStepControl';
import { useCreateDatasetConfigForManual } from './createDatabaseRecords';
import { SPECIAL_CONFIG_NAME_TO_TILE_IMAGE } from './DataSourceLogos';
import { FileDropSelectArea, GenericFileUploadSuccess, useGuidedSetupFileUploadStatus } from './ManualFileUpload';
import PreviousStep from './PreviousStep';
import { ProgressBar, TrackedSteps } from './ProgressBar';
import SecureDataBanner from './SecureDataBanner';
import {
  ConfigNameToDataSource,
  DataSourceHeaders,
  DataSourceToConfigName,
  PSEUDO_INTEGRATION_AUTOMATIC_CONFIG,
  DataSourceColumnsToConfigFields,
} from '../../dataSources/dataSourceConstants';
import {
  DataSourceToInstructions,
  dataSourceSupportsDedicatedFileUpload,
  SetupFlow,
  slugToDataSource,
  slugToSetupFlow,
} from '../../dataSources/DataSourceInstructions';
import { PageHeader } from '../../forms/GenericFormComponents';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import GenericLoadingPage from '../../generic/GenericLoadingPage';
import { ProfitRoverCard } from '../../generic/ProfitRoverCard';
import Header from '../../header/header';
import { Objective, USER_DIMENSION_TYPE, WorkflowDescription } from '../../workflow/workflowConstants';
import { useDataset, useDatasets, useDatasetSample } from '../../../data-access/query/datasets';
import { useDimensions } from '../../../data-access/query/dimensions';
import { useAddFieldsToDatasetConfig, useUpdateDatasetConfig } from '../../../data-access/mutation/datasetConfigs';
import { useDeleteDataset, useUpdateDataset } from '../../../data-access/mutation/datasets';
import { useCreateDimensions } from '../../../data-access/mutation/dimensions';
import { useUpdateGuidedSetup } from '../../../data-access/mutation/guidedSetup';
import { useCreateLocation } from '../../../data-access/mutation/locations';
import { useCreateWorkflow } from '../../../data-access/mutation/workflows';
import { useGuidedSetup } from '../../../data-access/query/guidedSetup';
import { useDatasetConfigs } from '../../../data-access/query/datasetConfigs';
import fileUploadIcon from '../../../images/file-upload-icon.svg';
import './integration-file-upload.scss';

const FileUploadTemplate = ({ dataSource, fileName, GoBack, setupFlow, handlers, uploadNeeded, isAccepting }) => {
  const { onAcceptDataset, onRejectDataset, onFinish } = handlers;

  const configName = DataSourceToConfigName[dataSource];
  const Logo = SPECIAL_CONFIG_NAME_TO_TILE_IMAGE[configName];
  const Instructions = DataSourceToInstructions[dataSource];
  const textPrompt = `Drag & Drop your ${configName} Dataset here`;
  const isAutoConfigSource = PSEUDO_INTEGRATION_AUTOMATIC_CONFIG.has(dataSource);

  let buttonText;
  if (isAutoConfigSource) {
    buttonText = 'Process This Dataset';
  }

  return (
    <HeaderAwarePage scrollable={false}>
      <Header />
      <ProgressBar activeStep={TrackedSteps.CONNECT_DATA} />

      <div className="px-5 pb-4 pt-5 integration-file-upload">
        {GoBack && <GoBack />}

        <PageHeader>
          Upload a Sales Transaction Dataset
          <img className="dataset-icon ml-3" src={fileUploadIcon} alt="Add a dataset" />
        </PageHeader>

        <SecureDataBanner />

        <div className="content d-flex">
          <div className="instructions-column">
            <h5>Follow these steps to find and upload your data</h5>
            <ProfitRoverCard className="instructions-container">
              <div className="header-image">{Logo}</div>
              <ol className="pr-4">
                <Instructions setupFlow={setupFlow} />
              </ol>
            </ProfitRoverCard>
          </div>

          <div className="file-upload-base">
            <div className="sticky">
              <div className="mr-5">
                <h6 className="word-no-wrap" style={{ visibility: uploadNeeded ? 'visible' : 'hidden' }}>
                  Once you have your file, upload it by dropping it below or by browsing for your file.
                </h6>
                {uploadNeeded ? (
                  <FileDropSelectArea textPrompt={textPrompt} onFinish={onFinish} />
                ) : (
                  <GenericFileUploadSuccess
                    fileName={fileName}
                    isAccepting={isAccepting}
                    onAcceptDataset={onAcceptDataset}
                    onRejectDataset={onRejectDataset}
                    buttonText={buttonText}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderAwarePage>
  );
};

const useResolvedDatasetDetails = (datasetDetails, guidedSetup) => {
  let { fileName, datasetId } = datasetDetails ?? {};

  if (!datasetId && guidedSetup.dataset_id != null) {
    datasetId = guidedSetup.dataset_id;
  }

  const { data: dataset = {}, isFetching: isFetchingDataset } = useDataset(datasetId, {
    enabled: datasetId != null,
  });

  if (!fileName && dataset.file_name != null) {
    fileName = dataset.file_name;
  }

  return {
    fileName,
    datasetId,
    dataset,
    isFetchingDataset,
  };
};

/**
 * For some 'pseudo integrations' we can attempt to automatically create all or part of the dataset config for the
 * customer. This method handles both the guided setup and data source paths.
 */
const useAutomaticConfiguration = (datasetId, dataSource, naicsCode, kaCode, onAutoFinish, onPartialCompletion) => {
  const addFieldsToDatasetConfig = useAddFieldsToDatasetConfig();
  const createLocation = useCreateLocation();
  const { data: datasetColumnSamples = [] } = useDatasetSample(datasetId, { enabled: datasetId != null, retry: 3 });
  const { data: dimensions = [] } = useDimensions(false);
  const createDimensions = useCreateDimensions();

  const attemptAutomaticConfiguration = async datasetConfigId => {
    /**
     * For the guided setup path we need to keep track of the things we're automatically creating so that we can 'undo'
     * (delete) them in the case where the customer navigates backwards to change something.
     */
    const autoGeneratedIds = {};

    // Create a 'generic' bare-bones location with no address
    const locationDescription = `${DataSourceToConfigName[dataSource]} location`;
    const genericLocation = {
      location_description: locationDescription,
      naics_code: naicsCode,
      ka_code: kaCode,
    };

    const { id: newLocationId } = await createLocation.mutateAsync(genericLocation);
    autoGeneratedIds.location = newLocationId;

    /**
     * Examine the headers of the dataset columns and compare them against the list of columns that we expect to be
     * present for the given pseudo integration partner (e.g. Shopify). Track exactly which columns are present or
     * missing.
     */
    const headers = datasetColumnSamples.map(({ header_name: columnName }) => columnName);
    const expectedHeaders = DataSourceHeaders[dataSource];

    const headerPresence = {};
    expectedHeaders.forEach(header => {
      headerPresence[header] = false;
    });

    headers.forEach(header => {
      if (Object.prototype.hasOwnProperty.call(headerPresence, header)) {
        headerPresence[header] = true;
      }
    });

    const headerToFieldMap = DataSourceColumnsToConfigFields[dataSource];

    const datasetConfigFields = [
      {
        dataset_field_name: 'LOC',
        fixed_value: locationDescription,
      },
    ];

    const [dimHeaders, otherHeaders] = _.partition(
      Object.keys(headerPresence),
      header => headerToFieldMap[header] === 'DIM',
    );
    const [dimensionHeader] = dimHeaders; // We're assuming a single 'product' dimension header for now

    // Construct the non-dimension fields for those that were found
    otherHeaders.forEach(header => {
      const isHeaderPresent = headerPresence[header];
      const datasetFieldName = headerToFieldMap[header];

      if (isHeaderPresent) {
        datasetConfigFields.push({
          dataset_field_name: datasetFieldName,
          from_column: header,
          product_dimension_id: null,
        });
      }
    });

    /**
     * If the dimension header is present we will create a new dimension if the customer has not created any dimensions
     * prior to this one. If the header is present and the customer has exactly one prior dimension we will reuse that
     * one. Otherwise (i.e. if the header is not present or the customer has more than one existing dimension) we will
     * forward the user to the manual data source configuration page where they will need to fill out (at least) the
     * dimension section.
     */
    const isDimensionHeaderPresent = headerPresence[dimensionHeader];
    const customerHasNoExistingDimensions = dimensions?.length === 0;
    const customerHasOneExistingDimension = dimensions?.length === 1;
    let insertedDimensionId;
    let existingDimensionId;

    if (customerHasNoExistingDimensions && isDimensionHeaderPresent) {
      const createDimensionsResponse = await createDimensions.mutateAsync({
        dimensions: [
          {
            name: 'Product',
            dimension_type: USER_DIMENSION_TYPE,
          },
        ],
      });

      const [createdDimension] = createDimensionsResponse;
      insertedDimensionId = createdDimension.product_dimension_id;

      autoGeneratedIds.dimension = insertedDimensionId;
      datasetConfigFields.push({
        dataset_field_name: headerToFieldMap[dimensionHeader],
        from_column: dimensionHeader,
        product_dimension_id: insertedDimensionId,
      });
    }

    const isReusingDimension = customerHasOneExistingDimension && isDimensionHeaderPresent;

    if (isReusingDimension) {
      const [existingDimension] = dimensions;
      existingDimensionId = existingDimension.product_dimension_id;

      datasetConfigFields.push({
        dataset_field_name: headerToFieldMap[dimensionHeader],
        from_column: dimensionHeader,
        product_dimension_id: existingDimensionId,
      });
    }

    // Add dataset config fields for the expected columns that we found
    const response = await addFieldsToDatasetConfig.mutateAsync({ datasetConfigId, fields: datasetConfigFields });
    const { insertedIds: insertedFieldIds } = response;
    autoGeneratedIds.fields = insertedFieldIds;

    const allHeadersPresent = Object.values(headerPresence).every(isPresent => isPresent === true);
    const canFinishImmediately = allHeadersPresent && (customerHasNoExistingDimensions || isReusingDimension);

    if (canFinishImmediately) {
      /**
       * If every expected column was present and either a) there were no dimensions created prior to this config or b)
       * we're reusing an existing dimension then we can automatically create everything and wrap up right here and now.
       */
      await onAutoFinish({
        autoGeneratedIds,
        dimensionIds: [insertedDimensionId || existingDimensionId],
      });
    } else {
      // Some expected headers are missing or could not create dimension
      await onPartialCompletion({ autoGeneratedIds });
    }
  };

  return attemptAutomaticConfiguration;
};

const useGuidedSetupFileUpload = () => {
  const [uploadedDatasetDetails, setUploadedDatasetDetails] = React.useState();
  const [datasetRejected, setDatasetRejected] = React.useState();

  const history = useHistory();

  const {
    data: guidedSetup = {},
    isFetching: isFetchingGuidedSetup,
    isLoading: isLoadingGuidedSetup,
    refetch: refetchGuidedSetup,
  } = useGuidedSetup();
  const {
    naics_industry: { naics_code: naicsCode, ka_code: kaCode } = {},
    data_source: { name: configName, item_classes: itemClasses, source: dataSource } = {},
    price_change_frequencies: priceChangeFrequencies,
  } = guidedSetup;

  let { dataset_config_id: datasetConfigId } = guidedSetup;

  const { fileName, datasetId, dataset, isFetchingDataset } = useResolvedDatasetDetails(
    uploadedDatasetDetails,
    guidedSetup,
  );

  const updateGuidedSetup = useUpdateGuidedSetup();
  const createDatasetConfigForManual = useCreateDatasetConfigForManual();
  const updateDatasetMutation = useUpdateDataset();
  const deleteDatasetMutation = useDeleteDataset();
  const createWorkflow = useCreateWorkflow();
  const updateDatasetConfig = useUpdateDatasetConfig();

  const onFinish = async datasetDetails => {
    const { datasetId: uploadedDatasetId } = datasetDetails;
    await updateGuidedSetup.mutateAsync({ dataset_id: uploadedDatasetId });
    refetchGuidedSetup(); // Trigger UI to respond to changes (specifically, hiding the back button)

    // Toggle back to showing the upload dataset UI
    setUploadedDatasetDetails(datasetDetails);
    setDatasetRejected(false);
  };

  const [isAccepting, setIsAccepting] = React.useState(false);

  const attemptAutomaticConfiguration = useAutomaticConfiguration(
    datasetId,
    dataSource,
    naicsCode,
    kaCode,
    async ({ autoGeneratedIds, dimensionIds }) => {
      const queuedPromises = [
        updateDatasetConfig.mutateAsync({
          datasetConfigId,
          datasetConfig: { is_editable: false },
        }),
      ];

      const { id: createdWorkflowId } = await createWorkflow.mutateAsync({
        objective: Objective.PRICEOPT,
        description: WorkflowDescription.PRICEOPT,
        dimensions: dimensionIds,
        naics_code: naicsCode,
        ka_code: kaCode,
        time_granularity: 'DAY',
        price_changes: priceChangeFrequencies,
      });

      queuedPromises.push(
        updateGuidedSetup.mutateAsync({
          is_complete: true,
          workflow_id: createdWorkflowId,
          auto_generated_ids: autoGeneratedIds,
        }),
      );

      await Promise.allSettled(queuedPromises);

      history.replace(history.location, { preventAutoForward: true });
      history.push('/welcome', { showCompletedGuidedSetupModal: true });
    },
    async ({ autoGeneratedIds }) => {
      // Some expected headers are missing or could not create dimension
      await updateGuidedSetup.mutateAsync({
        auto_generated_ids: autoGeneratedIds,
      });
      history.replace(history.location, { preventAutoForward: true });
      history.push('/guided-setup/manual/configure');
    },
  );

  const onAcceptDataset = async () => {
    setIsAccepting(true);

    try {
      if (!datasetConfigId) {
        // Create dataset config
        ({ datasetConfigId } = await createDatasetConfigForManual({
          configName,
          itemClasses,
          naicsCode,
          kaCode,
        }));

        // Store dataset config id on the Guided Setup document
        await updateGuidedSetup.mutateAsync({ dataset_config_id: datasetConfigId });
      }

      const datasetNotProperlyLinked = dataset.dataset_config_id !== datasetConfigId;

      if (datasetNotProperlyLinked) {
        // Link chosen dataset to config
        await updateDatasetMutation.mutateAsync({ datasetId, dataset: { dataset_config_id: datasetConfigId } });
      }

      if (PSEUDO_INTEGRATION_AUTOMATIC_CONFIG.has(dataSource)) {
        await attemptAutomaticConfiguration(datasetConfigId);
      } else {
        // Handle the case of a true manual upload for a file that has no associated pseudo integration
        history.replace(history.location, { preventAutoForward: true });
        history.push('/guided-setup/manual/configure');
      }
    } catch (err) {
      // TODO: UI error handling
      _.noop();
    } finally {
      setIsAccepting(false);
    }
  };

  const cleanDatasetRecords = async () => {
    setUploadedDatasetDetails(undefined);
    setDatasetRejected(true);

    await Promise.all([
      deleteDatasetMutation.mutateAsync(datasetId),
      // Remove the value but not the key to preserve the fact that the user has uploaded something at least once
      updateGuidedSetup.mutateAsync({ dataset_id: null }),
    ]);
  };

  const uploadNeeded = (uploadedDatasetDetails == null && datasetRejected) || fileName == null || datasetId == null;

  return {
    isFetching: isFetchingDataset || isFetchingGuidedSetup,
    isLoading: isLoadingGuidedSetup,
    isAccepting,
    dataset,
    fileName,
    datasetId,
    handlers: {
      onFinish,
      onAcceptDataset,
      onRejectDataset: cleanDatasetRecords,
    },
    setUploadedDatasetDetails,
    uploadNeeded,
  };
};

const GuidedSetupFileUploadPage = ({ dataSource, setupFlow }) => {
  const history = useHistory();

  const {
    location: { state: { preventAutoForward = false } = {} },
  } = history;

  const passedProps = useGuidedSetupFileUpload();
  const { isFetching, isLoading, fileName, datasetId, setUploadedDatasetDetails } = passedProps;

  const {
    lockedInToFileUpload,
    missingNecessaryInfo,
    datasetUploadedButNotConfirmed,
    datasetUploadedAndConfirmed,
  } = useGuidedSetupFileUploadStatus();
  /**
   * To prevent infinite re-renders, this Boolean prevents "navigation-like" behaviors
   * from happening repeatedly. This problem only affects this component since (unlike the
   * <ManualFileUpload>) the upload/success pages are the same component/route.
   */
  const [isInitialized, setIsInitialized] = React.useState(false);

  const { onClick: navigateToPOSSetup } = useNavigateToPOSGuidedSetupStep();
  const GoBack = lockedInToFileUpload ? null : () => <PreviousStep onClick={navigateToPOSSetup} />;

  if (!isInitialized && !isFetching) {
    setIsInitialized(true);

    if (missingNecessaryInfo) {
      // Go back
      history.push('/guided-setup');
    } else if (datasetUploadedButNotConfirmed) {
      // Show dataset confirmation UI
      setUploadedDatasetDetails({ fileName, datasetId });
    } else if (datasetUploadedAndConfirmed) {
      if (!preventAutoForward) {
        // Begin dataset configuration
        history.push('/guided-setup/manual/configure');
      }
    }

    const willRedirect = _.some([missingNecessaryInfo, datasetUploadedAndConfirmed]);
    if (willRedirect) {
      return <GenericLoadingPage />;
    }
  }

  if (!isInitialized || isLoading) {
    return <GenericLoadingPage />;
  }

  return <FileUploadTemplate dataSource={dataSource} setupFlow={setupFlow} GoBack={GoBack} {...passedProps} />;
};

const useAddNewDataSourceFileUpload = (dataset, datasetConfig) => {
  const [uploadedDatasetDetails, setUploadedDatasetDetails] = React.useState();
  const [isAccepting, setIsAccepting] = React.useState(false);

  const history = useHistory();
  const updateDatasetMutation = useUpdateDataset();
  const deleteDataset = useDeleteDataset();
  const updateDatasetConfig = useUpdateDatasetConfig();

  const { datasetId, fileName } = uploadedDatasetDetails ?? {};
  const { dataset_config_id: datasetConfigId, naics_code: naicsCode, ka_code: kaCode } = datasetConfig;

  const cleanDatasetRecords = async () => {
    await deleteDataset.mutateAsync(datasetId);
    setUploadedDatasetDetails();
  };

  const dataSource = ConfigNameToDataSource[datasetConfig.name];
  const attemptAutomaticConfiguration = useAutomaticConfiguration(
    datasetId,
    dataSource,
    naicsCode,
    kaCode,
    async () => {
      // All expected headers are present (data sources path)
      await updateDatasetConfig.mutateAsync({
        datasetConfigId,
        datasetConfig: { is_editable: false },
      });

      history.push('/data-sources');
    },
    () => {
      history.push('/data-sources/add-new/manual/configure', {
        dsConfigIdToEdit: datasetConfigId,
        fileId: datasetId,
      });
    },
  );

  const onAcceptDataset = async () => {
    try {
      setIsAccepting(true);

      const datasetNotProperlyLinked = dataset.dataset_config_id !== datasetConfigId;
      if (datasetNotProperlyLinked) {
        // Link chosen dataset to config
        await updateDatasetMutation.mutateAsync({ datasetId, dataset: { dataset_config_id: datasetConfigId } });
      }

      if (PSEUDO_INTEGRATION_AUTOMATIC_CONFIG.has(dataSource)) {
        await attemptAutomaticConfiguration(datasetConfigId);
      } else {
        // Handle the case of a true manual upload for a file that has no associated pseudo integration
        history.push('/data-sources/add-new/manual/configure', {
          fileId: datasetId,
          dsConfigIdToEdit: datasetConfigId,
        });
      }
    } catch (err) {
      // TODO: UI error handling
      _.noop();
    } finally {
      setIsAccepting(false);
    }
  };

  const handlers = {
    onFinish: datasetDetails => {
      setUploadedDatasetDetails(datasetDetails);
    },
    onAcceptDataset,
    onRejectDataset: cleanDatasetRecords,
  };

  return {
    fileName,
    handlers,
    isAccepting,
    uploadNeeded: uploadedDatasetDetails == null,
  };
};

const AddNewDataSourceFileUploadPage = ({ dataSource, setupFlow }) => {
  const history = useHistory();
  const {
    location: { state: { datasetConfigId } = {} },
  } = history;

  const { data: datasetConfigs = [], isLoading: isLoadingDatasetConfigs } = useDatasetConfigs();
  const { data: datasets = [], isLoading: isLoadingDatasets } = useDatasets();

  const datasetConfig = _.find(datasetConfigs, { dataset_config_id: datasetConfigId }) ?? {};
  const dataset = _.find(datasets, { dataset_config_id: datasetConfigId }) ?? {};
  const { dataset_id: datasetId } = dataset;

  const passedProps = useAddNewDataSourceFileUpload(dataset, datasetConfig);

  const isLoading = isLoadingDatasetConfigs || isLoadingDatasets;
  if (!isLoading) {
    if (datasetConfigId == null || datasetConfig == null) {
      // Go back
      history.push('/data-sources');
    } else if (datasetId != null) {
      // Go forward
      history.push('/data-sources/add-new/manual/configure', { dsConfigIdToEdit: datasetConfigId, fileId: datasetId });
    }
  }

  if (isLoading) {
    return <GenericLoadingPage />;
  }

  return <FileUploadTemplate dataSource={dataSource} setupFlow={setupFlow} {...passedProps} />;
};

const FileUploadPage = ({ dataSource, setupFlow = SetupFlow.GUIDED_SETUP }) => {
  if (setupFlow === SetupFlow.GUIDED_SETUP) {
    return <GuidedSetupFileUploadPage dataSource={dataSource} setupFlow={SetupFlow.GUIDED_SETUP} />;
  }

  if (setupFlow === SetupFlow.ADD_NEW_DATA_SOURCE) {
    return <AddNewDataSourceFileUploadPage dataSource={dataSource} setupFlow={SetupFlow.ADD_NEW_DATA_SOURCE} />;
  }

  return <Redirect to="/login-redirect" />;
};

const IntegrationFileUpload = () => {
  const { dataSource: dataSourceSlug, setupFlow: setupFlowSlug } = useParams();

  const dataSource = slugToDataSource(dataSourceSlug);
  const setupFlow = slugToSetupFlow(setupFlowSlug);

  const isSourceSupported = dataSourceSupportsDedicatedFileUpload(dataSource);
  if (!dataSource || !isSourceSupported) {
    return <Redirect to="/login-redirect" />;
  }

  return <FileUploadPage dataSource={dataSource} setupFlow={setupFlow} />;
};

export default IntegrationFileUpload;
