import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import BasicErrorWithImageFallback from './BasicErrorWithImageFallback';
import PopcornScreen from './PopcornScreen';
import {
  DataInProgress,
  NoLocationsError,
  NotEnoughHistoricalData,
} from '../../dashboards/eo/revenueUplift/revenueDashboardErrors';
import trafficCone from '../../../images/traffic-cone.png';
import { toggleIsOpen } from '../../../redux/notificationCenter/actions';

const RetryOrGoToFallback = ({ primaryOnLeave, secondaryOnLeave, resetErrorBoundary, ...renderProps }) => {
  const { error, toggleNotificationCenterIsOpen } = renderProps;
  const history = useHistory();

  if (primaryOnLeave == null) {
    // Default
    primaryOnLeave = renderProps.resetErrorBoundary;
  }

  if (secondaryOnLeave == null) {
    // Default
    secondaryOnLeave = () => history.push('/welcome');
  }

  if (error instanceof DataInProgress || error instanceof NoLocationsError) {
    return <PopcornScreen resetErrorBoundary={resetErrorBoundary} batch={error.batch} workflowId={error.workflowId} />;
  }

  if (error instanceof NotEnoughHistoricalData) {
    return (
      <BasicErrorWithImageFallback
        imageSrc={trafficCone}
        top={
          <p className="error-description">
            Uh oh! It looks like you don&apos;t have enough historical data for our engines to work their magic
          </p>
        }
        bottom={
          <p className="error-description">
            We&apos;d love to chat with you about it - please contact us{' '}
            <Link
              to={{
                pathname: '/help',
                state: {
                  message: 'I was told I don’t have enough historical data and would like some assistance',
                },
              }}
            >
              here
            </Link>
          </p>
        }
      />
    );
  }

  const hasAlertNotifications = localStorage.getItem('has_alert_notifications') === 'true';

  return (
    <BasicErrorWithImageFallback
      imageSrc={trafficCone}
      top={<p className="error-description">Uh oh! It looks like we&apos;ve encountered an error</p>}
      bottom={
        hasAlertNotifications ? (
          <>
            <p className="error-description">
              See notifications{' '}
              <spam className="link" onClick={toggleNotificationCenterIsOpen}>
                here
              </spam>
              <br />
              Still have questions?{' '}
              <Link
                to={{
                  pathname: '/help',
                  state: {
                    message: 'I encountered an error and need some assistance',
                  },
                }}
              >
                Contact us
              </Link>
            </p>
          </>
        ) : (
          <p className="error-description">
            We&apos;d love to chat with you about it - please contact us{' '}
            <Link
              to={{
                pathname: '/help',
                state: {
                  message: 'I encountered an error and need some assistance',
                },
              }}
            >
              here
            </Link>
          </p>
        )
      }
    />
  );
};

const mapDispatchToProps = {
  toggleNotificationCenterIsOpen: toggleIsOpen,
};

export default connect(null, mapDispatchToProps)(RetryOrGoToFallback);
