const TRUE = '1';

// eslint-disable-next-line import/prefer-default-export
export const transformProductPricePointsData = (value, header) => {
  switch (header) {
    case 'price':
    case 'remainingDemand':
    case 'revenue':
    case 'profit':
      return parseFloat(value);
    case 'isKaizenPrice':
    case 'isCurrentPrice':
      return value === TRUE;
    case 'tranDate':
    default:
      return value;
  }
};
