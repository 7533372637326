import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Dimensions Settings';

const ACTIONS = {
  ADD_NEW_DIMENSION_CHECKMARK_CLICK: 'Add new dimension checkmark click',
  ADD_NEW_DIMENSION_VALUE_CHECKMARK_CLICK: 'Add new dimension value checkmark click',
  EDIT_DIMENSION_VALUE_CHECKMARK_CLICK: 'Edit dimension value checkmark click',
  SAVE_CHANGES_DIMENSION_VALUE_BUTTON_CLICK: 'Save changes dimension value button click',
  EDIT_DIMENSION_VALUE_MAPPING_CHECKMARK_CLICK: 'Edit dimension value mapping checkmark click',
  SAVE_CHANGES_DIMENSION_VALUE_MAPPING_BUTTON_CLICK: 'Save changes dimension value mapping button click',
};

const LABELS = {
  MANAGE_DIMENSIONS: 'Manage Dimensions',
  MANAGE_DIMENSIONS_VALUES: 'Manage Dimension Values',
  DIMENSION_VALUE_MAPPING: 'Dimension Value Mapping',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitAddNewDimensionCheckmarkClick = () => {
  emitEvent({ action: ACTIONS.ADD_NEW_DIMENSION_CHECKMARK_CLICK, label: LABELS.MANAGE_DIMENSIONS });
};

export const gaEmitAddNewDimensionValueCheckmarkClick = () => {
  emitEvent({ action: ACTIONS.ADD_NEW_DIMENSION_VALUE_CHECKMARK_CLICK, label: LABELS.MANAGE_DIMENSIONS_VALUES });
};

export const gaEmitEditDimensionValueCheckmarkClick = () => {
  emitEvent({ action: ACTIONS.EDIT_DIMENSION_VALUE_CHECKMARK_CLICK, label: LABELS.MANAGE_DIMENSIONS_VALUES });
};

export const gaEmitSaveChangesDimensionValueButtonClick = () => {
  emitEvent({ action: ACTIONS.SAVE_CHANGES_DIMENSION_VALUE_BUTTON_CLICK, label: LABELS.MANAGE_DIMENSIONS_VALUES });
};

export const gaEmitEditDimensionValueMappingCheckmarkClick = () => {
  emitEvent({
    action: ACTIONS.EDIT_DIMENSION_VALUE_MAPPING_CHECKMARK_CLICK,
    label: LABELS.DIMENSION_VALUE_MAPPING,
  });
};

export const gaEmitSaveChangesDimensionValueMappingButtonClick = () => {
  emitEvent({
    action: ACTIONS.SAVE_CHANGES_DIMENSION_VALUE_MAPPING_BUTTON_CLICK,
    label: LABELS.DIMENSION_VALUE_MAPPING,
  });
};
