import _ from 'lodash';
import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { ONE_DAY_IN_MILLISECONDS } from '../constants/cacheTimes';
import { KDL_API_URL } from '../../../config/baseUrl';

/**
 * Requests
 */

export const fetchDailyWeatherForecast = async (kdlLocationId, { units } = {}) =>
  axios.get(`${KDL_API_URL}/weather/forecast/daily/${kdlLocationId}`, { params: { units } });

export const getWeatherLocationInfo = async (city, state, zip, country) => {
  const addressString = [city, state, zip, country].filter(Boolean).join(' ');

  const encodedAddressString = encodeURIComponent(addressString);

  let data;

  try {
    ({ data } = await axios.get(`${KDL_API_URL}/weather/locations?address=${encodedAddressString}`));
  } catch {
    // geolocation in kdl may fail which causes a 400 http response code
    _.noop();
  }

  // data will be undefined if we caught the 400 error above
  const [firstResult] = data ?? [];

  if (firstResult) {
    const { location_id: weatherLocationId, timezone, latitude, longitude } = firstResult;

    return { weatherLocationId, timezone, latitude, longitude };
  }

  // if we got no results at all due to a 400 error or kdl just returned no response then this
  // empty object return will signal call sites that there is no weather id
  return {};
};

/**
 * Hooks
 */

export const Units = {
  IMPERIAL: 'imperial',
  METRIC: 'metric',
};

const WEATHER_QUERY_KEY_BASE = 'kdlWeather';

export const useWeatherForLocation = (kdlLocationId, { units } = {}) =>
  useQuery(
    [WEATHER_QUERY_KEY_BASE, kdlLocationId, units],
    async () => {
      if (!kdlLocationId) {
        return [];
      }

      const response = await fetchDailyWeatherForecast(kdlLocationId, { units });
      return response?.data ?? [];
    },
    { staleTime: ONE_DAY_IN_MILLISECONDS },
  );

export const useHighAndLowTemps = kdlLocationId => {
  const query = useWeatherForLocation(kdlLocationId);

  query.data = React.useMemo(() => {
    if (Array.isArray(query.data)) {
      return query.data.map(({ temp_max: tempMax, temp_min: tempMin, forecast_datetime: forecastDateTime }) => {
        return {
          dateTime: forecastDateTime,
          highTemp: tempMax,
          lowTemp: tempMin,
        };
      });
    }

    return [];
  }, [query.data]);

  return query;
};
