import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import _ from 'lodash';
import { CUSTOMER_USERS_QUERY_KEY_BASE } from '../query/userManagement';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const createNewUser = async data => axios.post(`${KA_API_URL}/customer/user`, data);
export const deleteUser = async id => axios.delete(`${KA_API_URL}/customer/user/${id}`);
export const resendInvite = async id => axios.post(`${KA_API_URL}/customer/user/${id}/invite`);
export const updateUser = async (id, data) => axios.put(`${KA_API_URL}/users/${id}`, data);
export const updateUserRole = async (id, data) => axios.put(`${KA_API_URL}/customer/user/${id}/role`, data);

/**
 * Hooks
 */

export const useCreateNewUser = mutationConfig => {
  const queryClient = useQueryClient();

  return useMutation(
    async data => {
      try {
        const response = await createNewUser(data);

        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          throw new Error(error.response.data.message);
        }

        throw error;
      }
    },
    {
      ...mutationConfig,
      onSuccess: async () => {
        await queryClient.invalidateQueries(CUSTOMER_USERS_QUERY_KEY_BASE);

        if (_.isFunction(mutationConfig?.onSuccess)) {
          await mutationConfig.onSuccess();
        }
      },
    },
  );
};

export const useDeleteUser = mutationConfig => {
  const queryClient = useQueryClient();

  return useMutation(
    async userId => {
      const response = await deleteUser(userId);

      return response.data;
    },
    {
      ...mutationConfig,
      onSuccess: async () => {
        await queryClient.invalidateQueries(CUSTOMER_USERS_QUERY_KEY_BASE);

        if (_.isFunction(mutationConfig?.onSuccess)) {
          await mutationConfig.onSuccess();
        }
      },
    },
  );
};

export const useUpdateUser = mutationConfig => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, data }) => {
      const response = await updateUser(id, data);

      return response.data;
    },
    {
      ...mutationConfig,
      onSuccess: async () => {
        await queryClient.invalidateQueries(CUSTOMER_USERS_QUERY_KEY_BASE);

        if (_.isFunction(mutationConfig?.onSuccess)) {
          await mutationConfig.onSuccess();
        }
      },
    },
  );
};

export const useResendInvite = mutationConfig => {
  return useMutation(async userId => {
    const response = await resendInvite(userId);

    return response.data;
  }, mutationConfig);
};

export const useUpdateUserRole = mutationConfig => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, data }) => {
      const response = await updateUserRole(id, data);

      return response.data;
    },
    {
      ...mutationConfig,
      onSuccess: async () => {
        await queryClient.invalidateQueries(CUSTOMER_USERS_QUERY_KEY_BASE);

        if (_.isFunction(mutationConfig?.onSuccess)) {
          await mutationConfig.onSuccess();
        }
      },
    },
  );
};
