/**
 * Text truncation method that implements a way to measure the length of text rendered at a particular
 * fontSize. It isn't particularly efficient, but it is one of the easiest ways to pre-measure
 * text provided you know ahead of time what font-size you'll eventually be rendering at.
 *
 * DEVELOPER NOTE: This only works for text that is formatted exactly the same way. If you need more
 * flexibility than that, you'll need to use the approach outlined on the following story: KP-3306
 */
// eslint-disable-next-line import/prefer-default-export
export const computeTextWidth = (text, fontSize) => {
  const measuringElement = document.createElement('span');
  document.body.appendChild(measuringElement);

  measuringElement.innerHTML = text;
  measuringElement.style.fontSize = `${fontSize}px`;
  measuringElement.style.height = 'auto';
  measuringElement.style.width = 'auto';
  measuringElement.style.position = 'absolute';
  measuringElement.style.whiteSpace = 'no-wrap';

  const width = Math.ceil(measuringElement.clientWidth);

  document.body.removeChild(measuringElement);

  return width;
};
