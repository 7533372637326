import React from 'react';
import { useQueryClient } from 'react-query';
import { INVALID_QUERY_KEYS_AFTER_RESULTS_REFRESH } from './anomalyDetectionHooks';
import { SSEEventType, useEventStreamContext } from '../../../server-sent-events/eventStreamContext';

const useListenForNewResults = workflowId => {
  const { sseEventSource } = useEventStreamContext();

  const [hasNewResults, setHasNewResults] = React.useState(false);

  const resetHasNewResults = () => setHasNewResults(false);

  React.useEffect(() => {
    if (!sseEventSource) {
      return;
    }

    const eventArrivalListener = event => {
      const eventData = JSON.parse(event.data);
      const { payload } = eventData;

      if (payload.workflow_id === workflowId) {
        if (payload.batch_complete) {
          // TODO: Perhaps queryClient.invalidateQueries(PROCESSED_HISTORY_ROW_STATUS_QUERY_KEY) should be done here?
          setHasNewResults(true);
        }
      }
    };

    sseEventSource.addEventListener(SSEEventType.WORK_BATCH_PROGRESS, eventArrivalListener);

    // eslint-disable-next-line consistent-return
    return () => sseEventSource.removeEventListener(SSEEventType.WORK_BATCH_PROGRESS, eventArrivalListener);
  }, [sseEventSource, workflowId]);

  return { hasNewResults, resetHasNewResults };
};

// eslint-disable-next-line import/prefer-default-export
export const useRefreshResults = workflowId => {
  const queryClient = useQueryClient();

  const { hasNewResults, resetHasNewResults } = useListenForNewResults(workflowId);

  const onReloadDashboard = async () => {
    const invalidations = INVALID_QUERY_KEYS_AFTER_RESULTS_REFRESH.map(key => queryClient.invalidateQueries(key));

    await Promise.allSettled(invalidations);

    resetHasNewResults();
  };

  return {
    hasNewResults,
    onReloadDashboard,
  };
};
