import React from 'react';
import _ from 'lodash';
import LoadingIndicator from '../accountSettings/common/LoadingIndicator';
import './data-source-settings.scss';

const MAXIMUM_DATA_SAMPLE_LENGTH = 30;

const DatasetSampleTable = ({ datasetColumnSamples, isSampleLoading }) => {
  const headers = datasetColumnSamples.map(({ header_name: columnName }) => {
    return <th key={columnName}>{columnName}</th>;
  });

  // Create rows of data
  const rows = React.useMemo(() => {
    const sampleValues = datasetColumnSamples.map(sample => sample.sample_values);

    const sampleSizes = sampleValues.map(sampleValuesForColumn => sampleValuesForColumn.length);
    const numberOfRowsAvailable = sampleSizes.length > 0 ? _.min(sampleSizes) : 0;

    const assembledRows = [];

    for (let rowIndex = 0; rowIndex < numberOfRowsAvailable; ++rowIndex) {
      assembledRows[rowIndex] = sampleValues.map(sampleValuesForColumn => {
        return sampleValuesForColumn[rowIndex];
      });
    }

    return assembledRows.map((row, rowIndex) => (
      <tr key={rowIndex.toString()}>
        {row.map((sampleValue, colIndex) => {
          const valueTooLong = sampleValue.length > MAXIMUM_DATA_SAMPLE_LENGTH;
          sampleValue = sampleValue.slice(0, MAXIMUM_DATA_SAMPLE_LENGTH);
          if (valueTooLong) {
            sampleValue += '...';
          }
          return <td key={colIndex.toString()}>{sampleValue}</td>;
        })}
      </tr>
    ));
  }, [datasetColumnSamples]);

  return (
    <div className="dataset-samples-container">
      <div className="dataset-samples-table-heading">
        <div className="dataset-samples-table-title">Your Dataset Sample</div>
      </div>
      <div className="dataset-samples-table-container">
        {isSampleLoading ? (
          <LoadingIndicator />
        ) : (
          <table className="dataset-samples-table">
            <thead>
              <tr>{headers}</tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default DatasetSampleTable;
