/* eslint-disable react/display-name */
/* eslint-disable react/no-danger */
import React, { Fragment } from 'react';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { formatCurrency, formatPercent } from '../../../util/format';

const singleGameRevenueSummaryTable = props => {
  const {
    selectedGame,
    priceLevelDetails,
    singleGameSortObject,
    calculateSingleGameSortData,
    renderPriceDemandChart,
    renderRecommendationDetails,
    selectedPriceZoneIndex,
    intitialHighLightPriceZone,
    intitialHighLightPriceZoneIndex,
    handlePriceZoneClick,
  } = props;
  return (
    <Fragment>
      <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>{selectedGame.opponent} Revenue Summary</h6>
      <ReactTable
        data={priceLevelDetails}
        columns={[
          {
            Header: (
              <div>
                {singleGameSortObject.price_level.is_sorted === true ? (
                  <div className="row">
                    <div className="col-7" style={{ paddingTop: '12px' }}>
                      Price Zone
                    </div>
                    <div className="col-1" style={{ paddingTop: '5px' }}>
                      {' '}
                      {singleGameSortObject.price_level.is_sorted === true ? (
                        singleGameSortObject.price_level.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ paddingTop: '12px' }}>Price Zone</div>
                )}
              </div>
            ),
            id: 'price_level',
            width: 125,
            headerStyle: {
              background: '#ffc500',
              height: '55px',
              fontWeight: 'bold',
              border: '1px solid white',
            },
            sortable: true,
            accessor: d => d.price_level,
          },

          {
            Header: (
              <div>
                {singleGameSortObject.uplift.is_sorted === true ? (
                  <div className="row">
                    <div className="col-8" style={{ paddingTop: '12px' }}>
                      Revenue Uplift
                    </div>
                    <div className="col-1" style={{ paddingTop: '5px' }}>
                      {' '}
                      {singleGameSortObject.uplift.is_sorted === true ? (
                        singleGameSortObject.uplift.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ paddingTop: '12px' }}>Revenue Uplift</div>
                )}
              </div>
            ),

            headerStyle: {
              background: '#ffc500',
              height: '55px',
              fontWeight: 'bold',
              border: '1px solid white',
            },
            id: 'uplift',
            sortable: true,
            accessor: d => (
              <div
                style={{
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  backgroundColor: d.uplift === 0 ? '' : `rgb(0,175,79,${d.color})`,
                }}
                dangerouslySetInnerHTML={{
                  __html: formatCurrency(d.uplift, false),
                }}
              />
            ),
          },
          {
            Header: (
              <div>
                {singleGameSortObject.kaizen_price_change.is_sorted === true ? (
                  <div className="row">
                    <div className="col-8">
                      <div className="row" style={{ paddingTop: '2px', marginLeft: '2px' }}>
                        {' '}
                        KaizenPrice vs
                      </div>
                      <div className="row" style={{ marginLeft: '20px' }}>
                        Current
                      </div>
                    </div>
                    <div className="col-1" style={{ paddingTop: '5px' }}>
                      {' '}
                      {singleGameSortObject.kaizen_price_change.is_sorted === true ? (
                        singleGameSortObject.kaizen_price_change.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    KaizenPrice vs <br />
                    Current
                  </div>
                )}
              </div>
            ),
            headerStyle: {
              background: '#ffc500',
              height: '55px',
              fontWeight: 'bold',
              border: '1px solid white',
            },
            id: 'kaizen_price_change',
            accessor: d => formatPercent(d.kaizen_price_change),
            sortable: true,
            Cell: row => (
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  color:
                    row.original.kaizen_price - row.original.current_price > 0
                      ? 'rgb(0,175,79)'
                      : row.original.kaizen_price - row.original.current_price < 0
                      ? 'red'
                      : 'black',
                }}
              >
                {row.value}
              </div>
            ),
          },
        ]}
        getTheadThProps={(state, rowInfo, column) => {
          return {
            onClick: () => {
              if (column.sortable) {
                calculateSingleGameSortData(column.id);
              }
            },
          };
        }}
        getTdProps={(state, rowInfo) => {
          if (typeof rowInfo !== 'undefined') {
            return {
              style: {
                borderRight: '1px solid white',
                cursor: 'pointer',
              },

              onClick: () => {
                renderRecommendationDetails(rowInfo.original);
                renderPriceDemandChart(rowInfo.original, selectedGame);
              },
            };
          }
          return {};
        }}
        getTrProps={(state, rowInfo) => {
          if (typeof rowInfo !== 'undefined') {
            return {
              onClick: (e, handleOriginal) => {
                handlePriceZoneClick(rowInfo.index);
                if (handleOriginal) {
                  handleOriginal();
                }
              },
              style: {
                background:
                  rowInfo.index === selectedPriceZoneIndex ||
                  (intitialHighLightPriceZone && rowInfo.index === intitialHighLightPriceZoneIndex)
                    ? '#c0c0c0'
                    : '#e8eef6',
              },
            };
          }
          return {
            onClick: (e, handleOriginal) => {
              if (handleOriginal) {
                handleOriginal();
              }
            },
            style: {
              background: '#e8eef6',
            },
          };
        }}
        noDataText="Loading Revenue Summary "
        pageSize={priceLevelDetails.length > 0 ? priceLevelDetails.length : 7}
        showPagination={false}
        className="-highlight"
      />
    </Fragment>
  );
};

export default singleGameRevenueSummaryTable;
