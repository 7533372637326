import StatesAndProvincesList from './states-and-provinces.json';

export const stateDropdownOptions = StatesAndProvincesList.States.map(twoLetterState => ({
  value: twoLetterState,
  label: twoLetterState,
}));

export const provinceDropdownOptions = StatesAndProvincesList.Provinces.map(twoLetterProvince => ({
  value: twoLetterProvince,
  label: twoLetterProvince,
}));
