/* eslint-disable max-len */
import React from 'react';
import classnames from 'classnames';
import FORMAT_TYPE from '../formatType';
import { ReactComponent as SmallPriceTagTip } from '../../../images/priceTagIcon/small-price-tag-tip.svg';
import { ReactComponent as SmallUpArrow } from '../../../images/priceTagIcon/small-up-arrow.svg';
import { ReactComponent as SmallDownArrow } from '../../../images/priceTagIcon/small-down-arrow.svg';
import { ReactComponent as BigPriceTagTip } from '../../../images/priceTagIcon/big-price-tag-tip.svg';
import { ReactComponent as BigUpArrow } from '../../../images/priceTagIcon/big-up-arrow.svg';
import { ReactComponent as BigDownArrow } from '../../../images/priceTagIcon/big-down-arrow.svg';
import './price-tag-icon.css';

const UNDEFINED_DISPLAY = '-';

export const formatPercentage = percentage => {
  percentage = Math.abs(Math.round(percentage));

  return `${percentage}%`;
};

export const formatCurrency = (currency, { currencySymbol = '$' }) => {
  currency = currency >= 100 ? Math.abs(Math.round(currency)) : Math.abs(currency).toFixed(2);

  return `${currencySymbol}${currency}`;
};

export const valueAttributes = value => {
  const valueIsFinite = Number.isFinite(value);
  const valueIsNonNegative = valueIsFinite ? value >= 0 : undefined;
  const valueIsZero = value === 0;
  return { valueIsFinite, valueIsNonNegative, valueIsZero };
};

export const displayAttributes = (value, formatter, formatterArgs = {}) => {
  const valueIsFinite = Number.isFinite(value);
  const valueIsNonNegative = valueIsFinite ? value >= 0 : undefined;
  const valueIsZero = value === 0;

  const valueToDisplay = valueIsFinite ? formatter(value, formatterArgs) : UNDEFINED_DISPLAY;

  return { valueToDisplay, valueIsFinite, valueIsNonNegative, valueIsZero };
};

export const BigPriceTagIcon = ({ className, value, format = FORMAT_TYPE.CURRENCY, currencySymbol }) => {
  if (!Object.values(FORMAT_TYPE).includes(format)) {
    throw new Error('Developer error: you must include a valid "format" prop on a <PriceTagIcon>');
  }

  const isPercentage = format === FORMAT_TYPE.PERCENTAGE;
  const { valueToDisplay, valueIsFinite, valueIsNonNegative } = isPercentage
    ? displayAttributes(value, formatPercentage)
    : displayAttributes(value, formatCurrency, { currencySymbol });

  const SenseArrow = valueIsNonNegative ? BigUpArrow : BigDownArrow;
  const arrowClassName = valueIsNonNegative ? 'up' : 'down';

  const isLargeCurrencySymbol = currencySymbol.length > 1;

  return (
    <div className={classnames(className, 'big-price-tag-icon')}>
      <BigPriceTagTip className="big-price-tag-tip" />
      <div className={classnames('big-price-tag-content', { 'large-symbol': isLargeCurrencySymbol })}>
        {valueIsFinite && <SenseArrow className={classnames('sense-arrow', { arrowClassName })} />}
        {valueToDisplay}
      </div>
    </div>
  );
};

export const SmallPriceTagIcon = ({
  className,
  value,
  format = FORMAT_TYPE.CURRENCY,
  symbolForZero = UNDEFINED_DISPLAY,
  currencySymbol = '$',
}) => {
  if (!Object.values(FORMAT_TYPE).includes(format)) {
    throw new Error('Developer error: you must include a valid "format" prop on a <PriceTagIcon>');
  }

  const { valueIsFinite, valueIsNonNegative, valueIsZero } = valueAttributes(value);
  let valueToDisplay;
  if (valueIsZero) {
    valueToDisplay = symbolForZero;
  } else {
    switch (format) {
      case FORMAT_TYPE.PERCENTAGE:
        valueToDisplay = formatPercentage(value);
        break;
      case FORMAT_TYPE.PRECISE_PERCENTAGE:
        valueToDisplay = formatPercentage(value);
        break;
      case FORMAT_TYPE.CURRENCY:
        valueToDisplay = formatCurrency(value, { currencySymbol });
        break;
      default:
    }
  }

  const SenseArrow = valueIsNonNegative ? SmallUpArrow : SmallDownArrow;
  const arrowClassName = valueIsNonNegative ? 'up' : 'down';

  return (
    <div className={classnames(className, 'small-price-tag-icon')}>
      <SmallPriceTagTip className="small-price-tag-tip" />
      <div className="small-price-tag-content">
        {valueIsFinite && !valueIsZero ? (
          <SenseArrow className={classnames('sense-arrow', { arrowClassName })} />
        ) : null}
        {valueToDisplay}
      </div>
    </div>
  );
};
