import axios from 'axios';
import camelcase from 'camelcase';
import { readString } from 'react-papaparse';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';
import { logExecutionTime } from '../../utils/performanceMonitoring';

const transformForecastStatSummaryColumns = originalColumnName => {
  let finalColumnName;

  switch (originalColumnName) {
    case 'customer_entity_id':
      finalColumnName = 'locationId';
      break;
    default:
      finalColumnName = camelcase(originalColumnName);
  }

  return finalColumnName;
};

const productDimIdColumnRegex = /^productDim[0-9]+Id$/;

const transformForecastStatsSummaryValues = (value, header) => {
  let finalValue;

  switch (header) {
    case 'prediction':
    case 'monthPrediction':
    case 'quarterPrediction':
    case 'yearPrediction':
      finalValue = parseFloat(value);
      break;
    case 'productId':
    case 'locationId':
      finalValue = parseInt(value, 10);
      break;
    case 'weeksSinceRelease':
      finalValue = parseInt(value, 10);
      break;
    default:
      if (productDimIdColumnRegex.test(header)) {
        finalValue = parseInt(value, 10);
      } else {
        finalValue = value;
      }
      break;
  }

  return finalValue;
};

const fetchRawForecastStats = async (workflowId, output) =>
  axios.get(`${KA_API_URL}/stats/forecast/raw`, {
    params: { workflow_id: workflowId, output },
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/octet-stream, application/json',
    },
  });

const fetchDailyForecastStats = async workflowId => fetchRawForecastStats(workflowId, 'daily_forecast');

const fetchSummaryForecastStats = async workflowId => fetchRawForecastStats(workflowId, 'summary');

const FORECAST_STATS_KEY_BASE = 'forecastStats';
const FORECAST_SUMMARY_STATS_KEY_BASE = 'forecastSummaryStats';

export const useForecastStats = (workflowId, queryConfig) =>
  useQuery(
    [FORECAST_STATS_KEY_BASE, workflowId],
    async () => {
      const response = await fetchDailyForecastStats(workflowId);

      try {
        const { data: rawData } = response;

        const start = performance.now();

        // Convert raw bytes to a string
        const csvData = new TextDecoder('utf-8').decode(rawData);

        // Parse the data
        const { data: rows, errors } = readString(csvData, {
          header: true,
          transformHeader: transformForecastStatSummaryColumns,
          transform: transformForecastStatsSummaryValues,
        });

        // Remove any rows that contained too many or two few columns
        errors.forEach(error => rows.splice(error.row, 1));

        logExecutionTime('Forecast Stats parsing', start, performance.now());

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    queryConfig,
  );

export const useSummaryForecastStats = (workflowId, queryConfig) =>
  useQuery(
    [FORECAST_SUMMARY_STATS_KEY_BASE, workflowId],
    async () => {
      const response = await fetchSummaryForecastStats(workflowId);

      try {
        const { data: rawData } = response;

        const start = performance.now();

        // Convert raw bytes to a string
        const csvData = new TextDecoder('utf-8').decode(rawData);

        // Parse the data
        const { data: rows, errors } = readString(csvData, {
          header: true,
          transformHeader: transformForecastStatSummaryColumns,
          transform: transformForecastStatsSummaryValues,
        });

        // Remove any rows that contained too many or two few columns
        errors.forEach(error => rows.splice(error.row, 1));

        logExecutionTime('Forecast Stats parsing', start, performance.now());

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    queryConfig,
  );
