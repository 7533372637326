import React, { useRef } from 'react';
import * as d3 from 'd3';
import Tooltip from './Tooltip';
import { LIGHT_BLUE, LIGHT_GREEN, FONT_GRAY } from '../../../../../colors';

const MARGIN = {
  TOP: 40,
  RIGHT: 10,
  LEFT: 10,
  BOTTOM: 0,
};

const PADDING = 0.2;

const PaceBarPlot = ({ data, width, height, maxValue, stlyLineValue }) => {
  const svgRef = useRef();
  const tooltipRef = useRef(null);
  const [tooltipContent, setTooltipContent] = React.useState('');
  const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 });

  React.useLayoutEffect(() => {
    const svg = d3.select(svgRef.current);
    const innerWidth = width - MARGIN.LEFT - MARGIN.RIGHT;
    const innerHeight = height - MARGIN.TOP - MARGIN.BOTTOM;

    const handleMouseOver = (content, x, y) => {
      setTooltipPosition({ x, y });
      setTooltipContent(content);
    };

    const handleMouseOut = () => {
      setTooltipContent('');
    };

    // Clear previous plot
    svg.selectAll('*').remove();

    const xScale = d3
      .scaleLinear()
      .domain([0, maxValue])
      .range([0, innerWidth]);

    const yScale = d3
      .scaleBand()
      .domain(data.map((d, i) => i.toString()))
      .range([0, innerHeight])
      .padding(PADDING);

    const g = svg.append('g').attr('transform', `translate(${MARGIN.LEFT},${MARGIN.TOP})`);

    g.append('line')
      .attr('x1', xScale(0))
      .attr('y1', 0)
      .attr('x2', xScale(0))
      .attr('y2', innerHeight)
      .style('stroke', FONT_GRAY)
      .style('stroke-width', 1);

    const bars = g
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', (d, i) => `translate(0, ${yScale(i.toString())})`); // Use index for separation

    bars
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', d => xScale(d.toDate)) // Adjusted width based on toDate
      .attr('height', yScale.bandwidth())
      .style('fill', LIGHT_BLUE)
      .on('mouseover', (d, i) =>
        handleMouseOver(d.totalTooltip, xScale(d.toDate) * 0.25, yScale(i.toString()) + yScale.bandwidth() * 2.5),
      )
      .on('mouseout', handleMouseOut);

    bars
      .append('rect')
      .attr('x', d => xScale(d.toDate))
      .attr('y', 0)
      .attr('width', d => xScale(d.fcst))
      .attr('height', yScale.bandwidth())
      .style('fill', 'url(#diagonal-hatch')
      .on('mouseover', (d, i) =>
        handleMouseOver(
          d.forecastTooltip,
          xScale(d.toDate) + xScale(d.fcst) * 0.25,
          yScale(i.toString()) + yScale.bandwidth() * 2.5,
        ),
      )
      .on('mouseout', handleMouseOut);

    // Add a dashed vertical line at stlyLineValue when d.stlyLine is true
    bars
      .filter(d => d.stlyLine)
      .append('line')
      .attr('x1', xScale(stlyLineValue))
      .attr('y1', -yScale.bandwidth() * PADDING * 0.5)
      .attr('x2', xScale(stlyLineValue))
      .attr('y2', yScale.bandwidth() + yScale.bandwidth() * PADDING * 0.5)
      .style('stroke', LIGHT_GREEN)
      .style('stroke-width', 3)
      .style('stroke-dasharray', '6,3');

    // Add stly tooltip
    bars
      .filter(d => d.stlyLine)
      .append('rect')
      .attr('x', xScale(stlyLineValue) - 7.5)
      .attr('y', -yScale.bandwidth() * PADDING * 0.5)
      .attr('width', 15) // Adjusted width for the hitbox
      .attr('height', yScale.bandwidth() + yScale.bandwidth() * PADDING)
      .style('fill', 'transparent')
      .on('mouseover', (d, i) =>
        handleMouseOver(
          d.stlyTooltip,
          xScale(stlyLineValue) - yScale.bandwidth() * 2.5,
          yScale(i.toString()) + yScale.bandwidth() * 2.5,
        ),
      )
      .on('mouseout', handleMouseOut);

    // Create a diagonal hatch pattern
    const defs = svg.append('defs');
    defs
      .append('pattern')
      .attr('id', 'diagonal-hatch')
      .attr('width', 6)
      .attr('height', 6)
      .attr('patternTransform', 'rotate(45 0 0)')
      .attr('patternUnits', 'userSpaceOnUse')
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', 10)
      .attr('stroke', LIGHT_BLUE)
      .attr('stroke-width', 6);
  }, [data, width, height, stlyLineValue, maxValue]);

  return (
    <div style={{ position: 'relative', overflow: 'visible' }}>
      <svg ref={svgRef} width={width} height={height} />
      {tooltipContent && <Tooltip ref={tooltipRef} {...tooltipPosition} content={tooltipContent} />}
    </div>
  );
};

export default PaceBarPlot;
