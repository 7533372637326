/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import _ from 'lodash';
import axios from '../../utils/axios-client';
import FileUpload from './FileUpload';
import Header from '../header/header';
import Filters from './Filters';
import DataConfigurationTable from './DataConfigurationTable';
import { connectDatasetConfigToDataset } from './datasetConfig/DatasetConfigFunctions';
import DataSetTable from './DataSetTable';
import { KA_API_URL } from '../../config/baseUrl';
import { deleteDataset } from '../../data-access/mutation/datasets';
import './DataCenter.css';

class DataCenter extends Component {
  state = {
    file: [],
    openModal: false,
    loadingFile: false,
    modalError: false,
    modalMessage: '',
    step: 1,
    datasets: [],
    finalDatasets: [],
    dataConfigurations: [],
    finalDataConfigurations: [],
    newFileAddedId: '',
    dataConfigurationsAvailable: true,
    datasetsAvailable: true,
    datasetConfigId: '',
    selectedDataTypes: null,
    dataTypes: [],
    selectedDataConfigurations: null,
    allDataConfigurations: [],
    selectedSources: null,
    sources: [],
    datasetDescriptionValue: '',
    dataConfigurationSortObject: {
      data_type_description: {
        is_sorted: false,
        direction: 'ASC',
      },
      name: {
        is_sorted: false,
        direction: 'ASC',
      },
      number_of_datasets: {
        is_sorted: false,
        direction: 'ASC',
      },
      total_record_count: {
        is_sorted: false,
        direction: 'ASC',
      },
    },
    currentDataConfigurationSortProp: '',
    defaultDataConfigurationSortArray: ['name'],
    datasetSortObject: {
      data_type_description: {
        is_sorted: false,
        direction: 'ASC',
      },
      data_configuration: {
        is_sorted: false,
        direction: 'ASC',
      },
      description: {
        is_sorted: false,
        direction: 'ASC',
      },
      source: {
        is_sorted: false,
        direction: 'ASC',
      },
      last_fetched: {
        is_sorted: false,
        direction: 'ASC',
      },
      record_count: {
        is_sorted: false,
        direction: 'ASC',
      },
    },
    currentDatasetSortProp: '',
    defaultDatasetSortArray: ['last_fetched', 'description'],
    error: false,
    errorMessage: '',
    openEditModal: false,
  };

  async componentDidMount() {
    await this.renderDataSets();
  }

  // On Component Load Methods
  renderDataSets = async () => {
    try {
      const datasetResponse = await axios.get(`${KA_API_URL}/datasets`);
      const dataConfigurationResponse = await axios.get(`${KA_API_URL}/datasets/config`);

      const datasets = datasetResponse.data;
      const dataConfigurations = dataConfigurationResponse.data;

      await this.setState({
        datasetsAvailable: datasets.length > 0,
        dataConfigurationsAvailable: dataConfigurations.length > 0,
      });

      await this.buildDataSetsAndConfigurations(datasets, dataConfigurations);
      await this.calculateFilters(datasets, dataConfigurations);
    } catch (error) {
      await this.setState({
        error: true,
        errorMessage: error.response == null ? error.message : error.response.data.message,
      });
    }
  };

  buildDataSetsAndConfigurations = async (datasets, dataConfigurations) => {
    const map = new Map();

    datasets.forEach(dataset => {
      const recordCount = dataset.record_count == null ? 0 : dataset.record_count;
      if (map.has(dataset.dataset_config_id)) {
        const statObject = map.get(dataset.dataset_config_id);
        statObject.number_of_datasets += 1;
        statObject.total_record_count += recordCount;

        map.set(dataset.dataset_config_id, statObject);
      } else {
        map.set(dataset.dataset_config_id, {
          number_of_datasets: 1,
          total_record_count: recordCount,
        });
      }

      dataset.description = dataset.description !== null ? dataset.description : dataset.file_name;
      dataset.last_fetched = dataset.fetched_gmt_datetime;
      dataset.source = dataset.location_url_type_description;
    });

    dataConfigurations.forEach(configuration => {
      const statObject = map.get(configuration.dataset_config_id);
      if (statObject) {
        configuration.number_of_datasets = statObject.number_of_datasets;
        configuration.total_record_count = statObject.total_record_count;
      } else {
        configuration.number_of_datasets = null;
        configuration.total_record_count = 0;
      }
    });

    // for datasets that are appended to look at the most recent metadata to get just the last record count
    const datasetMap = new Map(datasets.map(obj => [obj.dataset_id, obj]));
    const appendDatasets = datasets.filter(e => e.is_no_replace);
    const promises = appendDatasets.map(dataset =>
      axios.get(`${KA_API_URL}/datasets/meta/${dataset.dataset_id}?pay_max=true`),
    );
    const responses = await Promise.all(promises);
    const datasetsAndMetaResponses = _.zip(appendDatasets, responses);

    datasetsAndMetaResponses.forEach(([dataset, datasetMeta]) => {
      if (datasetMeta.data.length > 0) {
        datasetMap.get(dataset.dataset_id).record_count = datasetMeta.data[0].record_count;
      }
    });

    dataConfigurations = await this.getDataConfigurationsSortedArray(dataConfigurations);
    datasets = await this.getDatasetsSortedArray(datasets);

    const finalDatasets = _.cloneDeep(datasets);
    const finalDataConfigurations = _.cloneDeep(dataConfigurations);
    await this.setState({
      datasets,
      dataConfigurations,
      finalDatasets,
      finalDataConfigurations,
    });
  };

  // Datasets Table Methods
  calculateDatasetsSortData = async id => {
    const { currentDatasetSortProp, datasetSortObject, datasets } = this.state;
    if (currentDatasetSortProp !== '' && currentDatasetSortProp !== id) {
      datasetSortObject[currentDatasetSortProp].is_sorted = false;
    }
    if (datasetSortObject[id].is_sorted === false) {
      datasetSortObject[id].is_sorted = true;
      datasetSortObject[id].direction = 'ASC';
    } else if (datasetSortObject[id].direction === 'ASC') {
      datasetSortObject[id].direction = 'DESC';
    } else {
      datasetSortObject[id].direction = 'ASC';
    }

    await this.setState({
      datasetSortObject,
      currentDatasetSortProp: id,
    });

    const sortedDataSets = await this.getDatasetsSortedArray(datasets);

    await this.setState({
      datasets: sortedDataSets,
    });
  };

  getDatasetsSortedArray = data => {
    const { currentDatasetSortProp, datasetSortObject, defaultDatasetSortArray } = this.state;
    const sortBy = [];

    if (currentDatasetSortProp !== '') {
      const { direction } = datasetSortObject[currentDatasetSortProp];
      sortBy.push({
        prop: currentDatasetSortProp,
        direction: direction === 'ASC' ? 1 : -1,
      });
    }

    const clonedSortArray = _.cloneDeep(defaultDatasetSortArray);

    let modifiedSortArray = [];
    if (currentDatasetSortProp !== '') {
      modifiedSortArray = clonedSortArray.filter(d => d !== currentDatasetSortProp);
    } else {
      modifiedSortArray = clonedSortArray;
    }
    modifiedSortArray.map(d => {
      sortBy.push({
        prop: d,
        direction: d === 'last_fetched' ? -1 : 1,
      });
    });

    const sortedResult = data.sort((a, b) => {
      let i = 0;
      let result = 0;
      while (i < sortBy.length && result === 0) {
        let aModified = '';
        let bModified = '';
        if (sortBy[i].prop === 'record_count') {
          aModified = a[sortBy[i].prop] !== null ? a[sortBy[i].prop] : 0;
          bModified = b[sortBy[i].prop] !== null ? b[sortBy[i].prop] : 0;
        } else {
          aModified = a[sortBy[i].prop] !== null ? a[sortBy[i].prop] : '';
          bModified = b[sortBy[i].prop] !== null ? b[sortBy[i].prop] : '';
        }

        result = sortBy[i].direction * (aModified < bModified ? -1 : aModified > bModified ? 1 : 0);
        i += 1;
      }
      return result;
    });

    return sortedResult;
  };

  // Filters Method
  calculateFilters = async (datasets, dataConfigurations) => {
    const dataTypes = [];
    const dataTypesSet = new Set();
    const allDataConfigurations = [];
    const dataConfigurationsSet = new Set();
    const sources = [];
    const sourcesSet = new Set();

    for (let i = 0; i < dataConfigurations.length; i++) {
      if (!dataTypesSet.has(dataConfigurations[i].data_type)) {
        dataTypes.push({ value: dataConfigurations[i].data_type, label: dataConfigurations[i].data_type_description });
        dataTypesSet.add(dataConfigurations[i].data_type);
      }

      if (!dataConfigurationsSet.has(dataConfigurations[i].dataset_config_id)) {
        allDataConfigurations.push({
          value: dataConfigurations[i].dataset_config_id,
          label: dataConfigurations[i].name,
        });
        dataConfigurationsSet.add(dataConfigurations[i].dataset_config_id);
      }
    }

    datasets.forEach(element => {
      const sourceEntry = { value: element.location_url_type, label: element.location_url_type_description };
      if (!sourcesSet.has(element.location_url_type)) {
        sources.push(sourceEntry);
        sourcesSet.add(element.location_url_type);
      }
    });

    dataTypes.sort((a, b) => (a.label > b.label ? 1 : -1));
    allDataConfigurations.sort((a, b) => (a.label > b.label ? 1 : -1));
    sources.sort((a, b) => (a.label > b.label ? 1 : -1));

    await this.setState({
      dataTypes,
      allDataConfigurations,
      datasetConfigId: allDataConfigurations.length > 0 ? allDataConfigurations[0].value : -1,
      sources,
    });
  };

  handleFilterChange = async (selectedOption, propName) => {
    await this.setState({
      [propName]: selectedOption,
    });
  };

  handleDataSetDescriptionChange = async e => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  applyFilters = async e => {
    e.preventDefault();
    const {
      finalDatasets,
      finalDataConfigurations,
      selectedDataTypes,
      selectedDataConfigurations,
      datasetDescriptionValue,
      selectedSources,
    } = this.state;

    let datasets = _.cloneDeep(finalDatasets);
    let dataConfigurations = _.cloneDeep(finalDataConfigurations);

    if (selectedDataTypes !== null && selectedDataTypes.length !== 0) {
      const dataTypesSet = new Set();
      selectedDataTypes.map(item => dataTypesSet.add(item.value));

      datasets = datasets.filter(item => {
        return dataTypesSet.has(item.data_type);
      });

      dataConfigurations = dataConfigurations.filter(item => {
        return dataTypesSet.has(item.data_type);
      });
    }

    if (selectedDataConfigurations !== null && selectedDataConfigurations.length !== 0) {
      const dataConfigurationsSet = new Set();
      selectedDataConfigurations.map(item => dataConfigurationsSet.add(item.value));

      datasets = datasets.filter(item => {
        return dataConfigurationsSet.has(item.dataset_config_id);
      });

      dataConfigurations = dataConfigurations.filter(item => {
        return dataConfigurationsSet.has(item.dataset_config_id);
      });
    }

    if (selectedSources !== null && selectedSources.length !== 0) {
      const sourcesSet = new Set();
      const configIdSetFromSources = new Set();
      selectedSources.map(item => sourcesSet.add(item.label));
      datasets = datasets.filter(item => {
        return sourcesSet.has(item.source);
      });

      datasets.map(dataset => configIdSetFromSources.add(dataset.dataset_config_id));
      dataConfigurations = dataConfigurations.filter(config => configIdSetFromSources.has(config.dataset_config_id));
    }

    if (datasetDescriptionValue !== '') {
      const query = datasetDescriptionValue.toLowerCase();
      const configIdSetFromDescription = new Set();
      datasets = datasets.filter(item => {
        return item.description && item.description.toLowerCase().includes(query);
      });

      datasets.map(dataset => configIdSetFromDescription.add(dataset.dataset_config_id));
      dataConfigurations = dataConfigurations.filter(config =>
        configIdSetFromDescription.has(config.dataset_config_id),
      );
    }

    await this.setState({
      datasetsAvailable: datasets.length > 0,
      dataConfigurationsAvailable: dataConfigurations.length > 0,
    });

    const sortedDatasets = this.getDatasetsSortedArray(datasets);
    const sortedDataConfigurations = this.getDataConfigurationsSortedArray(dataConfigurations);

    await this.setState({
      datasets: sortedDatasets,
      dataConfigurations: sortedDataConfigurations,
    });
  };

  // File Upload methods
  onfileUploadClick = async e => {
    e.target.value = null;
  };

  openModal = async e => {
    e.preventDefault();
    const { openModal } = this.state;

    await this.setState({
      openModal: !openModal,
      loadingFile: false,
      modalError: false,
      modalMessage: '',
      step: 1,
    });
  };

  onfileUploadChange = async e => {
    if (e.target.files.length > 0) {
      await this.setState({
        file: e.target.files[0],
      });
    }
  };

  uploadFile = async e => {
    e.preventDefault();
    const formData = new FormData();
    const { file } = this.state;
    const data = file;
    formData.append('file', data);
    formData.append('user_id', localStorage.getItem('id'));

    await this.setState({
      loadingFile: true,
      modalMessage: 'Processing the file',
    });

    try {
      const response = await axios.post(`${KA_API_URL}/datasets`, formData);
      if (response.status === 202) {
        await this.setState({
          step: 2,
          newFileAddedId: response.data.dataset_id,
          loadingFile: false,
          modalMessage: '',
        });
      }
    } catch (err) {
      await this.setState({
        loadingFile: false,
        modalError: true,
        modalMessage: 'Upload failed. Please try again',
      });
    }
  };

  // When user cancels at the select configuration step, delete the file
  onCancelConfigStep = async () => {
    const { newFileAddedId: datasetId, openModal } = this.state;
    try {
      await deleteDataset(datasetId);
    } finally {
      this.setState({
        openModal: !openModal,
        loadingFile: false,
        modalError: false,
        modalMessage: '',
        step: 1,
      });
    }
  };

  // Data Configurations Change And Save methods
  handleConfigIdChange = async e => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  saveConfiguration = async () => {
    const { datasetConfigId, newFileAddedId: datasetId } = this.state;
    try {
      const response = await connectDatasetConfigToDataset(datasetId, parseInt(datasetConfigId, 10));

      if (response.status === 200) {
        await this.setState({
          openModal: false,
          openEditModal: false,
          step: 1,
        });

        await this.renderDataSets();
      }
    } catch (err) {
      await this.setState({
        modalError: true,
      });
    }
  };

  openConfigurationPage = async () => {
    const { newFileAddedId } = this.state;
    const { history } = this.props;

    await localStorage.setItem('fileId', newFileAddedId);
    history.push({
      pathname: '/dataconfigurations',
      state: {
        fileId: newFileAddedId,
      },
    });
  };

  // Data Configuration Table Methods
  calculateDataConfigurationSortData = async id => {
    const { currentDataConfigurationSortProp, dataConfigurationSortObject, dataConfigurations } = this.state;
    if (currentDataConfigurationSortProp !== '' && currentDataConfigurationSortProp !== id) {
      dataConfigurationSortObject[currentDataConfigurationSortProp].is_sorted = false;
    }
    if (dataConfigurationSortObject[id].is_sorted === false) {
      dataConfigurationSortObject[id].is_sorted = true;
      dataConfigurationSortObject[id].direction = 'ASC';
    } else if (dataConfigurationSortObject[id].direction === 'ASC') {
      dataConfigurationSortObject[id].direction = 'DESC';
    } else {
      dataConfigurationSortObject[id].direction = 'ASC';
    }

    await this.setState({
      dataConfigurationSortObject,
      currentDataConfigurationSortProp: id,
    });

    const sortedDataConfigurtions = await this.getDataConfigurationsSortedArray(dataConfigurations);

    await this.setState({
      dataConfigurations: sortedDataConfigurtions,
    });
  };

  getDataConfigurationsSortedArray = data => {
    const {
      currentDataConfigurationSortProp,
      dataConfigurationSortObject,
      defaultDataConfigurationSortArray,
    } = this.state;
    const sortBy = [];

    if (currentDataConfigurationSortProp !== '') {
      const { direction } = dataConfigurationSortObject[currentDataConfigurationSortProp];
      sortBy.push({
        prop: currentDataConfigurationSortProp,
        direction: direction === 'ASC' ? 1 : -1,
      });
    }

    const clonedSortArray = _.cloneDeep(defaultDataConfigurationSortArray);

    let modifiedSortArray = [];
    if (currentDataConfigurationSortProp !== '') {
      modifiedSortArray = clonedSortArray.filter(d => d !== currentDataConfigurationSortProp);
    } else {
      modifiedSortArray = clonedSortArray;
    }
    modifiedSortArray.map(d => {
      sortBy.push({
        prop: d,
        direction: d === 'total_record_count' ? -1 : 1,
      });
    });

    const sortedResult = data.sort((a, b) => {
      let i = 0;
      let result = 0;
      while (i < sortBy.length && result === 0) {
        let aModified = '';
        let bModified = '';
        if (sortBy[i].prop === 'total_record_count') {
          aModified = a[sortBy[i].prop] == null ? 0 : a[sortBy[i].prop];
          bModified = b[sortBy[i].prop] == null ? 0 : b[sortBy[i].prop];
        } else {
          aModified = a[sortBy[i].prop] == null ? '' : a[sortBy[i].prop];
          bModified = b[sortBy[i].prop] == null ? '' : b[sortBy[i].prop];
        }

        result = sortBy[i].direction * (aModified < bModified ? -1 : aModified > bModified ? 1 : 0);
        i += 1;
      }
      return result;
    });

    return sortedResult;
  };

  editDataConfiguration = async (e, rowData) => {
    e.preventDefault();

    await this.setState({
      openEditModal: true,
      newFileAddedId: rowData.original.dataset_id,
    });
  };

  deleteDataSet = async (e, rowData) => {
    e.preventDefault();
    const dataSetId = rowData.original.dataset_id;
    try {
      await axios.delete(`${KA_API_URL}/datasets/${dataSetId}`);
      await this.renderDataSets();
    } catch (error) {
      await this.setState({
        error: true,
        errorMessage: error.response == null ? error.message : error.response.data.message,
      });
    }
  };

  render() {
    const {
      openModal,
      loadingFile,
      modalError,
      step,
      modalMessage,
      allDataConfigurations,
      error,
      errorMessage,
      selectedDataTypes,
      selectedDataConfigurations,
      selectedSources,
      dataTypes,
      sources,
      datasetDescriptionValue,
      dataConfigurationSortObject,
      datasetsAvailable,
      dataConfigurationsAvailable,
      dataConfigurations,
      datasets,
      datasetSortObject,
      openEditModal,
    } = this.state;

    const closeEditModal = () => {
      this.setState({
        openEditModal: !openEditModal,
      });
    };

    let errorMessageFlag = '';
    if (error) {
      errorMessageFlag = (
        <div
          style={{
            fontSize: '12px',
            color: 'red',
            textAlign: 'center',
            padding: '6px',
          }}
        >
          <h5>{errorMessage}</h5>
        </div>
      );
    }
    return (
      <div className="data-center">
        <Header />
        <h2>Data Center - Datasets and Configurations</h2>
        {errorMessageFlag}

        <FileUpload
          openModal={openModal}
          loadingFile={loadingFile}
          modalError={modalError}
          step={step}
          modalMessage={modalMessage}
          onOpenModalButtonClick={this.openModal}
          onFileUploadClick={this.onfileUploadClick}
          onFileUploadChange={this.onfileUploadChange}
          onSubmitFile={this.uploadFile}
          dataConfigurations={allDataConfigurations}
          onDataConfigurationSelectChange={this.handleConfigIdChange}
          onDataConfigurationSave={this.saveConfiguration}
          onCreateNewDataConfiguration={this.openConfigurationPage}
          onCancelConfigStep={this.onCancelConfigStep}
        />
        <br />
        <Filters
          dataTypeValue={selectedDataTypes}
          dataTypeOptions={dataTypes}
          onDataTypeChange={selectedOption => this.handleFilterChange(selectedOption, 'selectedDataTypes')}
          dataConfigurationValue={selectedDataConfigurations}
          dataConfigurationOptions={allDataConfigurations}
          onDatasetDescriptionChange={this.handleDataSetDescriptionChange}
          onDataConfigurationChange={selectedOption =>
            this.handleFilterChange(selectedOption, 'selectedDataConfigurations')
          }
          sourceValue={selectedSources}
          sourceOptions={sources}
          onSourceChange={selectedOption => this.handleFilterChange(selectedOption, 'selectedSources')}
          datasetDescriptionValue={datasetDescriptionValue}
          onButtonClick={this.applyFilters}
        />

        <DataConfigurationTable
          dataConfigurations={dataConfigurations}
          dataConfigurationsAvailable={dataConfigurationsAvailable}
          sortObject={dataConfigurationSortObject}
          onSortClick={this.calculateDataConfigurationSortData}
        />
        <br />
        <DataSetTable
          datasets={datasets}
          datasetsAvailable={datasetsAvailable}
          sortObject={datasetSortObject}
          onSortClick={this.calculateDatasetsSortData}
          openEditModal={openEditModal}
          onEditClick={this.editDataConfiguration}
          onDeleteClick={this.deleteDataSet}
          allDataConfigurations={allDataConfigurations}
          onDataConfigurationSelectChange={this.handleConfigIdChange}
          onDataConfigurationSave={this.saveConfiguration}
          onCreateNewDataConfiguration={this.openConfigurationPage}
          onCloseEditModal={closeEditModal}
        />
      </div>
    );
  }
}

export default DataCenter;
