import React from 'react';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import classnames from 'classnames';
import { faCheckCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfigTableContext } from './configTableContext';
import { SUCCESS_GREEN } from '../../../colors';

const ConfigAccordionItem = ({ children, title, uuid, valid }) => {
  const { expandedAccordion } = useConfigTableContext();

  const placeholderText = `Nothing to show.`;

  const isExpanded = expandedAccordion.includes(uuid);
  const ToggleExpandedButton = () => (
    <button
      tabIndex={0}
      className={classnames('accordion-toggle-expansion-button', isExpanded ? 'expanded' : 'collapsed')}
      type="button"
    >
      <FontAwesomeIcon icon={faChevronRight} size="sm" />
    </button>
  );

  return (
    <AccordionItem uuid={uuid} className={classnames('accordion__item', isExpanded ? 'expanded' : 'collapsed')}>
      <AccordionItemHeading>
        <AccordionItemButton>
          <span>{title}</span>
          <span className="d-flex align-items-center">
            {valid && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={SUCCESS_GREEN}
                style={{ backgroundColor: 'white', borderRadius: '50%', fontSize: '24px' }}
              />
            )}
            <ToggleExpandedButton />
          </span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>{children ?? placeholderText}</AccordionItemPanel>
    </AccordionItem>
  );
};

export default ConfigAccordionItem;
