const productDimRegex = /^product_dim_[0-9]+$/;

const isProductDimensionColumn = columnName => {
  return productDimRegex.test(columnName);
};

const remapColumnName = (columnName, dimensions) => {
  const dimIds = dimensions.map(dim => dim.product_dimension_id);

  let dimName = columnName;
  if (isProductDimensionColumn(columnName)) {
    const parts = columnName.split('_');
    const dimId = parseInt(parts[parts.length - 1], 10);

    if (dimIds.includes(dimId)) {
      const dimToUse = dimensions.find(dim => dim.product_dimension_id === dimId);
      if (dimToUse) {
        dimName = dimToUse.name;
      }
    }
  }

  return dimName;
};

/**
 * Replaces CSV column names that match productDimRegex with their product dimension names
 */
const transformHeader = (headerColumns, dimensions) => {
  if (dimensions && dimensions.length > 0) {
    return headerColumns.map(columnName => remapColumnName(columnName, dimensions));
  }

  return headerColumns;
};

export default transformHeader;