/* eslint-disable react/display-name */
import React from 'react';
import { Modal } from 'react-bootstrap';
import Select, { components } from 'react-select';
import ReactTable from 'react-table';
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ProfitRoverCancelButton, ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import { Country, getRegionSpecificData } from '../../generic/countryConstants';
import { ProfitRoverModalCard, ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';
import { KaizenProduct } from '../../generic/subscriptions/SubscriptionConstants';
import { useTrialingStatusForProduct } from '../../routing/subscriptionStatusHooks';
import { countryDropdownOptions } from '../../util/countryOptions';
import { MID_GREEN } from '../../../colors';
import locationsIcon from '../../../images/locations-icon.svg';
import { isValidZipCode, isValidPostalCode } from '../../../utils/validators';
import './create-location-modal.scss';

const EditableCell = props => {
  const { value, onChange, placeholder } = props;

  return (
    <TextareaAutosize
      value={value}
      onChange={e => onChange(e.target.value)}
      className={value ? 'has-value' : 'has-no-value'}
      placeholder={placeholder}
    />
  );
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faCaretDown} style={{ color: '#000000' }} />
    </components.DropdownIndicator>
  );
};

const CreateLocationModalHeader = ({ isTrialing }) => (
  <>
    <div className="create-location-modal-header">
      <span className="header-text step-text">Add a New Location</span>
      <span className="step-icon">
        <img src={locationsIcon} alt="Manual Logo" style={{ width: '82px' }} />
      </span>
    </div>
    {isTrialing && (
      <div className="location-select-table-instruction-line-1">
        You can get started with <strong>one free location</strong>.
        <i>
          You <strong>cannot</strong> change your free location after finishing your settings!
        </i>
      </div>
    )}
    <div className="location-select-table-instruction-line-2">
      Please add your address details to set up your location.
    </div>
  </>
);

const CreateLocationModal = props => {
  const { initialLocationName, onHide, onFinish } = props;

  const [locationName, setLocationName] = React.useState(initialLocationName);
  const [locationAddress, setLocationAddress] = React.useState();
  const [locationCity, setLocationCity] = React.useState();
  const [locationRegion, setLocationRegion] = React.useState();
  const [locationCountry, setLocationCountry] = React.useState();
  const [locationZip, setLocationZip] = React.useState();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const isButtonEnabled =
    locationName &&
    locationAddress &&
    locationCity &&
    locationRegion &&
    locationCountry &&
    (isValidZipCode(locationZip) || isValidPostalCode(locationZip)) &&
    !isSubmitting;

  const countryIsCanada = locationCountry?.value === Country.CA;
  const { regionLabel, regionDropdownOptions, postcodeLabel } = getRegionSpecificData(countryIsCanada);

  const isTrialing = useTrialingStatusForProduct(KaizenProduct.KP);

  const confirmLocation = () => {
    setIsSubmitting(true);
    const newLocation = {
      location_description: locationName,
      address: locationAddress,
      city: locationCity,
      state: locationRegion.value,
      country_code: locationCountry.value,
      country_name: locationCountry.label,
      zip: locationZip,
    };

    onFinish(newLocation);
  };

  const HEADER_STYLES = {
    background: MID_GREEN,
    color: 'white',
    textAlign: 'left',
    fontWeight: 'bold',
    paddingLeft: '10px',
    borderWidth: 0,
  };

  const COMMON_DROPDOWN_STYLES = {
    indicatorSeparator: () => ({
      display: 'none',
    }),
    valueContainer: () => ({
      height: '27px',
    }),
    indicatorsContainer: () => ({
      height: '27px',
    }),
    placeholder: provided => ({
      ...provided,
      paddingLeft: '0.2rem',
    }),
    input: () => ({
      height: '27px',
      paddingLeft: '0.5rem',
    }),
    menu: provided => ({
      ...provided,
      height: '27px',
      width: 'max-content',
      marginRight: '8px',
      minWidth: '100%',
      marginTop: '0px',
    }),
    menuList: provided => ({
      ...provided,
      fontSize: '14px',
      background: 'white',
      overflowX: 'hidden',
    }),
  };

  const regionCountryDropdownStyle = {
    ...COMMON_DROPDOWN_STYLES,
    container: () => ({
      position: 'absolute',
      top: '38px',
      fontSize: '14px',
      minWidth: 'inherit',
      width: 'inherit',
      paddingRight: '0.5rem',
      backgroundColor: 'transparent',
    }),
    control: (provided, controlState) => ({
      ...provided,
      minHeight: 'unset',
      height: '27px',
      boxShadow: controlState.isFocused ? '0px 1px 8px #FFC500D9' : '',
      border: locationRegion ? 'none' : '1px solid #707070',
      borderRadius: '4px',
      backgroundColor: 'transparent',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      height: '27px',
      display: locationRegion ? 'none' : 'flex',
      alignItems: 'center',
    }),
    singleValue: provided => ({
      ...provided,
      paddingLeft: '0.5rem',
    }),
  };

  const columns = [
    {
      id: 'location',
      Header: 'Location Name',
      headerStyle: { ...HEADER_STYLES, minWidth: '160px' },
      style: { paddingLeft: '0.5rem', minWidth: '160px' },
      Cell: () => {
        return <EditableCell value={locationName} onChange={setLocationName} placeholder="Location name" />;
      },
    },
    {
      id: 'country',
      Header: 'Country',
      maxWidth: 100,
      headerStyle: { ...HEADER_STYLES, minWidth: '75px' },
      style: { paddingLeft: '0.5rem', minWidth: '75px' },
      Cell: () => {
        return (
          <Select
            components={{ DropdownIndicator }}
            value={locationCountry}
            options={countryDropdownOptions}
            onChange={selectedCountry => {
              if (selectedCountry.value !== locationCountry?.value) {
                setLocationCountry(selectedCountry);
                setLocationRegion(null);
              }
            }}
            placeholder="Country"
            styles={regionCountryDropdownStyle}
          />
        );
      },
    },
    {
      id: 'address',
      Header: 'Address',
      headerStyle: { ...HEADER_STYLES, minWidth: '160px' },
      style: { paddingLeft: '0.5rem', minWidth: '160px' },
      Cell: () => {
        return <EditableCell value={locationAddress} onChange={setLocationAddress} placeholder="Address" />;
      },
    },
    {
      id: 'city',
      Header: 'City',
      headerStyle: { ...HEADER_STYLES, minWidth: '160px' },
      style: { paddingLeft: '0.5rem', minWidth: '160px' },
      Cell: () => {
        return <EditableCell value={locationCity} onChange={setLocationCity} placeholder="City" />;
      },
    },
    {
      id: 'state',
      Header: regionLabel,
      maxWidth: 100,
      headerStyle: { ...HEADER_STYLES, minWidth: '75px' },
      style: { paddingLeft: '0.5rem', minWidth: '75px' },
      Cell: () => {
        return (
          <Select
            components={{ DropdownIndicator }}
            value={locationRegion}
            options={regionDropdownOptions}
            onChange={e => setLocationRegion(e)}
            placeholder="State"
            styles={regionCountryDropdownStyle}
          />
        );
      },
    },
    {
      id: 'zipcode',
      Header: postcodeLabel,
      headerStyle: { ...HEADER_STYLES, minWidth: '105px' },
      style: { paddingLeft: '0.5rem' },
      maxWidth: 120,
      Cell: () => {
        return <EditableCell value={locationZip} onChange={setLocationZip} placeholder={postcodeLabel} />;
      },
    },
  ];

  return (
    <Modal show centered dialogClassName="create-location-modal-dialog">
      <ProfitRoverModalCard>
        <form>
          <ProfitRoverModalMain>
            <div className="create-location-modal-container">
              <CreateLocationModalHeader isTrialing={isTrialing} />
              <div>
                <ReactTable
                  columns={columns}
                  className="location-edit-table"
                  minRows={0}
                  showPagination={false}
                  sortable={false}
                  data={[{}]}
                />
              </div>
            </div>
          </ProfitRoverModalMain>

          <ProfitRoverModalFooter>
            <div className="create-location-modal-footer">
              <ProfitRoverCancelButton onClick={onHide}>Cancel</ProfitRoverCancelButton>
              <ProfitRoverPrimaryButton onClick={confirmLocation} disabled={!isButtonEnabled}>
                Add {isTrialing ? 'Trial' : 'This'} Location
              </ProfitRoverPrimaryButton>
            </div>
          </ProfitRoverModalFooter>
        </form>
      </ProfitRoverModalCard>
    </Modal>
  );
};

export default CreateLocationModal;
