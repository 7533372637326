import React from 'react';
import ColumnBasedFieldSelection from '../../../ColumnBasedFieldSelection';
import {
  ActionButton,
  ContentArea,
  PromptText,
  RadioButtonRow,
  SmallRadioButton,
  TextInput,
} from '../../../commonFormComponents';
import { DataSourceType, unknownDataSourceTypeError } from '../../../DatasetConfigFormConstants';
import DimensionSelection from './DimensionSelection';
import DimensionSelectionReview from './DimensionSelectionReview';

const DimFieldCreationFlow = props => {
  const {
    addDimensionToConfig,
    selectableDimensions,
    dataSample,
    // dimensionValues,
    industry,
    letterLabel,
    onCancelAddingDimension,
    refetchDimensions,
  } = props;

  const [selectedDimension, setSelectedDimension] = React.useState();
  const [fieldInData, setFieldInData] = React.useState();
  const [columnName, setColumnName] = React.useState();
  const [fixedValue, setFixedValue] = React.useState('');
  const [formula, setFormula] = React.useState('');
  const [dataSourceType, setDataSourceType] = React.useState();

  // Dimension values the user is allowed to see and add manually
  const dimOptions = React.useMemo(
    () =>
      selectableDimensions.map(dim => {
        return {
          label: dim.name,
          value: dim,
        };
      }),
    [selectableDimensions],
  );

  // For when we want the user to be able to select pre-existing dimension values as fixed values
  // const dimValueOptions = React.useMemo(() => {
  //   if (selectedDimension != null) {
  //     return dimensionValues
  //       .filter(dimValue => dimValue.dimension_id === selectedDimension.product_dimension_id)
  //       .map(({ value }) => ({ label: value, value }));
  //   }
  //   return [];
  // }, [dimensionValues, selectedDimension]);

  const fixedValueSatisfied = dataSourceType === DataSourceType.FIXED_VALUE && fixedValue !== '';
  const columnNameSatisfied = dataSourceType === DataSourceType.FROM_COLUMN && columnName != null;
  const formulaSatisfied = dataSourceType === DataSourceType.FORMULA && formula != null && formula !== '';
  const userHasSpecifiedValue = fixedValueSatisfied || columnNameSatisfied || formulaSatisfied;
  const dimCreatable = fieldInData != null && userHasSpecifiedValue;

  const createDimensionMapping = () => {
    let dimensionValue;

    if (dataSourceType === DataSourceType.FIXED_VALUE) {
      dimensionValue = fixedValue;
    } else if (dataSourceType === DataSourceType.FROM_COLUMN) {
      dimensionValue = columnName;
    } else if (dataSourceType === DataSourceType.FORMULA) {
      dimensionValue = formula;
    } else {
      throw unknownDataSourceTypeError(dataSourceType);
    }

    addDimensionToConfig(selectedDimension, dimensionValue, dataSourceType);
  };

  return (
    <div>
      {/* The user can select an existing dimension or create a new one (which will get selected automatically) */}
      {!selectedDimension ? (
        <DimensionSelection
          dimOptions={dimOptions}
          industry={industry}
          onSelectDimension={value => setSelectedDimension(value)}
          onCancelAddingDimension={onCancelAddingDimension}
          refetchDimensions={refetchDimensions}
        />
      ) : (
        <DimensionSelectionReview
          letterLabel={letterLabel}
          value={selectedDimension?.name}
          onClick={() => {
            setSelectedDimension(null);
          }}
          onCancel={onCancelAddingDimension}
        />
      )}

      {selectedDimension != null && (
        <ContentArea>
          <PromptText label="1.">Does this field exist in your data set?</PromptText>

          <RadioButtonRow>
            <SmallRadioButton
              selected={fieldInData === true}
              onClick={() => {
                setFieldInData(true);

                if (fieldInData === false) {
                  // Don't set to undefined if the user clicks this button while it's already selected
                  setDataSourceType(undefined);
                }
              }}
            >
              I think so!
            </SmallRadioButton>
            <SmallRadioButton
              selected={fieldInData === false}
              onClick={() => {
                setFieldInData(false);
                setDataSourceType(DataSourceType.FIXED_VALUE);
              }}
            >
              No
            </SmallRadioButton>
          </RadioButtonRow>

          {fieldInData != null && (
            <>
              {fieldInData ? (
                <>
                  <PromptText label="2.">
                    Look through your data and select the column that matches up with{' '}
                    <strong>{selectedDimension.name}</strong>.
                  </PromptText>

                  <ColumnBasedFieldSelection
                    dataSamples={dataSample}
                    columnName={columnName}
                    setColumnName={setColumnName}
                    formula={formula}
                    setFormula={setFormula}
                    dataSourceType={dataSourceType}
                    setDataSourceType={setDataSourceType}
                  />
                </>
              ) : (
                <>
                  <PromptText label="2.">What fixed value do you want to give this?</PromptText>

                  <TextInput value={fixedValue} onChange={e => setFixedValue(e.target.value)} />

                  {/* One day, we'll probably want both possibilities here */}

                  {/* <DropdownSelect
                    key="fixedValue"
                    options={dimValueOptions}
                    onSelect={value => setFixedValue(value)}
                    value={fixedValue}
                  /> */}
                </>
              )}
            </>
          )}

          {dimCreatable && (
            <ActionButton onClick={createDimensionMapping} style={{ marginTop: 30 }}>
              Confirm
            </ActionButton>
          )}
        </ContentArea>
      )}
    </div>
  );
};

export default DimFieldCreationFlow;
