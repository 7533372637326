/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { DataSource } from '../../dataSources/dataSourceConstants';
import goTabOauthURL from '../../../config/gotab';
import lightspeedKOauthURL from '../../../config/lightspeed';
import squareOauthURL from '../../../config/square';
import digestString from '../../../utils/digest';

const PARTNER_OATH_URL = {
  [DataSource.GOTAB]: goTabOauthURL,
  [DataSource.LIGHTSPEED_K]: lightspeedKOauthURL,
  [DataSource.SQUARE]: squareOauthURL,
};

export const useNavigateToPartnerOAuthInitiate = partner => {
  return async () => {
    const state = await digestString(Date.now());
    localStorage.setItem(`${_.toLower(partner)}_state`, state);

    const oauthURL = PARTNER_OATH_URL[partner].concat('&state=').concat(state);

    // "_self" ensures the URL is opened in the same tab
    window.open(oauthURL, '_self');
  };
};
