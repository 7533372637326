import React from 'react';
import {
  ActionButton,
  ConfigTypeSubHeader,
  ContentArea,
  DropdownSelect,
  PageHeader,
  SectionHeader,
} from '../../../commonFormComponents';
import DimFieldCreationFlow from './DimFieldCreationFlow';
import { DimensionFieldSeparator } from './DimensionsPageComponents';
import DatasetFieldBuilder from '../../../DatasetFieldBuilder';
import { DataSourceType, Industry, unknownDataSourceTypeError } from '../../../DatasetConfigFormConstants';
import { ProfitRoverAddButton } from '../../../../../forms/ProfitRoverButtons';
import { HOUR_DIMENSION_TYPE } from '../../../../../workflow/workflowConstants';
import { fetchDimensionValues, tryCreateDimension } from '../../../../../util/mapProductDimensions';
import { useDimensionTypes } from '../../../../../../data-access/query/dimensions';

const useDimensionValues = () => {
  const [dimensionValues, setDimensionValues] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetchDimensionValues();

      setDimensionValues(response.data);
    };

    fetchData();
  }, []);

  return [dimensionValues];
};

// Dimensions that shouldn't be created by this page because they were made elsewhere/earlier in the Config flow
const ignoredDimensionTypes = {
  [Industry.CFB]: ['TICKET_TYPE'],
};

/**
 * Turns a base-10 numeric index into base-26, with wrapping.
 *
 * For example:
 *    0 => A
 *   25 => Z
 *   26 => AA
 *   27 => AB
 *
 * See https://stackoverflow.com/a/32007970 for reference
 */
function convertIndexToLabel(i) {
  // eslint-disable-next-line no-bitwise
  return (i >= 26 ? convertIndexToLabel(((i / 26) >> 0) - 1) : '') + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26 >> 0];
}

export default function DimensionsPage(props) {
  const { formPageApi, dsConfigMetaData, onFinish, optional = false } = props;

  const {
    state: { dataType, industry, configFields },
  } = formPageApi;
  const { dimensions, refetchDimensions } = dsConfigMetaData;

  const { data: dimensionTypes = [] } = useDimensionTypes(dataType, industry);
  const relevantDimensionTypes = dimensionTypes.map(dimType => dimType.dimension_type);

  const relevantDimensions = dimensions.filter(dim => relevantDimensionTypes.includes(dim.dimension_type));
  const [dimensionValues] = useDimensionValues();
  const [configuredDimensions, setConfiguredDimensions] = React.useState([]);
  const [isAddingDimension, setIsAddingDimension] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();

  const sectionHeaderText = optional
    ? 'Dimensions are optional. Please add dimensions or finish.'
    : 'Please add at least one dimension.';

  const isTimeZoneIncluded = configFields.find(field => field.dataset_field_name === 'TRAN')?.timezone != null;

  // Only show dimensions that haven't been selected yet
  const selectedDimensionIds = configuredDimensions.map(dim => dim.product_dimension_id);

  // Only show dimensions that haven't already been created by the user in a particular UI flow
  const ignoredDimTypes = ignoredDimensionTypes[industry] ?? [];

  const selectableDimensions = relevantDimensions.filter(dim => {
    const dimId = dim.product_dimension_id;
    const dimType = dim.dimension_type;
    const isDerived = dim.derived; // Don't show derived dimensions to the user

    return !selectedDimensionIds.includes(dimId) && !ignoredDimTypes.includes(dimType) && !isDerived;
  });

  const addDimensionToConfig = (selectedDimension, dimensionValue, dataSourceType) => {
    setIsAddingDimension(false);

    // Add the newly created dimension to the state along with a key describing which type to create
    const newConfigDimension = {
      ...selectedDimension,
      dataSourceType,
      dimensionValue,
    };
    setConfiguredDimensions([...configuredDimensions, newConfigDimension]);
  };

  const canContinue = !isAddingDimension && (optional || configuredDimensions.length > 0);

  const onClickNext = async () => {
    let datasetFields = [];
    let hourDimensionId = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE && dim.derived)
      ?.product_dimension_id;

    if (hourDimensionId == null && isTimeZoneIncluded) {
      // Create the dimension
      const { data, errors } = await tryCreateDimension('Hour', HOUR_DIMENSION_TYPE);

      if (Array.isArray(errors) && errors.length > 0) {
        setErrorMessage(`Failed to add custom dimension: Hour`);
        await refetchDimensions(); // just in case the dimension was created
        return;
      }

      await refetchDimensions();
      hourDimensionId = data[0].product_dimension_id;
    }

    if (isTimeZoneIncluded) {
      const hourDimField = new DatasetFieldBuilder()
        .setDatasetFieldName('DIM')
        .setProductDimensionId(hourDimensionId)
        .build();
      datasetFields.push(hourDimField);
    }

    // Dimensions the user doesn't see but for which dataset fields may still need to be created
    const derivedDimensions = relevantDimensions.filter(dim => dim.derived);

    datasetFields = [
      ...datasetFields,
      ...derivedDimensions.map(dim =>
        new DatasetFieldBuilder()
          .setDatasetFieldName('DIM')
          .setProductDimensionId(dim.product_dimension_id)
          .build(),
      ),
    ];

    // Add DIM fields the user selected
    datasetFields = [
      ...datasetFields,
      ...configuredDimensions.map(dim => {
        const { dataSourceType, dimensionValue } = dim;

        const datasetFieldBuilder = new DatasetFieldBuilder().setDatasetFieldName('DIM');

        if (dataSourceType === DataSourceType.FIXED_VALUE) {
          datasetFieldBuilder.setFixedValue(dimensionValue);
        } else if (dataSourceType === DataSourceType.FROM_COLUMN) {
          datasetFieldBuilder.setFromColumn(dimensionValue);
        } else if (dataSourceType === DataSourceType.FORMULA) {
          datasetFieldBuilder.setFormula(dimensionValue);
        } else {
          throw unknownDataSourceTypeError(dataSourceType);
        }

        return datasetFieldBuilder.setProductDimensionId(dim.product_dimension_id).build();
      }),
    ];

    await formPageApi.actions.addDatasetFields(datasetFields);

    // NOTE: This will be needed in the future when we have a "review" page
    // formPageApi.actions.navigateToPage(Pages.REVIEW);
    const updatedDimensionIds = datasetFields.map(field => field.product_dimension_id);
    onFinish(updatedDimensionIds);
  };

  return (
    <div className="ds-config-container">
      <PageHeader>New Data Configuration</PageHeader>

      <ConfigTypeSubHeader industry={formPageApi.state.industry} dataType={formPageApi.state.dataType} />

      <hr />

      <SectionHeader>{sectionHeaderText}</SectionHeader>

      {configuredDimensions.map((dim, index) => {
        const letterLabel = convertIndexToLabel(index);

        const { dataSourceType, dimensionValue } = dim;

        const answerLabel = dimensionValue;
        let dataSourceTypeLabel = '';

        if (dataSourceType === DataSourceType.FIXED_VALUE) {
          dataSourceTypeLabel = 'You selected this value:';
        } else if (dataSourceType === DataSourceType.FROM_COLUMN) {
          dataSourceTypeLabel = 'You selected this column:';
        } else if (dataSourceType === DataSourceType.FORMULA) {
          dataSourceTypeLabel = 'You entered this formula:';
        }

        return (
          <div key={dim.product_dimension_id}>
            <div style={{ marginBottom: 5 }}>
              <SectionHeader label={letterLabel} style={{ marginBottom: 10 }}>
                {dim.name}
              </SectionHeader>
            </div>
            <ContentArea>
              <div>{dataSourceTypeLabel}</div>
              <DropdownSelect
                disabled
                options={[{ label: answerLabel, value: answerLabel }]}
                value={{ label: answerLabel, value: answerLabel }}
                style={{ margin: 0 }}
              />
            </ContentArea>
            <DimensionFieldSeparator />
          </div>
        );
      })}

      {!isAddingDimension ? (
        <ProfitRoverAddButton onClick={() => setIsAddingDimension(true)}>
          {configuredDimensions.length === 0 ? 'Add Dimension' : 'Add Another Dimension'}
        </ProfitRoverAddButton>
      ) : (
        <DimFieldCreationFlow
          addDimensionToConfig={addDimensionToConfig}
          selectableDimensions={selectableDimensions}
          dataSample={dsConfigMetaData.dataSample}
          dimensionValues={dimensionValues}
          industry={industry}
          letterLabel={convertIndexToLabel(configuredDimensions.length)}
          onCancelAddingDimension={() => setIsAddingDimension(false)}
          refetchDimensions={refetchDimensions}
        />
      )}

      {canContinue && (
        <div className="d-flex">
          <ActionButton onClick={onClickNext} style={{ marginTop: 30 }}>
            Finish
          </ActionButton>
          {errorMessage != null && <div className="dimension-post-error-message">{errorMessage}</div>}
        </div>
      )}
    </div>
  );
}
