import React from 'react';
import { Pages } from '../DatasetConfigFormReducer';
import DimensionsPage from './commonPages/dimensionsPage/DimensionsPage';
import GenericQuantityPage from './commonPages/GenericQuantityPage';
import LocationsPage from './commonPages/LocationsPage';
import PricePage from './commonPages/PricePage';
import TransactionDatePage from './commonPages/TransactionDatePage';

export default function EOTranPages(props) {
  const {
    formPageApi: {
      state: { page },
    },
    onFinish,
  } = props;

  switch (page) {
    case Pages.LOCATIONS:
      return <LocationsPage {...props} nextPage={Pages.TRANSACTION_DATE} label="A" />;
    case Pages.TRANSACTION_DATE:
      return <TransactionDatePage {...props} nextPage={Pages.QUANTITY} label="B" />;
    case Pages.QUANTITY:
      return <GenericQuantityPage {...props} nextPage={Pages.PRICE} label="C" fieldName="Item Quantity" />;
    case Pages.PRICE:
      return <PricePage {...props} nextPage={Pages.DIMENSIONS} label="D" />;
    case Pages.DIMENSIONS:
      return <DimensionsPage {...props} onFinish={onFinish} />;
    default:
      return null;
  }
}
