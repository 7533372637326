import axios from 'axios';
import React from 'react';
import { useDimensions } from '../../accountSettings/dimensionSettingsHooks';
import { KA_API_URL } from '../../../config/baseUrl';
import { createDatasetConfig, updateDatasetConfig } from '../../../data-access/mutation/datasetConfigs';
import { useLocations } from '../../../data-access/query/locations';

const fetchDataSample = datasetId =>
  axios.get(`${KA_API_URL}/datasets/sample`, {
    params: {
      dataset_id: datasetId,
    },
  });

const useDataSample = datasetId => {
  const [dataSample, setDataSample] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const dataSampleResponse = await fetchDataSample(datasetId);

      if (dataSampleResponse.data && dataSampleResponse.data.length > 0) {
        setDataSample(dataSampleResponse.data);
      }
    };

    fetchData();
  }, [datasetId]);

  return { dataSample };
};

const fetchTimeGranularities = () => axios.get(`${KA_API_URL}/time_granularity`);

export const useTimeGranularities = () => {
  const [timeGranularities, setTimeGranularities] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetchTimeGranularities();

      if (response.data && response.data.length > 0) {
        setTimeGranularities(response.data);
      }
    };

    fetchData();
  }, []);

  return timeGranularities;
};

/**
 * Data needed to support creating other screens. This could be broken up into individual hooks for
 * different industry/data-type combos to avoid fetching unnecessary data.
 */
export const useDatasetConfigMetaData = datasetId => {
  // TODO: customerId may no longer be needed throughout most of the application
  const [customerId] = React.useState(parseInt(localStorage.getItem('customer_id'), 10));
  const { dimensions, refetchDimensions } = useDimensions();
  const { dataSample } = useDataSample(datasetId);
  const { data: locations = [] } = useLocations();

  return {
    customerId,
    dimensions,
    refetchDimensions,
    dataSample,
    locations,
  };
};

/**
 * Creates an initial "stub" dataset config for which all future Form pages in a flow will be configuring.
 * It relies on the database inserting a default value of "true" for the "is_editable" column.
 */
export const createBaselineDatasetConfig = (configName, dataType, naicsKaCode, timeGranularity) => {
  return createDatasetConfig({
    data_type: dataType,
    naicsKaCode,
    dataset_config_name: configName,
    dataset_fields: [],
    time_granularity: timeGranularity,
  });
};

export const setDatasetConfigUneditable = datasetConfigId => {
  const params = {
    is_editable: false,
  };

  return updateDatasetConfig(datasetConfigId, params);
};

export const setDatasetConfigIsAssignedSeating = (datasetConfigId, isAssignedSeating) => {
  const params = { is_assigned_seating: isAssignedSeating };

  return updateDatasetConfig(datasetConfigId, params);
};

export const connectDatasetConfigToDataset = (datasetId, datasetConfigId) => {
  return axios.put(`${KA_API_URL}/datasets/${datasetId}`, { dataset_config_id: datasetConfigId });
};
