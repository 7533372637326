import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useGridApi = ({ defaultSortColumnId } = {}) => {
  const [gridApi, setGridApi] = React.useState();
  const [columnApi, setColumnApi] = React.useState();

  const onGridReady = React.useCallback(
    params => {
      const { api, columnApi: gridColumnApi } = params;

      setGridApi(api);
      setColumnApi(gridColumnApi);

      api.sizeColumnsToFit();

      if (defaultSortColumnId) {
        api.columnModel.columnApi.applyColumnState({ state: [{ colId: defaultSortColumnId, sort: 'desc' }] });
      }
    },
    [defaultSortColumnId],
  );

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (gridApi != null) {
      const updateTable = () => gridApi.sizeColumnsToFit();
      window.addEventListener('resize', updateTable);

      return () => window.removeEventListener('resize', updateTable);
    }
  }, [gridApi]);
  return { gridApi, columnApi, onGridReady };
};
