import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './link-with-directional-arrow.css';

const LinkWithDirectionalArrow = ({
  target,
  text,
  direction,
  arrowRelativeToText,
  className,
  linkClassName,
  onClick,
}) => {
  let arrow;
  switch (direction) {
    case 'up':
      arrow = '▲';
      break;
    case 'down':
      arrow = '▼';
      break;
    case 'right':
      arrow = '▶';
      break;
    case 'left':
    default:
      arrow = '◀';
      break;
  }

  let marginStyle;
  switch (arrowRelativeToText) {
    case 'right':
      marginStyle = { marginRight: '5px' };
      break;
    case 'left':
    default:
      marginStyle = { marginLeft: '5px' };
      break;
  }

  return (
    <div className={classnames('d-flex', className)}>
      {arrowRelativeToText === 'left' && <div className="arrow">{arrow}</div>}
      <div className={classnames('link-with-directional-arrow', linkClassName)} style={marginStyle}>
        <Link to={target} style={{ color: 'inherit' }} onClick={onClick}>
          {text}
        </Link>
      </div>
      {arrowRelativeToText === 'right' && <div className="arrow">{arrow}</div>}
    </div>
  );
};

/**
 * Same as Component above except this renders an <a /> for off-domain linking
 */
export const ALinkWithDirectionalArrow = ({ children, direction, className, arrowRelativeToText, ...props }) => {
  let arrow;
  switch (direction) {
    case 'up':
      arrow = '▲';
      break;
    case 'down':
      arrow = '▼';
      break;
    case 'right':
      arrow = '▶';
      break;
    case 'left':
    default:
      arrow = '◀';
      break;
  }

  let marginStyle;
  switch (arrowRelativeToText) {
    case 'right':
      marginStyle = { marginRight: '5px' };
      break;
    case 'left':
    default:
      marginStyle = { marginLeft: '5px' };
      break;
  }

  return (
    <span className={classnames('d-inline-flex', className)}>
      {arrowRelativeToText === 'left' && <div className="arrow">{arrow}</div>}
      <a className={classnames('link-with-directional-arrow', className)} style={marginStyle} {...props}>
        {children}
      </a>
      {arrowRelativeToText === 'right' && <div className="arrow">{arrow}</div>}
    </span>
  );
};

export const NavigateBack = ({ onClick, children }) => (
  <button type="button" className="d-flex pl-0" onClick={onClick}>
    <div className="arrow">◀</div>
    <div className={classnames('link-with-directional-arrow')} style={{ marginLeft: '5px' }}>
      {children}
    </div>
  </button>
);

export default LinkWithDirectionalArrow;
