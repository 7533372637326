import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Hours Tab';

const ACTIONS = {
  LOCATION_DROPDOWN_CLICK: 'Location dropdown click',
  HOURS_OF_OPERATION_DROPDOWN_CLICK: 'Set Hours of Operation for dropdown click',
  COMPARE_TO_DROPDOWN_CLICK: 'Compare To dropdown click',

  SHOW_METRICS_CHECKBOX_CLICK: 'Show Metrics checkbox click',
  SHOW_GRID_VIEW_BUTTON_CLICK: 'Show Grid View button click',
  SHOW_BARS_VIEW_BUTTON_CLICK: 'Show Bars View button click',
  BARS_TABLE_ARROWS_BUTTON_CLICK: 'Bars Table Arrows (hours) button click',
  BARS_TABLE_CLOSED_CHECKBOX_CLICK: 'Bars Table Closed checkbox click',
  BARS_TABLE_DRAG_HOUR_BAR: 'Bars Table drag Hour Bar',
  RESET_NEW_HOURS_TO_CURRENT_HOURS_BUTTON_CLICK: 'Reset New Hours to Current Hours button click',
  RESET_NEW_HOURS_TO_ROVERRECS_BUTTON_CLICK: 'Reset New Hours to RoverRecs button click',

  DAY_OF_WEEK_SELECT_CLICK: 'Day of Week select click',
  GRAPH_SOURCES_TABLE_ARROWS_BUTTON_CLICK: 'Graph Sources Table Arrows (hours) button click',
  GRAPH_SOURCES_CHECKBOX_CLICK: 'Graph Sources Graph? checkbox click',

  MINIMUM_REVENUE_THRESHOLD_INPUT_CHANGE: 'Minimum Revenue Threshold input change',
  CURRENT_HOURS_OF_OPERATION_LINK_CLICK: 'Current Hours of Operation link click',
};

const LABELS = {
  TOOLBAR: 'Toolbar',
  HOURS_OF_OPERATION: 'Hours of Operation',
  DETAILS: 'Details',
  SETTINGS: 'Settings',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitLocationDropdownClick = () => {
  emitEvent({ action: ACTIONS.LOCATION_DROPDOWN_CLICK, label: LABELS.TOOLBAR });
};

export const gaEmitHoursOfOperationDropdownClick = () => {
  emitEvent({ action: ACTIONS.HOURS_OF_OPERATION_DROPDOWN_CLICK, label: LABELS.TOOLBAR });
};

export const gaEmitCompareToDropdownClick = () => {
  emitEvent({ action: ACTIONS.COMPARE_TO_DROPDOWN_CLICK, label: LABELS.TOOLBAR });
};

export const gaEmitShowMetricsCheckboxClick = () => {
  emitEvent({ action: ACTIONS.SHOW_METRICS_CHECKBOX_CLICK, label: LABELS.HOURS_OF_OPERATION });
};

export const gaEmitShowGridViewButtonClick = () => {
  emitEvent({ action: ACTIONS.SHOW_GRID_VIEW_BUTTON_CLICK, label: LABELS.HOURS_OF_OPERATION });
};

export const gaEmitShowBarsViewButtonClick = () => {
  emitEvent({ action: ACTIONS.SHOW_BARS_VIEW_BUTTON_CLICK, label: LABELS.HOURS_OF_OPERATION });
};

export const gaEmitBarsTableArrowsButtonClick = () => {
  emitEvent({ action: ACTIONS.BARS_TABLE_ARROWS_BUTTON_CLICK, label: LABELS.HOURS_OF_OPERATION });
};

export const gaEmitBarsTableClosedCheckboxClick = () => {
  emitEvent({ action: ACTIONS.BARS_TABLE_CLOSED_CHECKBOX_CLICK, label: LABELS.HOURS_OF_OPERATION });
};

export const gaEmitBarsTableDragHourBar = () => {
  emitEvent({ action: ACTIONS.BARS_TABLE_DRAG_HOUR_BAR, label: LABELS.HOURS_OF_OPERATION });
};

export const gaEmitResetNewHoursToCurrentHoursButtonClick = () => {
  emitEvent({ action: ACTIONS.RESET_NEW_HOURS_TO_CURRENT_HOURS_BUTTON_CLICK, label: LABELS.HOURS_OF_OPERATION });
};

export const gaEmitResetNewHoursToRoverRecsButtonClick = () => {
  emitEvent({ action: ACTIONS.RESET_NEW_HOURS_TO_ROVERRECS_BUTTON_CLICK, label: LABELS.HOURS_OF_OPERATION });
};

export const gaEmitDayOfWeekSelectClick = () => {
  emitEvent({ action: ACTIONS.DAY_OF_WEEK_SELECT_CLICK, label: LABELS.DETAILS });
};

export const gaEmitGraphSourcesTableArrowsButtonClick = () => {
  emitEvent({ action: ACTIONS.GRAPH_SOURCES_TABLE_ARROWS_BUTTON_CLICK, label: LABELS.DETAILS });
};

export const gaEmitGraphSourcesCheckboxClick = () => {
  emitEvent({ action: ACTIONS.GRAPH_SOURCES_CHECKBOX_CLICK, label: LABELS.DETAILS });
};

export const gaEmitMinimumRevenueThresholdInputChange = () => {
  emitEvent({ action: ACTIONS.MINIMUM_REVENUE_THRESHOLD_INPUT_CHANGE, label: LABELS.SETTINGS });
};

export const gaEmitCurrentHoursOfOperationLinkClick = () => {
  emitEvent({ action: ACTIONS.CURRENT_HOURS_OF_OPERATION_LINK_CLICK, label: LABELS.SETTINGS });
};
