import React from 'react';

const PROFITROVER_SUPPORT_EMAIL_ADDRESS = process.env.REACT_APP_PROFITROVER_SUPPORT_EMAIL_ADDRESS;

const MailToSupportLink = props => {
  const { children, className, subject, body } = props;

  const hrefLink = `mailto:${PROFITROVER_SUPPORT_EMAIL_ADDRESS}?subject=${subject}&body=${body}`;

  return (
    <a href={hrefLink} className={className}>
      {children}
    </a>
  );
};

export default MailToSupportLink;
