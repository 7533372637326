import React from 'react';
import { Modal } from 'react-bootstrap';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';
import { ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';

const ConfirmationModal = ({ show, closeModal, onContinue }) => {
  return (
    <Modal
      className="location-activation-confirmation-modal"
      show={show}
      onHide={closeModal}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Proceed with activation?</Modal.Title>
      </Modal.Header>
      <ProfitRoverModalMain>
        In the following step, you will end your trial, start your paid subscription plan, and pay for your activated
        locations.
      </ProfitRoverModalMain>
      <ProfitRoverModalFooter>
        <ProfitRoverSecondaryButton variant="secondary" className="cancel-button" onClick={closeModal}>
          Cancel
        </ProfitRoverSecondaryButton>
        <ProfitRoverPrimaryButton variant="primary" onClick={onContinue} disabled={!true} className="save-button">
          Continue with Activation
        </ProfitRoverPrimaryButton>
      </ProfitRoverModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
