/* eslint-disable no-await-in-loop */
import axios from '../../../../utils/axios-client';
import { KDL_API_URL } from '../../../../config/baseUrl';

export async function getTeamDetails(visits, teamName) {
  const teamSchools = [];
  teamSchools.push(teamName);

  for (let i = 0; i < visits.length; ++i) {
    if (!visits[i].description.includes('Season')) {
      teamSchools.push(visits[i].description);
    }
  }

  const teamsQueryParam = [];
  teamSchools.map(team => teamsQueryParam.push(team));

  const teamDetailsResponse = await axios.get(`${KDL_API_URL}/cfb/teams?team_list=${teamsQueryParam}`);

  return teamDetailsResponse;
}

export async function getRecords(visits, teamName) {
  const teamSchools = [];
  teamSchools.push(teamName);

  for (let i = 0; i < visits.length; ++i) {
    if (!visits[i].description.includes('Season')) {
      teamSchools.push(visits[i].description);
    }
  }

  const records = [];
  for (let i = 0; i < teamSchools.length; ++i) {
    const resultsResponse = await axios.get(`${KDL_API_URL}/cfb/games/${teamSchools[i]}?dashboard=cfb`);

    let wins = 0;
    let totalGames = 0;
    const last = {};
    
    resultsResponse.data.sort((a, b) => {
      return b.start_date_time.substring(0, 10) - a.start_date_time.substring(0, 10);
    });
    
    resultsResponse.data.forEach(game => {
      if (game.winning_team === teamSchools[i]) {
        wins += 1;
      }
      if (game.winning_team) {
        totalGames += 1;
        last.home_team = game.home_team;
        last.opponent = game.opponent_team;
        last.team_points = game.team_points;
        last.opponent_points = game.opponent_points;
        last.home_game = game.home_team === teamSchools[i];
        last.win = game.team_points > game.opponent_points;      }
    });

    records.push({
      team: teamSchools[i],
      wins,
      losses: totalGames - wins,
      last,
    });
  }

  return records;
}

export async function getRankings() {
  const rankingsResponse = await axios.get(`${KDL_API_URL}/cfb/rankings/AP Top 25?&dashboard=cfb`);

  return rankingsResponse;
}
