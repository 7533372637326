/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import * as d3 from 'd3';

const height = 350;
const margin = { top: 20, right: 5, bottom: 20, left: 35 };

class Chart extends Component {
  state = {
    bars: [],
    text: [],
    width: 750,
    barWidth: 80,
    fontSize: '12px',
  };

  xAxis = d3
    .axisBottom()
    .tickPadding(9)
    .tickSizeInner(0);

  yAxis = d3
    .axisLeft()
    .tickPadding(9)
    .tickSizeInner(0)
    .tickFormat(d => {
      if (d / 1000 >= 1) {
        d = `${d / 1000}K`;
      }
      return d;
    });

  static getDerivedStateFromProps(nextProps) {
    const { data, temp } = nextProps;

    if (!data) {
      return {};
    }

    const width = window.innerWidth < 1330 ? 600 : 750;
    const barWidth = window.innerWidth < 1330 ? 50 : 80;
    const fontSize = window.innerWidth < 1330 ? '11px' : '12px';
    const dx = window.innerWidth < 1330 ? -12 : -6;

    let padding = 0.75;
    if (data.length > 0) {
      padding = data.length > 5 ? 0.75 : 0.85;
    }
    let yMax = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].value > 0 && data[i].name !== 'Total') {
        yMax += data[i].value;
      }
    }

    const xScale = d3
      .scaleBand()
      .domain(data.map(d => d.name))
      .range([margin.left, width - margin.right])
      .padding(padding);

    const yScale = d3
      .scaleLinear()
      .domain([0, yMax])
      .nice()
      .range([height - margin.bottom, margin.top + 30]);

    const colorScale = d3.scaleSequential(d3.interpolateSpectral);
    const [minAvg, maxAvg] = d3.extent(data, d => d.value);
    colorScale.domain([maxAvg, minAvg]);

    const text = data.map(d => {
      const y1 = yScale(Math.abs(d.high));
      const y2 = yScale(Math.abs(d.low));

      return {
        x: xScale(d.name) + 25,
        y: y2 - y1 > 0 ? y1 : y2,
        dx,
        dy: -6,
        value: d.value > 0 && d.name !== 'Total' ? `+${d.display}` : d.display,
      };
    });

    const bars = data.map(d => {
      const y1 = yScale(Math.abs(d.high));
      const y2 = yScale(Math.abs(d.low));

      return {
        x: xScale(d.name),
        y: y2 - y1 > 0 ? y1 : y2,
        height: y2 - y1 > 0 ? y2 - y1 : y1 - y2,
        fill: d.value > 0 ? '#00af4f' : '#FF3333',
      };
    });
    return { bars, xScale, yScale, text, temp, width, barWidth, fontSize };
  }

  componentDidMount() {
    const { xScale, yScale } = this.state;
    this.xAxis.scale(xScale);
    d3.select(this.refs.xAxis).call(this.xAxis);
    this.yAxis.scale(yScale);
    d3.select(this.refs.yAxis).call(this.yAxis);
    d3.select(this.refs.yAxis)
      .append('text')
      .attr('x', 0)
      .attr('y', margin.left - 30)
      .attr('transform', `translate(-50, ${height / 2}) rotate(-90)`)
      .attr('fill', '#000')
      .style('font-size', '18px')
      .style('text-anchor', 'middle')
      .text('Demand');
  }

  componentDidUpdate() {
    const { xScale, yScale } = this.state;
    this.xAxis.scale(xScale);
    d3.select(this.refs.xAxis).call(this.xAxis);
    this.yAxis.scale(yScale);
    d3.select(this.refs.yAxis).call(this.yAxis);
  }

  render() {
    const { bars, text, width, barWidth, fontSize } = this.state;
    return (
      <svg width={width} height={height}>
        {bars.map(d => (
          <rect key={d.x} x={d.x} y={d.y} width={barWidth} height={d.height} fill={d.fill} />
        ))}
        {text.map(d => (
          <text key={d.x} x={d.x} y={d.y} dx={d.dx} dy={d.dy} style={{ fontSize, fontWeight: 'bold' }}>
            {d.value}
          </text>
        ))}
        <g ref="xAxis" transform={`translate(30, ${height - margin.bottom})`} />
        <g ref="yAxis" transform={`translate(${margin.left + 30}, 0)`} />
      </svg>
    );
  }
}

export default Chart;
