import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Header';

const ACTIONS = {
  HELP_LINK_CLICK: 'Help Link click',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

// eslint-disable-next-line import/prefer-default-export
export const gaEmitHelpLinkClick = () => {
  emitEvent({ action: ACTIONS.HELP_LINK_CLICK });
};
