/**
 * Developer Note: All values for these enums are intentionally served by the API in camelCase
 * to support the special words reserved by CASL. However, in the database, these values are
 * actually stored in CAPITALIZED_SNAKE_CASE.
 *
 * These enums should be kept in sync with the contents of their respective database tables as
 * frequently as possible.
 */

export const Subject = {
  ALL: 'all', // Special reserved word in CASL (represents all subjects)
  WORKFLOW: 'workflow',
  USER: 'user',
};

export const Action = {
  MANAGE: 'manage', // Special reserved word in CASL (represents all actions)
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
  IMPERSONATE: 'impersonate',
};
