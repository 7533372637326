import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';
import { NoDataAvailableError } from '../../components/dashboards/pe/priceElasticityDashboardErrors';

/**
 * Requests
 */

const fetchOutputFileName = workflowId =>
  axios.get(`${KA_API_URL}/stats/modelformula/filename`, {
    params: { workflow_id: workflowId },
  });

export const fetchModelFormulaCSVFile = (workflowId, filename = 'Model.csv') =>
  axios
    .get(`${KA_API_URL}/stats/modelformula/csv`, {
      params: { workflow_id: workflowId },
      responseType: 'blob',
      headers: {
        'Content-Type': 'text/csv',
      },
    })
    .then(response => {
      const { data } = response;

      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement('a');

      link.href = downloadUrl;

      link.setAttribute('download', filename);

      document.body.appendChild(link);

      link.click();

      link.remove();
    });

/**
 * Hooks
 */

export const OUTPUT_FILENAME_KEY_BASE = 'outputFileName';

export const useOutputFileName = workflowId =>
  useQuery(
    [OUTPUT_FILENAME_KEY_BASE, workflowId],
    async () => {
      const response = await fetchOutputFileName(workflowId);

      if (response.status === 204) {
        throw new NoDataAvailableError();
      }

      return response.data;
    },
    { retry: 1, useErrorBoundary: true },
  );
