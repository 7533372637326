/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { useQuery } from 'react-query';
import accessTokenInstance from '../axios-instances/userRegistrationInstance';
import { KA_API_ROOT_URL } from '../../config/baseUrl';

/**
 * Requests
 */

// These requests use the default axios instance as no access tokens need to be attached to query these endpoints
const fetchUserRegistrationAccessToken = async () => axios.get(`${KA_API_ROOT_URL}/stripe/access-token`);
export const fetchStripePublishableKey = async () => axios.get(`${KA_API_ROOT_URL}/stripe/publishable-key`);
export const fetchTrialInfo = async () => axios.get(`${KA_API_ROOT_URL}/stripe/trial`);
const fetchCoupon = async couponId => axios.get(`${KA_API_ROOT_URL}/stripe/coupon/${couponId}`);

// This request uses the default axios instance as no API keys
// or access tokens need to be attached to query these endpoints
const fetchProfitRoverSubscriptionPrices = async priceNames =>
  accessTokenInstance.get(`${KA_API_ROOT_URL}/stripe/prices?product=KP&price_names=${priceNames}`);

/**
 * Hooks
 */

const SUBSCRIPTION_PRICES_QUERY_KEY_BASE = ['subscription_prices'];
const SUBSCRIPTION_TRIAL_INFO_QUERY_KEY_BASE = ['subscriptionTrialInfo'];
const USER_REGISTRATION_ACCESS_TOKEN_KEY_BASE = ['access_token'];
const COUPON_QUERY_KEY_BASE = 'Coupon';

export const useRegistrationAccessToken = () =>
  useQuery([...USER_REGISTRATION_ACCESS_TOKEN_KEY_BASE], async () => {
    const response = await fetchUserRegistrationAccessToken();
    return response?.data ?? null;
  });

export const useProfitRoverSubscriptionPrices = (priceNames, queryConfig) =>
  useQuery(
    [...SUBSCRIPTION_PRICES_QUERY_KEY_BASE],
    async () => {
      const response = await fetchProfitRoverSubscriptionPrices(priceNames);
      return response?.data ?? {};
    },
    { placeholderData: {}, ...queryConfig },
  );

export const useSubscriptionTrialLengths = () =>
  useQuery([...SUBSCRIPTION_TRIAL_INFO_QUERY_KEY_BASE], async () => {
    const response = await fetchTrialInfo();

    const { trial_length: trialLength, promo_trial_length: promoTrialLength } = response?.data;

    return { trialLength, promoTrialLength };
  });

export const useProfitRoverCoupon = couponId =>
  useQuery([COUPON_QUERY_KEY_BASE, couponId], async () => {
    const response = await fetchCoupon(couponId);
    return response?.data ?? {};
  });
