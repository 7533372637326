export const HOURS_OF_OPERATION_SETTINGS_KEYS = {
  HOURLY_REVENUE_CLOSE_THRESHOLD: 'hourly_revenue_close_threshold',
  VARY_HOURS_OF_OPERATION_BY: 'vary_hours_of_operation_by',
  BASE_RECOMMENDATIONS_ON: 'base_recommendations_on',
};

export const BASE_RECOMMENDATIONS = {
  FORECAST_AVG: 'forecast_avg',
  LAST_4_WEEKS_AVG: 'last_4_weeks_avg',
  STLY_AVG: 'stly_avg',
};

export const BASE_RECOMMENDATIONS_LABELS = {
  [BASE_RECOMMENDATIONS.FORECAST_AVG]: 'Forecasted Revenue',
  [BASE_RECOMMENDATIONS.LAST_4_WEEKS_AVG]: 'Last 4 Weeks Avg Revenue',
  [BASE_RECOMMENDATIONS.STLY_AVG]: 'STLY Avg Revenue',
};

export const DAY_OF_WEEK_VARIATION = 'day_of_week';
export const NO_VARIATION = 'none';

export const HOURS_OF_OPERATION_VARIATION_OPTION_KEYS = [DAY_OF_WEEK_VARIATION, NO_VARIATION];

export const HOURS_OF_OPERATION_VARIATION_LABELS = {
  [DAY_OF_WEEK_VARIATION]: 'Day of week',
  [NO_VARIATION]: 'Do not vary',
};

export const HOURS_OF_OPERATION_VARIATION_OPTIONS = [
  { value: DAY_OF_WEEK_VARIATION, label: HOURS_OF_OPERATION_VARIATION_LABELS[DAY_OF_WEEK_VARIATION] },
  { value: NO_VARIATION, label: HOURS_OF_OPERATION_VARIATION_LABELS[NO_VARIATION] },
];

export const DEFAULT_HOURS_SETTINGS = {
  [HOURS_OF_OPERATION_SETTINGS_KEYS.HOURLY_REVENUE_CLOSE_THRESHOLD]: 100,
  [HOURS_OF_OPERATION_SETTINGS_KEYS.VARY_HOURS_OF_OPERATION_BY]: DAY_OF_WEEK_VARIATION,
  [HOURS_OF_OPERATION_SETTINGS_KEYS.BASE_RECOMMENDATIONS_ON]: BASE_RECOMMENDATIONS.FORECAST_AVG,
};

export const NEXT_7_PERIOD = 'next_7';
export const NEXT_30_PERIOD = 'next_30';
export const NEXT_90_PERIOD = 'next_90';
export const NEXT_365_PERIOD = 'next_365';
export const THIS_CALENDAR_MONTH_PERIOD = 'current_month';
export const THIS_CALENDAR_QUARTER_PERIOD = 'current_quarter';
export const THIS_CALENDAR_YEAR_PERIOD = 'current_year';
export const NEXT_CALENDAR_MONTH_PERIOD = 'next_month';
export const NEXT_CALENDAR_QUARTER_PERIOD = 'next_quarter';
export const NEXT_CALENDAR_YEAR_PERIOD = 'next_year';

export const PERIOD_TO_LABEL = {
  [NEXT_7_PERIOD]: 'Next 7 Days',
  [NEXT_30_PERIOD]: 'Next 30 Days',
  [NEXT_90_PERIOD]: 'Next 90 Days',
  [NEXT_365_PERIOD]: 'Next 365 Days',
  [THIS_CALENDAR_MONTH_PERIOD]: 'This Calendar Month',
  [THIS_CALENDAR_QUARTER_PERIOD]: 'This Calendar Quarter',
  [THIS_CALENDAR_YEAR_PERIOD]: 'This Calendar Year',
  [NEXT_CALENDAR_MONTH_PERIOD]: 'Next Calendar Month',
  [NEXT_CALENDAR_QUARTER_PERIOD]: 'Next Calendar Quarter',
  [NEXT_CALENDAR_YEAR_PERIOD]: 'Next Calendar Year',
};

export const HOURS_OF_OPERATION_PERIOD_OPTIONS = [
  NEXT_7_PERIOD,
  NEXT_30_PERIOD,
  NEXT_90_PERIOD,
  NEXT_365_PERIOD,
  THIS_CALENDAR_MONTH_PERIOD,
  THIS_CALENDAR_QUARTER_PERIOD,
  THIS_CALENDAR_YEAR_PERIOD,
  NEXT_CALENDAR_MONTH_PERIOD,
  NEXT_CALENDAR_QUARTER_PERIOD,
  NEXT_CALENDAR_YEAR_PERIOD,
].map(value => ({ value, label: PERIOD_TO_LABEL[value] }));

export const CURRENT_HOURS_COMPARISON = 'current_hours';
export const RECOMMENDED_HOURS_COMPARISON = 'recommended_hours';

export const HOURS_COMPARISON_OPTIONS = [
  { value: CURRENT_HOURS_COMPARISON, label: 'Current Hours' },
  { value: RECOMMENDED_HOURS_COMPARISON, label: 'RoverRecs' },
];

export const NUMBER_OF_VISIBLE_HOURS = 8;
export const MAX_NEXT_DAY_HOUR_INDEX = 4; // Show up to '04:00' of the next day
export const MAX_HOUR_OFFSET = 21;
