import React from 'react';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './reactivate-subscription-banner.scss';

const TITLE_MESSAGE = "Ready to reactivate your subscription? It's easy!";

const ReactivateSubscriptionBanner = () => {
  const [showBanner, setShowBanner] = React.useState(false); // TODO: (KP-3054) Flip this back to "true"
  const hideBanner = () => setShowBanner(false);

  if (!showBanner) {
    return null;
  }

  return (
    <div className="reactivate-subscription-banner">
      <div className="reactivation-message d-flex flex-column">
        <h6 className="info">
          <i>{TITLE_MESSAGE}</i>
        </h6>
        <p>
          Just use the &quot;active&quot; toggle to activate the locations you want insights for below in the table.
          Then in the next screen, pick your plan and checkout.
        </p>
      </div>
      <div className="hide-banner">
        <button type="button" onClick={hideBanner}>
          <FontAwesomeIcon icon={faTimesCircle} size="2x" color="gray" />
        </button>
      </div>
    </div>
  );
};

export default ReactivateSubscriptionBanner;
