import _ from 'lodash';
import axios from 'axios';
import camelcase from 'camelcase';
import { readString } from 'react-papaparse';
import { useQuery } from 'react-query';
import { transformProductPricePointsData } from './pricestatsUtil';
import { KA_API_URL } from '../../../config/baseUrl';

/**
 * Requests
 */

const fetchRawPriceStats = params =>
  axios.get(`${KA_API_URL}/stats/price/raw`, {
    params,
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/octet-stream, application/json',
    },
  });

const fetchRawPricePoints = async (workflowId, productId) =>
  fetchRawPriceStats({ workflow_id: workflowId, product_id: productId, output: 'all_price_points' });

/**
 * Hooks
 */

const PRODUCT_PRICE_POINTS_QUERY_KEY_BASE = 'productPricePoints';

// eslint-disable-next-line import/prefer-default-export
export const useProductPricePoints = (workflowId, productId, queryConfig) => {
  return useQuery(
    [PRODUCT_PRICE_POINTS_QUERY_KEY_BASE, workflowId, productId],
    async () => {
      const response = await fetchRawPricePoints(workflowId, productId);

      const { data } = response;

      // Convert raw bytes to a string
      const decoder = new TextDecoder('utf-8');
      const csvData = decoder.decode(data);

      // Parse the data into Array<Object>
      const parseResults = readString(csvData, {
        header: true,
        transformHeader: header => {
          header = camelcase(header);

          if (header === 'remainingRevenue') {
            header = 'revenue';
          } else if (header === 'remainingProfit') {
            header = 'profit';
          }

          return header;
        },
        transform: transformProductPricePointsData,
      });

      const rows = parseResults.data;

      rows.forEach(row => {
        // Rename "remainingDemand" to "demand"
        row.demand = row.remainingDemand;
        delete row.remainingDemand;
      });

      return { rows, pricePointsByDate: _.groupBy(rows, 'tranDate') };
    },
    { staleTime: Number.POSITIVE_INFINITY, ...queryConfig },
  );
};
