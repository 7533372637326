import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import React from 'react';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { useMedia } from 'react-use';
import _ from 'lodash';
import { useGuidedSetupContext } from './guidedSetupContext';
import { useUpdateGuidedSetupState } from './guidedSetupHooks';
import PreviousStep from './PreviousStep';
import { ProgressBar } from './ProgressBar';
import { CheckedBullet } from './Util';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import { ProfitRoverCard } from '../../generic/ProfitRoverCard';
import Header from '../../header/header';
import { BACKGROUND_GRAY, MID_GREEN, FONT_BLACK, FONT_WHITE } from '../../../colors';
import { useUserInfo } from '../../../data-access/query/users';
import ExpertImage from '../../../images/Call-Screen-Image-Expert.jpg';
import CustomerImage from '../../../images/Call-Screen-Image-Customer.jpg';
import './schedule-strategy-call.scss';

const { REACT_APP_FREE_PROFITROVER_STRATEGY_CALL_CALENDLY_URL: CALENDLY_URL } = process.env;

const useCalendlyModal = (scheduledFreeCall, onEventScheduled) => {
  const [openModal, setOpenModal] = React.useState(false);
  const { updateGuidedSetup } = useUpdateGuidedSetupState();

  useCalendlyEventListener({
    onEventScheduled: () => {
      updateGuidedSetup({ scheduled_free_call: true }); // To prevent scheduling more calls

      if (_.isFunction(onEventScheduled)) {
        onEventScheduled();
      }
    },
  });

  return {
    openModal,
    setOpenModal,
  };
};

const ScheduleStrategyCall = () => {
  const {
    stepState: { goToNextStep, goToPreviousStep },
    guidedSetup: { scheduled_free_call: scheduledFreeCall = false } = {},
  } = useGuidedSetupContext();
  const { data: { first_name: firstName, last_name: lastName, email_address: email } = {}, isLoading } = useUserInfo();
  const name = `${firstName} ${lastName}`;
  const { openModal, setOpenModal } = useCalendlyModal(scheduledFreeCall, goToNextStep);

  const greeting = firstName != null && !isLoading ? `Hi ${firstName}! ` : '';

  const screenIsNarrow = useMedia('(max-width: 1200px)');

  return (
    <HeaderAwarePage className="schedule-strategy-call" scrollable={false}>
      <Header />
      <ProgressBar activeStep={null} />
      <PopupModal
        url={CALENDLY_URL}
        rootElement={document.getElementById('root')}
        open={!scheduledFreeCall && openModal}
        onModalClose={() => setOpenModal(false)}
        pageSettings={{
          // Calendly colors don't work in the URL if you include the "#" symbol #nice
          backgroundColor: BACKGROUND_GRAY.slice(1),
          primaryColor: MID_GREEN.slice(1),
          textColor: FONT_BLACK.slice(1),
          hideGdprBanner: true,
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
        }}
        prefill={{
          firstName,
          lastName,
          name,
          email,
        }}
      />
      <div className={classnames('layout-container', { narrow: screenIsNarrow })}>
        <main className="d-flex flex-column">
          <PreviousStep onClick={goToPreviousStep} />
          <ProfitRoverCard className={classnames('info-card', { narrow: screenIsNarrow })}>
            <div className="left">
              <div className="d-flex h-100">
                <div className="icon-area">
                  <div className="icon-bubble">
                    <FontAwesomeIcon icon={faPhone} size="2x" flip="horizontal" color={FONT_WHITE} />
                  </div>
                </div>
                <div className="button-area">
                  <h2>{greeting}Meet with one of our onboarding specialists for FREE.</h2>

                  <div className="button-group">
                    <ProfitRoverPrimaryButton onClick={() => setOpenModal(true)}>
                      Schedule a Free Call Now
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        className="ml-2"
                        style={{ fontSize: '1.5rem', marginTop: 1 }}
                        disabled={scheduledFreeCall}
                      />
                    </ProfitRoverPrimaryButton>
                    <ProfitRoverSecondaryButton onClick={goToNextStep}>Skip for Now</ProfitRoverSecondaryButton>
                  </div>
                </div>
              </div>
            </div>

            {!screenIsNarrow && <div className="divider" />}

            <div className={classnames('right', { narrow: screenIsNarrow })}>
              <ul>
                <CheckedBullet>Have an expert guide you through your setup</CheckedBullet>
                <CheckedBullet>Get personalized recommendations faster</CheckedBullet>
                <CheckedBullet>Get a personalized walkthrough of your dashboard and insights</CheckedBullet>
                <CheckedBullet>Talk to real people committed to helping your business thrive</CheckedBullet>
              </ul>
            </div>
          </ProfitRoverCard>
        </main>
        <div className="image-layout">
          <img src={ExpertImage} alt="Pricing expert on a call" />
          <img
            style={{ objectPosition: -100 }} // Hand-picked for this specific image
            src={CustomerImage}
            alt="Customer requesting pricing strategy setup assistance on a call"
          />
        </div>
      </div>
    </HeaderAwarePage>
  );
};

export default ScheduleStrategyCall;
