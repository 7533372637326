import classnames from 'classnames';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SettingsMenuContainer } from './FiltersMenu';
import { TAB_TO_LABEL, TAB_TO_PARAM, TABS } from './revenueUpliftDashboardTabs';
import { useAdminRoleCheck } from '../../../accountSettings/subscriptionManagement/AdminRoleCheck';
import { HOUR_DIMENSION_TYPE } from '../../../workflow/workflowConstants';
import {
  getWorkflowTitle,
  isEoPriceoptWorkflow,
  isMediaPriceElasticityWorkflow,
} from '../../../workflow/workflowFunctions';
import './filters-menu.scss';
import { PATH_LABELS, PATHS } from '../../../routing/routesConstants';

const DimensionName = ({ label, disabled, subitem }) => (
  <label className={classnames('eo-price-dim-preview-name', { disabled, subitem })}>{label}</label>
);

const BackButton = ({ onClick }) => {
  return (
    <button className="eo-price-back-button" type="button" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} size="sm" />
    </button>
  );
};

const FocusedNavigationItem = ({ focusedItem, popItem, pushItem, closeMenu }) => {
  const { label, subitems } = focusedItem;

  return (
    <>
      <div className="dim-viewer-top-bar">
        <BackButton onClick={popItem} />
        <label className="navigation-item-focused-label">{label}</label>
      </div>

      <hr className="focused-nav-item-separator" />

      {subitems.map(subitem => (
        <NavigationItem item={subitem} pushItem={pushItem} key={subitem.id} closeMenu={closeMenu} />
      ))}
    </>
  );
};

const NavigationItem = ({ item, pushItem, closeMenu }) => {
  const { label, path = '/', subitems = [], setActive } = item;

  const history = useHistory();
  const hasSubitems = subitems.length > 0;
  const currentPath = history.location.pathname;
  const isSameWorkflow = path.includes(currentPath);

  const onClick = () => {
    if (subitems.length > 0) {
      pushItem(item);
    } else if (setActive != null && isSameWorkflow) {
      closeMenu();
      setActive();
    } else {
      history.push(path);
    }
  };

  const ToggleExpandedButton = () => (
    <button
      type="button"
      aria-label="expand-filters"
      tabIndex="0"
      className="eo-price-toggle-expansion-button"
      onClick={onClick}
      style={{ marginRight: 5 }}
    >
      <FontAwesomeIcon icon={faChevronRight} size="sm" />
    </button>
  );

  return (
    <div className="eo-price-dim-label-and-filter-actions-row">
      {hasSubitems ? (
        <div className="eo-price-dim-label">
          <ToggleExpandedButton />
          <DimensionName label={label} />
        </div>
      ) : (
        <button type="button" className="eo-price-dim-button" onClick={onClick}>
          <DimensionName label={label} subitem />
        </button>
      )}
    </div>
  );
};

const getTabNavigationItems = (workflow, dimensions, setTabKey) => {
  const workflowDimensionsIds = workflow?.dimensions ?? [];
  const wfDimensions = dimensions.filter(dim => workflowDimensionsIds.includes(dim.product_dimension_id));
  const hourDimId = wfDimensions?.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;

  const tabs = [
    {
      value: TABS.AT_A_GLANCE,
      label: TAB_TO_LABEL[TABS.AT_A_GLANCE],
      param: TAB_TO_PARAM[TABS.AT_A_GLANCE],
    },
    { value: TABS.PRICING, label: TAB_TO_LABEL[TABS.PRICING], param: TAB_TO_PARAM[TABS.PRICING] },
  ];

  if (hourDimId) {
    tabs.push({
      value: TABS.STAFFING,
      label: TAB_TO_LABEL[TABS.STAFFING],
      param: TAB_TO_PARAM[TABS.STAFFING],
    });
    tabs.push({
      value: TABS.HOURS,
      label: TAB_TO_LABEL[TABS.HOURS],
      param: TAB_TO_PARAM[TABS.HOURS],
    });
  }

  return tabs.map(({ value, label, param }) => ({
    id: value,
    label,
    path: `/profitrover/${workflow.workflow_id}?tab=${param}`,
    setActive: setTabKey != null ? () => setTabKey(value) : null,
  }));
};

const getNavigationItemById = id => ({
  id,
  label: PATH_LABELS[id],
  path: PATHS[id],
});

const NavigationMenu = ({ isOpen, setIsOpen, workflows, dimensions, setTabKey, tabsId = [] }) => {
  const isAdmin = useAdminRoleCheck();

  const dashboardNavigationItems = React.useMemo(() => {
    const kpWorkflows = workflows.filter(isEoPriceoptWorkflow);
    const allMediaPriceElasticityWorkflows = workflows.every(isMediaPriceElasticityWorkflow);

    const createMediaPriceElasticityItems = () =>
      workflows.map(workflow => ({
        id: `workflow-${workflow.workflow_id}`,
        label: getWorkflowTitle(workflow),
        path: `/mediakaizenprice/${workflow.workflow_id}`,
      }));

    const createKpWorkflowItems = () =>
      kpWorkflows.map(workflow => ({
        id: `workflow-${workflow.workflow_id}`,
        label: getWorkflowTitle(workflow),
        subitems: getTabNavigationItems(workflow, dimensions, setTabKey),
      }));

    if (kpWorkflows.length === 1) {
      return getTabNavigationItems(kpWorkflows[0], dimensions, setTabKey);
    }

    return [
      getNavigationItemById('home'),
      ...(allMediaPriceElasticityWorkflows ? createMediaPriceElasticityItems() : createKpWorkflowItems()),
    ];
  }, [workflows, dimensions, setTabKey]);

  const settingsNavigationItems = React.useMemo(() => {
    const kpWorkflows = workflows.filter(isEoPriceoptWorkflow);
    const showPreferences = kpWorkflows.length === 1;

    const settingsSubitems = [
      getNavigationItemById('personal'),
      getNavigationItemById('company'),
      getNavigationItemById('locations'),
      {
        id: 'dimensions-data',
        label: 'Data',
        subitems: [
          getNavigationItemById('manageDimensions'),
          getNavigationItemById('manageDimensionValues'),
          getNavigationItemById('dimensionValueMapping'),
        ],
      },
      getNavigationItemById('customEvents'),
    ];

    if (isAdmin) {
      settingsSubitems.push(getNavigationItemById('users'));
      settingsSubitems.push(getNavigationItemById('subscription'));
    }

    if (showPreferences) {
      settingsSubitems.push(getNavigationItemById('preferences'));
    }

    return [
      {
        id: 'settings',
        label: 'Settings',
        subitems: settingsSubitems,
      },
      getNavigationItemById('dataSources'),
    ];
  }, [workflows, isAdmin]);

  const [focusedItems, setFocusedItems] = React.useState([]);

  const pushItem = item => setFocusedItems(prev => [...prev, item]);
  const popItem = () => setFocusedItems(prev => prev.slice(0, -1));

  const isFocused = focusedItems.length > 0;
  const focusedItem = focusedItems[focusedItems.length - 1];

  React.useEffect(() => {
    if (tabsId.length > 0) {
      const lastTabId = tabsId[tabsId.length - 1];
      if (lastTabId === 'settings') {
        setFocusedItems(settingsNavigationItems.filter(item => item.id === 'settings'));
      } else if (lastTabId === 'dimensions-data') {
        const settingsItem = settingsNavigationItems.find(item => item.id === 'settings');
        const dataItem = settingsItem.subitems.find(item => item.id === 'dimensions-data');
        setFocusedItems([settingsItem, dataItem]);
      }
    }
  }, [tabsId, settingsNavigationItems]);

  const closeNavigationMenu = () => setIsOpen(false);

  return (
    <SettingsMenuContainer isOpen={isOpen} onClose={closeNavigationMenu}>
      <div className="top-part pt-3">
        <div className="heading">
          <h5>Navigation</h5>
        </div>
        <hr />
      </div>
      <div className={classnames('eo-price-dim-filter-menu', { 'dimension-distributions': !isFocused })}>
        {!isFocused ? (
          <>
            {dashboardNavigationItems.map(item => (
              <NavigationItem item={item} pushItem={pushItem} key={item.id} closeMenu={closeNavigationMenu} />
            ))}
            <hr className="settings-separator" />
            {settingsNavigationItems.map(item => (
              <NavigationItem item={item} pushItem={pushItem} key={item.id} closeMenu={closeNavigationMenu} />
            ))}
          </>
        ) : (
          <FocusedNavigationItem
            focusedItem={focusedItem}
            popItem={popItem}
            pushItem={pushItem}
            closeMenu={closeNavigationMenu}
          />
        )}
      </div>
    </SettingsMenuContainer>
  );
};

export default NavigationMenu;
