import React from 'react';

const ProfitRoverTermsOfUseLink = props => {
  const { children, className } = props;

  return (
    <a href="https://profitrover.com/terms/" target="_blank" className={className} rel="noreferrer">
      {children}
    </a>
  );
};

export default ProfitRoverTermsOfUseLink;
