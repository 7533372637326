import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Custom Event Settings';

const ACTIONS = {
  ADD_NEW_CUSTOM_EVENT_CHECKMARK_CLICK: 'Add new custom event checkmark click',
  UPDATE_CUSTOM_EVENT_CHECKMARK_CLICK: 'Update custom event checkmark click',
  SAVE_CHANGES_BUTTON_CLICK: 'Save changes button click',
  DELETE_SELECTED_BUTTON_CLICK: 'Delete selected button click',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitAddNewCustomEventCheckmarkClick = () => {
  emitEvent({ action: ACTIONS.ADD_NEW_CUSTOM_EVENT_CHECKMARK_CLICK });
};

export const gaEmitUpdateCustomEventCheckmarkClick = () => {
  emitEvent({ action: ACTIONS.UPDATE_CUSTOM_EVENT_CHECKMARK_CLICK });
};

export const gaEmitSaveChangesButtonClick = () => {
  emitEvent({ action: ACTIONS.SAVE_CHANGES_BUTTON_CLICK });
};

export const gaEmitDeleteSelectedButtonClick = () => {
  emitEvent({ action: ACTIONS.DELETE_SELECTED_BUTTON_CLICK });
};
