import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const fetchDatasetConfig = async datasetConfigId =>
  axios.get(`${KA_API_URL}/datasets/config/${datasetConfigId}`);

export const fetchDatasetConfigs = async () => axios.get(`${KA_API_URL}/datasets/config`);

/**
 * Hooks
 */

const DATASET_CONFIG_QUERY_KEY_BASE = ['datasetConfig'];
const DATASET_CONFIGS_QUERY_KEY = ['datasetConfigs'];

export const useDatasetConfig = (datasetConfigId, queryConfig) =>
  useQuery(
    [...DATASET_CONFIG_QUERY_KEY_BASE, datasetConfigId],
    async () => {
      const response = await fetchDatasetConfig(datasetConfigId);
      const datasetConfig = response.data ?? {};

      return datasetConfig;
    },
    queryConfig,
  );

export const useDatasetConfigs = queryConfig =>
  useQuery(
    DATASET_CONFIGS_QUERY_KEY,
    async () => {
      const response = await fetchDatasetConfigs();
      const datasetConfigs = response.data ?? [];

      return datasetConfigs;
    },
    queryConfig,
  );
