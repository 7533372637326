import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Location & Hours Settings';

const ACTIONS = {
  UPDATE_LOCATION_CHECKMARK_CLICK: 'Update location green checkmark click',
  LINK_TO_SUBSCRIPTION_MANAGEMENT_CLICK: 'Go to subscription management page link click',
  HOURS_OF_OPERATION_SAVE_CHANGES_BUTTON_CLICK: 'Hours of Operation save changes button click',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitUpdateLocationCheckmarkClick = () => {
  emitEvent({ action: ACTIONS.UPDATE_LOCATION_CHECKMARK_CLICK });
};

export const gaEmitGoToSubscriptionManagementLinkClick = () => {
  emitEvent({ action: ACTIONS.LINK_TO_SUBSCRIPTION_MANAGEMENT_CLICK });
};

export const gaEmitHoursOfOperationSaveChangesButtonClick = () => {
  emitEvent({ action: ACTIONS.HOURS_OF_OPERATION_SAVE_CHANGES_BUTTON_CLICK });
};
