import axios from 'axios';
import { useMutation } from 'react-query';
import { SHOPIFY_BILLING_INFO_QUERY_KEY_BASE } from '../query/subscriptions';
import { SubscriptionUpdateAction } from '../../components/generic/subscriptions/SubscriptionConstants';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const createNewSetupIntent = async () => axios.get(`${KA_API_URL}/subscription/setupIntent`);

export const requestChangeLocationStatus = async (subscriptionId, locationIdsToActivate, locationIdsToDeactivate) =>
  axios.put(`${KA_API_URL}/subscription/${subscriptionId}`, {
    update_action: SubscriptionUpdateAction.REQUEST_CHANGE_LOCATION_STATUS,
    update_params: {
      locations_to_activate: locationIdsToActivate,
      locations_to_deactivate: locationIdsToDeactivate,
    },
  });

export const changeLocationStatus = async (subscriptionId, locationIdsToActivate, locationIdsToDeactivate) =>
  axios.put(`${KA_API_URL}/subscription/${subscriptionId}`, {
    update_action: SubscriptionUpdateAction.CHANGE_LOCATION_STATUS,
    update_params: {
      locations_to_activate: locationIdsToActivate,
      locations_to_deactivate: locationIdsToDeactivate,
    },
  });

const payInvoice = (subscriptionId, invoiceId) =>
  axios.post(`${KA_API_URL}/subscription/${subscriptionId}/invoice/${invoiceId}/pay`);

export const endTrialEarly = async (subscriptionId, stripePriceId) =>
  axios.put(`${KA_API_URL}/subscription/${subscriptionId}`, {
    update_action: SubscriptionUpdateAction.END_TRIAL_EARLY,
    update_params: {
      trial_end_time: 'now',
      stripe_price_id: stripePriceId,
    },
  });

export const toggleAutoRenew = async subscriptionId =>
  axios.put(`${KA_API_URL}/subscription/${subscriptionId}`, {
    update_action: SubscriptionUpdateAction.TOGGLE_AUTO_RENEW,
  });

export const changePlanInterval = async (subscriptionId, newIntervalPriceId) =>
  axios.put(`${KA_API_URL}/subscription/${subscriptionId}`, {
    update_action: SubscriptionUpdateAction.CHANGE_PLAN_INTERVAL,
    update_params: {
      new_interval_price_id: newIntervalPriceId,
    },
  });

const requestShopifyPlanIntervalUpdate = async (subscriptionId, billingInterval) =>
  axios.put(`${KA_API_URL}/subscription/${subscriptionId}`, {
    update_action: SubscriptionUpdateAction.REQUEST_CHANGE_PLAN_INTERVAL,
    update_params: {
      interval: billingInterval,
    },
  });

export const cancelPendingIntervalChange = async (subscriptionId, intervalPriceIdToRestore) =>
  axios.put(`${KA_API_URL}/subscription/${subscriptionId}`, {
    update_action: SubscriptionUpdateAction.CANCEL_PENDING_INTERVAL_CHANGE,
    update_params: {
      interval_price_id_to_restore: intervalPriceIdToRestore,
    },
  });

const activateTrialOrFirstFreeLocationOneTimeOnly = async (subscriptionId, locationId) =>
  axios.put(`${KA_API_URL}/subscription/${subscriptionId}`, {
    update_action: SubscriptionUpdateAction.ACTIVATE_ONLY,
    update_params: {
      location_to_activate: locationId,
    },
  });

/**
 * Hooks
 */

export const useRequestChangeLocationStatus = mutationConfig =>
  useMutation(async ({ subscriptionId, locationIdsToActivate, locationIdsToDeactivate }) => {
    const response = await requestChangeLocationStatus(subscriptionId, locationIdsToActivate, locationIdsToDeactivate);

    return response?.data ?? {};
  }, mutationConfig);

export const usePayInvoice = mutationConfig =>
  useMutation(async ({ subscriptionId, invoiceId }) => {
    const response = await payInvoice(subscriptionId, invoiceId);

    return response?.data ?? {};
  }, mutationConfig);

export const useActivateTrialOrFirstFreeLocationOneTimeOnly = mutationConfig =>
  useMutation(async ({ subscriptionId, locationId }) => {
    const response = await activateTrialOrFirstFreeLocationOneTimeOnly(subscriptionId, locationId);

    return response?.data ?? {};
  }, mutationConfig);

export const useShopifyRequestPlanIntervalUpdate = mutationConfig =>
  useMutation(
    async ({ subscriptionId, billingInterval }) => {
      const response = await requestShopifyPlanIntervalUpdate(subscriptionId, billingInterval);

      return response?.data;
    },
    { mutationKey: SHOPIFY_BILLING_INFO_QUERY_KEY_BASE, ...mutationConfig },
  );
