import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { FORM_ERROR } from 'final-form';
import CreatableSelect from 'react-select/creatable';
import { Field, useForm } from 'react-final-form';
import { FieldArray, useFieldArray } from 'react-final-form-arrays';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ConfigAccordionItem from './ConfigAccordionItem';
import { useConfigTableContext } from './configTableContext';
import { EMPTY_DESCRIPTOR, useInitialItemDescriptorAccordion } from '../fieldInitializers';
import { DataSourceType } from '../../data-center/datasetConfig/DatasetConfigFormConstants';
import { ProfitRoverAddButton } from '../../forms/ProfitRoverButtons';
import { useDimensionTypes } from '../../../data-access/query/dimensions';

const DEFAULT_NAME = 'item-descriptor-accordion';
const DEFAULT_TITLE = 'Item Descriptor(s)';
const DEFAULT_UUID = 'ITEM_DESCRIPTORS';

const ItemDescriptorRow = fieldProps => {
  const { columnNameOptions, fields, addDimensionToConfig, dimOptions } = fieldProps;

  return fields.map((fieldName, index) => {
    const isFieldInData = fields?.value[index]?.checkbox;
    const dataSourceType = isFieldInData ? DataSourceType.FROM_COLUMN : DataSourceType.FIXED_VALUE;

    return (
      <div key={index.toString()} className="d-flex">
        <div
          className="field-dotted-divider dataset-accordion-field-container item-descriptor-container"
          style={{ flexGrow: 1 }}
        >
          <div className="accordion-field-container-row">
            <div className="field-content-container">
              <div className="item-descriptor-container">
                <Field name={`${fieldName}.descriptorName`}>
                  {props => (
                    <>
                      <span className="field-select-text">
                        <i>Choose descriptor:</i>
                      </span>
                      <CreatableSelect
                        className="field-input-select"
                        options={dimOptions}
                        onChange={selectedValue => {
                          props.input.onChange(selectedValue);
                        }}
                        isClearable
                        onCreateOption={dimensionValue => {
                          const descriptorObject = { value: dimensionValue, label: dimensionValue };
                          addDimensionToConfig(dimensionValue, dataSourceType);
                          props.input.onChange(descriptorObject);
                        }}
                        placeholder="Start typing..."
                        value={props?.input?.value}
                        menuPlacement="auto"
                      />
                    </>
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div style={{ height: '40px', width: '1px', border: '.5px solid black', margin: '10px', opacity: '30%' }} />
          <div className="accordion-field-container-row">
            <div className="field-content-container">
              <div className="field-details-container">
                <Field name={`${fieldName}.checkbox`} type="checkbox">
                  {props => <input className="mx-2" {...props.input} />}
                </Field>
                <div className="accordion-field-description">Exists in my dataset</div>
              </div>
            </div>
          </div>
          <div style={{ height: '40px', width: '1px', border: '.5px solid black', margin: '10px', opacity: '30%' }} />
          <div className="accordion-field-container-row">
            <div className="field-content-container">
              {isFieldInData ? (
                <>
                  <div className="field-selection-column-container">
                    <Field name={`${fieldName}.fromColumn`}>
                      {props => (
                        <>
                          <span className="field-select-text">Column containing {DEFAULT_TITLE}:</span>
                          <Select
                            className="field-input-select"
                            options={columnNameOptions}
                            onChange={selectedValue => {
                              props.input.onChange(selectedValue);
                            }}
                            placeholder="Start typing..."
                            value={props?.input?.value}
                            menuPlacement="auto"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </>
              ) : (
                <div className="field-selection-column-container">
                  <span className="field-select-text">What value do you want to give this?</span>
                  <Field name={`${fieldName}.fixedValue`} type="input">
                    {props => <input className="mx-2, field-input-select" {...props.input} />}
                  </Field>
                </div>
              )}
            </div>
          </div>
        </div>

        {fields.length > 1 && (
          <div className="delete-icon">
            <FontAwesomeIcon icon={faTrash} className="fa-lg" onClick={() => fields.remove(index)} />
          </div>
        )}
      </div>
    );
  });
};

const useDimOptions = (fieldArrayState, userInputDimensions) => {
  const { dimensions = [], dataType, industry } = useConfigTableContext();
  const { data: dimensionTypes = [] } = useDimensionTypes(dataType, industry);

  const relevantDimensionTypes = dimensionTypes.map(dimType => dimType.dimension_type);
  const selectableDimensions = dimensions.filter(
    dim => relevantDimensionTypes.includes(dim.dimension_type) && !dim.derived,
  );

  const selectedRealDimensions = [];
  const selectedNewDimensions = [];

  const { fields } = fieldArrayState;

  const descriptorFields = fields.value ?? [];

  descriptorFields.forEach(value => {
    const hasSelectedValue = value.descriptorName?.value;
    if (hasSelectedValue) {
      const dimId = value.descriptorName?.value?.product_dimension_id;
      const isExistingDim = dimId != null;
      const isCreatedDim = _.isString(value.descriptorName?.value) || value.descriptorName?.isUserCreated;

      if (isExistingDim) {
        selectedRealDimensions.push(dimId);
      } else if (isCreatedDim) {
        const createdDimensionName = value.descriptorName?.value.name || value.descriptorName?.value;
        selectedNewDimensions.push(createdDimensionName);
      }
    }
  });

  // Build up objects to identify selectable existing and newly entered dimensions
  const selectableOptions = selectableDimensions.map(dim => ({
    label: dim.name,
    value: dim,
    isDisabled: selectedRealDimensions.includes(dim.product_dimension_id),
  }));
  const createdOptions = userInputDimensions.map(dim => ({
    label: dim.name,
    value: dim,
    isDisabled: selectedNewDimensions.includes(dim.name),
    isUserCreated: true,
  }));

  return [...selectableOptions, ...createdOptions];
};

const ItemDescriptorAccordionItem = ({ title = DEFAULT_TITLE, uuid = DEFAULT_UUID, name = DEFAULT_NAME }) => {
  const { columnNameOptions } = useConfigTableContext();

  const [userInputDimensions, setUserInputDimensions] = React.useState([]);

  const fieldArrayState = useFieldArray(name);
  const {
    fields,
    meta: { valid },
  } = fieldArrayState;

  const dimOptions = useDimOptions(fieldArrayState, userInputDimensions);

  const addDimensionToConfig = (createdDimensionName, dataSourceType) => {
    // Add the newly created dimension to the state along with a key describing which type to create
    const newConfigDimension = {
      dataSourceType,
      name: createdDimensionName,
    };
    setUserInputDimensions([...userInputDimensions, newConfigDimension]);
  };

  const validateItemDescriptor = values => {
    if (values.length === 0) {
      return FORM_ERROR;
    }

    const isValid = values.every(value => {
      const descriptorValueIsObject = _.isObject(value?.descriptorName);

      const fromColumnSatisfied =
        value?.checkbox &&
        descriptorValueIsObject &&
        value?.descriptorName?.value !== null &&
        value?.fromColumn !== null &&
        value?.fromColumn?.value;

      const fixedValueSatisfied = !value?.checkbox && value.descriptorName?.value !== '' && value?.fixedValue !== null;

      return fromColumnSatisfied || fixedValueSatisfied;
    });

    return isValid ? undefined : FORM_ERROR;
  };

  const formApi = useForm();
  const formState = formApi.getState();

  const errors = formState.errors[name] ?? {};

  const isComplete = Object.entries(errors).every(([, value]) => {
    return value == null;
  });

  const { initialFormState } = useInitialItemDescriptorAccordion(dimOptions);

  return (
    <ConfigAccordionItem title={title} uuid={uuid} valid={isComplete}>
      <FieldArray
        name={name}
        initialValue={initialFormState}
        component={ItemDescriptorRow}
        columnNameOptions={columnNameOptions}
        dimOptions={dimOptions}
        addDimensionToConfig={addDimensionToConfig}
        validate={validateItemDescriptor}
        isEqual={_.isEqual}
      />
      <ProfitRoverAddButton className="m-3" disabled={!valid} onClick={() => fields.push(EMPTY_DESCRIPTOR)}>
        <i>Add descriptor</i>
      </ProfitRoverAddButton>
    </ConfigAccordionItem>
  );
};

export default ItemDescriptorAccordionItem;
