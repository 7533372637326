/* eslint-disable jsx-a11y/no-onchange */
import React, { Component } from 'react';
import classnames from 'classnames';

const defaultButton = props => (
  <button type="button" {...props} className="-btn">
    {
      /* eslint-disable react/destructuring-assignment */
      props.children
    }
  </button>
);

export default class ReactTablePagination extends Component {
  static defaultProps = {
    PreviousComponent: defaultButton,
    NextComponent: defaultButton,
    renderPageJump: ({ value, onBlur, onKeyPress, inputType, pageJumpText }) => (
      <div className="-pageJump">
        <input aria-label={pageJumpText} type={inputType} value={value} onBlur={onBlur} onKeyPress={onKeyPress} />
      </div>
    ),
    renderCurrentPage: page => <span className="-currentPage">{page + 1}</span>,
    renderTotalPagesCount: pages => <span className="-totalPages">{pages || 1}</span>,
    renderPageSizeOptions: ({ pageSize, pageSizeOptions, rowsSelectorText, onPageSizeChange, rowsText }) => (
      <span className="select-wrap -pageSizeOptions">
        <select aria-label={rowsSelectorText} onChange={e => onPageSizeChange(Number(e.target.value))} value={pageSize}>
          {pageSizeOptions.map(option => (
            <option key={option} value={option}>
              {`${option} ${rowsText}`}
            </option>
          ))}
        </select>
      </span>
    ),
  };

  constructor(props) {
    super(props);

    this.getSafePage = this.getSafePage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.applyPage = this.applyPage.bind(this);

    this.state = {
      page: props.page,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { page } = this.props;
    if (page !== nextProps.page) {
      this.setState({ page: nextProps.page });
      localStorage.setItem('page', nextProps.page);
    }
    localStorage.setItem('pageSize', nextProps.pageSize);
  }

  getSafePage(goodPage) {
    const { page, pages } = this.props;
    if (Number.isNaN(goodPage)) {
      goodPage = page;
    }
    return Math.min(Math.max(goodPage, 0), pages - 1);
  }

  getPageJumpProperties() {
    const { page: statePage } = this.state;
    const { page: propsPage, pageJumpText } = this.props;

    if (localStorage.getItem('page') === '0' && statePage !== 0) {
      this.changePage(0);
    }
    return {
      onKeyPress: e => {
        if (e.which === 13 || e.keyCode === 13) {
          this.applyPage();
        }
      },
      onBlur: this.applyPage,
      value: statePage === '' ? '' : statePage + 1,
      onChange: e => {
        const val = e.target.value;
        const page = val - 1;
        if (val === '') {
          this.setState({ page: val });
        }
        this.setState({ page: this.getSafePage(page) });
        this.changePage(page === '' ? propsPage : page);
      },
      inputType: 'number',
      pageJumpText,
    };
  }

  changePage(e, toPage) {
    const { page, onPageChange } = this.props;
    toPage = this.getSafePage(toPage);
    this.setState({ page: toPage });
    if (page !== toPage) {
      onPageChange(toPage);
    }
  }

  applyPage() {
    const { page } = this.state;
    const { page: propsPage } = this.props;
    this.changePage(page === '' ? propsPage : page);
  }

  render() {
    const {
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      showPageJump,
      canPrevious,
      canNext,
      onPageSizeChange,
      className,
      PreviousComponent,
      NextComponent,
      renderPageJump,
      renderCurrentPage,
      renderPageSizeOptions,
      previousText,
      pageText,
      rowsSelectorText,
      rowsText,
      style,
      nextText,
    } = this.props;

    return (
      <div className={classnames(className, '-pagination')} style={style}>
        <div className="-previous">
          <PreviousComponent
            onClick={e => {
              if (!canPrevious) {
                return;
              }
              this.changePage(e, page - 1);
            }}
            disabled={!canPrevious}
          >
            {previousText}
          </PreviousComponent>
        </div>
        <div className="-center">
          <span className="-pageInfo">
            {pageText}
            {'  '}
            {showPageJump ? renderPageJump(this.getPageJumpProperties()) : renderCurrentPage(page)}
            {'   '}
          </span>
          {showPageSizeOptions &&
            renderPageSizeOptions({
              pageSize,
              rowsSelectorText,
              pageSizeOptions,
              onPageSizeChange,
              rowsText,
            })}
        </div>
        <div className="-next">
          <NextComponent
            onClick={e => {
              if (!canNext) {
                return;
              }
              this.changePage(e, page + 1);
            }}
            disabled={!canNext}
          >
            {nextText}
          </NextComponent>
        </div>
      </div>
    );
  }
}
