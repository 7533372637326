import React from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PSEUDO_INTEGRATION_CONFIG_NAMES } from './dataSourceConstants';
import {
  dataSourceNameToSlug,
  dataSourceSupportsDedicatedFileUpload,
  slugToDataSource,
} from './DataSourceInstructions';
import { getManualTimestampLabel, DataSourceBase, FinishSetupButton } from './DataSourceRowCommon';
import { ProfitRoverSecondaryButton } from '../forms/ProfitRoverButtons';
import { SPECIAL_CONFIG_NAME_TO_TILE_IMAGE } from '../setup/modern/DataSourceLogos';
import FileUploadModal from '../setup/modern/FileUploadModal';
import { FONT_GRAY } from '../../colors';

const ManualDataSourceRow = ({ datasetConfig, datasets }) => {
  const { name: configName, is_editable: configIsUnfinished, dataset_config_id: datasetConfigId } = datasetConfig;

  let label = configName;
  if (PSEUDO_INTEGRATION_CONFIG_NAMES.has(configName)) {
    // For file-based "pseudo-integrations"
    label = SPECIAL_CONFIG_NAME_TO_TILE_IMAGE[configName];
  }

  const datasetsWithTimestamps = datasets.filter(dataset => dataset.uploaded_gmt_datetime != null);
  // DateTime string format has a natural sort order
  const datasetsSortedByTimestamp = _.orderBy(datasetsWithTimestamps, dataset => dataset.uploaded_gmt_datetime, 'desc');
  const timestamps = datasetsSortedByTimestamp.map(dataset => dataset.uploaded_gmt_datetime);

  const [mostRecentDataset] = datasetsSortedByTimestamp;
  const { dataset_id: datasetId } = mostRecentDataset ?? {};

  const history = useHistory();

  const [showUploadDataModal, setShowUploadDataModal] = React.useState(false);

  const navigateToInitialFileUpload = () => {
    const slug = dataSourceNameToSlug(configName);
    const source = slugToDataSource(slug);

    let fileUploadUrl;
    if (dataSourceSupportsDedicatedFileUpload(source)) {
      fileUploadUrl = `/data-sources/add-new/manual/file-upload/${slug}`;
    } else {
      fileUploadUrl = '/data-sources/add-new/manual/file-upload';
    }

    history.push(fileUploadUrl, { datasetConfigId });
  };
  const navigateToConfigureSettings = () =>
    history.push('/data-sources/add-new/manual/configure', { dsConfigIdToEdit: datasetConfigId, fileId: datasetId });

  const dataSourceSlug = configName.replace(/[^a-zA-Z0-9-_]/g, '-').toLocaleLowerCase(); // Removes any characters that cannot be in URLs
  const navigateToEditDataSourceSettings = () =>
    history.push(`/data-sources/${dataSourceSlug}/settings`, { dsConfigIdToEdit: datasetConfigId, fileId: datasetId });

  const dataHasBeenUploaded = timestamps.length > 0;

  let actionButton;
  const timestamp = getManualTimestampLabel(timestamps);

  if (!configIsUnfinished) {
    actionButton = (
      <ProfitRoverSecondaryButton onClick={() => setShowUploadDataModal(true)} small>
        Upload More Data
      </ProfitRoverSecondaryButton>
    );
  } else {
    actionButton = (
      <FinishSetupButton onClick={dataHasBeenUploaded ? navigateToConfigureSettings : navigateToInitialFileUpload} />
    );
  }

  const settingsCog = !configIsUnfinished && datasets.length > 0 && (
    <button
      type="submit"
      onClick={navigateToEditDataSourceSettings}
      className="d-flex align-items-center justify-content-end"
    >
      <FontAwesomeIcon icon={faCog} style={{ fontSize: 20 }} transform={{ rotate: 30 }} color={FONT_GRAY} />
    </button>
  );

  return (
    <>
      {showUploadDataModal && (
        <FileUploadModal datasetConfigId={datasetConfigId} onHide={() => setShowUploadDataModal(false)} />
      )}
      <DataSourceBase label={label} timestamp={timestamp} actions={actionButton} settingsCog={settingsCog} />
    </>
  );
};

export default ManualDataSourceRow;
