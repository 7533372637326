import React from 'react';
import { PAYLOAD_TYPE } from './batchProgressConstants';
import { SSEEventType, useEventStreamContext } from '../../server-sent-events/eventStreamContext';
import { batchToAppProgressStage, eventToAppProgressStage } from './batchProgressUtil';

// eslint-disable-next-line import/prefer-default-export
export const useListenForBatchProgress = (workflowId, batchInProgress) => {
  const { sseEventSource } = useEventStreamContext();
  const [progressStage, setProgressStage] = React.useState(batchToAppProgressStage(batchInProgress));

  React.useEffect(() => {
    if (!sseEventSource) {
      return;
    }

    const eventArrivalListener = event => {
      const eventData = JSON.parse(event.data);
      const { payload, payload_type: payloadType } = eventData;

      if (payload.workflow_id === workflowId && Object.values(PAYLOAD_TYPE).includes(payloadType)) {
        setProgressStage(eventToAppProgressStage(payloadType, payload));
      }
    };

    sseEventSource.addEventListener(SSEEventType.WORK_BATCH_PROGRESS, eventArrivalListener);

    // eslint-disable-next-line consistent-return
    return () => sseEventSource.removeEventListener(SSEEventType.WORK_BATCH_PROGRESS, eventArrivalListener);
  }, [sseEventSource, workflowId]);

  return progressStage;
};
