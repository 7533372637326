/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';

import Header from '../../header/header';
import {
  ChartDataLoadingState,
  TicketsSoldOption,
  useKaizenExploreChartData,
  useKaizenExploreFilterState,
  useProcessedHistoryDataFromRaw,
  useTeamDetails,
} from './cfbKaizenExploreFunctions';
import AreaChart from '../../charts/areaChart';
import './cfbKaizenExplore.css';

const CFBKaizenExplore = props => {
  let { workflowId } = props?.match?.params;
  workflowId = parseInt(workflowId, 10);

  const { teamLogoUrl, logoLoaded } = useTeamDetails();
  const { data: chartData, errorMessage, loadingState } = useProcessedHistoryDataFromRaw(workflowId);
  const kaizenExploreState = useKaizenExploreFilterState(chartData);

  const {
    opponentNames,
    gameDates,
    priceZones,
    selectedOpponentName,
    setSelectedOpponentName,
    selectedGameDate,
    setSelectedGameDate,
    priceZone,
    setPriceZone,
    ticketsSold,
    setTicketsSold,
  } = kaizenExploreState;

  const activeChartData = useKaizenExploreChartData(
    chartData,
    selectedOpponentName,
    selectedGameDate,
    priceZone,
    ticketsSold,
  );

  const teamLogoAvailable = logoLoaded && teamLogoUrl != null && teamLogoUrl !== '';
  const dashboardTitle = teamLogoAvailable
    ? 'Football Tickets Dashboard'
    : `${localStorage.getItem('team')} Football Tickets Dashboard`;

  let loadingMessage;
  switch (loadingState) {
    case ChartDataLoadingState.FETCHING:
      loadingMessage = 'Fetching data...';
      break;
    case ChartDataLoadingState.PARSING:
      loadingMessage = 'Building visualizations...';
      break;
    case ChartDataLoadingState.DONE:
    default:
      loadingMessage = null;
  }

  const dataLoadedSuccessfully = loadingMessage == null && errorMessage == null;

  return (
    <>
      <Header />
      <div className="container-fluid" style={{ textAlign: 'center', paddingTop: '65px', maxWidth: 1200 }}>
        <div
          id="qr"
          style={{
            display: 'inline-block',
            align: 'center',
            fontSize: '22px',
            fontWeight: 'bold',
            marginRight: 10,
          }}
        >
          {teamLogoAvailable && (
            <img src={teamLogoUrl} alt={localStorage.getItem('team')} height="70px" align="middle" />
          )}
        </div>
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            fontSize: '32px',
            fontWeight: 'bold',
            marginTop: '10px',
            marginBottom: '15px',
          }}
        >
          {dashboardTitle}
        </div>
        <ControlsArea
          workflowId={workflowId}
          opponentNames={opponentNames}
          selectedOpponentName={selectedOpponentName}
          setSelectedOpponentName={setSelectedOpponentName}
          gameDates={gameDates}
          selectedGameDate={selectedGameDate}
          setSelectedGameDate={setSelectedGameDate}
          ticketsSold={ticketsSold}
          setTicketsSold={setTicketsSold}
          priceZones={priceZones}
          priceZone={priceZone}
          setPriceZone={setPriceZone}
        />
        {dataLoadedSuccessfully ? (
          <>
            <div>
              <h6 style={{ fontWeight: 'bold', fontSize: '24px', marginTop: 25 }}>
                Single Game Non-Group Ticket Sales
              </h6>
            </div>
            <AreaChart
              data={activeChartData ?? []}
              tickets={ticketsSold}
              date={selectedGameDate ?? '2020-01-01 12:00:00'} // Required because AreaChart only implements ComponentDidUpdate
            />
          </>
        ) : (
          <h4 style={{ minHeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {errorMessage != null ? errorMessage : loadingMessage}
          </h4>
        )}
      </div>
    </>
  );
};

const DashboardToggles = ({ workflowId }) => {
  return (
    <p>
      <span className="btn" style={{ border: '1px solid black' }}>
        <span style={{ cursor: 'pointer' }}>
          <a href={`/cfbkaizenprice/${workflowId}`} style={{ color: 'black' }}>
            {' KaizenPrice'}
          </a>{' '}
        </span>{' '}
      </span>
      &nbsp;
      <span
        className="btn"
        style={{
          border: '1px solid black',
          backgroundColor: 'rgb(0,175,79,0.7)',
        }}
      >
        <span style={{ cursor: 'pointer' }}>
          <a href={`/cfbkaizenexplore/${workflowId}`} style={{ color: 'black' }}>
            KaizenExplore
          </a>
        </span>
      </span>
    </p>
  );
};

const ControlsArea = props => {
  const {
    workflowId,
    opponentNames,
    selectedOpponentName,
    setSelectedOpponentName,
    gameDates,
    selectedGameDate,
    setSelectedGameDate,
    ticketsSold,
    setTicketsSold,
    priceZones,
    priceZone,
    setPriceZone,
  } = props;
  return (
    <>
      <DashboardToggles workflowId={workflowId} />
      <div className="select-container">
        <span>
          <p
            style={{
              display: 'inline-block',
              marginRight: '10px',
              fontWeight: 'bold',
            }}
          >
            &nbsp;&nbsp;Opponent :
          </p>
          <span
            style={{
              display: 'inline-block',
              width: '200px',
            }}
          >
            <select
              value={selectedOpponentName}
              className="form-control form-control-sm"
              name="currentOpponent"
              onChange={e => setSelectedOpponentName(e.target.value)}
            >
              {opponentNames.map(d => {
                return (
                  <option key={d} value={d}>
                    {d}
                  </option>
                );
              })}
            </select>
          </span>
        </span>
        <span>
          <p
            style={{
              display: 'inline-block',
              marginRight: '10px',
              fontWeight: 'bold',
            }}
          >
            &nbsp;&nbsp;Game :
          </p>
          <span
            style={{
              display: 'inline-block',
              width: '200px',
            }}
          >
            <select
              value={selectedGameDate}
              className="form-control form-control-sm"
              name="currentGameDate"
              onChange={e => setSelectedGameDate(e.target.value)}
            >
              {gameDates.map(d => {
                return (
                  <option key={d} value={d != null ? d : ''}>
                    {d != null ? d.substring(0, 10) : ''}
                  </option>
                );
              })}
            </select>
          </span>
        </span>
        <span>
          <p
            style={{
              display: 'inline-block',
              marginRight: '10px',
              fontWeight: 'bold',
            }}
          >
            &nbsp;&nbsp;Price Zone :
          </p>
          <span
            style={{
              display: 'inline-block',
              width: '200px',
            }}
          >
            <select
              value={priceZone}
              className="form-control form-control-sm"
              name="currentPriceZone"
              onChange={e => setPriceZone(e.target.value)}
            >
              {priceZones.map(d => {
                return (
                  <option key={d} value={d}>
                    {d}
                  </option>
                );
              })}
            </select>
          </span>
        </span>
        <span>
          <p
            style={{
              display: 'inline-block',
              marginRight: '10px',
              fontWeight: 'bold',
            }}
          >
            &nbsp;&nbsp;Tickets Sold :
          </p>
          <span
            style={{
              display: 'inline-block',
              width: '200px',
            }}
          >
            <select
              value={ticketsSold}
              className="form-control form-control-sm"
              name="ticketsSold"
              onChange={e => setTicketsSold(e.target.value)}
            >
              <option key="NC" value={TicketsSoldOption.NON_CUMULATIVE}>
                Non-Cumulative
              </option>
              <option key="C" value={TicketsSoldOption.CUMULATIVE}>
                Cumulative
              </option>
            </select>
          </span>
        </span>
      </div>
    </>
  );
};

export default CFBKaizenExplore;
