import { DAY_OF_WEEK_DIMENSION_ID, SEASON_DIMENSION_ID, WEEKPART_DIMENSION_ID } from './revenueUpliftConstants';
import { PriceChangeFrequency } from '../../../workflow/workflowConstants';

const PRICE_CHANGE_FREQUENCY_TO_COLUMN_NAME = {
  [PriceChangeFrequency.DOW]: 'dayOfWeek',
  [PriceChangeFrequency.WEEKPART]: 'weekpart',
  [PriceChangeFrequency.SEASON]: 'season',
  [PriceChangeFrequency.MONTH]: 'month',
  [PriceChangeFrequency.QUARTER]: 'quarter',
  [PriceChangeFrequency.YEAR]: 'year',
  [PriceChangeFrequency.WEEKS_SINCE_RELEASE]: 'weeksSinceRelease',
};

const COLUMN_NAME_TO_PRICE_CHANGE_FREQUENCY = {
  dayOfWeek: PriceChangeFrequency.DOW,
  weekpart: PriceChangeFrequency.WEEKPART,
  season: PriceChangeFrequency.SEASON,
  month: PriceChangeFrequency.MONTH,
  quarter: PriceChangeFrequency.QUARTER,
  year: PriceChangeFrequency.YEAR,
  weeksSinceRelease: PriceChangeFrequency.WEEKS_SINCE_RELEASE,
};

export const PRICE_FREQ_COL_TO_HEADER = {
  dayOfWeek: 'Day of Week',
  weekpart: 'Week Part',
  season: 'Season',
  month: 'Month',
  quarter: 'Quarter',
  year: 'Year',
  weeksSinceRelease: 'Weeks Since Release',
};

const PRICE_CHANGE_FREQUENCY_TO_DIM_ID = {
  [PriceChangeFrequency.DOW]: DAY_OF_WEEK_DIMENSION_ID,
  [PriceChangeFrequency.WEEKPART]: WEEKPART_DIMENSION_ID,
  [PriceChangeFrequency.SEASON]: SEASON_DIMENSION_ID,
};

export const convertFrequencyToColumnName = priceChangeFrequency =>
  PRICE_CHANGE_FREQUENCY_TO_COLUMN_NAME[priceChangeFrequency];

export const isPriceFrequencyColumnName = columnName =>
  Object.values(PRICE_CHANGE_FREQUENCY_TO_COLUMN_NAME).includes(columnName);

export const convertColumnNameToFrequency = columnName => COLUMN_NAME_TO_PRICE_CHANGE_FREQUENCY[columnName];

export const convertFrequencyToDimensionId = priceChangeFrequency =>
  PRICE_CHANGE_FREQUENCY_TO_DIM_ID[priceChangeFrequency];
