import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Users & Roles Settings';

const ACTIONS = {
  SEND_INVITE_ACTION_CLICK: 'Send Invite action click',
  RESEND_INVITE_ACTION_CLICK: 'Resend Invite action click',
  DELETE_USER_ACTION_CLICK: 'Delete User action click',
  UPDATE_USER_ROLE_ACTION_CLICK: 'Update User Role action click',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitSendInviteActionClick = () => {
  emitEvent({ action: ACTIONS.SEND_INVITE_ACTION_CLICK });
};

export const gaEmitResendInviteActionClick = () => {
  emitEvent({ action: ACTIONS.RESEND_INVITE_ACTION_CLICK });
};

export const gaEmitDeleteUserActionClick = () => {
  emitEvent({ action: ACTIONS.DELETE_USER_ACTION_CLICK });
};

export const gaEmitUpdateUserRoleActionClick = () => {
  emitEvent({ action: ACTIONS.UPDATE_USER_ROLE_ACTION_CLICK });
};
