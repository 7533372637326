import axios from 'axios';
import { useMutation } from 'react-query';
import accessTokenInstance from '../axios-instances/userRegistrationInstance';
import { KA_API_ROOT_URL } from '../../config/baseUrl';

/**
 * Requests
 */

/*
 * Uses the special Axios instance with the attached access token in the header to request a user registration code
 */

export const requestUserRegistrationCode = async (emailAddress, password) =>
  accessTokenInstance.post(`${KA_API_ROOT_URL}/stripe/registration-code`, {
    email_address: emailAddress,
    password,
  });

export const submitUserRegistrationCode = async (registrationCode, emailAddress, selectedPriceId, promoCode) =>
  accessTokenInstance.post(`${KA_API_ROOT_URL}/stripe/customer`, {
    registration_code: registrationCode,
    email_address: emailAddress,
    selected_price_id: selectedPriceId,
    promo_code: promoCode,
  });

export const setBillingInfo = async (emailAddress, city, line1, line2, postalCode, state, country, companyName) => {
  axios.put(`${KA_API_ROOT_URL}/stripe/billing-info`, {
    email_address: emailAddress,
    city,
    line1,
    line2,
    postal_code: postalCode,
    state,
    country,
    company_name: companyName,
  });
};

/**
 * Hooks
 */

export const useRequestRegistrationCode = mutationConfig =>
  useMutation(async ({ emailAddress, password }) => {
    const response = await requestUserRegistrationCode(emailAddress, password);

    return response;
  }, mutationConfig);

export const useRedeemRegistrationCode = mutationConfig =>
  useMutation(async ({ registrationCode, emailAddress, selectedPriceId, promoCode }) => {
    const response = await submitUserRegistrationCode(registrationCode, emailAddress, selectedPriceId, promoCode);

    return response;
  }, mutationConfig);
