/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-onchange */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../../../utils/axios-client';
import Header from '../../../header/header';
import './CFBKaizenPrice.css';
import { KA_API_URL } from '../../../../config/baseUrl';
import Chart from '../../../charts/lineChart';
import Legend from '../../../charts/lineChartLegend';
import { formatNumber } from '../../../util/format';
import { getTeamDetails, getRecords, getRankings } from './CFBKaizenPriceUtils';
import { mapCFBProductDimensions } from '../../../util/mapProductDimensions';
import HomeGamesTable from './HomeGamesTable';
import GameInfoSection from './GameInfoSection';
import SingleGameRevenueSummaryTable from './SingleGameRevenueSummaryTable';
import PriceLevelRecommendationDetailsSection from './PriceLevelRecommendationDetailsSection';
import CFBHeader from './CFBHeader';
import CFBFooter from './CFBFooter';

const moment = require('moment');
const momentTZ = require('moment-timezone');
const jstz = require('jstimezonedetect');

class CFBKaizenPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // caTickets endpoint response
      productVisits: [],
      futurePriceDemand: [],
      priceStats: [],
      // end caTickets response
      // response transforms
      teamDetailMap: new Map(),
      rankingsMap: new Map(),
      recordsMap: new Map(),
      // end response transforms
      gameArray: [],
      priceLevelDetails: [],
      error: false,
      recommendationDetails: [],
      recommendationDetailsPriceLevel: '',
      recommendationDetailsFooter: {},
      recommendationDetailsFooterShow: false,
      maxTimeStamp: '',
      intitialHighLightGame: true,
      intitialHighLightGameIndex: 0,
      intitialHighLightPriceZone: true,
      intitialHighLightPriceZoneIndex: 0,
      selectedGameIndex: '',
      selectedPriceZoneIndex: '',
      homeTeamLogo: '',
      homeTeam: '',
      timeZoneAbbr: '',
      logoLoaded: false,
      homeSchoolRank: '',
      selectedGame: {},
      selectedGameLogo: '',
      selectedGameTicketsSold: '',
      selectedGameTicketsRemaining: '',
      kickOff: '',
      currentGameInfoArray: [],
      seasonTicketsFlag: false,
      chartData: [
        {
          color: 'steelblue',
          points: [],
        },
      ],
      homeGamesCurrentSortProp: '',
      homeGamesSortObject: {
        date: {
          is_sorted: false,
          direction: 'ASC',
        },
        opponent: {
          is_sorted: false,
          direction: 'ASC',
        },
        revenue: {
          is_sorted: false,
          direction: 'ASC',
        },
      },
      singleGameCurrentSortProp: '',
      singleGameSortObject: {
        price_level: {
          is_sorted: false,
          direction: 'ASC',
        },
        uplift: {
          is_sorted: false,
          direction: 'ASC',
        },
        kaizen_price_change: {
          is_sorted: false,
          direction: 'ASC',
        },
      },
    };
    this.renderKickOff = this.renderKickoffTime.bind(this);
    this.renderUpcomingHomeGames = this.renderUpcomingHomeGames.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem('locations')) {
      try {
        const customerEntityId = localStorage.getItem('cfbLocationId');
        const teamName = localStorage.getItem('team');

        let { workflowId } = this.props?.match?.params;
        workflowId = parseInt(workflowId, 10);

        await this.setState({
          workflowId,
        });

        const maxPayDatesResponse = await axios.get(`${KA_API_URL}/datasets?data_type=TRAN`);
        const maxPayDates = [];
        for (let i = 0; i < maxPayDatesResponse.data.length; i++) {
          maxPayDates[i] = new Date(maxPayDatesResponse.data[i].pay_max_date);
        }
        let maxPayDate = '';
        maxPayDate = maxPayDates.length === 0 ? 'Invalid Date' : new Date(Math.max(...maxPayDates)).toDateString();

        const productVisitsResponse = await axios.get(
          `${KA_API_URL}/productvisit?location_id=${customerEntityId}&industry=cfb&limit=200&page_number=1`,
        );

        const maxTimeStamp = productVisitsResponse.data.length > 0 ? productVisitsResponse.data[0].run_datetime : null;

        const locationIds = `location_ids=${customerEntityId}`
        const dashboardLimitPage = `dashboard=cfb&limit=200&page_number=1`;
        const priceStatsResponse = await axios.get(
          `${KA_API_URL}/productvisit/pricestats?${locationIds}&${dashboardLimitPage}`,
        );

        const mappedProductVisit = await mapCFBProductDimensions(productVisitsResponse);

        const futurePriceDemand = [];
        for (let i = 0; i < priceStatsResponse.data.length; i++) {
          if (priceStatsResponse.data[i].is_kaizen_price || priceStatsResponse.data[i].is_current_price) {
            futurePriceDemand.push(priceStatsResponse.data[i]);
          }
        }

        const visitsResponse = await axios.get(
          `${KA_API_URL}/visits?location_id=${customerEntityId}&dashboard=cfb&limit=200&page_number=1`,
        );

        const teamDetails = await getTeamDetails(visitsResponse.data, teamName);
        const records = await getRecords(visitsResponse.data, teamName);
        const rankings = await getRankings();

        await this.setState({
          productVisits: mappedProductVisit,
          futurePriceDemand,
          priceStats: priceStatsResponse.data,
          maxTimeStamp,
          homeTeam: teamName,
        });

        // If there are no stats records then the pipeline likely has never been run or never completed
        if (priceStatsResponse.data.length === 0) {
          this.setState({
            error: true,
          });
          return;
        }

        // Create rankings map
        if (rankings.data != null) {
          const rankingsMap = new Map();
          for (let i = 0; i < rankings.data.length; ++i) {
            rankingsMap.set(rankings.data[i].school, rankings.data[i].rank);
          }
          await this.setState({
            rankingsMap,
          });
        }

        // Create records map
        if (records != null) {
          const recordsMap = new Map();
          for (let i = 0; i < records.length; ++i) {
            recordsMap.set(records[i].team, records[i]);
          }
          await this.setState({
            recordsMap,
          });
        }

        // Render Home Team Logo (and create team detail map)
        if (teamDetails.data != null) {
          const teamDetailMap = new Map();
          for (let i = 0; i < teamDetails.data.length; ++i) {
            // Convert logos to single logo
            if (teamDetails.data[i].logos != null) {
              const logoArray = teamDetails.data[i].logos.split("'");

              for (let j = 0; j < logoArray.length; j++) {
                if (
                  logoArray[j].includes('http') &&
                  !logoArray[j].includes('dark') &&
                  logoArray[j].includes('teamlogos')
                ) {
                  logoArray[j] = logoArray[j].replace('http:', 'https:');
                  teamDetails.data[i].logo = logoArray[j];
                  break;
                }
              }
            }
            teamDetailMap.set(teamDetails.data[i].team, teamDetails.data[i]);
            if (teamDetails.data[i].team === teamName) {
              this.renderTeamLogo(teamDetails.data[i].logo, teamName);
            }
          }
          await this.setState({
            teamDetailMap,
          });
        } else {
          await this.setState({
            homeTeamLogo: '',
            logoLoaded: true,
          });
        }

        // Render Dates
        this.renderMaxTransactions(maxPayDate, maxTimeStamp);

        // Render Upcoming Home Games
        this.renderUpcomingHomeGames(visitsResponse.data, mappedProductVisit, futurePriceDemand);

        // Render first game info, summary, first price level detail and chart
        const { gameArray, rankingsMap } = this.state;
        if (gameArray.length > 0) {
          await this.setState({
            selectedGame: gameArray[0],
            seasonTicketsFlag: gameArray[0].opponent.includes('Season'),
          });
          this.renderGameInfoLogo(gameArray[0].opponent);
          this.renderKickoffTime(gameArray[0].date);
          this.renderGameInfo(gameArray[0]);

          this.renderRevenueSummary(gameArray[0], mappedProductVisit, futurePriceDemand);

          if (rankingsMap.length > 0) {
            this.renderHomeSchoolRank();
          }
        }
      } catch (err) {
        this.setState({
          error: true,
        });
      }
    }
  }

  singleGameGetSortedArray = async data => {
    const { singleGameCurrentSortProp, singleGameSortObject } = this.state;
    const sortBy = [];

    if (singleGameCurrentSortProp !== '') {
      const { direction } = singleGameSortObject[singleGameCurrentSortProp];
      sortBy.push({
        prop: singleGameCurrentSortProp,
        direction: direction === 'ASC' ? 1 : -1,
      });
    }

    const sortedResult = data.sort((a, b) => {
      let i = 0;
      let result = 0;
      while (i < sortBy.length && result === 0) {
        const aModified = a[sortBy[i].prop] !== null ? a[sortBy[i].prop] : '';
        const bModified = b[sortBy[i].prop] !== null ? b[sortBy[i].prop] : '';

        result = sortBy[i].direction * (aModified < bModified ? -1 : aModified > bModified ? 1 : 0);
        i += 1;
      }
      return result;
    });

    return sortedResult;
  };

  calculateSingleGameSortData = async id => {
    const { singleGameCurrentSortProp, singleGameSortObject, priceLevelDetails } = this.state;

    if (singleGameCurrentSortProp !== '' && singleGameCurrentSortProp !== id) {
      singleGameSortObject[singleGameCurrentSortProp].is_sorted = false;
    }
    if (singleGameSortObject[id].is_sorted === false) {
      singleGameSortObject[id].is_sorted = true;
      singleGameSortObject[id].direction = 'ASC';
    } else if (singleGameSortObject[id].direction === 'ASC') {
      singleGameSortObject[id].direction = 'DESC';
    } else {
      singleGameSortObject[id].direction = 'ASC';
    }

    await this.setState({
      singleGameSortObject,
      singleGameCurrentSortProp: id,
    });

    const sortedData = await this.singleGameGetSortedArray(priceLevelDetails);
    await this.setState({
      priceLevelDetails: sortedData,
    });
  };

  homeGamesGetSortedArray = async data => {
    const { homeGamesCurrentSortProp, homeGamesSortObject } = this.state;
    const sortBy = [];

    if (homeGamesCurrentSortProp !== '') {
      const { direction } = homeGamesSortObject[homeGamesCurrentSortProp];
      sortBy.push({
        prop: homeGamesCurrentSortProp,
        direction: direction === 'ASC' ? 1 : -1,
      });
    }

    const sortedResult = data.sort((a, b) => {
      let i = 0;
      let result = 0;
      while (i < sortBy.length && result === 0) {
        const aModified = a[sortBy[i].prop] !== null ? a[sortBy[i].prop] : '';
        const bModified = b[sortBy[i].prop] !== null ? b[sortBy[i].prop] : '';

        result = sortBy[i].direction * (aModified < bModified ? -1 : aModified > bModified ? 1 : 0);
        i += 1;
      }
      return result;
    });

    return sortedResult;
  };

  calculateHomeGamesSortData = async id => {
    const { homeGamesCurrentSortProp, homeGamesSortObject, gameArray } = this.state;

    if (homeGamesCurrentSortProp !== '' && homeGamesCurrentSortProp !== id) {
      homeGamesSortObject[homeGamesCurrentSortProp].is_sorted = false;
    }
    if (homeGamesSortObject[id].is_sorted === false) {
      homeGamesSortObject[id].is_sorted = true;
      homeGamesSortObject[id].direction = 'ASC';
    } else if (homeGamesSortObject[id].direction === 'ASC') {
      homeGamesSortObject[id].direction = 'DESC';
    } else {
      homeGamesSortObject[id].direction = 'ASC';
    }

    await this.setState({
      homeGamesSortObject,
      homeGamesCurrentSortProp: id,
    });

    const sortedData = await this.homeGamesGetSortedArray(gameArray);

    await this.setState({
      gameArray: sortedData,
    });
  };

  handleTableDataClick = async data => {
    await this.setState({
      selectedGame: data,
      intitialHighLightGame: false,
      intitialHighLightPriceZone: true,
      selectedPriceZoneIndex: -1,
      seasonTicketsFlag: data.opponent.includes('Season'),
    });
  };

  handleRowClick = async index => {
    await this.setState({
      selectedGameIndex: index,
    });
  };

  handlePriceZoneClick = async index => {
    this.setState({
      selectedPriceZoneIndex: index,
      intitialHighLightPriceZone: false,
    });
  };

  renderKickoffTime = async date => {
    let kickOff = '';
    const splitT = date.split('T', 2);
    const splitDot = splitT[1].split('.', 2);
    const timeString = splitDot[0];
    const splitTime = timeString.split(':');
    if (splitTime.length >= 1) {
      let hours = parseInt(splitTime[0], 10);
      if (hours < 9) {
        kickOff = 'TBD';
      } else if (hours > 12) {
        hours -= 12;
        // eslint-disable-next-line
        kickOff = hours.toString() + ':' + splitTime[1] + ' ' + 'pm';
      } else {
        // eslint-disable-next-line
        kickOff = hours.toString() + ':' + splitTime[1] + ' ' + 'am';
      }
      await this.setState({
        kickOff,
      });
    }
  };

  renderHomeSchoolRank = async () => {
    const { rankingsMap } = this.state;
    const homeSchool = localStorage.getItem('team');
    if (rankingsMap.get(homeSchool) != null) {
      await this.setState({
        homeSchoolRank: rankingsMap.get(homeSchool),
      });
    }
  };

  async renderMaxTransactions(maxPayDate, maxTimeStamp) {
    if (maxPayDate !== 'unknown') {
      maxPayDate = moment(maxPayDate).format('MM-DD-YYYY');
    }
    if (maxTimeStamp !== 'unknown') {
      maxTimeStamp = moment(maxTimeStamp).format('MM-DD-YYYY HH:mm:ss');
    }
    const timeZoneName = jstz.determine().name();
    const timeZoneAbbr = momentTZ()
      .tz(timeZoneName)
      .zoneAbbr();
    await this.setState({
      maxTransaction: maxPayDate,
      maxTimeStamp,
      timeZoneAbbr,
    });
  }

  renderUpcomingHomeGames = async (visits, productVisits, futurePriceDemand) => {
    const { teamDetailMap, rankingsMap } = this.state;
    const gameMap = new Map();

    // Create map of games (visits) and roll up revenue, quantity sold, capacity
    for (let i = 0; i < visits.length; ++i) {
      // game info
      gameMap.set(visits[i].visit_id, {
        visit_id: visits[i].visit_id,
        date: visits[i].start_datetime,
        opponent: visits[i].description,
        uplift: 0,
        quantity_sold: 0,
        capacity: 0,
      });
      // revenue
      const gameMapEntry = gameMap.get(visits[i].visit_id);
      const gameFuturePriceDemand = futurePriceDemand.filter(d => d.visit_id === visits[i].visit_id);
      for (let j = 0; j < gameFuturePriceDemand.length; ++j) {
        if (gameFuturePriceDemand[j].is_kaizen_price === 1) {
          gameMapEntry.uplift += gameFuturePriceDemand[j].remaining_revenue;
        }
        if (gameFuturePriceDemand[j].is_current_price === 1) {
          gameMapEntry.uplift -= gameFuturePriceDemand[j].remaining_revenue;
        }
      }
      // quantity sold and capacity
      const gameProductVisits = productVisits.filter(d => d.visit_id === visits[i].visit_id);
      for (let j = 0; j < gameProductVisits.length; ++j) {
        gameMapEntry.quantity_sold += gameProductVisits[j].quantity_sold;
        gameMapEntry.capacity += gameProductVisits[j].capacity;
      }
    }
    // Calc max and use to set color percentage
    let maxUplift = 0;
    gameMap.forEach(value => {
      maxUplift = value.uplift > maxUplift ? value.uplift : maxUplift;
    });
    gameMap.forEach(value => {
      value.revenue = Math.round(value.uplift);
      value.color = (1 - (maxUplift - value.uplift) / maxUplift).toFixed(2);
    });

    // Convert to array and sort (season first then games by date)
    let gameArray = [];
    if (gameMap.size > 0) {
      gameArray = [...gameMap.values()].sort((a, b) => {
        if (a.opponent.includes('Season')) {
          return -1;
        }
        return moment(a.date) - moment(b.date);
      });

      // Add logo
      for (let i = 0; i < gameArray.length; i++) {
        const teamDetail = teamDetailMap.get(gameArray[i].opponent);
        if (teamDetail != null) {
          gameArray[i].logo = teamDetail.logo;
        }
      }

      // Add rank
      for (let i = 0; i < gameArray.length; i++) {
        gameArray[i].rank = rankingsMap.get(gameArray[i].opponent);
      }
    }

    await this.setState({
      gameArray,
    });
  };

  renderGameInfoLogo = async teamName => {
    if (teamName.includes('Season')) {
      return;
    }

    const { teamDetailMap } = this.state;
    const selectedGameLogo = teamDetailMap.get(teamName).logo;

    if (selectedGameLogo != null) {
      this.renderTeamLogo(selectedGameLogo, teamName);
    } else {
      await this.setState({
        selectedGameLogo: '',
      });
    }
  };

  renderGameInfo = async game => {
    const { recordsMap, rankingsMap, homeTeam } = this.state;
    const currentGameInfoArray = [];

    if (!game.opponent.includes('Season')) {
      const teamRecord = recordsMap.get(homeTeam);
      const teamRank = rankingsMap.get(homeTeam);
      const opponentRecord = recordsMap.get(game.opponent);
      const opponentRank = rankingsMap.get(game.opponent);

      currentGameInfoArray.push({
        metric: 'Record',
        homeTeam: `${teamRecord.wins}-${teamRecord.losses}`,
        awayTeam: `${opponentRecord.wins}-${opponentRecord.losses}`,
      });
      currentGameInfoArray.push({
        metric: 'Rank(AP)',
        homeTeam: teamRank != null ? teamRank : 'NR',
        awayTeam: opponentRank != null ? opponentRank : 'NR',
      });
      const isTeamLast = Object.entries(teamRecord.last).length > 0;
      const isOppLast = Object.entries(opponentRecord.last).length > 0;
      currentGameInfoArray.push({
        metric: 'Prior Game',
        homeTeam: isTeamLast ? (teamRecord.last.home_game ? 'vs ' : '@ ') + teamRecord.last.opponent : '',
        homeTeamScore: isTeamLast
          ? `${(teamRecord.last.win ? '(W ' : '(L ') + teamRecord.last.team_points}-${teamRecord.last.opponent_points})`
          : 'NA',
        awayTeam: isOppLast ? (opponentRecord.last.home_game ? 'vs ' : '@ ') + opponentRecord.last.opponent : '',
        awayTeamScore: isOppLast
          ? `${(opponentRecord.last.win ? '(W ' : '(L ') + opponentRecord.last.team_points}-${
              opponentRecord.last.opponent_points
            })`
          : 'NA',
      });
    }
    await this.setState({
      currentGameInfoArray,
      selectedGameTicketsSold: game.quantity_sold,
      selectedGameTicketsRemaining: game.capacity - game.quantity_sold,
    });
  };

  renderRevenueSummary = async (game, productVisits, futurePriceDemand) => {
    const priceLevelMap = new Map();

    const currentProductVisits = productVisits.filter(d => d.visit_id === game.visit_id);

    for (let i = 0; i < currentProductVisits.length; ++i) {
      priceLevelMap.set(currentProductVisits[i].product_visit_id, {
        product_id: currentProductVisits[i].product_id,
        price_level: currentProductVisits[i].product_dimension_value,
        quantity_sold: currentProductVisits[i].quantity_sold,
        capacity: currentProductVisits[i].capacity,
        current_price: 0,
        kaizen_price: 0,
        current_remaining_demand: 0,
        kaizen_remaining_demand: 0,
        current_remaining_revenue: 0,
        kaizen_remaining_revenue: 0,
        kaizen_price_change: 0,
        uplift: 0,
      });
    }

    for (let i = 0; i < futurePriceDemand.length; ++i) {
      const priceLevelDetail = priceLevelMap.get(futurePriceDemand[i].product_visit_id);
      if (priceLevelDetail != null) {
        if (futurePriceDemand[i].is_kaizen_price === 1) {
          priceLevelDetail.kaizen_price = futurePriceDemand[i].price;
          priceLevelDetail.kaizen_remaining_demand = futurePriceDemand[i].remaining_demand;
          priceLevelDetail.kaizen_remaining_revenue = futurePriceDemand[i].remaining_revenue;
          priceLevelDetail.uplift += futurePriceDemand[i].remaining_revenue;
        }
        if (futurePriceDemand[i].is_current_price === 1) {
          priceLevelDetail.current_price = futurePriceDemand[i].price;
          priceLevelDetail.current_remaining_demand = futurePriceDemand[i].remaining_demand;
          priceLevelDetail.current_remaining_revenue = futurePriceDemand[i].remaining_revenue;
          priceLevelDetail.uplift -= futurePriceDemand[i].remaining_revenue;
        }
      }
    }
    let maxUplift = 0;
    priceLevelMap.forEach(value => {
      maxUplift = value.uplift > maxUplift ? value.uplift : maxUplift;
    });
    priceLevelMap.forEach(value => {
      value.uplift = Math.round(value.uplift);
      value.kaizen_price_change =
        value.current_price !== 0 ? ((value.kaizen_price - value.current_price) / value.current_price) * 100 : 0;
      value.kaizen_demand_change =
        ((value.kaizen_remaining_demand - value.current_remaining_demand) / value.current_remaining_demand) * 100;
      value.kaizen_revenue_change =
        ((value.kaizen_remaining_revenue - value.current_remaining_revenue) / value.current_remaining_revenue) * 100;
      value.color = (1 - (maxUplift - value.uplift) / maxUplift).toFixed(2);
    });
    const priceLevelArray = [...priceLevelMap.values()].sort((a, b) => {
      return b.uplift - a.uplift;
    });

    await this.setState({
      priceLevelDetails: priceLevelArray,
    });

    const { selectedGame } = this.state;
    this.renderRecommendationDetails(priceLevelArray[0]);
    this.renderPriceDemandChart(priceLevelArray[0], selectedGame);
  };

  renderRecommendationDetails = async priceLevelDetail => {
    const priceLevelDetails = [];
    priceLevelDetails.push({
      metric: 'Price',
      current: priceLevelDetail.current_price,
      kaizen: priceLevelDetail.kaizen_price,
      change: priceLevelDetail.kaizen_price_change,
    });
    priceLevelDetails.push({
      metric: 'Demand',
      current: priceLevelDetail.current_remaining_demand,
      kaizen: priceLevelDetail.kaizen_remaining_demand,
      change: priceLevelDetail.kaizen_demand_change,
    });
    priceLevelDetails.push({
      metric: 'Revenue',
      current: priceLevelDetail.current_remaining_revenue,
      kaizen: priceLevelDetail.kaizen_remaining_revenue,
      change: priceLevelDetail.kaizen_revenue_change,
    });
    await this.setState({
      recommendationDetails: priceLevelDetails,
      recommendationDetailsPriceLevel: priceLevelDetail.price_level,
      recommendationDetailsFooter: {
        quantity_sold: priceLevelDetail.quantity_sold,
        capacity: priceLevelDetail.capacity,
        remaining_capacity: priceLevelDetail.capacity - priceLevelDetail.quantity_sold,
      },
      recommendationDetailsFooterShow: true,
    });
  };

  renderPriceDemandChart = async (priceLevelDetail, selectedGame) => {
    const { priceStats } = this.state;
    let initialGraphData = [];

    let kaizenPrice = 0;
    let currentPrice = 0;
    let kaizenDemand = 0;
    let kaizenRevenue = 0;

    if (priceLevelDetail !== null && priceLevelDetail !== undefined) {
      initialGraphData = priceStats.filter(
        d => d.product_id === priceLevelDetail.product_id && d.visit_id === selectedGame.visit_id,
      );
      kaizenPrice = priceLevelDetail.kaizen_price;
      currentPrice = priceLevelDetail.current_price;
      kaizenDemand = formatNumber(Math.round(priceLevelDetail.kaizen_remaining_demand));
      kaizenRevenue = formatNumber(Math.round(priceLevelDetail.kaizen_remaining_revenue));
    }

    const finalGraphData = [];

    for (let i = 0; i < initialGraphData.length; i++) {
      finalGraphData.push({
        x: initialGraphData[i].price,
        x0: initialGraphData[i].price.toFixed(2),
        y: initialGraphData[i].remaining_demand,
        y0: formatNumber(initialGraphData[i].remaining_demand),
        z: formatNumber(Math.round(initialGraphData[i].remaining_revenue)),
        kaizenPrice,
        kaizenPrice0: kaizenPrice.toFixed(2),
        currentPrice,
        currentPrice0: currentPrice.toFixed(2),
        kaizenDemand,
        kaizenRevenue,
      });
    }

    const chartData = [
      {
        color: 'steelblue',
        points: finalGraphData,
      },
    ];

    await this.setState({
      chartData,
    });
  };

  renderTeamLogo = async (logo, team) => {
    if (logo != null) {
      if (team === localStorage.getItem('team')) {
        await this.setState({
          homeTeamLogo: logo,
          logoLoaded: true,
        });
      } else {
        await this.setState({
          selectedGameLogo: logo,
        });
      }
    }
  };

  render() {
    const {
      error,
      logoLoaded,
      homeSchoolRank,
      homeTeamLogo,
      gameArray,
      homeGamesSortObject,
      futurePriceDemand,
      productVisits,
      selectedGame,
      selectedGameLogo,
      selectedGameIndex,
      intitialHighLightGame,
      intitialHighLightGameIndex,
      seasonTicketsFlag,
      kickOff,
      selectedGameTicketsRemaining,
      selectedGameTicketsSold,
      currentGameInfoArray,
      homeTeam,
      priceLevelDetails,
      singleGameSortObject,
      selectedPriceZoneIndex,
      intitialHighLightPriceZone,
      intitialHighLightPriceZoneIndex,
      recommendationDetails,
      recommendationDetailsPriceLevel,
      recommendationDetailsFooterShow,
      recommendationDetailsFooter,
      chartData,
      maxTimeStamp,
      maxTransaction,
      timeZoneAbbr,
      workflowId,
    } = this.state;

    let errorMessage = null;
    if (error) {
      errorMessage = (
        <div
          style={{
            fontSize: '12px',
            color: 'red',
            textAlign: 'center',
            padding: '6px',
          }}
        >
          <h5>There was a problem loading data. Please try later.</h5>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Header />
        <div className="container-fluid cfb-heading">
          {/* Heading and Price/Explore option */}
          <CFBHeader
            logoLoaded={logoLoaded}
            homeSchoolRank={homeSchoolRank}
            homeTeamLogo={homeTeamLogo}
            refreshResults={this.refreshResults}
            errorMessage={errorMessage}
            workflowId={workflowId}
          />

          <div className="row">
            <div className="col-sm-4">
              {/* HomeGames Table */}
              <HomeGamesTable
                gameArray={gameArray}
                homeGamesSortObject={homeGamesSortObject}
                selectedGameIndex={selectedGameIndex}
                intitialHighLightGame={intitialHighLightGame}
                intitialHighLightGameIndex={intitialHighLightGameIndex}
                productVisits={productVisits}
                futurePriceDemand={futurePriceDemand}
                renderGameInfo={this.renderGameInfo}
                renderGameInfoLogo={this.renderGameInfoLogo}
                renderKickoffTime={this.renderKickoffTime}
                renderRevenueSummary={this.renderRevenueSummary}
                handleTableDataClick={this.handleTableDataClick}
                handleRowClick={this.handleRowClick}
                calculateHomeGamesSortData={this.calculateHomeGamesSortData}
              />
              <br />

              {/* Game Info Section */}
              <GameInfoSection
                currentGameInfoArray={currentGameInfoArray}
                selectedGameLogo={selectedGameLogo}
                selectedGame={selectedGame}
                homeTeam={homeTeam}
                homeTeamLogo={homeTeamLogo}
                seasonTicketsFlag={seasonTicketsFlag}
                kickOff={kickOff}
                selectedGameTicketsSold={selectedGameTicketsSold}
                selectedGameTicketsRemaining={selectedGameTicketsRemaining}
              />
            </div>

            {/* SingleGameRevenueSummaryTable */}
            <div className="col-sm-4">
              <SingleGameRevenueSummaryTable
                selectedGame={selectedGame}
                priceLevelDetails={priceLevelDetails}
                singleGameSortObject={singleGameSortObject}
                calculateSingleGameSortData={this.calculateSingleGameSortData}
                renderPriceDemandChart={this.renderPriceDemandChart}
                renderRecommendationDetails={this.renderRecommendationDetails}
                selectedPriceZoneIndex={selectedPriceZoneIndex}
                intitialHighLightPriceZone={intitialHighLightPriceZone}
                intitialHighLightPriceZoneIndex={intitialHighLightPriceZoneIndex}
                handlePriceZoneClick={this.handlePriceZoneClick}
              />
            </div>

            {/* Price Level Recommendation Details and Line Chart */}
            <div className="col-sm-4">
              <PriceLevelRecommendationDetailsSection
                recommendationDetailsPriceLevel={recommendationDetailsPriceLevel}
                recommendationDetails={recommendationDetails}
                recommendationDetailsFooter={recommendationDetailsFooter}
                recommendationDetailsFooterShow={recommendationDetailsFooterShow}
              />
              <div>
                <h6 className="chart-heading">Price vs. Remaining Demand</h6>
                <Chart data={chartData} />
                {chartData[0].points.length > 0 ? <Legend /> : <div />}
              </div>
            </div>
          </div>

          {/* Footer timestamps */}
          {maxTransaction !== '' && maxTimeStamp !== '' ? (
            <CFBFooter maxTimeStamp={maxTimeStamp} maxTransaction={maxTransaction} timeZoneAbbr={timeZoneAbbr} />
          ) : (
            <div />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, {})(CFBKaizenPrice);
