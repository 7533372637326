import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_ROOT_URL, KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_USER_INFO_BASE = `${KA_API_URL}/users`;
export const GET_CUSTOMER_INFO_BASE = `${KA_API_URL}/customer`;

/**
 * Requests
 */

export const fetchUserInfo = async userId => axios.get(`${GET_USER_INFO_BASE}/${userId}`);
export const fetchCustomerInfo = async () => axios.get(GET_CUSTOMER_INFO_BASE);
export const fetchAllCustomers = async () => axios.get(`${KA_API_URL}/customers?search=*`);
export const fetchUsers = async customerId => axios.get(`${KA_API_URL}/customers/users?customer_id=${customerId}`);
export const fetchIsImpersonating = async () => axios.get(`${KA_API_ROOT_URL}/auth/impersonate`);

/**
 * Hooks
 */

const USER_INFO_QUERY_KEY_BASE = 'userInfo';
const CUSTOMER_INFO_QUERY_KEY_BASE = 'customerInfo';
const ALL_CUSTOMERS_QUERY_KEY = 'allCustomers';
const USERS_QUERY_KEY_BASE = 'users';
const IMPERSONATING_KEY_BASE = 'impersonating';

export const useUserInfo = (userId, queryConfig) => {
  userId = userId ?? localStorage.getItem('id');

  return useQuery(
    [USER_INFO_QUERY_KEY_BASE, userId],
    async () => {
      const response = await fetchUserInfo(userId);

      return response.data;
    },
    queryConfig,
  );
};

export const useCustomerInfo = queryConfig =>
  useQuery(
    [CUSTOMER_INFO_QUERY_KEY_BASE],
    async () => {
      const response = await fetchCustomerInfo();

      return response.data;
    },
    queryConfig,
  );

export const useAllCustomers = queryConfig =>
  useQuery(
    [ALL_CUSTOMERS_QUERY_KEY],
    async () => {
      const response = await fetchAllCustomers();

      return response.data;
    },
    queryConfig,
  );

export const useUsers = (customerId, queryConfig) =>
  useQuery(
    [USERS_QUERY_KEY_BASE, customerId],
    async () => {
      if (customerId == null) {
        return [];
      }

      const response = await fetchUsers(customerId);

      return response.data;
    },
    queryConfig,
  );

export const useIsImpersonating = queryConfig =>
  useQuery(
    [IMPERSONATING_KEY_BASE],
    async () => {
      const response = await fetchIsImpersonating();

      return response.data;
    },
    queryConfig,
  );
