import { useQueryClient } from 'react-query';
import { GUIDED_SETUP_QUERY_KEY } from '../../../data-access/query/guidedSetup';
import { useUpdateGuidedSetup } from '../../../data-access/mutation/guidedSetup';

// eslint-disable-next-line import/prefer-default-export
export const useUpdateGuidedSetupState = () => {
  const queryClient = useQueryClient();

  const updateGuidedSetupMutation = useUpdateGuidedSetup({
    onSuccess: async () => queryClient.invalidateQueries(GUIDED_SETUP_QUERY_KEY),
  });

  const updateGuidedSetup = async changes => {
    await updateGuidedSetupMutation.mutateAsync(changes);
  };

  return { updateGuidedSetup };
};
