const { Rule } = require('./password-policy');

const MIN_LENGTH = 8;

const ruleMinLength = password => {
  return password.length >= MIN_LENGTH;
};

const ruleLowercase = password => {
  return /[a-z]/.test(password);
};

const ruleUppercase = password => {
  return /[A-Z]/.test(password);
};

const ruleDigit = password => {
  return /[0-9]/.test(password);
};

const SPECIAL_CHARS = '!@#$%&*?';
const SPECIAL_CHARS_PATTERN = `[${SPECIAL_CHARS}]`;

const ruleSpecialCharacter = password => {
  return new RegExp(SPECIAL_CHARS_PATTERN).test(password);
};

const FORBIDDEN_WORDS = ['password'];

const ruleForbiddenWords = password => {
  return (
    password && password.length > 0 && !FORBIDDEN_WORDS.some(word => password.toLowerCase().match(new RegExp(word)))
  );
};

const minLength = new Rule(`Must be at least ${MIN_LENGTH} characters`, ruleMinLength, 'minlength');
const lowercase = new Rule('Must contain at least one lowercase letter', ruleLowercase, 'lowercase');
const uppercase = new Rule('Must contain at least one uppercase letter', ruleUppercase, 'uppercase');
const numeric = new Rule('Must contain at least one digit', ruleDigit, 'digit');
const special = new Rule(
  `Must contain at least one special character (${SPECIAL_CHARS})`,
  ruleSpecialCharacter,
  'special',
);
const forbidden = new Rule("Must not contain a forbidden word (e.g. 'password')", ruleForbiddenWords, 'forbidden');

const baseProfitRoverRules = [minLength, lowercase, uppercase, numeric, special, forbidden];

module.exports = {
  baseProfitRoverRules,
};
