import axios from 'axios';
import { useMutation } from 'react-query';
import { mutateNaicsKaCode } from './common';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const createLocation = async location => {
  mutateNaicsKaCode(location);

  return axios.post(`${KA_API_URL}/locations`, location);
};

export const updateLocation = async (locationId, location) =>
  axios.put(`${KA_API_URL}/locations/${locationId}`, location);

const deleteLocation = async locationId => axios.delete(`${KA_API_URL}/locations/${locationId}`);

/**
 * Hooks
 */

export const useCreateLocation = mutationConfig =>
  useMutation(async location => {
    const response = await createLocation(location);

    return response?.data;
  }, mutationConfig);

export const useUpdateLocation = mutationConfig =>
  useMutation(async ({ locationId, location }) => {
    const response = await updateLocation(locationId, location);

    return response?.data ?? {};
  }, mutationConfig);

export const useDeleteLocation = mutationConfig =>
  useMutation(async ({ locationId }) => {
    const response = await deleteLocation(locationId);

    return response?.data;
  }, mutationConfig);
