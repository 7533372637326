import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useGuidedSetupContext } from './guidedSetupContext';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import Header from '../../header/header';
import { ProfitRoverCard } from '../../generic/ProfitRoverCard';
import { CheckedBullet } from './Util';
import dashboardSummaryImgSrc from '../../../images/profitrover-summary.JPG';
import ProfitRoverLogoImgSrc from '../../../images/profitrover-logo-blue.png';
import { ReactComponent as SetupIcon } from '../../../images/setup-icon.svg';
import './guided-setup-welcome.scss';

const GuidedSetupWelcome = () => {
  const {
    stepState: { goToNextStep },
    guidedSetup: { app_store_install_source: appStoreInstallSource },
  } = useGuidedSetupContext();

  return (
    <HeaderAwarePage scrollable={false}>
      <Header />

      <div className="guided-setup-welcome">
        <div className="layout">
          <div className="welcome">
            <div className="cut-card">
              <h1 className="heading">
                Welcome to&nbsp;
                <img
                  height="32px"
                  src={ProfitRoverLogoImgSrc}
                  alt="ProfitRover Logo"
                  style={{ position: 'relative', bottom: 5, left: 5 }}
                />
                &nbsp;!
              </h1>
              <p className="subtitle mb-4">
                We can&apos;t wait to reveal your profit-enhancing opportunities! Let&apos;s set you up first.
              </p>

              <p className="setup-text mb-2">
                This setup will take about 5-10 minutes, and you can pick back up at any point.
              </p>

              <div className="d-flex">
                <SetupIcon />

                <ol className="mt-3">
                  <li>Tell us about your company</li>
                  <li>
                    {appStoreInstallSource == null
                      ? 'Connect to your Point of Sale System OR upload data'
                      : 'Describe what kinds of purchases your data represents'}
                  </li>
                  <li>Unlock your insights</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="benefits">
            <ProfitRoverCard className="card pt-lg-5">
              <div className="img-container d-flex justify-content-center">
                <img src={dashboardSummaryImgSrc} alt="Dashboard with summary statistics" />
              </div>

              <div className="d-flex justify-content-center">
                <div style={{ margin: 'auto' }}>
                  <h2 className="heading mt-5 mb-3">You&apos;re just minutes away from these benefits:</h2>
                  <ul>
                    <CheckedBullet>
                      Staffing recommendations that will help cut costs and optimize your guest experience
                    </CheckedBullet>
                    <CheckedBullet>Pricing recommendations that will grow your revenue</CheckedBullet>
                    <CheckedBullet>Detailed forecasts that help you plan for the days and months ahead</CheckedBullet>
                  </ul>
                  <div className="py-3">
                    <ProfitRoverPrimaryButton onClick={goToNextStep}>
                      Let&apos;s Get Set Up
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        className="ml-2"
                        style={{ fontSize: '1.5rem', marginTop: 1 }}
                      />
                    </ProfitRoverPrimaryButton>
                  </div>
                </div>
              </div>
            </ProfitRoverCard>
          </div>
        </div>
      </div>
    </HeaderAwarePage>
  );
};

export default GuidedSetupWelcome;
