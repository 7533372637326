/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Toggle from 'react-toggle';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { Notification } from './Notifications';
import { useListenForNotificationEvents } from './notificationCenterHooks';
import { NOTIFICATION_TYPES, NOTIFICATION_TYPE_BY_PAYLOAD_TYPE } from './NotificationCenterConstants';
import NotificationCenterContext from './NotificationCenterContext';
import ProfitRoverSpinner from '../spinner/ProfitRoverSpinner';
import { updateNotifications } from '../../data-access/mutation/notifications';
import { useDatasetConfigs } from '../../data-access/query/datasetConfigs';
import { useDatasets } from '../../data-access/query/datasets';
import { useDimensions } from '../../data-access/query/dimensions';
import { useWorkflows } from '../../data-access/query/workflows';
import {
  gaEmitHeaderBellClick,
  gaEmitMarkAllAsReadClick,
  gaEmitSwitchToAlertTabClick,
  gaEmitSwitchToInfoTabClick,
  gaEmitToggleShowOnlyUnread,
} from '../../google-analytics/notificationCenter';
import { markNotificationsReadByType, hideMenu } from '../../redux/notificationCenter/actions';
import './notification-center.css';

export const HEADER_ICON_ID = 'notification-center-header-icon';
const NO_UNREAD_NOTIFICATIONS_TEXT = 'No new notifications to display.';
const NO_NEWER_NOTIFICATIONS_FOOTER_TEXT = "That's all of your notifications for the last 30 days.";

/**
 * TODO: Make a generic version of this
 */
const useOutsideClickDetection = () => {
  const boundaryRef = React.useRef();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const closeMenu = () => dispatch(hideMenu());

    const handleClick = e => {
      const node = boundaryRef.current;

      const clickedInside = node?.contains(e.target);

      const headerIconElement = document.getElementById(HEADER_ICON_ID);
      const clickedOnNotificationCenterIcon = headerIconElement?.contains(e.target);

      if (clickedOnNotificationCenterIcon) {
        gaEmitHeaderBellClick();
      }

      if (clickedInside || clickedOnNotificationCenterIcon) {
        // Ignore click
        return;
      }

      // Clicked outside the menu
      closeMenu();
    };

    // Add when component mounts
    document.addEventListener('mousedown', handleClick);

    // Unregister event listener when component unmounts/dependencies change
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [dispatch]);

  return { boundaryRef };
};

const sortDateTimeAscending = (a, b) => moment(b.created_at) - moment(a.created_at);

const useNotificationCenterActions = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(state => state.notificationCenter.isOpen);

  const updateAllNotificationsRead = React.useCallback(
    notificationType => {
      dispatch(markNotificationsReadByType(notificationType));
    },
    [dispatch],
  );

  return {
    isOpen,
    updateAllNotificationsRead,
  };
};

const updateCurrentNotifications = async (notifications, updateAllNotificationsRead, notificationType) => {
  gaEmitMarkAllAsReadClick();
  const notificationIds = [];

  notifications.forEach(notification => {
    if (!notification.read) {
      notificationIds.push(notification.id);
    }
  });

  if (notificationIds.length > 0) {
    await updateNotifications(notificationIds);
    updateAllNotificationsRead(notificationType);
  }
};

const MarkAllReadContainer = ({ notifications, updateAllNotificationsRead, notificationType }) => {
  return (
    <div
      className="mark-read-text"
      alt="Mark all as read"
      onClick={() => updateCurrentNotifications(notifications, updateAllNotificationsRead, notificationType)}
    >
      Mark all as read
    </div>
  );
};

const NotificationsList = ({ notifications = [], updateAllNotificationsRead, notificationType, showUnread }) => {
  const [sortedToday, sortedOlder] = React.useMemo(() => {
    const now = moment();
    const [today, older] = _.partition(notifications, notification => {
      const isNotificationCreatedToday = moment
        .tz(notification.created_at, 'UTC')
        .local()
        .isSame(now, 'day');
      return isNotificationCreatedToday;
    });

    const sortedTodayArr = today.sort(sortDateTimeAscending);
    const sortedOlderArr = older.sort(sortDateTimeAscending);

    return [sortedTodayArr, sortedOlderArr];
  }, [notifications]);
  const footerText =
    notifications.length > 0 && showUnread
      ? NO_NEWER_NOTIFICATIONS_FOOTER_TEXT
      : sortedToday.length === 0 && sortedOlder.length === 0 && showUnread
      ? NO_UNREAD_NOTIFICATIONS_TEXT
      : NO_NEWER_NOTIFICATIONS_FOOTER_TEXT;

  return (
    <>
      <div className="notifications-list-container">
        {sortedToday.length > 0 && (
          <div className="today-notifications-container">
            <div className="today-notifications-title">
              <p>Today</p>
              <MarkAllReadContainer
                notificationType={notificationType}
                updateAllNotificationsRead={updateAllNotificationsRead}
                notifications={notifications}
              />
            </div>
            {sortedToday.map(notification => (
              <Notification key={notification.id} notification={notification} />
            ))}
          </div>
        )}
        <div className="older-notifications-container">
          <div className="older-notifications-title">
            <p>Older</p>
            {sortedToday.length === 0 && (
              <MarkAllReadContainer
                notificationType={notificationType}
                updateAllNotificationsRead={updateAllNotificationsRead}
                notifications={notifications}
              />
            )}
          </div>
          {sortedOlder.map(notification => (
            <Notification key={notification.id} notification={notification} />
          ))}
        </div>
      </div>
      <NotificationFooter footerText={footerText} />
    </>
  );
};

const NotificationFooter = ({ footerText }) => (
  <>
    <hr className="footer-line" />
    <div className="text-center mb-5 mx-4">{footerText}</div>
  </>
);

const AuthenticatedNotificationCenter = () => {
  const { notifications } = useListenForNotificationEvents();
  const { isOpen, updateAllNotificationsRead } = useNotificationCenterActions();
  const { data: workflows, error: workflowsError, isFetching: isFetchingWorkflows } = useWorkflows();
  const { data: datasets, error: datasetsError, isFetching: isFetchingDatasets } = useDatasets();
  const { data: dimensions, error: dimensionsError, isFetching: isFetchingDimensions } = useDimensions(false);
  const [showUnread, setShowUnread] = React.useState(true);
  const {
    data: datasetConfigs,
    error: datasetConfigsError,
    isFetching: isFetchingDatasetConfigs,
  } = useDatasetConfigs();

  const notificationCenterContextValue = React.useMemo(
    () => ({
      workflowsById: _.keyBy(workflows, 'workflow_id'),
      datasetsById: _.keyBy(datasets, 'dataset_id'),
      datasetConfigsById: _.keyBy(datasetConfigs, 'dataset_config_id'),
      nonDerivedDimensions: dimensions?.map(dim => dim.product_dimension_id),
    }),
    [workflows, datasets, datasetConfigs, dimensions],
  );

  const { boundaryRef } = useOutsideClickDetection();

  const toggleShowUnread = () => {
    gaEmitToggleShowOnlyUnread();
    setShowUnread(!showUnread);
  };

  const tabSwitch = key => {
    switch (key) {
      case NOTIFICATION_TYPES.ALERTS:
        gaEmitSwitchToAlertTabClick();
        break;
      case NOTIFICATION_TYPES.INFO:
        gaEmitSwitchToInfoTabClick();
        break;
      default:
        break;
    }
  };

  const [alertNotifications, infoNotifications] = _.partition(notifications, notification =>
    NOTIFICATION_TYPE_BY_PAYLOAD_TYPE.ALERTS.includes(notification.payload_type),
  );

  const unreadAlertNotifications = alertNotifications.filter(notification => notification.read !== true);
  const unreadInfoNotifications = infoNotifications.filter(notification => notification.read !== true);

  localStorage.setItem('has_alert_notifications', alertNotifications.length > 0);

  const numOfUnreadAlerts = unreadAlertNotifications.length;
  const numOfUnreadInfos = unreadInfoNotifications.length;

  const isMenuVisible = isOpen;
  const workflowsLoading = workflowsError || isFetchingWorkflows;
  const datasetsLoading = datasetsError || isFetchingDatasets;
  const datasetConfigsLoading = datasetConfigsError || isFetchingDatasetConfigs;
  const dimensionsLoading = dimensionsError || isFetchingDimensions;
  const notificationCenterLoading = workflowsLoading || datasetsLoading || datasetConfigsLoading || dimensionsLoading;

  return (
    <section ref={boundaryRef} className={classnames('notification-center', { open: isMenuVisible })}>
      {notificationCenterLoading ? (
        <ProfitRoverSpinner />
      ) : (
        <>
          <div className="header">
            <h4>
              <strong>Notification Center</strong>
            </h4>
            <div className="unread-header-toggle">
              <h2>Show only unread</h2>
              <Toggle icons={{ unchecked: null }} checked={showUnread} onChange={toggleShowUnread} />
            </div>
          </div>
          <NotificationCenterContext.Provider value={notificationCenterContextValue}>
            <div className="notifications-area">
              {numOfUnreadAlerts > 0 && <div className="unread-alerts-number">{numOfUnreadAlerts}</div>}
              {numOfUnreadInfos > 0 && <div className="unread-info-number">{numOfUnreadInfos}</div>}
              <Tabs defaultActiveKey={NOTIFICATION_TYPES.ALERTS} onSelect={tabSwitch}>
                <Tab tabClassName="nc-tab" title="Alerts" eventKey={NOTIFICATION_TYPES.ALERTS}>
                  <NotificationsList
                    updateAllNotificationsRead={updateAllNotificationsRead}
                    notifications={showUnread ? unreadAlertNotifications : alertNotifications}
                    notificationType={NOTIFICATION_TYPES.ALERTS}
                    showUnread
                  />
                </Tab>
                <Tab tabClassName="nc-tab" title="Info" eventKey={NOTIFICATION_TYPES.INFO}>
                  <NotificationsList
                    updateAllNotificationsRead={updateAllNotificationsRead}
                    notifications={showUnread ? unreadInfoNotifications : infoNotifications}
                    notificationType={NOTIFICATION_TYPES.INFO}
                    showUnread
                  />
                </Tab>
              </Tabs>
            </div>
          </NotificationCenterContext.Provider>
        </>
      )}
    </section>
  );
};

const NotificationCenter = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  if (!isAuthenticated) {
    return null;
  }

  return <AuthenticatedNotificationCenter />;
};

export default NotificationCenter;
