import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAbilityContext, Can } from '../../../casl/abilityContext';
import { Action, Subject } from '../../../casl/caslConstants';

export const useAdminRoleCheck = () => {
  const ability = useAbilityContext();
  return ability?.can(Action.MANAGE, Subject.ALL);
};

const AdminRoleCheck = ({ children, redirect }) => (
  <Can I={Action.MANAGE} a={Subject.ALL} passThrough>
    {allowed => (allowed ? <>{children}</> : <Redirect to={redirect ?? '/'} />)}
  </Can>
);

export default AdminRoleCheck;
