import axios from 'axios';
import { useMutation } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const updateStaffingRoles = async staffingRoles =>
  axios.put(`${KA_API_URL}/config/staffing_roles`, staffingRoles);

/**
 * Hooks
 */

export const useUpdateStaffingRoles = mutationConfig =>
  useMutation(async staffingRoles => {
    const response = await updateStaffingRoles(staffingRoles);

    return response?.data ?? {};
  }, mutationConfig);
