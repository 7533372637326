import classnames from 'classnames';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FONT_BLACK } from '../../colors';
import './profitrover-spinner.css';

const ProfitRoverSpinner = ({ className, style, color = FONT_BLACK }) => {
  return (
    <Spinner
      className={classnames('profitrover-spinner', className)}
      style={{ ...style, color }}
      animation="border"
      role="status"
    />
  );
};

export const CenteredProfitRoverSpinner = ({ className, spinnerClassName, style, color = FONT_BLACK, loadingText }) => {
  return (
    <div className={classnames('centered-profitrover-spinner', className)}>
      <ProfitRoverSpinner className={spinnerClassName} style={style} color={color} />
      {loadingText && <div style={{ fontSize: '24px' }}> {loadingText}</div>}
    </div>
  );
};

export default ProfitRoverSpinner;
