import { AUTH_ERROR, LOGOUT, JWT_REFRESH, USER_LOADED } from './types';
import { IN_DEVELOPMENT_BUILD } from '../../config/build';
import { attemptLogin } from '../../data-access/query/authentication';
import store from '../../store';

export const attemptToReloadExistingUserSession = () => dispatch => {
  const accessTokenInLocalStorage = !!localStorage.getItem('token');

  dispatch({
    type: accessTokenInLocalStorage ? USER_LOADED : AUTH_ERROR,
  });
};

export const setAuthInLocalStorage = (email, loginData) => {
  localStorage.setItem('email', email);
  localStorage.setItem('id', loginData.id);
  localStorage.setItem('token', loginData.token);
  localStorage.setItem('renewal_token', loginData.renewal_token);
  localStorage.setItem('name', loginData.name);
  localStorage.setItem('full_name', loginData.full_name);
  localStorage.setItem('customer_name', loginData.customer_name);
  localStorage.setItem('customer_id', loginData.customer_id);
  localStorage.setItem('kdl_api_key', loginData.kdl_api_key);
};

const detectNewerAppVersion = version => {
  const browserVersion = localStorage.getItem('kp_app_version') ?? undefined;
  const newerVersionDetected = version != null && version !== browserVersion;

  if (newerVersionDetected) {
    if (IN_DEVELOPMENT_BUILD) {
      // eslint-disable-next-line no-console
      console.log(`api header version=${version}\nlocal storage version=${localStorage.getItem('kp_app_version')}`);
    }

    localStorage.setItem('kp_app_version', version);
  }

  return newerVersionDetected;
};

export const signIn = (email, password) => {
  return async dispatch => {
    try {
      const response = await attemptLogin(email, password);

      const { data: loginData, status, headers } = response;
      const { token: accessToken } = loginData;

      const latestKPAppVersion = headers['kp-app-version'];
      const accessTokenIsPresent = accessToken != null;
      const responseIsAcceptable = status === 200;
      const responseIsValid = responseIsAcceptable && accessTokenIsPresent;

      if (!responseIsValid) {
        throw new Error({ response, message: 'Authentication response was unacceptable' });
      }

      setAuthInLocalStorage(email, loginData);

      const newerVersionDetected = detectNewerAppVersion(latestKPAppVersion);

      dispatch({
        type: USER_LOADED,
      });

      return newerVersionDetected;
    } catch (error) {
      dispatch({ type: AUTH_ERROR });

      // Re-throw so the caller/dispatcher can handle the error too
      throw error;
    }
  };
};

export const logout = () => ({
  type: LOGOUT,
});

export const refreshJWT = () => ({
  type: JWT_REFRESH,
});

const dispatchUser = () => {
  return async dispatch => {
    try {
      dispatch({
        type: USER_LOADED,
      });
    } catch (error) {
      dispatch({ type: AUTH_ERROR });
      throw error;
    }
  };
};

/**
 * Used by impersonate user
 */
export const loadUser = () => {
  store.dispatch(dispatchUser());
};
