import React from 'react';
import _ from 'lodash';
import { Field, useField, useForm } from 'react-final-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FORM_ERROR } from 'final-form';
import ConfigAccordionItem from './ConfigAccordionItem';
import CreateLocationModal from './CreateLocationModal';
import { useConfigTableContext } from './configTableContext';
import { useInitialLocationAccordion } from '../fieldInitializers';
import { useCreateLocation } from '../../../data-access/mutation/locations';
import { getWeatherLocationInfo } from '../../../data-access/query/kdl/weather';
import { fetchLocation, useLocations } from '../../../data-access/query/locations';

const DEFAULT_NAME = 'location-accordion';
const DEFAULT_TITLE = 'Location';
const DEFAULT_UUID = 'LOCATION';

const LocationAccordion = ({ title = DEFAULT_TITLE, uuid = DEFAULT_UUID, name = DEFAULT_NAME }) => {
  const { naicsCode, kaCode } = useConfigTableContext();

  const { initialFormState, checkboxName, dropdownName, columnName, datasetFieldIdName } = useInitialLocationAccordion(
    name,
  );
  useField(datasetFieldIdName, { initialValue: initialFormState.datasetFieldId });

  const [showCreateLocationModal, setShowCreateLocationModal] = React.useState(false);
  const [createdLocationName, setCreatedLocationName] = React.useState();

  const { data: locations = [], refetch: refetchLocations } = useLocations();
  const locationOptions = locations.map(location => ({
    value: location,
    label: location?.location_description ?? location?.location_identifier,
  }));
  const createLocation = useCreateLocation();

  const { columnNameOptions = [] } = useConfigTableContext();

  const formApi = useForm();
  const formState = formApi.getState();

  const data = formState.values[name];
  const isFieldInData = data?.checkbox;

  const errors = formState.errors[name] ?? {};
  const isComplete = Object.entries(errors).every(([, value]) => {
    return value == null;
  });

  const selectRef = React.useRef(null);

  const closeModal = () => {
    setShowCreateLocationModal(false);

    /**
     * Fixes issue where <Select> would not be clickable after dismissing the Modal
     * (the issue does seem to be related to focus management). It also helps guide the
     * user back to what they were doing prior to launching the modal.
     */
    if (selectRef.current) {
      selectRef.current.focus();
    }
  };

  return (
    <ConfigAccordionItem title={title} uuid={uuid} valid={isComplete}>
      <div className="dataset-accordion-field-container">
        <div className="accordion-field-container-row">
          <div className="field-content-container">
            <div className="field-details-container">
              <Field name={checkboxName} type="checkbox" initialValue={initialFormState.checkbox}>
                {props => <input className="mx-2" {...props.input} />}
              </Field>
              <div className="accordion-field-description">This dataset is for 1 location</div>
            </div>
          </div>
        </div>
        <div style={{ height: '75px', width: '1px', border: '.5px solid black', margin: '10px', opacity: '30%' }} />

        <div className="accordion-field-container-row">
          <div className="field-content-container">
            <div className="location-selection-container">
              {isFieldInData ? (
                <>
                  {showCreateLocationModal && (
                    <CreateLocationModal
                      initialLocationName={createdLocationName}
                      onHide={closeModal}
                      onFinish={async location => {
                        // get weather information for the new location
                        const { city, state, country_name: countryName, zip } = location;
                        const weatherLocationResponse = await getWeatherLocationInfo(city, state, zip, countryName);

                        if (!_.isEmpty(weatherLocationResponse)) {
                          const { weatherLocationId, timezone, latitude, longitude } = weatherLocationResponse;

                          location = {
                            ...location,
                            naics_code: naicsCode,
                            ka_code: kaCode,
                            kdl_weather_location_id: weatherLocationId,
                            timezone,
                            latitude,
                            longitude,
                          };
                        }
                        const { id } = await createLocation.mutateAsync(location);
                        await refetchLocations();

                        // Lookup newly created location
                        const locationResponse = await fetchLocation(id);
                        const newLocation = locationResponse?.data;

                        const newLocationOption = { label: newLocation.location_description, value: newLocation };

                        // Use form mutator to update Form state programmatically
                        formApi.mutators.addNewLocation([dropdownName, newLocationOption]);
                        setCreatedLocationName(newLocation.location_description);

                        closeModal();
                      }}
                    />
                  )}

                  {/* Pick an existing location (or create one on-the-fly) */}
                  <Field
                    name={dropdownName}
                    allowNull
                    initialValue={initialFormState.fixedValue}
                    validate={(value, allValues) => {
                      const val = allValues[name]?.fixedValue;
                      return val == null ? FORM_ERROR : undefined;
                    }}
                    isEqual={(a, b) => a?.value?.location_id === b?.value?.location_id}
                  >
                    {props => (
                      <>
                        <span>
                          <i>Which location?</i>
                        </span>
                        <CreatableSelect
                          ref={selectRef}
                          options={locationOptions}
                          onChange={selectedValue => {
                            props.input.onChange(selectedValue);
                          }}
                          value={props?.input?.value}
                          onCreateOption={newLocationName => {
                            setCreatedLocationName(newLocationName);
                            setShowCreateLocationModal(true);
                          }}
                          placeholder="Start typing..."
                          createOptionPosition="first"
                          noOptionsMessage={() => 'Start typing to create a new option'}
                          isClearable
                        />
                      </>
                    )}
                  </Field>
                </>
              ) : (
                // Select a column from the dataset
                <Field
                  name={columnName}
                  allowNull
                  initialValue={initialFormState.fromColumn}
                  validate={(value, allValues) => {
                    const val = allValues[name]?.fromColumn;
                    return val == null ? FORM_ERROR : undefined;
                  }}
                  isEqual={(a, b) => a?.label === b?.label}
                >
                  {props => (
                    <>
                      <span>
                        <i>Column containing {DEFAULT_TITLE}:</i>
                      </span>
                      <Select
                        options={columnNameOptions}
                        onChange={selectedValue => {
                          props.input.onChange(selectedValue);
                        }}
                        placeholder="Start typing..."
                        value={props?.input?.value}
                      />
                    </>
                  )}
                </Field>
              )}
            </div>
          </div>
        </div>
      </div>
    </ConfigAccordionItem>
  );
};

export default LocationAccordion;
