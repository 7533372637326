import React from 'react';
import Select, { components } from 'react-select';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIndicator from '../../../accountSettings/common/LoadingIndicator';
import PriceTagIcon from '../../../../images/price-tag-icon.svg';

const ProfitRoverIcon = '/images/ProfitRoverLogo.png';

const selectStyles = {
  container: provided => ({
    ...provided,
    borderColor: '#cccccc',
    borderRadius: '4px',
    boxShadow: '0px 1px 4px #0000002e',
    fontSize: '14px',
  }),
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: state.selectProps.height,
    height: state.selectProps.height,
    boxShadow: 'none',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    height: state.selectProps.height,
    padding: '0px',
  }),
  dropDownIndicator: (provided, state) => ({
    ...provided,
    height: state.selectProps.height,
    padding: '0px',
  }),
  indicatorSeparator: () => ({}),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: provided => ({ ...provided, paddingRight: '2px' }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 2px',
    fontSize: '14px',
    marginRight: '1px',
    marginLeft: state.data.icon ? 'unset' : '20px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.selectProps.isLoading ? 0 : 1,
    display: 'flex',
    alignItems: 'center',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: state.selectProps.height,
    padding: state.selectProps.value.icon ? '2px 6px 2px 2px' : '2px 6px 2px 18px',
    justifyContent: 'center',
  }),
};

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon icon={faCaretDown} style={{ color: props.isDisabled ? '#999999' : '#000000' }} />
  </components.DropdownIndicator>
);

const Option = props => (
  <components.Option {...props}>
    {props.data.icon && (
      <img
        style={{ width: '15px', marginBottom: '4px', marginRight: '3px', marginLeft: '2px' }}
        src={props.data.icon === 'kaizen' ? ProfitRoverIcon : PriceTagIcon}
        alt="price type icon"
      />
    )}
    {props.data.label}
  </components.Option>
);

const SingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.icon && (
      <img
        style={{ width: '15px', marginRight: '2px' }}
        src={props.data.icon === 'kaizen' ? ProfitRoverIcon : PriceTagIcon}
        alt="price type icon"
      />
    )}
    {props.data.label}
  </components.SingleValue>
);

const customComponents = { DropdownIndicator, Option, LoadingIndicator, SingleValue };

const ApprovedPriceSelect = React.forwardRef((props, ref) => (
  <Select
    ref={ref}
    {...props}
    components={customComponents}
    isSearchable={false}
    loadingMessage={() => null}
    styles={selectStyles}
    height="30px"
  />
));
ApprovedPriceSelect.displayName = 'ApprovedPriceSelect';

export default ApprovedPriceSelect;
