import axios from 'axios';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { HOURS_OF_OPERATION_QUERY_KEY_BASE } from '../query/hoursOfOperation';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const updateHoursOfOperation = async hoursOfOperation =>
  axios.put(`${KA_API_URL}/config/hours_of_operation`, hoursOfOperation);

/**
 * Hooks
 */

export const useUpdateHoursOfOperation = mutationConfig => {
  const queryClient = useQueryClient();

  return useMutation(
    async hoursOfOperation => {
      const response = await updateHoursOfOperation(hoursOfOperation);

      return response?.data ?? {};
    },
    {
      ...mutationConfig,
      onSuccess: async () => {
        await queryClient.invalidateQueries(HOURS_OF_OPERATION_QUERY_KEY_BASE);

        if (_.isFunction(mutationConfig?.onSuccess)) {
          await mutationConfig.onSuccess();
        }
      },
    },
  );
};
