import React from 'react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import ProfitRoverAppsTitle from './ProfitRoverAppsTitle';
import { useRegistrationContext } from './registrationContext';
import { ProfitRoverCard } from '../generic/ProfitRoverCard';
import ProfitRoverSpinner from '../spinner/ProfitRoverSpinner';
import { LIGHT_GREEN } from '../../colors';
import { signIn } from '../../redux/auth/actions';
import './automatic-login.scss';

const THREE_S = 3000;
const SIX_S = 6000;
const TWELVE_S = 12000;

const DELAYS = [THREE_S, THREE_S, THREE_S, SIX_S, SIX_S, SIX_S, SIX_S, TWELVE_S, TWELVE_S, TWELVE_S];

const delay = async delayLength => new Promise(resolve => setTimeout(() => resolve(), delayLength));

const useAutoLogin = (email, password) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const [isAttemptingLogin, setIsAttemptingLogin] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();

  const tryToSignin = React.useCallback(() => dispatch(signIn(email, password)), [dispatch, email, password]);

  const attemptAutoLoginWithRetry = React.useCallback(
    async attemptSignIn => {
      const delays = [...DELAYS];
      let loggedIn = false;

      let delayLength = delays.shift(); // How long to wait before attemping to login

      /* eslint-disable no-await-in-loop */
      while (!loggedIn && delays.length > 0) {
        await delay(delayLength);

        if (!isAttemptingLogin) {
          setIsAttemptingLogin(true); // Trigger UI update
        }

        try {
          await attemptSignIn();
          loggedIn = true;
        } catch (error) {
          delayLength = delays.shift();
        }
      }

      const noMoreTriesRemaining = delays.length === 0;
      if (!loggedIn && noMoreTriesRemaining) {
        throw new Error(`Unable to login after ${DELAYS.length} attempts.`);
      }
    },
    [isAttemptingLogin],
  );

  // Initiate attempt
  React.useEffect(() => {
    const attemptLogin = async () => {
      if (!isAttemptingLogin) {
        try {
          await attemptAutoLoginWithRetry(tryToSignin);

          /**
           * Navigate to the login redirect page once login succeeds, preserving
           * any query params that may have been added to the URL by coming in from
           * an integration
           */
          history.push({ pathname: '/login-redirect', search });
        } catch (error) {
          setErrorMessage('Something went wrong on our end. Please try to login again later.');
        }
      }
    };

    attemptLogin();
  }, [history, search, isAttemptingLogin, tryToSignin, attemptAutoLoginWithRetry]);

  return { errorMessage, isAttemptingLogin };
};

const CreatingAccountMessage = () => (
  <>
    <div className="success-top-text">Almost There!</div>
    <div style={{ padding: 50, flexGrow: 1 }}>
      <ProfitRoverSpinner color={LIGHT_GREEN} style={{ width: '3.14rem', height: '3.14rem' }} />
    </div>
    <div className="success-bottom-text">
      <strong>Creating Your Account</strong>
    </div>
  </>
);

const SigningInMessage = () => (
  <>
    <div className="success-top-text">Account Created</div>
    <div className="checkmark-container">
      <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#5fad56', fontSize: '5rem' }} />
    </div>
    <ProfitRoverSpinner color={LIGHT_GREEN} style={{ width: '1.5rem', height: '1.5rem' }} />
    <div className="success-bottom-text" style={{ fontWeight: 500 }}>
      Signing you in...
    </div>
  </>
);

const AutomaticLogin = () => {
  const { emailAddress, password } = useRegistrationContext();
  const { errorMessage, isAttemptingLogin } = useAutoLogin(emailAddress, password);

  const display = !isAttemptingLogin ? (
    // Only shown before the first attempt to login
    <CreatingAccountMessage />
  ) : (
    // Shown while attempting to login
    <SigningInMessage />
  );

  return (
    <div className="container-fluid register-container d-flex flex-column justify-content-center align-items-center">
      <div className="my-5 text-center">
        <ProfitRoverAppsTitle />
        <div>
          <span className="profitrover-text-subtitle">
            You&apos;re just a few steps away from profit-enhancing insights.
          </span>
        </div>
      </div>
      <ProfitRoverCard className="success-message-container">
        {/* Error message will be shown if we fail to authenticate after a sufficient number of tries */}
        {errorMessage != null ? errorMessage : display}
      </ProfitRoverCard>
    </div>
  );
};

export default AutomaticLogin;
