import React from 'react';
import ApprovedPriceSelect from './ApprovedPriceSelect';
import { formatCurrency } from '../../../util/format';
import { useProductPricePoints } from '../../../../data-access/query/outputs/pricestats';
import { gaEmitApprovedPriceDropdownClick } from '../../../../google-analytics/revenueUpliftDashboardV2';

const formatValue = (val, currencySymbol) => formatCurrency(val, true, currencySymbol);

const ApprovedPriceEditor = React.forwardRef((props, ref) => {
  const { context, currencySymbol } = props;
  const { productId, tranDate } = props.data;

  const { workflowId, approvedPricesApi } = context;

  const approvedPrice = approvedPricesApi.selectors.getApprovedPrice(props.data);
  const priceIcon =
    approvedPrice === props.data.newPrice ? 'kaizen' : approvedPrice === props.data.baselinePrice ? 'current' : null;
  const [selectedValue, setSelectedValue] = React.useState({
    value: approvedPrice,
    label: formatValue(approvedPrice, currencySymbol),
    icon: priceIcon,
  });

  const state = useProductPricePoints(workflowId, productId, { enabled: workflowId != null && productId != null });

  const { data, isFetching } = state;
  const { pricePointsByDate = {}, rows } = data ?? {};

  const pricesPointsForDate = pricePointsByDate[tranDate] ?? [];

  const options = pricesPointsForDate.map(({ price }) => {
    const label = formatValue(price, currencySymbol);
    const optionPriceIcon =
      price === props.data.newPrice ? 'kaizen' : price === props.data.baselinePrice ? 'current' : null;
    return { value: price, label, icon: optionPriceIcon };
  });

  const selectRef = React.useRef(null);

  React.useImperativeHandle(ref, () => ({
    getValue: () => null,
    afterGuiAttached: () => {
      gaEmitApprovedPriceDropdownClick();
      if (selectRef.current) {
        // Re-open the menu when editing a cell more than once
        selectRef.current.focus();
      }
    },
  }));

  return (
    <div ref={ref} className="price-rec-approved-price-container">
      <ApprovedPriceSelect
        ref={selectRef}
        classNamePrefix="select"
        noOptionsMessage={() => (isFetching ? null : 'No price options available')}
        isLoading={isFetching}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        openMenuOnFocus
        options={options}
        onChange={e => {
          const selectedPrice = e.value;

          const pricePointsAtSelectedPrice = rows.filter(row => row.price === selectedPrice);
          approvedPricesApi.actions.setApprovedPricePoint(selectedPrice, pricePointsAtSelectedPrice, props.data);

          setSelectedValue(selectedPrice);
          props.api.stopEditing();
        }}
        value={selectedValue}
      />
    </div>
  );
});

ApprovedPriceEditor.displayName = 'ApprovedPriceEditor';

export default ApprovedPriceEditor;
