import { ChargeStatus, InvoiceStatus } from './SubscriptionConstants';

const HIDDEN_INVOICE_STATUSES = new Set([InvoiceStatus.VOID, InvoiceStatus.UNCOLLECTIBLE, InvoiceStatus.DRAFT]);
const UNPAID_INVOICE_STATUSES = new Set([InvoiceStatus.OPEN, InvoiceStatus.UNCOLLECTIBLE, InvoiceStatus.DRAFT]);

export const isPaid = invoice => invoice.paid;

/**
 * An invoice can be "open" without having had any attempted charges yet (typically this is for one hour after
 * the invoice is finalized). Only open invoices that have had an attempted charge that failed should be considered
 * "unpaid" in the sense that the user needs to be involved in rectifying the payment issue.
 */
export const hasFailedPayment = invoice =>
  invoice.invoice_status === InvoiceStatus.OPEN && invoice.charge_status === ChargeStatus.FAILED;

export const hasUnpaid = invoice => UNPAID_INVOICE_STATUSES.has(invoice.invoice_status);

/**
 * Whether this invoice should be "visible" to the user. We only want the user to see "open" and "paid" invoices
 * that were for a total cost greater than 0.
 */
export const shouldBeHidden = invoice => {
  const { invoice_status: invoiceStatus, total } = invoice;

  return HIDDEN_INVOICE_STATUSES.has(invoiceStatus) || total === 0;
};

/**
 * If a customer attempts to activate a new location but the payment failed,
 * a 'location_activation' reason is provided in the invoice metadata
 */
export const hasFailedForLocationActivation = invoice =>
  hasFailedPayment(invoice) && invoice.metadata?.update_reason === 'location_activation';
