import axios from 'axios';
import { useMutation } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';
import { mutateNaicsKaCode } from './common';

/**
 * Requests
 */

export const createDatasetConfig = async datasetConfig => {
  mutateNaicsKaCode(datasetConfig);

  return axios.post(`${KA_API_URL}/datasets/config`, datasetConfig);
};

export const updateDatasetConfig = async (datasetConfigId, datasetConfig) =>
  axios.put(`${KA_API_URL}/datasets/config/${datasetConfigId}`, datasetConfig);

/**
 * Allows you to create arbitrary fields associated with a Dataset Config
 */
export const addFieldsToDatasetConfig = (datasetConfigId, fields) =>
  axios.post(`${KA_API_URL}/datasets/config/${datasetConfigId}/fields`, {
    dataset_fields: fields,
  });

export const updateConfigFields = (datasetConfigId, fields) =>
  axios.put(`${KA_API_URL}/datasets/config/${datasetConfigId}/fields`, fields);

const deleteConfigFields = (datasetConfigId, fieldIds) =>
  axios.delete(`${KA_API_URL}/datasets/config/${datasetConfigId}/fields`, { data: fieldIds });

/**
 * @param {Number} datasetConfigId
 * @param {Number[]} [datasetIds] - Optional subset of datasets to synchronize
 */
const requestDataSync = async (datasetConfigId, datasetIds) => {
  /**
   * OASTools seems to (incorrectly) consider an empty object to not be acceptable,
   * so we put some garbage data in the PUT body to get past schema validation during
   * a "data sync"
   */
  const datasetConfig = { is: 'syncing' };

  return axios.put(`${KA_API_URL}/datasets/config/${datasetConfigId}`, datasetConfig, {
    params: { operation: 'data_sync', dataset_ids: datasetIds },
  });
};

/**
 * Hooks
 */

export const useCreateDatasetConfig = mutationConfig =>
  useMutation(async datasetConfig => {
    const response = await createDatasetConfig(datasetConfig);

    return response?.data;
  }, mutationConfig);

export const useRequestDataSync = mutationConfig =>
  useMutation(async ({ datasetConfigId, datasetIds }) => {
    const response = await requestDataSync(datasetConfigId, datasetIds);

    return response?.data;
  }, mutationConfig);

export const useUpdateDatasetConfig = mutationConfig =>
  useMutation(async ({ datasetConfigId, datasetConfig }) => {
    const response = await updateDatasetConfig(datasetConfigId, datasetConfig);

    return response?.data;
  }, mutationConfig);

export const useAddFieldsToDatasetConfig = mutationConfig =>
  useMutation(async ({ datasetConfigId, fields }) => {
    const response = await addFieldsToDatasetConfig(datasetConfigId, fields);

    return response?.data;
  }, mutationConfig);

export const useUpdateConfigFields = mutationConfig =>
  useMutation(async ({ datasetConfigId, fields }) => {
    const response = await updateConfigFields(datasetConfigId, fields);

    return response?.data;
  }, mutationConfig);

export const useDeleteConfigFields = mutationConfig =>
  useMutation(async ({ datasetConfigId, fieldIds }) => {
    const response = await deleteConfigFields(datasetConfigId, fieldIds);

    return response?.data;
  }, mutationConfig);
