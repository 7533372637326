import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Update Workflow Dimensions Page';

const ACTIONS = {
  SAVE_AND_REFRESH_RESULTS_BUTTON_CLICK: 'Save and refresh results button click',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

// eslint-disable-next-line import/prefer-default-export
export const gaEmitSaveAndRefreshResultsButtonClick = () => {
  emitEvent({ action: ACTIONS.SAVE_AND_REFRESH_RESULTS_BUTTON_CLICK });
};
