import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import { ProfitRoverModalCard, ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';

export const PollingTimeoutModal = ({ show }) => {
  const history = useHistory();

  const onHide = () => history.push('/subscription-management');

  return (
    <Modal className="subscription-changes-modal" show={show} centered backdrop="static" onHide={onHide}>
      <ProfitRoverModalCard>
        <ProfitRoverModalMain style={{ textAlign: 'left' }}>
          <h4 className="modal-title">Unable to Verify Subscription Changes</h4>
          At this time, we cannot verify your subscription changes are complete. You should receive a confirmation email
          once your changes have been finalized.
        </ProfitRoverModalMain>
        <ProfitRoverModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
          <ProfitRoverPrimaryButton onClick={onHide}>OK</ProfitRoverPrimaryButton>
        </ProfitRoverModalFooter>
      </ProfitRoverModalCard>
    </Modal>
  );
};

export const UnableToMakeChangesModal = ({ show }) => {
  const history = useHistory();

  const onHide = () => history.push('/subscription-management');

  return (
    <Modal className="subscription-changes-modal" show={show} centered backdrop="static" onHide={onHide}>
      <ProfitRoverModalCard>
        <ProfitRoverModalMain style={{ textAlign: 'left' }}>
          <h4 className="modal-title">Unable to Make Subscription Changes</h4>
          We are unable to make changes to your subscription at this time. Please try again later.
        </ProfitRoverModalMain>
        <ProfitRoverModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
          <ProfitRoverPrimaryButton onClick={onHide}>OK</ProfitRoverPrimaryButton>
        </ProfitRoverModalFooter>
      </ProfitRoverModalCard>
    </Modal>
  );
};

export const SubmissionErrorModal = ({ show }) => {
  const history = useHistory();

  const onHide = () => history.push('/subscription-management');

  return (
    <Modal className="subscription-changes-modal" show={show} centered backdrop="static" onHide={onHide}>
      <ProfitRoverModalCard>
        <ProfitRoverModalMain style={{ textAlign: 'left' }}>
          <h4 className="modal-title">Unable to Update Subscription</h4>
          An error occurred while processing this request. Please reach out to us for assistance.
        </ProfitRoverModalMain>
        <ProfitRoverModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
          <ProfitRoverPrimaryButton onClick={onHide}>OK</ProfitRoverPrimaryButton>
        </ProfitRoverModalFooter>
      </ProfitRoverModalCard>
    </Modal>
  );
};
