import React from 'react';
import { faExclamationTriangle, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataSourceDisplayName } from './dataSourceConstants';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverCard } from '../generic/ProfitRoverCard';
import ProfitRoverTooltip from '../generic/ProfitRoverTooltip';
import { convertUTCDateStringToLocalDateString } from '../util/format';
import { useRequestDataSync } from '../../data-access/mutation/datasetConfigs';
import { useWorkflowIndustryDataTypeBatchesWithStatus } from '../../data-access/query/workflows';
import { timestampTextRelativeToNow } from '../../utils/date-handling';
import { gaEmitSyncNowButtonClick } from '../../google-analytics/dataCenter';

const computeTimestampLabels = timestamp => ({
  howLongAgo: timestampTextRelativeToNow(timestamp),
  exactDateTime: convertUTCDateStringToLocalDateString(timestamp),
});

export const getManualTimestampLabel = timestamps => {
  let timestampLabel;

  if (timestamps.length > 0) {
    const [mostRecent] = timestamps;
    const { howLongAgo } = computeTimestampLabels(mostRecent);
    timestampLabel = `Last uploaded ${howLongAgo}`;
  } else {
    timestampLabel = 'No data uploaded';
  }

  return timestampLabel;
};

export const getIntegrationTimestampLabel = (timestamp, setupIncomplete, credentialRevocationTime) => {
  if (credentialRevocationTime != null) {
    const { howLongAgo } = computeTimestampLabels(credentialRevocationTime);

    return `Disconnected: ${howLongAgo}`;
  }
  if (setupIncomplete) {
    return 'Connection Unestablished';
  }
  if (timestamp != null && credentialRevocationTime == null) {
    const { howLongAgo } = computeTimestampLabels(timestamp);

    return `Connected: Last synced ${howLongAgo}`;
  }
  if (credentialRevocationTime == null) {
    return 'Connected: No data synced';
  }

  return 'Unknown Connection Status';
};

export const DataSourceBase = ({ label, name, timestamp, actions, settingsCog }) => (
  <ProfitRoverCard className="data-source-card">
    <div className="flex-container">
      <div className="label">{label ?? ''}</div>
      <div className="name">{name ?? ''}</div>
      <div className="timestamp">
        <i>{timestamp}</i>
      </div>
      <div className="actions">{actions}</div>
      <div className="settings-cog">{settingsCog}</div>
    </div>
  </ProfitRoverCard>
);

export const FinishSetupButton = props => (
  <div className="d-flex align-items-center">
    <ProfitRoverTooltip
      tooltipText="The prior setup never finished or there is new functionality since you last disconnected."
      placement="auto"
    >
      <FontAwesomeIcon icon={faExclamationTriangle} color="#f6be00" style={{ marginRight: 10, fontSize: 20 }} />
    </ProfitRoverTooltip>
    <ProfitRoverTooltip
      tooltipText="Click this button to complete the connection and start syncing to ProfitRover."
      placement="auto"
    >
      <ProfitRoverPrimaryButton {...props} small>
        Finish Setup
      </ProfitRoverPrimaryButton>
    </ProfitRoverTooltip>
  </div>
);

export const ReconnectButton = props => (
  <ProfitRoverTooltip
    tooltipText="Click here to reconnect your existing account and start syncing to ProfitRover"
    placement="auto"
  >
    <ProfitRoverSecondaryButton {...props} small>
      Reconnect
    </ProfitRoverSecondaryButton>
  </ProfitRoverTooltip>
);

export const ResyncDataSourceButton = ({ datasetConfig, disabled, setupIncomplete, isWorkflow }) => {
  const { dataset_config_id: datasetConfigId, industry, data_type: dataType, partner } = datasetConfig;
  const {
    primaryForecastComplete,
    batchComplete,
    isRefetching: isRefetchingBatches,
    isLoading: isLoadingBatches,
    refetch: refetchBatches,
  } = useWorkflowIndustryDataTypeBatchesWithStatus(industry, dataType);

  const [isRequestingSync, setRequestingSync] = React.useState(false);
  const requestDataSyncMutation = useRequestDataSync({ onSuccess: refetchBatches });
  const requestDataSync = async () => {
    gaEmitSyncNowButtonClick();
    setRequestingSync(true);
    await requestDataSyncMutation.mutateAsync({ datasetConfigId });
  };

  const requestsAreUnsettled = isRequestingSync || isLoadingBatches || isRefetchingBatches;
  const waitForForecast = isWorkflow && !primaryForecastComplete;
  const waitForBatch = !isWorkflow && !batchComplete;
  const disableSyncButton = disabled || requestsAreUnsettled || waitForForecast || waitForBatch;

  let tooltipText = `Click to instruct ProfitRover to sync with your ${DataSourceDisplayName[partner]} account`;
  if (disableSyncButton) {
    if (setupIncomplete) {
      tooltipText = `Sync not available until a connection with ${DataSourceDisplayName[partner]} is established`;
    } else {
      tooltipText = 'Sync not available, ProfitRover is currently working on updating your dashboard results';
    }
  }

  return (
    <ProfitRoverTooltip delay={{ show: 200, hide: 100 }} tooltipText={tooltipText}>
      <span className="d-inline-block">
        <ProfitRoverSecondaryButton
          style={{ pointerEvents: disableSyncButton ? 'none' : 'auto' }}
          disabled={disableSyncButton}
          onClick={requestDataSync}
          small
        >
          <FontAwesomeIcon className="mr-2" icon={faSyncAlt} rotation={90} size="xs" />
          Sync Now
        </ProfitRoverSecondaryButton>
      </span>
    </ProfitRoverTooltip>
  );
};
