import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faHistory, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import moment from 'moment-timezone';
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useAnomalyDetectionContext } from './AnomalyDetectionContext';
import { DATELIKE_FIELD_TYPES, NUMERIC_FIELD_TYPES, useAnomalyHistoryDetail } from './anomalyDetectionHooks';
import LoadingIndicator from '../../accountSettings/common/LoadingIndicator';
import ProfitRoverTooltip from '../../generic/ProfitRoverTooltip';
import { formatCurrency } from '../../util/format';
import { useGridApi } from '../../util/gridCommonFunctions';
import { FONT_BLACK, ERROR } from '../../../colors';
import { ReactComponent as ReviewedIcon } from '../../../images/reviewed-icon.svg';

const DEFAULT_COLUMN_DEFINTION = {
  sortable: true,
  resizable: true,
};

/**
 * Formatting guidelines:
 * - Numbers should be right-justified (REV, MEASURE), 2 decimals of precision
 *   - Include a currency format: $...
 *   - TODO: Only format as a currency if the underlying number is actually a currency
 * - Strings should be left-justified (DIM, INFO, TRAN)
 */
const getColumnProps = columnDefinition => {
  const isNumeric = NUMERIC_FIELD_TYPES.includes(columnDefinition.field);
  const isDate = DATELIKE_FIELD_TYPES.includes(columnDefinition.field);

  const alignment = isNumeric ? 'rightAligned' : undefined;

  let valueFormatter;
  if (isNumeric) {
    valueFormatter = params => formatCurrency(params.value, true);
  } else if (isDate) {
    valueFormatter = params => moment(params.value, true).format('M/D/YY');
  }

  return {
    type: alignment,
    valueFormatter,
  };
};

const ActionsPalette = props => {
  const { data, context } = props;
  const { primary_key: primaryKey, reviewed } = data;
  const { onToggleExcluded, onToggleReviewed, isStagedForExclusion, isExclusionInProgress } = context;

  const excluded = isStagedForExclusion(primaryKey);
  const exclusionInProgress = isExclusionInProgress(primaryKey);

  const iconsDisabled = exclusionInProgress;

  const noopIfDisabled = func => (!iconsDisabled ? func : undefined);

  return (
    <>
      <input type="checkbox" />

      <ProfitRoverTooltip
        shouldDisplayTooltip={iconsDisabled}
        tooltipId={primaryKey}
        tooltipClass="updates-in-progress-tooltip"
        tooltipText="This record is undergoing updates. You cannot make changes until your results are refreshed."
        placement="right"
      >
        <div className={classnames('icons', { disabled: iconsDisabled })}>
          <button
            type="button"
            /**
             * Use of "disabled" prop interferes with Bootstrap Tooltip (<ProfitRoverTooltip>),
             * so we must unwire the onClick as an alternative approach
             */
            onClick={noopIfDisabled(() => {
              onToggleExcluded(primaryKey);
            })}
          >
            <FontAwesomeIcon
              icon={faMinusCircle}
              className={classnames('excluded-icon', { excluded })}
              color={excluded || exclusionInProgress ? ERROR : FONT_BLACK}
            />
          </button>
          <button
            type="button"
            onClick={noopIfDisabled(() => {
              onToggleReviewed(primaryKey);
            })}
          >
            {reviewed ? (
              <div className="d-flex align-items-center justify-content-center">
                <ReviewedIcon />
              </div>
            ) : (
              <FontAwesomeIcon className="reviewed-icon" icon={faClipboard} />
            )}
          </button>
        </div>
      </ProfitRoverTooltip>

      <div className="flex-grow-1" />

      <div className="d-flex align-items-center justify-content-center" style={{ width: 30 }}>
        {excluded && <FontAwesomeIcon icon={faHistory} flip="horizontal" className="mr-1" />}
        {exclusionInProgress && <LoadingIndicator />}
      </div>
    </>
  );
};

const getRowNodeId = data => data.primary_key;
const rowClassRules = {
  reviewed: ({ data }) => data.reviewed,
};
const frameworkComponents = {
  LoadingOverlay: LoadingIndicator,
  ActionsPalette,
};
const onFirstDataRendered = ({ columnApi }) => {
  const allColumnIds = [];
  columnApi.getAllColumns().forEach(column => {
    allColumnIds.push(column.getId());
  });
  columnApi.autoSizeColumns(allColumnIds, false);
};

const RecordsView = ({ renderTable, productDimIds, productDimValues, date, timeGranularity }) => {
  const { workflowId, anomalyHistoryLegend, rowStatusState, locationGroupId } = useAnomalyDetectionContext();

  const { primaryColumns, otherColumns } = anomalyHistoryLegend;
  const {
    statusesByPrimaryKey,
    isReviewed,
    toggleRowReviewed,
    toggleRowExcluded,
    isExcluded,
    isStagedForExclusion,
    isExclusionInProgress,
  } = rowStatusState;

  // Only fire the query after the first time the accordion is expanded
  const [queryEnabled, setQueryEnabled] = React.useState(false);
  React.useEffect(() => {
    if (renderTable && !queryEnabled) {
      setQueryEnabled(true);
    }
  }, [renderTable, queryEnabled]);

  const historyDetail = useAnomalyHistoryDetail(
    { workflowId, productDimIds, productDimValues, date, timeGranularity, locationGroupId },
    anomalyHistoryLegend,
    {
      enabled: queryEnabled && workflowId != null,
      retry: 1,
    },
  );
  const { data: rowData } = historyDetail;

  const joinedRowData = React.useMemo(
    () =>
      rowData
        ?.filter(row => !isExcluded(row.primary_key))
        .map(row => ({
          ...row,
          reviewed: isReviewed(row.primary_key),
          excludeState: statusesByPrimaryKey.get(row.primary_key)?.exclude_state,
        })),
    [isExcluded, isReviewed, rowData, statusesByPrimaryKey],
  );

  const { onGridReady } = useGridApi();

  const ACTIONS_COLUMN_WIDTH = 150;

  const columns = React.useMemo(() => {
    return [
      {
        pinned: 'left',
        headerName: '',
        width: ACTIONS_COLUMN_WIDTH,
        minWidth: ACTIONS_COLUMN_WIDTH,
        maxWidth: ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        cellClass: 'actions-palette',
        cellRenderer: 'ActionsPalette',
      },
      ...primaryColumns.map(col => ({
        pinned: 'left',
        field: col.columnName,
        ...getColumnProps(col),
      })),
      ...otherColumns.map(col => ({
        field: col.columnName,
        ...getColumnProps(col),
      })),
    ];
  }, [otherColumns, primaryColumns]);

  return (
    <div className="ag-theme-alpine anomaly-detail-table">
      {renderTable && (
        <AgGridReact
          rowData={joinedRowData}
          columnDefs={columns}
          defaultColDef={DEFAULT_COLUMN_DEFINTION}
          animateRows
          immutableData
          getRowNodeId={getRowNodeId}
          onGridReady={onGridReady}
          frameworkComponents={frameworkComponents}
          loadingOverlayComponent="LoadingOverlay"
          onFirstDataRendered={onFirstDataRendered}
          rowClassRules={rowClassRules}
          context={{
            onToggleReviewed: toggleRowReviewed,
            onToggleExcluded: toggleRowExcluded,
            isStagedForExclusion,
            isExclusionInProgress,
          }}
        />
      )}
    </div>
  );
};

export default RecordsView;
