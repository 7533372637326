import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { ProfitRoverLinearLogo } from '../../generic/ProfitRoverLogos';
import { useProductPrices } from '../../registration/customerRegistrationHooks';

const TrialToPaidOrderDetails = props => {
  const { prices, selectedPlan, setSelectedPlan, coupon } = props;

  const {
    annualPriceDollarAmt,
    pricePerMonthDollarAmt,
    savingsPerYear,
    savingsPerYearWithDiscount,
    savingsPerYearPct,
    savingsPerYearWithDiscountPct,
    annualPriceWithDiscount,
    pricePerMonthWithDiscount,
  } = useProductPrices(prices, coupon);

  const handleSelectPlan = event => {
    const userSelectedPlan = prices[event.target.value];
    setSelectedPlan(userSelectedPlan);
  };

  const selectedAnnualPlan = selectedPlan?.recurring.interval === 'year';

  return (
    <div className="order-details-container">
      <div className="order-details-content-container">
        <div className="product-description-app-name">
          <ProfitRoverLinearLogo className="profitrover-logo" />
        </div>
        <div className="billing-frequency-container">
          <label className="plan-container" htmlFor="monthly-plan-button">
            <div className="row monthly-plan-text">
              <input
                type="radio"
                name="radio"
                id="monthly-plan-button"
                value="monthly"
                onChange={handleSelectPlan}
                checked={selectedPlan?.recurring.interval === 'month'}
              />
              <div className="col text-nowrap" style={{ padding: '0px' }}>
                <span style={{ paddingLeft: '4px', paddingRight: '5px' }}>Monthly Plan:</span>
              </div>
              <div className="col text-right text-nowrap">
                {coupon ? (
                  <>
                    <span className="discount-price">${pricePerMonthDollarAmt}</span>
                    <span>${pricePerMonthWithDiscount}</span>
                  </>
                ) : (
                  <span>${pricePerMonthDollarAmt}</span>
                )}
                <span> / Month Per Location</span>
                <span className="tax-text"> + tax</span>
              </div>
            </div>
          </label>
          <label className="plan-container" htmlFor="annual-plan-button">
            <div className="row">
              <input
                type="radio"
                name="radio"
                id="annual-plan-button"
                value="annual"
                onChange={handleSelectPlan}
                checked={selectedPlan?.recurring.interval === 'year'}
              />
              <div className="col text-nowrap" style={{ padding: '0px' }}>
                <span style={{ paddingLeft: '4px', paddingRight: '5px' }}>Annual Plan:</span>
              </div>
              <div className="col text-right">
                <div className="col text-right text-nowrap" style={{ padding: '0px' }}>
                  {coupon ? (
                    <>
                      <span className="discount-price">${annualPriceDollarAmt}</span>
                      <span>${annualPriceWithDiscount}</span>
                    </>
                  ) : (
                    <span>${annualPriceDollarAmt}</span>
                  )}
                  <span> / Year Per Location</span>
                  <span className="tax-text"> + tax</span>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div className="order-details-savings-container pb-2">
        <div className="text-center savings-per-year-text-container">
          <FontAwesomeIcon icon={faStar} />
          <p className="savings-per-year-text">
            {selectedAnnualPlan
              ? `You will be saving ${coupon ? savingsPerYearWithDiscountPct : savingsPerYearPct}%!`
              : `Save ${coupon ? savingsPerYearWithDiscountPct : savingsPerYearPct}% by switching to annual billing!`}
          </p>
          <FontAwesomeIcon icon={faStar} />
        </div>
        <div className="savings-per-location-text-container">
          <p className="savings-per-location-text">
            That&apos;s ${coupon ? savingsPerYearWithDiscount : savingsPerYear} per location!
          </p>
        </div>
      </div>
    </div>
  );
};

export default TrialToPaidOrderDetails;
