/**
 * These constants exist to give a "dimensionId" to "pseudo-dimensions" that appear
 * in the dashboard: i.e. those that aren't actually real "product dimensions". This
 * allows us to uniformly key dimension values "by dimension id" in various state
 * objects.
 */

export const LOCATIONS_DIMENSION_ID = 'Locations';
export const DAY_OF_WEEK_DIMENSION_ID = 'Day of Week';
export const WEEKPART_DIMENSION_ID = 'Week Part';
export const SEASON_DIMENSION_ID = 'Season';
export const WEEKS_SINCE_RELEASE_DIMENSION_ID = 'Weeks Since Release';

export const TIME_DIMENSION_IDS = [
  DAY_OF_WEEK_DIMENSION_ID,
  WEEKPART_DIMENSION_ID,
  SEASON_DIMENSION_ID,
  WEEKS_SINCE_RELEASE_DIMENSION_ID,
];

export const PRICE_TABLE_DATE_FORMAT = 'M/D/YY';
export const PRICE_REC_TABLE_MONTH_FORMAT = 'MMMM';
export const FULL_DATE_FORMAT = 'yyyy-MM-dd';

export const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const WEEKPART_FEATURE_COLUMN_NAME = 'weekpart';
export const SEASON_FEATURE_COLUMN_NAME = 'season';
export const WEEKS_SINCE_RELEASE_FEATURE_COLUMN_NAME = 'weeks_since_release';
