import React from 'react';
import { faHistory, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBeginProcessingExclusionsMutation } from './anomalyDetectionHooks';
import LoadingIndicator from '../../accountSettings/common/LoadingIndicator';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';
import ProfitRoverTooltip from '../../generic/ProfitRoverTooltip';

const REFRESH_RESULTS_TOOLTIP_TEXT = `
When you refresh, your anomalies could be different. Segments that you have been working on could move around
or disappear entirely. You can always bring back excluded records via the "Data View".
`;

export const RefreshResultsButton = ({ onReloadDashboard }) => (
  <div className="d-flex align-items-center pr-3 mr-3" style={{ borderRight: '1px solid gray' }}>
    <i className="info mr-3 font-small" style={{ fontSize: 14 }}>
      Anomalies Reprocessed
    </i>
    <ProfitRoverTooltip
      tooltipClass="refresh-results-tooltip"
      placement="bottom-end"
      tooltipText={REFRESH_RESULTS_TOOLTIP_TEXT}
    >
      <ProfitRoverSecondaryButton onClick={onReloadDashboard} small>
        Refresh
        <FontAwesomeIcon icon={faSync} className="ml-2" />
      </ProfitRoverSecondaryButton>
    </ProfitRoverTooltip>
  </div>
);

export const UpdatesInProgressIndicator = () => (
  <div className="updates-in-progress d-flex align-items-center justify-content-center mr-4">
    <span className="mr-3">Updates in Progress</span>
    <LoadingIndicator />
  </div>
);

export const ProcessChangesButton = ({ workflowId, numStagedForExclusion, transitionFromStagedToInProgress }) => {
  const beginProcessingExclusions = useBeginProcessingExclusionsMutation();

  return (
    <ProfitRoverPrimaryButton
      className="process-changes-button"
      onClick={async () => {
        await beginProcessingExclusions.mutateAsync({ workflowId });
        transitionFromStagedToInProgress();
      }}
      disabled={numStagedForExclusion === 0 || beginProcessingExclusions.isLoading}
      small
    >
      Process Pending Changes
      <FontAwesomeIcon icon={faHistory} flip="horizontal" className="ml-2" />
      {numStagedForExclusion > 0 && (
        <span className="pending-changes-counter">{numStagedForExclusion < 100 ? numStagedForExclusion : '99+'}</span>
      )}
    </ProfitRoverPrimaryButton>
  );
};
