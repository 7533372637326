import React from 'react';
import ColumnBasedFieldSelection from '../../ColumnBasedFieldSelection';
import {
  ActionButton,
  ConfigTypeSubHeader,
  ContentArea,
  PageHeader,
  PromptText,
  RadioButtonRow,
  SectionHeader,
  SmallRadioButton,
  TextInput,
} from '../../commonFormComponents';
import { DataSourceType, unknownDataSourceTypeError } from '../../DatasetConfigFormConstants';
import DatasetFieldBuilder from '../../DatasetFieldBuilder';
import { DATASET_FIELD_DISPLAY } from '../../../../generic/datasetFieldDisplay';

const DATASET_FIELD_NAME = 'QTY';
const FIELD_NAME = DATASET_FIELD_DISPLAY[DATASET_FIELD_NAME];

export default function GenericQuantityPage(props) {
  const { formPageApi, dsConfigMetaData, fieldName, label, nextPage } = props;

  const fieldNameToUse = fieldName ?? FIELD_NAME;

  const [fieldInData, setFieldInData] = React.useState();
  const [columnName, setColumnName] = React.useState();
  const [fixedValue, setFixedValue] = React.useState('');
  const [formula, setFormula] = React.useState('');
  const [dataSourceType, setDataSourceType] = React.useState();

  const fixedValueSatisfied = dataSourceType === DataSourceType.FIXED_VALUE && fixedValue != null && fixedValue !== '';
  const columnNameSatisfied = dataSourceType === DataSourceType.FROM_COLUMN && columnName != null;
  const formulaSatisfied = dataSourceType === DataSourceType.FORMULA && formula != null && formula !== '';
  const userHasSpecifiedValue = fixedValueSatisfied || columnNameSatisfied || formulaSatisfied;
  const canContinue = fieldInData != null && userHasSpecifiedValue;

  const onClickNext = async () => {
    const datasetFieldBuilder = new DatasetFieldBuilder().setDatasetFieldName(DATASET_FIELD_NAME);

    if (dataSourceType === DataSourceType.FIXED_VALUE) {
      datasetFieldBuilder.setFixedValue(fixedValue);
    } else if (dataSourceType === DataSourceType.FROM_COLUMN) {
      datasetFieldBuilder.setFromColumn(columnName);
    } else if (dataSourceType === DataSourceType.FORMULA) {
      datasetFieldBuilder.setFormula(formula);
    } else {
      throw unknownDataSourceTypeError(dataSourceType);
    }

    const datasetField = datasetFieldBuilder.build();

    await formPageApi.actions.addDatasetFields(datasetField);
    formPageApi.actions.navigateToPage(nextPage);
  };

  return (
    <div className="ds-config-container">
      <PageHeader>New Data Configuration</PageHeader>

      <ConfigTypeSubHeader industry={formPageApi.state.industry} dataType={formPageApi.state.dataType} />

      <hr />

      <SectionHeader label={label}>{fieldNameToUse}</SectionHeader>

      <ContentArea>
        <PromptText label="1.">Does this field exist in your data set?</PromptText>
        <RadioButtonRow>
          <SmallRadioButton
            selected={fieldInData === true}
            onClick={() => {
              setFieldInData(true);

              if (fieldInData === false) {
                // Don't set to undefined if the user clicks this button while it's already selected
                setDataSourceType(undefined);
              }
            }}
          >
            I think so!
          </SmallRadioButton>
          <SmallRadioButton
            selected={fieldInData === false}
            onClick={() => {
              setFieldInData(false);
              setDataSourceType(DataSourceType.FIXED_VALUE);
            }}
          >
            No
          </SmallRadioButton>
        </RadioButtonRow>

        {fieldInData != null && (
          <>
            {fieldInData ? (
              <>
                <PromptText label="2.">
                  Look through your data and select the column that matches up with <strong>{fieldNameToUse}</strong>.
                </PromptText>

                <ColumnBasedFieldSelection
                  dataSamples={dsConfigMetaData.dataSample}
                  columnName={columnName}
                  setColumnName={setColumnName}
                  formula={formula}
                  setFormula={setFormula}
                  dataSourceType={dataSourceType}
                  setDataSourceType={setDataSourceType}
                />
              </>
            ) : (
              <>
                <PromptText label="2.">What value do you want to give this?</PromptText>

                <TextInput value={fixedValue} onChange={e => setFixedValue(e.target.value)} />
              </>
            )}
          </>
        )}

        {canContinue && (
          <ActionButton onClick={onClickNext} style={{ marginTop: 30 }}>
            Continue
          </ActionButton>
        )}
      </ContentArea>
    </div>
  );
}
