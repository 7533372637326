import _ from 'lodash';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import CustomerSelect from './CustomerSelect';
import impersonateUser from './impersonation';
import UserSelect from './UserSelect';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import Header from '../header/header';
import { PageHeader, PromptText } from '../forms/GenericFormComponents';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import { Can } from '../../casl/abilityContext';
import { Action, Subject } from '../../casl/caslConstants';
import { useUsers } from '../../data-access/query/users';
import './impersonate-user-page.scss';

const mapUserToOption = user => ({ label: user.email_address, value: user.user_id });

const ImpersonateUserPage = () => {
  const history = useHistory();
  const [customer, setCustomer] = React.useState();
  const [user, setUser] = React.useState();
  const [message, setMessage] = React.useState('');

  const selectedCustomerId = customer?.value;
  const { data: allUsers = [], isLoading: isLoadingUsers } = useUsers(selectedCustomerId, {
    enabled: selectedCustomerId != null,
  });
  const userOptions = React.useMemo(() => _.sortBy(allUsers.map(mapUserToOption), option => option.label), [allUsers]);

  const onClickImpersonate = async () => {
    try {
      await impersonateUser(user?.label);

      setMessage('');

      history.push({ pathname: '/login-redirect' });
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <HeaderAwarePage className="impersonate-user">
      <Header />

      <div className="p-3 px-5">
        <PageHeader className="mb-3">Impersonate User</PageHeader>

        <div className="select-spacing">
          <PromptText>Please select a customer:</PromptText>
          <CustomerSelect
            className="select-customer"
            onChange={e => {
              if (e?.value !== customer?.value) {
                setCustomer(e);
                setUser(null);
              }
            }}
            value={customer}
          />
        </div>

        <div className="select-spacing">
          <PromptText>Please select a user:</PromptText>
          <UserSelect
            className="select-user"
            isLoading={isLoadingUsers}
            options={isLoadingUsers ? [] : userOptions}
            onChange={e => setUser(e)}
            value={user}
          />
        </div>

        <div>
          <ProfitRoverPrimaryButton onClick={onClickImpersonate}>Impersonate</ProfitRoverPrimaryButton>
        </div>
        <div className="error-message">{message}</div>
      </div>
    </HeaderAwarePage>
  );
};

const PageAccessCheck = props => (
  <Can I={Action.IMPERSONATE} a={Subject.USER} passThrough>
    {allowed => (allowed ? <ImpersonateUserPage {...props} /> : <Redirect to="/welcome" />)}
  </Can>
);

export default PageAccessCheck;
