import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Header from '../../header/header';
import {
  useDatasetConfigMetaData,
  setDatasetConfigUneditable,
  connectDatasetConfigToDataset,
} from './DatasetConfigFunctions';
import { Industry, DataType } from './DatasetConfigFormConstants';
import { useFormPageApi, Pages } from './DatasetConfigFormReducer';
import BasicInfoPage from './pages/BasicInfoPage';
// import CFBAdvTranPages from './pages/CFBAdvTranPages';
// import EOAdmitPages from './pages/EOAdmitPages';
import EOTranPages from './pages/EOTranPages';
import BasicErrorFallback from '../../generic/errorBoundary/BasicErrorFallback';
import { KaizenProduct, KaizenProductStatus } from '../../generic/subscriptions/SubscriptionConstants';
import { useSubscriptionStatusContext } from '../../routing/subscriptionStatusHooks';
import { CenteredProfitRoverSpinner } from '../../spinner/ProfitRoverSpinner';
import { Objective } from '../../workflow/workflowConstants';
import { fetchWorkflows } from '../../../data-access/query/workflows';
import { createWorkflow } from '../../../data-access/mutation/workflows';
import { useNaicsCodes } from '../../../data-access/query/naics';
import './DatasetConfigForm.css';

function DatasetConfigForm(props) {
  const { history } = props;
  const { subscriptionsByKaizenProduct } = useSubscriptionStatusContext();
  const { data: naicsCodes = [] } = useNaicsCodes();

  const [finishingMessage, setFinishingMessage] = React.useState();

  // TODO: Implement support for editing a previous config at a later time
  const { fileId: datasetId, dsConfigIdToEdit } = props?.location?.state;

  const dsConfigMetaData = useDatasetConfigMetaData(datasetId);
  const formPageApi = useFormPageApi(dsConfigIdToEdit);

  const getNaicsLocationInfo = () => {
    const kaizenPriceSubscriptionId = subscriptionsByKaizenProduct[KaizenProduct.KP].subscription_id;
    const relevantLocation = dsConfigMetaData.locations.find(
      location =>
        Object.keys(location.status_by_subscription_id) > 0 &&
        location.status_by_subscription_id[kaizenPriceSubscriptionId].status === KaizenProductStatus.ACTIVE,
    );

    // Assuming we will always have one active location for a particular subscription
    const relevantNaicsKaCode = relevantLocation?.naicsKaCode;
    const relevantIndustry = naicsCodes.find(code => code.naicsKaCode === relevantNaicsKaCode)?.industry;
    return { naicsKaCode: relevantNaicsKaCode, relevantIndustry };
  };

  const { industry, dataType, page, datasetConfigId } = formPageApi?.state;

  const onFinish = async dimensionIds => {
    const { naicsKaCode, relevantIndustry } = getNaicsLocationInfo();

    if (relevantIndustry != null) {
      const existingWorkflows = await fetchWorkflows();
      const workflowsForIndustry = existingWorkflows.data.filter(workflow => workflow?.industry === relevantIndustry);

      if (workflowsForIndustry.length === 0) {
        setFinishingMessage('Creating analysis');

        await createWorkflow({
          naicsKaCode,
          objective: Objective.PRICEOPT,
          description: 'ProfitRover',
          time_granularity: 'DAY',
          dimensions: dimensionIds,
        });
      }
    }

    setFinishingMessage('Linking new configuration to dataset');

    // "Publish" the dataset config by making it no longer editable
    await setDatasetConfigUneditable(datasetConfigId);

    // Update the dataset to connect it to this newly minted dataset config
    await connectDatasetConfigToDataset(datasetId, datasetConfigId);

    history.push('/welcome');
  };

  const showBasicInfoPage = page === Pages.BASIC_INFO && industry == null && dataType == null;

  if (finishingMessage != null) {
    return <CenteredProfitRoverSpinner className="workflow-loading-spinner" loadingText={finishingMessage} />;
  }

  return (
    <>
      <Header />

      {showBasicInfoPage && (
        <ErrorBoundary
          fallbackRender={renderProps => (
            <BasicErrorFallback
              title="Unable to Continue"
              message="Please try again or return to the Data Center."
              onLeave={() => history.push('datacenter')}
              {...renderProps}
            />
          )}
        >
          <BasicInfoPage formPageApi={formPageApi} />
        </ErrorBoundary>
      )}

      {industry === Industry.CFB && (
        <ErrorBoundary>
          {/* {dataType === DataType.ADVTRAN && (
            <CFBAdvTranPages formPageApi={formPageApi} dsConfigMetaData={dsConfigMetaData} onFinish={onFinish} />
          )} */}
        </ErrorBoundary>
      )}
      {industry === Industry.EO && (
        <>
          {/* <ErrorBoundary>
            {dataType === DataType.ADMIT && (
              <EOAdmitPages formPageApi={formPageApi} dsConfigMetaData={dsConfigMetaData} onFinish={onFinish} />
            )}
          </ErrorBoundary> */}
          <ErrorBoundary>
            {dataType === DataType.TRAN && (
              <EOTranPages formPageApi={formPageApi} dsConfigMetaData={dsConfigMetaData} onFinish={onFinish} />
            )}
          </ErrorBoundary>
        </>
      )}
      {industry === Industry.MEDIA && (
        <ErrorBoundary>
          {/* {dataType === DataType.REVENUE && (
            <MediaRevenuePages formPageApi={formPageApi} dsConfigMetaData={dsConfigMetaData} />
          )} */}
        </ErrorBoundary>
      )}
    </>
  );
}

export default DatasetConfigForm;
