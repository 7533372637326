import _ from 'lodash';
import React from 'react';

export const Baseline = {
  RECENT_PRICES: 'AT_RECENT_PRICES_FORECAST',
  RECOMMENDED_PRICES: 'RECOMMENDED_PRICES',
};

const [CurrentPrices, RecommendedPrices] = [
  { label: 'Recent Prices', value: Baseline.RECENT_PRICES },
  { label: 'RoverRecs', value: Baseline.RECOMMENDED_PRICES },
];

export { CurrentPrices };

// Kept in case historical baselines are added
export const getIsUsingHistoricalBaseline = baseline =>
  baseline !== Baseline.RECENT_PRICES && baseline !== Baseline.RECOMMENDED_PRICES;

export const getBaselineDateFunc = () => {
  return _.identity;
};

export const useBaselineComparison = () => {
  const [newPrices, setNewPrices] = React.useState(RecommendedPrices);
  const [baseline, setBaseline] = React.useState(CurrentPrices);

  return {
    isUsingHistoricalBaseline: getIsUsingHistoricalBaseline(baseline?.value),
    newPrices,
    setNewPrices,
    baseline,
    setBaseline,
  };
};
