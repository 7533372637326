import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { PollingTimeoutModal, UnableToMakeChangesModal } from './CheckoutModals';
import { useUpdateSubscription } from './CheckoutSubscriptionChangesHooks';
import {
  BubbleLabel,
  CheckoutHeaderBar,
  ConfirmChangesButton,
  GoBackButton,
  PageTitle,
  PaymentFailedMessage,
  PaymentMethodSection,
  SpreadText,
} from './common/CommonSubscriptionComponents';
import ManagePaymentMethodModal from './ManagePaymentMethodModal';
import PaymentSuccessDetails, { ChargedPaymentSuccess } from './PaymentSuccessDetails';
import LoadingIndicator from '../common/LoadingIndicator';
import { ProfitRoverModalCard, ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';
import ProfitRoverTermsOfUseLink from '../../generic/ProfitRoverTermsOfUseLink';
import { StripeBillingScheme } from '../../generic/subscriptions/SubscriptionConstants';
import { nullSafeFormatCurrency } from '../../util/format';
import { BACKGROUND_GRAY, LIGHT_GREEN } from '../../../colors';
import { usePayInvoice } from '../../../data-access/mutation/subscriptions';
import { useProfitRoverSubscriptionPrices } from '../../../data-access/query/stripe';
import {
  BILLING_INFO_QUERY_KEY_BASE,
  SUBSCRIPTION_PREVIEW_INVOICE_QUERY_KEY_BASE,
  useBillingInfo,
  useLocationStatusChangePreviewInvoice,
} from '../../../data-access/query/subscriptions';
import './select-plan.scss';

const computedDisplayInfo = previewInvoicesData => {
  const { invoices, outcome } = previewInvoicesData;
  const { immediate: immediateBillingInvoice, next_billing_cycle: nextBillingCycleInvoice } = invoices ?? {};
  const {
    num_locations_being_activated: numLocationsBeingActivated,
    num_locations_being_deactivated: numLocationsBeingDeactivated,
    num_locations_being_reactivated: numLocationsBeingReactivated,
  } = outcome ?? {};

  let immediatePaymentDetails = {};
  let nextBillingDetails = {};

  if (invoices && outcome) {
    const netIncreaseInQuantity = numLocationsBeingActivated;

    const { lines: nextCycleLineItems = [] } = nextBillingCycleInvoice ?? {};
    const [nextCycle] = _.takeRight(nextCycleLineItems, 1);

    const { tiers = [] } = nextCycle?.price ?? {};
    const [, nonFreeTier = {}] = tiers;
    let { unit_amount: pricePerQuantity } = nonFreeTier;
    pricePerQuantity /= 100;

    let { subtotal: originalNextBillingSubtotal } = nextBillingCycleInvoice ?? {}; // How much the user would be billed next cycle if they made no changes
    let nextBillingAmount = originalNextBillingSubtotal / 100;

    const changesToNextBill = [];
    if (netIncreaseInQuantity > 0) {
      let amount = pricePerQuantity * netIncreaseInQuantity;
      nextBillingAmount += amount;

      amount = nullSafeFormatCurrency(amount);
      amount = `+${amount}`;

      const suffix = netIncreaseInQuantity > 1 ? 's' : '';
      changesToNextBill.push({
        label: `Activating ${netIncreaseInQuantity} Location${suffix}`,
        className: 'increase',
        formattedAmount: amount,
      });
    }

    if (numLocationsBeingDeactivated > 0) {
      let amount = pricePerQuantity * numLocationsBeingDeactivated;
      nextBillingAmount -= amount;

      amount = nullSafeFormatCurrency(amount);
      amount = `-${amount}`;

      const suffix = numLocationsBeingDeactivated > 1 ? 's' : '';
      changesToNextBill.push({
        label: `Deactivating ${numLocationsBeingDeactivated} Location${suffix}`,
        className: 'decrease',
        formattedAmount: amount,
      });
    }

    if (numLocationsBeingReactivated > 0) {
      let amount = pricePerQuantity * numLocationsBeingReactivated;
      nextBillingAmount += amount;

      amount = nullSafeFormatCurrency(amount);
      amount = `+${amount}`;

      const suffix = numLocationsBeingReactivated > 1 ? 's' : '';
      changesToNextBill.push({
        label: `Reactivating ${numLocationsBeingReactivated} Location${suffix}`,
        className: 'increase',
        formattedAmount: amount,
      });
    }

    nextBillingAmount = nullSafeFormatCurrency(nextBillingAmount);
    originalNextBillingSubtotal = nullSafeFormatCurrency(originalNextBillingSubtotal / 100);

    let nextBillingDate = nextCycle?.period?.start;
    nextBillingDate = moment.unix(nextBillingDate).format('MMM Do, YYYY');

    nextBillingDetails = {
      originalBillingAmount: originalNextBillingSubtotal,
      changesToNextBill,
      nextBillingAmount,
      nextBillingDate,
    };

    // ---

    const { lines: lineItems = [] } = immediateBillingInvoice ?? {};
    const [remainingTime] = _.takeRight(lineItems, 1); // TODO: These need to be sorted correctly, which could be a weak assumption

    let proratedPeriodStart = remainingTime?.period?.start;
    let proratedPeriodEnd = remainingTime?.period?.end;

    proratedPeriodStart = moment.unix(proratedPeriodStart).format('MMM Do'); // Oct 12th
    proratedPeriodEnd = moment.unix(proratedPeriodEnd).format('MMM Do, YYYY'); // Oct 16th 2021;

    /**
     * Developer note: as of 5/13/2022, the preview invoice returned from Stripe represents the state of the _upcoming_
     * invoice including the changes we're proposing to make right now; that is, if we made these changes but did not
     * immediately charge the customer then their next invoice would contain the line items returned by the endpoint
     * for that call. We _will_ immediately charge the customer, however, which means we should not include the line
     * item(s) representing the charges for the upcoming period, only the line items that represent the proposed changes
     * that we want to make for this period. This means we cannot naively (but intuitively, reasonably) present the
     * amount_due to the customer as the charge that they are about to receive - instead we must filter the line items
     * according to the subscription proration date. See the following Stripe docs for more information, including the
     * recommendation of using this strategy:
     *
     * https://stripe.com/docs/billing/subscriptions/prorations#preview-proration
     * https://stripe.com/docs/api/invoices/upcoming
     */
    let proratedSubtotal = 0;
    let sumOfTaxAmounts = 0;
    if (immediateBillingInvoice?.lines) {
      const { subscription_proration_date: subscriptionProrationDate } = immediateBillingInvoice;
      proratedSubtotal = immediateBillingInvoice.lines
        .filter(line => line.period.start === subscriptionProrationDate)
        .map(line => line.amount)
        .reduce((prev, cur) => prev + cur, 0);
      // const subtotal = immediateBillingInvoice?.subtotal ?? 0;
      // const proratedSubtotal = nullSafeFormatCurrency(subtotal / 100);

      const taxAmounts = immediateBillingInvoice.lines
        .filter(line => line.period.start === subscriptionProrationDate)
        .map(line => line.tax_amounts);

      sumOfTaxAmounts = _.flatten(taxAmounts)
        .map(taxAmount => taxAmount.amount)
        .reduce((prev, curr) => prev + curr, 0);
    }

    let dueToday = proratedSubtotal + sumOfTaxAmounts;
    const customerWillBeCharged = dueToday > 0;

    // let tax = immediateBillingInvoice?.tax ?? 0;
    // tax = nullSafeFormatCurrency(tax / 100);
    const tax = nullSafeFormatCurrency(sumOfTaxAmounts / 100);
    dueToday = nullSafeFormatCurrency(dueToday / 100);
    proratedSubtotal = nullSafeFormatCurrency(proratedSubtotal / 100);

    immediatePaymentDetails = {
      customerWillBeCharged,
      proratedPeriodStart,
      proratedPeriodEnd,
      proratedSubtotal,
      tax,
      dueToday,
    };
  }

  return {
    immediatePaymentDetails,
    nextBillingDetails,
  };
};

const BillingChange = ({ isLoading, label, className, formattedAmount }) => {
  return (
    <SpreadText className="change-in-billing-amount">
      <p>{label}</p>
      <p>{isLoading ? <LoadingIndicator /> : <span className={className}>{formattedAmount}</span>}</p>
    </SpreadText>
  );
};

const PaymentInfo = ({
  isLoading,
  subscriptionId,
  immediatePaymentDetails,
  numLocationsBeingActivated,
  onCancel,
  updateSubscriptionMutation,
  setShowUpdatePaymentMethodModal,
}) => {
  const {
    onConfirmChanges,
    setPaymentCompletionDetails,
    isSubmitting,
    pollingForFinalizedChanges,
    showPaymentFailed,
    submissionError,
  } = updateSubscriptionMutation;

  const reattemptPaymentMutation = usePayInvoice({
    onSuccess: ({ payment_email_address: paymentEmailAddress, paid_invoice: paidInvoice }) =>
      setPaymentCompletionDetails(new ChargedPaymentSuccess({ paidInvoice, paymentEmailAddress })),
  });

  const reattemptPayment = async () =>
    reattemptPaymentMutation.mutateAsync({ subscriptionId, invoiceId: submissionError.invoiceId });

  const defaultPaymentMethodState = useBillingInfo(subscriptionId, 'defaultPaymentMethod');
  const { default_payment_method: defaultPaymentMethod } = defaultPaymentMethodState?.data ?? {};
  const hasPaymentMethod = defaultPaymentMethod != null;

  const {
    customerWillBeCharged = true,
    proratedPeriodStart = 'Invalid date',
    proratedPeriodEnd = 'Invalid date',
    proratedSubtotal,
    tax,
    dueToday,
  } = immediatePaymentDetails;

  const changesInProgress = isSubmitting || pollingForFinalizedChanges || reattemptPaymentMutation.isLoading;
  const confirmButtonDisabled = isLoading || changesInProgress || showPaymentFailed || !hasPaymentMethod;
  const showProratedPeriod = proratedPeriodStart !== 'Invalid date' && proratedPeriodEnd !== 'Invalid date';

  return (
    <>
      <ProfitRoverModalMain className="left-main">
        {showPaymentFailed && <PaymentFailedMessage onClick={() => setShowUpdatePaymentMethodModal(true)} />}
        <p className="ssl-text">
          <FontAwesomeIcon icon={faLock} color={LIGHT_GREEN} />
          This is a secure SSL Encrypted Payment
        </p>
        <PaymentMethodSection
          defaultPaymentMethodState={defaultPaymentMethodState}
          paymentFailed={showPaymentFailed}
          onClick={() => setShowUpdatePaymentMethodModal(true)}
        />
        <div className="padded-container">
          {numLocationsBeingActivated > 0 && (
            <h6 className="locations-count">Activating {numLocationsBeingActivated} Location(s)</h6>
          )}
          {showProratedPeriod && (
            <SpreadText className="prorated-period">
              <p>Prorated period</p>
              <p>
                <i>
                  {proratedPeriodStart} - {proratedPeriodEnd}
                </i>
              </p>
            </SpreadText>
          )}
        </div>
        <div className="padded-container emphasis-area">
          <SpreadText>
            <p>Prorated subtotal</p>
            <p>{isLoading ? <LoadingIndicator /> : proratedSubtotal}</p>
          </SpreadText>
          <SpreadText className="sales-tax">
            <p>Sales Tax</p>
            <p>{isLoading ? <LoadingIndicator /> : tax}</p>
          </SpreadText>
          <SpreadText>
            <h5>Due Today</h5>
            <h5>{isLoading ? <LoadingIndicator /> : dueToday}</h5>
          </SpreadText>
        </div>
      </ProfitRoverModalMain>
      <ProfitRoverModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
        <GoBackButton
          onClick={onCancel}
          paymentFailed={showPaymentFailed}
          subscriptionId={subscriptionId}
          disabled={isSubmitting}
        />
        <ConfirmChangesButton
          customerWillBeCharged={customerWillBeCharged}
          changesInProgress={changesInProgress}
          disabled={confirmButtonDisabled}
          onConfirmChanges={onConfirmChanges}
          onTryAgain={reattemptPayment}
          submissionError={submissionError}
        />
      </ProfitRoverModalFooter>
    </>
  );
};

const SUCCESS_TITLE = 'Successfully Made Changes to Subscription';

const CheckoutFirstFreeSubscriptionChanges = ({
  locationsToActivate = [],
  locationsToDeactivate = [],
  onCancel,
  subscriptionId,
}) => {
  const [selectedPlan, setSelectedPlan] = React.useState();
  const locationIdsToActivate = locationsToActivate.map(loc => loc.location_id);
  const locationIdsToDeactivate = locationsToDeactivate.map(loc => loc.location_id);

  const updateSubscriptionMutation = useUpdateSubscription(
    subscriptionId,
    locationIdsToActivate,
    locationIdsToDeactivate,
  );
  const { paymentCompletionDetails, setShowPaymentFailed, verificationError } = updateSubscriptionMutation;

  const queryClient = useQueryClient();

  const currentBillingInfoState = useBillingInfo(subscriptionId);
  const { data: currentBillingInfo, isLoading: isLoadingCurrentBillingInfo } = currentBillingInfoState;
  const { quantity, billing_scheme: billingScheme } = currentBillingInfo?.plan_info ?? {};
  const showFreeLocationLine = billingScheme === StripeBillingScheme.TIERED && quantity === 1;

  const {
    data: previewInvoicesData = {},
    isLoading,
    error: previewInvoiceError,
  } = useLocationStatusChangePreviewInvoice(subscriptionId, locationIdsToActivate, locationIdsToDeactivate);

  const { price_nickname: priceName } = currentBillingInfo?.plan_info ?? {};

  const { data: priceMap = {}, isLoading: isLoadingPrices } = useProfitRoverSubscriptionPrices(priceName, {
    enabled: !!priceName,
  });
  const prices = priceMap[priceName];

  React.useEffect(() => {
    if (!isLoadingPrices && prices != null && selectedPlan == null && !isLoadingCurrentBillingInfo) {
      const currentlySelectedPlan =
        currentBillingInfo?.plan_info.billing_interval === 'month' ? prices.monthly : prices.annual;
      setSelectedPlan(currentlySelectedPlan);
    }
  }, [prices, isLoadingPrices, currentBillingInfo, isLoadingCurrentBillingInfo, selectedPlan]);

  const { num_locations_being_activated: numLocationsBeingActivated } = previewInvoicesData?.outcome ?? {};

  const displayInfo = computedDisplayInfo(previewInvoicesData);
  const {
    immediatePaymentDetails,
    nextBillingDetails: { originalBillingAmount, changesToNextBill = [], nextBillingAmount, nextBillingDate },
  } = displayInfo;

  const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] = React.useState(false);
  const onHide = () => setShowUpdatePaymentMethodModal(false);

  const blurStyles = { filter: showUpdatePaymentMethodModal ? 'blur(8px)' : undefined };

  return (
    <>
      <CheckoutHeaderBar />
      <div className="checkout-subscription-changes" style={{ backgroundColor: BACKGROUND_GRAY, ...blurStyles }}>
        <UnableToMakeChangesModal show={previewInvoiceError != null} />
        <PollingTimeoutModal show={verificationError != null} />

        <ManagePaymentMethodModal
          show={showUpdatePaymentMethodModal}
          subscriptionId={subscriptionId}
          onHide={onHide}
          onSuccess={async () => {
            await Promise.allSettled([
              queryClient.refetchQueries([
                ...SUBSCRIPTION_PREVIEW_INVOICE_QUERY_KEY_BASE,
                subscriptionId,
                locationIdsToActivate,
                locationIdsToDeactivate,
              ]),
              queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId, 'defaultPaymentMethod']),
            ]);
            setShowPaymentFailed(false);
            onHide();
          }}
        />

        <PageTitle>Update Your Subscription</PageTitle>
        <div className="section-layout">
          <div className="labeled-bubble-container">
            {/* TODO: Hidden until we can decide if we want to support this */}
            {/* <ProfitRoverModalMain style={{ backgroundColor: '#f2f2f2', paddingTop: '0px', paddingBottom: '0px' }}>
                {isLoadingPrices ? (
                  <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 150 }}>
                    <LoadingIndicator />
                  </div>
                ) : (
                  <EndTrialEarlyOrderDetails
                    prices={prices}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                  />
                )}
              </ProfitRoverModalMain> */}

            {paymentCompletionDetails == null ? (
              <>
                <BubbleLabel>Payment Due Today</BubbleLabel>
                <ProfitRoverModalCard>
                  <PaymentInfo
                    isLoading={isLoading}
                    subscriptionId={subscriptionId}
                    immediatePaymentDetails={immediatePaymentDetails}
                    numLocationsBeingActivated={numLocationsBeingActivated}
                    onCancel={onCancel}
                    updateSubscriptionMutation={updateSubscriptionMutation}
                    setShowUpdatePaymentMethodModal={setShowUpdatePaymentMethodModal}
                  />
                </ProfitRoverModalCard>
              </>
            ) : (
              <PaymentSuccessDetails
                title={SUCCESS_TITLE}
                subscriptionId={subscriptionId}
                paymentCompletionDetails={paymentCompletionDetails}
              />
            )}
          </div>
          <div className="labeled-bubble-container">
            <BubbleLabel>Next Billing Details</BubbleLabel>
            <ProfitRoverModalCard className="next-billing-details">
              <ProfitRoverModalMain className="right-main">
                {showFreeLocationLine && (
                  <SpreadText>
                    <p>First Location</p>
                    <i className="line-item-free">Free</i>
                  </SpreadText>
                )}
                <SpreadText>
                  <p>Billing Amount was</p>
                  <p>{isLoading ? <LoadingIndicator /> : originalBillingAmount}</p>
                </SpreadText>

                <div className="line-item-changes">
                  {changesToNextBill.map(props => (
                    <BillingChange key={props.className} isLoading={isLoading} {...props} />
                  ))}
                </div>

                <hr />

                <SpreadText className="next-billing-amount">
                  <p>
                    <strong>Next Billing Amount</strong>
                  </p>
                  <div>
                    {isLoading ? (
                      <LoadingIndicator />
                    ) : (
                      <p style={{ marginRight: -29 }}>
                        <strong>{nextBillingAmount}</strong>
                        <span className="plus-tax-text"> + tax</span>
                      </p>
                    )}
                  </div>
                </SpreadText>
                <SpreadText className="scheduled-for">
                  <p>
                    <i>Scheduled for</i>
                  </p>
                  <p>
                    <i>{isLoading ? <LoadingIndicator /> : nextBillingDate}</i>
                  </p>
                </SpreadText>
              </ProfitRoverModalMain>
            </ProfitRoverModalCard>
          </div>
          <div className="explanatory-text-container">
            <p>
              By updating your subscription, you authorize us to make regularly scheduled charges to your credit card.
              You will be charged each billing period for the amount due for that period. For more information regarding
              billing, please review the <ProfitRoverTermsOfUseLink>Terms of Use</ProfitRoverTermsOfUseLink>.
            </p>
            <p>
              You will have access to insights for any newly activated locations in addition to your free location. You
              can automatically add more locations to your account by connecting any data source that contains new
              location information. You can then activate new locations to your subscription plan at any point, which
              can result in a prorated amount if done before your next billing cycle.
            </p>
            <p>
              You can manage your subscription plan via the subscription settings page. Turning off auto-renew on your
              subscription plan will effectively cancel your next bill, and you will lose insights on your next billing
              date for any additional locations excluding your one free location.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutFirstFreeSubscriptionChanges;
