import React from 'react';
import _ from 'lodash';
import { Industry, DataType } from '../DatasetConfigFormConstants';
import { computeNextPageForBasicInfo } from '../DatasetConfigFormReducer';
import { useTimeGranularities } from '../DatasetConfigFunctions';
import {
  ActionButton,
  LargeRadioButton,
  PageHeader,
  PageSubHeader,
  PromptText,
  RadioButtonRow,
  SmallRadioButton,
  TextInput,
} from '../commonFormComponents';
import { useLocationNaicsCodes, useLocations } from '../../../../data-access/query/locations';
import { useNaicsCodes } from '../../../../data-access/query/naics';

const NO_ANSWER = null;
const NO_ANSWER_LABEL = "It's Not";
const ALLOWED_TIME_GRANULARITIES = Object.freeze(['DAY', 'WEEK', 'MONTH', 'QUARTER']);

export default function BasicInfoPage(props) {
  const { formPageApi } = props;

  const { state: formState, actions: formActions } = formPageApi;
  const { navigateToPage, setBasicInfo } = formActions;

  const allTimeGranularities = useTimeGranularities();
  const timeGranularities = allTimeGranularities.filter(gran =>
    ALLOWED_TIME_GRANULARITIES.includes(gran.time_granularity),
  );

  const { data: locations = [], isFetching: isFetchingLocations } = useLocations();

  const { data: naicsCodes = [], isFetching: isFetchingNaicsCodes } = useNaicsCodes();
  const naicsKaCodesInLocations = useLocationNaicsCodes(locations, naicsCodes);
  const validNaicsKaCodes = _.keyBy(naicsKaCodesInLocations, 'naicsKaCode');

  const [naicsKaCode, setNaicsKaCode] = React.useState();
  const naicsKaCodeSelected = naicsKaCode != null;
  const industry = naicsKaCodeSelected ? validNaicsKaCodes[naicsKaCode].industry : null;

  React.useEffect(() => {
    const isFetching = isFetchingLocations && isFetchingNaicsCodes;
    if (!isFetching && naicsKaCodesInLocations.length === 1) {
      setNaicsKaCode(naicsKaCodesInLocations[0].naicsKaCode);
    }
  }, [isFetchingLocations, isFetchingNaicsCodes, naicsKaCodesInLocations]);

  const [dataType, setDataType] = React.useState(formState.dataType);
  const [timeGranularity, setTimeGranularity] = React.useState(formState.timeGranularity);
  const [configName, setConfigName] = React.useState(formState.datasetConfigName ?? '');

  const industryAndDataTypeSelected = industry != null && dataType != null;
  const timeGranularityRequired = industry !== Industry.CFB;
  const timeGranularitySpecified = timeGranularity !== undefined;
  const timeGranularityHandled = timeGranularityRequired ? timeGranularitySpecified : true;
  const configNameSpecified = configName != null && configName !== '';

  const canContinue = industryAndDataTypeSelected && timeGranularityHandled && configNameSpecified;

  const onClickNext = async () => {
    let timeGranularityValue = null;

    // We don't
    if (timeGranularityRequired) {
      // eslint-disable-next-line camelcase
      timeGranularityValue = timeGranularity?.time_granularity ?? null;
    }

    await setBasicInfo(naicsKaCode, industry, dataType, timeGranularityValue, configName);

    const nextPage = computeNextPageForBasicInfo(industry, dataType);

    navigateToPage(nextPage);
  };

  return (
    <div className="ds-config-container">
      <PageHeader>New Data Configuration</PageHeader>

      <PageSubHeader>
        <div style={{ fontWeight: 500 }}>Let&apos;s start with the basics.</div>
      </PageSubHeader>

      <hr />

      <PromptText>Select your industry for this configuration.</PromptText>
      <RadioButtonRow>
        {naicsKaCodesInLocations.map(code => (
          <LargeRadioButton
            key={code.description}
            selected={code.naicsKaCode === naicsKaCode}
            onClick={() => setNaicsKaCode(code.naicsKaCode)}
          >
            {code.description}
          </LargeRadioButton>
        ))}
      </RadioButtonRow>

      {industry != null && (
        <>
          <PromptText>Select your Data Type.</PromptText>

          <RadioButtonRow>
            <LargeRadioButton
              selected={dataType === DataType.TRAN || dataType === DataType.ADVTRAN}
              onClick={() => {
                if (industry === Industry.CFB) {
                  setDataType(DataType.ADVTRAN);
                } else {
                  setDataType(DataType.TRAN);
                }
              }}
            >
              Transactions
            </LargeRadioButton>
          </RadioButtonRow>
        </>
      )}

      {industryAndDataTypeSelected && timeGranularityRequired && (
        <>
          <PromptText>How is your data aggregated?</PromptText>

          <RadioButtonRow>
            {[...timeGranularities, NO_ANSWER].map(granularity => {
              const label = granularity?.description ?? NO_ANSWER_LABEL;
              // Note about timeGranularity:
              // null => user selected "It's not", undefined => user hasn't selected anything
              const selected =
                granularity === NO_ANSWER
                  ? timeGranularity === NO_ANSWER
                  : (granularity.description ?? null) === (timeGranularity?.description ?? undefined);
              const onClick = () => setTimeGranularity(granularity);
              return (
                <SmallRadioButton key={label} selected={selected} onClick={onClick}>
                  {label}
                </SmallRadioButton>
              );
            })}
          </RadioButtonRow>
        </>
      )}

      {industryAndDataTypeSelected && timeGranularityHandled && (
        <>
          <PromptText>Please give this data configuration a name.</PromptText>

          <TextInput
            onChange={e => setConfigName(e.target.value)}
            placeholder="My Dataset Configuration"
            style={{ minWidth: 300 }}
            value={configName}
          />
        </>
      )}

      {canContinue && (
        <ActionButton onClick={onClickNext} style={{ marginTop: 30 }}>
          Next
        </ActionButton>
      )}
    </div>
  );
}
