import _ from 'lodash';
import { provinceDropdownOptions, stateDropdownOptions } from '../util/stateAndProvinceOptions';

export const Country = {
  US: 'US',
  CA: 'CA',
};

export const CountryDisplay = {
  US: 'USA',
  CA: 'Canada',
};

export const Region = {
  STATE: 'State',
  PROVINCE: 'Province',
  COMBO: 'State / Province',
};

export const Postcode = {
  ZIP: 'Zip Code',
  POSTAL: 'Postal Code',
  COMBO: 'Zip / Postal',
};

export const getRegionSpecificData = countryIsCanada => {
  if (!_.isBoolean(countryIsCanada)) {
    const typeToDisplay = countryIsCanada == null ? countryIsCanada : typeof countryIsCanada;
    throw new Error(`Developer error: countryIsCanada must be a Boolean, not ${typeToDisplay}`);
  }

  return countryIsCanada
    ? { regionLabel: Region.PROVINCE, regionDropdownOptions: provinceDropdownOptions, postcodeLabel: Postcode.POSTAL }
    : { regionLabel: Region.STATE, regionDropdownOptions: stateDropdownOptions, postcodeLabel: Postcode.ZIP };
};
