import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const getCredentials = async () => axios.get(`${KA_API_URL}/credential`);

/**
 * Hooks
 */

export const CREDENTIALS_QUERY_KEY = ['credentials'];

export const useCredentials = () =>
  useQuery(CREDENTIALS_QUERY_KEY, async () => {
    const response = await getCredentials();
    return response?.data ?? [];
  });

export const usePartnerCredentials = partner => {
  const credentialsQuery = useCredentials();
  const { data = [] } = credentialsQuery;

  credentialsQuery.data = React.useMemo(() => data.filter(credential => credential.partner === partner), [
    data,
    partner,
  ]);

  return credentialsQuery;
};
