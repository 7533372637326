import React from 'react';

import { CancelButton, SectionHeader } from '../../../commonFormComponents';

const DimensionSelectionReview = props => {
  const { value, onClick, letterLabel, onCancel } = props;

  return (
    <div style={{ marginBottom: 10, cursor: 'pointer' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SectionHeader label={letterLabel} style={{ margin: 0 }}>
          {value}
        </SectionHeader>
        <div
          role="button"
          tabIndex="0"
          className="dim-selection-reselect"
          onClick={onClick}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              onClick();
            }
          }}
        >
          <i>Reselect</i>
        </div>
        <CancelButton
          className="dim-selection-cancel"
          onClick={onCancel}
          style={{ position: 'static', marginLeft: 150, marginRight: 5 }}
        >
          <i>Cancel</i>
        </CancelButton>
      </div>
    </div>
  );
};

export default DimensionSelectionReview;
