import _ from 'lodash';
import camelcase from 'camelcase';
import * as d3 from 'd3';
import moment from 'moment-timezone';
import React from 'react';
import { useAnomalyDetectionContext } from './AnomalyDetectionContext';
import { ACTUALS_LINE_COLOR, EXPECTED_LINE_COLOR, STROKE_OPACITY } from './graphConstants';
import { compactFormatNumber } from '../../util/format';
import { ERROR, FONT_BLACK, HIGH_TEMPERATURE_OVERLAY_BASE } from '../../../colors';
import { useLineGraphContext, LineGraphProvider } from './LineGraphContext';

const TimeGranularity = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
};

/**
 * Condenses the rendered data to make room for accessory elements such as axis labels and legends.
 */
const GRAPH_MARGIN = {
  TOP: 30,
  LEFT: 90,
  RIGHT: 40,
  BOTTOM: 50,
};

const POINT_RADIUS = 4;

const AXIS_LABEL_FONT_SIZE = '16px';
const AXIS_TICK_FONT_SIZE = '12px';
const AXIS_FONT_WEIGHT = 400;

// Parses YYYY-MM-DD times into Date objects so that D3 can properly interpret them as time values
const dateParser = d3.timeParse('%Y-%m-%d');

/**
 * color - Color in which the line will display
 * dataKey - "accessor" key into the datum that this line represents
 * dashed - If true, render as a dashed line instead of a solid line
 */

const Line = ({
  dataKey,
  data,
  dateColumn,
  styleOptions: { color = FONT_BLACK, dashed = false, strokeWidth = 3, strokeOpacity = 1.0 } = {},
}) => {
  const svgRef = React.useRef(null);

  const { height, width, xScale, yScale, yMin } = useLineGraphContext();

  React.useLayoutEffect(() => {
    if (width === 0 || height === 0 || svgRef.current == null) {
      return;
    }

    const svgEl = d3.select(svgRef.current);

    const startingLine = d3
      .line()
      .x(d => xScale(dateParser(d[dateColumn])))
      .y(() => yScale(yMin));

    const line = d3
      .line()
      .x(d => xScale(dateParser(d[dateColumn])))
      .y(d => yScale(d[dataKey]));

    const transitionLinePosition = d3
      .transition()
      .ease(d3.easeExp)
      .duration(600);

    svgEl
      .selectAll('path')
      .data(data != null ? [data] : [])
      .join(
        enter =>
          enter
            .append('path')
            .attr('class', 'line')
            .attr('fill', 'none')
            .attr('stroke', color)
            .attr('stroke-width', strokeWidth)
            .attr('stroke-linejoin', 'round')
            .attr('stroke-dasharray', dashed ? '2 2' : undefined)
            .attr('stroke-opacity', strokeOpacity)
            // Start with the line being level with the x-axis
            .attr('d', d => startingLine(d)),
        update =>
          update
            .attr('stroke', color)
            .attr('stroke-width', strokeWidth)
            .attr('stroke-linejoin', 'round')
            .attr('stroke-dasharray', dashed ? '2 2' : undefined)
            .attr('stroke-opacity', strokeOpacity),
        exit =>
          exit
            .transition(transitionLinePosition)
            .attr('d', d => startingLine(d))
            .remove(),
      )
      // Transition the line to its correct/actual position
      .transition(transitionLinePosition)
      .attr('d', d => line(d));
  }, [color, dashed, data, dataKey, dateColumn, height, width, xScale, yMin, yScale, strokeWidth, strokeOpacity]);

  return <g ref={svgRef} className="lines-container" />;
};

const XAxis = ({ ticks, disableAnimation, color = FONT_BLACK, ...props }) => {
  const ref = React.useRef(null);

  const { height, xTickFormat: tickFormat, xScale: scale } = useLineGraphContext();

  React.useLayoutEffect(() => {
    if (height === 0) {
      return;
    }

    const axis = d3
      .axisBottom(scale)
      .ticks(ticks)
      .tickFormat(tickFormat)
      .tickPadding([12])
      .tickSizeInner([0])
      .tickSizeOuter([0]);

    const axisGroup = d3.select(ref.current);

    axisGroup
      .attr('transform', `translate(0,${height - 80})`)
      .attr('fill', color)
      .selectAll('text')
      .attr('letter-spacing', 0.12)
      .attr('font-size', AXIS_TICK_FONT_SIZE)
      .attr('font-weight', AXIS_FONT_WEIGHT)
      .attr('font-family', 'URWDIN');

    if (disableAnimation) {
      axisGroup.call(axis);
    } else {
      axisGroup
        .transition()
        .duration(100)
        .ease(d3.easeLinear)
        .call(axis);
    }
  }, [scale, ticks, tickFormat, disableAnimation, color, height]);

  return <g ref={ref} {...props} />;
};

const YAxis = ({ label, ticks, disableAnimation, color = FONT_BLACK, ...props }) => {
  const ref = React.useRef(null);
  const labelRef = React.useRef(null);

  const { height, yTickFormat: tickFormat, yScale: scale } = useLineGraphContext();

  // Draw the axis and tick marks
  React.useLayoutEffect(() => {
    const axis = d3
      .axisLeft(scale)
      .ticks(ticks)
      .tickFormat(tickFormat)
      .tickPadding([7])
      .tickSizeInner([0])
      .tickSizeOuter([0]);

    const axisGroup = d3.select(ref.current);

    if (disableAnimation) {
      axisGroup.call(axis);
    } else {
      axisGroup
        .transition()
        .duration(300)
        .ease(d3.easeLinear)
        .call(axis);
    }

    axisGroup
      .selectAll('text')
      .attr('fill', color)
      .attr('letter-spacing', 0.12)
      .attr('font-size', AXIS_TICK_FONT_SIZE)
      .attr('font-weight', AXIS_FONT_WEIGHT)
      .attr('font-family', 'URWDIN');
  }, [scale, ticks, tickFormat, disableAnimation, color, height]);

  // Draw axis label
  React.useLayoutEffect(() => {
    d3.select(labelRef.current)
      // Note: This rotation swaps the meaning of "x" and "y" for vertical text
      .attr('transform', 'rotate(-90)')
      .attr('x', -(height / 2 - GRAPH_MARGIN.BOTTOM)) // "Centered vertically"
      .attr('y', -(GRAPH_MARGIN.LEFT * 0.75)) // How many pixels away from the left edge
      .attr('text-anchor', 'middle')
      .attr('fill', 'black')
      .attr('font-size', AXIS_LABEL_FONT_SIZE);
  }, [height]);

  return (
    <>
      <text ref={labelRef} className="y-label">
        {label}
      </text>
      <g ref={ref} {...props} />;
    </>
  );
};

const TIME_GRANULARITY_TO_AXIS_BASE_LABEL = {
  [TimeGranularity.DAY]: 'Daily',
  [TimeGranularity.WEEK]: 'Weekly',
  [TimeGranularity.MONTH]: 'Monthly',
};

const getMinForKey = (data, key) => d3.min(data, d => d[key]);
const getMaxForKey = (data, key) => d3.max(data, d => d[key]);

const useGraphController = (data, comparisonColumns, dateColumn, height, width) => {
  const minMaxes = React.useMemo(() => {
    const lineSeriesKeys = ['segmentActual', 'segmentPredicted', ...comparisonColumns];

    const lineMinimums = [...lineSeriesKeys].map(key => {
      return getMinForKey(data, key);
    });
    const lineMaximums = [...lineSeriesKeys].map(key => {
      return getMaxForKey(data, key);
    });

    const [xMin, xMax] = d3.extent(data, d => dateParser(d[dateColumn]));
    /**
     * This can replace the line above to add "padding" to the axis by
     * adding/subtracting variable amounts of time to the true min and
     * max dates
     */
    // const [xMin, xMax] = d3
    //   .extent(data, d => dateParser(d[dateColumn]))
    //   .map((val, i) => (i === 0 ? d3.timeDay.offset(val, -1) : d3.timeDay.offset(val, 1)));

    const yMin = d3.min(lineMinimums);
    const yMax = d3.max(lineMaximums);

    return {
      xMin,
      xMax,
      yMin,
      yMax,
    };
  }, [comparisonColumns, data, dateColumn]);
  const { xMin, xMax, yMin, yMax } = minMaxes;

  const xScale = React.useMemo(
    () =>
      d3
        .scaleTime()
        .domain([xMin, xMax])
        .range([0, width - (GRAPH_MARGIN.LEFT + GRAPH_MARGIN.RIGHT)]),
    [width, xMax, xMin],
  );
  const yScale = React.useMemo(() => {
    return d3
      .scaleLinear()
      .domain([yMin, yMax])
      .range([height - (GRAPH_MARGIN.TOP + GRAPH_MARGIN.BOTTOM), 0]);
  }, [yMin, yMax, height]);

  const xTickFormat = React.useCallback(d => moment(d).format('M/D/YY'), []);
  const yTickFormat = React.useCallback(d => compactFormatNumber(d), []);

  return {
    xMin,
    xMax,
    yMin,
    yMax,
    xScale,
    yScale,
    xTickFormat,
    yTickFormat,
  };
};

const Point = ({ yValue, date }) => {
  const circleRef = React.useRef();
  const { xScale, yScale } = useLineGraphContext();

  React.useEffect(() => {
    if (!circleRef.current || date == null || !yValue) {
      return;
    }

    const animation = d3
      .transition()
      .ease(d3.easeExp)
      .duration(600);

    d3.select(circleRef.current)
      .selectAll('circle')
      .data([{ date }])
      .join(
        enter =>
          enter
            .append('circle')
            .attr('r', 0)
            .attr('cx', xScale(dateParser(date)))
            .attr('cy', yScale(yValue))
            .attr('fill', ERROR)
            .attr('opacity', 0),
        update => update,
        exit => exit.remove(),
      )
      .transition(animation)
      .attr('cx', () => xScale(dateParser(date)))
      .attr('cy', yScale(yValue))
      .transition(animation)
      .attr('r', POINT_RADIUS)
      .attr('opacity', 1);
  }, [date, yValue, xScale, yScale]);

  return <g ref={circleRef} className="circle-container" />;
};

const generateVerticalLineData = (dateColumn, anomalousDate, yMin, yMax) => {
  const verticalLineData = [
    {
      [dateColumn]: anomalousDate,
      segmentDiff: yMin,
    },
    {
      [dateColumn]: anomalousDate,
      segmentDiff: yMax,
    },
  ];

  return verticalLineData;
};

const AnomalyLineGraph = ({ data, comparisons, width, height, timeGranularity, anomalyDate }) => {
  const contextValue = useAnomalyDetectionContext();
  const { metricColumn: metricColumnLabel } = contextValue;
  let { dateColumn } = contextValue;

  const comparisonsToShow = comparisons.filter(({ checked }) => checked);
  const comparisonColumns = comparisonsToShow.map(({ columnLabel }) => columnLabel);

  dateColumn = camelcase(dateColumn ?? '');
  data = React.useMemo(() => _.orderBy(data, [dateColumn], 'asc'), [data, dateColumn]);

  const lineGraphContextInternals = useGraphController(data, comparisonColumns, dateColumn, height, width);
  const { yMin, yMax } = lineGraphContextInternals;

  const timeGranLabel = TIME_GRANULARITY_TO_AXIS_BASE_LABEL[timeGranularity];

  let yAxisLabel = 'Metric';
  if (timeGranLabel && metricColumnLabel) {
    yAxisLabel = `${timeGranLabel} ${metricColumnLabel}`;

    // TODO: KP-3305
    const unitLabel = true;
    if (unitLabel != null) {
      yAxisLabel += ' ($)';
    }
  }

  const anomalousRow = data.find(row => row[dateColumn] === anomalyDate) ?? {};
  const anomalousDate = anomalousRow?.[dateColumn];
  const { segmentActual, segmentPredicted } = anomalousRow;

  let outerHighlightLineData = [];
  let anomalousHighlightLineData = [];
  if (anomalousDate) {
    outerHighlightLineData = generateVerticalLineData(dateColumn, anomalousDate, yMin, yMax);
    anomalousHighlightLineData = generateVerticalLineData(dateColumn, anomalousDate, segmentActual, segmentPredicted);
  }

  const graphContextValue = { ...lineGraphContextInternals, height, width };
  return (
    <svg className="graph-container" width="100%" height="100%">
      <g transform={`translate(${GRAPH_MARGIN.LEFT},${GRAPH_MARGIN.TOP})`}>
        <LineGraphProvider value={graphContextValue}>
          <Line
            dataKey="segmentDiff"
            data={outerHighlightLineData}
            dateColumn={dateColumn}
            styleOptions={{ color: HIGH_TEMPERATURE_OVERLAY_BASE, strokeWidth: 10 }}
          />
          <Line
            dataKey="segmentActual"
            data={data}
            dateColumn={dateColumn}
            styleOptions={{ color: ACTUALS_LINE_COLOR, strokeOpacity: STROKE_OPACITY }}
          />
          {comparisons.map(comparison => (
            <Line
              key={comparison.color}
              dataKey={comparison.columnLabel}
              data={comparison.checked ? data : null} // Supports "hiding" animation
              dateColumn={dateColumn}
              styleOptions={{ color: comparison.color, strokeWidth: 2 }}
            />
          ))}
          <Line
            dataKey="segmentPredicted"
            data={data}
            dateColumn={dateColumn}
            styleOptions={{ color: EXPECTED_LINE_COLOR, dashed: true, strokeOpacity: STROKE_OPACITY }}
          />
          <Line
            dataKey="segmentDiff"
            data={anomalousHighlightLineData}
            dateColumn={dateColumn}
            styleOptions={{ color: ERROR, dashed: true, strokeOpacity: 0.9 }}
          />
          <XAxis ticks={5} disableAnimation />
          <YAxis label={yAxisLabel} ticks={7} />

          {/* Creates endcaps on the anomalous line */}
          <Point yValue={segmentActual} date={anomalousDate} />
          <Point yValue={segmentPredicted} date={anomalousDate} />
        </LineGraphProvider>
      </g>
    </svg>
  );
};

export default AnomalyLineGraph;
