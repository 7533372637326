/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Copyright from './Copyright';
import Header from '../header/header';
import axios from '../../utils/axios-client';
import { KA_API_ROOT_URL } from '../../config/baseUrl';
import { PasswordRuleList } from '../forms/GenericFormComponents';
import { PasswordPolicy } from './password-policy';
import { baseProfitRoverRules } from './password-rules';
import { ProfitRoverLogoIcon } from '../generic/ProfitRoverLogos';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import './auth.css';

const passwordPolicy = new PasswordPolicy(baseProfitRoverRules);

const CreatePassword = () => {
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [passwordIsAllowed, setPasswordIsAllowed] = React.useState(false);
  const [error, setError] = React.useState(null);

  const history = useHistory();
  const location = useLocation();

  const submitResetPassword = async e => {
    e.preventDefault();

    const urlParams = new URLSearchParams(location.search);
    const jwt = urlParams.get('jwt');

    const data = {
      confirmPassword,
      jwt,
    };

    try {
      await axios.put(`${KA_API_ROOT_URL}/resetforgottenpassword`, { data });
      history.push('/login');
    } catch {
      setError('Password creation has failed');
    }
  };

  const newPasswordChangeHandler = e => {
    if (passwordPolicy.checkPassword(e.target.value)) {
      setPasswordIsAllowed(true);
    } else {
      setPasswordIsAllowed(false);
    }

    setNewPassword(e.target.value);
  };

  const confirmPasswordChangeHandler = e => {
    setConfirmPassword(e.target.value);
  };

  const providedPasswordIsValid = () => {
    const passwordMatch = newPassword === confirmPassword;
    return passwordMatch && passwordIsAllowed;
  };

  const redirectVar = null;

  return (
    <>
      <Header />
      {redirectVar}
      <div style={{ paddingTop: '100px', textAlign: 'center' }}>
        <ProfitRoverLogoIcon className="forgot-page-profitrover-logo" />

        <div id="forgot">
          <h1 className="h3 mb-3 font-weight-normal" style={{ margin: '30px' }}>
            Welcome to ProfitRover! Please create your password.
          </h1>
          <form className="form-signin">
            <label htmlFor="newPassword" className="sr-only">
              New Password
            </label>
            <input
              type="Password"
              id="newPassword"
              className="form-control"
              placeholder="New Password"
              required=""
              onChange={newPasswordChangeHandler}
            />
            <label htmlFor="newPassword" className="sr-only">
              Confirm Password
            </label>
            <input
              type="Password"
              id="confirmPassword"
              className="form-control"
              placeholder="Confirm Password"
              required=""
              onChange={confirmPasswordChangeHandler}
            />
            <ProfitRoverPrimaryButton type="submit" disabled={!providedPasswordIsValid()} onClick={submitResetPassword}>
              Submit
            </ProfitRoverPrimaryButton>

            {error && <div className="warning pt-3">{error}</div>}

            <div style={{ marginTop: '1rem', fontSize: '20px', textAlign: 'left' }}>
              Password policy:
              <PasswordRuleList rules={passwordPolicy.rules} />
            </div>
            <p className="mt-5 mb-3 text-muted" style={{ textAlign: 'center' }}>
              <Copyright />
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreatePassword;
