import classnames from 'classnames';
import React from 'react';
import { Tab } from 'react-bootstrap';
import './profitrover-tab-content.scss';

/**
 * Provides helpful default styling of the various React Bootstrap Tabbed Nav components
 */
const ProfitRoverTabContent = props => (
  <Tab.Content {...props} className={classnames('profitrover-tab-content', props.className)} />
);

export default ProfitRoverTabContent;
