import React, { useRef } from 'react';
import * as d3 from 'd3';
import { compactFormatNumber } from '../../../../util/format';
import { LIGHT_BLUE, FONT_GRAY, FONT_BLACK } from '../../../../../colors';

const MARGIN = {
  TOP: 20,
  RIGHT: 60,
  LEFT: 20,
  BOTTOM: 30,
};

const BAR_RADIUS = 3;

const HorizontalBarPlot = ({ data, width, height, formatAsCurrency = false, currencySymbol = '' }) => {
  const svgRef = useRef();

  React.useLayoutEffect(() => {
    const svg = d3.select(svgRef.current);
    const innerWidth = width - MARGIN.LEFT - MARGIN.RIGHT;
    const innerHeight = height - MARGIN.TOP - MARGIN.BOTTOM;

    // Clear previous plot
    svg.selectAll('*').remove();

    // Create a diagonal hatch pattern
    const defs = svg.append('defs');

    defs
      .append('pattern')
      .attr('id', 'diagonal-hatch')
      .attr('width', 6)
      .attr('height', 6)
      .attr('patternTransform', 'rotate(45 0 0)')
      .attr('patternUnits', 'userSpaceOnUse')
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', 10)
      .attr('stroke', LIGHT_BLUE)
      .attr('stroke-width', 6);

    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .range([0, innerWidth]);

    const yScale = d3
      .scaleBand()
      .domain(data.map(d => d.label))
      .range([0, innerHeight])
      .padding(0.2);

    const g = svg.append('g').attr('transform', `translate(${MARGIN.LEFT},${MARGIN.TOP})`);

    if (data.some(d => d.value > 0)) {
      g.append('line')
        .attr('x1', xScale(0))
        .attr('y1', 0)
        .attr('x2', xScale(0))
        .attr('y2', innerHeight)
        .style('stroke', FONT_GRAY)
        .style('stroke-width', 1);
    }

    // Draw a vertical dotted line for categories with "indicator: true"
    const indicatorCategories = data.filter(d => d.indicator);

    g.selectAll('.indicator-line')
      .data(indicatorCategories)
      .enter()
      .append('line')
      .attr('class', 'indicator-line')
      .attr('x1', d => xScale(d.value))
      .attr('y1', 0)
      .attr('x2', d => xScale(d.value))
      .attr('y2', innerHeight)
      .style('stroke', FONT_GRAY)
      .style('stroke-dasharray', '2,2'); // Add the dotted line style

    // Create bars and add numbers or "No data" text
    const bars = g
      .selectAll('g.bar-group')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'bar-group')
      .attr('transform', d => `translate(0, ${yScale(d.label)})`);

    bars.each(function handleData(d) {
      const group = d3.select(this);
      if (d.value === 0) {
        group
          .append('text')
          .attr('x', innerWidth / 2)
          .attr('y', yScale.bandwidth() / 2)
          .attr('dy', '0.35em')
          .style('text-anchor', 'middle')
          .text('No data for this period')
          .style('fill', FONT_BLACK)
          .style('font-family', 'URWDIN')
          .style('font-weight', '400')
          .style('font-size', '13px');
      } else {
        group
          .append('rect')
          .attr('width', xScale(d.value))
          .attr('height', yScale.bandwidth())
          .attr('rx', BAR_RADIUS)
          .attr('ry', BAR_RADIUS)
          .style('fill', value => (value.hash ? 'url(#diagonal-hatch)' : LIGHT_BLUE));

        group
          .append('text')
          .attr('x', xScale(d.value) + 5)
          .attr('y', yScale.bandwidth() / 2)
          .attr('dy', '0.35em')
          .style('text-anchor', 'start')
          .text(compactFormatNumber(d.value, { fixedDecimalDigits: 1, formatAsCurrency, currencySymbol }))
          .style('fill', FONT_BLACK)
          .style('font-family', 'URWDIN')
          .style('font-weight', '400')
          .style('font-size', '12px');
      }
    });
  }, [data, width, height, currencySymbol, formatAsCurrency]);

  return (
    <svg ref={svgRef} width={width} height={height}>
      <defs>
        <pattern
          id="diagonal-hatch"
          width="4"
          height="4"
          patternTransform="rotate(45 0 0)"
          patternUnits="userSpaceOnUse"
        >
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: LIGHT_BLUE, strokeWidth: 2 }} />
        </pattern>
      </defs>
    </svg>
  );
};

export default HorizontalBarPlot;
