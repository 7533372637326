import React from 'react';
import UserManagementModal from './UserManagementModal';
import UserTable from './UserTable';
import NAVIGATION_SEPARATOR from '../settingsNavigationMenuConstants';
import SettingsNavigationMenu from '../SettingsNavigationMenu';
import SettingsPageWrapper from '../SettingsPageWrapper';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import Header from '../../header/header';
import {
  useCreateNewUser,
  useDeleteUser,
  useResendInvite,
  useUpdateUser,
  useUpdateUserRole,
} from '../../../data-access/mutation/userManagement';
import { useCustomerRoles, useCustomerUsers } from '../../../data-access/query/userManagement';
import {
  gaEmitDeleteUserActionClick,
  gaEmitResendInviteActionClick,
  gaEmitSendInviteActionClick,
  gaEmitUpdateUserRoleActionClick,
} from '../../../google-analytics/userRolesSettings';
import { isValidEmailAddress } from '../../../utils/validators';
import './user-management.scss';

const defaultUserForm = {
  first_name: '',
  last_name: '',
  email_address: '',
  role_id: 'team_member',
};

const UserManagement = () => {
  const [sortObject, setSortObject] = React.useState({
    first_name: {
      is_sorted: false,
      direction: 'ASC',
    },
    last_name: {
      is_sorted: false,
      direction: 'ASC',
    },
    email_address: {
      is_sorted: false,
      direction: 'ASC',
    },
    status: {
      is_sorted: false,
      direction: 'ASC',
    },
  });
  const [currentSortProp, setCurrentSortProp] = React.useState('');
  const [footerOpen, setFooterOpen] = React.useState(false);
  const [updateError, setUpdateError] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [userForm, setUserForm] = React.useState(defaultUserForm);
  const userId = React.useRef(localStorage.getItem('id'));

  const { data: users = [], isLoading: isLoadingUsers, isFetching: isFetchingUsers } = useCustomerUsers();
  const { data: roles = [], isLoading: isLoadingRoles } = useCustomerRoles();
  const { mutate: createNewUser, isLoading: createUserLoading } = useCreateNewUser({
    onSuccess: () => {
      setFooterOpen(false);
      setUpdateError(null);
      setUserForm(defaultUserForm);
    },
    onError: error => {
      if (error.message === 'Invalid email') {
        setUpdateError('Email is already in use or invalid.');
      } else {
        setUpdateError('Failed to create new user. Please try again later.');
      }
    },
  });
  const { mutate: updateUser, isLoading: userUpdateLoading } = useUpdateUser({
    onSuccess: () => {
      setUpdateError(null);
    },
    onError: () => {
      setUpdateError('Email already in use or invalid.');
    },
  });
  const { mutate: deleteUser, isLoading: deleteUserLoading } = useDeleteUser({
    onSuccess: () => {
      setUpdateError(null);
    },
    onError: () => {
      setUpdateError('Failed to delete user. Please try again later.');
    },
  });
  const { mutate: resendInvite, isLoading: resendInviteLoading } = useResendInvite({
    onSuccess: () => {
      setUpdateError(null);
      setSuccessMessage('A new invitation has been sent to complete the account setup process.');
    },
    onError: () => {
      setUpdateError('Failed to send invite. Please try again later.');
    },
  });
  const { mutate: updateUserRole, isLoading: updateUserRoleLoading } = useUpdateUserRole({
    onSuccess: () => {
      setUpdateError(null);
    },
    onError: () => {
      setUpdateError('Failed to update user role. Please try again later.');
    },
  });

  const onResendInvite = id => {
    gaEmitResendInviteActionClick();
    resendInvite(id);
  };

  const onDeleteUser = id => {
    gaEmitDeleteUserActionClick();
    deleteUser(id);
  };

  const onUpdateUserRole = (id, data) => {
    gaEmitUpdateUserRoleActionClick();
    updateUserRole(id, data);
  };

  const sortedData = React.useMemo(() => {
    const { [currentSortProp]: currentSort } = sortObject;

    if (!currentSort) {
      return users;
    }

    const direction = currentSort.direction === 'ASC' ? 1 : -1;

    return users.sort((a, b) => {
      if (a[currentSortProp].toLowerCase() < b[currentSortProp].toLowerCase()) {
        return -1 * direction;
      }

      if (a[currentSortProp].toLowerCase() > b[currentSortProp].toLowerCase()) {
        return 1 * direction;
      }

      return 0;
    });
  }, [sortObject, currentSortProp, users]);
  const roleOptions = React.useMemo(() => [...roles, { role_id: 'team_member', description: 'Team Member' }], [roles]);

  const calculateSortData = id => {
    const newSortObject = { ...sortObject };
    if (currentSortProp !== '' && currentSortProp !== id) {
      newSortObject[currentSortProp].is_sorted = false;
    }
    if (sortObject[id].is_sorted === false) {
      newSortObject[id] = {
        is_sorted: true,
        direction: 'ASC',
      };
    } else {
      newSortObject[id] = {
        is_sorted: true,
        direction: newSortObject[id].direction === 'ASC' ? 'DESC' : 'ASC',
      };
    }

    setSortObject(newSortObject);
    setCurrentSortProp(id);
  };

  const onAddUserClick = () => {
    setFooterOpen(true);
  };

  const handleNewUserChange = async e => {
    if (e) {
      e.preventDefault();

      setUserForm({
        ...userForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onCreateNewUser = () => {
    gaEmitSendInviteActionClick();
    let { role_id: roleId } = userForm;
    roleId = roleId === 'team_member' ? null : parseInt(roleId, 10);

    createNewUser({
      ...userForm,
      role_id: roleId,
    });
  };

  const cancelNewUser = () => {
    setUserForm({
      first_name: '',
      last_name: '',
      email_address: '',
      role_id: 'team_member',
    });
    setFooterOpen(false);
  };

  const hideErrorModal = () => {
    setUpdateError(null);
  };

  const hideSuccessModal = () => {
    setSuccessMessage(null);
  };

  const updateDisabled = createUserLoading || deleteUserLoading || resendInviteLoading || isFetchingUsers;

  const newUserComplete =
    userForm.first_name.length > 0 &&
    userForm.last_name.length > 0 &&
    userForm.email_address.length > 0 &&
    isValidEmailAddress(userForm.email_address);

  return (
    <HeaderAwarePage>
      <Header />
      <SettingsNavigationMenu label={`Settings ${NAVIGATION_SEPARATOR} Users & Roles`} tabsId={['settings']} />
      <SettingsPageWrapper>
        <div className="container">
          <div className="user-management-container">
            <UserManagementModal
              show={updateError != null && updateError.length > 0}
              onHide={hideErrorModal}
              message={updateError}
              title="Error"
            />
            <UserManagementModal
              show={successMessage != null && successMessage.length > 0}
              onHide={hideSuccessModal}
              message={successMessage}
              title="Invitation sent!"
            />
            <h3 className="settings-header">Users & Roles</h3>
            <UserTable
              data={sortedData}
              userId={userId}
              roleOptions={roleOptions}
              newRoleValue={userForm.role_id}
              dataAvailable={isLoadingUsers || isLoadingRoles}
              sortClick={calculateSortData}
              sortObject={sortObject}
              createNewUser={onCreateNewUser}
              footerOpen={footerOpen}
              handleNewUserChange={handleNewUserChange}
              deleteUser={onDeleteUser}
              updateUser={updateUser}
              userUpdateLoading={userUpdateLoading}
              updateUserRole={onUpdateUserRole}
              updateUserRoleLoading={updateUserRoleLoading}
              resendInvite={onResendInvite}
              cancelNewUser={cancelNewUser}
              updateDisabled={updateDisabled}
              setUpdateError={setUpdateError}
              newUserComplete={newUserComplete}
            />
            <div className="d-flex mt-3 save-btn-container">
              <ProfitRoverPrimaryButton onClick={onAddUserClick} disabled={footerOpen}>
                Add User
              </ProfitRoverPrimaryButton>
            </div>
          </div>
        </div>
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

export default UserManagement;
