import camelcase from 'camelcase';
import { format, parse } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import {
  ACTUAL_INTERVAL,
  DEMAND_METRIC,
  GROSS_SALES_PLUS_TIPS_METRIC,
  INCOME_METRIC,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_WEEK,
  PRIOR_INTERVAL,
  PERIOD_OPTIONS,
  STLY_INTERVAL,
  TIPS_METRIC,
  YESTERDAY,
  PERIOD_TO_INTERVAL_LABEL,
} from './atAGlanceTabConstants';
import AtAGlanceTabContext from './atAGlanceTabContext';
import { FULL_DATE_FORMAT } from './revenueUpliftConstants';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import { compactFormatNumber } from '../../../util/format';
import { HOUR_DIMENSION_TYPE } from '../../../workflow/workflowConstants';
import { ReactComponent as LowGaugeSVG } from '../../../../images/gauges/low-gauge.svg';
import { ReactComponent as MediumGaugeSVG } from '../../../../images/gauges/med-gauge.svg';
import { ReactComponent as HighGaugeSVG } from '../../../../images/gauges/high-gauge.svg';
// Unused but kept around in case it's needed in the future
// import { ReactComponent as NoneGaugeSVG } from '../../../../images/gauges/none-gauge.svg';

export const GaugeLevel = {
  NONE: 'NONE',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

export const GaugeLevelToIcon = {
  [GaugeLevel.NONE]: null,
  [GaugeLevel.LOW]: <LowGaugeSVG />,
  [GaugeLevel.MEDIUM]: <MediumGaugeSVG />,
  [GaugeLevel.HIGH]: <HighGaugeSVG />,
};

export const bucket = (key, quantity, percentiles) => {
  if (quantity < 0.5 || !percentiles.medium || !percentiles.high) {
    return GaugeLevel.NONE;
  }
  if (quantity > 0 && quantity < percentiles.medium[key]) {
    return GaugeLevel.LOW;
  }
  if (quantity <= percentiles.high[key]) {
    return GaugeLevel.MEDIUM;
  }
  if (quantity >= percentiles.high[key]) {
    return GaugeLevel.HIGH;
  }

  return GaugeLevel.NONE;
};

const HIDE_CENTS_CUTOFF = 10000;

export const formatNumber = (number, { currencySymbol, hideCents } = {}) => {
  let formattedNumber;

  if (Number.isFinite(number)) {
    if (number <= HIDE_CENTS_CUTOFF) {
      // Potentially hide cents based on options passed at callsite
      formattedNumber = new Intl.NumberFormat('en', {
        minimumFractionDigits: hideCents ? 0 : 2,
        maximumFractionDigits: hideCents ? 0 : 2,
      }).format(number);
    } else {
      // Don't show cents at all
      formattedNumber = new Intl.NumberFormat('en', { maximumFractionDigits: 0 }).format(number);
    }
  } else {
    // A non-finite number was provided
    if (Number.isNaN(number) || number == null) {
      return '-';
    }

    return '∞';
  }

  if (currencySymbol) {
    formattedNumber = `${currencySymbol}${formattedNumber}`;
  }

  return formattedNumber;
};

export const usePercentiles = (aggregatedHistoryRows, historySummaryStatsRows) => {
  const { isPriceOptimizationProfit } = React.useContext(DomainContext);

  const percentiles = React.useMemo(() => {
    const incomeMetricKey = isPriceOptimizationProfit ? 'profit' : 'revenue';
    const incomeMediumPercentiles = {};
    const incomeHighPercentiles = {};
    const demandMediumPercentiles = {};
    const demandHighPercentiles = {};

    aggregatedHistoryRows.forEach(row => {
      const incomeMediumKey = `${incomeMetricKey}33`;
      const incomeHighKey = `${incomeMetricKey}67`;
      const demandMediumKey = 'demand33';
      const demandHighKey = 'demand67';
      const {
        productId,
        [incomeMediumKey]: incomeMedium,
        [incomeHighKey]: incomeHigh,
        [demandMediumKey]: demandMedium,
        [demandHighKey]: demandHigh,
      } = row;

      incomeMediumPercentiles[productId] = incomeMedium;
      incomeHighPercentiles[productId] = incomeHigh;
      demandMediumPercentiles[productId] = demandMedium;
      demandHighPercentiles[productId] = demandHigh;
    });

    const gratuityMediumPercentiles = {};
    const gratuityHighPercentiles = {};
    historySummaryStatsRows.forEach(row => {
      const { productId, gratuity33: gratuityMedium, gratuity67: gratuityHigh } = row;

      gratuityMediumPercentiles[productId] = gratuityMedium;
      gratuityHighPercentiles[productId] = gratuityHigh;
    });

    const incomeMediumPercentile = _.sum(Object.values(incomeMediumPercentiles)) ?? 0;
    const incomeHighPercentile = _.sum(Object.values(incomeHighPercentiles)) ?? 0;
    const demandMediumPercentile = _.sum(Object.values(demandMediumPercentiles)) ?? 0;
    const demandHighPercentile = _.sum(Object.values(demandHighPercentiles)) ?? 0;
    const gratuityMediumPercentile = _.sum(Object.values(gratuityMediumPercentiles)) ?? 0;
    const gratuityHighPercentile = _.sum(Object.values(gratuityHighPercentiles)) ?? 0;

    return {
      medium: {
        [incomeMetricKey]: incomeMediumPercentile,
        demand: demandMediumPercentile,
        tips: gratuityMediumPercentile,
        revenuePlusTips: gratuityMediumPercentile + incomeMediumPercentile,
      },
      high: {
        [incomeMetricKey]: incomeHighPercentile,
        demand: demandHighPercentile,
        tips: gratuityHighPercentile,
        revenuePlusTips: gratuityHighPercentile + incomeHighPercentile,
      },
    };
  }, [historySummaryStatsRows, isPriceOptimizationProfit, aggregatedHistoryRows]);

  return percentiles;
};

export const useSevenDayForecast = (sevenDayForecastRows, weeklyForecastStatsRows, percentiles) => {
  const { isPriceOptimizationProfit } = React.useContext(DomainContext);

  return React.useMemo(() => {
    const rowsGroupedByDay = _.groupBy(sevenDayForecastRows, 'tranDate');
    const forecastStatsRowsGroupedByDay = _.groupBy(weeklyForecastStatsRows, 'tranDate');

    const revenueByDay = {};
    const demandByDay = {};
    const tipsByDay = {};
    let totalForecastedRevenue = 0;
    let totalForecastedDemand = 0;
    let totalForecastedTips = 0;

    const currentIncomeMetric = isPriceOptimizationProfit ? 'currentProfit' : 'currentRevenue';

    Object.entries(rowsGroupedByDay).forEach(([day, rows]) => {
      const revSum = _.sumBy(rows, currentIncomeMetric);
      const demSum = _.sumBy(rows, 'currentDemand');

      revenueByDay[day] = revSum;
      demandByDay[day] = demSum;

      totalForecastedRevenue += revSum;
      totalForecastedDemand += demSum;
    });

    Object.entries(forecastStatsRowsGroupedByDay).forEach(([day, rows]) => {
      const tipSum = _.sumBy(rows, 'prediction');

      tipsByDay[day] = tipSum;

      totalForecastedTips += tipSum;
    });

    const gaugesByDay = {};
    const incomeMetricKey = isPriceOptimizationProfit ? 'profit' : 'revenue';

    Object.keys(revenueByDay).forEach(key => {
      const revenue = revenueByDay[key];
      const demand = demandByDay[key];

      gaugesByDay[key] = {
        revenueGauge: bucket(incomeMetricKey, revenue, percentiles),
        demandGauge: bucket('demand', demand, percentiles),
      };
    });

    const statsGaugesByDay = {};
    Object.keys(tipsByDay).forEach(key => {
      const tips = tipsByDay[key];
      const revenuePlusTips = (revenueByDay[key] ?? 0) + tips;

      statsGaugesByDay[key] = {
        tipsGauge: bucket('tips', tips, percentiles),
        revenuePlusTipsGauge: bucket('revenuePlusTips', revenuePlusTips, percentiles),
      };
    });

    return {
      revenueByDay,
      demandByDay,
      tipsByDay,
      gaugesByDay,
      statsGaugesByDay,
      totalForecastedRevenue,
      totalForecastedDemand,
      totalForecastedTips,
    };
  }, [weeklyForecastStatsRows, sevenDayForecastRows, percentiles, isPriceOptimizationProfit]);
};

const METRIC_TO_OPACITY_LOOKUP_KEYS = {
  [INCOME_METRIC]: ['minRevenue', 'maxRevenue', 'revenueByDayAndHour'],
  [DEMAND_METRIC]: ['minDemand', 'maxDemand', 'demandByDayAndHour'],
  [TIPS_METRIC]: ['minTips', 'maxTips', 'tipsByDayAndHour'],
  [GROSS_SALES_PLUS_TIPS_METRIC]: ['minRevenuePlusTips', 'maxRevenuePlusTips', 'revenuePlusTipsByDayAndHour'],
};

export const useHourlyGridData = getDimColumnKey => {
  const { dimensions, isPriceOptimizationProfit } = React.useContext(DomainContext);
  const {
    getDimValueLabel,
    currencySymbol,
    minMaxSevenDayForecastHours,
    minMaxSevenDayForecastStatsHours,
    weeklyForecastRows,
    weeklyForecastStatsRows,
  } = React.useContext(DashboardContext);
  const { selectedMetric } = React.useContext(AtAGlanceTabContext);

  const hourDimId = React.useMemo(
    () => dimensions?.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id,
    [dimensions],
  );

  const valuesByDayAndHour = React.useMemo(() => {
    if (!hourDimId) {
      return {};
    }

    const getGroupKey = row => {
      const { tranDate } = row;
      const hourValueId = row[getDimColumnKey(hourDimId)];
      const hourValue = getDimValueLabel(hourDimId, hourValueId);
      return `${tranDate} ${hourValue}`;
    };

    const rowsGroupedByDayAndHour = _.groupBy(weeklyForecastRows, getGroupKey);
    const forecastStatsRowsGroupedByDayAndHour = _.groupBy(weeklyForecastStatsRows, getGroupKey);

    const revenueByDayAndHour = {};
    const demandByDayAndHour = {};
    const tipsByDayAndHour = {};
    const revenuePlusTipsByDayAndHour = {};

    const incomeMetricKey = isPriceOptimizationProfit ? 'currentProfit' : 'currentRevenue';

    Object.entries(rowsGroupedByDayAndHour).forEach(([dayAndHour, rows]) => {
      const revSum = _.sumBy(rows, incomeMetricKey);
      const demSum = _.sumBy(rows, 'currentDemand');

      revenueByDayAndHour[dayAndHour] = revSum;
      revenuePlusTipsByDayAndHour[dayAndHour] = revSum;
      demandByDayAndHour[dayAndHour] = demSum;
    });
    Object.entries(forecastStatsRowsGroupedByDayAndHour).forEach(([dayAndHour, rows]) => {
      const tipSum = _.sumBy(rows, 'prediction');
      tipsByDayAndHour[dayAndHour] = tipSum;

      const keyExist = dayAndHour in revenuePlusTipsByDayAndHour;
      if (!keyExist) {
        revenuePlusTipsByDayAndHour[dayAndHour] = tipSum;
      } else {
        revenuePlusTipsByDayAndHour[dayAndHour] += tipSum;
      }
    });

    return { revenueByDayAndHour, demandByDayAndHour, tipsByDayAndHour, revenuePlusTipsByDayAndHour };
  }, [
    weeklyForecastRows,
    weeklyForecastStatsRows,
    hourDimId,
    getDimColumnKey,
    getDimValueLabel,
    isPriceOptimizationProfit,
  ]);

  const hourRanges = React.useMemo(() => {
    if (!hourDimId) {
      return {};
    }

    const getRange = (minHour, maxHour) => {
      if (!minHour || !maxHour) {
        return [];
      }

      const minHourInt = parseInt(minHour.split(':')[0], 10);
      const maxHourInt = parseInt(maxHour.split(':')[0], 10);

      return _.range(minHourInt, maxHourInt + 1).map(hour => {
        const hourString = hour.toString().padStart(2, '0');
        return `${hourString}:00`; // 09:00
      });
    };

    const combineRanges = (range1, range2) => {
      if (range1.length === 0 && range2.length > 0) {
        return range2;
      }
      if (range2.length === 0 && range1.length > 0) {
        return range1;
      }
      if (range1.length === 0 && range2.length === 0) {
        return [];
      }

      const minHour1 = parseInt(range1[0].split(':')[0], 10);
      const maxHour1 = parseInt(range1[range1.length - 1].split(':')[0], 10);
      const minHour2 = parseInt(range2[0].split(':')[0], 10);
      const maxHour2 = parseInt(range2[range2.length - 1].split(':')[0], 10);

      const minHour = Math.min(minHour1, minHour2);
      const maxHour = Math.max(maxHour1, maxHour2);

      return _.range(minHour, maxHour + 1).map(hour => {
        const hourString = hour.toString().padStart(2, '0');
        return `${hourString}:00`; // 09:00
      });
    };

    const priceHourRange = getRange(minMaxSevenDayForecastHours[0], minMaxSevenDayForecastHours[1]);
    const statsHourRange = getRange(minMaxSevenDayForecastStatsHours[0], minMaxSevenDayForecastStatsHours[1]);
    const priceAndStatsHourRange = combineRanges(priceHourRange, statsHourRange);

    return { priceHourRange, statsHourRange, priceAndStatsHourRange };
  }, [minMaxSevenDayForecastHours, minMaxSevenDayForecastStatsHours, hourDimId]);

  const minMaxValues = React.useMemo(() => {
    if (!hourDimId) {
      return {};
    }

    const maxRevenue = _.max(Object.values(valuesByDayAndHour.revenueByDayAndHour)) ?? 0;
    const maxDemand = _.max(Object.values(valuesByDayAndHour.demandByDayAndHour)) ?? 0;
    const maxTips = _.max(Object.values(valuesByDayAndHour.tipsByDayAndHour)) ?? 0;
    const minRevenue = _.min(Object.values(valuesByDayAndHour.revenueByDayAndHour)) ?? 0;
    const minDemand = _.min(Object.values(valuesByDayAndHour.demandByDayAndHour)) ?? 0;
    const minTips = _.min(Object.values(valuesByDayAndHour.tipsByDayAndHour)) ?? 0;
    const maxRevenuePlusTips = _.max(Object.values(valuesByDayAndHour.revenuePlusTipsByDayAndHour)) ?? 0;
    const minRevenuePlusTips = _.min(Object.values(valuesByDayAndHour.revenuePlusTipsByDayAndHour)) ?? 0;

    return {
      maxRevenue,
      maxDemand,
      maxTips,
      minRevenue,
      minDemand,
      minTips,
      maxRevenuePlusTips,
      minRevenuePlusTips,
    };
  }, [valuesByDayAndHour, hourDimId]);

  const getOpacityByDayAndHour = React.useCallback(
    (day, hour) => {
      if (!hourDimId) {
        return 0;
      }

      const computeOpacity = (minKey, maxKey, metricValuesKey) => {
        const [min, max] = [minMaxValues[minKey], minMaxValues[maxKey]];
        if (min === max) {
          return 0;
        }

        const dayAndHour = `${day} ${hour}`;
        const value = valuesByDayAndHour[metricValuesKey][dayAndHour] ?? 0;
        const position = value - min;
        const range = max - min;

        return position / range;
      };

      const metric = selectedMetric.value;
      if (!(metric in METRIC_TO_OPACITY_LOOKUP_KEYS)) {
        return 0;
      }

      return computeOpacity(...METRIC_TO_OPACITY_LOOKUP_KEYS[metric]);
    },
    [selectedMetric, valuesByDayAndHour, minMaxValues, hourDimId],
  );

  const getValueByDayAndHour = React.useCallback(
    (day, hour) => {
      if (!hourDimId) {
        return '-';
      }

      const dayAndHour = `${day} ${hour}`;
      switch (selectedMetric.value) {
        case INCOME_METRIC:
          return compactFormatNumber(valuesByDayAndHour.revenueByDayAndHour[dayAndHour] ?? 0, {
            formatAsCurrency: true,
            currencySymbol,
          });
        case DEMAND_METRIC:
          return compactFormatNumber(valuesByDayAndHour.demandByDayAndHour[dayAndHour] ?? 0);
        case TIPS_METRIC:
          return compactFormatNumber(valuesByDayAndHour.tipsByDayAndHour[dayAndHour] ?? 0, {
            formatAsCurrency: true,
            currencySymbol,
          });
        case GROSS_SALES_PLUS_TIPS_METRIC:
          return compactFormatNumber(
            (valuesByDayAndHour.revenueByDayAndHour[dayAndHour] ?? 0) +
              (valuesByDayAndHour.tipsByDayAndHour[dayAndHour] ?? 0),
            {
              formatAsCurrency: true,
              currencySymbol,
            },
          );
        default:
          return 0;
      }
    },
    [selectedMetric, valuesByDayAndHour, currencySymbol, hourDimId],
  );

  const currentRange = React.useMemo(() => {
    if (!hourDimId) {
      return [];
    }

    switch (selectedMetric.value) {
      case INCOME_METRIC:
      case DEMAND_METRIC:
        return hourRanges.priceHourRange;
      case TIPS_METRIC:
        return hourRanges.statsHourRange;
      case GROSS_SALES_PLUS_TIPS_METRIC:
        return hourRanges.priceAndStatsHourRange;
      default:
        return [];
    }
  }, [selectedMetric, hourRanges, hourDimId]);

  if (!hourDimId) {
    return {};
  }

  return {
    range: currentRange,
    getValueByDayAndHour,
    getOpacityByDayAndHour,
  };
};

// Returns the day of the week (e.g. 'Monday')
const dateToDayOfWeek = date => format(date, 'EEEE');

export const usePerformanceReview = () => {
  const {
    paceHistoryRows,
    historySummaryStatsRows,
    minAndMaxStatisticHistoryDates,
    forecastStatsMinAndMaxHistoryDates,
  } = React.useContext(DashboardContext);
  const { isPriceOptimizationProfit, processedHistorySummaryMeta, forecastStatsHistorySummaryMeta } = React.useContext(
    DomainContext,
  );
  const { selectedMetric } = React.useContext(AtAGlanceTabContext);

  const [selectedPeriod, setSelectedPeriod] = React.useState(PERIOD_OPTIONS[0]);
  const [selectedByUser, setSelectedByUser] = React.useState(false);
  const lastRefreshed = React.useMemo(() => {
    const { as_of_date: asOfDate } =
      selectedMetric.value === TIPS_METRIC ? forecastStatsHistorySummaryMeta : processedHistorySummaryMeta;

    return asOfDate;
  }, [selectedMetric, forecastStatsHistorySummaryMeta, processedHistorySummaryMeta]);

  const comparisonIntervals = React.useMemo(() => {
    const { relative_dates: relativeDates } =
      selectedMetric.value === TIPS_METRIC ? forecastStatsHistorySummaryMeta : processedHistorySummaryMeta;

    if (selectedPeriod.value === YESTERDAY) {
      const { yesterday, sdlw_yesterday: sdlwYesterday, sdly_yesterday: sdlyYesterday } = relativeDates;

      return [
        {
          key: ACTUAL_INTERVAL,
          startDate: yesterday,
          endDate: yesterday,
        },
        {
          key: PRIOR_INTERVAL,
          startDate: sdlwYesterday,
          endDate: sdlwYesterday,
        },
        {
          key: STLY_INTERVAL,
          startDate: sdlyYesterday,
          endDate: sdlyYesterday,
        },
      ];
    }
    if (selectedPeriod.value === LAST_WEEK) {
      const {
        week_last_start: weekStart,
        week_last_end: weekEnd,
        week_prior_start: priorWeekStart,
        week_prior_end: priorWeekEnd,
        stly_week_last_start: stlyWeekStart,
        stly_week_last_end: stlyWeekEnd,
      } = relativeDates;

      return [
        {
          key: ACTUAL_INTERVAL,
          startDate: weekStart,
          endDate: weekEnd,
        },
        {
          key: PRIOR_INTERVAL,
          startDate: priorWeekStart,
          endDate: priorWeekEnd,
        },
        {
          key: STLY_INTERVAL,
          startDate: stlyWeekStart,
          endDate: stlyWeekEnd,
        },
      ];
    }
    if (selectedPeriod.value === LAST_MONTH) {
      const {
        month_last_start: monthStart,
        month_last_end: monthEnd,
        month_prior_start: priorMonthStart,
        month_prior_end: priorMonthEnd,
        stly_month_last_start: stlyMonthStart,
        stly_month_last_end: stlyMonthEnd,
      } = relativeDates;

      return [
        {
          key: ACTUAL_INTERVAL,
          startDate: monthStart,
          endDate: monthEnd,
        },
        {
          key: PRIOR_INTERVAL,
          startDate: priorMonthStart,
          endDate: priorMonthEnd,
        },
        {
          key: STLY_INTERVAL,
          startDate: stlyMonthStart,
          endDate: stlyMonthEnd,
        },
      ];
    }

    const {
      quarter_last_start: quarterStart,
      quarter_last_end: quarterEnd,
      quarter_prior_start: priorQuarterStart,
      quarter_prior_end: priorQuarterEnd,
      stly_quarter_last_start: stlyQuarterStart,
      stly_quarter_last_end: stlyQuarterEnd,
    } = relativeDates;

    return [
      {
        key: ACTUAL_INTERVAL,
        startDate: quarterStart,
        endDate: quarterEnd,
      },
      {
        key: PRIOR_INTERVAL,
        startDate: priorQuarterStart,
        endDate: priorQuarterEnd,
      },
      {
        key: STLY_INTERVAL,
        startDate: stlyQuarterStart,
        endDate: stlyQuarterEnd,
      },
    ];
  }, [selectedPeriod, selectedMetric, processedHistorySummaryMeta, forecastStatsHistorySummaryMeta]);

  const incomeMetricKey = isPriceOptimizationProfit ? 'profit' : 'revenue';

  const values = React.useMemo(() => {
    const currentValues = {
      [ACTUAL_INTERVAL]: { [incomeMetricKey]: 0, demand: 0, tips: 0 },
      [PRIOR_INTERVAL]: { [incomeMetricKey]: 0, demand: 0, tips: 0 },
      [STLY_INTERVAL]: { [incomeMetricKey]: 0, demand: 0, tips: 0 },
    };

    paceHistoryRows.forEach(row => {
      comparisonIntervals.forEach(({ key }) => {
        const demandKey = camelcase(
          `${selectedPeriod.value}_${selectedPeriod.value === YESTERDAY ? key.replace('last', '') : key}_demand`,
        );
        const incomeKey = camelcase(
          `${selectedPeriod.value}_${
            selectedPeriod.value === YESTERDAY ? key.replace('last', '') : key
          }_${incomeMetricKey}`,
        );

        currentValues[key][incomeMetricKey] += row[incomeKey];
        currentValues[key].demand += row[demandKey];
      });
    });

    historySummaryStatsRows.forEach(row => {
      comparisonIntervals.forEach(({ key }) => {
        const gratuityKey = camelcase(
          `${selectedPeriod.value}_${selectedPeriod.value === YESTERDAY ? key.replace('last', '') : key}_gratuity`,
        );

        currentValues[key].tips += row[gratuityKey];
      });
    });

    return currentValues;
  }, [comparisonIntervals, paceHistoryRows, historySummaryStatsRows, incomeMetricKey, selectedPeriod]);

  const performanceReviewData = React.useMemo(() => {
    const [minHistoryDate, maxHistoryDate] = minAndMaxStatisticHistoryDates;
    const [minStatsHistoryDate, maxStatsHistoryDate] = forecastStatsMinAndMaxHistoryDates;

    // If range1 is fully contained in range2, returns range 1
    // If range1 is fully outside of range2, returns null
    // If range1 is partially contained in range2, returns the intersection
    const getIntersectionRange = (range1, range2) => {
      if (range1.length < 2 || range2.length < 2 || !range2[0] || !range2[1]) {
        return null;
      }
      if (range1[0] >= range2[0] && range1[1] <= range2[1]) {
        return range1;
      }
      if (range1[0] < range2[0] && range1[1] > range2[1]) {
        return range2;
      }
      if (range1[0] >= range2[0] && range1[0] <= range2[1]) {
        return [range1[0], range2[1]];
      }
      if (range1[1] >= range2[0] && range1[1] <= range2[1]) {
        return [range2[0], range1[1]];
      }
      return null;
    };

    const parseRange = range => {
      const startDate = parse(range[0], FULL_DATE_FORMAT, new Date());
      const endDate = parse(range[1], FULL_DATE_FORMAT, new Date());
      if (range[0] === range[1]) {
        return format(startDate, 'MMM d, yyyy');
      }
      return `${format(startDate, 'MMM d, yyyy')} - ${format(endDate, 'MMM d, yyyy')}`;
    };

    const getValuesByMetric = () => {
      if (selectedMetric.value === INCOME_METRIC) {
        return {
          actual: values[ACTUAL_INTERVAL][incomeMetricKey],
          prior: values[PRIOR_INTERVAL][incomeMetricKey],
          stly: values[STLY_INTERVAL][incomeMetricKey],
        };
      }

      if (selectedMetric.value === DEMAND_METRIC) {
        return {
          actual: values[ACTUAL_INTERVAL].demand,
          prior: values[PRIOR_INTERVAL].demand,
          stly: values[STLY_INTERVAL].demand,
        };
      }

      if (selectedMetric.value === TIPS_METRIC) {
        return {
          actual: values[ACTUAL_INTERVAL].tips,
          prior: values[PRIOR_INTERVAL].tips,
          stly: values[STLY_INTERVAL].tips,
        };
      }

      if (selectedMetric.value === GROSS_SALES_PLUS_TIPS_METRIC) {
        return {
          actual: values[ACTUAL_INTERVAL].revenue + values[ACTUAL_INTERVAL].tips,
          prior: values[PRIOR_INTERVAL].revenue + values[PRIOR_INTERVAL].tips,
          stly: values[STLY_INTERVAL].revenue + values[STLY_INTERVAL].tips,
        };
      }

      return {
        actual: 0,
        prior: 0,
        stly: 0,
      };
    };

    const getRangesByMetric = () => {
      const actualInterval = comparisonIntervals.find(period => period.key === ACTUAL_INTERVAL);
      const priorInterval = comparisonIntervals.find(period => period.key === PRIOR_INTERVAL);
      const stlyInterval = comparisonIntervals.find(period => period.key === STLY_INTERVAL);
      const actualIntervalRange = [actualInterval.startDate, actualInterval.endDate];
      const priorIntervalRange = [priorInterval.startDate, priorInterval.endDate];
      const stlyIntervalRange = [stlyInterval.startDate, stlyInterval.endDate];

      if (selectedMetric.value === INCOME_METRIC || selectedMetric.value === DEMAND_METRIC) {
        const actualRange = getIntersectionRange(actualIntervalRange, [minHistoryDate, maxHistoryDate]);
        const priorRange = getIntersectionRange(priorIntervalRange, [minHistoryDate, maxHistoryDate]);
        const stlyRange = getIntersectionRange(stlyIntervalRange, [minHistoryDate, maxHistoryDate]);

        return {
          actual: { period: actualIntervalRange, intersection: actualRange },
          prior: { period: priorIntervalRange, intersection: priorRange },
          stly: { period: stlyIntervalRange, intersection: stlyRange },
        };
      }

      if (selectedMetric.value === TIPS_METRIC) {
        const actualRange = getIntersectionRange(actualIntervalRange, [minStatsHistoryDate, maxStatsHistoryDate]);
        const priorRange = getIntersectionRange(priorIntervalRange, [minStatsHistoryDate, maxStatsHistoryDate]);
        const stlyRange = getIntersectionRange(stlyIntervalRange, [minStatsHistoryDate, maxStatsHistoryDate]);

        return {
          actual: { period: actualIntervalRange, intersection: actualRange },
          prior: { period: priorIntervalRange, intersection: priorRange },
          stly: { period: stlyIntervalRange, intersection: stlyRange },
        };
      }

      if (selectedMetric.value === GROSS_SALES_PLUS_TIPS_METRIC) {
        const minDate = _.min([minHistoryDate, minStatsHistoryDate]);
        const maxDate = _.max([maxHistoryDate, maxStatsHistoryDate]);
        const actualRange = getIntersectionRange(actualIntervalRange, [minDate, maxDate]);
        const priorRange = getIntersectionRange(priorIntervalRange, [minDate, maxDate]);
        const stlyRange = getIntersectionRange(stlyIntervalRange, [minDate, maxDate]);

        return {
          actual: { period: actualIntervalRange, intersection: actualRange },
          prior: { period: priorIntervalRange, intersection: priorRange },
          stly: { period: stlyIntervalRange, intersection: stlyRange },
        };
      }

      return {
        actual: { period: actualIntervalRange, intersection: null },
        prior: { period: priorIntervalRange, intersection: null },
        stly: { period: stlyIntervalRange, intersection: null },
      };
    };

    const getPriorIntervalLabel = () => {
      const priorDayStartDate = comparisonIntervals.find(period => period.key === PRIOR_INTERVAL)?.startDate;
      const priorDayLabel = dateToDayOfWeek(parse(priorDayStartDate, 'yyyy-MM-dd', new Date()));
      switch (selectedPeriod.value) {
        case YESTERDAY:
          return `Prior ${priorDayLabel}`;
        case LAST_WEEK:
          return 'Prior Week';
        case LAST_MONTH:
          return 'Prior Month';
        case LAST_QUARTER:
          return 'Prior Quarter';
        default:
          return '';
      }
    };

    const valuesByMetric = getValuesByMetric();
    const ranges = getRangesByMetric();

    const data = [];
    if (ranges.actual.intersection) {
      data.push({
        label: PERIOD_TO_INTERVAL_LABEL[selectedPeriod.value],
        type: ACTUAL_INTERVAL,
        value: valuesByMetric.actual,
        indicator: true,
        hash: ranges.actual.intersection !== ranges.actual.period,
        range: parseRange(ranges.actual.intersection),
      });
    } else {
      data.push({
        label: PERIOD_TO_INTERVAL_LABEL[selectedPeriod.value],
        type: ACTUAL_INTERVAL,
        value: 0,
        range: parseRange(ranges.actual.period),
      });
    }

    if (ranges.prior.intersection) {
      data.push({
        label: getPriorIntervalLabel(),
        type: PRIOR_INTERVAL,
        value: valuesByMetric.prior,
        hash: ranges.prior.intersection !== ranges.prior.period,
        range: parseRange(ranges.prior.intersection),
      });
    } else {
      data.push({
        label: getPriorIntervalLabel(),
        type: PRIOR_INTERVAL,
        value: 0,
        range: parseRange(ranges.prior.period),
      });
    }

    if (ranges.stly.intersection) {
      data.push({
        label: 'STLY',
        type: STLY_INTERVAL,
        value: valuesByMetric.stly,
        hash: ranges.stly.intersection !== ranges.stly.period,
        range: parseRange(ranges.stly.intersection),
      });
    } else {
      data.push({
        label: 'STLY',
        type: STLY_INTERVAL,
        value: 0,
        range: parseRange(ranges.stly.period),
      });
    }

    return data;
  }, [
    selectedMetric,
    values,
    comparisonIntervals,
    selectedPeriod,
    incomeMetricKey,
    forecastStatsMinAndMaxHistoryDates,
    minAndMaxStatisticHistoryDates,
  ]);

  React.useEffect(() => {
    // Select next period if no available data
    // e.g if yesterday is empty, select last week by default
    const isDataEmpty = performanceReviewData.some(d => d.value === 0 && d.type === ACTUAL_INTERVAL);
    const index = PERIOD_OPTIONS.findIndex(option => option.value === selectedPeriod.value) + 1;

    if (isDataEmpty && !selectedByUser && index < PERIOD_OPTIONS.length) {
      setSelectedPeriod(PERIOD_OPTIONS[index]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performanceReviewData]);

  const actualPeriod = comparisonIntervals.find(period => period.key === ACTUAL_INTERVAL);

  return {
    periodOptions: PERIOD_OPTIONS,
    selectedPeriod,
    setSelectedPeriod: period => {
      setSelectedByUser(true);
      setSelectedPeriod(period);
    },
    startDate: actualPeriod.startDate,
    endDate: actualPeriod.endDate,
    performanceReviewData,
    lastRefreshed,
  };
};

export const usePaceSummary = () => {
  const {
    paceHistoryRows,
    paceForecastRows,
    historySummaryStatsRows,
    forecastStatsSummaryRows,
    minAndMaxStatisticHistoryDates,
    forecastStatsMinAndMaxHistoryDates,
  } = React.useContext(DashboardContext);
  const { isPriceOptimizationProfit, processedHistorySummaryMeta, forecastStatsHistorySummaryMeta } = React.useContext(
    DomainContext,
  );
  const { selectedMetric } = React.useContext(AtAGlanceTabContext);

  const dates = React.useMemo(() => {
    const { relative_dates: relativeDates, as_of_date: asOfDate } =
      selectedMetric.value === TIPS_METRIC ? forecastStatsHistorySummaryMeta : processedHistorySummaryMeta;
    const {
      month_start: monthStart,
      month_end: monthEnd,
      stly_month_start: stlyMonthStart,
      stly_month_end: stlyMonthEnd,
      quarter_start: quarterStart,
      quarter_end: quarterEnd,
      stly_quarter_start: stlyQuarterStart,
      stly_quarter_end: stlyQuarterEnd,
      year_start: yearStart,
      year_end: yearEnd,
      stly_year_start: stlyYearStart,
      stly_year_end: stlyYearEnd,
    } = relativeDates;

    return {
      today: asOfDate,
      periods: [
        {
          key: 'month',
          start: monthStart,
          end: monthEnd,
          stlyStart: stlyMonthStart,
          stlyEnd: stlyMonthEnd,
        },
        {
          key: 'quarter',
          start: quarterStart,
          end: quarterEnd,
          stlyStart: stlyQuarterStart,
          stlyEnd: stlyQuarterEnd,
        },
        {
          key: 'year',
          start: yearStart,
          end: yearEnd,
          stlyStart: stlyYearStart,
          stlyEnd: stlyYearEnd,
        },
      ],
    };
  }, [forecastStatsHistorySummaryMeta, processedHistorySummaryMeta, selectedMetric]);

  const values = React.useMemo(() => {
    const { periods } = dates;

    const revenueData = {
      month: { toDate: 0, fcst: 0, stly: 0 },
      quarter: { toDate: 0, fcst: 0, stly: 0 },
      year: { toDate: 0, fcst: 0, stly: 0 },
    };
    const demandData = {
      month: { toDate: 0, fcst: 0, stly: 0 },
      quarter: { toDate: 0, fcst: 0, stly: 0 },
      year: { toDate: 0, fcst: 0, stly: 0 },
    };
    const tipsData = {
      month: { toDate: 0, fcst: 0, stly: 0 },
      quarter: { toDate: 0, fcst: 0, stly: 0 },
      year: { toDate: 0, fcst: 0, stly: 0 },
    };
    const revPlusTipsData = {
      month: { toDate: 0, fcst: 0, stly: 0 },
      quarter: { toDate: 0, fcst: 0, stly: 0 },
      year: { toDate: 0, fcst: 0, stly: 0 },
    };

    const incomeMetricKey = isPriceOptimizationProfit ? 'profit' : 'revenue';

    paceHistoryRows.forEach(row => {
      periods.forEach(({ key }) => {
        const demandToDateKey = camelcase(`${key}_current_demand`);
        const incomeToDateKey = camelcase(`${key}_current_${incomeMetricKey}`);
        const demandStlyKey = camelcase(`${key}_stly_demand`);
        const incomeStlyKey = camelcase(`${key}_stly_${incomeMetricKey}`);

        revenueData[key].toDate += row[incomeToDateKey];
        revPlusTipsData[key].toDate += row[incomeToDateKey];
        demandData[key].toDate += row[demandToDateKey];
        revenueData[key].stly += row[incomeStlyKey];
        revPlusTipsData[key].stly += row[incomeStlyKey];
        demandData[key].stly += row[demandStlyKey];
      });
    });

    paceForecastRows.forEach(row => {
      periods.forEach(({ key }) => {
        const demandToDateKey = camelcase(`${key}_demand`);
        const incomeToDateKey = camelcase(`${key}_${incomeMetricKey}`);

        revenueData[key].fcst += row[incomeToDateKey];
        revPlusTipsData[key].fcst += row[incomeToDateKey];
        demandData[key].fcst += row[demandToDateKey];
      });
    });

    historySummaryStatsRows.forEach(row => {
      periods.forEach(({ key }) => {
        const gratuityKey = camelcase(`${key}_current_gratuity`);
        const gratuityStlyKey = camelcase(`${key}_stly_gratuity`);

        tipsData[key].toDate += row[gratuityKey];
        revPlusTipsData[key].toDate += row[gratuityKey];
        tipsData[key].stly += row[gratuityStlyKey];
        revPlusTipsData[key].stly += row[gratuityStlyKey];
      });
    });

    forecastStatsSummaryRows.forEach(row => {
      periods.forEach(({ key }) => {
        const gratuityKey = camelcase(`${key}_prediction`);
        const gratuity = row[gratuityKey];

        tipsData[key].fcst += gratuity;
        revPlusTipsData[key].fcst += gratuity;
      });
    });

    return { demandData, revenueData, tipsData, revPlusTipsData };
  }, [
    dates,
    paceHistoryRows,
    paceForecastRows,
    historySummaryStatsRows,
    forecastStatsSummaryRows,
    isPriceOptimizationProfit,
  ]);

  return {
    dates,
    revenue: values.revenueData,
    demand: values.demandData,
    tips: values.tipsData,
    revenuePlusTips: values.revPlusTipsData,
    minMaxHistoryDates: minAndMaxStatisticHistoryDates,
    minMaxStatsHistoryDates: forecastStatsMinAndMaxHistoryDates,
  };
};
