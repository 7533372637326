import * as d3 from 'd3';
import React from 'react';
import { DARK_GREEN, FONT_GRAY, LIGHT_BLUE, LIGHT_COOL_GRAY, MID_GREEN, LIGHT_GREEN } from '../../../../../colors';

const MARGIN = {
  TOP: 10,
  RIGHT: 0,
  LEFT: 60,
  BOTTOM: 10,
};

const BAR_RADIUS = 3;

// TODO: Don't duplicate this and instead extract to a d3 utility module
const SCALE_COLORS = [LIGHT_GREEN, MID_GREEN, DARK_GREEN, LIGHT_BLUE, FONT_GRAY, LIGHT_COOL_GRAY];
const colorScale = d3
  .scaleOrdinal()
  .domain([0, 1, 2, 3, 4, 5])
  .range(SCALE_COLORS);

export const computeBoxColor = rank => colorScale(rank);

const useController = (data, { labelKey, seriesKey, tickFormat, height, width }) => {
  const [yMin, yMax] = d3.extent(data.map(d => d[seriesKey]));
  const xValues = data.map(d => d[labelKey]);

  const xScale = d3
    .scaleBand()
    .domain(xValues)
    .range([MARGIN.LEFT, width - MARGIN.RIGHT])
    .padding(0.4)
    .paddingInner(0.5);

  const yScale = d3
    .scaleLinear()
    .domain([0, yMax])
    .range([height - MARGIN.BOTTOM, MARGIN.TOP])
    .nice();

  const xAxis = d3
    .axisBottom()
    .scale(xScale)
    .tickSize(0)
    .ticks(0)
    .tickFormat(() => null);

  const yAxis = d3
    .axisLeft()
    .scale(yScale)
    .tickSize(0)
    .tickSizeInner(0)
    .ticks(4)
    .tickFormat(tickFormat);

  return {
    xScale,
    xAxis,
    yScale,
    yAxis,
    yMin,
    yMax,
  };
};

const XAxis = ({ axis, height }) => {
  const ref = React.useRef(null);

  React.useLayoutEffect(() => {
    const axisGroup = d3
      .select(ref.current)
      .attr('transform', `translate(0, ${height - MARGIN.BOTTOM})`)
      .call(axis);

    axisGroup.selectAll('path').attr('color', FONT_GRAY);

    // Format text
    axisGroup
      .selectAll('text')
      .attr('font-family', 'URWDIN')
      .attr('font-weight', 400)
      .attr('font-size', 12)
      .attr('color', FONT_GRAY);
  });

  return <g ref={ref} />;
};

const YAxis = ({ axis }) => {
  const ref = React.useRef(null);

  React.useLayoutEffect(() => {
    const axisGroup = d3
      .select(ref.current)
      .attr('transform', `translate(${MARGIN.LEFT}, 0)`)
      .call(axis);

    axisGroup.selectAll('path').attr('color', FONT_GRAY);

    // Format text
    axisGroup
      .selectAll('text')
      .attr('font-family', 'URWDIN')
      .attr('font-weight', 400)
      .attr('font-size', 12)
      .attr('color', FONT_GRAY);
  });

  return <g ref={ref} />;
};

const Bar = props => {
  const { x, y, barWidth, barHeight, rank } = props;

  const ref = React.useRef(null);

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const selection = d3.select(ref.current);

    selection
      .attr('x', x)
      .attr('y', y)
      .attr('width', barWidth)
      .attr('height', barHeight)
      .attr('fill', computeBoxColor(rank))
      .attr('rx', BAR_RADIUS)
      .attr('ry', BAR_RADIUS);
  }, [barHeight, barWidth, rank, x, y]);

  // style={{ filter: BAR_SHADOW }}
  return <rect ref={ref} />;
};

const DEFAULT_SERIES_KEY = 'value';
const DEFAULT_LABEL_KEY = 'name';

const BarChart = props => {
  const { data = [], labelKey = DEFAULT_LABEL_KEY, seriesKey = DEFAULT_SERIES_KEY, tickFormat, height, width } = props;

  const { xScale, xAxis, yScale, yAxis } = useController(data, {
    labelKey,
    seriesKey,
    tickFormat,
    height,
    width,
  });

  return (
    <svg width={width} height={height}>
      <XAxis axis={xAxis} height={height} />
      <YAxis axis={yAxis} />
      {data.map(datum => {
        return (
          <Bar
            key={datum[labelKey]}
            datum={datum}
            x={xScale(datum[labelKey])}
            y={yScale(datum[seriesKey])}
            barHeight={yScale(0) - yScale(datum[seriesKey])}
            barWidth={xScale.bandwidth()}
            rank={datum.rank}
          />
        );
      })}
    </svg>
  );
};

export default BarChart;
