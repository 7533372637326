/**
 * Attempt to collect payment was unsuccessful, which means the customer needs to update
 * their payment method and then attempt to manually pay for the open/unpaid invoice that
 * was created as part of the subscription update process
 */
export class PaymentFailedError extends Error {
  constructor(message, invoiceId) {
    super(message);
    this.invoiceId = invoiceId;
  }
}

/**
 * This error indicates that the customer attempted to make proposed changes that were deemed
 * invalid - either the new location statuses that were requested were logically invalid or unallowed,
 * or the customer's account is already dilinquent and so further changes are not allowed until
 * the most recent unpaid invoice is rectified.
 *
 * In practice, this error should never be encountered because that would imply our UI and/or backend
 * logic is flawed.
 */
export class UpdateForbiddenError extends Error {}
