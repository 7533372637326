import _ from 'lodash';
import classnames from 'classnames';
import { format, isValid } from 'date-fns';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { useResizeDetector } from 'react-resize-detector';
import { DEMAND_METRIC, INCOME_METRIC, METRIC_TO_LABEL } from './atAGlanceTabConstants';
import BreakdownPlot from './charts/BreakdownPlot';
import ComparePlot from './charts/ComparePlot';
import PriceChangePlot from './charts/PriceChangePlot';
import { TimeAndBaselineToolbar } from './FiltersToolbar';
import { convertFrequencyToDimensionId } from './priceChangeFrequencyUtil';
import {
  LARGE_DECREASE,
  LARGE_INCREASE,
  MEDIUM_DECREASE,
  MEDIUM_INCREASE,
  NO_CHANGE,
  PRICE_CHANGE_BUCKET_ID,
  SMALL_DECREASE,
  SMALL_INCREASE,
} from './pricingTabConstants';
import {
  DISPLAY_FREQUENCIES,
  getFrequencyLabel,
  useBreakdownData,
  usePriceChangeData,
  usePriceChangeDistributionData,
  useSummaryData,
} from './pricingTabUtil';
import PriceRecommendationTable from './RevenuePriceRecTable';
import { LOCATIONS_DIMENSION_ID } from './revenueUpliftConstants';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import RevenueUpliftSelect from './RevenueUpliftSelect';
import { parseDatasetDate } from './timePeriod';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import { CenteredProfitRoverSpinner } from '../../../spinner/ProfitRoverSpinner';
import { compactFormatNumber } from '../../../util/format';
import {
  gaEmitAveragePriceChangeDropdownClick,
  gaEmitBreakdownDimensionDropdownClick,
  gaEmitBreakdownImpactDropdownClick,
  gaEmitPriceChangeSummaryTabClick,
} from '../../../../google-analytics/pricingTab';
import { ERROR, FONT_BLACK, FONT_GRAY, SUCCESS_GREEN, MID_GREEN, LIGHT_GREEN } from '../../../../colors';
import './pricing-tab.scss';

const VizContainer = ({ children }) => {
  const { ref, height, width } = useResizeDetector();

  return (
    <div ref={ref} className="viz-container">
      {children({ height, width })}
    </div>
  );
};

const FilterButton = ({ onClick }) => {
  return (
    <button type="button" className="open-button mb-1 ml-1" onClick={onClick}>
      <FontAwesomeIcon icon={faFilter} style={{ fontSize: '1rem' }} color={FONT_BLACK} />
    </button>
  );
};

const PriceChangeBarColors = {
  [SMALL_INCREASE]: SUCCESS_GREEN,
  [MEDIUM_INCREASE]: SUCCESS_GREEN,
  [LARGE_INCREASE]: SUCCESS_GREEN,
  [NO_CHANGE]: FONT_GRAY,
  [SMALL_DECREASE]: ERROR,
  [MEDIUM_DECREASE]: ERROR,
  [LARGE_DECREASE]: ERROR,
};

const PriceChangeDistributionViz = ({ openFiltersMenu }) => {
  const { setFocusedDimensionId } = React.useContext(DashboardContext);

  const data = usePriceChangeDistributionData();

  const formatPriceChangePercent = number => {
    if (Number.isNaN(number)) {
      return '-';
    }

    if (Number.isFinite(number)) {
      return `${new Intl.NumberFormat('en', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }).format(number)}%`;
    }

    return '∞';
  };

  const onFilterClick = () => {
    setFocusedDimensionId(PRICE_CHANGE_BUCKET_ID);
    openFiltersMenu();
  };

  return (
    <div className="viz-card price-change-distribution-viz">
      <div className="viz-title-container">
        <h4 className="viz-title">Price Change Distribution</h4>
        <FilterButton onClick={onFilterClick} />
      </div>
      <div className="viz-content">
        <div className="y-label">% of Prices</div>
        <div className="d-flex flex-column align-items-center price-change-viz-and-labels">
          <VizContainer>
            {({ height, width }) => (
              <PriceChangePlot
                height={height}
                width={width}
                data={data}
                formatNumber={formatPriceChangePercent}
                barColors={PriceChangeBarColors}
              />
            )}
          </VizContainer>
          <div className="w-100 mt-3 labels">
            {data.map(({ label }) => (
              <div key={label} className="label">
                <p>{label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const BarColors = {
  new_prices: LIGHT_GREEN,
  baseline: FONT_GRAY,
  stly: MID_GREEN,
};

const formatNumberBasedOnMetric = (number, metric, currencySymbol, plusSign = false) => {
  if (metric === INCOME_METRIC) {
    return `${number < 0 ? '-' : ''}${plusSign && number > 0 ? '+' : ''}${compactFormatNumber(Math.abs(number), {
      formatAsCurrency: true,
      currencySymbol,
      fixedDecimalDigits: 1,
    })}`;
  }

  return compactFormatNumber(number, { formatAsCurrency: false, fixedDecimalDigits: 1 });
};

const Uplift = ({ baselineValue, newValue, metric }) => {
  const { currencySymbol } = React.useContext(DashboardContext);

  const formatUplift = number =>
    compactFormatNumber(Math.abs(number), {
      formatAsCurrency: metric === INCOME_METRIC,
      fixedDecimalDigits: 1,
      currencySymbol,
    });

  const formatUpliftPercent = number =>
    compactFormatNumber(Math.abs(number), { formatAsCurrency: false, fixedDecimalDigits: 1 });

  const uplift = newValue - baselineValue;
  const upliftPercent = baselineValue > 0 ? (uplift / baselineValue) * 100 : 0;

  const isPositive = uplift > 0;
  const isNegative = uplift < 0;
  const isZero = !uplift;
  const percentIsZero = !upliftPercent;

  return (
    <span className={classnames('uplift', { 'uplift-positive': isPositive, 'uplift-negative': isNegative })}>
      <div className={isZero ? null : classnames('triangle', isPositive ? 'triangle-up ' : 'triangle-down')} />
      {`${isZero ? '- - -' : formatUplift(uplift)} ${
        isZero || percentIsZero ? '' : `(${formatUpliftPercent(upliftPercent)}%)`
      }`}
    </span>
  );
};

const HatchedBox = () => {
  return (
    <svg viewBox="0 0 12 12" className="color-box mr-1">
      <defs>
        <pattern
          id="diagonal-hatch"
          width="2"
          height="2"
          patternTransform="rotate(45 0 0)"
          patternUnits="userSpaceOnUse"
        >
          <line x1="0" y1="0" x2="0" y2="2" stroke={BarColors.stly} strokeWidth="1.2" fill={BarColors.stly} />
        </pattern>
      </defs>
      <rect className="fcst-remaining" width="12" height="12" style={{ fill: 'url(#diagonal-hatch)' }} />
    </svg>
  );
};

const SummaryViz = ({ openFiltersMenu }) => {
  const { baselineComparison, currencySymbol, clearFocusedDimension, minAndMaxBaselineDates } = React.useContext(
    DashboardContext,
  );
  const { baseline, newPrices } = baselineComparison;

  const summaryData = useSummaryData();

  const legend = React.useMemo(() => {
    const legendData = [
      {
        label: baseline.label,
        value: 'baseline',
      },
      {
        label: newPrices.label,
        value: 'new_prices',
      },
    ];

    if (summaryData.flatMap(d => d.values).some(d => d.key === 'stly')) {
      legendData.push({
        value: 'stly',
        label: 'STLY',
      });
    }

    return legendData;
  }, [baseline, newPrices, summaryData]);

  const getIntervalLabel = () => {
    const [start, end] = minAndMaxBaselineDates;

    const startDate = parseDatasetDate(start);
    const endDate = parseDatasetDate(end);

    if (isValid(startDate) && isValid(endDate)) {
      return startDate === endDate
        ? `${format(startDate, 'MMM do, yyyy')}`
        : `${format(startDate, 'MMM do, yyyy')} - ${format(endDate, 'MMM do, yyyy')}`;
    }

    return '';
  };

  const onFilterClick = () => {
    clearFocusedDimension();
    openFiltersMenu();
  };

  return (
    <div className="viz-card summary-viz">
      <div className="viz-title-container">
        <div className="d-flex flex-wrap align-items-center" style={{ gap: '0.8rem' }}>
          <h4 className="viz-title">Summary</h4>
          <p className="interval">{getIntervalLabel()}</p>
        </div>
        <FilterButton onClick={onFilterClick} />
      </div>
      <div className="viz-content">
        <div className="uplift-label">
          At {newPrices.label} vs. {baseline.label}
        </div>
        <div className="d-flex flex-column w-100 graph-container">
          {summaryData.map(({ metric, values }, i) => (
            <>
              <div key={metric} className="d-flex flex-row align-items-center w-100">
                <div className="label mr-3">{METRIC_TO_LABEL[metric]}</div>
                <VizContainer>
                  {({ height, width }) => (
                    <ComparePlot
                      height={height}
                      width={width}
                      data={values}
                      formatNumber={v => formatNumberBasedOnMetric(v, metric, currencySymbol)}
                      barColors={BarColors}
                    />
                  )}
                </VizContainer>
                <Uplift
                  baselineValue={values.find(({ key }) => key === 'baseline')?.value}
                  newValue={values.find(({ key }) => key === 'new_prices')?.value}
                  metric={metric}
                />
              </div>
              <div className={classnames({ divider: i < summaryData.length - 1 })} />
            </>
          ))}
        </div>
        <div className="d-flex flex-row legend">
          {legend.map(({ value, label }) => (
            <div key={value} className="item">
              {value === 'stly' ? (
                <HatchedBox />
              ) : (
                <div className="color-box" style={{ backgroundColor: BarColors[value] }} />
              )}
              <p>{label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ImpactOptions = [
  {
    label: `${METRIC_TO_LABEL[INCOME_METRIC]} Impact`,
    value: INCOME_METRIC,
  },
  {
    label: `${METRIC_TO_LABEL[DEMAND_METRIC]} Impact`,
    value: DEMAND_METRIC,
  },
];

const BreakdownLabel = ({ labelId, labelName }) => {
  const textElementRef = React.useRef(null);
  const [isOverflown, setIsOverflown] = React.useState(false);

  React.useEffect(() => {
    const compareSize = () => {
      const element = textElementRef.current;

      if (!element) {
        return;
      }

      const compare = element
        ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
        : false;

      setIsOverflown(compare);
    };

    compareSize();
  }, [textElementRef]);

  return (
    <div key={labelId} className="label" id={`label-${labelId}`}>
      <ProfitRoverTooltip
        id={`tooltip-${labelId}`}
        shouldDisplayTooltip={isOverflown}
        placement="bottom"
        tooltipText={labelName}
      >
        <p ref={textElementRef}>{labelName}</p>
      </ProfitRoverTooltip>
    </div>
  );
};

const BreakdownViz = ({ sortedDims, openFiltersMenu }) => {
  const { currencySymbol, setFocusedDimensionId } = React.useContext(DashboardContext);
  const [selectedImpact, setSelectedImpact] = React.useState(ImpactOptions[0]);

  const [selectedDim, setSelectedDim] = React.useState(sortedDims[0]);

  const data = useBreakdownData(selectedDim, selectedImpact.value);

  const onFilterClick = () => {
    setFocusedDimensionId(selectedDim.product_dimension_id);
    openFiltersMenu();
  };

  const onImpactChange = impact => {
    gaEmitBreakdownImpactDropdownClick();
    setSelectedImpact(impact);
  };

  const onDimChange = dim => {
    gaEmitBreakdownDimensionDropdownClick();
    setSelectedDim(dim);
  };

  return (
    <div className="viz-card breakdown-viz">
      <div className="viz-content">
        <div className="viz-title-container">
          <div className="d-flex align-items-center mb-2 breakdown-selectors">
            <h4 className="viz-title">Breakdown:</h4>
            <div style={{ minWidth: 160 }}>
              <RevenueUpliftSelect options={ImpactOptions} onChange={onImpactChange} value={selectedImpact} />
            </div>
            <div>by</div>
            <div style={{ minWidth: 160 }}>
              <RevenueUpliftSelect
                options={sortedDims}
                onChange={onDimChange}
                getOptionLabel={dim => dim.name}
                getOptionValue={_.identity}
                value={selectedDim}
              />
            </div>
          </div>
          <FilterButton onClick={onFilterClick} />
        </div>
        {data.length > 0 ? (
          <div className="d-flex w-100 justify-content-center breakdown-container">
            <div className="labels mr-3">
              {data.map(({ id, name }) => (
                <BreakdownLabel key={id} labelId={id} labelName={name} />
              ))}
            </div>
            <VizContainer>
              {({ height, width }) => (
                <BreakdownPlot
                  height={height}
                  width={width}
                  data={data}
                  formatNumber={number => formatNumberBasedOnMetric(number, selectedImpact.value, currencySymbol, true)}
                />
              )}
            </VizContainer>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <div>No data</div>
          </div>
        )}
      </div>
    </div>
  );
};

const AvgPriceChangeViz = ({ sortedDims, openFiltersMenu }) => {
  const { setFocusedDimensionId } = React.useContext(DashboardContext);
  const [selectedDim, setSelectedDim] = React.useState(sortedDims[0]);

  const data = usePriceChangeData(selectedDim);

  const formatPriceChangePercent = number =>
    `${compactFormatNumber(number, { formatAsCurrency: false, fixedDecimalDigits: 1 })}%`;

  const onFilterClick = () => {
    setFocusedDimensionId(selectedDim.product_dimension_id);
    openFiltersMenu();
  };

  const onDimChange = dim => {
    gaEmitAveragePriceChangeDropdownClick();
    setSelectedDim(dim);
  };

  return (
    <div className="viz-card breakdown-viz">
      <div className="viz-content">
        <div className="viz-title-container">
          <div className="d-flex align-items-center mb-2 breakdown-selectors">
            <h4 className="viz-title">Avg. Price Change % by</h4>
            <div style={{ minWidth: 160 }}>
              <RevenueUpliftSelect
                options={sortedDims}
                onChange={onDimChange}
                getOptionLabel={dim => dim.name}
                getOptionValue={_.identity}
                value={selectedDim}
              />
            </div>
          </div>
          <FilterButton onClick={onFilterClick} />
        </div>
        {data.length > 0 ? (
          <div className="d-flex w-100 justify-content-center breakdown-container">
            <div className="labels mr-3">
              {data.map(({ id, name }) => (
                <BreakdownLabel key={id} labelId={id} labelName={name} />
              ))}
            </div>
            <VizContainer>
              {({ height, width }) => (
                <BreakdownPlot height={height} width={width} data={data} formatNumber={formatPriceChangePercent} />
              )}
            </VizContainer>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <div>No data</div>
          </div>
        )}
      </div>
    </div>
  );
};

const Tabs = {
  IMPACT: 'IMPACT',
  PRICE_CHANGE: 'PRICE_CHANGE',
};

const PricingVisualizations = ({ openFiltersMenu }) => {
  const { dimensions, locations, priceFrequencyDetails } = React.useContext(DomainContext);
  const { pricingDimIds } = React.useContext(DashboardContext);
  const [selectedTabKey, setSelectedTabKey] = React.useState(Tabs.IMPACT);

  const sortedDims = React.useMemo(() => {
    const dims = dimensions.filter(d => pricingDimIds.includes(d.product_dimension_id?.toString()));

    if (locations.length > 1) {
      dims.push({
        product_dimension_id: LOCATIONS_DIMENSION_ID,
        name: 'Location',
      });
    }

    priceFrequencyDetails.forEach(freq => {
      if (DISPLAY_FREQUENCIES.includes(freq)) {
        dims.push({
          product_dimension_id: convertFrequencyToDimensionId(freq),
          name: getFrequencyLabel(freq),
        });
      }
    });

    return _.sortBy(dims, 'name');
  }, [dimensions, pricingDimIds, locations, priceFrequencyDetails]);

  const onTabSelect = key => {
    if (key === Tabs.PRICE_CHANGE) {
      gaEmitPriceChangeSummaryTabClick();
    }
    setSelectedTabKey(key);
  };

  return (
    <Tab.Container activeKey={selectedTabKey} onSelect={onTabSelect}>
      <Tab.Content className="pricing-viz-container">
        <Nav className="nav-summary d-flex">
          <Nav.Item>
            <Nav.Link eventKey={Tabs.IMPACT}>Impact Summary</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={Tabs.PRICE_CHANGE}>Price Change Summary</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Pane eventKey={Tabs.IMPACT} unmountOnExit className="tab-pane">
          <div className="cards-container">
            <SummaryViz openFiltersMenu={openFiltersMenu} />
            <BreakdownViz sortedDims={sortedDims} openFiltersMenu={openFiltersMenu} />
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey={Tabs.PRICE_CHANGE} unmountOnExit className="tab-pane">
          <div className="cards-container">
            <PriceChangeDistributionViz openFiltersMenu={openFiltersMenu} />
            <AvgPriceChangeViz sortedDims={sortedDims} openFiltersMenu={openFiltersMenu} />
          </div>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

const PricingTab = ({ isActive, openFiltersMenu }) => {
  const { isLoadingPricingScenarios } = React.useContext(DashboardContext);

  if (isLoadingPricingScenarios) {
    return (
      <div className="pricing-tab h-100">
        <CenteredProfitRoverSpinner />
      </div>
    );
  }

  return (
    <div className="pricing-tab">
      <div className="mt-2 mb-2">
        <TimeAndBaselineToolbar />
      </div>
      <ProfitRoverCard className="px-3 pt-3 pb-2 mb-3 pricing-card">
        <PricingVisualizations openFiltersMenu={openFiltersMenu} />
      </ProfitRoverCard>
      <ProfitRoverCard className="px-3 pt-3 pb-2 table-card">
        <PriceRecommendationTable isActive={isActive} />
      </ProfitRoverCard>
    </div>
  );
};

export default PricingTab;
