/* eslint-disable react/display-name */
import React from 'react';
import { Prompt } from 'react-router-dom';
import ReactTable from 'react-table';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faTimes, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { useDimValueRowApi } from './DimensionValuesSettingsHooks';
import NAVIGATION_SEPARATOR from '../settingsNavigationMenuConstants';
import SettingsNavigationMenu from '../SettingsNavigationMenu';
import SettingsPageWrapper from '../SettingsPageWrapper';
import IconButton from '../components/IconButton';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import Header from '../../header/header';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import ProfitRoverTooltip from '../../generic/ProfitRoverTooltip';
import { FONT_WHITE, MID_GREEN } from '../../../colors';
import { useDimensions } from '../../../data-access/query/dimensions';
import { useDimensionValues, useRawDimensionValues } from '../../../data-access/query/dimensionValues';
import { useEditDimensionValuesMutations } from '../../../data-access/mutation/dimensionValues';
import {
  gaEmitAddNewDimensionValueCheckmarkClick,
  gaEmitSaveChangesDimensionValueButtonClick,
} from '../../../google-analytics/dataSettings';
import './dimension-values-settings.css';

const tooltipText = "Filters can't be changed until pending changes are saved";

const SelectionAndFilteringArea = props => {
  const {
    searchText,
    setSearchText,
    dimensions,
    dimensionsLoading,
    selectedDimensionState,
    mappedToDataFilter,
    setMappedToDataFilter,
    changesPending,
  } = props;

  const [selectedDimension, setSelectedDimension] = selectedDimensionState;

  const onDimensionChange = dimension => {
    setSelectedDimension(dimension);

    const newDimensionSelected =
      dimension && selectedDimension && dimension.product_dimension_id !== selectedDimension.product_dimension_id;
    const dimensionCleared = dimension == null;

    if (newDimensionSelected || dimensionCleared) {
      setSearchText('');
    }
  };

  const onSelectMappedToData = value => {
    setMappedToDataFilter(value);
  };

  const disableInputs = selectedDimension == null || changesPending;

  const StagedChangesTooltip = React.useMemo(
    () => ({ children }) => (
      <ProfitRoverTooltip
        shouldDisplayTooltip={changesPending}
        tooltipText={tooltipText}
        tooltipId="disabled-filter-tooltip"
        placement="bottom"
      >
        {children}
      </ProfitRoverTooltip>
    ),
    [changesPending],
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div>Manage Values For Dimension:</div>
        <StagedChangesTooltip>
          <div style={{ maxWidth: 500, minWidth: 250 }}>
            <Select
              options={dimensions}
              value={selectedDimension}
              getOptionLabel={dim => dim.name}
              getOptionValue={dim => dim}
              onChange={onDimensionChange}
              placeholder="Select a Dimension..."
              isDisabled={dimensionsLoading || changesPending}
              isClearable
              isSearchable
              isMulti={false}
            />
          </div>
        </StagedChangesTooltip>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: 300 }}>
        <div>Filter to Master Values:</div>
        <StagedChangesTooltip>
          <input
            style={{
              padding: '0px 7px',
              height: 38,
              borderRadius: 4,
              border: '1px solid rgb(204, 204, 204)',
              background: 'white',
            }}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            placeholder="Type to search..."
            disabled={disableInputs}
          />
        </StagedChangesTooltip>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: 150 }}>
        <div>Mapped to Data?</div>
        <StagedChangesTooltip>
          <div style={{ minWidth: 175 }}>
            <Select
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              isDisabled={disableInputs}
              isMulti={false}
              onChange={onSelectMappedToData}
              value={mappedToDataFilter}
              isClearable
            />
          </div>
        </StagedChangesTooltip>
      </div>
    </div>
  );
};

const HEADER_STYLES = {
  background: MID_GREEN,
  color: FONT_WHITE,
  paddingTop: '8px',
  fontWeight: 'bold',
  fontSize: 14,
};

const TEXT_CELL_STYLES = {
  display: 'flex',
  alignItems: 'center',
};

const ICON_FONT_SIZE = 30;

const InlineCellInput = ({ onChange, value }) => {
  return <input onChange={onChange} value={value} style={{ width: '100%', padding: '1px 5px' }} />;
};

const DimensionValuesTable = props => {
  const { selectedDimension, onClickSave, data, mappedDimensionValueIdsSet, rowsApi } = props;

  const {
    createNewDimValue,
    setIsEditing,
    updateValue,
    resetValueToOriginal,
    setValueAsDeleted,
    stagedChangesValues,
  } = rowsApi;

  const [isCreatingNewValue, setIsCreatingNewValue] = React.useState(false);
  const [newDimensionValue, setNewDimensionValue] = React.useState('');

  const onClickCreateNew = () => {
    setIsCreatingNewValue(true);
  };

  const columns = [
    {
      id: 'value',
      Header: 'Master Value',
      headerStyle: HEADER_STYLES,
      accessor: 'value',
      style: TEXT_CELL_STYLES,
      minWidth: 600,
      Cell: cellProps => {
        const { original: dimValueSetting } = cellProps;

        if (dimValueSetting.isBeingEdited()) {
          return (
            <InlineCellInput
              value={dimValueSetting.getEditedValue()}
              onChange={e => updateValue(dimValueSetting, e.target.value)}
            />
          );
        }

        let backgroundColor = 'unset';
        if (dimValueSetting.isValueEdited() && !dimValueSetting.isNew()) {
          backgroundColor = '#ffffcc';
        }

        const displayValue = dimValueSetting.isValueEdited() ? dimValueSetting.getEditedValue() : dimValueSetting.value;

        return <div style={{ paddingLeft: 10, backgroundColor, width: '100%' }}>{displayValue}</div>;
      },
      Footer: () =>
        isCreatingNewValue ? (
          <input
            style={{ width: '100%', marginRight: 3 }}
            value={newDimensionValue}
            onChange={e => setNewDimensionValue(e.target.value)}
            placeholder="Enter a name for your new dimension value..."
          />
        ) : null,
    },
    {
      id: 'mappedToData',
      Header: 'Mapped To Data',
      headerStyle: HEADER_STYLES,
      accessor: d => d.isMapped(mappedDimensionValueIdsSet),
      minWidth: 150,
      style: { ...TEXT_CELL_STYLES, justifyContent: 'center', overflow: 'visible' },
      Cell: cellProps => (cellProps.value ? 'Yes' : 'No'),
    },
    {
      minWidth: 100,
      Header: 'Actions',
      headerStyle: HEADER_STYLES,
      style: { display: 'flex', justifyContent: 'center' },
      Cell: cellProps => {
        const { original: dimValueSetting } = cellProps;

        if (dimValueSetting.isBeingEdited()) {
          return (
            <>
              {/* Accept changes */}
              <IconButton
                key="accept"
                onClick={() => {
                  setIsEditing(dimValueSetting, false);
                }}
              >
                <FontAwesomeIcon className="Icon" icon={faCheck} fontSize={ICON_FONT_SIZE} />
              </IconButton>
              {/* Reset changes to original value */}
              <IconButton key="reset" onClick={() => resetValueToOriginal(dimValueSetting)}>
                <FontAwesomeIcon className="Icon" icon={faUndo} fontSize={ICON_FONT_SIZE} />
              </IconButton>
            </>
          );
        }

        return (
          <>
            {/* Begin editing */}
            <IconButton key="edit" onClick={() => setIsEditing(dimValueSetting, true)}>
              <FontAwesomeIcon className="Icon" icon={faEdit} fontSize={ICON_FONT_SIZE} />
            </IconButton>

            {/* Delete dimension value  */}
            <IconButton
              key="delete"
              onClick={() => setValueAsDeleted(dimValueSetting)}
              disabled={dimValueSetting.isMapped(mappedDimensionValueIdsSet)}
            >
              <FontAwesomeIcon className="Icon" icon={faTrash} fontSize={ICON_FONT_SIZE} />
            </IconButton>
          </>
        );
      },
      Footer: () => {
        if (isCreatingNewValue) {
          return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
              <FontAwesomeIcon
                className="Icon"
                icon={faCheck}
                fontSize={ICON_FONT_SIZE}
                onClick={() => {
                  gaEmitAddNewDimensionValueCheckmarkClick();
                  // Create brand new dimension to the table
                  createNewDimValue({ value: newDimensionValue, dimensionId: selectedDimension.product_dimension_id });

                  // clear the footer since we're done creating them
                  setIsCreatingNewValue(false);
                  setNewDimensionValue('');
                }}
              />
              <IconButton key="stop-creating" onClick={() => setIsCreatingNewValue(false)}>
                {' '}
                <FontAwesomeIcon className="Icon" icon={faTimes} fontSize={ICON_FONT_SIZE} />
              </IconButton>
            </div>
          );
        }

        return null;
      },
    },
  ];

  const getTrProps = (state, rowInfo) => {
    let backgroundColor = 'white';
    if (rowInfo) {
      const { original: dimValueSetting } = rowInfo;

      if (dimValueSetting.isNew()) {
        backgroundColor = 'rgba(0, 151, 19, 0.3)';
      } else if (dimValueSetting.isDeleted()) {
        backgroundColor = '#ffcccb';
      }
    }

    return {
      style: {
        backgroundColor,
      },
    };
  };

  const incompleteValues = React.useMemo(
    () => data.filter(dimValue => dimValue.getEditedValue() === '' || dimValue.isBeingEdited()),
    [data],
  );

  const editingInProgress = incompleteValues.length > 0;
  const { createdValues, editedValues, deletedValues } = stagedChangesValues;
  const stagedChanges = createdValues.length > 0 || editedValues.length > 0 || deletedValues.length > 0;

  return (
    <div style={{ maxWidth: '100%' }}>
      <Prompt
        when={stagedChanges || incompleteValues.length > 0}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <ReactTable
        data={data}
        columns={columns}
        getTrProps={getTrProps}
        defaultPageSize={10}
        pageSizeOptions={[10]}
        minRows={1}
        filterable={false}
        showPaginationBottom={data.length > 10}
        resizable={false}
        sortable={false}
        style={{ background: 'white' }}
      />

      <div className="d-flex mt-3 save-btn-container" style={{ gap: 16 }}>
        <ProfitRoverPrimaryButton
          onClick={onClickCreateNew}
          disabled={editingInProgress || !selectedDimension || isCreatingNewValue}
        >
          Add Dimension Value
        </ProfitRoverPrimaryButton>
        <ProfitRoverPrimaryButton
          disabled={editingInProgress || !stagedChanges}
          onClick={() => onClickSave(createdValues, editedValues, deletedValues)}
        >
          Save Changes
        </ProfitRoverPrimaryButton>
      </div>
    </div>
  );
};

const DimensionValuesSettings = () => {
  const { data: dimensions = [], isFetching: dimensionsLoading } = useDimensions(false);

  const dimensionValuesState = useDimensionValues(true);
  let { data: dimensionValues } = dimensionValuesState;
  const { refetch: refetchDimensionValues } = dimensionValuesState;
  const { data: rawDimensionValues = [] } = useRawDimensionValues();
  const mappedDimensionValueIdsSet = React.useMemo(() => {
    const mappedDimensionValueIds = rawDimensionValues
      .filter(rawDimValue => !rawDimValue.is_ignore)
      .map(rawDimValue => rawDimValue.product_dimension_value_id);
    return new Set(mappedDimensionValueIds);
  }, [rawDimensionValues]);

  const selectedDimensionState = React.useState();
  const [selectedDimension] = selectedDimensionState;

  const [searchText, setSearchText] = React.useState('');
  const [mappedToDataFilter, setMappedToDataFilter] = React.useState();

  const {
    createDimensionValuesMutation,
    updateDimensionValuesMutation,
    deleteDimensionValuesMutation,
  } = useEditDimensionValuesMutations();

  const onClickSave = async (valuesToCreate, valuesToUpdate, valuesToDelete) => {
    gaEmitSaveChangesDimensionValueButtonClick();
    if (valuesToCreate.length > 0) {
      valuesToCreate = valuesToCreate.map(dimValue => {
        // A user may have edited a new value they created but before having saved the new value
        const isEdited = dimValue.isValueEdited();
        const value = isEdited ? dimValue.getEditedValue() : dimValue.value;
        return { value, dimension_id: dimValue.dimension_id };
      });
      await createDimensionValuesMutation.mutateAsync({ values: valuesToCreate });
    }

    if (valuesToUpdate.length > 0) {
      valuesToUpdate = valuesToUpdate.map(({ id, editedValue }) => ({ id, value: editedValue }));
      await updateDimensionValuesMutation.mutateAsync({ values: valuesToUpdate });
    }

    if (valuesToDelete.length > 0) {
      valuesToDelete = valuesToDelete.map(({ id, value, dimensionId }) => ({ id, value, dimension_id: dimensionId }));
      await deleteDimensionValuesMutation.mutateAsync({ values: valuesToDelete });
    }

    await refetchDimensionValues();
  };

  dimensionValues = React.useMemo(() => {
    // Only show dimension values for the currently selected dimension (and ones that haven't been mapped)
    if (selectedDimension) {
      const selectedDimensionId = selectedDimension.product_dimension_id;
      return dimensionValues.filter(
        dimValue => dimValue.dimension_id === selectedDimensionId || dimValue.dimension_id == null,
      );
    }

    return dimensionValues;
  }, [selectedDimension, dimensionValues]);

  const rowsApi = useDimValueRowApi(dimensionValues, selectedDimension);
  const { tableDimValues, stagedChangesValues } = rowsApi;
  // Only show rows if a dimension has been selected
  let data = selectedDimension ? tableDimValues : [];

  // Filter to rows that match the search criteria, if present
  data = data.filter(row => {
    let textMatch = true;
    if (searchText !== '') {
      let valueText = row.isValueEdited() ? row.getEditedValue() : row.value;

      // Case-insensitive search
      valueText = valueText.toLocaleLowerCase();
      textMatch = valueText.includes(searchText.toLocaleLowerCase());
    }

    let mappedToDataMatch = true;
    if (mappedToDataFilter != null) {
      mappedToDataMatch = row.isMapped(mappedDimensionValueIdsSet) === mappedToDataFilter.value;
    }

    const isBeingEdited = row.isBeingEdited();
    const isEdited = row.isValueEdited();

    return (textMatch && mappedToDataMatch) || isBeingEdited || isEdited;
  });

  const { createdValues = [], editedValues = [], deletedValues = [] } = stagedChangesValues;
  const stagedChanges = createdValues.length > 0 || editedValues.length > 0 || deletedValues.length > 0;

  return (
    <HeaderAwarePage>
      <Header />
      <SettingsNavigationMenu
        label={`Settings ${NAVIGATION_SEPARATOR} Data ${NAVIGATION_SEPARATOR} Manage Dimension Values`}
        tabsId={['settings', 'dimensions-data']}
      />
      <SettingsPageWrapper>
        <div className="container">
          <h3 className="settings-header">Manage Dimension Values</h3>

          <div className="w-100">
            <SelectionAndFilteringArea
              dimensions={dimensions}
              dimensionsLoading={dimensionsLoading}
              selectedDimensionState={selectedDimensionState}
              searchText={searchText}
              setSearchText={setSearchText}
              mappedToDataFilter={mappedToDataFilter}
              setMappedToDataFilter={setMappedToDataFilter}
              changesPending={stagedChanges}
            />

            <DimensionValuesTable
              selectedDimension={selectedDimension}
              dimensionValues={dimensionValues}
              onClickSave={onClickSave}
              data={data}
              mappedDimensionValueIdsSet={mappedDimensionValueIdsSet}
              rowsApi={rowsApi}
              stagedChangesValues={stagedChangesValues}
            />
          </div>
        </div>
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

export default DimensionValuesSettings;
