import _ from 'lodash';
import { format, parse } from 'date-fns';
import moment from 'moment-timezone';
import React from 'react';
import { CurrentPrices } from './baselineComparison';
import { FULL_DATE_FORMAT } from './revenueUpliftConstants';
import { DomainContext } from './revenueUpliftContexts';
import { DATE_RANGE_SELECTION, DATASET_DATE_FORMAT } from '../../../../utils/date-handling';
import { logExecutionTime } from '../../../../utils/performanceMonitoring';
import { useSaveUponLeave, useSaveWithDebounce } from './statePersistence';

export const formatAsDatasetDate = date => format(date, FULL_DATE_FORMAT);
export const parseDatasetDate = date => parse(date, FULL_DATE_FORMAT, new Date(0, 0, 0, 0, 0, 0, 0));

export const getDayOfWeek = date => format(date, 'EEEE');

/**
 * When no date range selection has been persisted, this function will attempt to
 * pick a suitable date range such that results will be visible (business closures can
 * produce gaps of time with no demand)
 *
 * @param {Moment} today
 * @param {String} earliestDate - of the format "YYYY-MM-DD"
 * @returns {DATE_RANGE_SELECTION}
 */
export const initialDateRangeSelection = (today, earliestDate) => {
  today = today.clone().startOf('day');
  const earliestInDataset = moment(earliestDate).startOf('day');

  const thirtyDaysLater = today.clone().add(30, 'days');
  if (earliestInDataset.isBefore(thirtyDaysLater.endOf('day'))) {
    return DATE_RANGE_SELECTION.NEXT_30_DAYS;
  }

  const ninetyDaysLater = today.clone().add(90, 'days');
  if (earliestInDataset.isBetween(today, ninetyDaysLater, '()')) {
    return DATE_RANGE_SELECTION.NEXT_90_DAYS;
  }

  const aYearLater = today.clone().add(1, 'year');
  if (earliestInDataset.isBetween(today, aYearLater, '()')) {
    return DATE_RANGE_SELECTION.NEXT_365_DAYS;
  }

  return DATE_RANGE_SELECTION.ALL_AVAILABLE;
};

const afterToday = ({ tranDate }) => {
  const today = moment();

  const transactionDate = moment(tranDate, DATASET_DATE_FORMAT);
  return tranDate != null && transactionDate.isSameOrAfter(today, 'day');
};

export const useMinAndMaxDatesInDataset = (rows, predicate = afterToday) => {
  return React.useMemo(() => {
    const start = performance.now();

    const relevantDates = _.uniqBy(rows, row => row.tranDate)
      .filter(predicate)
      .map(row => row.tranDate)
      .sort();

    const end = performance.now();
    logExecutionTime('Finding min and max dates', start, end);

    return [relevantDates[0], relevantDates[relevantDates.length - 1]];
  }, [predicate, rows]);
};

export const SELECTED_TIME_PERIOD_KEY = 'timePeriod';

export const useDateRange = (
  workflowId,
  initialTimePeriod,
  scenariosMeta,
  minAndMaxDatasetDates,
  baselineComparison,
) => {
  const [selectedRange, setSelectedRange] = React.useState(
    Object.values(DATE_RANGE_SELECTION).includes(initialTimePeriod?.range)
      ? initialTimePeriod?.range
      : DATE_RANGE_SELECTION.NEXT_30_DAYS,
  );
  const { setBaseline } = baselineComparison;

  const stateToSave = React.useMemo(() => {
    return { range: selectedRange };
  }, [selectedRange]);

  useSaveWithDebounce(workflowId, 'time_period', stateToSave);
  useSaveUponLeave(workflowId, 'time_period', stateToSave);

  const onShowNext90Days = () => {
    setSelectedRange(DATE_RANGE_SELECTION.NEXT_90_DAYS);
    setBaseline(CurrentPrices);
  };

  const minAndMaxBaselineDates = React.useMemo(() => {
    const [minDatasetDate, maxDatasetDate] = minAndMaxDatasetDates;
    const { relative_dates: relativeDates = {} } = scenariosMeta;

    if (selectedRange === DATE_RANGE_SELECTION.NEXT_7_DAYS) {
      const { next_7_start: start, next_7_end: end } = relativeDates;

      return [_.max([start, minDatasetDate]), _.min([end, maxDatasetDate])];
    }
    if (selectedRange === DATE_RANGE_SELECTION.NEXT_30_DAYS) {
      const { next_30_start: start, next_30_end: end } = relativeDates;

      return [_.max([start, minDatasetDate]), _.min([end, maxDatasetDate])];
    }
    if (selectedRange === DATE_RANGE_SELECTION.NEXT_90_DAYS) {
      const { next_90_start: start, next_90_end: end } = relativeDates;

      return [_.max([start, minDatasetDate]), _.min([end, maxDatasetDate])];
    }
    if (selectedRange === DATE_RANGE_SELECTION.NEXT_365_DAYS) {
      const { next_365_start: start, next_365_end: end } = relativeDates;

      return [_.max([start, minDatasetDate]), _.min([end, maxDatasetDate])];
    }
    if (selectedRange === DATE_RANGE_SELECTION.CURRENT_MONTH) {
      const { month_start: start, month_end: end } = relativeDates;

      return [_.max([start, minDatasetDate]), _.min([end, maxDatasetDate])];
    }
    if (selectedRange === DATE_RANGE_SELECTION.CURRENT_QUARTER) {
      const { quarter_start: start, quarter_end: end } = relativeDates;

      return [_.max([start, minDatasetDate]), _.min([end, maxDatasetDate])];
    }
    if (selectedRange === DATE_RANGE_SELECTION.CURRENT_YEAR) {
      const { year_start: start, year_end: end } = relativeDates;

      return [_.max([start, minDatasetDate]), _.min([end, maxDatasetDate])];
    }
    if (selectedRange === DATE_RANGE_SELECTION.NEXT_MONTH) {
      const { month_next_start: start, month_next_end: end } = relativeDates;

      return [_.max([start, minDatasetDate]), _.min([end, maxDatasetDate])];
    }
    if (selectedRange === DATE_RANGE_SELECTION.NEXT_QUARTER) {
      const { quarter_next_start: start, quarter_next_end: end } = relativeDates;

      return [_.max([start, minDatasetDate]), _.min([end, maxDatasetDate])];
    }
    if (selectedRange === DATE_RANGE_SELECTION.NEXT_YEAR) {
      const { year_next_start: start, year_next_end: end } = relativeDates;

      return [_.max([start, minDatasetDate]), _.min([end, maxDatasetDate])];
    }

    return [null, null];
  }, [minAndMaxDatasetDates, scenariosMeta, selectedRange]);

  return {
    selectedRange,
    setSelectedRange,
    onShowNext90Days,
    minAndMaxBaselineDates,
  };
};

export const useProductDims = () => {
  const { workflow, dimensions: productDimensions } = React.useContext(DomainContext);

  return React.useMemo(() => {
    const pricingDimensions = workflow.pricing_dimensions ?? [];
    const shouldAggregateDimensions = pricingDimensions.length > 0; // pricing_dimensions by default is an empty array

    const pricingDims = shouldAggregateDimensions
      ? pricingDimensions
      : productDimensions.map(dim => dim.product_dimension_id);

    return {
      pricingDimIds: pricingDims.map(id => id.toString()),
      shouldAggregateDimensions,
    };
  }, [productDimensions, workflow]);
};
