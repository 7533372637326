export const PRICE_CHANGE_BUCKET_ID = 'priceChangeBucket';
export const PRICE_CHANGE_BUCKET_LABEL = 'Price Change Buckets';

export const SMALL_DECREASE = 'small_decrease';
export const MEDIUM_DECREASE = 'medium_decrease';
export const LARGE_DECREASE = 'large_decrease';
export const NO_CHANGE = 'no_change';
export const SMALL_INCREASE = 'small_increase';
export const MEDIUM_INCREASE = 'medium_increase';
export const LARGE_INCREASE = 'large_increase';

export const BUCKETS = [
  LARGE_DECREASE,
  MEDIUM_DECREASE,
  SMALL_DECREASE,
  NO_CHANGE,
  SMALL_INCREASE,
  MEDIUM_INCREASE,
  LARGE_INCREASE,
];

export const BUCKET_TO_LABEL = {
  [SMALL_DECREASE]: 'Small Dec',
  [MEDIUM_DECREASE]: 'Med Dec',
  [LARGE_DECREASE]: 'Large Dec',
  [NO_CHANGE]: 'No Change',
  [SMALL_INCREASE]: 'Small Inc',
  [MEDIUM_INCREASE]: 'Med Inc',
  [LARGE_INCREASE]: 'Large Inc',
};

export const BUCKET_TO_FILTER_LABEL = {
  [SMALL_DECREASE]: 'Small Decrease',
  [MEDIUM_DECREASE]: 'Medium Decrease',
  [LARGE_DECREASE]: 'Large Decrease',
  [NO_CHANGE]: 'No Change',
  [SMALL_INCREASE]: 'Small Increase',
  [MEDIUM_INCREASE]: 'Medium Increase',
  [LARGE_INCREASE]: 'Large Increase',
};

export const MEDIUM_PRICE_CHANGE_RATIO_CAP = 0.05;
export const LARGE_PRICE_CHANGE_RATIO_CAP = 0.15;

export const PRICE_LIKE_COLS = Object.freeze(['revenue', 'demand', 'profit', 'price']);
