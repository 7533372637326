import React from 'react';
import Select from 'react-select';
import { Field, useField, useForm } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import ConfigAccordionItem from './ConfigAccordionItem';
import { useConfigTableContext } from './configTableContext';
import { useInitialItemQuantityAccordion } from '../fieldInitializers';

const DEFAULT_NAME = 'item-quantity-accordion';
const DEFAULT_TITLE = 'Item Quantity';
const DEFAULT_UUID = 'ITEM_QUANTITY';

const ItemQuantityAccordionItem = ({ title = DEFAULT_TITLE, uuid = DEFAULT_UUID, name = DEFAULT_NAME }) => {
  const { columnNameOptions } = useConfigTableContext();

  const {
    initialFormState,
    checkboxName,
    fixedValue,
    columnName,
    datasetFieldIdName,
  } = useInitialItemQuantityAccordion(name);
  useField(datasetFieldIdName, { initialValue: initialFormState.datasetFieldId });

  const formApi = useForm();
  const formState = formApi.getState();

  const data = formState.values[name];
  const isFieldInData = data?.checkbox;

  const errors = formState.errors[name] ?? {};

  const hasFixedValueInput = !isFieldInData ? formState.values[name]?.fixedValue != null : true;
  const isComplete = Object.entries(errors).every(([, value]) => {
    return value == null;
  });

  return (
    <ConfigAccordionItem title={title} uuid={uuid} valid={isComplete && hasFixedValueInput}>
      <div className="dataset-accordion-field-container">
        <div className="accordion-field-container-row">
          <div className="field-content-container">
            <div className="field-details-container">
              <Field name={checkboxName} type="checkbox" initialValue={initialFormState.checkbox}>
                {props => <input className="mx-2" {...props.input} />}
              </Field>
              <div className="accordion-field-description">Exists in my dataset</div>
            </div>
          </div>
        </div>
        <div style={{ height: '75px', width: '1px', border: '.5px solid black', margin: '10px', opacity: '30%' }} />
        <div className="accordion-field-container-row">
          <div className="field-content-container">
            <div className="field-selection-column-container">
              {isFieldInData ? (
                <Field
                  name={columnName}
                  initialValue={initialFormState.columnName}
                  validate={(value, allValues) => {
                    const val = allValues[name]?.fromColumn;
                    return val == null ? FORM_ERROR : undefined;
                  }}
                  isEqual={(a, b) => a?.label === b?.label}
                >
                  {props => (
                    <>
                      <span className="field-select-text">Column containing {DEFAULT_TITLE}:</span>
                      <Select
                        className="field-input-select"
                        options={columnNameOptions}
                        onChange={selectedValue => {
                          props.input.onChange(selectedValue);
                        }}
                        placeholder="Start typing..."
                        value={props?.input?.value}
                      />
                    </>
                  )}
                </Field>
              ) : (
                <>
                  <span className="field-select-text">What value do you want to give this?</span>
                  <Field
                    name={fixedValue}
                    type="number"
                    initialValue={initialFormState.fixedValue}
                    validate={(value, allValues) => {
                      const val = allValues[name]?.fixedValue;
                      return val == null ? FORM_ERROR : undefined;
                    }}
                  >
                    {props => <input className="mx-2, field-input-select" {...props.input} />}
                  </Field>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ConfigAccordionItem>
  );
};

export default ItemQuantityAccordionItem;
