import React from 'react';
import _ from 'lodash';
import { useGridApi } from '../../util/gridCommonFunctions';
import { useLocations } from '../../../data-access/query/locations';
import { useNaicsCodes } from '../../../data-access/query/naics';

// eslint-disable-next-line import/prefer-default-export
export const useLocationTableState = () => {
  const { gridApi, onGridReady } = useGridApi();
  const { data: locations = [], isLoading: isLoadingLocations } = useLocations();
  const { data: naicsCodes = [], isLoading: isLoadingNaicsCodes } = useNaicsCodes();
  const naicsCodesKeyedByCode = React.useMemo(() => _.keyBy(naicsCodes, 'naicsKaCode'), [naicsCodes]);

  const initialRowData = React.useMemo(
    () =>
      locations.map(location => {
        const industryDescription = naicsCodesKeyedByCode[location.naicsKaCode]?.description;

        const rowData = {
          ...location,
          industry_description: industryDescription,
          updated_values: {},
        };

        return rowData;
      }),
    [locations, naicsCodesKeyedByCode],
  );

  const isDataReady = !isLoadingNaicsCodes && !isLoadingLocations && initialRowData != null;

  const numberOfLocations = locations.length;

  return {
    gridApi,
    onGridReady,
    numberOfLocations,
    isDataReady,
    initialRowData,
  };
};
