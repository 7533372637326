export const RevenueDashboardErrorText = {
  NoDataAvailableError: 'We are unable to retrieve your data at this time.',
  NoValidDatasetsError: `You don't have any valid data to display. Please visit the Data Center to resolve.`,
  NotEnoughHistoricalData: `You don't have enough historical data to show insights.`,
  DataInProgress:
    "We are still processing your data. You'll receive a notification letting you know when your insights are ready.",
  UnexpectedError: 'We encountered an unexpected error. Please contact our support team.',
  NoDimensionValueError:
    'There is a problem with your dimension values. Please check the dimension value mapping in Settings.',
  NoLocationsError: `We are still processing the locations from your data. You'll receive a notification letting you 
  know when your insights are ready.`,
};

export class NoDataAvailableError extends Error {
  constructor() {
    super(RevenueDashboardErrorText.NoDataAvailableError);

    this.title = 'Unable to Load Dashboard';
    this.primaryButtonText = 'Try Again';
    this.secondaryButtonText = 'Go Home';
  }
}

export class NotEnoughHistoricalData extends Error {
  constructor() {
    super(RevenueDashboardErrorText.NotEnoughHistoricalData);

    this.title = 'Unable to Load Dashboard';
    this.primaryButtonText = 'Try Again';
    this.secondaryButtonText = 'Go Home';
  }
}

export class NoValidDatasetsError extends Error {
  constructor() {
    super(RevenueDashboardErrorText.NoValidDatasetsError);

    this.title = 'Unable to Show Dashboard';
    this.primaryButtonText = 'Go to Data Center';
    this.secondaryButtonText = 'Go Home';
  }
}

export class DataInProgress extends Error {
  constructor(batch, workflowId) {
    super(RevenueDashboardErrorText.DataInProgress);
    this.batch = batch;
    this.workflowId = workflowId;
  }
}

export class UnexpectedError extends Error {
  constructor() {
    super(RevenueDashboardErrorText.UnexpectedError);
    this.title = 'Unable to Load Dashboard';
    this.primaryButtonText = 'Try again';
    this.secondaryButtonText = 'Go Home';
  }
}

export class NoDimensionValueError extends Error {
  constructor() {
    super(RevenueDashboardErrorText.NoDimensionValueError);
    this.title = 'Unable to Load Dashboard';
    this.primaryButtonText = 'Go to Dimension Value Settings';
    this.secondaryButtonText = 'Go Home';
  }
}

export class NoLocationsError extends Error {
  constructor(workflowId) {
    super(RevenueDashboardErrorText.NoLocationsError);
    this.title = 'No Available Locations';
    this.primaryButtonText = 'Try Again';
    this.secondaryButtonText = 'Go Home';
    this.workflowId = workflowId;
  }
}

export const isKnownError = error =>
  error instanceof NoDataAvailableError ||
  error instanceof NotEnoughHistoricalData ||
  error instanceof NoValidDatasetsError ||
  error instanceof UnexpectedError ||
  error instanceof NoDimensionValueError ||
  error instanceof DataInProgress ||
  error instanceof NoLocationsError;
