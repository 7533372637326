import axios from 'axios';
import { KA_API_URL } from '../../config/baseUrl';

export const fetchDimensionTypes = () => {
  return axios.get(`${KA_API_URL}/products/dimensions/types`);
};

export const fetchDimensions = () => {
  return axios.get(`${KA_API_URL}/products/dimensions`);
};

const createDimension = (name, dimensionType) => {
  return axios.post(`${KA_API_URL}/products/dimensions`, {
    dimensions: [
      {
        name,
        dimension_type: dimensionType,
      },
    ],
  });
};

export const tryCreateDimension = async (name, dimensionType) => {
  try {
    const response = await createDimension(name, dimensionType);
    return { data: response?.data, errors: response?.data?.errors };
  } catch (err) {
    return null;
  }
};

const fetchRawDimensionValues = () => {
  return axios.get(`${KA_API_URL}/products/dimensions/raw/values`);
};

export const fetchDimensionValues = () => {
  return axios.get(`${KA_API_URL}/products/dimensions/values`);
};

export const fetchProductDimensionUnits = () => {
  return axios.get(`${KA_API_URL}/products/dimensions/values/units`);
};

export const getProductDimensions = async () => {
  let productDimensionRawValues = await fetchRawDimensionValues();
  productDimensionRawValues = productDimensionRawValues.data;

  let productDimensionValues = await fetchDimensionValues();
  productDimensionValues = productDimensionValues.data;

  let productDimensionUnits = await fetchProductDimensionUnits();
  productDimensionUnits = productDimensionUnits.data;

  return { productDimensionRawValues, productDimensionValues, productDimensionUnits };
};

export const mapCFBProductDimensions = async productVisitsResponse => {
  const productDimensionRawValues = await fetchRawDimensionValues();

  const productDimensionUnits = await fetchProductDimensionUnits();

  const dimensionsMap = new Map();
  const dimensionsData = productDimensionUnits.data;
  dimensionsData.map(data => {
    dimensionsMap.set(data.product_id, data.values);
    return true;
  });

  const dimensionRawValuesMap = new Map();
  const dimensionsRawData = productDimensionRawValues.data;
  dimensionsRawData.map(data => {
    dimensionRawValuesMap.set(data.product_dimension_value_id, data);
    return true;
  });

  const productDimensions = await fetchDimensions();

  //  get customer's price_level dimension_id
  const priceLevelDimensionId = productDimensions.data.filter(dim => dim.dimension_type === 'PRICE_LEVEL')[0]
    .product_dimension_id;
  const productVisits = [];
  for (let i = 0; i < productVisitsResponse.data.length; i++) {
    const visit = productVisitsResponse.data[i];
    // get the dimension from the dimensionsMap
    let priceLevelDimension = dimensionsMap.get(visit.product_id);
    if (priceLevelDimension) {
      priceLevelDimension = priceLevelDimension.filter(val => val.dimension_id === priceLevelDimensionId);
      // get the product dimension value from dimensionRawValuesMap
      const dimensionValue = dimensionRawValuesMap.get(priceLevelDimension[0].value_id);
      visit.product_dimension_value = dimensionValue.value;
      productVisits.push(visit);
    }
  }
  return productVisits;
};
