import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import { Field, useField, useForm } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import ConfigAccordionItem from './ConfigAccordionItem';
import { useConfigTableContext } from './configTableContext';
import { ConfigFieldName } from '../fieldBuilders';
import { useInitialPriceAccordion } from '../fieldInitializers';

const DEFAULT_NAME = 'price-accordion';
const DEFAULT_TITLE = 'Price';
const DEFAULT_UUID = 'PRICE';

const PriceAccordionItem = ({ title = DEFAULT_TITLE, uuid = DEFAULT_UUID, name = DEFAULT_NAME }) => {
  const { columnNameOptions } = useConfigTableContext();

  const {
    initialFormState,
    checkboxName,
    fixedValue,
    columnName,
    priceType,
    datasetFieldIdName,
  } = useInitialPriceAccordion(name);
  useField(datasetFieldIdName, { initialValue: initialFormState.datasetFieldId });

  const formApi = useForm();
  const formState = formApi.getState();

  const data = formState.values[name];
  const isFieldInData = data?.checkbox;

  const errors = formState.errors[name] ?? {};

  const hasFixedValueInput = !isFieldInData ? formState.values[name]?.fixedValue != null : true;
  const hasPriceType = formState.values[name]?.priceType != null;
  const isComplete = Object.entries(errors).every(([, value]) => {
    return value == null && hasPriceType;
  });

  return (
    <ConfigAccordionItem title={title} uuid={uuid} valid={isComplete && hasPriceType && hasFixedValueInput}>
      <div className="dataset-accordion-field-container">
        <div className="accordion-field-container-row">
          <div className="field-content-container">
            <div className="field-details-container">
              <Field name={checkboxName} type="checkbox" initialValue={initialFormState.checkbox}>
                {props => <input className="mx-2" {...props.input} />}
              </Field>

              <div className="accordion-field-description">Exists in my dataset</div>
            </div>
          </div>
        </div>
        <div style={{ height: '75px', width: '1px', border: '.5px solid black', margin: '10px', opacity: '30%' }} />
        <div className="accordion-field-container-row">
          <div className="field-content-container price-field-container">
            {isFieldInData ? (
              <>
                <div className="field-selection-column-container">
                  <Field
                    name={columnName}
                    initialValue={initialFormState.columnName}
                    validate={(value, allValues) => {
                      const val = allValues[name]?.fromColumn;
                      return val == null ? FORM_ERROR : undefined;
                    }}
                    isEqual={_.isEqual}
                  >
                    {props => (
                      <>
                        <span className="field-select-text">Column containing {DEFAULT_TITLE}:</span>
                        <Select
                          className="field-input-select"
                          options={columnNameOptions}
                          onChange={selectedValue => {
                            props.input.onChange(selectedValue);
                          }}
                          placeholder="Start typing..."
                          value={props?.input?.value}
                        />
                      </>
                    )}
                  </Field>
                </div>
              </>
            ) : (
              <div className="field-selection-column-container">
                <span className="field-select-text">What price do you charge?</span>
                <Field name={fixedValue} type="input" initialValue={initialFormState.fixedValue}>
                  {props => <input className="mx-2, field-input-select" {...props.input} />}
                </Field>
              </div>
            )}
            <div style={{ height: '75px', width: '1px', border: '.5px solid black', margin: '10px', opacity: '30%' }} />
            <div className="price-type-container">
              <div className="field-selection-column-container">
                <Field
                  name={priceType}
                  type="radio"
                  value={ConfigFieldName.TOTAL_TRANSACTION_PRICE}
                  initialValue={initialFormState.priceType}
                  validate={(value, allValues) => {
                    /**
                     * Note: This validator applies to both radio buttons, so it's only provided on this
                     * <Field> for concision.
                     */
                    const selectedPriceType = allValues[name]?.priceType;
                    return selectedPriceType == null ? FORM_ERROR : undefined;
                  }}
                >
                  {props => (
                    <div className="price-type-option">
                      <input className="mx-2" {...props.input} />
                      <span className="field-select-text">Represents the Total Price for all items combined</span>
                    </div>
                  )}
                </Field>
              </div>
              <div className="field-selection-column-container price-type-container">
                <Field
                  name={priceType}
                  type="radio"
                  value={ConfigFieldName.PRICE_PER_UNIT}
                  initialValue={initialFormState.priceType}
                >
                  {props => (
                    <div className="price-type-option">
                      <input className="mx-2" {...props.input} />
                      <span className="field-select-text">Represents the Price Per Item</span>
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfigAccordionItem>
  );
};

export default PriceAccordionItem;
