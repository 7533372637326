import React from 'react';
import SettingsNavigationMenu from '../SettingsNavigationMenu';
import SettingsPageWrapper from '../SettingsPageWrapper';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import Header from '../../header/header';
import { BACKGROUND_GRAY } from '../../../colors';

const EmptyPage = ({ children }) => (
  <HeaderAwarePage>
    <Header />
    <SettingsNavigationMenu label="Settings" />
    <SettingsPageWrapper style={{ backgroundColor: BACKGROUND_GRAY }}>{children}</SettingsPageWrapper>
  </HeaderAwarePage>
);

export default EmptyPage;
