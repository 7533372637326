import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const fetchSchedules = async () => axios.get(`${KA_API_URL}/schedule`);

/**
 * Hooks
 */

const SCHEDULES_QUERY_KEY = ['schedules'];

export const useSchedules = queryConfig =>
  useQuery(
    SCHEDULES_QUERY_KEY,
    async () => {
      const response = await fetchSchedules();
      const schedules = response.data ?? [];

      return schedules;
    },
    queryConfig,
  );
