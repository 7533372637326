import React from 'react';
import {
  WEEKPART_DIMENSION_ID,
  SEASON_DIMENSION_ID,
  WEEKS_SINCE_RELEASE_DIMENSION_ID,
  WEEKPART_FEATURE_COLUMN_NAME,
  SEASON_FEATURE_COLUMN_NAME,
  WEEKS_SINCE_RELEASE_FEATURE_COLUMN_NAME,
} from './revenueUpliftConstants';

const useConfigurableTimeDims = priceStatsMeta => {
  const interpretation = React.useMemo(() => {
    const filterValues = priceStatsMeta?.filter_values ?? [];
    const filterValueDims = new Set(filterValues.map(x => x.column_name));

    return {
      [WEEKPART_DIMENSION_ID]: {
        included: filterValueDims.has(WEEKPART_FEATURE_COLUMN_NAME),
        values: filterValues.find(col => col.column_name === WEEKPART_FEATURE_COLUMN_NAME)?.values,
      },
      [SEASON_DIMENSION_ID]: {
        included: filterValueDims.has(SEASON_FEATURE_COLUMN_NAME),
        values: filterValues.find(col => col.column_name === SEASON_FEATURE_COLUMN_NAME)?.values,
      },
      [WEEKS_SINCE_RELEASE_DIMENSION_ID]: {
        included: filterValueDims.has(WEEKS_SINCE_RELEASE_FEATURE_COLUMN_NAME),
        values: filterValues.find(col => col.column_name === WEEKS_SINCE_RELEASE_FEATURE_COLUMN_NAME)?.values,
      },
    };
  }, [priceStatsMeta?.filter_values]);

  const shouldIncludeDim = React.useCallback(dimId => interpretation[dimId].included, [interpretation]);

  return {
    ...interpretation,
    shouldIncludeDim,
  };
};

export default useConfigurableTimeDims;
