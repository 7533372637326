import axios from 'axios';
import { useMutation } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const updateHoursOfOperationSettings = async hoursOfOperationSettings =>
  axios.put(`${KA_API_URL}/config/hours_of_operation_settings`, hoursOfOperationSettings);

/**
 * Hooks
 */

export const useUpdateHoursOfOperationSettings = mutationConfig =>
  useMutation(async hoursOfOperationSettings => {
    const response = await updateHoursOfOperationSettings(hoursOfOperationSettings);

    return response?.data ?? {};
  }, mutationConfig);
