import classnames from 'classnames';
import React from 'react';
import './header-aware-page.css';

/**
 * DEVELOPER NOTE: Please re-use this component wherever applicable so we don't reproduce
 * the same (or different) solutions to this problem throughout the codebase.
 */

// TODO: Write a test that ensures this component has appropriate CSS applied to it.
// This should protect against the deletion of the CSS that makes this happen.

/**
 * This component serves as a generic container for any "Page" the user might\ navigate
 * to within the application that must account for the space that the ProfitRover-created
 * <Header> component (which utilizes a Bootstrap navbar) takes up. You generally never
 * want your page's content to be obscured by or hidden behind by the header, so the CSS
 * applied to the div returned by this component attempts to manage that problem.
 *
 * If the header can appear or disappear according to some logic on your page, the
 * "showingHeader" prop allows you to programmatically remove any padding added to
 * account for the header.
 */
const HeaderAwarePage = ({ children, className, style, showingHeader = true, scrollable = true }) => {
  return (
    <div
      style={style}
      className={classnames(
        'header-aware-page-container',
        { 'header-present': showingHeader, 'fixed-height': !scrollable },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default HeaderAwarePage;
