import React from 'react';
import { faCheck, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  APP_PROGRESS_STAGE,
  DISPLAYABLE_PROGRESS_STAGES,
  TOTAL_NUMBER_OF_APP_PROGRESS_STEPS,
} from './batchProgressConstants';
import { useListenForBatchProgress } from './batchProgressEventStream';
import ProfitRoverSpinner from '../spinner/ProfitRoverSpinner';
import './workflow-progress.scss';

const WorkflowProgress = ({ batch, workflowId }) => {
  const progressStage = useListenForBatchProgress(workflowId, batch);
  const { stage, step, completionPct } = progressStage;

  const isUpdated =
    stage === APP_PROGRESS_STAGE.COMPLETE || (stage === APP_PROGRESS_STAGE.UPDATING_RESULTS && completionPct === 100);

  const stageLabel = isUpdated ? 'Up-To-Date' : DISPLAYABLE_PROGRESS_STAGES.find(({ value }) => value === stage)?.label;

  const getStageProgress = () => {
    if (isUpdated) {
      return '';
    }

    if (stage === APP_PROGRESS_STAGE.PREPARING_DATA && step >= 1 && step <= TOTAL_NUMBER_OF_APP_PROGRESS_STEPS) {
      return `: Step ${step} of ${TOTAL_NUMBER_OF_APP_PROGRESS_STEPS}`;
    }

    if (stage === APP_PROGRESS_STAGE.UPDATING_RESULTS && completionPct >= 0 && completionPct <= 100) {
      return `: ${completionPct}%`;
    }

    return '';
  };

  const refreshPage = () => {
    window.location.reload();
  };

  // Only show refresh button if a batch was completed since the dashboard was loaded
  const showRefreshButton = isUpdated && batch != null;

  return (
    <div className="workflow-progress-container">
      {isUpdated ? (
        <div className="finished-check">
          <FontAwesomeIcon className="icon" icon={faCheck} />
        </div>
      ) : (
        <ProfitRoverSpinner className="loader" />
      )}
      <div className="progress-stage-label">
        {stageLabel}
        {getStageProgress()}
      </div>
      {showRefreshButton && (
        <button type="button" className="refresh-button" onClick={refreshPage}>
          <FontAwesomeIcon icon={faRotateRight} className="icon" />
          Refresh
        </button>
      )}
    </div>
  );
};

export default WorkflowProgress;
