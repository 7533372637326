import React from 'react';
import GenericErrorBoundaryPage, { Message, MessageBubble, Title } from './GenericErrorBoundaryPage';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';

/**
 * @deprecated
 *
 * See GenericErrorBoundaryPage.jsx for the more flexible alternative that doesn't bake in any
 * assumptions like this one does. This <BasicErrorFallback> has been kept around for ease of transition purposes,
 * but it should not be used going forward.
 *
 * Requires an extended Error class in order to supply the desired buttonText(s) and title.
 */
const BasicErrorFallback = ({ error, resetErrorBoundary, primaryOnLeave, secondaryOnLeave }) => (
  <GenericErrorBoundaryPage>
    <MessageBubble>
      <div className="h-100 d-flex flex-column justify-content-between">
        <div className="mb-5">
          <Title>{error.title ?? 'An error has occurred'}</Title>
          <Message>{error?.message ?? 'We encountered an unexpected error. Please contact our support team.'}</Message>
        </div>
        <div className="d-flex">
          <ProfitRoverPrimaryButton onClick={primaryOnLeave || resetErrorBoundary} style={{ marginRight: 5 }}>
            {error.primaryButtonText ?? 'Try again'}
          </ProfitRoverPrimaryButton>
          {secondaryOnLeave != null && (
            <ProfitRoverSecondaryButton onClick={secondaryOnLeave} style={{ marginLeft: 5 }}>
              {error.secondaryButtonText ?? 'Go Home'}
            </ProfitRoverSecondaryButton>
          )}
        </div>
      </div>
    </MessageBubble>
  </GenericErrorBoundaryPage>
);

export default BasicErrorFallback;
