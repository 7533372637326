import React, { Component } from 'react';
import * as d3 from 'd3';

const width = 1100;
const height = 100;

class ProfitRoverExploreLegend extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static getDerivedStateFromProps(nextProps) {
    const { legends } = nextProps;
    const ticketsCoordinates = [
      { x: 58, y: 40 },
      { x: 317, y: 40 },
      { x: 50, y: 90 },
      { x: 320, y: 90 },
      { x: 50, y: 140 },
    ];

    const priceCoordinates = [
      { x: 650, y: 40 },
      { x: 870, y: 40 },
      { x: 650, y: 90 },
      { x: 870, y: 90 },
      { x: 650, y: 140 },
    ];

    const rectangleCoordinates = [
      { x: 40, y: 20 },
      { x: 310, y: 20 },
      { x: 60, y: 70 },
      { x: 240, y: 70 },
      { x: 70, y: 140 },
    ];

    const lineCoordinates = [
      { x1: 610, y1: 35, x2: 640, y2: 35 },
      { x1: 830, y1: 35, x2: 860, y2: 35 },
      { x1: 630, y1: 35, x2: 660, y2: 35 },
      { x1: 850, y1: 35, x2: 880, y2: 35 },
      { x1: 870, y1: 35, x2: 900, y2: 35 },
    ];

    const rectangleColors = ['rgb(255,249,234)', 'rgb(235,236,238)', 'blue', 'tan', 'orange'];
    const lineColors = ['rgb(255,197,0)', 'rgb(55,68,87)', 'blue', 'tan', 'orange'];

    return {
      legends,
      ticketsCoordinates,
      priceCoordinates,
      rectangleCoordinates,
      rectangleColors,
      lineCoordinates,
      lineColors,
    };
  }

  componentDidMount() {
    this.renderText();
  }

  componentDidUpdate() {
    d3.selectAll('.legendtext').remove();
    this.renderText();
  }

  renderText() {
    const {
      legends,
      ticketsCoordinates,
      priceCoordinates,
      rectangleCoordinates,
      rectangleColors,
      lineCoordinates,
      lineColors,
    } = this.state;
    if (legends.length > 0) {
      const length = legends.length <= 10 ? legends.length : 10;
      if (length === 2) {
        d3.select(`#legend`)
          .append('rect')
          .attr('x', rectangleCoordinates[1].x)
          .attr('y', rectangleCoordinates[1].y)
          .attr('width', legends[0].description.length > 22 ? 260 : 210)
          .attr('class', 'legendtext')
          .attr('height', 30)
          .style('stroke', 'rgb(128,128,128,0.3)')
          .style('fill', rectangleColors[0]);
      } else {
        for (let i = 0; i < length; i++) {
          if (!legends[i].description.includes('Price')) {
            d3.select(`#legend`)
              .append('rect')
              .attr(
                'x',
                legends[i].description.length > 22 ? rectangleCoordinates[i].x : rectangleCoordinates[i].x + 20,
              )
              .attr('y', rectangleCoordinates[i].y)
              .attr('width', legends[i].description.length > 22 ? 260 : legends[i].description.length < 17 ? 165 : 210)
              .attr('class', 'legendtext')
              .attr('height', 30)
              .style('stroke', 'rgb(128,128,128,0.3)')
              .style('fill', rectangleColors[i]);
          }
        }
      }

      if (length === 2) {
        d3.select(`#legend`)
          .append('text')
          .attr('x', legends[0].description.length > 22 ? ticketsCoordinates[1].x + 5 : ticketsCoordinates[1].x)
          .attr('y', ticketsCoordinates[1].y)
          .attr('class', 'legendtext')
          .style('font-size', '18px')
          .attr('class', 'legendtext')
          .style('font-family', 'sans-serif')
          .style('font-weight', 'bold')
          .style('fill', 'black')
          .text(legends[0].description);
      } else {
        for (let i = 0; i < length; i++) {
          if (!legends[i].description.includes('Price')) {
            d3.select(`#legend`)
              .append('text')
              .attr('x', legends[i].description.length > 22 ? ticketsCoordinates[i].x : ticketsCoordinates[i].x + 25)
              .attr('y', ticketsCoordinates[i].y)
              .attr('class', 'legendtext')
              .style('font-size', '18px')
              .attr('class', 'legendtext')
              .style('font-family', 'sans-serif')
              .style('font-weight', 'bold')
              .style('fill', 'black')
              .text(legends[i].description);
          }
        }
      }

      let k = 0;
      for (let i = 0; i < length; i++) {
        if (legends[i].description.includes('Price')) {
          d3.select(`#legend`)
            .append('text')
            .attr('x', priceCoordinates[k].x)
            .attr('y', priceCoordinates[k].y)
            .attr('class', 'legendtext')
            .style('font-size', '18px')
            .style('font-family', 'sans-serif')
            .style('font-weight', 'bold')
            .style('fill', legends[i].color)
            .text(legends[i].description);
          k += 1;
        }
      }

      const numberOfLines = length / 2;
      for (let i = 0; i < numberOfLines; i++) {
        d3.select(`#legend`)
          .append('line')
          .attr('x1', lineCoordinates[i].x1)
          .attr('y1', lineCoordinates[i].y1)
          .attr('class', 'legendtext')
          .attr('x2', lineCoordinates[i].x2)
          .attr('y2', lineCoordinates[i].y2)
          .style('stroke', lineColors[i])
          .style('stroke-width', '3px');
      }
    }
  }

  render() {
    return <svg id="legend" viewBox={`0 0 ${width} ${height}`} />;
  }
}

export default ProfitRoverExploreLegend;
