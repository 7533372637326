import React from 'react';
import { getIntegrationTimestampLabel, DataSourceBase, ResyncDataSourceButton } from './DataSourceRowCommon';

const DatabaseDataSourceRow = ({ datasetConfig, datasets }) => {
  const { name: label = '', is_editable: configIsUnfinished } = datasetConfig;

  // only 1 dataset per database config is supported
  const [dataset] = datasets;
  const { fetched_gmt_datetime: datasetFetchedTime } = dataset ?? {};

  const timestamp = getIntegrationTimestampLabel(datasetFetchedTime, configIsUnfinished);

  const actionButton = <ResyncDataSourceButton datasetConfig={datasetConfig} disabled={configIsUnfinished} />;

  return <DataSourceBase label={label} timestamp={timestamp} actions={actionButton} />;
};

export default DatabaseDataSourceRow;
