/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverLogoIcon } from '../generic/ProfitRoverLogos';
import Header from '../header/header';
import { KA_API_ROOT_URL } from '../../config/baseUrl';
import axios from '../../utils/axios-client';
import './auth.css';

class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submissionSuccess: false,
      error: false,
      errorMessage: 'An error has occured. Please try again.',
    };
  }

  emailChangeHandler = e => {
    this.setState({
      email: e.target.value,
    });
  };

  redirectToLogin = e => {
    e.preventDefault();
    const { history } = this.props;
    return history.push('/login');
  };

  submitForgotPassword = async e => {
    e.preventDefault();
    const { email } = this.state;
    try {
      const response = await axios.post(`${KA_API_ROOT_URL}/forgotpassword`, { params: { email } });
      if (response.status === 200) {
        this.setState({ submissionSuccess: true, error: false });
      }
    } catch (error) {
      this.setState({ error: true });
    }
  };

  render() {
    const redirectVar = null;
    const { submissionSuccess, error, errorMessage } = this.state;

    return (
      <React.Fragment>
        <Header />
        {redirectVar}
        <div className="login-page-container">
          <div className="login-form-container">
            <ProfitRoverLogoIcon className="login-profitrover-logo" />
            <div id="forgot">
              <form
                className="form-signin"
                style={{
                  maxWidth: 450,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <h1 className="h3 mb-3 font-weight-bold" style={{ marginBottom: '30px' }}>
                  Forgot Password
                </h1>
                <p className="h3 mb-3 font-weight-normal" style={{ fontSize: 18 }}>
                  For your security, please enter your email to confirm your identity:
                </p>
                {!submissionSuccess ? (
                  <>
                    <label className="sr-only reset-password-email-text">Email address</label>
                    <input
                      type="Email"
                      className="form-control"
                      placeholder="Email address"
                      required=""
                      onChange={this.emailChangeHandler}
                    />
                    {error && <div className="reset-password-error-text">{errorMessage}</div>}
                    <ProfitRoverPrimaryButton type="submit" onClick={this.submitForgotPassword}>
                      Submit
                    </ProfitRoverPrimaryButton>
                  </>
                ) : (
                  <>
                    <div className="reset-password-success-text">
                      Please check your email. Link expires in 10 minutes.
                    </div>
                    <ProfitRoverPrimaryButton type="submit" onClick={this.redirectToLogin}>
                      Return to Login
                    </ProfitRoverPrimaryButton>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Forgot;
