export const yearLevelCalculateCalendarData = async data => {
  let maxAttendanceUplift = 0;
  for (let i = 0; i < data.length; i++) {
    maxAttendanceUplift =
      data[i].attendance_uplift > maxAttendanceUplift ? data[i].attendance_uplift : maxAttendanceUplift;
  }
  for (let i = 0; i < data.length; i++) {
    data[i].color = (1 - (maxAttendanceUplift - data[i].attendance_uplift) / maxAttendanceUplift).toFixed(2);
  }
  data.sort((a, b) => b.attendance_uplift - a.attendance_uplift);
  return data;
};

export const monthLevelCalculateCalendarData = async data => {
  let maxAttendanceUplift = 0;

  for (let i = 0; i < data.length; i++) {
    maxAttendanceUplift =
      data[i].attendance_uplift > maxAttendanceUplift ? data[i].attendance_uplift : maxAttendanceUplift;
  }

  for (let i = 0; i < data.length; i++) {
    data[i].color = (1 - (maxAttendanceUplift - data[i].attendance_uplift) / maxAttendanceUplift).toFixed(2);
  }
  data.sort((a, b) => b.attendance_uplift - a.attendance_uplift);

  return data;
};
