import _ from 'lodash';
import {
  faChevronRight,
  faChartBar,
  faEllipsisV,
  faEyeSlash,
  faTable,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import moment from 'moment-timezone';
import React from 'react';
import { Dropdown, Nav, Tab } from 'react-bootstrap';
import useCollapse from 'react-collapsed';
import { useAnomalyDetectionContext } from './AnomalyDetectionContext';
import GraphsView from './GraphsView';
import RecordsView from './RecordsView';
import { compactFormatNumber } from '../../util/format';
import { FONT_GRAY, MEDIUM_COOL_GRAY } from '../../../colors';
import { computeTextWidth } from '../../../utils/textTruncation';

// Note: This must match whatever the CSS specifies for this element
const TOP_LABEL_FONT_SIZE_PX = 14;
const TRUNCATED_LABEL_POSTAMBLE = 'More Filters...';
const LABEL_SPACER = ` | `;

export const ColumnHeaders = ({ metricColumnLabel }) => {
  const { dimValuesTextRef } = useAnomalyDetectionContext();

  return (
    <div className="column-headers">
      <div className="justify-content-start" />
      <div ref={dimValuesTextRef} className="justify-content-start" style={{ minWidth: '100%' }}>
        Segment
      </div>
      <div className="align-items-center flex-column">
        <div>Kaizen</div>
        <div>Anomaly Score</div>
      </div>
      <div style={{ textAlign: 'center' }}>{metricColumnLabel ?? 'Metric'}</div>
      <div className="">Baseline</div>
      <div className="">Difference</div>
    </div>
  );
};

const createDimValueLabel = (dimLabel, valueLabel) => `${dimLabel}: ${valueLabel}`;

const useTopLineData = (row, availableTextWidth) => {
  const {
    dimensions: { data: dimensions = [] },
    metricColumn,
    dateColumn,
  } = useAnomalyDetectionContext();

  const { kaizenAnomalyTimeGranularity: timeGranularity } = row;
  const date = row[dateColumn];

  const {
    isTruncated,
    truncatedLabels,
    remainingLabels,
    dimValueLabels,
    productDimIds,
    productDimValues,
  } = React.useMemo(() => {
    const labels = [];

    // Add in the time granularity dimension
    let formattedDate;
    const useDayFormat = ['DAY', 'WEEK'].includes(timeGranularity);
    if (useDayFormat) {
      formattedDate = moment(date, true).format('MMMM D, YYYY');
    } else if (timeGranularity === 'MONTH') {
      formattedDate = moment(date, true).format('MMMM YYYY');
    } else if (timeGranularity === 'QUARTER') {
      const quarterYear = moment(date, true).format('Q YYYY');
      formattedDate = `Q${quarterYear}`;
    }

    labels.push(createDimValueLabel(_.capitalize(_.lowerCase(timeGranularity)), formattedDate));

    const dimIds = [];
    const dimValues = [];
    dimensions.forEach(dim => {
      const dimId = dim.product_dimension_id;

      const dimValue = row[`productDim${dimId}`];
      if (dimValue) {
        dimIds.push(dimId);
        dimValues.push(dimValue);
        labels.push(createDimValueLabel(dim.name, dimValue));
      }
    });

    const computeWidthWithTruncationText = (text, numUnusedValues) => {
      const fullText = `${text}... ${numUnusedValues} ${TRUNCATED_LABEL_POSTAMBLE}`;
      return computeTextWidth(fullText, TOP_LABEL_FONT_SIZE_PX);
    };

    const allLabels = _.clone(labels);
    let labelIndex = 0;

    let concatenatedLabel = '';
    while (labelIndex < allLabels.length) {
      concatenatedLabel += `${allLabels[labelIndex]}${LABEL_SPACER}`;

      const numUnusedValues = allLabels.length - labelIndex;
      if (computeWidthWithTruncationText(concatenatedLabel, numUnusedValues) > availableTextWidth) {
        break;
      }

      labelIndex += 1;
    }
    concatenatedLabel = concatenatedLabel.slice(0, concatenatedLabel.length - LABEL_SPACER.length); // Cut off trailing delimiter and spaces

    // Remove values until the text fits
    let numUnusedValues = allLabels.length - labelIndex - 1;
    while (computeWidthWithTruncationText(concatenatedLabel, numUnusedValues) > availableTextWidth) {
      const { length } = concatenatedLabel;
      const labelLength = allLabels[labelIndex].length;

      concatenatedLabel = concatenatedLabel.slice(0, length - labelLength - 3);

      labelIndex -= 1;
      numUnusedValues = allLabels.length - labelIndex;
    }
    const labelsRemaining = allLabels.length - (labelIndex + 1);

    const usedAllLabels = labelsRemaining < 1;
    if (!usedAllLabels) {
      concatenatedLabel = `${concatenatedLabel} ... ${labelsRemaining} ${TRUNCATED_LABEL_POSTAMBLE}`;
    }

    return {
      isTruncated: !usedAllLabels,
      truncatedLabels: concatenatedLabel, // Part of the text that fits
      remainingLabels: allLabels.slice(labelIndex + 1, allLabels.length).join(LABEL_SPACER), // Everything else
      productDimIds: dimIds,
      productDimValues: dimValues,
    };
  }, [timeGranularity, dimensions, availableTextWidth, date, row]);

  const { kaizenAnomalyBaseline } = row;
  let { kaizenAnomalyScore } = row;
  kaizenAnomalyScore = kaizenAnomalyScore.toFixed(0);

  const metricValue = row[metricColumn];
  const formattedMetricValue = compactFormatNumber(metricValue, { formatAsCurrency: true });

  const baseline = compactFormatNumber(kaizenAnomalyBaseline, { formatAsCurrency: true });

  const difference = Math.abs(metricValue - kaizenAnomalyBaseline);
  const formattedDifference = compactFormatNumber(difference, { formatAsCurrency: true });

  return {
    isTruncated,
    truncatedLabels,
    remainingLabels,
    dimValueLabels,
    kaizenAnomalyScore,
    formattedMetricValue,
    baseline,
    formattedDifference,
    productDimIds,
    productDimValues,
    date,
    timeGranularity,
  };
};

const ActionMenuButton = React.forwardRef(({ children, onClick, isOpen }, ref) => {
  return (
    <button
      type="button"
      className={classnames('actions-menu-button', { open: isOpen })}
      ref={ref}
      onClick={e => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      {children}
    </button>
  );
});
ActionMenuButton.displayName = 'ActionMenuButton';

const ActionMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      {children}
    </div>
  );
});

ActionMenu.displayName = 'ActionMenu';

const AnomalyAccordion = ({ row }) => {
  const { dimValuesMaxTextWidth } = useAnomalyDetectionContext();
  const {
    isTruncated,
    truncatedLabels,
    remainingLabels,
    kaizenAnomalyScore,
    formattedMetricValue,
    baseline,
    formattedDifference,
    productDimIds,
    productDimValues,
    date,
    timeGranularity,
  } = useTopLineData(row, dimValuesMaxTextWidth);

  const [isExpanded, setExpanded] = React.useState(false);
  const [isHidden, setIsHidden] = React.useState(false);
  const [isActionMenuOpen, setIsActionMenuOpen] = React.useState(false);
  const [renderTable, setRenderTable] = React.useState(false);

  const [filtersIsExpanded, setFiltersIsExpanded] = React.useState(false);

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  const toggleActionMenuOpen = () => {
    setIsActionMenuOpen(!isActionMenuOpen);
  };

  const hideAnomaly = e => {
    e.stopPropagation();
    setIsHidden(true);
  };

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    duration: 333,
    // "renderTable" lags behind "isExpanded" to improve rendering performance when painting/unpainting AG Grid tables
    onExpandEnd: () => setRenderTable(true),
    onCollapseStart: () => {
      setRenderTable(false);
    },
  });

  const { getCollapseProps: getFiltersCollapseProps, getToggleProps: getFiltersToggleProps } = useCollapse({
    isExpanded: filtersIsExpanded,
    duration: 333,
  });

  return (
    <div className="accordion" style={{ display: isHidden ? 'none' : null }}>
      <div
        className="top-line"
        {...getToggleProps({
          onClick: toggleExpanded,
        })}
        tabIndex={null}
      >
        <div className="actions">
          <Dropdown onToggle={toggleActionMenuOpen}>
            <Dropdown.Toggle id="actionsMenuDropdown" as={ActionMenuButton} isOpen={isActionMenuOpen}>
              <FontAwesomeIcon
                icon={faEllipsisV}
                color={isActionMenuOpen ? 'white' : MEDIUM_COOL_GRAY}
                style={{ fontSize: '0.95rem' }}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu as={ActionMenu}>
              <Dropdown.Item as="button" eventKey="hideAnomaly" onClick={hideAnomaly}>
                <FontAwesomeIcon icon={faEyeSlash} color={FONT_GRAY} className="mr-2" />
                Hide Anomaly
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button
            type="button"
            className={classnames('expand-button', { expanded: isExpanded })}
            {...getToggleProps({
              onClick: toggleExpanded,
            })}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
          {/* <div className="star-button">
              <FontAwesomeIcon icon={faStar} />
            </div> */}
        </div>
        <div className="segment-list">
          <div className="dim-value-label-text">{truncatedLabels}</div>
        </div>
        <div className="score">{kaizenAnomalyScore ?? '-'}</div>
        <div className="metric">{formattedMetricValue ?? '-'}</div>
        <div className="metric">{baseline}</div>
        <div className="metric">{formattedDifference}</div>
      </div>

      <Tab.Container defaultActiveKey="records">
        {isExpanded && (
          <>
            {isTruncated && (
              <div className="filters-area py-2">
                <div
                  className="filters-expansion-handle"
                  {...getFiltersToggleProps({
                    onClick: () => setFiltersIsExpanded(!filtersIsExpanded),
                  })}
                >
                  <div className="label">
                    More Filters
                    <FontAwesomeIcon
                      className="ml-2"
                      color="black"
                      icon={filtersIsExpanded ? faChevronUp : faChevronDown}
                      style={{ marginBottom: -2 }}
                    />
                  </div>
                </div>
                <div className="filters-full-list" {...getFiltersCollapseProps()}>
                  {remainingLabels}
                </div>
              </div>
            )}
            <Nav className="expanded-view-tabs">
              <Nav.Item>
                <Nav.Link eventKey="records">
                  <FontAwesomeIcon icon={faTable} className="tab-icon" size="lg" />
                  Records
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="graphs">
                  <FontAwesomeIcon icon={faChartBar} className="tab-icon" size="lg" />
                  Graphs
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </>
        )}

        <Tab.Content {...getCollapseProps()}>
          <Tab.Pane eventKey="records" as="section" className="expanded-view" mountOnEnter unmountOnExit>
            <RecordsView
              renderTable={renderTable}
              productDimIds={productDimIds}
              productDimValues={productDimValues}
              date={date}
              timeGranularity={timeGranularity}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="graphs" className="expanded-view" as="section" mountOnEnter unmountOnExit>
            {isExpanded && (
              <GraphsView
                date={date}
                timeGranularity={timeGranularity}
                productDimIds={productDimIds}
                productDimValues={productDimValues}
                anomalyScore={kaizenAnomalyScore}
              />
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default AnomalyAccordion;
