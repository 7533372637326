import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

const fetchUserPreferences = async (userId, subject) =>
  axios.get(`${KA_API_URL}/users/${userId}/preferences/${subject}`);

const updateUserPreferences = async (userId, subject, value) =>
  axios.put(`${KA_API_URL}/users/${userId}/preferences/${subject}`, value);

/**
 * Hooks
 */

const USER_PREFERENCES_QUERY_KEY_BASE = 'user_preferences';

export const usePreferences = (userId, subject, queryConfig) =>
  useQuery(
    [USER_PREFERENCES_QUERY_KEY_BASE, userId, subject],
    async () => {
      const response = await fetchUserPreferences(userId, subject);

      return response.data;
    },
    queryConfig,
  );

/**
 * Mutations
 */

export const useEditPreferencesMutation = (userId, subject, mutationConfig) =>
  useMutation(async value => updateUserPreferences(userId, subject, value), mutationConfig);
