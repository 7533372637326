import React from 'react';
import { ReactComponent as SecureDataBadge } from '../../../images/secure-data-badge.svg';
import './secure-data-banner.scss';

const SecureDataBanner = () => {
  return (
    <div className="secure-data-banner">
      <div className="secure-data-badge">
        <SecureDataBadge />
      </div>
      Your data is fully encrypted and protected. We will never sell or share your data.
    </div>
  );
};

export default SecureDataBanner;
