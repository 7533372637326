/* eslint-disable react/display-name */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { DropdownSelect } from '../../forms/GenericFormComponents';

export default forwardRef((props, ref) => {
  const [value, setValue] = useState('');
  const [regionOptions, setRegionOptions] = useState(props.values);

  const onChangeHandler = option => setValue(option);

  const onCountrySelection = regionDropdownOptions => setRegionOptions(regionDropdownOptions);

  const isDisabled = props.colDef.field === 'industry_description';

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    afterGuiAttached: () => setValue(props.value),
    onCountrySelection: regionDropdownOptions => {
      onCountrySelection(regionDropdownOptions);
      setValue(regionDropdownOptions[0].value);
    },
  }));

  const isRegionColumn = props.colDef.field === 'state';

  return (
    <DropdownSelect
      disabled={isDisabled}
      showPlaceholder={false}
      options={isRegionColumn ? regionOptions : props.values}
      onSelect={onChangeHandler}
      value={value}
      className="location-select-element"
    />
  );
});
