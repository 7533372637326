/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/display-name */
import React from 'react';
import ReactTable from 'react-table';
import Modal from 'react-bootstrap/Modal';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faArrowUp, faArrowDown, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import pagination from '../dashboards/eo/eoPagination';
import {
  ProfitRoverCancelButton,
  ProfitRoverPrimaryButton,
  ProfitRoverSecondaryButton,
} from '../forms/ProfitRoverButtons';
import ProfitRoverTooltip from '../generic/ProfitRoverTooltip';
import { formatNumber, convertUTCDateStringToLocalDateString } from '../util/format';

function actionComponent(onEditClick, onDeleteClick, rowData) {
  return (
    <div>
      <FontAwesomeIcon name="editOpen" className="Icon" icon={faEdit} onClick={e => onEditClick(e, rowData)} />
      <FontAwesomeIcon icon={faTrash} className="Icon" onClick={e => onDeleteClick(e, rowData)} />
    </div>
  );
}
actionComponent.displayName = 'Action';

const dataSetTable = props => {
  const {
    datasets,
    datasetsAvailable,
    onSortClick,
    sortObject,
    openEditModal,
    onEditClick,
    allDataConfigurations,
    onCreateNewDataConfiguration,
    onDataConfigurationSave,
    onDataConfigurationSelectChange,
    onDeleteClick,
    onCloseEditModal,
  } = props;
  const headerStyleObj = {
    fontSize: '14px',
    height: '40px',
    background: '#ffc500',
    fontWeight: 'bold',
    boxSizing: 'border-box',
  };

  const tableDataStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };

  const renderSortIcon = columnKey => {
    const isSorted = sortObject[columnKey].is_sorted === true;
    const isAscendingSort = sortObject[columnKey].direction === 'ASC';

    return (
      isSorted &&
      (isAscendingSort ? (
        <FontAwesomeIcon className="datacenter-icon" name="editClose" icon={faArrowUp} />
      ) : (
        <FontAwesomeIcon className="datacenter-icon" name="editClose" icon={faArrowDown} />
      ))
    );
  };
  return (
    <div>
      <h5>Datasets</h5>
      <ReactTable
        data={datasets}
        columns={[
          {
            Header: (
              <div className="column-header column-header-center-align">
                Data Type
                {renderSortIcon('data_type_description')}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'data_type_description',
            style: { whiteSpace: 'unset' },
            accessor: d => d.data_type_description,
            sortable: true,
          },
          {
            Header: (
              <div className="column-header column-header-center-align">
                Dataset Configuration
                {renderSortIcon('data_configuration')}
              </div>
            ),
            headerStyle: headerStyleObj,
            style: { whiteSpace: 'unset' },
            id: 'data_configuration',
            accessor: d => d.data_configuration,
            sortable: true,
          },
          {
            Header: (
              <div className="column-header column-header-center-align">
                Description
                {renderSortIcon('description')}
              </div>
            ),
            headerStyle: headerStyleObj,
            style: { whiteSpace: 'unset' },
            id: 'description',
            accessor: d => d,
            sortable: true,
            Cell: rowData => {
              const alertIcon = (
                <FontAwesomeIcon className="bad-dataset-icon" name="badDataset" icon={faExclamationTriangle} />
              );
              const tooltipText =
                'Dataset upload error - see Notification Center for more details. Please delete and reupload dataset.';
              const datasetHasError = rowData.value.is_processed && !rowData.value.is_validated;
              const datasetDescription = datasetHasError ? (
                <div>
                  <ProfitRoverTooltip
                    shouldDisplayTooltip={datasetHasError}
                    tooltipId="bad-dataset"
                    placement="bottom"
                    tooltipClass="bad-dataset-tooltip"
                    tooltipText={tooltipText}
                  >
                    <span>{alertIcon} </span>
                  </ProfitRoverTooltip>
                  {rowData.value.description}
                </div>
              ) : (
                rowData.value.description
              );

              return datasetDescription;
            },
          },
          {
            Header: (
              <div className="column-header column-header-center-align">
                Source
                {renderSortIcon('source')}
              </div>
            ),
            headerStyle: headerStyleObj,
            style: { whiteSpace: 'unset' },
            id: 'source',
            accessor: d => d.source,
            sortable: true,
          },

          {
            Header: (
              <div className="column-header column-header-center-align">
                Last Processed
                {renderSortIcon('last_fetched')}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'last_fetched',
            style: { whiteSpace: 'unset' },
            accessor: d => convertUTCDateStringToLocalDateString(d.last_fetched),
            sortable: true,
          },
          {
            Header: (
              <div className="column-header column-header-center-align">
                Records Added
                {renderSortIcon('record_count')}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'record_count',
            style: { whiteSpace: 'unset' },
            accessor: d => (d.record_count == null ? '-' : formatNumber(d.record_count)),
            sortable: true,
          },
          {
            Header: <div className="column-header column-header-center-align">Actions</div>,
            headerStyle: headerStyleObj,
            id: 'actions',
            accessor: d => d,
            width: 70,
            sortable: false,
            Cell: rowData => actionComponent(onEditClick, onDeleteClick, rowData),
          },
        ]}
        getTheadThProps={(state, rowInfo, column) => {
          return {
            onClick: () => {
              if (column.sortable) {
                onSortClick(column.id);
              }
            },
          };
        }}
        getTdProps={(state, rowInfo) => {
          if (typeof rowInfo !== 'undefined') {
            return {
              style: tableDataStyleObject,
            };
          }
          return {};
        }}
        noDataText={datasetsAvailable ? 'Loading Data' : 'No Datasets Found'}
        defaultPageSize={4}
        pageSizeOptions={[4, 10, 20, 25, 50, 100]}
        PaginationComponent={pagination}
        showPagination={datasets.length > 4}
        className="-striped -highlight"
      />
      <Modal show={openEditModal} className="file-upload-modal">
        <div>
          <div className="file-upload-modal-header">
            <h4> Edit Data Configuration</h4>
          </div>
          <br />
          <div className="row pb-1">
            <span className="col-4 data-config-select-span">
              <p>Select Existing Data</p>
              <p>Configuration</p>
            </span>
            <span className="col-5">
              <select className="userSelect" name="datasetConfigId" onChange={onDataConfigurationSelectChange}>
                {allDataConfigurations.map(configuration => (
                  <option key={configuration.value} value={configuration.value}>
                    {configuration.label}
                  </option>
                ))}
              </select>
            </span>
            <span className="col-2">
              <ProfitRoverPrimaryButton onClick={onDataConfigurationSave}>Save</ProfitRoverPrimaryButton>
            </span>
          </div>

          <div>
            <h4 className="file-upload-modal-buttons">OR</h4>
          </div>

          <div className={classnames('file-upload-modal-buttons', 'datacenter-file-upload-button-bar')}>
            <ProfitRoverSecondaryButton onClick={onCreateNewDataConfiguration}>
              Create New Data Configuration
            </ProfitRoverSecondaryButton>
            <ProfitRoverCancelButton onClick={onCloseEditModal}>Close</ProfitRoverCancelButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default dataSetTable;
