import React, { useCallback } from 'react';
import { faArrowRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { Link, useHistory } from 'react-router-dom';
import { useNavigateToPOSGuidedSetupStep } from './commonStepControl';
import { convertItemClassesToIndustryDataTypes, useCreateDatasetConfigForManual } from './createDatabaseRecords';
import { useUpdateGuidedSetupState } from './guidedSetupHooks';
import PreviousStep from './PreviousStep';
import { ProgressBar, TrackedSteps } from './ProgressBar';
import SecureDataBanner from './SecureDataBanner';
import { INDUSTRY_AND_DATA_TYPE_TO_FIELDS } from '../../data-center/datasetConfig/DatasetConfigFormConstants';
import { isFileBasedSource } from '../../dataSources/dataSourceConstants';
import { PageHeader, VerticalSeparator } from '../../forms/GenericFormComponents';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';
import GenericLoadingPage from '../../generic/GenericLoadingPage';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import Header from '../../header/header';
import ProfitRoverSpinner, { CenteredProfitRoverSpinner } from '../../spinner/ProfitRoverSpinner';
import { useCreateDataset, useDeleteDataset, useUpdateDataset } from '../../../data-access/mutation/datasets';
import { useDatasetConfigs } from '../../../data-access/query/datasetConfigs';
import { useDataset, useDatasets } from '../../../data-access/query/datasets';
import { useGuidedSetup } from '../../../data-access/query/guidedSetup';
import fileUploadIcon from '../../../images/file-upload-icon.svg';
import uploadIcon from '../../../images/icon-material-file-upload.svg';
import './manual-file-upload.scss';

const guidedSetupUploadPath = '/guided-setup/manual/file-upload';
const guidedSetupConfigPath = '/guided-setup/manual/configure';
const dataSourceUploadPath = '/data-sources/add-new/manual/file-upload';
const dataSourceConfigPath = '/data-sources/add-new/manual/configure';

export const mapIndustryDataTypeToFields = datasetConfig => {
  const { industry, data_type: dataType } = datasetConfig;
  return INDUSTRY_AND_DATA_TYPE_TO_FIELDS[industry + dataType] ?? [];
};

const SUPPORTED_FILE_TYPES = '.xlsx, .xls, .csv';
const DEFAULT_TEXT_PROMPT = 'Drag & Drop your file here';

export const FileDropSelectArea = props => {
  const { className, datasetConfig, fields, onFinish, textPrompt = DEFAULT_TEXT_PROMPT } = props;

  const [errorMessage, setErrorMessage] = React.useState();

  const createDataset = useCreateDataset({
    onError: () => {
      setErrorMessage('File Upload Failed');
    },
  });
  const { isLoading: isProcessing } = createDataset;

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length !== 0) {
        const datasetConfigId = datasetConfig?.dataset_config_id;
        const dataset = new FormData();
        const fileToUpload = acceptedFiles[0];
        const fileName = fileToUpload.name;

        dataset.append('file', fileToUpload);
        dataset.append('user_id', localStorage.getItem('id'));
        if (datasetConfigId != null) {
          dataset.append('config_id', datasetConfigId);
        }

        const data = await createDataset.mutateAsync(dataset);
        const { dataset_id: datasetId } = data;

        const datasetDetails = {
          fileName,
          datasetId,
          datasetConfig,
          fields,
        };

        if (_.isFunction(onFinish)) {
          await onFinish(datasetDetails);
        }
      } else if (fileRejections.length !== 0) {
        const badFilename = fileRejections[0].file.path;
        // though unlikely there could be more than one error, but just display the first one
        const badFileMessage = fileRejections[0].errors[0].message;
        setErrorMessage(`Cannot process file ${badFilename}: ${badFileMessage}`);
      }
    },
    [createDataset, datasetConfig, fields, onFinish],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: SUPPORTED_FILE_TYPES,
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <>
      <div className={classnames('file-upload-container', className)}>
        <div {...getRootProps({ className: 'dropzone' })}>
          {isProcessing ? (
            <CenteredProfitRoverSpinner className="processing-spinner" />
          ) : (
            <>
              <input {...getInputProps()} />
              <img width="60px" className="my-2" src={uploadIcon} alt="Upload a file" />
              <p className="drag-and-drop-text">{textPrompt}</p>
              <p className="supported-files-text">Supported files: {SUPPORTED_FILE_TYPES}</p>
              <p className="drag-and-drop-text">OR</p>
              <ProfitRoverSecondaryButton onClick={open}>Browse</ProfitRoverSecondaryButton>
            </>
          )}
        </div>
      </div>
      {errorMessage != null && (
        <>
          <div className="error-message">{errorMessage}</div>
          <div className="try-again">
            <p>Please Try Again</p>
          </div>
        </>
      )}
    </>
  );
};

/**
 * Supports a "go back" behavior that involves linking to different pages
 * @deprecated
 */
export const FileUploadSuccess = ({ fileName, datasetId, datasetConfigId, restartPath, configPath }) => {
  const history = useHistory();
  const deleteDataset = useDeleteDataset();

  const handleConfigClick = async () => {
    history.push(configPath, {
      fileId: datasetId,
      dsConfigIdToEdit: datasetConfigId,
    });
  };

  const cleanDatasetRecords = async () => {
    await deleteDataset.mutateAsync(datasetId);
  };

  return (
    <>
      <div className="file-upload-container">
        <div className="file-upload-success">
          <FontAwesomeIcon className="success-icon" icon={faCheckCircle} />
          <p className="drag-and-drop-text flex-center">
            <span className="word-no-wrap">File Name: </span>&nbsp;&nbsp;
            <span className="word-wrap"> {fileName}</span>
          </p>
          <p className="supported-files-text wrong-data-set-text">
            <span>Wrong Dataset? </span>
            <i className="choose-another-file">
              <Link
                to={{ pathname: restartPath, state: { datasetConfigId } }}
                className="underline"
                onClick={cleanDatasetRecords}
              >
                Choose a Different File
              </Link>
            </i>
          </p>
          <ProfitRoverPrimaryButton onClick={handleConfigClick}>
            Configure Data Source Settings
            <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '10px' }} />
          </ProfitRoverPrimaryButton>
        </div>
      </div>
    </>
  );
};

export const GenericFileUploadSuccess = ({
  fileName,
  isAccepting,
  onAcceptDataset,
  onRejectDataset,
  buttonText = 'Configure Data Source Settings',
}) => {
  let button = (
    <ProfitRoverPrimaryButton onClick={onAcceptDataset}>
      {buttonText}
      <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '10px' }} />
    </ProfitRoverPrimaryButton>
  );

  if (isAccepting != null && isAccepting) {
    button = (
      <ProfitRoverPrimaryButton className="d-flex justify-content-center align-items-center" style={{ minWidth: 312 }}>
        <ProfitRoverSpinner />
      </ProfitRoverPrimaryButton>
    );
  }

  return (
    <div className="file-upload-container">
      <div className="file-upload-success">
        <FontAwesomeIcon className="success-icon" icon={faCheckCircle} />
        <p className="drag-and-drop-text flex-center">
          <span className="word-no-wrap">File Name: </span>&nbsp;&nbsp;
          <span className="word-wrap"> {fileName}</span>
        </p>
        <p className="supported-files-text wrong-data-set-text">
          <span>Wrong Dataset? </span>
          {/* This cannot be a <Link> because the onClick must take place before navigation */}
          <button type="button" className="reject-dataset mt-1" onClick={onRejectDataset}>
            <i className="choose-another-file">Choose a Different File</i>
          </button>
        </p>
        {button}
      </div>
    </div>
  );
};

/**
 * Supports generic onClick behavior on "Choose a Different File"
 */
export const DatasetUploadSuccess = ({ fileName, onReset }) => (
  <>
    <div className="file-upload-container">
      <div className="file-upload-success">
        <FontAwesomeIcon className="success-icon" icon={faCheckCircle} />
        <p className="drag-and-drop-text flex-center">
          <span className="word-no-wrap">File Name: </span>&nbsp;&nbsp;
          <span className="word-wrap"> {fileName}</span>
        </p>
        <p className="supported-files-text wrong-data-set-text">
          <span>Wrong Dataset? </span>
          <button type="button" className="underline choose-another-file" onClick={onReset}>
            <em>Choose a Different File</em>
          </button>
        </p>
      </div>
    </div>
  </>
);

export const DatasetFieldDescriptions = ({ fields = [] }) => (
  <div className="dataset-field-descriptions">
    {fields.length > 0 &&
      fields.map(field => (
        <div className="dataset-field-descriptor" key={field}>
          {field}
        </div>
      ))}
  </div>
);

export const FileUpload = props => {
  const { children, datasetConfigName } = props;

  return (
    <div className="file-upload-container file-upload-base">
      <PageHeader>
        Upload a Sales Transaction Dataset <img className="dataset-icon" src={fileUploadIcon} alt="Add a dataset" />
        <VerticalSeparator style={{ marginLeft: '2px' }} />
        {datasetConfigName}
      </PageHeader>

      <SecureDataBanner />

      <div className="instructions-text-container">
        <div>
          We need you to upload a dataset manually because we don&apos;t have an integration readily available for your
          indicated system.
        </div>
        <i>Don&apos;t have your dataset ready to upload? No worries!</i>
        <span>&nbsp;&nbsp;We are saving your progress, and you can pick back up at this step once you are ready.</span>
        <br />
        <div className="dataset-field-instructions">
          In the following steps, we&apos;re going to be asking you about the following types of information related to
          your data:
        </div>
      </div>

      {children}
    </div>
  );
};

const ManualFilePageLayout = props => {
  const { children, isLoading = false, fromGuidedSetup = false } = props;

  return (
    <HeaderAwarePage>
      <Header />
      {fromGuidedSetup && <ProgressBar activeStep={TrackedSteps.CONNECT_DATA} />}
      {isLoading ? <GenericLoadingPage /> : <div className="container mt-5">{children}</div>}
    </HeaderAwarePage>
  );
};

/**
 * Returns Boolean state values that should be used for automatically redirecting the user and
 * preventing previous step navigation once the user has passed the "point of no return".
 */
export const useGuidedSetupFileUploadStatus = () => {
  const { data: guidedSetup = {}, isFetching: isGuidedSetupLoading } = useGuidedSetup();
  const {
    dataset_id: datasetId,
    dataset_config_id: datasetConfigId,
    naics_industry: { industry } = {},
    data_source: dataSource,
  } = guidedSetup;
  const configName = dataSource?.name;

  const missingNecessaryInfo = !configName || !industry;
  const datasetUploadedButNotConfirmed = datasetId != null && datasetConfigId == null;
  const datasetUploadedAndConfirmed = datasetId != null && datasetConfigId != null;

  /**
   * Once a dataset file has been uploaded for the first time, the Guided Setup document will contain a dataset_id
   * key-value pair. So long as this key is present (regardless of its value) and the user has picked a file-based
   * data source, the user may no longer return to the POS Setup Page. They may change the file they've uploaded as many
   * times as they like, but they MUST proceed in the Guided Setup with an uploaded file after having done it once.
   */
  const lockedInToFileUpload = isFileBasedSource(dataSource?.source) && 'dataset_id' in guidedSetup;

  return {
    isGuidedSetupLoading,
    lockedInToFileUpload,
    missingNecessaryInfo,
    datasetUploadedButNotConfirmed,
    datasetUploadedAndConfirmed,
  };
};

export const GuidedSetupManualFileUpload = () => {
  const history = useHistory();

  const guidedSetupQuery = useGuidedSetup();

  const { data: guidedSetup = {} } = guidedSetupQuery;
  const { dataset_id: datasetId, naics_industry: { industry } = {}, data_source: dataSource } = guidedSetup;
  const configName = dataSource?.name;
  const itemClasses = dataSource?.item_classes;

  const {
    isGuidedSetupLoading,
    lockedInToFileUpload,
    missingNecessaryInfo,
    datasetUploadedButNotConfirmed,
    datasetUploadedAndConfirmed,
  } = useGuidedSetupFileUploadStatus();

  const { data: dataset, isLoading: isLoadingDataset } = useDataset(datasetId, {
    enabled: !isGuidedSetupLoading && datasetId != null,
  });
  const { updateGuidedSetup } = useUpdateGuidedSetupState();
  const { onClick } = useNavigateToPOSGuidedSetupStep();

  const [industryDataType] = convertItemClassesToIndustryDataTypes(itemClasses);
  const { dataType } = industryDataType;
  const fields = INDUSTRY_AND_DATA_TYPE_TO_FIELDS[industry + dataType];

  const isLoading = isGuidedSetupLoading || isLoadingDataset;

  if (!isLoading) {
    if (missingNecessaryInfo) {
      // Go back
      history.push('/guided-setup');
    } else if (datasetUploadedButNotConfirmed) {
      // Go forward to dataset confirmation page
      const { file_name: fileName } = dataset;
      history.push(`${guidedSetupUploadPath}-success`, { fileName, datasetId, fields });
    } else if (datasetUploadedAndConfirmed) {
      // Begin dataset configuration
      history.push('/guided-setup/manual/configure');
    }

    const willRedirect = _.some([missingNecessaryInfo, datasetUploadedButNotConfirmed, datasetUploadedAndConfirmed]);
    if (willRedirect) {
      return <ManualFilePageLayout isLoading />;
    }
  }

  return (
    <ManualFilePageLayout isLoading={isLoading} fromGuidedSetup>
      {!lockedInToFileUpload && !isGuidedSetupLoading && <PreviousStep onClick={onClick} />}

      <FileUpload datasetConfigName={configName}>
        <DatasetFieldDescriptions fields={fields} />
        <FileDropSelectArea
          fields={fields}
          onFinish={async datasetDetails => {
            const { datasetId: uploadedDatasetId } = datasetDetails;
            await updateGuidedSetup({ dataset_id: uploadedDatasetId });

            history.push(`${guidedSetupUploadPath}-success`, datasetDetails);
          }}
        />
      </FileUpload>
    </ManualFilePageLayout>
  );
};

export const GuidedSetupFileUploadSuccess = props => {
  const { location, history } = props;
  const { state = {} } = location;
  const { fields } = state;
  let { fileName, datasetId } = state;

  const guidedSetup = useGuidedSetup();
  const { isFetching } = guidedSetup;
  const guidedSetupData = guidedSetup?.data ?? {};
  const {
    naics_industry: { naics_code: naicsCode, ka_code: kaCode } = {},
    data_source: { name: configName, item_classes: itemClasses } = {},
  } = guidedSetupData;

  let { dataset_config_id: datasetConfigId } = guidedSetupData;
  ({ dataset_id: datasetId } = guidedSetupData);

  const { data: dataset = {}, isLoading: isLoadingDataset } = useDataset(datasetId, {
    enabled: datasetId != null,
  });
  ({ file_name: fileName } = dataset);

  const { updateGuidedSetup } = useUpdateGuidedSetupState();
  const createDatasetConfigForManual = useCreateDatasetConfigForManual();
  const updateDatasetMutation = useUpdateDataset();
  const deleteDatasetMutation = useDeleteDataset();

  const onAcceptDataset = async () => {
    if (!datasetConfigId) {
      // Create dataset config
      ({ datasetConfigId } = await createDatasetConfigForManual({
        configName,
        itemClasses,
        naicsCode,
        kaCode,
      }));

      // Store dataset config id on the Guided Setup documentt
      await updateGuidedSetup({ dataset_config_id: datasetConfigId });
    }

    const datasetNotProperlyLinked = dataset.dataset_config_id !== datasetConfigId;
    if (datasetNotProperlyLinked) {
      // Link chosen dataset to config
      await updateDatasetMutation.mutateAsync({ datasetId, dataset: { dataset_config_id: datasetConfigId } });
    }

    history.push(guidedSetupConfigPath, {
      fileId: datasetId,
      dsConfigIdToEdit: datasetConfigId,
    });
  };

  const cleanDatasetRecords = async () => {
    await Promise.all([
      deleteDatasetMutation.mutateAsync(datasetId),
      // Remove the value but not the key to preserve the fact that the user has uploaded something at least once
      updateGuidedSetup({ dataset_id: null }),
    ]);

    history.push(guidedSetupUploadPath);
  };

  return (
    <ManualFilePageLayout isLoading={isFetching || isLoadingDataset} fromGuidedSetup>
      <FileUpload datasetConfigName={configName}>
        <DatasetFieldDescriptions fields={fields} />
        <GenericFileUploadSuccess
          fileName={fileName}
          onAcceptDataset={onAcceptDataset}
          onRejectDataset={cleanDatasetRecords}
        />
      </FileUpload>
    </ManualFilePageLayout>
  );
};

export const DataSourceManualFileUpload = props => {
  const { datasetConfigId } = props.location.state ?? {};
  const { data: datasetConfigs = [], isLoading: isLoadingDatasetConfigs } = useDatasetConfigs();
  const { data: datasets = [], isLoading: isLoadingDatasets } = useDatasets();

  const datasetConfig = _.find(datasetConfigs, { dataset_config_id: datasetConfigId }) ?? {};
  const dataset = _.find(datasets, { dataset_config_id: datasetConfigId }) ?? {};
  const { dataset_id: datasetId } = dataset;

  const fields = mapIndustryDataTypeToFields(datasetConfig);

  const history = useHistory();

  if (!isLoadingDatasetConfigs && !isLoadingDatasets) {
    if (datasetConfigId == null) {
      // Go back
      history.push('/data-sources');
    } else if (datasetId != null) {
      // Go forward
      history.push('/data-sources/add-new/manual/configure', { dsConfigIdToEdit: datasetConfigId, fileId: datasetId });
    }
  }

  return (
    <ManualFilePageLayout isLoading={isLoadingDatasetConfigs || isLoadingDatasets}>
      <FileUpload datasetConfigName={datasetConfig.name}>
        <DatasetFieldDescriptions fields={fields} />

        <FileDropSelectArea
          onFinish={datasetDetails => {
            history.push(`${dataSourceUploadPath}-success`, datasetDetails);
          }}
          datasetConfig={datasetConfig}
          fields={fields}
        />
      </FileUpload>
    </ManualFilePageLayout>
  );
};

export const DataSourceManualFileUploadSuccess = props => {
  const { location } = props;
  const { state } = location;
  const { fileName, datasetId, datasetConfig, fields } = state;

  return (
    <ManualFilePageLayout>
      <FileUpload datasetConfigName={datasetConfig?.name} datasetConfig={datasetConfig}>
        <DatasetFieldDescriptions fields={fields} />

        <FileUploadSuccess
          fileName={fileName}
          datasetId={datasetId}
          datasetConfigId={datasetConfig.dataset_config_id}
          restartPath={dataSourceUploadPath}
          configPath={dataSourceConfigPath}
        />
      </FileUpload>
    </ManualFilePageLayout>
  );
};
