import { Ability } from '@casl/ability';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_CUSTOMER_PERMISSIONS_BASE = `${KA_API_URL}/permissions`;

/**
 * Requests
 */

const fetchCustomerPermissions = async () => axios.get(GET_CUSTOMER_PERMISSIONS_BASE);

/**
 * Hooks
 */

const PERMISSIONS_QUERY_KEY_BASE = 'permissions';

// eslint-disable-next-line import/prefer-default-export
export const useUserAbility = () => {
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated ?? false);
  const userIdStr = localStorage.getItem('id');
  const userId = Number(userIdStr);

  return useQuery(
    [PERMISSIONS_QUERY_KEY_BASE, userId],
    async () => {
      const { data: customerPermissions } = await fetchCustomerPermissions();
      const myPermissions = customerPermissions.find(permissionsForUser => permissionsForUser.userId === userId);

      return new Ability(myPermissions?.permissions ?? []);
    },
    { enabled: isAuthenticated && Number.isSafeInteger(userId) },
  );
};
