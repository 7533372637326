/**
 * Builder for creating "dataset field" inputs based on the user's selections that have a value of
 * null for each property that isn't specified.
 */
export default class DatasetFieldBuilder {
  constructor() {
    this.datasetFieldId = null;
    this.datasetFieldName = null;
    this.fromColumn = null;
    this.fixedValue = null;
    this.formula = null;
    this.productDimensionId = null;
    this.timezone = null;
  }

  setDatasetFieldId(datasetFieldId) {
    this.datasetFieldId = datasetFieldId;
    return this;
  }

  setDatasetFieldName(datasetFieldName) {
    this.datasetFieldName = datasetFieldName;
    return this;
  }

  setFromColumn(fromColumn) {
    this.fromColumn = fromColumn;
    return this;
  }

  setFixedValue(fixedValue) {
    this.fixedValue = fixedValue;
    return this;
  }

  setFormula(formula) {
    this.formula = formula;
    return this;
  }

  setProductDimensionId(productDimensionId) {
    this.productDimensionId = productDimensionId;
    return this;
  }

  setTimezone(timezone) {
    this.timezone = timezone;
    return this;
  }

  build() {
    if (this.datasetFieldName == null) {
      throw new Error('Developer error: You cannot build a dataset field without a name.');
    }

    return {
      dataset_field_id: this.datasetFieldId,
      dataset_field_name: this.datasetFieldName,
      fixed_value: this.fixedValue,
      from_column: this.fromColumn,
      formula: this.formula,
      product_dimension_id: this.productDimensionId,
      timezone: this.timezone,
    };
  }
}
