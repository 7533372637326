import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, auth: { isAuthenticated, loading }, ...rest }) => {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={props => {
        const userIsUnauthenticated = !isAuthenticated && !loading;

        if (userIsUnauthenticated) {
          if (location.pathname !== '/logout') {
            localStorage.setItem('login_redirect_path', location.pathname);
            localStorage.setItem('login_redirect_search', location.search);
          }

          return <Redirect to={{ pathname: '/login' }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

// eslint-disable-next-line react/forbid-prop-types
PrivateRoute.propTypes = { auth: PropTypes.object.isRequired };

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
