import React from 'react';
import ColumnBasedFieldSelection from '../../ColumnBasedFieldSelection';
import {
  ActionButton,
  ConfigTypeSubHeader,
  ContentArea,
  DropdownSelect,
  PageHeader,
  PromptText,
  RadioButtonRow,
  SectionHeader,
  SmallRadioButton,
  TextInput,
} from '../../commonFormComponents';
import { DataSourceType, unknownDataSourceTypeError } from '../../DatasetConfigFormConstants';
import DatasetFieldBuilder from '../../DatasetFieldBuilder';
import { DATASET_FIELD_DISPLAY } from '../../../../generic/datasetFieldDisplay';
import { updateLocation } from '../../../../../data-access/mutation/locations';

const DATASET_FIELD_NAME = 'LOC';
const FIELD_NAME = DATASET_FIELD_DISPLAY[DATASET_FIELD_NAME];

export default function LocationsPage(props) {
  const { formPageApi, dsConfigMetaData, label, nextPage } = props;

  const [isMultipleLocations, setIsMultipleLocations] = React.useState();
  const [columnName, setColumnName] = React.useState();
  const [location, setLocation] = React.useState();
  const [dataSourceType, setDataSourceType] = React.useState();

  const [fixedLocationName, setFixedLocationName] = React.useState('');

  const { locations } = dsConfigMetaData;
  const locationOptions = React.useMemo(
    () =>
      locations.map(loc => ({
        label: loc.location_description,
        value: loc,
      })),
    [locations],
  );
  // TODO: [KP-2738] In the future, this will need to actually check for the presence of 1 ACTIVE location
  const hasOnlyOneActiveLocation = locations.length === 1;

  const fixedValueSatisfied = dataSourceType === DataSourceType.FIXED_VALUE && location != null;
  const columnNameSatisfied = dataSourceType === DataSourceType.FROM_COLUMN && columnName != null;
  const userHasSpecifiedValue = fixedValueSatisfied || columnNameSatisfied;
  const specialQuestionHandled =
    !isMultipleLocations || !hasOnlyOneActiveLocation || (hasOnlyOneActiveLocation && fixedLocationName !== '');

  const canContinue = isMultipleLocations != null && userHasSpecifiedValue && specialQuestionHandled;

  const onClickNext = async () => {
    const shouldNotCreateDatasetField = !isMultipleLocations && dsConfigMetaData.locations.length === 1;

    if (shouldNotCreateDatasetField) {
      // Don't create a dataset field for "LOC", which will prompt Arcturus to assume only
      // one location exists (and so Arcturus will lookup the single location for this
      // customer by itself instead of using a "LOC" dataset field)
      formPageApi.actions.navigateToPage(nextPage);
      return;
    }

    const datasetFieldBuilder = new DatasetFieldBuilder().setDatasetFieldName(DATASET_FIELD_NAME);

    if (dataSourceType === DataSourceType.FIXED_VALUE) {
      datasetFieldBuilder.setFixedValue(location.location_identifier);
    } else if (dataSourceType === DataSourceType.FROM_COLUMN) {
      datasetFieldBuilder.setFromColumn(columnName);
    } else {
      throw unknownDataSourceTypeError(dataSourceType);
    }

    const datasetField = datasetFieldBuilder.build();

    if (hasOnlyOneActiveLocation) {
      const { location_id: locationId } = locations[0];

      // Store what the user entered on the fact_customer_entity record
      await updateLocation(locationId, { location_identifier: fixedLocationName });
    }

    await formPageApi.actions.addDatasetFields(datasetField);
    formPageApi.actions.navigateToPage(nextPage);
  };

  return (
    <div className="ds-config-container">
      <PageHeader>New Data Configuration</PageHeader>

      <ConfigTypeSubHeader industry={formPageApi.state.industry} dataType={formPageApi.state.dataType} />

      <hr />

      <SectionHeader label={label}>{FIELD_NAME}</SectionHeader>

      <ContentArea>
        <PromptText label="1.">Does this data set contain data for more than one location?</PromptText>
        <RadioButtonRow>
          <SmallRadioButton
            selected={isMultipleLocations === true}
            onClick={() => {
              setIsMultipleLocations(true);

              if (isMultipleLocations === false) {
                // Don't set to undefined if the user clicks this button while it's already selected
                setDataSourceType(undefined);
              }
            }}
          >
            I think so!
          </SmallRadioButton>
          <SmallRadioButton
            selected={isMultipleLocations === false}
            onClick={() => {
              setIsMultipleLocations(false);
              setDataSourceType(DataSourceType.FIXED_VALUE);
            }}
          >
            No
          </SmallRadioButton>
        </RadioButtonRow>

        {isMultipleLocations != null && (
          <>
            {isMultipleLocations ? (
              <>
                <PromptText label="2.">
                  Look through your data and select the column that matches up with <strong>{FIELD_NAME}</strong>.
                </PromptText>

                <ColumnBasedFieldSelection
                  allowFormula={false}
                  dataSamples={dsConfigMetaData.dataSample}
                  columnName={columnName}
                  setColumnName={setColumnName}
                  dataSourceType={dataSourceType}
                  setDataSourceType={setDataSourceType}
                />

                {hasOnlyOneActiveLocation && (
                  <>
                    <PromptText label="3.">
                      Please type in the name/identifier of your <strong>one active location</strong> EXACTLY as it
                      appears in your data from the column you selected above.
                    </PromptText>

                    <TextInput onChange={e => setFixedLocationName(e.target.value)} value={fixedLocationName} />
                  </>
                )}
              </>
            ) : (
              <>
                <PromptText label="2.">Which location does this data tie to?</PromptText>

                <DropdownSelect
                  key="fixed-value"
                  options={locationOptions}
                  onSelect={value => setLocation(value)}
                  value={location}
                />
              </>
            )}
          </>
        )}

        {canContinue && (
          <ActionButton onClick={onClickNext} style={{ marginTop: 30 }}>
            Continue
          </ActionButton>
        )}
      </ContentArea>
    </div>
  );
}
