import React from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import { useLocationDayStatistics, useRowsByDayMultipleLocations, useShiftsByLocation } from './staffingTabUtil';
import { parseDatasetDate } from './timePeriod';
import useLocationName from './hooks/useLocationName';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import { CenteredProfitRoverSpinner } from '../../../spinner/ProfitRoverSpinner';
import { useGridApi } from '../../../util/gridCommonFunctions';
import { formatCurrency } from '../../../util/format';
import { gaEmitStaffingSeeMoreLinkClick } from '../../../../google-analytics/atAGlanceTab';

const ProfitRoverIcon = '/images/ProfitRoverLogo.png';
const RESULTS_COUNT = 3;

const buildHeaderTemplate = icon => {
  return `
      <div class="ag-cell-label-container" role="presentation">
        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          <img class="price-rec-table-header-icon" src="${icon}" />
          <span ref="eText" role="columnheader"></span>
        </div>
      </div>`;
};

const RevenueRecsTable = ({ data, isActive }) => {
  const { workflowId } = React.useContext(DomainContext);
  const { currencySymbol, staffingRolesApi } = React.useContext(DashboardContext);
  const { isLoadingStaffingRoles } = staffingRolesApi;
  const { getLocationName } = useLocationName();
  const containerRef = React.useRef();
  const { gridApi, onGridReady } = useGridApi();

  React.useEffect(() => {
    const gridResizeMonitor = new ResizeObserver(() => {
      if (isActive && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    });

    if (containerRef.current) {
      gridResizeMonitor.observe(containerRef.current);
    }

    return () => gridResizeMonitor.disconnect();
  }, [gridApi, isActive]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isActive && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [gridApi, data, isActive]);

  const isLoading = gridApi == null || isLoadingStaffingRoles;

  return (
    <div className="eo-price-rec-table-container" ref={containerRef}>
      {isLoading && (
        <div className="w-100 d-flex justify-content-center align-self-center">
          <CenteredProfitRoverSpinner />
        </div>
      )}
      <div className={classNames('ag-theme-alpine price-rec-table', { hidden: isLoading })}>
        <AgGridReact
          rowData={data}
          context={{ workflowId }}
          onGridReady={onGridReady}
          defaultColDef={{
            resizable: true,
          }}
          groupHeaderHeight={30}
          headerHeight={30}
          maintainColumnOrder
        >
          <AgGridColumn
            field="locationId"
            headerName="Location"
            headerClass="border-right center-text"
            cellClass="border-right"
            valueGetter={params => getLocationName(params.data.locationId)}
            minWidth={120}
          />
          <AgGridColumn
            field="date"
            headerName="Date"
            headerClass="border-right center-text"
            cellClass="border-right center-text"
            valueGetter={params => format(parseDatasetDate(params.data.date), 'EEE MMM d')}
            minWidth={100}
            maxWidth={130}
          />
          <AgGridColumn
            field="income"
            headerName="Revenue Forecast"
            headerClass="border-right center-text"
            cellClass="border-right center-text"
            valueGetter={params => formatCurrency(params.data.income, false, currencySymbol)}
            minWidth={120}
            maxWidth={130}
          />
          <AgGridColumn
            field="shifts"
            headerName="RoverRecs - # Shifts"
            headerClass="border-right center-text"
            cellClass="border-right center-text"
            headerComponentParams={{
              template: buildHeaderTemplate(ProfitRoverIcon),
            }}
            minWidth={160}
          />
        </AgGridReact>
      </div>
    </div>
  );
};

const StaffingRecsSection = ({ showStaffingTab, isActive }) => {
  const {
    rowsByLocationByDay,
    rowsByLocationByDayAndHour,
    gratuityRowsByLocationByDay,
  } = useRowsByDayMultipleLocations();
  const shiftsByLocationByDay = useShiftsByLocation(rowsByLocationByDayAndHour, gratuityRowsByLocationByDay);
  const statistics = useLocationDayStatistics(rowsByLocationByDay, gratuityRowsByLocationByDay, shiftsByLocationByDay);

  const sortedData = statistics.filter(stat => !stat.isClosed).sort((a, b) => b.income - a.income);
  const topIncome = sortedData.slice(0, RESULTS_COUNT);
  const bottomIncome = sortedData.slice(-RESULTS_COUNT).reverse();

  const onClickSeeMore = () => {
    gaEmitStaffingSeeMoreLinkClick();
    showStaffingTab();
  };

  return (
    <div className="top-recs-section w-100 pb-2">
      <div className="d-flex flex-column" style={{ flex: 1 }}>
        <div className="d-flex mt-3 mb-2">
          <h5 className="mb-0">Staffing Recommendations</h5>
          <ProfitRoverTooltip tooltipText="Next 28 days" placement="top" delay={{ show: 100, hide: 100 }}>
            <FontAwesomeIcon className="ml-1" icon={faCircleQuestion} />
          </ProfitRoverTooltip>
          <button type="button" className="link-like ml-2" onClick={onClickSeeMore}>
            See More
          </button>
        </div>
        <ProfitRoverCard className="staffing-section p-3">
          <div className="d-flex flex-column mr-2" style={{ flex: 1 }}>
            <h6 className="mb-2 text-center">Bottom 3 Revenue Days</h6>
            <RevenueRecsTable data={bottomIncome} isActive={isActive} />
          </div>
          <div className="d-flex flex-column ml-2" style={{ flex: 1 }}>
            <h6 className="mb-2 text-center">Top 3 Revenue Days</h6>
            <RevenueRecsTable data={topIncome} isActive={isActive} />
          </div>
        </ProfitRoverCard>
      </div>
    </div>
  );
};

export default StaffingRecsSection;
