/* eslint-disable array-callback-return */
import axios from '../../utils/axios-client';
import {KA_API_URL } from '../../config/baseUrl';

const getFiltersData = async (type, industry, workflowId) => {
  const locationsDescriptionmap = new Map();
  const productClassesDescriptionMap = new Map();
  const distinctLocationsArray = [];
  const distinctProductsSet = new Set();
  const distinctProductsArray = [];
  const distinctProductClassesArray = [];
  const productClassProductDescriptionMap = new Map();
  const productClassProductDescriptionArray = [];
  let allProductsArray = [];

  try {

    const filtersResponse = await axios.get(`${KA_API_URL}/productvisit/stats/filters`, {
      params: {
        stat_type: type,
        industry,
        product_classes: 'GEN',
        workflow_id: workflowId,
      },
    });

    const locations = localStorage.getItem('locations') ? JSON.parse(localStorage.getItem('locations')) : [];
    const locationIds = [];

    locations.map(location => {
      locationIds.push(location.customer_entity_id);
      locationsDescriptionmap.set(location.customer_entity_id, location);
    });

    filtersResponse.data.locations.map(location => {
      distinctLocationsArray.push({
        value: location.customer_entity_id,
        label: location.description,
        weather_id: locationsDescriptionmap.get(location.customer_entity_id).kdl_weather_location_id,
        state_code: locationsDescriptionmap.get(location.customer_entity_id).state,
      });
    });

    filtersResponse.data.product_classes.map(productClass => {
      distinctProductClassesArray.push({
        value: productClass.product_class,
        label: productClass.description,
      });

      productClassesDescriptionMap.set(productClass.product_class, productClass.description);
    });

    filtersResponse.data.products.map(product => {
      if (!distinctProductsSet.has(product.description)) {
        distinctProductsSet.add(product.description);
        distinctProductsArray.push({ value: product.description, label: product.description });
        productClassProductDescriptionMap.set(product.description, product.product_class);
        productClassProductDescriptionArray.push({
          product_class: product.product_class,
          product_description: product.description,
          product_id: product.product_id,
          product_class_description: productClassesDescriptionMap.get(product.product_class),
        });
      }
    });

    distinctLocationsArray.sort((a, b) => (a.label > b.label ? 1 : -1));
    distinctProductsArray.sort((a, b) => (a.label > b.label ? 1 : -1));
    distinctProductClassesArray.sort((a, b) => (a.label > b.label ? 1 : -1));
    allProductsArray = filtersResponse.data.products;

    return {
      distinctLocationsArray,
      distinctProductsArray,
      distinctProductClassesArray,
      productClassProductDescriptionArray,
      productClassProductDescriptionMap,
      allProductsArray,
      locationsDescriptionmap,
      productClassesDescriptionMap,
    };
  } catch (err) {
    return {
      distinctLocationsArray,
      distinctProductsArray,
      distinctProductClassesArray,
      productClassProductDescriptionArray,
      productClassProductDescriptionMap,
      allProductsArray,
      locationsDescriptionmap,
      productClassesDescriptionMap,
    };
  }
};

export default getFiltersData;
