export const NOTIFICATION_TYPES = {
  ALERTS: 'ALERTS',
  INFO: 'INFO',
};

export const PAYLOAD_TYPE = {
  DATASET_DOES_NOT_SPECIFY_SOURCE: 'dataset_does_not_specify_source',
  DATASET_NO_LOCATION: 'dataset_no_location',
  DATASET_LOCATION_DOES_NOT_EXIST: 'dataset_location_does_not_exist',
  DATASET_NO_LOCATION_IDS_EXIST: 'dataset_no_location_ids_exist',
  DATASET_MISSING_LOCATION: 'dataset_missing_location',
  DATASET_MISSING_COLUMNS: 'dataset_missing_columns',
  DATASET_MISSING_REQUIRED_FIELDS: 'dataset_missing_required_fields',
  DATASET_VALUE_BAD_FORMAT: 'dataset_value_bad_format',
  DATASET_DIMENSION_VALUES: 'dataset_dimension_values',
  WORKFLOW_COMPLETE_ERROR: 'workflow_complete_error',
  WORKFLOW_COMPLETE_ABORT: 'workflow_complete_abort',
  WORKFLOW_COMPLETE: 'workflow_complete',
  SUBSCRIPTION_BILLING_ACTIVATED: 'subscription_billing_activated',
  ERROR: 'error',
};

export const NOTIFICATION_TYPE_BY_PAYLOAD_TYPE = {
  [NOTIFICATION_TYPES.ALERTS]: [
    PAYLOAD_TYPE.ERROR,
    PAYLOAD_TYPE.DATASET_DOES_NOT_SPECIFY_SOURCE,
    PAYLOAD_TYPE.DATASET_NO_LOCATION,
    PAYLOAD_TYPE.DATASET_LOCATION_DOES_NOT_EXIST,
    PAYLOAD_TYPE.DATASET_MISSING_LOCATION,
    PAYLOAD_TYPE.DATASET_MISSING_COLUMNS,
    PAYLOAD_TYPE.DATASET_MISSING_REQUIRED_FIELDS,
    PAYLOAD_TYPE.DATASET_VALUE_BAD_FORMAT,
    PAYLOAD_TYPE.WORKFLOW_COMPLETE_ERROR,
    PAYLOAD_TYPE.WORKFLOW_COMPLETE_ABORT,
  ],
  [NOTIFICATION_TYPES.INFO]: [
    PAYLOAD_TYPE.DATASET_DIMENSION_VALUES,
    PAYLOAD_TYPE.WORKFLOW_COMPLETE,
    PAYLOAD_TYPE.SUBSCRIPTION_BILLING_ACTIVATED,
  ],
};
