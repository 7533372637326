import axios from 'axios';
import { KA_API_ROOT_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const attemptLogin = async (email, password) => axios.post(`${KA_API_ROOT_URL}/auth/login`, { email, password });

export const verifyRecaptchaToken = async token => axios.post(`${KA_API_ROOT_URL}/auth/verifyRecaptcha`, { token });
