import { Objective } from '../../workflow/workflowConstants';

export const KaizenProduct = {
  KP: 'KP',
  KF: 'KF',
  KI: 'KI',
};

export const KaizenProductStatus = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  INCOMPLETE: 'INCOMPLETE',
  INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
  PAST_DUE: 'PAST_DUE',
  TRIALING: 'TRIALING',
  UNPAID: 'UNPAID',
};

export const KaizenBillingInterval = {
  MONTHLY: 'month',
  ANNUAL: 'annual',
};

export const ObjectiveToKaizenProduct = {
  [Objective.ATTENDFORECAST]: KaizenProduct.KF,
  [Objective.PRICEELASTICITY]: KaizenProduct.KI,
  [Objective.ANOMALY]: KaizenProduct.KI,
  [Objective.PRICEOPT]: KaizenProduct.KP,
  [Objective.ALL]: KaizenProduct.KP,
};

export const LocationActivationStatus = {
  ACTIVE: 'ACTIVE',
  PENDING_ACTIVATION: 'PENDING_ACTIVATION',
  PENDING_DEACTIVATION: 'PENDING_DEACTIVATION',
  INACTIVE: 'INACTIVE',
};

const LOCATION_ACTIVE_STATUSES = Object.freeze([
  LocationActivationStatus.ACTIVE,
  LocationActivationStatus.PENDING_DEACTIVATION,
]);

export const isLocationActive = status => LOCATION_ACTIVE_STATUSES.includes(status);

// 'opcodes' for different types of subscription update actions
export const SubscriptionUpdateAction = {
  REQUEST_CHANGE_LOCATION_STATUS: 'request_change_location_status',
  CHANGE_LOCATION_STATUS: 'change_location_status',
  END_TRIAL_EARLY: 'end_trial_early',
  TOGGLE_AUTO_RENEW: 'toggle_auto_renew',
  REQUEST_CHANGE_PLAN_INTERVAL: 'request_change_plan_interval',
  CHANGE_PLAN_INTERVAL: 'change_plan_interval',
  CANCEL_PENDING_INTERVAL_CHANGE: 'cancel_pending_interval_change',
  ACTIVATE_ONLY: 'activate_only',
};

export const ChargeStatus = {
  SUCCESS: 'succeeded',
  PENDING: 'pending',
  FAILED: 'failed',
};

export const InvoiceStatus = {
  VOID: 'void',
  OPEN: 'open',
  UNCOLLECTIBLE: 'uncollectible',
  PAID: 'paid',
  DRAFT: 'draft',
};

export const SubscriptionType = {
  TRIAL_PERIOD: 'TRIAL_PERIOD',
  FIRST_FREE: 'FIRST_FREE',
};

export const StripeBillingScheme = {
  PER_UNIT: 'per_unit',
  TIERED: 'tiered',
};

export const SubscriptionBannerButton = {
  NONE: 'no_button',
  START_NOW: 'start_now',
  ADD_PAYMENT: 'add_payment',
};
