import React, { Component } from 'react';
import * as d3 from 'd3';
import './donutChart.css';

const width = 250;
const height = 250;
const radius = 135;

class DonutChart extends Component {
  state = {
    pie: [],
    arc: [],
  };

  static getDerivedStateFromProps(nextProps) {
    const { data, id, classes, total } = nextProps;

    if (!data) {
      return {};
    }

    const arc = d3
      .arc()
      .outerRadius(radius - 12)
      .innerRadius(100);

    const pie = d3
      .pie()
      .sort(null)
      .value(d => {
        return d.uplift;
      });

    return {
      arc,
      pie,
      data,
      id,
      classes,
      total,
    };
  }

  componentDidMount() {
    this.renderDonut();
  }

  componentDidUpdate() {
    this.renderDonut();
  }

  renderDonut() {
    const { id, total, classes, pie, data, arc } = this.state;
    const tooltip = d3.select(`#tooltip`);
    d3.select(`#${id}`)
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`)
      .selectAll('.arc')
      .data(pie(data))
      .enter()
      .append('g')
      .append('path')
      .attr('d', arc)
      .style('stroke', 'grey')
      .style('fill', d => {
        return d.data.color;
      })
      .style('cursor', 'pointer')
      .on('mouseover', d => {
        d3.selectAll(`.${total}`).remove();
        d3.select(`#${id}`)
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '1.4em')
          .attr('class', classes)
          .attr('x', 125)
          .attr('y', 130)
          .text(d.data.upliftDisplay);

        tooltip
          .html(d.data.name)
          .style('display', 'block')
          .style(
            'left',
            `${
              d3.event.clientX < 330 && id === 'locationDonut'
                ? d3.event.clientX - 150
                : d3.event.clientX < 650 && id === 'productDonut' && d.data.name.length > 8
                ? d3.event.clientX - 100
                : d3.event.clientX < 650 && id === 'productDonut'
                ? d3.event.clientX - 70
                : d3.event.clientX + 10
            }px`,
          )
          .style('top', `${d3.event.clientY}px`)
          .style('font-size', '1.1em')
          .style('background-color', 'lightgray');
      })
      .on('mouseout', d => {
        d3.selectAll(`.${classes}`).remove();
        d3.select(`#${id}`)
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '1.4em')
          .attr('class', total)
          .attr('x', 125)
          .attr('y', 120)
          .text('Total');

        d3.select(`#${id}`)
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '1.4em')
          .attr('class', total)
          .attr('x', 125)
          .attr('y', 150)
          .text(d.data.total);
        if (tooltip) {
          tooltip.style('display', 'none');
        }
      });

    if (data.length > 0) {
      d3.selectAll(`.${total}`).remove();
      this.renderTotal(data[0].total);
    }
  }

  renderTotal(renderTotal) {
    const { id, total } = this.state;
    d3.select(`#${id}`)
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('font-size', '1.4em')
      .attr('class', total)
      .attr('x', 125)
      .attr('y', 120)
      .text('Total');

    d3.select(`#${id}`)
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('font-size', '1.4em')
      .attr('class', total)
      .attr('x', 125)
      .attr('y', 150)
      .text(renderTotal);
  }

  render() {
    const { id } = this.state;

    return (
      <div>
        <svg id={id} width={width} height={height} />
        <div id="tooltip" style={{ position: 'absolute', padding: '5px', zIndex: 1 }} />
      </div>
    );
  }
}

export default DonutChart;
