import _ from 'lodash';
import React from 'react';
import { useSelector, useStore } from 'react-redux';
import { KA_API_ROOT_URL } from '../config/baseUrl';

const NOTIFICATIONS_RETRIEVAL_DAYS_BACK = process.env.REACT_APP_NOTIFICATIONS_RETRIEVAL_DAYS_BACK || 30;
const daysBackQueryParam = `&days_back=${NOTIFICATIONS_RETRIEVAL_DAYS_BACK}`;

export const SSEEventType = {
  NOTIFICATION: 'notification',
  WORK_BATCH_PROGRESS: 'work_batch_progress',
};

const EventStreamContext = React.createContext();

const useEventStreamConnection = () => {
  const [sseEventSource, setSseEventSource] = React.useState();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const reduxStore = useStore();

  /**
   * This is used to trigger rerender in order to reestablish a connection
   * to the event stream when the JWT is refreshed. Without this, reconnection
   * events will include the old (expired) JWT, potentially causing an infinite
   * re-render cycle.
   */
  const jwtRefreshedAt = useSelector(state => state.auth.jwtRefreshedAt);

  React.useEffect(() => {
    /**
     * We disable SSE connections in the testing environment as the SSE EventSource API would need to be
     * polyfilled or mocked since jsdom doesn't implement it. We'll save completing this exercise for when
     * it is necessary for testing purposes.
     */
    const isTestEnvironment = navigator.userAgent.includes('jsdom');

    if (!isAuthenticated || isTestEnvironment) {
      return;
    }

    const jwt = localStorage.getItem('token');
    const connectionHasBeenPreviouslyEstablished = jwtRefreshedAt != null;

    let sseConnectionUrl = `${KA_API_ROOT_URL}/generic_events/event_stream?token=${jwt}`;
    if (connectionHasBeenPreviouslyEstablished) {
      // Prevent refetching all notifications (which would appear as duplicates) upon JWT refresh
      const { notifications } = reduxStore.getState().notificationCenter;
      const [mostRecent] = _.orderBy(notifications, 'created_at', 'desc');

      if (mostRecent?.id) {
        sseConnectionUrl += `&last_seen_event_id=${mostRecent.id}`;
      }
    } else {
      // Initial connection retrieves all notifications up to a certain date in the past
      sseConnectionUrl += daysBackQueryParam;
    }

    const eventSource = new EventSource(sseConnectionUrl);
    setSseEventSource(eventSource);

    const closeEventSource = () => eventSource.close();
    eventSource.addEventListener('stop', closeEventSource);

    // eslint-disable-next-line consistent-return
    return () => {
      eventSource.removeEventListener('stop', closeEventSource);
      closeEventSource();
    };
  }, [isAuthenticated, jwtRefreshedAt, reduxStore]);

  return { sseEventSource };
};

export const EventStreamContextProvider = ({ children }) => {
  const contextValue = useEventStreamConnection();

  return <EventStreamContext.Provider value={contextValue}>{children}</EventStreamContext.Provider>;
};

export const useEventStreamContext = () => React.useContext(EventStreamContext);
