import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Units } from '../../data-access/query/kdl/weather';
import { ReactComponent as LowTempIcon } from '../../images/weather/temperature-cold.svg';
import { ReactComponent as HighTempIcon } from '../../images/weather/temperature-hot.svg';
import { ReactComponent as SunIcon } from '../../images/weather/sun.svg';
import { ReactComponent as CloudIcon } from '../../images/weather/cloudy.svg';
import { ReactComponent as SunCloudIcon } from '../../images/weather/partly-cloudy.svg';
import { ReactComponent as SunCloudRainIcon } from '../../images/weather/partly-cloudy-rain.svg';
import { ReactComponent as CloudRainIcon } from '../../images/weather/rain.svg';
import { ReactComponent as SunCloudSnowIcon } from '../../images/weather/partly-cloudy-snow.svg';
import { ReactComponent as CloudSnowIcon } from '../../images/weather/cloudy-snow.svg';
import { ReactComponent as SunCloudMixIcon } from '../../images/weather/partly-cloudy-wintry-mix.svg';
import { ReactComponent as CloudMixIcon } from '../../images/weather/cloudy-wintry-mix.svg';

const WEATHER_ICON_TO_LABEL = new Map([
  [SunIcon, 'Clear'],
  [SunCloudIcon, 'Partly Cloudy'],
  [CloudIcon, 'Cloudy'],
  [SunCloudRainIcon, 'Rain'],
  [CloudRainIcon, 'Rain'],
  [SunCloudSnowIcon, 'Snow'],
  [CloudSnowIcon, 'Snow'],
  [SunCloudMixIcon, 'Wintry Mix'],
  [CloudMixIcon, 'Wintry Mix'],
]);

const CloudCover = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

const LOW_CLOUD_COVER_PCT = 10;
const MEDIUM_CLOUD_COVER_PCT = 50;

const classifyCloudCoverPct = cloudCoverPct => {
  if (cloudCoverPct < LOW_CLOUD_COVER_PCT) {
    return CloudCover.LOW;
  }
  if (LOW_CLOUD_COVER_PCT <= cloudCoverPct && cloudCoverPct < MEDIUM_CLOUD_COVER_PCT) {
    return CloudCover.MEDIUM;
  }

  return CloudCover.HIGH;
};

const PrecipitationLevel = {
  TRACE: 'TRACE',
  HIGH: 'HIGH',
};

const PRECIPITATION_THRESHOLD = 0.1; // inches or centimeters

const classifyPrecipitation = precipAmt => {
  if (precipAmt < PRECIPITATION_THRESHOLD) {
    return PrecipitationLevel.TRACE;
  }

  return PrecipitationLevel.HIGH;
};

const SUN_CONDITIONS = [CloudCover.LOW, PrecipitationLevel.TRACE, PrecipitationLevel.TRACE];
const SUN_CLOUD_CONDITIONS = [CloudCover.MEDIUM, PrecipitationLevel.TRACE, PrecipitationLevel.TRACE];
const CLOUD_CONDITIONS = [CloudCover.HIGH, PrecipitationLevel.TRACE, PrecipitationLevel.TRACE];
const SUN_CLOUD_RAIN_CONDITIONS = [CloudCover.MEDIUM, PrecipitationLevel.HIGH, PrecipitationLevel.TRACE];
const CLOUD_RAIN_CONDITIONS = [CloudCover.HIGH, PrecipitationLevel.HIGH, PrecipitationLevel.TRACE];
const SUN_CLOUD_SNOW_CONDITIONS = [CloudCover.MEDIUM, PrecipitationLevel.TRACE, PrecipitationLevel.HIGH];
const CLOUD_SNOW_CONDITIONS = [CloudCover.HIGH, PrecipitationLevel.TRACE, PrecipitationLevel.HIGH];
const SUN_CLOUD_MIX_CONDITIONS = [CloudCover.MEDIUM, PrecipitationLevel.HIGH, PrecipitationLevel.HIGH];
const CLOUD_MIX_CONDITIONS = [CloudCover.HIGH, PrecipitationLevel.HIGH, PrecipitationLevel.HIGH];

const DEFAULT_ICON = SunIcon;

// Deep comparison necessary because of Array value comparison
const conditionsMatch = (actual, conditions) => _.isEqual(actual, conditions);

const getIconForConditions = conditions => {
  let Icon = DEFAULT_ICON;
  if (conditionsMatch(conditions, SUN_CONDITIONS)) {
    Icon = SunIcon;
  } else if (conditionsMatch(conditions, SUN_CLOUD_CONDITIONS)) {
    Icon = SunCloudIcon;
  } else if (conditionsMatch(conditions, CLOUD_CONDITIONS)) {
    Icon = CloudIcon;
  } else if (conditionsMatch(conditions, SUN_CLOUD_RAIN_CONDITIONS)) {
    Icon = SunCloudRainIcon;
  } else if (conditionsMatch(conditions, CLOUD_RAIN_CONDITIONS)) {
    Icon = CloudRainIcon;
  } else if (conditionsMatch(conditions, SUN_CLOUD_SNOW_CONDITIONS)) {
    Icon = SunCloudSnowIcon;
  } else if (conditionsMatch(conditions, CLOUD_SNOW_CONDITIONS)) {
    Icon = CloudSnowIcon;
  } else if (conditionsMatch(conditions, SUN_CLOUD_MIX_CONDITIONS)) {
    Icon = SunCloudMixIcon;
  } else if (conditionsMatch(conditions, CLOUD_MIX_CONDITIONS)) {
    Icon = CloudMixIcon;
  }

  return Icon;
};

const IconToLabel = Icon => WEATHER_ICON_TO_LABEL.get(Icon);
const getLabelForConditions = conditions => IconToLabel(getIconForConditions(conditions));

export const useConditions = (cloudCoverPct, rainAmt, snowAmt) => {
  const conditions = React.useMemo(() => {
    const cloudCoverLevel = classifyCloudCoverPct(cloudCoverPct);
    const rainLevel = classifyPrecipitation(rainAmt);
    const snowLevel = classifyPrecipitation(snowAmt);

    return [cloudCoverLevel, rainLevel, snowLevel];
  }, [cloudCoverPct, rainAmt, snowAmt]);

  const conditionsLabel = React.useMemo(() => getLabelForConditions(conditions), [conditions]);

  return { conditions, conditionsLabel };
};

export const WeatherIcon = ({ conditions, ...props }) => {
  const Icon = getIconForConditions(conditions);

  return <Icon {...props} />;
};
WeatherIcon.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.string).isRequired,
  units: PropTypes.oneOf(Object.values(Units)),
};
WeatherIcon.defaultProps = {
  units: Units.IMPERIAL,
};

export { HighTempIcon, LowTempIcon };
