import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Subscription Settings';

const ACTIONS = {
  PAY_AMOUNT_DUE_LOCATIONS_BUTTON_CLICK: 'Pay Amount Due Locations button click',
  CONFIRM_CHANGES_LOCATIONS_BUTTON_CLICK: 'Confirm Changes Locations button click',
  PAY_AMOUNT_DUE_PLAN_BUTTON_CLICK: 'Pay Amount Due Plan button click',
  TURN_OFF_AUTO_RENEW_BUTTON_CLICK: 'Turn Off Auto Renew button click',
  TURN_ON_AUTO_RENEW_TOGGLE_CLICK: 'Turn On Auto Renew toggle click',
  VIEW_INVOICES_BUTTON_CLICK: 'View Invoices button click',
  DOWNLOAD_INVOICE_ACTION_CLICK: 'Download Invoice action click',
  ADD_PAYMENT_METHOD_BUTTON_CLICK: 'Add Payment Method button click',
  EDIT_PAYMENT_METHOD_BUTTON_CLICK: 'Edit Payment Method button click',
};

const LABELS = {
  ACTIVATE_LOCATIONS: 'Activate Locations',
  DEACTIVATE_LOCATIONS: 'Deactivate Locations',
  SWITCH_TO_ANNUAL_PLAN: 'Switch to Annual Plan',
  MANAGE_PLAN: 'Manage Plan',
  INVOICES: 'Invoices',
  MANAGE_PAYMENT_METHOD: 'Manage Payment Method',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitPayAmountDueLocationsButtonClick = () => {
  emitEvent({ action: ACTIONS.PAY_AMOUNT_DUE_LOCATIONS_BUTTON_CLICK, label: LABELS.ACTIVATE_LOCATIONS });
};

export const gaEmitConfirmChangesLocationsButtonClick = () => {
  emitEvent({ action: ACTIONS.CONFIRM_CHANGES_LOCATIONS_BUTTON_CLICK, label: LABELS.DEACTIVATE_LOCATIONS });
};

export const gaEmitPayAmountDuePlanButtonClick = () => {
  emitEvent({ action: ACTIONS.PAY_AMOUNT_DUE_PLAN_BUTTON_CLICK, label: LABELS.SWITCH_TO_ANNUAL_PLAN });
};

export const gaEmitTurnOffAutoRenewButtonClick = () => {
  emitEvent({ action: ACTIONS.TURN_OFF_AUTO_RENEW_BUTTON_CLICK, label: LABELS.MANAGE_PLAN });
};

export const gaEmitTurnOnAutoRenewToggleClick = () => {
  emitEvent({ action: ACTIONS.TURN_ON_AUTO_RENEW_TOGGLE_CLICK, label: LABELS.MANAGE_PLAN });
};

export const gaEmitViewInvoicesButtonClick = () => {
  emitEvent({ action: ACTIONS.VIEW_INVOICES_BUTTON_CLICK, label: LABELS.INVOICES });
};

export const gaEmitDownloadInvoiceActionClick = () => {
  emitEvent({ action: ACTIONS.DOWNLOAD_INVOICE_ACTION_CLICK, label: LABELS.INVOICES });
};

export const gaEmitAddPaymentMethodButtonClick = () => {
  emitEvent({ action: ACTIONS.ADD_PAYMENT_METHOD_BUTTON_CLICK, label: LABELS.MANAGE_PAYMENT_METHOD });
};

export const gaEmitEditPaymentMethodButtonClick = () => {
  emitEvent({ action: ACTIONS.EDIT_PAYMENT_METHOD_BUTTON_CLICK, label: LABELS.MANAGE_PAYMENT_METHOD });
};
