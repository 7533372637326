/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCheck, faArrowUp, faArrowDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import { ProfitRoverDestructiveButton, ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import { FONT_WHITE, MID_GREEN } from '../../../colors';
import './CustomEvents.css';

function actionComponent(openSingleRow, rowData, singleRowEditSet, updateSingleRow, deleteSingle) {
  return (
    <div>
      {rowData.event_id === null ? (
        ''
      ) : (
        <div>
          {singleRowEditSet.has(rowData.event_id) ? (
            <FontAwesomeIcon
              name="editOpen"
              className="Icon"
              icon={faCheck}
              onClick={e => updateSingleRow(e, rowData)}
            />
          ) : (
            <FontAwesomeIcon name="editOpen" className="Icon" icon={faEdit} onClick={e => openSingleRow(e, rowData)} />
          )}

          <FontAwesomeIcon icon={faTrash} className="Icon" onClick={() => deleteSingle(rowData)} />
        </div>
      )}
    </div>
  );
}
actionComponent.displayName = 'Action';

const eventTable = props => {
  const {
    customerLocations,
    data,
    dataAvailable,
    openSingleRow,
    singleRowEditSet,
    handleSingleEditEventChange,
    customEventsTypesMap,
    customEventsTypes,
    updateSingleRow,
    singleRowUpdateMap,
    multiRowEditMap,
    handleCheck,
    applyAll,
    isAllSelected,
    deleteSingle,
    sortClick,
    sortObject,
    openFooter,
    newStartDate,
    newEndDate,
    handleNewEventChange,
    addNewEvent,
    toggleFooterInputs,
    updateCustomEventsMap,
    createEventsArray,
    multiRowDeleteSet,
    saveChanges,
    toggleModal,
    deleteMultiple,
  } = props;

  const headerStyleObj = {
    fontSize: '14px',
    height: '40px',
    background: MID_GREEN,
    color: FONT_WHITE,
    fontWeight: 'bold',
    boxSizing: 'border-box',
  };

  const tableDataStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: 'white',
  };

  return (
    <div>
      <ReactTable
        data={data}
        columns={[
          {
            Header: <input type="checkbox" checked={isAllSelected} onChange={applyAll} style={{ marginTop: '5px' }} />,
            headerStyle: headerStyleObj,
            id: 'apply_all',
            accessor: d => d,
            Cell: row => (
              <div>
                {row.value.event_id !== null ? (
                  <input
                    className="checked-input"
                    name="checked"
                    id={row.index}
                    type="checkbox"
                    checked={multiRowEditMap.get(row.value.event_id)}
                    onChange={() => handleCheck(row.original)}
                  />
                ) : null}
              </div>
            ),
            sortable: false,
            resizable: false,
            width: 25,
          },

          {
            Header: (
              <div>
                {!sortObject.location_description.is_sorted ? (
                  <div className="column-header">Location / Business Unit</div>
                ) : (
                  <div className="row">
                    <div className="col-10 column-header column-header-align">Location / Business Unit</div>
                    <div>
                      {sortObject.location_description.is_sorted === true ? (
                        sortObject.location_description.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'location_description',
            style: { whiteSpace: 'unset' },
            accessor: d => d,
            Cell: row => (
              <div className={row.value.event_id === null ? 'table-column-green' : ''}>
                {row.value.location_description}
              </div>
            ),
            sortable: true,
            Footer: row =>
              openFooter ? (
                <select
                  name="newLocationId"
                  className="select"
                  onChange={e => handleNewEventChange(e, row, null, null)}
                >
                  <option value="" />
                  {customerLocations.map(location => (
                    <option key={location.location_id} value={location.location_id}>
                      {location.location_description}
                    </option>
                  ))}
                </select>
              ) : null,
          },
          {
            Header: (
              <div>
                {!sortObject.description.is_sorted ? (
                  <div className="column-header">Event Name / Description</div>
                ) : (
                  <div className="row">
                    <div className="col-9 column-header column-header-align">Event Name / Description</div>
                    <div className="event-div">
                      {sortObject.description.is_sorted === true ? (
                        sortObject.description.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
            headerStyle: headerStyleObj,
            style: { whiteSpace: 'unset' },
            id: 'description',
            accessor: d => d,
            Cell: row => (
              <div>
                {singleRowEditSet.has(row.value.event_id) ? (
                  <input
                    className="input"
                    type="text"
                    name="editDescription"
                    onChange={e => handleSingleEditEventChange(e, row, null, null)}
                    defaultValue={
                      singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                      singleRowUpdateMap.get(row.value.event_id).description !== undefined
                        ? singleRowUpdateMap.get(row.value.event_id).description
                        : row.value.description !== null
                        ? row.value.description
                        : ''
                    }
                  />
                ) : (
                  <div
                    className={
                      singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                      singleRowUpdateMap.get(row.value.event_id).description !== undefined
                        ? 'table-column-yellow'
                        : ''
                    }
                  >
                    {singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                    singleRowUpdateMap.get(row.value.event_id).description !== undefined ? (
                      singleRowUpdateMap.get(row.value.event_id).description
                    ) : row.value.description === null ? (
                      '-'
                    ) : (
                      <div className={row.value.event_id === null ? 'table-column-green' : ''}>
                        {row.value.description}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ),
            sortable: true,
            Footer: row =>
              openFooter ? (
                <input
                  name="newDescription"
                  className="select"
                  onChange={e => handleNewEventChange(e, row, null, null)}
                />
              ) : null,
          },
          {
            Header: (
              <div>
                {!sortObject.event_impact_type_description.is_sorted ? (
                  <div className="column-header">Impact Type</div>
                ) : (
                  <div className="row">
                    <div className="col-8 column-header column-header-align">Impact Type</div>
                    <div>
                      {sortObject.event_impact_type_description.is_sorted === true ? (
                        sortObject.event_impact_type_description.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'event_impact_type_description',
            style: { whiteSpace: 'unset' },
            accessor: d => d,
            Cell: row => (
              <div>
                {singleRowEditSet.has(row.value.event_id) ? (
                  <select
                    className="select"
                    name="editImpactType"
                    onChange={e => handleSingleEditEventChange(e, row, null, null)}
                  >
                    <option
                      key={1}
                      value={
                        singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                        singleRowUpdateMap.get(row.value.event_id).event_impact_type !== undefined
                          ? singleRowUpdateMap.get(row.value.event_id).event_impact_type
                          : row.value.event_impact_type !== null
                          ? row.value.event_impact_type
                          : ''
                      }
                    >
                      {singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                      singleRowUpdateMap.get(row.value.event_id).event_impact_type !== undefined
                        ? customEventsTypesMap.get(singleRowUpdateMap.get(row.value.event_id).event_impact_type)
                        : row.value.event_impact_type_description !== null
                        ? row.value.event_impact_type_description
                        : ''}
                    </option>
                    {singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                    singleRowUpdateMap.get(row.value.event_id).event_impact_type !== undefined
                      ? customEventsTypes
                          .filter(
                            event =>
                              event.event_impact_type !== singleRowUpdateMap.get(row.value.event_id).event_impact_type,
                          )
                          .map(event => {
                            return (
                              <option key={event.event_impact_type} value={event.event_impact_type}>
                                {event.description}
                              </option>
                            );
                          })
                      : customEventsTypes
                          .filter(
                            event =>
                              event.event_impact_type !==
                              (row.value.event_impact_type !== null ? row.value.event_impact_type : ''),
                          )
                          .map(event => {
                            return (
                              <option key={event.event_impact_type} value={event.event_impact_type}>
                                {event.description}
                              </option>
                            );
                          })}
                  </select>
                ) : (
                  <div
                    className={
                      singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                      singleRowUpdateMap.get(row.value.event_id).event_impact_type !== undefined
                        ? 'table-column-yellow'
                        : ''
                    }
                  >
                    {singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                    singleRowUpdateMap.get(row.value.event_id).event_impact_type !== undefined ? (
                      customEventsTypesMap.get(singleRowUpdateMap.get(row.value.event_id).event_impact_type)
                    ) : row.value.event_impact_type_description === null ? (
                      '-'
                    ) : (
                      <div className={row.value.event_id === null ? 'table-column-green' : ''}>
                        {row.value.event_impact_type_description}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ),
            sortable: true,
            Footer: row =>
              openFooter ? (
                <select
                  className="select"
                  name="newImpactType"
                  onChange={e => handleNewEventChange(e, row, null, null)}
                >
                  <option value="" />
                  {customEventsTypes.map(event => (
                    <option key={event.event_impact_type} value={event.event_impact_type}>
                      {event.description}
                    </option>
                  ))}
                </select>
              ) : null,
          },
          {
            Header: (
              <div>
                {!sortObject.event_start_date.is_sorted ? (
                  <div className="column-header">Start Date</div>
                ) : (
                  <div className="row">
                    <div className="col-8 column-header column-header-align">Start Date</div>
                    <div>
                      {sortObject.event_start_date.is_sorted === true ? (
                        sortObject.event_start_date.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'event_start_date',
            style: { whiteSpace: 'unset' },
            accessor: d => d,
            Cell: row => (
              <div>
                {singleRowEditSet.has(row.value.event_id) ? (
                  <div className="date-picker">
                    <DatePicker
                      selected={
                        singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                        singleRowUpdateMap.get(row.value.event_id).event_start_date !== undefined
                          ? new Date(singleRowUpdateMap.get(row.value.event_id).event_start_date)
                          : new Date(row.value.event_start_date)
                      }
                      value={
                        singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                        singleRowUpdateMap.get(row.value.event_id).event_start_date !== undefined
                          ? singleRowUpdateMap.get(row.value.event_id).event_start_date
                          : row.value.event_start_date
                          ? row.value.event_start_date
                          : 'MM/DD/YYYY'
                      }
                      onChange={date => handleSingleEditEventChange(null, row, 'editStartDate', date)}
                    />
                  </div>
                ) : (
                  <div
                    className={
                      singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                      singleRowUpdateMap.get(row.value.event_id).event_start_date !== undefined
                        ? 'table-column-yellow'
                        : ''
                    }
                  >
                    {singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                    singleRowUpdateMap.get(row.value.event_id).event_start_date !== undefined ? (
                      singleRowUpdateMap.get(row.value.event_id).event_start_date
                    ) : row.value.event_start_date == null ? (
                      '-'
                    ) : (
                      <div className={row.value.event_id === null ? 'table-column-green' : ''}>
                        {row.value.event_start_date}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ),
            sortable: true,
            Footer: row =>
              openFooter ? (
                <div className="date-picker">
                  <DatePicker
                    value={newStartDate}
                    onChange={date => handleNewEventChange(null, row, 'newStartDate', date)}
                  />
                </div>
              ) : null,
          },
          {
            Header: (
              <div>
                {!sortObject.event_end_date.is_sorted ? (
                  <div className="column-header">End Date</div>
                ) : (
                  <div className="row">
                    <div className="col-8 column-header column-header-align">End Date</div>
                    <div>
                      {sortObject.event_end_date.is_sorted === true ? (
                        sortObject.event_end_date.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'event_end_date',
            style: { whiteSpace: 'unset' },
            accessor: d => d,
            Cell: row => (
              <div>
                {singleRowEditSet.has(row.value.event_id) ? (
                  <div className="date-picker">
                    <DatePicker
                      selected={
                        singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                        singleRowUpdateMap.get(row.value.event_id).event_end_date !== undefined
                          ? new Date(singleRowUpdateMap.get(row.value.event_id).event_end_date)
                          : row.value.event_end_date !== null && row.value.event_end_date !== undefined
                          ? new Date(row.value.event_end_date)
                          : new Date(row.value.event_start_date)
                      }
                      value={
                        singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                        singleRowUpdateMap.get(row.value.event_id).event_end_date !== undefined
                          ? singleRowUpdateMap.get(row.value.event_id).event_end_date
                          : row.value.event_end_date != null
                          ? row.value.event_end_date
                          : 'MM/DD/YYYY'
                      }
                      onChange={date => handleSingleEditEventChange(null, row, 'editEndDate', date)}
                    />
                  </div>
                ) : (
                  <div
                    className={
                      singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                      singleRowUpdateMap.get(row.value.event_id).event_end_date !== undefined
                        ? 'table-column-yellow'
                        : ''
                    }
                  >
                    {singleRowUpdateMap.get(row.value.event_id) !== undefined &&
                    singleRowUpdateMap.get(row.value.event_id).event_end_date !== undefined ? (
                      singleRowUpdateMap.get(row.value.event_id).event_end_date
                    ) : row.value.event_end_date == null ? (
                      '-'
                    ) : (
                      <div className={row.value.event_id === null ? 'table-column-green' : ''}>
                        {row.value.event_end_date}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ),
            sortable: true,
            Footer: row =>
              openFooter ? (
                <div className="date-picker">
                  <DatePicker
                    value={newEndDate}
                    onChange={date => handleNewEventChange(null, row, 'newEndDate', date)}
                  />
                </div>
              ) : null,
          },
          {
            Header: (
              <div>
                {!sortObject.is_closed.is_sorted ? (
                  <div className="column-header">Closed?</div>
                ) : (
                  <div className="row">
                    <div className="col-8 column-header column-header-align">Closed?</div>
                    <div>
                      {sortObject.is_closed.is_sorted === true ? (
                        sortObject.is_closed.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
            headerStyle: headerStyleObj,
            style: { whiteSpace: 'unset' },
            id: 'is_closed',
            accessor: d => d,
            width: 110,
            Cell: rowData => {
              let isChecked;
              const singleRowEditStatus = singleRowUpdateMap.get(rowData.value.event_id);
              const hasBeenSingleRowEdited = singleRowEditStatus?.is_closed != null;

              if (hasBeenSingleRowEdited) {
                isChecked = singleRowUpdateMap.get(rowData.value.event_id).is_closed;
              } else {
                isChecked = rowData.value.is_closed;
              }

              return (
                <input
                  className="checked-input"
                  name="closedEvent"
                  id={rowData.index}
                  type="checkbox"
                  checked={isChecked}
                  disabled={!singleRowEditSet.has(rowData.value.event_id)}
                  onChange={e => handleSingleEditEventChange(e, rowData, null, null)}
                />
              );
            },
            sortable: true,
            Footer: rowData =>
              openFooter ? (
                <div>
                  <input
                    className="checked-input"
                    name="newIsClosed"
                    type="checkbox"
                    defaultChecked={false}
                    onChange={e => handleNewEventChange(e, rowData, null, null)}
                  />
                </div>
              ) : null,
          },
          {
            Header: <div className="column-header">Actions</div>,
            headerStyle: headerStyleObj,
            id: 'actions',
            accessor: d => d,
            width: 70,
            sortable: false,
            Cell: rowData =>
              actionComponent(openSingleRow, rowData.original, singleRowEditSet, updateSingleRow, deleteSingle),
            Footer: openFooter ? (
              <div>
                <FontAwesomeIcon icon={faCheck} className="Icon" onClick={addNewEvent} />
                <FontAwesomeIcon icon={faTimes} className="Icon" onClick={toggleFooterInputs} />
              </div>
            ) : null,
          },
        ]}
        getTheadThProps={(state, rowInfo, column) => {
          return {
            onClick: () => {
              if (column.sortable) {
                sortClick(column.id);
              }
            },
          };
        }}
        getTdProps={(state, rowInfo) => {
          if (typeof rowInfo !== 'undefined') {
            return {
              style: tableDataStyleObject,
            };
          }
          return {};
        }}
        noDataText={dataAvailable ? 'Loading Data' : 'No Custom Events Found'}
        defaultPageSize={10}
        pageSizeOptions={[10]}
        minRows={1}
        showPaginationBottom={data.length > 10}
        style={{ background: 'white' }}
      />
      <div className="d-flex mt-3 save-btn-container" style={{ gap: 16 }}>
        <ProfitRoverPrimaryButton onClick={toggleFooterInputs} disabled={openFooter}>
          Add Custom Event
        </ProfitRoverPrimaryButton>

        <ProfitRoverPrimaryButton
          disabled={updateCustomEventsMap.size === 0 && createEventsArray.length === 0}
          onClick={saveChanges}
        >
          Save Changes
        </ProfitRoverPrimaryButton>

        <ProfitRoverPrimaryButton disabled={multiRowEditMap.size === 0} onClick={toggleModal}>
          Edit Selected
        </ProfitRoverPrimaryButton>

        <ProfitRoverDestructiveButton disabled={multiRowDeleteSet.size === 0} onClick={deleteMultiple}>
          Delete Selected
        </ProfitRoverDestructiveButton>
      </div>
    </div>
  );
};

export default eventTable;
