const {
  REACT_APP_GOTAB_ACCESS_ID: GOTAB_ACCESS_ID,
  REACT_APP_GOTAB_AUTH_REDIRECT_URL: GOTAB_AUTH_REDIRECT_URL,
} = process.env;

const goTabOauthURL = 'https://manager.gotab.io/manager/oauth?response_type=code'
  .concat('&access_id=')
  .concat(GOTAB_ACCESS_ID)
  .concat('&redirect_url=')
  .concat(GOTAB_AUTH_REDIRECT_URL);

export default goTabOauthURL;
