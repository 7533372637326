import React from 'react';
import moment from 'moment';
import { getTimeZones } from '@vvo/tzdb';
import ColumnBasedFieldSelection from '../../ColumnBasedFieldSelection';
import {
  ActionButton,
  ConfigTypeSubHeader,
  ContentArea,
  DropdownSelect,
  PageHeader,
  PromptText,
  RadioButtonRow,
  SectionHeader,
  SmallRadioButton,
  TextInput,
} from '../../commonFormComponents';
import { DataSourceType, unknownDataSourceTypeError } from '../../DatasetConfigFormConstants';
import DatasetFieldBuilder from '../../DatasetFieldBuilder';
import { DATASET_FIELD_DISPLAY } from '../../../../generic/datasetFieldDisplay';

const DATASET_FIELD_NAME = 'TRAN';
const REQUIRED_COUNTRY_TIMEZONES = new Set(['CA', 'US', 'BS']);
const FIELD_NAME = DATASET_FIELD_DISPLAY[DATASET_FIELD_NAME];

const timeZones = getTimeZones();

// Filtering for America, Canada, Bahamas timezones
const requiredTimeZones = timeZones.filter(timezone => REQUIRED_COUNTRY_TIMEZONES.has(timezone.countryCode));

// concatenate array of timezones from the group of an each timezone object
const timeZoneList = requiredTimeZones
  .reduce((timezones, value) => [...timezones, ...value.group], [])
  .filter(val => !val.includes('US/')) // Removes timezones like US/Pacific, US/Atlantic.. etc
  .sort();

const customTimeZones = [
  { label: 'Location Local Time', value: 'LOCAL' },
  { label: 'UTC', value: 'UTC' },
  { isDisabled: true, label: '------------------', value: 'separator' },
];
let tzOptions = timeZoneList.map(value => ({ label: value, value }));
tzOptions = [...customTimeZones, ...tzOptions];

/**
 * Validates string representation of a date is in ISO 8601 format.
 * Reference: https://en.wikipedia.org/wiki/ISO_8601
 */
const isValidDateValue = dateText => {
  return moment(dateText, 'YYYY-MM-DD', true).isValid();
};

export const useTimeZoneOptions = () => tzOptions;

const TimeZoneDropDown = ({ value, onSelect }) => {
  const timeZoneOptions = useTimeZoneOptions();

  return <DropdownSelect options={timeZoneOptions} value={value} style={{ margin: 0 }} onSelect={onSelect} />;
};

export default function TransactionDatePage(props) {
  const { formPageApi, dsConfigMetaData, label, nextPage } = props;

  const [fieldInData, setFieldInData] = React.useState();
  const [isTimeIncluded, setIsTimeIncluded] = React.useState();
  const [columnName, setColumnName] = React.useState();
  const [fixedValue, setFixedValue] = React.useState('');
  const [formula, setFormula] = React.useState('');
  const [dataSourceType, setDataSourceType] = React.useState();
  const [selectedTimezone, setSelectedTimezone] = React.useState();

  const dateValueIsValid = !fieldInData && isValidDateValue(fixedValue);
  const fixedValueSatisfied = dataSourceType === DataSourceType.FIXED_VALUE && dateValueIsValid;
  const columnNameSatisfied = dataSourceType === DataSourceType.FROM_COLUMN && columnName != null;
  const formulaSatisfied = dataSourceType === DataSourceType.FORMULA && formula != null && formula !== '';
  const userHasSpecifiedValue = fixedValueSatisfied || columnNameSatisfied || formulaSatisfied;
  const userHasSpecifiedTimeValue = selectedTimezone != null && isTimeIncluded != null;
  const isTimeValueSatisfied = userHasSpecifiedTimeValue || isTimeIncluded === false;

  const canAskTranDateHasTime = fieldInData === true && userHasSpecifiedValue;
  const canDisplayTimeZoneDropDown = canAskTranDateHasTime && isTimeIncluded === true;
  const canContinue = fieldInData != null && userHasSpecifiedValue && (isTimeValueSatisfied || fieldInData === false);

  const onClickNext = async () => {
    const datasetFieldBuilder = new DatasetFieldBuilder().setDatasetFieldName(DATASET_FIELD_NAME);

    if (dataSourceType === DataSourceType.FIXED_VALUE) {
      datasetFieldBuilder.setFixedValue(fixedValue);
    } else if (dataSourceType === DataSourceType.FROM_COLUMN) {
      datasetFieldBuilder.setFromColumn(columnName);
    } else if (dataSourceType === DataSourceType.FORMULA) {
      datasetFieldBuilder.setFormula(formula);
    } else {
      throw unknownDataSourceTypeError(dataSourceType);
    }

    if (userHasSpecifiedTimeValue) {
      datasetFieldBuilder.setTimezone(selectedTimezone);
    }

    await formPageApi.actions.addDatasetFields(datasetFieldBuilder.build());

    formPageApi.actions.navigateToPage(nextPage);
  };

  return (
    <div className="ds-config-container">
      <PageHeader>New Data Configuration</PageHeader>

      <ConfigTypeSubHeader industry={formPageApi.state.industry} dataType={formPageApi.state.dataType} />

      <hr />

      <SectionHeader label={label}>{FIELD_NAME}</SectionHeader>

      <ContentArea>
        <PromptText label="1.">Does this field exist in your data set?</PromptText>
        <RadioButtonRow>
          <SmallRadioButton
            selected={fieldInData === true}
            onClick={() => {
              setFieldInData(true);

              if (fieldInData === false) {
                // Don't set to undefined if the user clicks this button while it's already selected
                setDataSourceType(undefined);
              }
            }}
          >
            I think so!
          </SmallRadioButton>
          <SmallRadioButton
            selected={fieldInData === false}
            onClick={() => {
              setFieldInData(false);
              setFixedValue('');
              setIsTimeIncluded();
              setSelectedTimezone();
              setDataSourceType(DataSourceType.FIXED_VALUE);
            }}
          >
            No
          </SmallRadioButton>
        </RadioButtonRow>

        {fieldInData != null && (
          <>
            {fieldInData ? (
              <>
                <PromptText label="2.">
                  Select the column that matches up with <strong>{FIELD_NAME}</strong>.
                </PromptText>

                <ColumnBasedFieldSelection
                  dataSamples={dsConfigMetaData.dataSample}
                  columnName={columnName}
                  setColumnName={setColumnName}
                  formula={formula}
                  setFormula={setFormula}
                  dataSourceType={dataSourceType}
                  setDataSourceType={setDataSourceType}
                />
              </>
            ) : (
              <>
                <PromptText label="2.">Please give this date a fixed value.</PromptText>

                <TextInput placeholder="YYYY-MM-DD" value={fixedValue} onChange={e => setFixedValue(e.target.value)} />
              </>
            )}
          </>
        )}

        {canAskTranDateHasTime && (
          <>
            <PromptText label="3.">
              Does your <strong>{FIELD_NAME}</strong> field also include Time?
            </PromptText>
            <RadioButtonRow>
              <SmallRadioButton selected={isTimeIncluded === true} onClick={() => setIsTimeIncluded(true)}>
                Yes
              </SmallRadioButton>
              <SmallRadioButton selected={isTimeIncluded === false} onClick={() => setIsTimeIncluded(false)}>
                No
              </SmallRadioButton>
            </RadioButtonRow>
          </>
        )}
        {canDisplayTimeZoneDropDown && (
          <>
            <PromptText label="4.">
              In which <strong>Time Zone</strong> is your data recorded?
            </PromptText>
            <TimeZoneDropDown value={selectedTimezone} onSelect={setSelectedTimezone} />
          </>
        )}

        {canContinue && (
          <ActionButton onClick={onClickNext} style={{ marginTop: 30 }}>
            Continue
          </ActionButton>
        )}
      </ContentArea>
    </div>
  );
}
