import _ from 'lodash';
import camelcase from 'camelcase';
import React from 'react';
import { useAnomalyDetectionContext } from './AnomalyDetectionContext';
import { useAnomalySegmentStatsMeta } from './anomalyDetectionHooks';
import './anomaly-comparisons.scss';

const COMPARISON_COLOR_PALETTE = [
  '#7FD4EF',
  '#AED6AA',
  '#F9DE7F',
  '#F094EF',
  '#F6C588',
  '#D99288',
  '#C089EF',
  '#ADFC93',
  '#F2978A',
  '#B1B3B3',
];

const NOT_EQUAL_COMP_FUNC = '__ne__';

const mapToDimName = (dimsById, dimId) => {
  const { name = '' } = dimsById[dimId] ?? {};
  return name;
};

const buildCheckboxLabel = (slice, dimsById) => {
  let label = '';

  const dimIds = Object.keys(slice.definition);
  const [sameIds, otherIds] = _.partition(dimIds, dimId => {
    const dimDefinition = slice.definition[dimId];

    return dimDefinition.comparison_func !== NOT_EQUAL_COMP_FUNC;
  });

  let otherLabel = 'All Other: ';
  const otherDimNames = otherIds.map(dimId => mapToDimName(dimsById, dimId));
  otherLabel += otherDimNames.join(', ');

  // For some slices, this part of the label will not be needed
  const hasASameLabel = sameIds.length > 0;
  let sameLabel = '';
  if (hasASameLabel) {
    sameLabel = 'Same: ';

    const sameDimNames = sameIds.map(dimId => mapToDimName(dimsById, dimId));
    sameLabel += sameDimNames.join(', ');

    label += `${sameLabel} | `;
  }

  label += otherLabel;

  return label;
};

export const useAnomalyComparisonLegend = params => {
  const {
    dimensions: { data: dimensions = [] },
  } = useAnomalyDetectionContext();
  const { data: anomalySegmentMeta = {} } = useAnomalySegmentStatsMeta(params);

  const [comparisonSelectionState, setComparisonSelectionState] = React.useState({});

  const toggleSelectionState = columnLabel => {
    const currentValue = comparisonSelectionState[columnLabel];
    const newState = { ...comparisonSelectionState, [columnLabel]: !currentValue };
    setComparisonSelectionState(newState);
  };

  let { slice_attributes: slices = [] } = anomalySegmentMeta;
  // These should already be in order, but this code serves as an extra defensive measure
  slices = _.orderBy(slices, 'score', 'desc');

  const dimsById = _.keyBy(dimensions, 'product_dimension_id');
  const comparisons = slices.map((slice, i) => {
    // Columns are camelcased in the Hook that fetches the CSV data
    const columnLabel = camelcase(slice.column_label);

    return {
      ...slice,
      checked: comparisonSelectionState[columnLabel] === true,
      color: COMPARISON_COLOR_PALETTE[i],
      columnLabel,
      label: buildCheckboxLabel(slice, dimsById),
    };
  });

  /**
   * When a (re)fetch occurs, this useEffect will reconcile any new comparisons with the
   * existing selection state.
   */
  React.useEffect(() => {
    const knownColumns = Object.keys(comparisonSelectionState);
    const columns = comparisons.map(({ columnLabel }) => columnLabel);

    if (!_.isEqual(knownColumns, columns)) {
      const state = {};
      comparisons.forEach(({ columnLabel }) => {
        if (columnLabel in comparisonSelectionState) {
          state[columnLabel] = comparisonSelectionState[columnLabel];
        } else {
          state[columnLabel] = false;
        }
      });

      setComparisonSelectionState(state);
    }
  }, [comparisonSelectionState, comparisons]);

  return { comparisons, toggleSelectionState };
};

const ComparisonLegendCheckbox = ({ comparison, onClick }) => {
  const { label, color, checked } = comparison;

  const interactionHandlers = {
    onClick,
    onKeyDown: e => {
      if (e.key === 'Enter') {
        onClick();
      }
    },
  };

  return (
    <div className="checkbox-legend-row">
      <label>{label}</label>
      <div className="d-flex align-items-center justify-content-center">
        <input type="checkbox" tabIndex="0" checked={checked} {...interactionHandlers} />
      </div>
      <div
        className="legend-box"
        style={{ backgroundColor: color }}
        role="checkbox"
        aria-checked={checked}
        {...interactionHandlers}
      />
      <div className="comparison-label" role="checkbox" aria-checked={checked} {...interactionHandlers}>
        {label}
      </div>
    </div>
  );
};

const AnomalyComparisons = ({ comparisons, toggleSelectionState }) => (
  <div className="anomaly-comparisons">
    <div className="heading">Relevant Comparisons</div>
    <div className="checkbox-rows">
      {comparisons.map((comparison, i) => {
        const { label, columnLabel } = comparison;
        return (
          <ComparisonLegendCheckbox
            key={label + i}
            comparison={comparison}
            onClick={() => toggleSelectionState(columnLabel)}
          />
        );
      })}
    </div>
  </div>
);

export default AnomalyComparisons;
