import React from 'react';

/**
 * React Context for all fetched data from our own domain (dimensions/values, locations, etc).
 */
export const DomainContext = React.createContext();

/**
 * React Context for all the data needed to render the various components of the dashboard
 * (uplift bars, price change recommendations, graphs, etc).
 */
export const DashboardContext = React.createContext();
