import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // "Alerts" (like react-router-dom's <Prompt>) cause window focus events,
      // which will force data to be refetched unnecessarily, so this feature shall
      // be opt-in on a per-query basis
      refetchOnWindowFocus: false,
    },
  },
});

export default queryClient;
