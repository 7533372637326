import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ERROR } from '../../../colors';
import './datasetSampleTable.css';

const MAXIMUM_DATA_SAMPLE_LENGTH = 30;

const DatasetSampleTable = props => {
  const { dataSamples, disableColumnSelection, onSelectColumn } = props;

  const [hoveredColumn, setHoveredColumn] = React.useState();

  const columnNames = dataSamples.map(sample => sample.header_name);

  const onSelectColumnHandler = React.useCallback(
    columnName => {
      onSelectColumn(columnName);
      setHoveredColumn(undefined);
    },
    [onSelectColumn],
  );

  const onMouseLeave = React.useCallback(() => {
    setHoveredColumn(undefined);
  }, []);

  // Create rows of data
  let rows = React.useMemo(() => {
    const sampleValues = dataSamples.map(sample => sample.sample_values);

    const sampleSizes = sampleValues.map(sampleValuesForColumn => sampleValuesForColumn.length);
    const numberOfRowsAvailable = sampleSizes.length > 0 ? _.min(sampleSizes) : 0;

    const assembledRows = [];
    for (let rowIndex = 0; rowIndex < numberOfRowsAvailable; ++rowIndex) {
      assembledRows[rowIndex] = sampleValues.map(sampleValuesForColumn => {
        return sampleValuesForColumn[rowIndex];
      });
    }
    return assembledRows;
  }, [dataSamples]);

  const headers = columnNames.map(columnName => {
    const onMouseEnter = () => {
      setHoveredColumn(columnName);
    };
    return (
      <th
        key={columnName}
        className={classnames({ hovered: hoveredColumn === columnName })}
        onClick={() => onSelectColumnHandler(columnName)}
        onMouseEnter={onMouseEnter}
      >
        {columnName}
      </th>
    );
  });

  rows = rows.map((row, rowIndex) => (
    <tr key={rowIndex.toString()}>
      {row.map((sampleValue, colIndex) => {
        const index = colIndex.toString();
        const onClick = () => onSelectColumnHandler(columnNames[colIndex]);
        const onKeyDown = e => {
          if (e.keyCode === 'Enter') {
            onClick();
          }
        };
        const onMouseEnter = () => {
          setHoveredColumn(columnNames[colIndex]);
        };
        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <td
            key={index}
            className={classnames({ hovered: hoveredColumn === columnNames[colIndex] })}
            onMouseEnter={onMouseEnter}
            onKeyDown={onKeyDown}
            onClick={onClick}
          >
            {sampleValue.length > MAXIMUM_DATA_SAMPLE_LENGTH
              ? `${sampleValue.slice(0, MAXIMUM_DATA_SAMPLE_LENGTH)}...`
              : sampleValue}
          </td>
        );
      })}
    </tr>
  ));

  /**
   * Customers with integrations will likely not have a data sample to display.
   */
  const canDisplayTable = headers.length > 0;
  const backupText = (
    <i style={{ marginBottom: 10, color: ERROR }}>
      It seems we cannot display any of your data for some reason.{' '}
      <Link to="/help">Please contact us here for assistance.</Link>
    </i>
  );

  return canDisplayTable ? (
    <div className={classnames('ds-config-sample-table-container', { 'disable-events': disableColumnSelection })}>
      <table className="ds-config-sample-table" onMouseLeave={onMouseLeave}>
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  ) : (
    backupText
  );
};

export default DatasetSampleTable;
