import moment from 'moment-timezone';

const Copyright = () => {
  // Here for reference
  // const timeZoneOffSet = moment.tz.guess();

  // Library uses best guess automatically
  const currentYear = moment.tz().format('YYYY');
  return `© Copyright ${currentYear}・ProfitRover・All rights reserved.`;
};

export default Copyright;
