import React from 'react';
import _ from 'lodash';
import { useConfigTableContext } from './settingsAccordion/configTableContext';
import { ConfigFieldName } from './fieldBuilders';
import { useTimeZoneOptions } from '../data-center/datasetConfig/pages/commonPages/TransactionDatePage';

export const getLocationIdentifier = location => location?.location_identifier ?? location?.location_description;
export const getLocationDescription = location => location?.location_description ?? location?.location_identifier;

export const useInitialLocationAccordion = accordionFieldName => {
  const checkboxName = `${accordionFieldName}.checkbox`;
  const dropdownName = `${accordionFieldName}.fixedValue`;
  const columnName = `${accordionFieldName}.fromColumn`;
  const datasetFieldIdName = `${accordionFieldName}.datasetFieldId`;

  const { columnNameOptions, locations, datasetConfigFields } = useConfigTableContext();

  const initialFormState = React.useMemo(() => {
    let initialState = { checkbox: true };

    const locationField = datasetConfigFields.find(field => field.dataset_field_name === 'LOC');

    if (locationField) {
      const formStateForField = {
        checkbox: null,
        fixedValue: null,
        fromColumn: null,
        datasetFieldId: locationField?.dataset_field_id,
      };

      if (locationField.fixed_value != null) {
        const fixedLocation = locations.find(loc => getLocationIdentifier(loc) === locationField.fixed_value);

        formStateForField.checkbox = true;
        formStateForField.fixedValue = { label: getLocationDescription(fixedLocation), value: fixedLocation };
      } else {
        const { from_column: fromColumn } = locationField;

        const selectedColumn = columnNameOptions.find(option => option.value === fromColumn);

        formStateForField.checkbox = false;
        formStateForField.fromColumn = selectedColumn;
      }

      initialState = formStateForField;
    }

    return initialState;
  }, [columnNameOptions, datasetConfigFields, locations]);

  return {
    initialFormState,
    checkboxName,
    dropdownName,
    columnName,
    datasetFieldIdName,
  };
};

export const useInitialTransactionDateAccordion = accordionFieldName => {
  const checkboxName = `${accordionFieldName}.checkbox`;
  const timeCheckBoxName = `${checkboxName}_time`;
  const fixedValue = `${accordionFieldName}.fixedValue`;
  const columnName = `${accordionFieldName}.fromColumn`;
  const priceType = `${accordionFieldName}.priceType`;
  const timezone = `${accordionFieldName}.timezone`;
  const datasetFieldIdName = `${accordionFieldName}.datasetFieldId`;

  const { columnNameOptions, datasetConfigFields } = useConfigTableContext();

  const timeZoneOptions = useTimeZoneOptions();

  const initialFormState = React.useMemo(() => {
    const initialState = { checkbox: true, timeCheckbox: false };

    const transactionDateField = datasetConfigFields.find(({ dataset_field_name: dsFieldName }) =>
      [ConfigFieldName.TRANSACTION_DATE].includes(dsFieldName),
    );

    if (transactionDateField) {
      const {
        from_column: fromColumn,
        fixed_value: fieldFixedValue,
        timezone: timeZone,
        dataset_field_id: datasetFieldId,
      } = transactionDateField;

      initialState.checkbox = fromColumn != null;

      if (fromColumn != null) {
        initialState.columnName = columnNameOptions.find(option => option.value === fromColumn);
        initialState.fixedValue = null;
      } else if (fieldFixedValue != null) {
        initialState.fixedValue = fieldFixedValue;
        initialState.columnName = null;
      }

      initialState.timeCheckbox = timeZone != null;
      initialState.timezone = timeZoneOptions.find(option => option.value === timeZone) ?? null;
      initialState.datasetFieldId = datasetFieldId;
    }

    return initialState;
  }, [columnNameOptions, datasetConfigFields, timeZoneOptions]);

  return {
    initialFormState,
    checkboxName,
    timeCheckBoxName,
    fixedValue,
    columnName,
    priceType,
    timezone,
    datasetFieldIdName,
  };
};

export const useInitialItemQuantityAccordion = accordionFieldName => {
  const checkboxName = `${accordionFieldName}.checkbox`;
  const fixedValue = `${accordionFieldName}.fixedValue`;
  const columnName = `${accordionFieldName}.fromColumn`;
  const datasetFieldIdName = `${accordionFieldName}.datasetFieldId`;

  const { columnNameOptions, datasetConfigFields } = useConfigTableContext();

  const initialFormState = React.useMemo(() => {
    const initialState = { checkbox: true };

    const itemQuantityField = datasetConfigFields.find(
      ({ dataset_field_name: dsFieldName }) => dsFieldName === ConfigFieldName.ITEM_QUANTITY,
    );

    if (itemQuantityField) {
      const {
        from_column: fromColumn,
        fixed_value: fieldFixedValue,
        dataset_field_id: datasetFieldId,
      } = itemQuantityField;

      initialState.checkbox = fromColumn != null;
      initialState.datasetFieldId = datasetFieldId;

      if (fromColumn != null) {
        initialState.columnName = columnNameOptions.find(option => option.value === fromColumn);
        initialState.fixedValue = null;
      } else if (fieldFixedValue != null) {
        initialState.fixedValue = fieldFixedValue;
        initialState.columnName = null;
      }
    }

    return initialState;
  }, [columnNameOptions, datasetConfigFields]);

  return {
    initialFormState,
    checkboxName,
    fixedValue,
    columnName,
    datasetFieldIdName,
  };
};

const { PRICE_PER_UNIT, TOTAL_TRANSACTION_PRICE } = ConfigFieldName;

export const useInitialPriceAccordion = accordionFieldName => {
  const checkboxName = `${accordionFieldName}.checkbox`;
  const fixedValue = `${accordionFieldName}.fixedValue`;
  const columnName = `${accordionFieldName}.fromColumn`;
  const priceType = `${accordionFieldName}.priceType`;
  const datasetFieldIdName = `${accordionFieldName}.datasetFieldId`;

  const { columnNameOptions, datasetConfigFields } = useConfigTableContext();

  const initialFormState = React.useMemo(() => {
    const initialState = { checkbox: true };

    const priceTypeField = datasetConfigFields.find(({ dataset_field_name: dsFieldName }) =>
      [PRICE_PER_UNIT, TOTAL_TRANSACTION_PRICE].includes(dsFieldName),
    );

    if (priceTypeField) {
      const {
        dataset_field_name: dsFieldName,
        from_column: fromColumn,
        fixed_value: fieldFixedValue,
        dataset_field_id: datasetFieldId,
      } = priceTypeField;

      if (dsFieldName === PRICE_PER_UNIT) {
        initialState.priceType = PRICE_PER_UNIT;
      } else if (dsFieldName === TOTAL_TRANSACTION_PRICE) {
        initialState.priceType = TOTAL_TRANSACTION_PRICE;
      }

      initialState.checkbox = fromColumn != null;
      initialState.datasetFieldId = datasetFieldId;

      if (fromColumn != null) {
        initialState.columnName = columnNameOptions.find(option => option.value === fromColumn);
      } else if (fieldFixedValue != null) {
        initialState.fixedValue = fieldFixedValue;
      }
    }

    return initialState;
  }, [columnNameOptions, datasetConfigFields]);

  return {
    initialFormState,
    checkboxName,
    fixedValue,
    columnName,
    priceType,
    datasetFieldIdName,
  };
};

export const EMPTY_DESCRIPTOR = { checkbox: true, fromColumn: null, descriptorName: null, fixedValue: null };

export const useInitialItemDescriptorAccordion = () => {
  const { columnNameOptions, datasetConfigFields, dimensions } = useConfigTableContext();

  const initialFormState = React.useMemo(() => {
    let initialState = [EMPTY_DESCRIPTOR];

    const dimensionsById = _.keyBy(dimensions, 'product_dimension_id');

    const dimFields = datasetConfigFields
      .filter(({ dataset_field_name: dsFieldName }) => dsFieldName === ConfigFieldName.PRODUCT_DIMENSION)
      .filter(field => {
        const { product_dimension_id: productDimId } = field;

        // Only create item descriptor rows for USER type dimensions
        return dimensionsById[productDimId]?.dimension_type === 'USER';
      });

    if (dimFields.length > 0) {
      initialState = dimFields.map(field => {
        const initialDescriptor = { checkbox: true, fromColumn: null, descriptorName: null, fixedValue: null };
        const {
          from_column: fromColumn,
          fixed_value: fieldFixedValue,
          product_dimension_id: dimId,
          dataset_field_id: datasetFieldId,
        } = field;

        const selectedDim = dimensions.find(dim => dim.product_dimension_id === dimId);

        if (selectedDim != null) {
          initialDescriptor.descriptorName = { label: selectedDim.name, value: selectedDim };
        }

        initialDescriptor.checkbox = fromColumn != null;
        initialDescriptor.datasetFieldId = datasetFieldId;

        if (fromColumn != null) {
          initialDescriptor.fromColumn = columnNameOptions.find(option => option.value === fromColumn);
        } else if (fieldFixedValue != null) {
          initialDescriptor.fixedValue = fieldFixedValue;
        }

        return initialDescriptor;
      });
    }

    return initialState;
  }, [columnNameOptions, datasetConfigFields, dimensions]);

  return {
    initialFormState,
  };
};
