import React from 'react';
import AdminRoleCheck from '../AdminRoleCheck';
import ShopifySubscriptionManagmentSummary from './ShopifySubscriptionManagmentSummary';
import { NotFound } from '../common/CommonSubscriptionComponents';
import LoadingPage from '../../common/LoadingPage';
import AddressDetailsModal from '../../../dashboards/eo/revenueUplift/AddressDetailsModal';
import PickLocationToActivateModal from '../../../dashboards/eo/revenueUplift/PickLocationToActivateModal';
import { useSelectTrialOrFirstFreeLocation } from '../../../dashboards/eo/revenueUplift/revenueUpliftDashboardHooks';
import { KaizenProduct, SubscriptionType } from '../../../generic/subscriptions/SubscriptionConstants';
import { useSubscriptionStatusForProduct } from '../../../routing/subscriptionStatusHooks';
import { useLocations } from '../../../../data-access/query/locations';

const ShopifySubscriptionManagment = () => {
  const {
    isFetching,
    hasSubscription,
    isShopifySubscription,
    subscriptionId,
    subscriptionType,
    kaizenProductStatus,
  } = useSubscriptionStatusForProduct(KaizenProduct.KP);

  const { data: locations = [], isFetching: isFetchingLocations } = useLocations();

  const isFreeTier = subscriptionType === SubscriptionType.FIRST_FREE;

  const {
    selectedLocation,
    setSelectedLocation,
    onSelectLocation,
    onHideAddressDetailsModal,
    onUpdateLocation,
    showPickLocationToActivateModal,
    showAddressDetailsModal,
    isSubmittingTrialLocationSelection,
  } = useSelectTrialOrFirstFreeLocation(isFetchingLocations, locations);

  if (isFetching) {
    return <LoadingPage />;
  }

  if (!isShopifySubscription || !hasSubscription) {
    return <NotFound />;
  }

  if (showPickLocationToActivateModal) {
    return (
      <PickLocationToActivateModal
        locations={locations}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        onSelectLocation={onSelectLocation}
        subscriptionType={SubscriptionType.TRIAL_PERIOD}
      />
    );
  }

  if (showAddressDetailsModal) {
    return (
      <AddressDetailsModal
        selectedLocation={selectedLocation}
        onHideAddressDetailsModal={onHideAddressDetailsModal}
        onUpdateLocation={onUpdateLocation}
        isSubmittingTrialLocationSelection={isSubmittingTrialLocationSelection}
      />
    );
  }

  return (
    <ShopifySubscriptionManagmentSummary
      subscriptionId={subscriptionId}
      isFreeTier={isFreeTier}
      kaizenProductStatus={kaizenProductStatus}
    />
  );
};

const RoleCheck = props => (
  <AdminRoleCheck>
    <ShopifySubscriptionManagment {...props} />
  </AdminRoleCheck>
);

export default RoleCheck;
