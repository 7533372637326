import _ from 'lodash';

/**
 * TODO: Rename keys containing the word "current" to use "baseline" instead
 */
class DimValueStatistic {
  constructor(label, datasetValue) {
    this.hasRows = false;

    this.label = label;
    this.datasetValue = datasetValue;

    this.kaizenPriceChangePctRec = null;
    this.kaizenPriceChangeCurrencyRec = null;

    this.sumOfApprovedPriceWeights = 0.0;
    this.sumOfCurrentPriceWeights = 0.0; // Running sum total
    this.sumOfKaizenPriceWeights = 0.0;

    // Used for calculating price change recommendations when the total demand is 0
    this.unweightedApprovedPrices = [];
    this.unweightedCurrentPrices = [];
    this.unweightedKaizenPrices = [];

    this.approvedRevenue = 0.0; // Running sum total
    this.baselineRevenue = 0.0; // Running sum total
    this.kaizenRevenue = 0.0; // Running sum total

    this.totalDemandAtApprovedPrices = 0.0; // Running sum total
    this.totalDemandAtCurrentPrices = 0.0; // Running sum total
    this.totalDemandAtKaizenPrices = 0.0; // Running sum total
  }
}

export default class DashboardDimensionStats {
  constructor({ id, label, dimValues }) {
    this.id = id;
    this.label = label;

    this.dimValueStats = {};
    dimValues.forEach(dimValue => {
      let dimValLabel;
      let datasetValue;
      if (_.isObject(dimValue)) {
        ({ dimValLabel, datasetValue } = dimValue);
      } else {
        // Assume dimValue is a string, and use it for both
        dimValLabel = dimValue;
        datasetValue = dimValue;
      }

      this.dimValueStats[datasetValue] = new DimValueStatistic(dimValLabel, datasetValue);
    });
  }
}
