import { Country, Region, Postcode } from './countryConstants';

// eslint-disable-next-line import/prefer-default-export
export const getLocationTableLabels = locations => {
  let regionLabel = Region.STATE;
  let postcodeLabel = Postcode.ZIP;

  const locationCountries = locations.map(location => location.country_code || location.country);
  const includesUSLocation = locationCountries.includes(Country.US);
  const includesCALocation = locationCountries.includes(Country.CA);

  if (includesUSLocation && includesCALocation) {
    regionLabel = Region.COMBO;
    postcodeLabel = Postcode.COMBO;
  } else if (includesCALocation) {
    regionLabel = Region.PROVINCE;
    postcodeLabel = Postcode.POSTAL;
  }

  return { regionLabel, postcodeLabel };
};
