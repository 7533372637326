import React from 'react';
import { DataSource, DataSourceToConfigName } from '../../dataSources/dataSourceConstants';
import { ReactComponent as BookeoLogoImage } from '../../../images/bookeo-logo.svg';
import { ReactComponent as CloverLogoImage } from '../../../images/clover-logo.svg';
import { ReactComponent as EtsyLogoImage } from '../../../images/etsy-logo.svg';
import { ReactComponent as GoTabLogoImage } from '../../../images/gotab-orange-logo.svg';
import { ReactComponent as LightspeedLogoImage } from '../../../images/lightspeed-logo.svg';
import { ReactComponent as ShopifyLogoImage } from '../../../images/shopify-logo.svg';
import { ReactComponent as SquareLogoImage } from '../../../images/square-logo.svg';
import { ReactComponent as ToastLogoImage } from '../../../images/toast-logo.svg';
import './data-source-logos.scss';

/**
 * Bakes in alt text for each integration DataSource's logo
 */
export const BookeoLogo = props => <BookeoLogoImage alt="Bookeo Logo" {...props} />;
export const CloverLogo = props => <CloverLogoImage alt="Clover Logo" {...props} />;
export const EtsyLogo = props => <EtsyLogoImage alt="Etsy Logo" {...props} />;
export const GoTabLogo = props => <GoTabLogoImage alt="GoTab Logo" {...props} />;
export const LightspeedLogo = props => <LightspeedLogoImage alt="Lightspeed Logo" {...props} />;
export const ShopifyLogo = props => <ShopifyLogoImage alt="Shopify Logo" {...props} />;
export const SquareLogo = props => <SquareLogoImage alt="Square Logo" {...props} />;
export const ToastLogo = props => <ToastLogoImage alt="Toast Logo" {...props} />;

/**
 * Maps DataSources to images to display as radio buttons in the <PointOfSaleSetup> page
 */
export const DATA_SOURCE_TO_TILE_IMAGE = {
  [DataSource.BOOKEO]: <BookeoLogoImage />,
  [DataSource.CLOVER]: <CloverLogoImage />,
  [DataSource.ETSY]: <EtsyLogoImage className="px-4 pt-2" />,
  [DataSource.GOTAB]: <GoTabLogoImage />,
  [DataSource.LIGHTSPEED_K]: <LightspeedLogoImage />,
  [DataSource.SHOPIFY]: <ShopifyLogoImage />,
  [DataSource.SQUARE]: <SquareLogo />,
  [DataSource.TOAST]: <ToastLogoImage />,
};

const CLOVER_DATA_CENTER_LOGO = <CloverLogoImage style={{ width: 120 }} />;
const ETSY_DATA_CENTER_LOGO = <EtsyLogoImage className="pt-1" style={{ marginLeft: -4, width: 80 }} />;
const GOTAB_DATA_CENTER_LOGO = <GoTabLogoImage />;
const LIGHTSPEED_K_DATA_CENTER_LOGO = <LightspeedLogoImage style={{ width: 120 }} />;
const SHOPIFY_DATA_CENTER_LOGO = <ShopifyLogoImage style={{ marginLeft: -1, width: 120 }} />;
const TOAST_DATA_CENTER_LOGO = <ToastLogoImage style={{ marginLeft: -1, width: 120 }} />;

/**
 * For rendering logo labels in the Data Center for fully-fledged integrations
 */
export const DATA_SOURCE_TO_DATA_CENTER_LABEL = {
  [DataSource.BOOKEO]: <BookeoLogoImage style={{ maxHeight: 41 }} />,
  [DataSource.CLOVER]: CLOVER_DATA_CENTER_LOGO,
  [DataSource.GOTAB]: GOTAB_DATA_CENTER_LOGO,
  [DataSource.LIGHTSPEED_K]: LIGHTSPEED_K_DATA_CENTER_LOGO,
  [DataSource.ETSY]: ETSY_DATA_CENTER_LOGO,
  [DataSource.SHOPIFY]: SHOPIFY_DATA_CENTER_LOGO,
  [DataSource.SQUARE]: <SquareLogo style={{ height: '100%', width: 132 }} />,
  [DataSource.TOAST]: TOAST_DATA_CENTER_LOGO,
};

/**
 * Supports rendering file-based configs' labels as images instead of text for specially recognized config names
 */
export const SPECIAL_CONFIG_NAME_TO_TILE_IMAGE = {
  [DataSourceToConfigName[DataSource.CLOVER]]: CLOVER_DATA_CENTER_LOGO,
  [DataSourceToConfigName[DataSource.ETSY]]: ETSY_DATA_CENTER_LOGO,
  [DataSourceToConfigName[DataSource.GOTAB]]: GOTAB_DATA_CENTER_LOGO,
  [DataSourceToConfigName[DataSource.LIGHTSPEED_K]]: LIGHTSPEED_K_DATA_CENTER_LOGO,
  [DataSourceToConfigName[DataSource.SHOPIFY]]: SHOPIFY_DATA_CENTER_LOGO,
  [DataSourceToConfigName[DataSource.TOAST]]: TOAST_DATA_CENTER_LOGO,
};

/**
 * Maps DataSources to images to display in instructions screens
 */
export const DATA_SOURCE_TO_HEADING_LOGO = {
  [DataSource.BOOKEO]: <BookeoLogo />,
  [DataSource.CLOVER]: <CloverLogo />,
  [DataSource.ETSY]: <EtsyLogo />,
  [DataSource.GOTAB]: <GoTabLogo />,
  [DataSource.LIGHTSPEED_K]: <LightspeedLogo style={{ width: 120 }} />,
  [DataSource.SHOPIFY]: <ShopifyLogo />,
  [DataSource.SQUARE]: <SquareLogo style={{ height: '100%', width: 132 }} />,
  [DataSource.TOAST]: <ToastLogo />,
};

export const LogoHeading = ({ dataSource }) => {
  return (
    <div className="data-source-logo-heading">
      <div className="logo">{DATA_SOURCE_TO_HEADING_LOGO[dataSource]}</div>
    </div>
  );
};
