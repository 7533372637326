import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { DataSource } from '../dataSources/dataSourceConstants';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import { KaizenProduct, KaizenProductStatus } from '../generic/subscriptions/SubscriptionConstants';
import Header from '../header/header';
import { useUrlIntegrationDetails } from '../integration/integrationHooks';
import { useSubscriptionStatusForProduct } from '../routing/subscriptionStatusHooks';
import { CenteredProfitRoverSpinner } from '../spinner/ProfitRoverSpinner';
import { useCreateShopifySubscription } from '../../data-access/mutation/shopify';
import { useGuidedSetup } from '../../data-access/query/guidedSetup';
import { onLogout } from '../../utils/axios-client';

const loadingPage = (
  <>
    <Header />
    <HeaderAwarePage scrollable={false}>
      <CenteredProfitRoverSpinner />
    </HeaderAwarePage>
  </>
);

const ShopifyRedirect = () => {
  const shopifySubscriptionMutation = useCreateShopifySubscription({
    onSuccess: async ({ confirmation_url: confirmationUrl }) => {
      if (confirmationUrl) {
        // logout the user to prevent this redirection happening again
        await onLogout();
        // Navigate to Shopify in the same tab
        window.open(confirmationUrl, '_self');
      }
    },
  });

  React.useEffect(() => {
    const createSubscription = async () => {
      await shopifySubscriptionMutation.mutateAsync();
    };

    createSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loadingPage;
};

const GuidedSetupRedirect = () => {
  const {
    hasSubscription: hasKaizenPriceSubscription,
    isFetching,
    isShopifySubscription,
    kaizenProductStatus,
  } = useSubscriptionStatusForProduct(KaizenProduct.KP);
  const { data: guidedSetup = {}, isLoading } = useGuidedSetup();
  const { is_complete: isComplete = false } = guidedSetup;

  if (isLoading || isFetching) {
    return loadingPage;
  }

  if (isShopifySubscription && kaizenProductStatus === KaizenProductStatus.INCOMPLETE) {
    return <ShopifyRedirect />;
  }

  if (hasKaizenPriceSubscription && !isComplete) {
    return <Redirect to="/guided-setup" />;
  }

  return <Redirect to="/welcome" />;
};

const RedirectWithSameQueryParams = ({ to, ...props }) => {
  const { search } = useLocation();

  return <Redirect to={{ pathname: to, search }} {...props} />;
};

const LoginRedirect = () => {
  const { integrationProvider } = useUrlIntegrationDetails();

  if (integrationProvider === DataSource.CLOVER) {
    return <RedirectWithSameQueryParams to="/clover" />;
  }

  if (integrationProvider === DataSource.SQUARE) {
    return <Redirect to="/square-request-auth-code" />;
  }

  // If not attempting to integrate with a 3rd party
  return <GuidedSetupRedirect />;
};

export default LoginRedirect;
