import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FONT_BLACK } from '../../../../colors';
import './revenue-uplift-dashboard-v2.scss';

const NavigationToolbar = ({ openNavigationMenu, label }) => {
  return (
    <div className="filters-toolbar">
      <div
        className="nav-clickable mr-2"
        onClick={openNavigationMenu}
        role="button"
        tabIndex={0}
        onKeyDown={e => e.key === 'Enter' && openNavigationMenu()}
      >
        <FontAwesomeIcon icon={faBars} style={{ fontSize: '1.1rem' }} color={FONT_BLACK} />
        <p className="nav-link mt-1">{label != null ? label : 'Navigation'}</p>
      </div>
    </div>
  );
};

export default NavigationToolbar;
