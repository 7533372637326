/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faRightLong } from '@fortawesome/free-solid-svg-icons';
import Warning from '@material-ui/icons/Warning';
import { LoginErrors, PROFITROVER_WEBAPP, PROFITROVER_WEBSITE } from './authConstants';
import Copyright from './Copyright';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverLogoIcon } from '../generic/ProfitRoverLogos';
import Header from '../header/header';
import { useUrlIntegrationDetails } from '../integration/integrationHooks';
import { CenteredProfitRoverSpinner } from '../spinner/ProfitRoverSpinner';
import { RECAPTCHA_SITE_KEY } from '../../config/google';
import { signIn } from '../../redux/auth/actions';
import { verifyRecaptchaToken } from '../../data-access/query/authentication';
import { onLogout } from '../../utils/axios-client';
import './auth.css';

class LoginBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordIsVisible: false,
      errorType: null,
      error: false,
    };
    this.recaptchaRef = React.createRef();
    // Bind the handlers to this class
    this.emailChangeHandler = this.emailChangeHandler.bind(this);
    this.passwordChangeHandler = this.passwordChangeHandler.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  togglePasswordVisibility = () => this.setState(prevState => ({ passwordIsVisible: !prevState.passwordIsVisible }));

  passwordChangeHandler = e => this.setState({ password: e.target.value });

  emailChangeHandler = e => this.setState({ email: e.target.value });

  submitLogin = async e => {
    e.preventDefault();

    const {
      tryToSignIn,
      history,
      location: { search },
    } = this.props;

    if (RECAPTCHA_SITE_KEY) {
      this.recaptchaRef.current.reset();
      const token = await this.recaptchaRef.current.executeAsync();
      const response = await verifyRecaptchaToken(token);

      if (!response?.data?.success) {
        this.setState({
          error: true,
          errorType: LoginErrors.OTHER,
        });
        return;
      }
    }

    try {
      const { email, password } = this.state;

      const newerVersionDetected = await tryToSignIn(email, password);
      const redirectPath = localStorage.getItem('login_redirect_path');
      const redirectSearch = localStorage.getItem('login_redirect_search');

      const searchParams = new URLSearchParams(search); // Carry over any query params to the next page
      searchParams.delete('from');

      if (redirectPath != null) {
        localStorage.removeItem('login_redirect_path');
        localStorage.removeItem('login_redirect_search');

        if (redirectSearch != null) {
          const searchParamsStr = redirectSearch.replace('?', '');
          const searchParamsArr = searchParamsStr.split('&');
          searchParamsArr.forEach(param => {
            const [key, value] = param.split('=');

            if (key != null && value != null) {
              searchParams.append(key, value);
            }
          });
        }

        history.push({
          pathname: redirectPath,
          search: searchParams.toString(),
        });
      } else {
        history.push({ pathname: '/login-redirect', search: searchParams.toString() });
      }

      if (newerVersionDetected) {
        // Refresh the page to download the latest version of the application code
        window.location.reload();
      }
    } catch (err) {
      this.setState({ error: true, errorType: LoginErrors.INVALID_CREDENTIALS });
    }
  };

  handleFocus = () => this.setState({ error: false });

  render() {
    const {
      location: { search },
    } = this.props;

    const { passwordIsVisible, error, errorType } = this.state;

    const searchParams = new URLSearchParams(search);
    const fromLegacy = searchParams.get('from') === 'legacy';

    let errorMessageFragment = null;
    if (error) {
      let errorText;
      if (errorType === LoginErrors.INVALID_CREDENTIALS) {
        errorText = (
          <span>
            Invalid credentials.
            <br />
            Try again or <Link to="/forgot">reset your password</Link>.
          </span>
        );
      } else {
        errorText = 'An error has occurred, please refresh the page and try again.';
      }

      errorMessageFragment = (
        <div className="sign-in-error-container" data-cy="sign-in-error">
          <div className="sign-in-error-warning-box">
            <Warning className="sign-in-error-warning-icon" />
          </div>
          <div className="sign-in-error-message-box">{errorText}</div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Header />
        <div className="login-page-container">
          {fromLegacy && (
            <div className="rebrand-banner">
              <div className="logos-container">
                <img className="logo kaizen" src="/images/KaizenPriceLogo.png" alt="KaizenPrice Logo" />
                <FontAwesomeIcon className="arrow-icon" icon={faRightLong} />
                <img className="logo profitrover" src="/images/ProfitRoverLinearLogo.png" alt="ProfitRover Logo" />
              </div>
              <div className="rebrand-info">
                KaizenPrice is now ProfitRover! Click{' '}
                <a href={PROFITROVER_WEBSITE} target="_blank" rel="noreferrer">
                  here
                </a>{' '}
                to learn more about this exciting news and about our new features to help drive profitability for your
                business.
              </div>
              <div className="rebrand-info">
                <span>
                  Going forward you can login to your dashboard at{' '}
                  <a href={PROFITROVER_WEBAPP} target="_blank" rel="noreferrer">
                    {PROFITROVER_WEBAPP}
                  </a>
                </span>
              </div>
            </div>
          )}
          <div className="login-form-container">
            <ProfitRoverLogoIcon className="login-profitrover-logo" />
            <form className="form-signin">
              {errorMessageFragment}
              <label htmlFor="inputEmail" className="sr-only">
                Email address
              </label>
              <label htmlFor="inputEmail" style={{ position: 'relative', display: 'block' }}>
                <span className="input-field-dynamic-label">Email</span>
                <input
                  type="email"
                  id="inputEmail"
                  data-cy="input-email"
                  className="form-control"
                  required=""
                  onChange={this.emailChangeHandler}
                  onFocus={this.handleFocus}
                />
              </label>
              <label htmlFor="inputPassword" className="sr-only">
                Password
              </label>
              <label htmlFor="inputPassword" style={{ position: 'relative', display: 'block' }}>
                <span className="input-field-dynamic-label">Password</span>
                <input
                  type={passwordIsVisible ? 'text' : 'password'}
                  id="inputPassword"
                  data-cy="input-password"
                  className="form-control"
                  required=""
                  onChange={this.passwordChangeHandler}
                  onFocus={this.handleFocus}
                />
                <FontAwesomeIcon
                  className="password-visibility-icon"
                  icon={passwordIsVisible ? faEye : faEyeSlash}
                  onClick={this.togglePasswordVisibility}
                />
              </label>
              {RECAPTCHA_SITE_KEY && (
                <ReCAPTCHA ref={this.recaptchaRef} size="invisible" sitekey={RECAPTCHA_SITE_KEY} />
              )}

              <ProfitRoverPrimaryButton className="form-signin-buttons" type="submit" onClick={this.submitLogin}>
                Sign in
              </ProfitRoverPrimaryButton>

              {/* Carry over any URL params to the registration/signup page */}
              <Link to={{ pathname: '/customer-registration', search }} style={{ textDecoration: 'none' }}>
                <ProfitRoverSecondaryButton className="form-signin-buttons">
                  Get Started for Free
                </ProfitRoverSecondaryButton>
              </Link>

              <p className="forgot-password">
                <Link to="/forgot">Forgot Password?</Link>
              </p>
              <p className="copyright-text">
                <Copyright />
              </p>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Login = connect(null, { tryToSignIn: signIn })(LoginBase);

const usePageChecks = history => {
  const [checksCompleted, setChecksCompleted] = React.useState(false);
  const { integrationProvider } = useUrlIntegrationDetails();

  React.useEffect(() => {
    const loggedIn = localStorage.getItem('token');
    const comingFromAnIntegrationSource = integrationProvider != null;

    if (loggedIn && comingFromAnIntegrationSource) {
      const performLogout = async () => {
        await onLogout();
        setChecksCompleted(true);
      };

      performLogout();
    } else if (loggedIn) {
      // The user is already logged in, so they don't need to do it again
      history.push({ pathname: '/login-redirect' });
    } else {
      setChecksCompleted(true);
    }
  }, [integrationProvider, history]);

  return checksCompleted;
};

const LoginPage = ({ history, location }) => {
  const checksCompleted = usePageChecks(history);

  return checksCompleted ? <Login history={history} location={location} /> : <CenteredProfitRoverSpinner />;
};

export default withRouter(LoginPage);
