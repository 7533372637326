import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { VerticalSeparator } from '../../forms/GenericFormComponents';
import { SUCCESS_GREEN } from '../../../colors';
import { useGuidedSetup } from '../../../data-access/query/guidedSetup';
import './progress-bar.scss';

export const TrackedSteps = {
  WELCOME: 'WELCOME',
  ABOUT_YOU: 'ABOUT_YOU',
  DATA_SOURCE: 'DATA_SOURCE',
  CONNECT_DATA: 'CONNECT_DATA',
};

export const StepListItem = ({ active, completed, finalStep = false, children }) => {
  let label = children;

  if (active) {
    label = <strong>{label}</strong>;
  }

  return (
    <div>
      <div className={classnames('tracked-step-label', { active })}>
        {label}
        {completed && <FontAwesomeIcon className="completed-check" icon={faCheckCircle} color={SUCCESS_GREEN} />}
        {!finalStep && <span className="horizontal-step-separator" />}
      </div>
    </div>
  );
};

export const ProgressBar = props => {
  const { activeStep } = props;
  const { data: guidedSetup = {} } = useGuidedSetup();

  const hasCompletedAboutYou = guidedSetup?.naics_industry != null;
  const hasCompletedDataSource = guidedSetup?.data_source != null;
  const hasCompletedConnectData = guidedSetup?.is_complete === true;

  return (
    <div className="guided-setup-progress-bar-container">
      <div className="guided-setup-progress-bar">
        <span className="general-setup-text">General Setup</span>
        <VerticalSeparator className="vertical-separator" />
        <StepListItem active={activeStep === TrackedSteps.ABOUT_YOU} completed={hasCompletedAboutYou}>
          About You
        </StepListItem>
        <StepListItem active={activeStep === TrackedSteps.DATA_SOURCE} completed={hasCompletedDataSource}>
          Data Source
        </StepListItem>
        <StepListItem active={activeStep === TrackedSteps.CONNECT_DATA} completed={hasCompletedConnectData} finalStep>
          Connect Data
        </StepListItem>
      </div>
    </div>
  );
};

ProgressBar.propTypes = { activeStep: PropTypes.oneOf([...Object.values(TrackedSteps), null]) };
ProgressBar.defaultProps = { activeStep: null };
