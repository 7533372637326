import moment from 'moment-timezone';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { TIME_RANGE_DEFINITIONS } from './TimeRangeDefinitions';
import { Objective } from './workflowConstants';
import Header from '../header/header';
import {
  ActionButton,
  PageHeader,
  PromptText,
  TextInput,
  SimpleRadioButton,
  CustomDatePicker,
} from '../forms/GenericFormComponents';
import { ConfigSection } from '../generic/customerConfigConstants';
import GenericLoadingPage from '../generic/GenericLoadingPage';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import { Can } from '../../casl/abilityContext';
import { Action, Subject } from '../../casl/caslConstants';
import { useUpdateCustomerConfigSection } from '../../data-access/mutation/config';
import { updateWorkflow } from '../../data-access/mutation/workflows';
import { useCustomerConfigSection } from '../../data-access/query/config';

const SUPPORTED_OBJECTIVES = Object.freeze([Objective.PRICEELASTICITY]);

const WORKFLOW_DATE_FORMAT = 'YYYY-MM-DD';

const UpdateWorkflowPage = ({ modelInputDateFilters, props }) => {
  const { history, location } = props;
  const workflow = location?.state?.workflow ?? {};
  const { workflow_id: workflowId, description, objective } = workflow;

  const [workflowDateFilters] = modelInputDateFilters.filter(dateFilter => dateFilter.workflow_id === workflowId);

  const { start_date: modelInputStartDate, end_date: modelInputEndDate, recent_days: modelInputRecentDays } =
    workflowDateFilters ?? {};
  const updateCustomerConfigSection = useUpdateCustomerConfigSection();

  const [workflowDescription, setWorkflowDescription] = React.useState(description);
  const workflowExists = props?.location?.state?.workflow;

  const defaultTimeRange = !(modelInputRecentDays || modelInputStartDate || modelInputEndDate);
  const startDateEndDateTimeRange = !!(modelInputStartDate && modelInputEndDate);

  const existingTimeRange = defaultTimeRange
    ? TIME_RANGE_DEFINITIONS.DEFAULT
    : modelInputRecentDays
    ? TIME_RANGE_DEFINITIONS.MOST_RECENT_DAYS
    : startDateEndDateTimeRange
    ? TIME_RANGE_DEFINITIONS.START_DATE_END_DATE
    : TIME_RANGE_DEFINITIONS.ONLY_START_DATE;

  const initialOnlyStartDate = modelInputStartDate
    ? moment(modelInputStartDate, WORKFLOW_DATE_FORMAT).toDate()
    : moment().toDate();

  const initialStartDate = modelInputStartDate
    ? moment(modelInputStartDate, WORKFLOW_DATE_FORMAT).toDate()
    : moment().toDate();

  const initialEndDate = modelInputEndDate
    ? moment(modelInputEndDate, WORKFLOW_DATE_FORMAT).toDate()
    : moment().toDate();

  const initialRecentDays = modelInputRecentDays ?? 365;
  const [timeRangeType, setTimeRangeType] = React.useState(existingTimeRange);
  const [recentDays, setRecentDays] = React.useState(initialRecentDays);
  const [onlyStartDate, setOnlyStartDate] = React.useState(initialOnlyStartDate);
  const [startDate, setStartDate] = React.useState(initialStartDate);
  const [endDate, setEndDate] = React.useState(initialEndDate);

  const pageAllowsWorkflow = SUPPORTED_OBJECTIVES.includes(objective);

  if (!workflowExists || !pageAllowsWorkflow) {
    props.history.push('/welcome');
    return null;
  }

  const workflowDescriptionChanged = workflowDescription !== '' && workflowDescription !== description;

  const changedValueRecentDays =
    timeRangeType === TIME_RANGE_DEFINITIONS.MOST_RECENT_DAYS && recentDays !== initialRecentDays;
  const changedValueOnlyStartDate =
    timeRangeType === TIME_RANGE_DEFINITIONS.ONLY_START_DATE &&
    (onlyStartDate > initialOnlyStartDate || onlyStartDate < initialOnlyStartDate);
  const changedValueStartDateEndDate =
    timeRangeType === TIME_RANGE_DEFINITIONS.START_DATE_END_DATE &&
    (startDate > initialStartDate ||
      startDate < initialStartDate ||
      endDate > initialEndDate ||
      endDate < initialEndDate);
  const existingTimeRangeRadioValueChanged =
    changedValueRecentDays || changedValueOnlyStartDate || changedValueStartDateEndDate;
  const noChangesMade =
    !workflowDescriptionChanged && existingTimeRange === timeRangeType && !existingTimeRangeRadioValueChanged;
  const onlyWorkflowDescriptionChanged =
    workflowDescriptionChanged && existingTimeRange === timeRangeType && !existingTimeRangeRadioValueChanged;

  const onClickEditAnalysis = async () => {
    const workflowBody = { description: workflowDescription };
    let modelInputDateFilter = {};

    if (timeRangeType === TIME_RANGE_DEFINITIONS.MOST_RECENT_DAYS) {
      const currentRecentDays = parseInt(recentDays, 10);
      if (modelInputRecentDays !== currentRecentDays) {
        modelInputDateFilter = {
          recent_days: currentRecentDays,
          start_date: null,
          end_date: null,
        };
      }
    } else if (timeRangeType === TIME_RANGE_DEFINITIONS.ONLY_START_DATE) {
      const currentStartDate = moment(onlyStartDate).format(WORKFLOW_DATE_FORMAT);
      modelInputDateFilter = {
        recent_days: null,
        start_date: currentStartDate,
        end_date: null,
      };
    } else if (timeRangeType === TIME_RANGE_DEFINITIONS.START_DATE_END_DATE) {
      const currentStartDate = moment(startDate).format(WORKFLOW_DATE_FORMAT);
      const currentEndDate = moment(endDate).format(WORKFLOW_DATE_FORMAT);
      if (modelInputStartDate !== currentStartDate || modelInputEndDate !== currentEndDate) {
        modelInputDateFilter = {
          recent_days: null,
          start_date: currentStartDate,
          end_date: currentEndDate,
        };
      }
    } else if (timeRangeType === TIME_RANGE_DEFINITIONS.DEFAULT) {
      modelInputDateFilter = {
        recent_days: null,
        start_date: null,
        end_date: null,
      };
    }

    const shouldRefreshResults = objective === Objective.PRICEELASTICITY && !onlyWorkflowDescriptionChanged;
    await updateWorkflow(workflowId, workflowBody, shouldRefreshResults);
    modelInputDateFilter.workflow_id = workflowId;

    // Copy so that mutations of the Array don't mutate props/state outside of the React paradigm
    const updatedFilters = [...modelInputDateFilters];

    const existingFilterConfigIndex = modelInputDateFilters.indexOf(workflowDateFilters);
    const noDateFilterForThisWorkflowExists = existingFilterConfigIndex === -1;

    if (noDateFilterForThisWorkflowExists) {
      updatedFilters.push(modelInputDateFilter);
    } else {
      updatedFilters.splice(existingFilterConfigIndex, 1, modelInputDateFilter);
    }

    await updateCustomerConfigSection.mutateAsync({
      section: ConfigSection.MODEL_INPUT_DATE_FILTERS,
      config: updatedFilters,
    });

    history.push('./welcome');
  };

  return (
    <HeaderAwarePage style={{ backgroundColor: 'white' }}>
      <Header />

      <div className="edit-workflow-div pt-5">
        <PageHeader>Edit Analysis</PageHeader>

        <hr />

        <>
          <PromptText>Please give this analysis a new name / description.</PromptText>

          <TextInput
            className="workflow-description-text"
            onChange={e => setWorkflowDescription(e.target.value)}
            placeholder="My Analysis"
            value={workflowDescription}
          />
        </>

        <h4 className="workflow-section">Data Used for Analysis</h4>

        <hr />

        <p style={{ marginBottom: '10px' }}>Date Range:</p>
        <div className="date-range-options-container">
          <SimpleRadioButton
            selected={timeRangeType === TIME_RANGE_DEFINITIONS.DEFAULT}
            onClick={() => setTimeRangeType(TIME_RANGE_DEFINITIONS.DEFAULT)}
          >
            Default
          </SimpleRadioButton>
          <SimpleRadioButton
            selected={timeRangeType === TIME_RANGE_DEFINITIONS.MOST_RECENT_DAYS}
            onClick={() => setTimeRangeType(TIME_RANGE_DEFINITIONS.MOST_RECENT_DAYS)}
          >
            Most Recent
            <input
              className="page-input"
              type="number"
              max="9999"
              step="5"
              value={recentDays}
              onChange={e => setRecentDays(e.target.value)}
              style={{ width: 60, marginRight: '10px', marginLeft: '15px' }}
            />
            Days
          </SimpleRadioButton>
          <SimpleRadioButton
            selected={timeRangeType === TIME_RANGE_DEFINITIONS.ONLY_START_DATE}
            onClick={() => setTimeRangeType(TIME_RANGE_DEFINITIONS.ONLY_START_DATE)}
          >
            From
            <CustomDatePicker variant="outlined" value={onlyStartDate} onChange={date => setOnlyStartDate(date)} />
            Onwards
          </SimpleRadioButton>
          <SimpleRadioButton
            selected={timeRangeType === TIME_RANGE_DEFINITIONS.START_DATE_END_DATE}
            onClick={() => setTimeRangeType(TIME_RANGE_DEFINITIONS.START_DATE_END_DATE)}
          >
            From
            <CustomDatePicker variant="outlined" value={startDate} onChange={date => setStartDate(date)} />
            To
            <CustomDatePicker variant="outlined" value={endDate} onChange={date => setEndDate(date)} />
          </SimpleRadioButton>
        </div>
        <div className="save-buttons">
          <ActionButton disabled={noChangesMade} onClick={onClickEditAnalysis}>
            Save & Refresh Results
          </ActionButton>
        </div>
      </div>
    </HeaderAwarePage>
  );
};

const UpdateWorkflowPageWrapper = props => {
  const { data: modelInputDateFilters = [], isFetching } = useCustomerConfigSection(
    ConfigSection.MODEL_INPUT_DATE_FILTERS,
  );

  if (isFetching) {
    return <GenericLoadingPage />;
  }

  return <UpdateWorkflowPage modelInputDateFilters={modelInputDateFilters} props={props} />;
};

const PageAccessCheck = props => (
  <Can I={Action.EDIT} a={Subject.WORKFLOW} passThrough>
    {allowed => (allowed ? <UpdateWorkflowPageWrapper {...props} /> : <Redirect to="/welcome" />)}
  </Can>
);

export default PageAccessCheck;
