/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import * as d3 from 'd3';

const width = 400;
const height = 270;
const margin = { top: 20, right: 8, bottom: 35, left: 60 };

class LineChart extends Component {
  state = {
    line: [],
  };

  xAxis = d3
    .axisBottom()
    .ticks(5)
    .tickPadding(9)
    .tickSizeInner(0);

  yAxis = d3
    .axisLeft()
    .ticks(5)
    .tickPadding(9)
    .tickSizeInner(0)
    .tickFormat(d => {
      if (d - Math.floor(d) > 10 ** -7 && Math.ceil(d) - d > 10 ** -7) {
        return '';
      }
      return d.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    });

  static getDerivedStateFromProps(nextProps) {
    let { data } = nextProps;

    data = data.length > 0 ? data[0].points : [];
    if (!data) {
      return {};
    }

    const yScale = d3.scaleLinear().range([height - margin.bottom, margin.top]);

    const xScale = d3.scaleLinear().range([margin.left, width - margin.right - 5]);

    const demandMax = d3.extent(data, d => d.y);

    const priceMax = d3.extent(data, d => d.x);
    yScale.domain(demandMax);
    xScale.domain(priceMax);

    let kaizenPrice = 0;
    let kaizenDemand = 0;
    let kaizenRevenue = 0;
    let kaizenPrice0 = '';
    if (data.length !== 0) {
      // eslint-disable-next-line prefer-destructuring
      kaizenPrice = data[0].kaizenPrice;
      // eslint-disable-next-line prefer-destructuring
      kaizenDemand = data[0].kaizenDemand;
      // eslint-disable-next-line prefer-destructuring
      kaizenRevenue = data[0].kaizenRevenue;
      // eslint-disable-next-line prefer-destructuring
      kaizenPrice0 = data[0].kaizenPrice0;
    }

    const xMin = priceMax[0];
    const yMin = demandMax[0];
    const lineGenerator = d3
      .line()
      .x(d => xScale(d.x))
      .y(d => yScale(d.y));

    const line = [
      {
        fill: 'none',
        path: lineGenerator(data),
        stroke: 'black',
      },
    ];

    return {
      line,
      xScale,
      yScale,
      data,
      xMin,
      yMin,
      kaizenPrice,
      kaizenPrice0,
      kaizenDemand,
      kaizenRevenue,
    };
  }

  componentDidMount() {
    this.renderGraph();
  }

  componentDidUpdate() {
    this.renderGraph();
  }

  renderGraph() {
    const { xScale, yScale } = this.state;

    this.xAxis.scale(xScale);
    d3.select(this.refs.xAxis)
      .call(this.xAxis)
      .style('font-size', '12');

    this.yAxis.scale(yScale);
    d3.select(this.refs.yAxis)
      .call(this.yAxis)
      .style('font-size', '12');

    d3.selectAll('.AxisLabel').remove();

    d3.select(`#my-svg`)
      .append('text')
      .attr('x', 165)
      .attr('y', 268)
      .attr('class', 'AxisLabel')
      .style('font-size', 16)
      .style('font-family', 'sans-serif')
      .text('Price($)');

    d3.select(`#my-svg`)
      .append('text')
      .attr('x', 0)
      .attr('y', 62)
      .attr('class', 'AxisLabel')
      .attr('transform', `translate(-50, ${height / 2 - 5}) rotate(-90)`)
      .style('font-size', 16)
      .style('font-family', 'sans-serif')
      .style('text-anchor', 'middle')
      .text('Remaining Demand');
    d3.selectAll('.rect2').remove();
    d3.selectAll('.price').remove();
    d3.selectAll('.demand').remove();
    d3.selectAll('.revenue').remove();
    d3.selectAll('.bar').remove();
    // eslint-disable-next-line
    {
      this.renderPoints();
    }
    // eslint-disable-next-line
    {
      this.renderText();
    }
  }

  renderText() {
    const { kaizenPrice0, kaizenDemand, kaizenRevenue } = this.state;
    d3.select(`#my-svg`)
      .append('text')
      .attr('x', 120)
      .attr('y', 180)
      .attr('class', 'price')
      .style('font-size', 16)
      .style('font-family', 'sans-serif')
      .text(`Price: $${kaizenPrice0.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`);

    d3.select(`#my-svg`)
      .append('text')
      .attr('x', 120)
      .attr('y', 200)
      .attr('class', 'demand')
      .style('font-size', 16)
      .style('font-family', 'sans-serif')

      .text(`Demand: ${kaizenDemand}`);

    d3.select(`#my-svg`)
      .append('text')
      .attr('x', 120)
      .attr('y', 220)
      .attr('class', 'revenue')
      .style('font-family', 'sans-serif')
      .style('font-size', 16)

      .text(`Revenue: $${kaizenRevenue}`);
  }

  renderPoints() {
    const { data, xScale, yScale } = this.state;

    if (data.length > 0) {
      d3.select(`#my-svg`)
        .append('g')
        .selectAll('circle')
        .data(data)
        .enter()
        .append('circle')
        .attr('class', 'bar')
        .attr('cx', d => {
          return xScale(d.x);
        })
        .attr('cy', d => {
          return yScale(d.y);
        })
        .attr('r', () => {
          return 7;
        })
        .style('stroke', d => {
          if (d.x === data[0].kaizenPrice) {
            return '#00af4f';
          }
          if (d.x === data[0].currentPrice) {
            return '#ff8c00';
          }
          return 'black';
        })
        .style('fill', d => {
          if (d.x === data[0].kaizenPrice) {
            return 'lightgreen';
          }
          return 'white';
        })
        .style('strokeWidth', () => {
          return '20px';
        })

        .style('cursor', 'pointer')
        .on('click', function onPointClick(d) {
          d3.selectAll('.bar').style('fill', 'white');
          d3.selectAll('.bar').style('strokeWidth', '2px');
          d3.select(this).attr('r', 7);
          d3.select(this).style('fill', () => {
            if (d.x === data[0].kaizenPrice) {
              return 'lightgreen';
            }
            if (d.x === data[0].currentPrice) {
              return 'orange';
            }
            return 'lightblue';
          });

          d3.select(this).style('strokeWidth', '20px');
          d3.selectAll('.price').text(`Price: $${d.x0.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`);
          d3.selectAll('.demand').text(`Demand: ${d.y0}`);
          d3.selectAll('.revenue').text(`Revenue: $${d.z}`);
        });
    }
  }

  render() {
    const { line } = this.state;
    return (
      <svg id="my-svg" width={width} height={height}>
        {line.map((d, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <path key={i} d={d.path} stroke={d.stroke} fill={d.fill} />
        ))}
        <g ref="xAxis" transform={`translate(0, ${height - margin.bottom})`} />
        <g ref="yAxis" transform={`translate(${margin.left}, 0)`} />

        {this.renderPoints()}
      </svg>
    );
  }
}

export default LineChart;
