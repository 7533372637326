import React from 'react';
import { Modal } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import {
  DatasetFieldDescriptions,
  DatasetUploadSuccess,
  FileDropSelectArea,
  mapIndustryDataTypeToFields,
} from './ManualFileUpload';
import { PageHeader, VerticalSeparator } from '../../forms/GenericFormComponents';
import { ProfitRoverPrimaryButton, ProfitRoverCancelButton } from '../../forms/ProfitRoverButtons';
import { ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';
import ProfitRoverSpinner from '../../spinner/ProfitRoverSpinner';
import { useDatasetConfig } from '../../../data-access/query/datasetConfigs';
import { DATASETS_QUERY_KEY } from '../../../data-access/query/datasets';
import { useRequestDataSync } from '../../../data-access/mutation/datasetConfigs';
import { useDeleteDataset } from '../../../data-access/mutation/datasets';
import fileUploadIcon from '../../../images/file-upload-icon.svg';

const useUploadAttempt = () => {
  const [datasetDetails, setDatasetDetails] = React.useState(null);
  const deleteDataset = useDeleteDataset();

  const reset = () => {
    setDatasetDetails(null);
  };

  const cleanDatasetRecord = async datasetId => {
    await deleteDataset.mutateAsync(datasetId);
    await reset();
  };

  return { isSuccessful: datasetDetails != null, datasetDetails, setDatasetDetails, cleanDatasetRecord };
};

const FileUploadModal = ({ datasetConfigId, onHide }) => {
  const [isFinishing, setIsFinishing] = React.useState(false);
  const queryClient = useQueryClient();

  const { data: datasetConfig = {} } = useDatasetConfig(datasetConfigId, { enabled: datasetConfigId != null });
  const { name: dataSourceName } = datasetConfig;

  const fields = mapIndustryDataTypeToFields(datasetConfig);

  const { isSuccessful, setDatasetDetails, datasetDetails, cleanDatasetRecord } = useUploadAttempt();
  const { fileName, datasetId } = datasetDetails ?? {};

  const requestDataSync = useRequestDataSync({ datasetConfigId });

  return (
    <Modal centered show size="lg">
      <ProfitRoverModalMain className="file-upload-base">
        <PageHeader>
          Upload More Data <img className="dataset-icon" src={fileUploadIcon} alt="Add a dataset" />
          <VerticalSeparator style={{ marginLeft: '2px' }} />
          {dataSourceName}
        </PageHeader>

        {isSuccessful ? (
          <>
            <p className="pt-2">
              <strong>
                You have successfully added your dataset! You will receive a message in the Notification Center once
                your insights are ready. Manage your datasets via your data source settings.
              </strong>
            </p>

            <DatasetUploadSuccess fileName={fileName} onReset={() => cleanDatasetRecord(datasetId)} />
          </>
        ) : (
          <>
            <p>Remember your dataset should have the following types of information:</p>
            <DatasetFieldDescriptions fields={fields} />

            <FileDropSelectArea
              onFinish={newDatasetDetails => {
                setDatasetDetails(newDatasetDetails);
              }}
              datasetConfig={datasetConfig}
            />
          </>
        )}
      </ProfitRoverModalMain>

      {isSuccessful ? (
        <ProfitRoverModalFooter className="d-flex justify-content-center">
          <ProfitRoverPrimaryButton
            disabled={isFinishing}
            onClick={async () => {
              setIsFinishing(true);

              // Send a PROCESS message for only this new dataset
              await requestDataSync.mutateAsync({ datasetConfigId, datasetIds: [datasetId] });

              await Promise.allSettled([queryClient.refetchQueries(DATASETS_QUERY_KEY), onHide()]);
            }}
          >
            {isFinishing ? <ProfitRoverSpinner /> : 'Finish'}
          </ProfitRoverPrimaryButton>
        </ProfitRoverModalFooter>
      ) : (
        <ProfitRoverModalFooter className="d-flex justify-content-end">
          <ProfitRoverCancelButton onClick={onHide}>Cancel</ProfitRoverCancelButton>
        </ProfitRoverModalFooter>
      )}
    </Modal>
  );
};

export default FileUploadModal;
