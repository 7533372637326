import React from 'react';
import EmptyPage from './EmptyPage';
import ProfitRoverSpinner from '../../spinner/ProfitRoverSpinner';

const LoadingPage = () => (
  <EmptyPage>
    <div className="d-flex align-items-center justify-content-center" style={{ height: '75vh' }}>
      <ProfitRoverSpinner />
    </div>
  </EmptyPage>
);

export default LoadingPage;
