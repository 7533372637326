import React from 'react';
import { useResizeDetector } from 'react-resize-detector';

const ResizeDetector = ({ children }) => {
  const { ref, height, width } = useResizeDetector();

  return (
    <div ref={ref} className="resize-container">
      {children({ height, width })}
    </div>
  );
};

export default ResizeDetector;
