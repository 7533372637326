export default class DimensionValueSelection {
  constructor(dimensionValueId, dimensionValue, dimensionId, isSelected) {
    this.dimensionId = dimensionId;
    this.dimensionValueId = dimensionValueId;
    this.dimensionValue = dimensionValue;

    this.isSelected = isSelected; // Whether the checkbox for this dimension value is "selected"
  }

  /**
   * Supports creating a new instance of this class from an existing one. This is
   * intended to support the use case in React where you're not supposed to mutate
   * state values directly. Instead, if you want to modify a value, you can copy
   * the original value with this method and mutate the copy before setting the
   * new React state.
   */
  static from(dimensionValueSelection) {
    const copy = { ...dimensionValueSelection };
    Object.setPrototypeOf(copy, DimensionValueSelection.prototype);

    return copy;
  }

  setIsSelected(isSelected) {
    this.isSelected = isSelected;
  }
}
