import _ from 'lodash';
import axios from 'axios';
import camelcase from 'camelcase';
import { readString } from 'react-papaparse';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

const transformColumns = originalColumnName => {
  let finalColumnName;

  switch (originalColumnName) {
    case 'customer_entity_id':
      finalColumnName = 'locationId';
      break;
    case 'weekday':
    case 'Weekday':
      finalColumnName = 'dayOfWeek';
      break;
    case 'date':
    case 'Date':
      finalColumnName = 'day';
      break;
    default:
      finalColumnName = camelcase(originalColumnName);
  }

  return finalColumnName;
};

const productDimIdColumnRegex = /^productDim[0-9]+Id$/;

const parseData = (value, header) => {
  let finalValue = value;

  switch (header) {
    case 'locationId':
    case 'productId':
      finalValue = parseInt(value, 10);
      break;
    case 'revenue':
    case 'profit':
    case 'demand':
    case 'price':
    case 'gratuity':
      finalValue = parseFloat(value);
      break;
    case 'yesterdayRevenue':
    case 'yesterdayProfit':
    case 'yesterdayDemand':
    case 'yesterdayGratuity':
    case 'yesterdayPriorRevenue':
    case 'yesterdayPriorProfit':
    case 'yesterdayPriorDemand':
    case 'yesterdayPriorGratuity':
    case 'yesterdayStlyRevenue':
    case 'yesterdayStlyProfit':
    case 'yesterdayStlyDemand':
    case 'yesterdayStlyGratuity':
    case 'weekLastRevenue':
    case 'weekLastProfit':
    case 'weekLastDemand':
    case 'weekLastGratuity':
    case 'weekPriorRevenue':
    case 'weekPriorProfit':
    case 'weekPriorDemand':
    case 'weekPriorGratuity':
    case 'weekLastStlyRevenue':
    case 'weekLastStlyProfit':
    case 'weekLastStlyDemand':
    case 'weekLastStlyGratuity':
    case 'monthCurrentRevenue':
    case 'monthCurrentProfit':
    case 'monthCurrentDemand':
    case 'monthCurrentGratuity':
    case 'monthLastRevenue':
    case 'monthLastProfit':
    case 'monthLastDemand':
    case 'monthLastGratuity':
    case 'monthPriorRevenue':
    case 'monthPriorProfit':
    case 'monthPriorDemand':
    case 'monthPriorGratuity':
    case 'monthStlyRevenue':
    case 'monthStlyProfit':
    case 'monthStlyDemand':
    case 'monthStlyGratuity':
    case 'monthLastStlyRevenue':
    case 'monthLastStlyProfit':
    case 'monthLastStlyDemand':
    case 'monthLastStlyGratuity':
    case 'quarterCurrentRevenue':
    case 'quarterCurrentProfit':
    case 'quarterCurrentDemand':
    case 'quarterCurrentGratuity':
    case 'quarterLastRevenue':
    case 'quarterLastProfit':
    case 'quarterLastDemand':
    case 'quarterLastGratuity':
    case 'quarterPriorRevenue':
    case 'quarterPriorProfit':
    case 'quarterPriorDemand':
    case 'quarterPriorGratuity':
    case 'quarterStlyRevenue':
    case 'quarterStlyProfit':
    case 'quarterStlyDemand':
    case 'quarterStlyGratuity':
    case 'quarterLastStlyRevenue':
    case 'quarterLastStlyProfit':
    case 'quarterLastStlyDemand':
    case 'quarterLastStlyGratuity':
    case 'yearCurrentRevenue':
    case 'yearCurrentProfit':
    case 'yearCurrentDemand':
    case 'yearCurrentGratuity':
    case 'yearStlyRevenue':
    case 'yearStlyProfit':
    case 'yearStlyDemand':
    case 'yearStlyGratuity':
    case 'revenue33':
    case 'revenue67':
    case 'profit33':
    case 'profit67':
    case 'demand33':
    case 'demand67':
    case 'gratuity33':
    case 'gratuity67':
      finalValue = parseFloat(value);
      break;
    case 'weeksSinceRelease':
      finalValue = parseInt(value, 10);
      break;
    default:
      if (productDimIdColumnRegex.test(header)) {
        finalValue = parseInt(value, 10);
      } else {
        finalValue = value;
      }
      break;
  }

  return finalValue;
};

const parsePricingHistory = (value, header) => {
  let finalValue;

  switch (header) {
    case 'stlyNext7Demand':
    case 'stlyNext30Demand':
    case 'stlyNext90Demand':
    case 'stlyNext365Demand':
    case 'stlyMonthToDateDemand':
    case 'stlyQuarterToDateDemand':
    case 'stlyYearToDateDemand':
    case 'stlyCurrentMonthDemand':
    case 'stlyCurrentQuarterDemand':
    case 'stlyCurrentYearDemand':
    case 'stlyMonthNextDemand':
    case 'stlyQuarterNextDemand':
    case 'stlyYearNextDemand':
      finalValue = parseFloat(value);
      break;
    case 'productId':
    case 'locationId':
    case 'stlyNext7Revenue':
    case 'stlyNext30Revenue':
    case 'stlyNext90Revenue':
    case 'stlyNext365Revenue':
    case 'stlyMonthToDateRevenue':
    case 'stlyQuarterToDateRevenue':
    case 'stlyYearToDateRevenue':
    case 'stlyCurrentMonthRevenue':
    case 'stlyCurrentQuarterRevenue':
    case 'stlyCurrentYearRevenue':
    case 'stlyMonthNextRevenue':
    case 'stlyQuarterNextRevenue':
    case 'stlyYearNextRevenue':
    case 'stlyNext7Profit':
    case 'stlyNext30Profit':
    case 'stlyNext90Profit':
    case 'stlyNext365Profit':
    case 'stlyMonthToDateProfit':
    case 'stlyQuarterToDateProfit':
    case 'stlyYearToDateProfit':
    case 'stlyCurrentMonthProfit':
    case 'stlyCurrentQuarterProfit':
    case 'stlyCurrentYearProfit':
    case 'stlyMonthNextProfit':
    case 'stlyQuarterNextProfit':
    case 'stlyYearNextProfit':
      finalValue = parseInt(value, 10);
      break;
    case 'weeksSinceRelease':
      finalValue = parseInt(value, 10);
      break;
    default:
      if (productDimIdColumnRegex.test(header)) {
        finalValue = parseInt(value, 10);
      } else {
        finalValue = value;
      }
      break;
  }

  return finalValue;
};

const parseAverageProductRevenueHistory = (value, header) => {
  let finalValue;

  switch (header) {
    case 'productId':
    case 'locationId':
    case 'next7StlyAvgRevenue':
    case 'next30StlyAvgRevenue':
    case 'next90StlyAvgRevenue':
    case 'next365StlyAvgRevenue':
    case 'currentMonthStlyAvgRevenue':
    case 'currentQuarterStlyAvgRevenue':
    case 'currentYearStlyAvgRevenue':
    case 'nextMonthStlyAvgRevenue':
    case 'nextQuarterStlyAvgRevenue':
    case 'last4WeeksAvgRevenue':
    case 'last4WeeksStlyAvgRevenue':
    case 'weeksSinceRelease':
      finalValue = parseInt(value, 10);
      break;
    default:
      if (productDimIdColumnRegex.test(header)) {
        finalValue = parseInt(value, 10);
      } else {
        finalValue = value;
      }
      break;
  }

  return finalValue;
};

const transformResponse = (data, transform) => {
  // Convert raw bytes to a string
  const csvData = new TextDecoder('utf-8').decode(data);

  // Parse the data
  const { data: rows, errors } = readString(csvData, {
    header: true,
    transformHeader: transformColumns,
    transform,
  });

  // Remove any rows that contained too many or two few columns
  errors.forEach(error => rows.splice(error.row, 1));

  return rows;
};

export const GET_PROCESSED_HISTORY_BASE = `${KA_API_URL}/processed_history/aggregated/raw`;
export const GET_PROCESSED_HISTORY_META_BASE = `${KA_API_URL}/processed_history/aggregated/meta`;

const fetchProcessedHistory = (workflowId, output) =>
  axios.get(GET_PROCESSED_HISTORY_BASE, {
    params: { workflow_id: workflowId, output },
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/octet-stream, application/json',
    },
  });

const fetchAggregatedMetricHistory = workflowId => fetchProcessedHistory(workflowId, 'aggregated');
const fetchProcessedHistorySummary = workflowId => fetchProcessedHistory(workflowId, 'summary');
const fetchPricingHistorySummary = workflowId => fetchProcessedHistory(workflowId, 'price_change_summary');
const fetchAverageProductRevenueHistory = workflowId => fetchProcessedHistory(workflowId, 'product_rev_avgs');

const fetchMetaHistory = async (workflowId, output) =>
  axios.get(GET_PROCESSED_HISTORY_META_BASE, {
    params: { workflow_id: workflowId, output },
  });

const fetchProcessedHistorySummaryMeta = async workflowId => fetchMetaHistory(workflowId, 'summary');

export const PROCESSED_HISTORY_META_QUERY_KEY_BASE = 'ProcessedHistorySummaryMeta';
export const AGGREGATED_METRIC_HISTORY_QUERY_KEY_BASE = 'AggregatedMetricHistory';
export const AGGREGATED_METRIC_HISTORY_SUMMARY_QUERY_KEY_BASE = 'AggregatedMetricHistorySummary';
export const PRICING_HISTORY_SUMMARY_QUERY_KEY_BASE = 'PricingHistorySummary';
export const AVERAGE_PRODUCT_REVENUE_HISTORY_QUERY_KEY_BASE = 'AverageProductRevenueHistory';

const queryAllowable = (...args) => !_.some(args, arg => _.isNil(arg));

export const useProcessedHistorySummaryMeta = (workflowId, queryConfig) =>
  useQuery(
    [PROCESSED_HISTORY_META_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchProcessedHistorySummaryMeta(workflowId);
      return response.data;
    },
    queryConfig,
  );

export const useAggregatedMetricHistory = (workflowId, queryConfig) =>
  useQuery(
    [AGGREGATED_METRIC_HISTORY_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchAggregatedMetricHistory(workflowId);

      try {
        const rows = transformResponse(response.data, parseData);
        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    {
      ...queryConfig,
      enabled: queryAllowable(workflowId) && (queryConfig?.enabled ?? true),
    },
  );

export const useAggregatedMetricHistorySummary = (workflowId, queryConfig) =>
  useQuery(
    [AGGREGATED_METRIC_HISTORY_SUMMARY_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchProcessedHistorySummary(workflowId);

      try {
        const { data: rawData } = response;
        const rows = transformResponse(rawData, parseData);

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    queryConfig,
  );

export const usePricingHistorySummary = (workflowId, queryConfig) =>
  useQuery(
    [PRICING_HISTORY_SUMMARY_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchPricingHistorySummary(workflowId);

      try {
        const { data: rawData } = response;
        const rows = transformResponse(rawData, parsePricingHistory);

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    { retry: 2, ...queryConfig },
  );

export const useAverageProductRevenueHistory = (workflowId, queryConfig) =>
  useQuery(
    [AVERAGE_PRODUCT_REVENUE_HISTORY_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchAverageProductRevenueHistory(workflowId);

      try {
        const { data: rawData } = response;
        const rows = transformResponse(rawData, parseAverageProductRevenueHistory);

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    { retry: 2, ...queryConfig },
  );
