import React from 'react';
import { Modal } from 'react-bootstrap';
import { DataSourceDisplayName } from './dataSourceConstants';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverModalFooter, ProfitRoverModalMain } from '../generic/ProfitRoverCard';

const ReconnectConfirmationModal = ({ partner, show, onClose, onContinue }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>Reconnect to {DataSourceDisplayName[partner]}</Modal.Title>
      </Modal.Header>
      <ProfitRoverModalMain>
        You have previously disconnected from {DataSourceDisplayName[partner]}. Are you sure you would like to
        reestablish this connection?
      </ProfitRoverModalMain>
      <ProfitRoverModalFooter className="disconnect-confirmation-modal-footer">
        <ProfitRoverPrimaryButton onClick={onContinue}>Reconnect</ProfitRoverPrimaryButton>
        <ProfitRoverSecondaryButton onClick={onClose}>Cancel</ProfitRoverSecondaryButton>
      </ProfitRoverModalFooter>
    </Modal>
  );
};

export default ReconnectConfirmationModal;
