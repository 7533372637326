/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { KA_API_ROOT_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const updateNotifications = async notificationIds =>
  axios.put(`${KA_API_ROOT_URL}/notifications/toggle-read`, notificationIds);
