import React from 'react';
import { createContextualCan } from '@casl/react';

export const AbilityContext = React.createContext();

/**
 * This "Can" React Component is automatically wired up to reference the user-specific Ability
 * instance. Thus, this component should be used instead of the <Can> exported from the
 * @casl/react package.
 */
export const Can = createContextualCan(AbilityContext.Consumer);

export const useAbilityContext = () => React.useContext(AbilityContext);
