import React from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, Redirect } from 'react-router-dom';
import AdminRoleCheck from './AdminRoleCheck';
import CheckoutSubscriptionChanges from './CheckoutSubscriptionChanges';
import CheckoutEndTrialEarly from './CheckoutEndTrialEarly';
import CheckoutFirstFreeSubscriptionChanges from './CheckoutFirstFreeSubscriptionChanges';
import { NotFound } from './common/CommonSubscriptionComponents';
import SubscriptionManagementSummary from './SubscriptionManagementSummary';
import LoadingPage from '../common/LoadingPage';
import AddressDetailsModal from '../../dashboards/eo/revenueUplift/AddressDetailsModal';
import PickLocationToActivateModal from '../../dashboards/eo/revenueUplift/PickLocationToActivateModal';
import { useSelectTrialOrFirstFreeLocation } from '../../dashboards/eo/revenueUplift/revenueUpliftDashboardHooks';
import {
  KaizenProduct,
  KaizenProductStatus,
  SubscriptionType,
} from '../../generic/subscriptions/SubscriptionConstants';
import { useSubscriptionStatusForProduct } from '../../routing/subscriptionStatusHooks';
import { useLocations } from '../../../data-access/query/locations';
import { BILLING_INFO_QUERY_KEY_BASE } from '../../../data-access/query/subscriptions';
import './subscription-management.css';

const CheckoutFlowManager = ({
  subscriptionId,
  isTrialing,
  isCanceled,
  isDelinquent,
  trialHasExpired,
  subscriptionType,
}) => {
  const isFreeTier = subscriptionType === SubscriptionType.FIRST_FREE;
  const location = useLocation();
  const queryClient = useQueryClient();
  const { requestEndTrialEarly } = location?.state ?? {};

  const [locationsToActivate, setLocationsToActivate] = React.useState();
  const [locationsToDeactivate, setLocationsToDeactivate] = React.useState();
  const [isEndingTrialEarly, setIsEndingTrialEarly] = React.useState(requestEndTrialEarly);

  const isCheckingOutChanges =
    locationsToActivate?.length > 0 || locationsToDeactivate?.length > 0 || isEndingTrialEarly;

  if (!isCheckingOutChanges) {
    return (
      <SubscriptionManagementSummary
        subscriptionId={subscriptionId}
        setLocationsToActivate={setLocationsToActivate}
        setLocationsToDeactivate={setLocationsToDeactivate}
        isTrialing={isTrialing}
        isCanceled={isCanceled}
        isDelinquent={isDelinquent}
        isFreeTier={isFreeTier}
        trialHasExpired={trialHasExpired}
      />
    );
  }

  const onCancelChanges = async () => {
    await queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId]);
    setLocationsToActivate();
    setLocationsToDeactivate();
    setIsEndingTrialEarly(false);
  };

  if (isEndingTrialEarly) {
    return <CheckoutEndTrialEarly subscriptionId={subscriptionId} onCancel={onCancelChanges} />;
  }
  if (isFreeTier) {
    return (
      <CheckoutFirstFreeSubscriptionChanges
        subscriptionId={subscriptionId}
        onCancel={onCancelChanges}
        locationsToActivate={locationsToActivate}
        locationsToDeactivate={locationsToDeactivate}
      />
    );
  }

  return (
    <CheckoutSubscriptionChanges
      subscriptionId={subscriptionId}
      onCancel={onCancelChanges}
      locationsToActivate={locationsToActivate}
      locationsToDeactivate={locationsToDeactivate}
    />
  );
};

/**
 * For now, this page only supports showing KaizenPrice subscriptions backed by Stripe. If a user somehow manually
 * navigates to this page, and no Stripe-backed KaizenPrice subscription exists for them, we simply render a
 * fallback UI.
 */
const PageVisibilityCheck = () => {
  const {
    isDelinquent,
    isFetching: isFetchingSubscriptionStatus,
    subscriptionsByKaizenProduct,
    isStripeSubscription,
    isShopifySubscription,
    hasSubscription,
    subscriptionType,
    trialHasExpired,
  } = useSubscriptionStatusForProduct(KaizenProduct.KP);

  const location = useLocation();

  const { data: locations = [], isFetching: isFetchingLocations } = useLocations();

  const { subscription_id: kaizenPriceSubscriptionId, kaizen_product_status: kaizenProductStatus } =
    subscriptionsByKaizenProduct[KaizenProduct.KP] ?? {};

  const {
    selectedLocation,
    setSelectedLocation,
    onSelectLocation,
    onHideAddressDetailsModal,
    onUpdateLocation,
    showPickLocationToActivateModal,
    showAddressDetailsModal,
    isSubmittingTrialLocationSelection,
  } = useSelectTrialOrFirstFreeLocation(isFetchingLocations, locations);

  if (isFetchingSubscriptionStatus) {
    return <LoadingPage />;
  }

  const isShowingModal = showPickLocationToActivateModal || showAddressDetailsModal;

  if (hasSubscription && isStripeSubscription) {
    if (showPickLocationToActivateModal) {
      return (
        <PickLocationToActivateModal
          locations={locations}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          onSelectLocation={onSelectLocation}
          subscriptionType={subscriptionType}
        />
      );
    }

    if (showAddressDetailsModal) {
      return (
        <AddressDetailsModal
          selectedLocation={selectedLocation}
          onHideAddressDetailsModal={onHideAddressDetailsModal}
          onUpdateLocation={onUpdateLocation}
          isSubmittingTrialLocationSelection={isSubmittingTrialLocationSelection}
        />
      );
    }

    const isTrialing = kaizenProductStatus === KaizenProductStatus.TRIALING;
    const isCanceled = kaizenProductStatus === KaizenProductStatus.CANCELED;

    // "key" prop allows us to redirect to this page from itself to "reset" the page's state
    return (
      !isShowingModal && (
        <CheckoutFlowManager
          key={location.key}
          subscriptionId={kaizenPriceSubscriptionId}
          isTrialing={isTrialing}
          isCanceled={isCanceled}
          isDelinquent={isDelinquent}
          subscriptionType={subscriptionType}
          trialHasExpired={trialHasExpired}
        />
      )
    );
  }

  if (hasSubscription && isShopifySubscription) {
    return <Redirect to="/subscription-management/shopify" />;
  }

  return <NotFound />;
};

const RoleCheck = props => (
  <AdminRoleCheck>
    <PageVisibilityCheck {...props} />
  </AdminRoleCheck>
);

export default RoleCheck;
