import classnames from 'classnames';
import React from 'react';
import GenericErrorBoundaryPage from './GenericErrorBoundaryPage';

const BasicErrorWithImageFallback = ({
  imageSrc,
  top,
  bottom,
  animate = false,
  refreshInterval,
  resetErrorBoundary,
  showNavigationMenu = true,
}) => {
  const [secondsLeft, setSecondsLeft] = React.useState(refreshInterval);

  React.useEffect(() => {
    if (refreshInterval != null) {
      const timer = setInterval(() => {
        setSecondsLeft(prev => (prev > 1 ? prev - 1 : prev));
      }, 1000);

      const refreshTimer = setTimeout(() => {
        resetErrorBoundary();
      }, refreshInterval * 1000);

      return () => {
        clearInterval(timer);
        clearTimeout(refreshTimer);
      };
    }

    return () => {};
  }, [refreshInterval, resetErrorBoundary]);

  return (
    <GenericErrorBoundaryPage showNavigationMenu={showNavigationMenu}>
      <div className="error-with-image-container">
        {top}
        <img
          className={classnames('img', {
            animation: animate,
          })}
          src={imageSrc}
          alt=""
        />
        {bottom}
        {refreshInterval != null && (
          <p className="error-description-secondary">
            We&lsquo;ll check again in {secondsLeft} second{secondsLeft === 1 ? '' : 's'}...
          </p>
        )}
      </div>
    </GenericErrorBoundaryPage>
  );
};

export default BasicErrorWithImageFallback;
