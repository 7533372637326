import React from 'react';
import _ from 'lodash';
import { readString } from 'react-papaparse';
import axios from '../../../utils/axios-client';
import { KA_API_URL, KDL_API_URL } from '../../../config/baseUrl';
import { useDimensions } from '../../accountSettings/dimensionSettingsHooks';
import transformHeader from '../../util/processedHistoryUtil';

export const TicketsSoldOption = {
  CUMULATIVE: 'cumulative',
  NON_CUMULATIVE: 'non-cumulative',
};

export const ChartDataLoadingState = {
  FETCHING: 'fetching',
  PARSING: 'parsing',
  DONE: 'done',
};

// Column names that should always have the same label
const OPPONENT_NAME_COLUMN = 'opponent_name';
const GAME_DATE_COLUMN = 'visit_datetime';
const AVERAGE_PRICE_COLUMN = 'average_price';
const QUANTITY_SOLD_COLUMN = 'quantity_sold';
const DAYS_PRIOR_COLUMN = 'days_prior';
const IS_GROUP_SALE_COLUMN = 'is_group_sale';
const PRICE_COLUMN = 'price';

// Column names that can vary from customer to customer
let CHANNEL_COLUMN;
let PRICE_ZONE_COLUMN;

const ALL_PRICE_ZONES_VALUE = 'All';

const fetchRawProcessedHistoryData = workflowId =>
  axios.get(`${KA_API_URL}/processed_history/raw`, {
    params: { workflow_id: workflowId },
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/octet-stream, application/json',
    },
  });

const fetchTeamDetails = teamName => axios.get(`${KDL_API_URL}/cfb/teams/${teamName}`);

export const useProcessedHistoryDataFromRaw = (workflowId) => {
  const { dimensions } = useDimensions();
  const [rawData, setRawData] = React.useState();
  const [data, setData] = React.useState([]);
  const [loadingState, setLoadingState] = React.useState(ChartDataLoadingState.FETCHING);
  const [errorMessage, setErrorMessage] = React.useState();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchRawProcessedHistoryData(workflowId);

        if (response.data) {
          setRawData(response.data);
          setLoadingState(ChartDataLoadingState.PARSING);
        }
      } catch (error) {
        setErrorMessage('Unable to fetch data at this time.');
      }
    };

    // Don't fetch the data until we know about the customer's dimensions
    if (dimensions.length > 0) {
      // Find customer dimensions that will correspond to product_dim_{id} columns we need to know about
      const channelDim = dimensions.find(dim => dim.dimension_type === 'CHANNEL');
      const priceZoneDim = dimensions.find(dim => dim.dimension_type === 'PRICE_LEVEL');

      // Update the column names for later processing
      if (channelDim) {
        CHANNEL_COLUMN = channelDim.name;
      }

      if (priceZoneDim) {
        PRICE_ZONE_COLUMN = priceZoneDim.name;
      }

      fetchData();
    }
  }, [workflowId, dimensions]);

  React.useEffect(() => {
    if (!rawData) {
      return;
    }

    try {
      // Convert raw bytes to a string
      const decoder = new TextDecoder('utf-8');
      const csvData = decoder.decode(rawData);

      // Parse the data
      readString(csvData, {
        worker: true,
        complete: result => {
          const parsedData = result.data;

          // Map over any column names that correspond to a customer's own dimensions
          parsedData[0] = transformHeader(parsedData[0], dimensions);

          setData(parsedData);
          setLoadingState(ChartDataLoadingState.DONE);
        },
      });
    } catch {
      setErrorMessage('An unexpected error occurred.');
    }
  }, [rawData, dimensions]);

  return { data, errorMessage, loadingState };
};

export const useTeamDetails = () => {
  const [teamLogoUrl, setTeamLogoUrl] = React.useState();
  const [logoLoaded, setLogoLoaded] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const teamName = localStorage.getItem('team');
      const response = await fetchTeamDetails(teamName);

      if (response.data != null) {
        const cleanedStr = response.data.logos.replaceAll("'", '"');
        const logosJson = JSON.parse(cleanedStr);
        let url = logosJson.find(item => !item.includes('dark'));
        url = url.replace('http', 'https');

        if (url) {
          setTeamLogoUrl(url);
          setLogoLoaded(true);
        }
      }
    };

    fetchData();
  }, []);

  return { teamLogoUrl, logoLoaded };
};

const getColumnIndex = (header, desiredColumnName) => header.findIndex(colName => colName === desiredColumnName);

export const computeUniqueOpponentNames = chartData => {
  if (!chartData?.length > 0) {
    return [];
  }

  const header = chartData[0];
  const rows = chartData.slice(1, chartData.length);
  const opponentNameColumnIndex = getColumnIndex(header, OPPONENT_NAME_COLUMN);

  if (opponentNameColumnIndex) {
    let opponentNames = _.uniqBy(rows, row => row[opponentNameColumnIndex]).map(row => row[opponentNameColumnIndex]);
    opponentNames = opponentNames.filter(name => name != null && name !== '');

    return opponentNames.sort(); // Alphabetical, ascending
  }

  // TODO: Throw an error here?
  return [];
};

export const computeUniqueGameDates = (opponentName, chartData) => {
  if (!opponentName || !chartData.length > 0) {
    return [];
  }

  const header = chartData[0];
  const rows = chartData.slice(1, chartData.length);
  const opponentNameColumnIndex = getColumnIndex(header, OPPONENT_NAME_COLUMN);
  const gameDateColumnIndex = getColumnIndex(header, GAME_DATE_COLUMN);

  if (opponentNameColumnIndex && gameDateColumnIndex) {
    const gameDateSet = new Set();

    rows.forEach(row => {
      const gameDate = row[gameDateColumnIndex];
      const dateUnencountered = !gameDateSet.has(gameDate);
      const opponentNameMatches = row[opponentNameColumnIndex] === opponentName;
      if (opponentNameMatches && dateUnencountered) {
        gameDateSet.add(gameDate);
      }
    });

    return [...gameDateSet].sort();
  }

  return [];
};

export const computeUniquePriceZones = (chartData, opponentName, gameDate) => {
  if (!chartData.length > 0) {
    return [];
  }

  const header = chartData[0];
  const rows = chartData.slice(1, chartData.length);
  const priceZoneColumnIndex = getColumnIndex(header, PRICE_ZONE_COLUMN);
  const opponentNameColIndex = getColumnIndex(header, OPPONENT_NAME_COLUMN);
  const gameDateColIndex = getColumnIndex(header, GAME_DATE_COLUMN);
  const daysPriorColIndex = getColumnIndex(header, DAYS_PRIOR_COLUMN);
  const isGroupSaleColIndex = getColumnIndex(header, IS_GROUP_SALE_COLUMN);

  if (priceZoneColumnIndex) {
    const relevantData = rows.filter(
      row =>
        row[opponentNameColIndex] === opponentName &&
        row[gameDateColIndex] === gameDate &&
        row[daysPriorColIndex] >= 0 &&
        row[isGroupSaleColIndex] === '0',
    );

    let priceZones = _.uniqBy(relevantData, row => row[priceZoneColumnIndex])
      .map(row => row[priceZoneColumnIndex])
      .filter(zone => zone != null && zone !== '');

    try {
      // TODO: This does something specific for a customer (GT), but may need to be more general.
      // This could be as simple as discovering there is already a formula for this.
      priceZones = _.orderBy(priceZones, zone => {
        const [, num] = zone.split('_');
        const parsedInt = parseInt(num, 10);

        if (Number.isNaN(parsedInt)) {
          throw new Error('Unexpected price level format');
        }

        return parsedInt;
      });
    } catch {
      priceZones.sort();
    }

    return priceZones;
  }

  return [];
};

export const getActiveChartData = (chartData, opponentName, gameDate, priceZone) => {
  if (!chartData.length > 0 || !opponentName || !gameDate) {
    return [];
  }

  const header = chartData[0];
  const rows = chartData.slice(1, chartData.length);
  const opponentNameColIndex = getColumnIndex(header, OPPONENT_NAME_COLUMN);
  const gameDateColIndex = getColumnIndex(header, GAME_DATE_COLUMN);
  const averagePriceColIndex = getColumnIndex(header, AVERAGE_PRICE_COLUMN);
  const quantitySoldColIndex = getColumnIndex(header, QUANTITY_SOLD_COLUMN);
  const daysPriorColIndex = getColumnIndex(header, DAYS_PRIOR_COLUMN);
  const isGroupSaleColIndex = getColumnIndex(header, IS_GROUP_SALE_COLUMN);

  // Filter to rows that pertain to the currently selected opponent and game date
  let relevantDataRows = rows.filter(
    row => row[opponentNameColIndex] === opponentName && row[gameDateColIndex] === gameDate,
  );

  // Remove rows where "days_prior" is negative or "is_group_sales" is true
  relevantDataRows = relevantDataRows.filter(row => {
    return row[daysPriorColIndex] >= 0 && row[isGroupSaleColIndex] === '0';
  });

  const channelColIndex = getColumnIndex(header, CHANNEL_COLUMN);
  const channels = _.uniqBy(relevantDataRows, row => row[channelColIndex]).map(row => row[channelColIndex]);

  let dataPoints = [];
  // Filter to a certain price zone, if applicable
  if (priceZone != null && priceZone !== ALL_PRICE_ZONES_VALUE) {
    const priceZoneColIndex = getColumnIndex(header, PRICE_ZONE_COLUMN);
    relevantDataRows = relevantDataRows.filter(row => row[priceZoneColIndex] === priceZone);

    channels.forEach(channel => {
      // Filter to rows belonging to this channel
      const channelDataRows = relevantDataRows.filter(row => row[channelColIndex] === channel);

      // Extract all existing values of "days_prior" for later filtering
      const uniqueDaysPriorValues = _.uniqBy(channelDataRows, row => row[daysPriorColIndex])
        .map(row => parseInt(row[daysPriorColIndex], 10))
        .sort((a, b) => b - a); // Descending

      const dataPointsForChannel = uniqueDaysPriorValues.map(daysPriorValue => {
        // Filter to rows that have the same number of days prior to the game date
        const relevantDayRows = channelDataRows.filter(row => parseInt(row[daysPriorColIndex], 10) === daysPriorValue);

        const numberOfRows = relevantDayRows.length;

        // Compute an average price by summing over all rows and dividing by the number of rows
        const priceColIndex = getColumnIndex(header, PRICE_COLUMN);
        const averagePrice =
          relevantDayRows.reduce((previousValue, currentValue) => {
            return previousValue + parseFloat(currentValue[priceColIndex]);
          }, 0) / numberOfRows;

        return {
          average_price: averagePrice,
          quantity_sold: numberOfRows,
          days_prior: daysPriorValue,
          channel: channel || '', // Use a default channel name if one doesn't exist for this customer
        };
      });

      dataPoints = [...dataPoints, ...dataPointsForChannel];
    });
  } else {
    channels.forEach(channel => {
      // Filter to rows belonging to this channel
      const channelDataRows = relevantDataRows.filter(row => row[channelColIndex] === channel);

      // Get one row for each value of "days prior", as it should contain all the necessary viz information
      const uniqueDataRows = _.uniqBy(channelDataRows, row => row[daysPriorColIndex]);

      // Map those rows into the shape the chart expects
      const dataPointsForChannel = uniqueDataRows.map(row => ({
        average_price: parseFloat(row[averagePriceColIndex]),
        quantity_sold: parseFloat(row[quantitySoldColIndex]),
        days_prior: parseInt(row[daysPriorColIndex], 10),
        channel: channel || '',
      }));

      // Append new data points to dataPoints
      dataPoints = [...dataPoints, ...dataPointsForChannel];
    });
  }

  return dataPoints;
};

const getCumulativeActiveChartData = (chartData, opponentName, gameDate, priceZone) => {
  if (!chartData.length > 0 || !opponentName || !gameDate) {
    return [];
  }

  const header = chartData[0];
  const rows = chartData.slice(1, chartData.length);
  const opponentNameColIndex = getColumnIndex(header, OPPONENT_NAME_COLUMN);
  const gameDateColIndex = getColumnIndex(header, GAME_DATE_COLUMN);
  const averagePriceColIndex = getColumnIndex(header, AVERAGE_PRICE_COLUMN);
  const daysPriorColIndex = getColumnIndex(header, DAYS_PRIOR_COLUMN);
  const isGroupSaleColIndex = getColumnIndex(header, IS_GROUP_SALE_COLUMN);

  // Filter to rows that pertain to the currently selected opponent and game date
  let relevantDataRows = rows.filter(
    row => row[opponentNameColIndex] === opponentName && row[gameDateColIndex] === gameDate,
  );

  // Remove rows where "days_prior" is negative or "is_group_sales" is true
  relevantDataRows = relevantDataRows.filter(row => {
    return row[daysPriorColIndex] >= 0 && row[isGroupSaleColIndex] === '0';
  });

  const channelColIndex = getColumnIndex(header, CHANNEL_COLUMN);
  const channels = _.uniqBy(relevantDataRows, row => row[channelColIndex]).map(row => row[channelColIndex]);

  let dataPoints = [];
  // Filter to a certain price zone, if applicable
  if (priceZone != null && priceZone !== ALL_PRICE_ZONES_VALUE) {
    const priceZoneColIndex = getColumnIndex(header, PRICE_ZONE_COLUMN);
    relevantDataRows = relevantDataRows.filter(row => row[priceZoneColIndex] === priceZone);

    channels.forEach(channel => {
      // Filter to rows belonging to this channel
      const channelDataRows = relevantDataRows.filter(row => row[channelColIndex] === channel);

      // Extract all existing values of "days_prior" for computing running sums
      const uniqueDaysPriorValues = _.uniqBy(channelDataRows, row => row[daysPriorColIndex])
        .map(row => parseFloat(row[daysPriorColIndex], 10))
        .sort((a, b) => b - a); // Descending

      let runningTotalSold = 0;
      const dataPointsForChannel = uniqueDaysPriorValues.map(daysPriorValue => {
        // Filter to rows that have the same number of days prior to the game date
        const relevantDayRows = channelDataRows.filter(row => parseInt(row[daysPriorColIndex], 10) === daysPriorValue);

        // Calculate the quantity sold by merely counting the number of rows
        runningTotalSold += relevantDayRows.length;

        // Compute an average price by summing over all rows and dividing by the number of rows
        const priceColIndex = getColumnIndex(header, PRICE_COLUMN);
        const averagePrice =
          relevantDayRows.reduce((previousValue, currentValue) => {
            return previousValue + parseFloat(currentValue[priceColIndex]);
          }, 0) / relevantDayRows.length;

        return {
          average_price: averagePrice,
          quantity_sold: runningTotalSold,
          days_prior: daysPriorValue,
          channel: channel || '',
        };
      });

      dataPoints = [...dataPoints, ...dataPointsForChannel];
    });
  } else {
    channels.forEach(channel => {
      // Filter to rows belonging to this channel
      const channelDataRows = relevantDataRows.filter(row => row[channelColIndex] === channel);

      // Extract all existing values of "days_prior" for computing running sums
      const uniqueDaysPriorValues = _.uniqBy(channelDataRows, row => row[daysPriorColIndex])
        .map(row => parseFloat(row[daysPriorColIndex], 10))
        .sort((a, b) => b - a); // Descending

      let runningTotalSold = 0;
      const dataPointsForChannel = uniqueDaysPriorValues.map(daysPriorValue => {
        // Filter to rows that have the same number of days prior to the game date
        const relevantDayRows = channelDataRows.filter(row => parseInt(row[daysPriorColIndex], 10) === daysPriorValue);

        // Calculate the quantity sold by merely counting the number of rows
        runningTotalSold += relevantDayRows.length;

        // Use the first row for this data as it contains the correct value for this number of "days prior"
        const averagePrice = parseFloat(relevantDayRows[0][averagePriceColIndex]);

        return {
          average_price: averagePrice,
          quantity_sold: runningTotalSold,
          days_prior: daysPriorValue,
          channel: channel || '',
        };
      });

      // Append new data points to dataPoints
      dataPoints = [...dataPoints, ...dataPointsForChannel];
    });
  }

  return dataPoints;
};

export const useKaizenExploreFilterState = chartData => {
  // Dynamic filter values that the user can choose from
  const [opponentNames, setOpponentNames] = React.useState([]);
  const [gameDates, setGameDates] = React.useState([]);
  const [priceZones, setPriceZones] = React.useState([]);

  // Values that are currently "selected"
  const [selectedOpponentName, setSelectedOpponentName] = React.useState();
  const [selectedGameDate, setSelectedGameDate] = React.useState();
  const [priceZone, setPriceZone] = React.useState();
  const [ticketsSold, setTicketsSold] = React.useState(TicketsSoldOption.NON_CUMULATIVE);

  React.useEffect(() => {
    if (chartData) {
      const uniqueOpponentNames = computeUniqueOpponentNames(chartData);

      if (uniqueOpponentNames.length > 0) {
        setOpponentNames(uniqueOpponentNames);
        setSelectedOpponentName(uniqueOpponentNames[0]);
      }
    }
  }, [chartData]);

  React.useEffect(() => {
    if (chartData) {
      const gameDatesForOpponent = computeUniqueGameDates(selectedOpponentName, chartData);

      if (gameDatesForOpponent.length > 0) {
        setGameDates(gameDatesForOpponent);
        setSelectedGameDate(gameDatesForOpponent[0]);
      }
    }
  }, [chartData, selectedOpponentName]);

  React.useEffect(() => {
    if (chartData) {
      const uniquePriceZones = computeUniquePriceZones(chartData, selectedOpponentName, selectedGameDate);

      if (uniquePriceZones.length > 0) {
        setPriceZones([ALL_PRICE_ZONES_VALUE, ...uniquePriceZones]);
        setPriceZone(ALL_PRICE_ZONES_VALUE);
      }
    }
  }, [chartData, selectedOpponentName, selectedGameDate]);

  return {
    opponentNames,
    gameDates,
    priceZones,
    selectedOpponentName,
    setSelectedOpponentName,
    selectedGameDate,
    setSelectedGameDate,
    priceZone,
    setPriceZone,
    ticketsSold,
    setTicketsSold,
  };
};

export const useKaizenExploreChartData = (chartData, opponentName, gameDate, priceZone, ticketsSold) => {
  const activeChartData = React.useMemo(() => {
    const data =
      ticketsSold === TicketsSoldOption.NON_CUMULATIVE
        ? getActiveChartData(chartData, opponentName, gameDate, priceZone)
        : getCumulativeActiveChartData(chartData, opponentName, gameDate, priceZone);

    return data;
  }, [chartData, opponentName, gameDate, priceZone, ticketsSold]);

  return activeChartData;
};
