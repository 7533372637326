/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import * as d3 from 'd3';
import ProfitRoverExploreLegend from './areaChartLegend';
import { formatNumber, formatCurrency } from '../util/format';

const moment = require('moment');

const width = 1200;
const height = 400;
const margin = { top: 20, right: 30, bottom: 40, left: 60 };

const areaChartLightColor = ['rgb(255,197,0,0.1)', 'rgb(55,68,87,0.1)'];

class LineChart extends Component {
  state = {
    line: [],
  };

  xAxis = d3
    .axisBottom()
    .ticks(15)
    .tickPadding(9)
    .tickSizeInner(0);

  yAxis = d3
    .axisLeft()
    .ticks(15)
    .tickPadding(9)
    .tickSizeInner(0)
    .tickFormat(d => {
      if (d / 1000 >= 1) {
        d = `${d / 1000}K`;
      }
      return d;
    });

  zAxis = d3
    .axisRight()
    .ticks(10)
    .tickPadding(9)
    .tickSizeInner(0)
    .tickFormat(d => {
      if (d - Math.floor(d) > 10 ** -7 && Math.ceil(d) - d > 10 ** -7) {
        return '';
      }
      return d.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    });

  static getDerivedStateFromProps(nextProps) {
    const { data, tickets, date } = nextProps;
    if (!data) {
      return {};
    }

    let gameDate = '';
    if (date !== '') {
      gameDate = new Date(
        parseInt(date.substring(0, 4), 10),
        parseInt(date.substring(5, 7) - 1, 10),
        parseInt(date.substring(8, 10), 10),
      );
    }

    const yScale = d3.scaleLinear().range([height - margin.bottom - 20, margin.top]);

    const zScale = d3.scaleLinear().range([height - margin.bottom - 20, margin.top]);
    const xScale = d3.scaleLinear().range([margin.left + 20, width - margin.right - 55]);

    xScale.domain([d3.max(data, d => d.days_prior), 0]);

    zScale.domain([0, d3.max(data, d => d.average_price)]);

    const colours = [
      'rgb(255,197,0)',
      'rgb(55,68,87)',
      'purple',
      'blue',
      'yellow',
      'indigo',
      'violet',
      'pink',
      'lime',
      'tan',
    ];

    let globalColourCount = 0;
    let globalColourCountForLegend = 0;

    const channelsArray = [];
    const channelsSet = new Set();

    for (let i = 0; i < data.length; i++) {
      if (!channelsSet.has(data[i].channel)) {
        channelsArray.push(data[i].channel);
        channelsSet.add(data[i].channel);
      }
    }
    channelsArray.sort();

    const channelsDataMap = new Map();

    for (let i = 0; i < channelsArray.length; i++) {
      const currentChannelData = data.filter(
        // eslint-disable-next-line
        d => d.channel === channelsArray[i],
      );

      const tempDaysTicketsMap = new Map();
      for (let j = 0; j < currentChannelData.length; j++) {
        tempDaysTicketsMap.set(currentChannelData[j].days_prior, currentChannelData[j].quantity_sold);
      }
      channelsDataMap.set(channelsArray[i], tempDaysTicketsMap);
    }

    const daysPriorSet = new Set();
    const finalDataArray = [];
    const currentCumulativeTicketsMap = new Map();
    for (let i = 0; i < channelsArray.length; i++) {
      currentCumulativeTicketsMap.set(channelsArray[i], 0);
    }

    data.sort((a, b) => (a.days_prior < b.days_prior ? 1 : -1));

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (!daysPriorSet.has(data[i].days_prior)) {
          const obj = {};
          obj.days_prior = data[i].days_prior;
          for (let k = 0; k < channelsArray.length; k++) {
            const currentChannelMap = channelsDataMap.get(channelsArray[k]);
            let tixSold = 0;

            if (tickets === 'non-cumulative') {
              tixSold = currentChannelMap.has(data[i].days_prior) ? currentChannelMap.get(data[i].days_prior) : 0;
            } else if (currentChannelMap.has(data[i].days_prior)) {
              tixSold = currentChannelMap.get(data[i].days_prior);
              currentCumulativeTicketsMap.set(channelsArray[k], tixSold);
            } else {
              tixSold = currentCumulativeTicketsMap.get(channelsArray[k]);
            }
            obj[channelsArray[k]] = tixSold;
          }
          finalDataArray.push(obj);
          daysPriorSet.add(data[i].days_prior);
        }
      }
    }

    const stack = d3.stack().keys(channelsArray);

    let stackedValues = [];
    if (finalDataArray.length > 1) {
      stackedValues = stack(finalDataArray);
      yScale.domain([0, d3.max(stackedValues[stackedValues.length - 1], dp => dp[1])]);
    }

    const stackedData = [];
    // Copy the stack offsets back into the data.
    stackedValues.forEach(layer => {
      const currentStack = [];
      layer.forEach((d, i) => {
        currentStack.push({
          values: d,
          days_prior: finalDataArray[i].days_prior,
        });
      });
      stackedData.push(currentStack);
    });

    const area = d3
      .area()
      .x(dataPoint => xScale(dataPoint.days_prior))
      .y0(dataPoint => yScale(dataPoint.values[0]))
      .y1(dataPoint => yScale(dataPoint.values[1]));

    const legendsDescriptionArray = [];
    for (let i = 0; i < channelsArray.length; i++) {
      legendsDescriptionArray.push({
        description: `${channelsArray[i]}  Tickets Sold`,
        color: globalColourCountForLegend <= 5 ? colours[globalColourCountForLegend] : 'black',
      });
      globalColourCountForLegend += 1;
    }
    globalColourCountForLegend = 0;

    for (let i = 0; i < channelsArray.length; i++) {
      legendsDescriptionArray.push({
        description: `${channelsArray[i]} Price`,
        color: globalColourCountForLegend <= 5 ? colours[globalColourCountForLegend] : 'black',
      });
      globalColourCountForLegend += 1;
    }

    const channelsDataArray = [];
    for (let i = 0; i < channelsArray.length; i++) {
      const currentChannelData = data.filter(
        // eslint-disable-next-line
        d => d.channel === channelsArray[i],
      );
      channelsDataArray.push(currentChannelData);
    }

    let maxDayPrior = 0;
    for (let i = 0; i < channelsDataArray.length; i++) {
      if (channelsDataArray[i][0].days_prior > maxDayPrior) {
        maxDayPrior = channelsDataArray[i][0].days_prior;
      }
    }

    const lineGeneratorFunctionsArray = [];
    const line = [];

    for (let i = 0; i < channelsDataArray.length; i++) {
      const priceLineGenerator = d3
        .line()
        .x(d => xScale(d.days_prior))
        .y(d => zScale(d.average_price));

      lineGeneratorFunctionsArray.push(priceLineGenerator);
    }
    globalColourCount = 0;

    for (let i = 0; i < lineGeneratorFunctionsArray.length; i++) {
      line.push({
        fill: 'none',
        path: lineGeneratorFunctionsArray[i](channelsDataArray[i]),
        stroke: globalColourCount <= 5 ? colours[globalColourCount] : 'black',
        strokeWidth: '7px',
        strokeLinecap: 'round',
        lineFor: 'prices',
      });
      globalColourCount += 1;
    }

    const finalHistoryArray = [];

    for (let i = 0; i < channelsDataArray.length; i++) {
      const finalHistoryMap = new Map();
      const arr = channelsDataArray[i];
      for (let j = 0; j < arr.length; j++) {
        finalHistoryMap.set(arr[j].days_prior, arr[j]);
      }

      const historyObj = {};
      historyObj.channel = arr[0].channel;
      const historyObjPriceTixData = [];

      let currentTix =
        finalHistoryMap.get(maxDayPrior) !== undefined ? finalHistoryMap.get(maxDayPrior).quantity_sold : 0;
      let currentPrice =
        finalHistoryMap.get(maxDayPrior) !== undefined ? finalHistoryMap.get(maxDayPrior).average_price : 0;
      for (let k = maxDayPrior; k >= 0; k--) {
        const currentDayData = {};
        if (finalHistoryMap.get(k) !== undefined) {
          currentDayData.days_prior = k;
          currentDayData.tickets_sold = finalHistoryMap.get(k).quantity_sold;
          currentDayData.average_price = finalHistoryMap.get(k).average_price;
          historyObjPriceTixData.push(currentDayData);
          currentTix = finalHistoryMap.get(k).quantity_sold;
          currentPrice = finalHistoryMap.get(k).average_price;
        } else {
          currentDayData.days_prior = k;
          if (tickets === 'non-cumulative') {
            currentDayData.tickets_sold = 0;
            currentDayData.average_price = 0;
          } else {
            currentDayData.tickets_sold = currentTix;
            currentDayData.average_price = currentPrice;
          }
          historyObjPriceTixData.push(currentDayData);
        }
      }
      historyObj.data = historyObjPriceTixData;
      finalHistoryArray.push(historyObj);
    }
    return {
      line,
      xScale,
      yScale,
      zScale,
      data,
      legendsDescriptionArray,
      stackedData,
      area,
      finalHistoryArray,
      maxDayPrior,
      gameDate,
      tickets,
    };
  }

  componentDidUpdate() {
    const { xScale, yScale, zScale, stackedData, area, finalHistoryArray, maxDayPrior, gameDate } = this.state;
    this.xAxis.scale(xScale);
    d3.select(this.refs.xAxis)
      .call(this.xAxis)
      .style('font-size', '15');

    this.yAxis.scale(yScale);
    d3.select(this.refs.yAxis)
      .call(this.yAxis)
      .style('font-size', '15');

    this.zAxis.scale(zScale);

    d3.select(this.refs.zAxis)
      .call(this.zAxis)
      .style('font-size', '15');

    d3.selectAll('.AxisLabel').remove();

    d3.select(`#my-svg`)
      .attr('viewBox', `0 0 ${width} ${height}`)
      .append('text')
      .attr('x', 600)
      .attr('y', 390)
      .attr('class', 'AxisLabel')
      .style('font-size', '24px')
      .style('font-family', 'sans-serif')
      .style('font-weight', 'bold')
      .style('text-anchor', 'middle')
      .text('Days Prior To Game');

    d3.select(`#my-svg`)
      .append('text')
      .attr('x', 10)
      .attr('y', 72)
      .attr('class', 'AxisLabel')
      .attr('transform', `translate(-50, ${height / 2 - 5}) rotate(-90)`)
      .style('font-size', '24px')
      .style('font-family', 'sans-serif')
      .style('font-weight', 'bold')
      .style('text-anchor', 'middle')
      .text('Tickets Sold (#)');

    d3.select(`#my-svg`)
      .append('text')
      .attr('x', 10)
      .attr('y', 1230)
      .attr('class', 'AxisLabel')
      .attr('transform', `translate(-50, ${height / 2 - 5}) rotate(-90)`)
      .style('font-size', '24px')
      .style('font-family', 'sans-serif')
      .style('font-weight', 'bold')
      .style('text-anchor', 'middle')
      .text('Ticket Price ($)');

    d3.selectAll('.series').remove();

    d3.select(`#my-svg`)
      .selectAll('.series')
      .data(stackedData)
      .enter()
      .append('g')
      .attr('class', 'series')
      .append('path')
      .attr('transform', `translate(${margin.left - 60},0)`)
      .style('fill', (d, i) => areaChartLightColor[i])
      .attr('stroke', 'rgb(128,128,128,0.3')
      .attr('stroke-width', '1px')
      .attr('d', d => area(d));

    const tooltip = d3.select(`#tooltip`);
    const tooltipLine = d3.select(`#my-svg`).append('line');
    const x = xScale;
    const history = finalHistoryArray;

    const tipbox = d3
      .select(`#my-svg`)
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('opacity', 0)
      .on('mousemove', () => {
        let day = (x.invert(d3.mouse(tipbox.node())[0]) / 10) * 10;
        if (day > maxDayPrior || day < 0) {
          if (tooltip) {
            tooltip.style('display', 'none');
          }
          if (tooltipLine) {
            tooltipLine.attr('stroke', 'none');
          }
          return;
        }

        day = Math.round(day);

        let dayPriorDate = new Date(gameDate);
        dayPriorDate.setDate(dayPriorDate.getDate() - day);
        dayPriorDate = moment(dayPriorDate).format('YYYY-MM-DD');
        tooltipLine
          .attr('stroke', 'black')
          .attr('x1', x(day))
          .attr('x2', x(day))
          .attr('y1', 0)
          .attr('y2', height - 60);

        tooltip
          .html(`Date: ${dayPriorDate}`)
          .style('display', 'block')
          .style('left', `${d3.event.clientX < 800 ? d3.event.clientX + 20 : d3.event.clientX - 270}px`)
          .style('top', `${d3.event.clientX < 800 ? d3.event.clientY - 20 : d3.event.clientY + 20}px`)
          .selectAll()
          .data(history)
          .enter()
          .append('div')
          .style('text-align', 'left')
          .html(d =>
            d.data.find(h => h.days_prior === day).tickets_sold > 0
              ? `${d.channel} Tickets Sold: ${formatNumber(d.data.find(h => h.days_prior === day).tickets_sold)}`
              : `${d.channel} Tickets Sold: ${0}`,
          )
          .append('span')
          .style('color', 'lightgray')
          .html(d => (d.data.find(h => h.days_prior === day).tickets_sold >= 10 ? '' : '----'))
          .append('div')
          .style('color', 'black')
          .style('text-align', 'left')
          .html(d =>
            d.data.find(h => h.days_prior === day).tickets_sold > 0
              ? // eslint-disable-next-line
                d.channel + ' Price: ' + formatCurrency(d.data.find(h => h.days_prior === day).average_price, true)
              : // eslint-disable-next-line
                d.channel + ' Price: ' + 'N/A',
          )
          .append('span')
          .style('color', 'lightgray')
          .html(d => (d.data.find(h => h.days_prior === day).tickets_sold >= 10 ? '' : '----'))
          .append('div');
      })
      .on('mouseout', () => {
        if (tooltip) {
          tooltip.style('display', 'none');
        }
        if (tooltipLine) {
          tooltipLine.attr('stroke', 'none');
        }
      });
  }

  render() {
    const { line, data, legendsDescriptionArray } = this.state;
    return (
      <div>
        <svg id="my-svg" preserveAspectRatio="xMidYMid meet">
          {line.map((d, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <path key={i} d={d.path} stroke={d.stroke} fill={d.fill} strokeWidth="3px" />
          ))}
          <g ref="xAxis" transform={`translate(0, ${height - margin.bottom - 20})`} />
          <g ref="yAxis" transform={`translate(${margin.left + 20}, 0)`} />
          <g ref="zAxis" transform={`translate(${width - 85}, 0)`} />
        </svg>
        <div id="tooltip" style={{ position: 'absolute', backgroundColor: 'lightgray', padding: '5px' }} />
        <br />
        {data.length > 0 ? <ProfitRoverExploreLegend legends={legendsDescriptionArray} /> : <div />}
      </div>
    );
  }
}

export default LineChart;
