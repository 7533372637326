import { useDatasetConfigs } from '../../../data-access/query/datasetConfigs';
import { useGuidedSetup } from '../../../data-access/query/guidedSetup';

// eslint-disable-next-line import/prefer-default-export
export const useNewDataSourceNaicsData = () => {
  const { data: datasetConfigs = [] } = useDatasetConfigs();
  const { data: guidedSetup } = useGuidedSetup();

  const [firstConfig] = datasetConfigs;

  let naicsCode;
  let kaCode;
  if (guidedSetup?.naics_industry?.naics_code != null && guidedSetup?.naics_industry?.ka_code != null) {
    naicsCode = guidedSetup.naics_industry.naics_code;
    kaCode = guidedSetup.naics_industry.ka_code;
  } else if (firstConfig?.naics_code != null && firstConfig?.ka_code != null) {
    naicsCode = firstConfig.naics_code;
    kaCode = firstConfig.ka_code;
  }

  return { naicsCode, kaCode };
};
