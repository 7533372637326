import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import LoadingIndicator from '../../accountSettings/common/LoadingIndicator';
import RetryOrGoToFallback from '../../generic/errorBoundary/RetryOrGoToFallback';
import ProfitRoverTooltip from '../../generic/ProfitRoverTooltip';
import Header from '../../header/header';
import { getWorkflowTitle } from '../../workflow/workflowFunctions';
import {
  fetchModelFormulaCSVFile,
  OUTPUT_FILENAME_KEY_BASE,
  useOutputFileName,
} from '../../../data-access/query/modelFormula';
import { useWorkflow, useWorkflowBatchesWithStatus } from '../../../data-access/query/workflows';
import './price-elasticity-dashboard.css';

const DatasetCSVDownload = ({ workflowId, fileName, batchComplete }) => {
  const fileNameAvailable = fileName != null;
  const readyForDownload = fileNameAvailable && batchComplete;

  const tooltipText =
    'Your insights are updating. You will receive a notification in the Notification Center when they are ready.';

  return (
    <button type="button" disabled={!readyForDownload} onClick={() => fetchModelFormulaCSVFile(workflowId, fileName)}>
      <ProfitRoverTooltip
        shouldDisplayTooltip={!readyForDownload}
        tooltipId="download-disabled"
        placement="top"
        tooltipText={tooltipText}
      >
        <FontAwesomeIcon icon={faDownload} size="1x" />
      </ProfitRoverTooltip>
    </button>
  );
};

const PriceElasticityDashboard = props => {
  const { workflowId } = props;
  const { data: fileName, isLoading: isLoadingFileName } = useOutputFileName(workflowId);
  const { data: workflow, isLoading: isLoadingWorkflow } = useWorkflow(workflowId);
  const { batchComplete, isLoading: isLoadingBatches } = useWorkflowBatchesWithStatus(workflowId);

  const loading = isLoadingFileName || isLoadingWorkflow || isLoadingBatches;

  const workflowTitle = isLoadingWorkflow ? null : getWorkflowTitle(workflow);

  return (
    <>
      <Header headerSubText={workflowTitle} />
      <div className="price-elasticity-dashboard">
        <div>
          <div className="heading-label">File</div>
          <div>{fileName}</div>
        </div>
        <div className="pe-download-container">
          <div className="heading-label">Actions</div>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <DatasetCSVDownload workflowId={workflowId} fileName={fileName} batchComplete={batchComplete} />
          )}
        </div>
      </div>
    </>
  );
};

const PriceElasticityDashboardPage = props => {
  let { workflowId } = props?.match?.params;
  workflowId = +workflowId;
  const queryClient = useQueryClient();

  const onReset = async () => {
    await queryClient.removeQueries([OUTPUT_FILENAME_KEY_BASE, workflowId]);
  };

  return (
    <ErrorBoundary FallbackComponent={RetryOrGoToFallback} onReset={onReset}>
      <PriceElasticityDashboard workflowId={workflowId} />
    </ErrorBoundary>
  );
};

export default PriceElasticityDashboardPage;
