import React from 'react';

export const RegistrationContext = React.createContext();
export const useRegistrationContext = () => React.useContext(RegistrationContext);

export const REGISTRATION_STEPS = {
  PRODUCT_DESCRIPTION: 'PRODUCT_DESCRIPTION',
  REQUEST_CODE: 'REQUEST_CODE',
  REDEEM_CODE: 'REDEEM_CODE',
  AUTO_LOGIN: 'AUTO_LOGIN',
};
