import React from 'react'
import './icon-button.css'

const IconButton = ({ children, disabled, onClick }) => {
  return (
    <button type="button" className="settings-page-icon-button" tabIndex="0" onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default IconButton;
