import React from 'react';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { BubbleLabel } from './common/CommonSubscriptionComponents';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import { ProfitRoverModalCard, ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';
import { nullSafeFormatCurrency } from '../../util/format';
import { useBillingInfo, useRefreshSubscriptionData } from '../../../data-access/query/subscriptions';

export class ChargedPaymentSuccess {
  constructor({ paidInvoice, paymentEmailAddress }) {
    this.paidInvoice = paidInvoice;
    this.paymentEmailAddress = paymentEmailAddress;
  }
}

const SuccessItem = ({ label, value }) => (
  <div className="success-item">
    <span className="success-item-label">{label}</span>
    <span className="success-item-value">{value}</span>
  </div>
);

const PaymentDetails = ({ subscriptionId, title, paymentDetails }) => {
  const { data: billingInfo } = useBillingInfo(subscriptionId, 'defaultPaymentMethod', {
    enabled: false, // Data should already be cached
  });

  if (!billingInfo) {
    return null;
  }

  const { default_payment_method: defaultPaymentMethod } = billingInfo ?? {};
  const { last4: last4Digits = '' } = defaultPaymentMethod;
  const brand = defaultPaymentMethod?.brand?.toUpperCase() ?? '';
  const paymentMethodDisplay = `${brand}........${last4Digits}`;

  let topNote;
  const items = [];
  let bottomNote;
  if (paymentDetails instanceof ChargedPaymentSuccess) {
    const { paidInvoice, paymentEmailAddress } = paymentDetails;
    items.push(['Payment Date', moment.unix(paidInvoice?.status_transitions?.paid_at).format('MMM Do, YYYY h:mm A')]);
    items.push(['Amount Paid', nullSafeFormatCurrency(paidInvoice?.amount_paid / 100)]);
    items.push(['Card', paymentMethodDisplay]);
    items.push(['Invoice Number', paidInvoice?.number ?? '--']);

    bottomNote = (
      <span>
        We sent an email receipt to <span className="payment-email">{paymentEmailAddress}</span>. You can also view this
        payment in your invoices.
      </span>
    );
  }

  return (
    <div className="payment-success-message">
      <div className="d-flex flex-column align-items-center">
        <FontAwesomeIcon style={{ marginBottom: 5 }} icon={faCheckCircle} size="3x" color="#5FAD56" />
        <div className="payment-success-title">{title}</div>
      </div>
      {topNote && <div className="top-note">{topNote}</div>}
      {items.length > 0 && (
        <>
          <hr />
          {items.map(([label, value]) => (
            <SuccessItem key={label} label={label} value={value} />
          ))}
        </>
      )}
      <hr />
      {bottomNote && <div className="bottom-note">{bottomNote}</div>}
    </div>
  );
};

const PaymentSuccessDetails = ({ subscriptionId, title, paymentCompletionDetails }) => {
  const refreshSubscriptionData = useRefreshSubscriptionData(subscriptionId);

  const history = useHistory();
  const returnToSubMgmtSummaryPage = () => history.push('/subscription-management');

  return (
    <>
      <BubbleLabel>Payment Summary</BubbleLabel>
      <ProfitRoverModalCard>
        <ProfitRoverModalMain className="left-main">
          <PaymentDetails subscriptionId={subscriptionId} title={title} paymentDetails={paymentCompletionDetails} />
        </ProfitRoverModalMain>
        <ProfitRoverModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
          <ProfitRoverPrimaryButton
            onClick={async () => {
              returnToSubMgmtSummaryPage();

              await refreshSubscriptionData();
            }}
          >
            OK
          </ProfitRoverPrimaryButton>
        </ProfitRoverModalFooter>
      </ProfitRoverModalCard>
    </>
  );
};

export default PaymentSuccessDetails;
