import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_DATASETS_BASE = `${KA_API_URL}/datasets`;

/**
 * Requests
 */

const fetchDataset = async datasetId => axios.get(`${GET_DATASETS_BASE}/${datasetId}`);
export const fetchDatasets = async () => axios.get(GET_DATASETS_BASE);
export const fetchDatasetsForIndustry = async industry => axios.get(`${GET_DATASETS_BASE}/industry/${industry}`);

export const fetchDatasetSample = datasetId =>
  axios.get(`${KA_API_URL}/datasets/sample`, {
    params: {
      dataset_id: datasetId,
    },
  });

/**
 * Hooks
 */

const DATASET_QUERY_KEY_BASE = 'dataset';
export const DATASETS_QUERY_KEY = ['datasets'];
export const DATASETS_INDUSTRY_QUERY_KEY_BASE = ['datasetsForIndustry'];
const DATASET_SAMPLE_QUERY_KEY_BASE = ['datasetSample'];

export const useDataset = (datasetId, queryConfig) =>
  useQuery(
    [DATASET_QUERY_KEY_BASE, datasetId],
    async () => {
      const response = await fetchDataset(datasetId);

      return response?.data;
    },
    queryConfig,
  );

export const useDatasets = () =>
  useQuery(DATASETS_QUERY_KEY, async () => {
    const response = await fetchDatasets();
    const datasets = response.data ?? [];

    return datasets;
  });

export const useDatasetsForIndustry = (industry, queryConfig) =>
  useQuery(
    [...DATASETS_INDUSTRY_QUERY_KEY_BASE, industry],
    async () => {
      const response = await fetchDatasetsForIndustry(industry);
      const datasets = response.data ?? [];

      return datasets;
    },
    queryConfig,
  );

export const useDatasetSample = (datasetId, queryConfig) =>
  useQuery(
    [...DATASET_SAMPLE_QUERY_KEY_BASE, datasetId],
    async () => {
      const response = await fetchDatasetSample(datasetId);
      const samples = response.data ?? [];

      return samples;
    },
    queryConfig,
  );
