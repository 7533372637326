import React from 'react';

export const ProfitRoverLogoIcon = ({ className, props }) => (
  <img className={className} src="/images/ProfitRoverLogo.png" alt="ProfitRover Logo" {...props} />
);

export const ProfitRoverLinearLogo = ({ className, ...props }) => (
  <img className={className} src="/images/ProfitRoverLinearLogo.png" alt="ProfitRover Logo" {...props} />
);

export const ProfitRoverHeaderLogo = ({ className }) => (
  <img className={className} src="/images/ProfitRoverHeaderLogo.png" alt="ProfitRover Logo" />
);
