const mutateNaicsKaCode = bodyObject => {
  if ('naicsKaCode' in bodyObject) {
    bodyObject.naics_code = bodyObject.naicsKaCode.substring(0, 6);
    bodyObject.ka_code = bodyObject.naicsKaCode.substring(6, 7);
    delete bodyObject.naicsKaCode;
  }
};

module.exports = {
  mutateNaicsKaCode,
};
