import classnames from 'classnames';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './profitrover-tooltip.scss';

const ProfitRoverTooltip = props => {
  const {
    children,
    shouldDisplayTooltip = true,
    tooltipText,
    tooltipId,
    placement,
    tooltipClass,
    onToggle,
    ...triggerProps
  } = props;

  if (shouldDisplayTooltip) {
    return (
      <OverlayTrigger
        placement={placement}
        key={`${placement}-tooltip`}
        overlay={
          <Tooltip id={tooltipId} className={classnames('default-profitrover-tooltip', tooltipClass)}>
            {tooltipText}
          </Tooltip>
        }
        onToggle={onToggle}
        {...triggerProps}
      >
        {children}
      </OverlayTrigger>
    );
  }

  return children;
};

export default ProfitRoverTooltip;
