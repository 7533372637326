import React from 'react';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProfitRoverPrimaryButton, ProfitRoverCancelButton } from '../../forms/ProfitRoverButtons';
import ProfitRoverSpinner from '../../spinner/ProfitRoverSpinner';
import './checkout-panel.css';

const CheckoutPlanSwitchPanel = ({ switchingTo, onContinue, onCancel, isLoading }) => {
  return (
    <footer className="checkout-panel-container">
      <div className="checkout-panel">
        <div className="shopping-cart-container">
          <FontAwesomeIcon icon={faShoppingCart} />
        </div>
        <div className="changed-status">Switching to {switchingTo}</div>
        <div className="action-buttons">
          <ProfitRoverPrimaryButton small onClick={onContinue} disabled={isLoading}>
            Continue
          </ProfitRoverPrimaryButton>
          <ProfitRoverCancelButton small onClick={onCancel} disabled={isLoading}>
            Cancel
          </ProfitRoverCancelButton>
          {isLoading && <ProfitRoverSpinner />}
        </div>
      </div>
    </footer>
  );
};

export default CheckoutPlanSwitchPanel;
