import React from 'react';
import { DashboardContext } from './revenueUpliftContexts';
import RevenueUpliftSelect from './RevenueUpliftSelect';
import { DATE_RANGE_SELECTION, DATE_RANGE_TO_LABEL } from '../../../../utils/date-handling';
import { gaEmitImpactsDropdownClick } from '../../../../google-analytics/pricingTab';
import './time-period-picker.scss';

export const POPOVER_ID = 'time-period-picker-popover';
// const NO_INPUT_RANGES = Object.freeze([]);
// const LAYOUT_COLLAPSE_WIDTH = 985;
// const DATE_DISPLAY_FORMAT = 'MMM d, yyyy';

// Formats date range like so: Aug 25, 2022 - Sep 1, 2022
// const formatDateRange = (startDate, endDate) => {
//   return `${format(startDate, DATE_DISPLAY_FORMAT)} - ${format(endDate, DATE_DISPLAY_FORMAT)}`;
// };

// const PickerPopover = props => {
//   const { children } = props;

//   /**
//    * We must prevent propagation of the MouseMove event to the dashboard underneath the Popover
//    * in order to mitigate distracting re-renders of the "collapse" UI.
//    */
//   return (
//     <div onMouseMove={e => e.stopPropagation()} className="time-period-popover-container">
//       {children}
//     </div>
//   );
// };

// Unused but kept in case we need a date picker again in the future
// const TimePeriodPicker = ({
//   selectedDateRange,
//   selectedRange,
//   onSelectDateRange,
//   predefinedDateRanges,
//   minDate,
//   maxDate,
//   isDisabled,
// }) => {
//   const { width } = useWindowSize();
//   const isCollapsedLayout = width <= LAYOUT_COLLAPSE_WIDTH;

//   const [showPopover, setShowPopover] = React.useState(false);
//   const target = React.useRef(null);

//   const memoizedSelectedRange = React.useMemo(() => [selectedRange], [selectedRange]);
//   const onSelect = React.useCallback(
//     rangesByKey => {
//       onSelectDateRange(rangesByKey[SELECTED_TIME_PERIOD_KEY], () => setShowPopover(false));
//     },
//     [onSelectDateRange],
//   );

//   const { startDate, endDate } = selectedRange;

//   let dateRangeDisplay;
//   try {
//     dateRangeDisplay = formatDateRange(startDate, endDate);
//   } catch {
//     dateRangeDisplay = '(Invalid)';
//   }

//   return (
//     <>
//       <button
//         className="date-range-picker-anchor"
//         type="button"
//         ref={target}
//         onClick={() => setShowPopover(!showPopover)}
//         disabled={isDisabled}
//       >
//         <strong>{DATE_RANGE_TO_LABEL[selectedDateRange] ?? 'Custom'}: </strong>
//         {dateRangeDisplay}
//       </button>

//       <Overlay
//         show={!isDisabled && showPopover}
//         onHide={() => setShowPopover(false)}
//         rootClose
//         target={target.current}
//         placement="bottom-start"
//       >
//         <Popover id={POPOVER_ID}>
//           <PickerPopover>
//             <DateRangePicker
//               ranges={memoizedSelectedRange}
//               onChange={onSelect}
//               staticRanges={predefinedDateRanges}
//               inputRanges={NO_INPUT_RANGES}
//               fixedHeight
//               months={isCollapsedLayout ? 1 : 2}
//               minDate={minDate}
//               maxDate={maxDate}
//               direction="horizontal"
//               preventSnapRefocus
//             />
//           </PickerPopover>
//         </Popover>
//       </Overlay>
//     </>
//   );
// };

const RevenueUpliftTimePeriodPicker = props => {
  const { selectedRange, setSelectedRange } = React.useContext(DashboardContext);

  const options = Object.values(DATE_RANGE_SELECTION).map(option => ({
    label: DATE_RANGE_TO_LABEL[option],
    value: option,
  }));

  const onSelectOption = option => {
    gaEmitImpactsDropdownClick(option.value);
    setSelectedRange(option.value);
  };

  return (
    <RevenueUpliftSelect
      options={options}
      value={{ label: DATE_RANGE_TO_LABEL[selectedRange], value: selectedRange }}
      onChange={onSelectOption}
      placeholder="Select a time period"
      {...props}
    />
  );
};

export default RevenueUpliftTimePeriodPicker;
