import { AUTH_ERROR, LOGOUT, JWT_REFRESH, USER_LOADED } from './types';

const initialState = {
  loading: true,
  isAuthenticated: false,
};

export default function reducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case USER_LOADED: {
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: {},
      };
    }
    case AUTH_ERROR:
    case LOGOUT:
      return { loading: false, isAuthenticated: false };
    case JWT_REFRESH:
      /*
       * This action pokes an otherwise useless value in state in order to trigger a reconnect to the Notification
       * Center's notification stream, which uses the EventSource API and Server-sent Events (SSE) instead of e.g. an
       * axios request. The EventSource must be recreated when the JWT is refreshed because the JWT is itself a
       * parameter of the API call that establishes the connection to the notification stream.
       */
      return { ...state, jwtRefreshedAt: Date.now() };
    default:
      return state;
  }
}
