import React from 'react';
import { Modal } from 'react-bootstrap';
import Select, { components } from 'react-select';
import ReactTable from 'react-table';
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ProfitRoverCancelButton, ProfitRoverPrimaryButton } from '../../../forms/ProfitRoverButtons';
import { Country, getRegionSpecificData } from '../../../generic/countryConstants';
import { ProfitRoverModalCard, ProfitRoverModalFooter, ProfitRoverModalMain } from '../../../generic/ProfitRoverCard';
import { countryDropdownOptions } from '../../../util/countryOptions';
import { MID_GREEN } from '../../../../colors';
import { isValidZipCode, isValidPostalCode } from '../../../../utils/validators';
import './address-details-modal.scss';

const EditableCell = props => {
  const { value, onChange, placeholder } = props;

  return (
    <TextareaAutosize
      value={value}
      onChange={e => onChange(e.target.value)}
      className={value ? 'has-value' : 'has-no-value'}
      placeholder={placeholder}
    />
  );
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faCaretDown} style={{ color: '#000000' }} />
    </components.DropdownIndicator>
  );
};

const AddressDetailsModal = props => {
  const {
    selectedLocation,
    onHideAddressDetailsModal: onHide,
    onUpdateLocation,
    isSubmittingTrialLocationSelection,
  } = props;

  const {
    location_id: locationId,
    location_description: locationDescription,
    location_identifier: locationIdentifier,
  } = selectedLocation;
  let {
    address: initialAddress,
    city: initialCity,
    zip: initialZip,
    state: initialRegion,
    country_code: initialCountry,
  } = selectedLocation;

  // allowing these variables to be null will cause a warning because they're used as textarea values
  initialAddress = initialAddress ?? '';
  initialCity = initialCity ?? '';
  initialZip = initialZip ?? '';

  if (initialCountry) {
    initialCountry = countryDropdownOptions.find(country => country.value === initialCountry);
  }

  const [locationName, setLocationName] = React.useState(
    () => locationDescription ?? locationIdentifier ?? `Location id: ${locationId}`,
  );
  const [locationAddress, setLocationAddress] = React.useState(initialAddress);
  const [locationCity, setLocationCity] = React.useState(initialCity);
  const [locationCountry, setLocationCountry] = React.useState(initialCountry);
  const [locationZip, setLocationZip] = React.useState(initialZip);

  const countryIsCanada = locationCountry?.value === Country.CA;
  const { regionLabel, regionDropdownOptions, postcodeLabel } = getRegionSpecificData(countryIsCanada);

  if (initialRegion) {
    initialRegion = regionDropdownOptions.find(region => region.value === initialRegion);
  }
  const [locationRegion, setLocationRegion] = React.useState(initialRegion);

  const isButtonEnabled =
    locationName &&
    locationAddress &&
    locationCity &&
    locationRegion &&
    locationCountry &&
    (isValidZipCode(locationZip) || isValidPostalCode(locationZip)) &&
    !isSubmittingTrialLocationSelection;

  const confirmSelectionAndUpdateDetails = async () => {
    const locationDetails = {
      location_description: locationName,
      address: locationAddress,
      city: locationCity,
      state: locationRegion.value,
      country_code: locationCountry.value,
      country_name: locationCountry.label,
      zip: locationZip,
    };
    await onUpdateLocation(locationDetails);
  };

  const HEADER_STYLES = {
    background: MID_GREEN,
    color: 'white',
    textAlign: 'left',
    fontWeight: 'bold',
    paddingLeft: '10px',
    borderWidth: 0,
  };

  const COMMON_DROPDOWN_STYLES = {
    indicatorSeparator: () => ({
      display: 'none',
    }),
    valueContainer: () => ({
      height: '27px',
    }),
    indicatorsContainer: () => ({
      height: '27px',
    }),
    placeholder: provided => ({
      ...provided,
      paddingLeft: '0.2rem',
    }),
    input: () => ({
      height: '27px',
      paddingLeft: '0.5rem',
    }),
    menu: provided => ({
      ...provided,
      height: '27px',
      width: 'max-content',
      marginRight: '8px',
      minWidth: '100%',
      marginTop: '0px',
    }),
    menuList: provided => ({
      ...provided,
      fontSize: '14px',
      background: 'white',
      overflowX: 'hidden',
    }),
  };

  const regionCountryDropdownStyle = {
    ...COMMON_DROPDOWN_STYLES,
    container: () => ({
      position: 'absolute',
      top: '38px',
      fontSize: '14px',
      minWidth: 'inherit',
      width: 'inherit',
      paddingRight: '0.5rem',
      backgroundColor: 'transparent',
    }),
    control: (provided, controlState) => ({
      ...provided,
      minHeight: 'unset',
      height: '27px',
      boxShadow: controlState.isFocused ? '0px 1px 8px #FFC500D9' : '',
      border: locationRegion ? 'none' : '1px solid #707070',
      borderRadius: '4px',
      backgroundColor: 'transparent',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      height: '27px',
      display: locationRegion ? 'none' : 'flex',
      alignItems: 'center',
    }),
    singleValue: provided => ({
      ...provided,
      paddingLeft: '0.5rem',
    }),
  };

  const columns = [
    {
      id: 'location',
      Header: 'Location Name',
      headerStyle: { ...HEADER_STYLES, minWidth: '160px' },
      style: { paddingLeft: '0.5rem', minWidth: '160px' },
      Cell: () => {
        return <EditableCell value={locationName} onChange={setLocationName} placeholder="Location name" />;
      },
    },
    {
      id: 'address',
      Header: 'Address',
      headerStyle: { ...HEADER_STYLES, minWidth: '160px' },
      style: { paddingLeft: '0.5rem', minWidth: '160px' },
      Cell: () => {
        return <EditableCell value={locationAddress} onChange={setLocationAddress} placeholder="Address" />;
      },
    },
    {
      id: 'country',
      Header: 'Country',
      maxWidth: 100,
      headerStyle: { ...HEADER_STYLES, minWidth: '75px' },
      style: { paddingLeft: '0.5rem', minWidth: '75px' },
      Cell: () => {
        return (
          <Select
            components={{ DropdownIndicator }}
            value={locationCountry}
            options={countryDropdownOptions}
            onChange={selectedCountry => {
              if (selectedCountry.value !== locationCountry?.value) {
                setLocationCountry(selectedCountry);
                setLocationRegion(null);
              }
            }}
            placeholder="Country"
            styles={regionCountryDropdownStyle}
          />
        );
      },
    },
    {
      id: 'city',
      Header: 'City',
      headerStyle: { ...HEADER_STYLES, minWidth: '160px' },
      style: { paddingLeft: '0.5rem', minWidth: '160px' },
      Cell: () => {
        return <EditableCell value={locationCity} onChange={setLocationCity} placeholder="City" />;
      },
    },
    {
      id: 'state',
      Header: regionLabel,
      maxWidth: 100,
      headerStyle: { ...HEADER_STYLES, minWidth: '75px' },
      style: { paddingLeft: '0.5rem', minWidth: '75px' },
      Cell: () => {
        return (
          <Select
            components={{ DropdownIndicator }}
            value={locationRegion}
            options={regionDropdownOptions}
            onChange={e => setLocationRegion(e)}
            placeholder="State"
            styles={regionCountryDropdownStyle}
          />
        );
      },
    },
    {
      id: 'zipcode',
      Header: postcodeLabel,
      headerStyle: { ...HEADER_STYLES, minWidth: '105px' },
      style: { paddingLeft: '0.5rem' },
      maxWidth: 120,
      Cell: () => {
        return <EditableCell value={locationZip} onChange={setLocationZip} placeholder={postcodeLabel} />;
      },
    },
  ];

  return (
    <Modal show centered dialogClassName="address-details-modal-dialog">
      <ProfitRoverModalCard>
        <ProfitRoverModalMain>
          <div className="address-details-modal-container">
            <div className="header-line-1">Update address details?</div>
            <div className="header-line-2">
              This will enable you to get location specific details like weather, holidays, and more!
            </div>
            <div>
              <ReactTable
                columns={columns}
                className="location-edit-table"
                minRows={0}
                showPagination={false}
                sortable={false}
                data={[{}]}
              />
            </div>
          </div>
        </ProfitRoverModalMain>
        <ProfitRoverModalFooter className="address-details-modal-footer">
          <div className="footer-left">
            <ProfitRoverCancelButton onClick={onHide}>Reselect Location</ProfitRoverCancelButton>
          </div>
          <div className="footer-right">
            <ProfitRoverPrimaryButton onClick={confirmSelectionAndUpdateDetails} disabled={!isButtonEnabled}>
              Confirm
            </ProfitRoverPrimaryButton>
          </div>
        </ProfitRoverModalFooter>
      </ProfitRoverModalCard>
    </Modal>
  );
};

export default AddressDetailsModal;
