import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Objective } from '../components/workflow/workflowConstants';

/**
 * Enumerates the kinds of dashboards that can be viewed by users. Useful in mapping
 * behaviors or data to distinct dashboards on offer.
 */
export const WorkflowTypes = {
  EO_PRICE: 'EOPRICING',
  CFB_PRICE: 'CFBPRICING',
  ATTENDANCE: 'ATTEND',
  PRICE_ELASTICITY: 'PRICEELASTICITY',
  ANOMALY: 'ANOMALY',
};

const MAP_WORKFLOW_TYPE_TO_DASHBOARD_URL = {
  [WorkflowTypes.ATTENDANCE]: '/eokaizenattendance',
  [WorkflowTypes.EO_PRICE]: '/profitrover',
  [WorkflowTypes.CFB_PRICE]: '/cfbkaizenprice/',
  [WorkflowTypes.PRICE_ELASTICITY]: '/mediakaizenprice',
  [WorkflowTypes.ANOMALY]: '/kaizenanomaly',
};

export function calculateWorkflowType(industry, objective) {
  let workflowType;

  if (industry === 'EO' && objective === Objective.ATTENDFORECAST) {
    workflowType = WorkflowTypes.ATTENDANCE;
  } else if (industry === 'EO' && objective === Objective.PRICEOPT) {
    workflowType = WorkflowTypes.EO_PRICE;
  } else if (industry === 'CFB') {
    workflowType = WorkflowTypes.CFB_PRICE;
  } else if (industry === 'MEDIA') {
    workflowType = WorkflowTypes.PRICE_ELASTICITY;
  } else if (industry === 'LOGISTICS' && objective === Objective.ANOMALY) {
    workflowType = WorkflowTypes.ANOMALY;
  } else if (industry === 'MOVIE' && objective === Objective.PRICEOPT) {
    workflowType = WorkflowTypes.EO_PRICE;
  }

  return workflowType;
}

/**
 * Returns a function that will navigate to the dashboard that
 * a workflow corresponds to.
 *
 * @param {*} workflow
 */
export function useNavigateToDashboard(workflow) {
  const { workflow_id: workflowId, objective, industry } = workflow ?? {};

  const history = useHistory();

  if (workflow == null) {
    return _.noop;
  }

  const workflowType = calculateWorkflowType(industry, objective);

  const baseUrl = MAP_WORKFLOW_TYPE_TO_DASHBOARD_URL[workflowType];
  const navigationPath = `${baseUrl}/${workflowId}`;

  return () => {
    if (navigationPath) {
      history.push(navigationPath);
    }
  };
}
