import axios from 'axios';
import { KA_API_ROOT_URL } from '../../config/baseUrl';
import { loadUser, setAuthInLocalStorage } from '../../redux/auth/actions';
import { resetState } from '../../utils/axios-client';

const impersonateUser = async email => {
  if (email == null || email === '') {
    throw new Error('no user email provided');
  }

  const response = await axios.post(`${KA_API_ROOT_URL}/auth/impersonate`, { email });

  const { data: loginData, status: responseStatus } = response;

  if (responseStatus !== 200) {
    throw new Error(`impersonate response code: ${responseStatus}`);
  }

  // Erase currently logged in user
  resetState();

  // Mimic the signin process
  setAuthInLocalStorage(email, loginData);
  loadUser();
};

export default impersonateUser;
