import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DataSourceDisplayName, isAncillaryDataSource } from './dataSourceConstants';
import {
  getIntegrationTimestampLabel,
  DataSourceBase,
  FinishSetupButton,
  ReconnectButton,
  ResyncDataSourceButton,
} from './DataSourceRowCommon';
import DisconnectConfirmationModal from './DisconnectConfirmationModal';
import ReconnectConfirmationModal from './ReconnectConfirmationModal';
import { DataType } from '../data-center/datasetConfig/DatasetConfigFormConstants';
import { ProfitRoverDestructiveButton } from '../forms/ProfitRoverButtons';
import ProfitRoverTooltip from '../generic/ProfitRoverTooltip';
import { CREDENTIAL_TYPE } from '../setup/IntegrationConstants';
import { shouldHaveWorkflow, useCreatePartnerRecords } from '../setup/modern/createDatabaseRecords';
import { DATA_SOURCE_TO_DATA_CENTER_LABEL } from '../setup/modern/DataSourceLogos';
import { useRevokePartnerAccessTokens } from '../../data-access/mutation/oauthPartner';
import { usePartnerCredentials } from '../../data-access/query/credentials';
import { gaEmitDisconnectButtonClick } from '../../google-analytics/dataCenter';

const OauthDataSourceRow = ({ datasetConfig, datasets }) => {
  const { partner, name, data_type: dataType, industry } = datasetConfig;

  const history = useHistory();
  const [showDisconnectModal, setShowDisconnectModal] = React.useState(false);
  const toggleShowDisconnectModal = () => setShowDisconnectModal(!showDisconnectModal);

  const [showReconnectModal, setShowReconnectModal] = React.useState(false);
  const toggleShowReconnectModal = () => setShowReconnectModal(!showReconnectModal);

  const { data: partnerCredentials = [], isLoading: isLoadingCredentials } = usePartnerCredentials(partner);
  const revokePartnerAccessTokensMutation = useRevokePartnerAccessTokens(partner, {
    onSuccess: toggleShowDisconnectModal,
  });
  const { isLoading: isRevokingTokens } = revokePartnerAccessTokensMutation;

  const {
    isInitializing,
    allPartnerRecordsExist,
    tranRecordsMissing,
    gratuityRecordsMissing,
    catalogRecordsMissing,
    createPartnerRecords,
  } = useCreatePartnerRecords();

  const credentialsNotRevoked = partnerCredentials.filter(cred => cred.revoked_gmt_datetime == null);
  const hasMissingAutomaticRecords = !allPartnerRecordsExist(partner);

  /**
   * Note: for Oauth partners we are expecting 2 credentials (access and renewal) that are not revoked
   */
  const partnerCredentialsMissing = credentialsNotRevoked.length < 2;

  // these credentials are revoked in tandem so just examine the access key
  const partnerCredentialsRevocationTime = partnerCredentials.find(
    credential => credential.credential_type === CREDENTIAL_TYPE.ACCESS_KEY,
  )?.revoked_gmt_datetime;
  const partnerCredentialsAreRevoked = partnerCredentialsRevocationTime != null;

  const label = DATA_SOURCE_TO_DATA_CENTER_LABEL[partner];

  // only 1 dataset per integration config is supported
  const [dataset] = datasets;
  const { fetched_gmt_datetime: datasetFetchedTime } = dataset ?? {};

  const setupIncomplete = partnerCredentialsMissing || hasMissingAutomaticRecords;
  const isDataSourceIncomplete = () => {
    switch (dataType) {
      case DataType.TRAN:
        tranRecordsMissing(partner);
        break;
      case DataType.GRATUITY:
        gratuityRecordsMissing(partner);
        break;
      case DataType.CATALOG:
        catalogRecordsMissing(partner);
        break;
      default:
        tranRecordsMissing(partner);
    }
  };

  const timestamp = getIntegrationTimestampLabel(
    datasetFetchedTime,
    isDataSourceIncomplete(),
    partnerCredentialsRevocationTime,
  );

  const navigateToOAuthFlow = async () => {
    await createPartnerRecords({ partner });

    history.push(`/data-sources/add-new/${_.toLower(partner)}`);
  };

  const navigateToReconnectOAuthFlow = async () => {
    await createPartnerRecords({ partner });

    history.push(`/data-sources/reconnect/${_.toLower(partner)}`);
  };

  const finishSetup = async () => {
    if (partnerCredentialsAreRevoked) {
      navigateToReconnectOAuthFlow();
    }
    if (partnerCredentialsMissing) {
      navigateToOAuthFlow();
    }
  };

  const onDisconnect = () => {
    gaEmitDisconnectButtonClick();
    revokePartnerAccessTokensMutation.mutateAsync();
  };

  const actionButtonIsDisabled = isInitializing || isLoadingCredentials || partnerCredentialsAreRevoked;
  const finishSetupButtonDisabled = isInitializing || isLoadingCredentials;
  const finishOrReconnect =
    !isInitializing && !isLoadingCredentials && (partnerCredentialsMissing || partnerCredentialsAreRevoked);

  let actionButton = (
    <ResyncDataSourceButton
      datasetConfig={datasetConfig}
      disabled={actionButtonIsDisabled}
      setupIncomplete={setupIncomplete}
      isWorkflow={shouldHaveWorkflow(industry, dataType)}
    />
  );

  // Do not override the action button if this is an ancillary config
  if (!isAncillaryDataSource(datasetConfig)) {
    if (
      !isInitializing &&
      hasMissingAutomaticRecords &&
      (tranRecordsMissing(partner) || gratuityRecordsMissing(partner))
    ) {
      actionButton = <FinishSetupButton disabled={finishSetupButtonDisabled} onClick={finishSetup} />;
    } else if (finishOrReconnect) {
      if (partnerCredentialsAreRevoked) {
        actionButton = <ReconnectButton disabled={finishSetupButtonDisabled} onClick={toggleShowReconnectModal} />;
      } else {
        actionButton = <FinishSetupButton disabled={finishSetupButtonDisabled} onClick={navigateToOAuthFlow} />;
      }
    }
  }

  const showDisconnectButton =
    dataType === DataType.TRAN &&
    !partnerCredentialsAreRevoked &&
    !partnerCredentialsMissing &&
    !tranRecordsMissing(partner);

  const disconnectButton = (
    <ProfitRoverTooltip
      delay={{ show: 200, hide: 100 }}
      tooltipText={`Click to instruct ProfitRover to stop syncing with your ${DataSourceDisplayName[partner]} account`}
    >
      <ProfitRoverDestructiveButton
        type="button"
        className="disconnect-btn ml-4"
        onClick={toggleShowDisconnectModal}
        disabled={isRevokingTokens}
        small
      >
        <i>Disconnect</i>
      </ProfitRoverDestructiveButton>
    </ProfitRoverTooltip>
  );

  return (
    <>
      <DisconnectConfirmationModal
        partner={partner}
        show={showDisconnectModal}
        onClose={toggleShowDisconnectModal}
        onContinue={onDisconnect}
      />
      <ReconnectConfirmationModal
        partner={partner}
        show={showReconnectModal}
        onClose={toggleShowReconnectModal}
        onContinue={navigateToReconnectOAuthFlow}
      />

      <DataSourceBase
        label={label}
        name={name}
        timestamp={timestamp}
        actions={
          <>
            {actionButton}
            {showDisconnectButton && disconnectButton}
          </>
        }
      />
    </>
  );
};

export default OauthDataSourceRow;
