import React from 'react';
import lodash from 'lodash';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import Select from 'react-select';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faUndo, faCheck, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ProfitRoverCancelButton, ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import pagination from '../dashboards/eo/eoPagination';
import Header from '../header/header';
import getFiltersData from '../util/commonFilterCalculations';
import { formatNumber, formatCurrency } from '../util/format';
import { mapCFBProductDimensions } from '../util/mapProductDimensions';
import { KA_API_URL } from '../../config/baseUrl';
import axios from '../../utils/axios-client';

const moment = require('moment');

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 1em;
  margin-left: 16em;
`;

class OverRideCurrentPrice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allLocations: [],
      selectedLocations: null,
      allProductClasses: [],
      selectedProductClasses: null,
      distinctProducts: [],
      selectedDistinctProducts: [],
      visitDate: null,
      onSaleDate: null,
      productEventDatesAndPrices: [],
      finalProductEventDatesAndPrices: [],
      productClassMap: new Map(),
      singleEditProductVisitIdSet: new Set(),
      singleEditProductVisitIdMap: new Map(),
      singleUpdatedProductVisitIdSet: new Set(),
      singleUpdatedProductVisitIdMap: new Map(),
      multiEditProductVisitIdSet: new Set(),
      multiUpdatedProductVisitIdSet: new Set(),
      multiUpdatedProductVisitIdMap: new Map(),
      displayModal: false,
      multiCurrentPrice: '',
      multiOnSaleDate: '',
      error: false,
      errorMessage: '',
      applyAll: false,
      loadingMessage: 'Loading Data',
      multiEditDate: '',
      currentSortProp: '',
      sortObject: {
        location: {
          is_sorted: false,
          direction: 'ASC',
        },
        product_class: {
          is_sorted: false,
          direction: 'ASC',
        },
        product_dimension_value: {
          is_sorted: false,
          direction: 'ASC',
        },
        start_datetime: {
          is_sorted: false,
          direction: 'ASC',
        },
        on_sale_date: {
          is_sorted: false,
          direction: 'ASC',
        },
        ovrd_current_price: {
          is_sorted: false,
          direction: 'ASC',
        },
      },
      defaultSortArray: ['location', 'product_class', 'start_datetime'],
    };
  }

  async componentDidMount() {
    localStorage.removeItem('unsaved_page');

    const filtersData = await getFiltersData('price', 'CFB');
    const map = new Map();
    // eslint-disable-next-line
    filtersData.distinctProductClassesArray.map(productClass => {
      map.set(productClass.value, productClass.label);
    });

    await this.setState({
      allLocations: filtersData.distinctLocationsArray,
      allProductClasses: filtersData.distinctProductClassesArray,
      distinctProducts: filtersData.distinctProductsArray,
      productClassMap: map,
    });

    try {
      const productEventDatesAndPrices = await this.getProductEventDatesAndPrices();
      const finalProductEventDatesAndPrices = lodash.cloneDeep(productEventDatesAndPrices);

      await this.setState({
        productEventDatesAndPrices,
        finalProductEventDatesAndPrices,
      });

      if (productEventDatesAndPrices.length === 0) {
        await this.setState({
          loadingMessage: 'No data to display',
        });
      }
    } catch (err) {
      await this.setState({
        productEventDatesAndPrices: [],
        loadingMessage: 'No data to display',
        finalProductEventDatesAndPrices: [],
      });
    }
  }

  getSortedArray = async data => {
    const { currentSortProp, sortObject, defaultSortArray } = this.state;

    const sortBy = [];

    if (currentSortProp !== '') {
      const { direction } = sortObject[currentSortProp];
      sortBy.push({
        prop: currentSortProp,
        direction: direction === 'ASC' ? 1 : -1,
      });
    }

    const clonedSortArray = lodash.cloneDeep(defaultSortArray);

    let modifiedSortArray = [];
    if (currentSortProp !== '') {
      modifiedSortArray = clonedSortArray.filter(d => d !== currentSortProp);
    } else {
      modifiedSortArray = clonedSortArray;
    }
    modifiedSortArray.forEach(d => {
      sortBy.push({
        prop: d,
        direction: d === 'start_datetime' ? -1 : 1,
      });
    });

    const sortedResult = data.sort((a, b) => {
      let i = 0;
      let result = 0;
      while (i < sortBy.length && result === 0) {
        let aModified = '';
        let bModified = '';
        if (sortBy[i].prop === 'on_sale_date') {
          aModified = a[sortBy[i].prop] !== null ? a[sortBy[i].prop] : '';
          bModified = b[sortBy[i].prop] !== null ? b[sortBy[i].prop] : '';
        } else if (sortBy[i].prop === 'ovrd_current_price') {
          aModified = a[sortBy[i].prop] !== null ? a[sortBy[i].prop] : 0;
          bModified = b[sortBy[i].prop] !== null ? b[sortBy[i].prop] : 0;
        } else {
          aModified = a[sortBy[i].prop];
          bModified = b[sortBy[i].prop];
        }

        result = sortBy[i].direction * (aModified < bModified ? -1 : aModified > bModified ? 1 : 0);
        i += 1;
      }
      return result;
    });

    return sortedResult;
  };

  calculateSortData = async id => {
    const { productEventDatesAndPrices, currentSortProp, sortObject } = this.state;

    if (currentSortProp !== '' && currentSortProp !== id) {
      sortObject[currentSortProp].is_sorted = false;
    }
    if (sortObject[id].is_sorted === false) {
      sortObject[id].is_sorted = true;
      sortObject[id].direction = 'ASC';
    } else if (sortObject[id].direction === 'ASC') {
      sortObject[id].direction = 'DESC';
    } else {
      sortObject[id].direction = 'ASC';
    }

    await this.setState({
      sortObject,
      currentSortProp: id,
    });

    const sortedResult = await this.getSortedArray(productEventDatesAndPrices);

    await this.setState({
      productEventDatesAndPrices: sortedResult,
    });
  };

  filterData = async () => {
    const {
      selectedLocations,
      selectedProductClasses,
      selectedDistinctProducts,
      visitDate,
      onSaleDate,
      productEventDatesAndPrices,
      finalProductEventDatesAndPrices,
    } = this.state;

    let data = lodash.cloneDeep(finalProductEventDatesAndPrices);
    if (selectedLocations !== null && selectedLocations.length !== 0) {
      const locationsSet = new Set();
      selectedLocations.map(item => locationsSet.add(item.label));

      data = data.filter(item => {
        return locationsSet.has(item.location);
      });
    }

    if (selectedProductClasses !== null && selectedProductClasses.length !== 0) {
      const productClassesSet = new Set();
      selectedProductClasses.map(item => productClassesSet.add(item.value));

      data = data.filter(item => {
        return productClassesSet.has(item.product_class);
      });
    }

    if (selectedDistinctProducts !== null && selectedDistinctProducts.length !== 0) {
      const productsSet = new Set();
      selectedDistinctProducts.map(item => productsSet.add(item.value));

      data = data.filter(item => {
        return productsSet.has(item.product_dimension_value);
      });
    }

    if (visitDate !== null) {
      const date = visitDate;
      data = data.filter(item => {
        return item.start_datetime !== null ? item.start_datetime.substring(0, 10) === date : false;
      });
    }

    if (onSaleDate !== null) {
      const date = onSaleDate;
      data = data.filter(item => {
        return item.on_sale_date !== null ? item.on_sale_date.substring(0, 10) === date : false;
      });
    }

    const sortedData = await this.getSortedArray(data);

    await this.setState({
      productEventDatesAndPrices: sortedData,
    });
    if (productEventDatesAndPrices.length === 0) {
      await this.setState({
        loadingMessage: 'No data to display',
      });
    }
  };

  applyFilters = async () => {
    const { singleUpdatedProductVisitIdMap, multiUpdatedProductVisitIdSet } = this.state;
    let confirmed = '';
    if (singleUpdatedProductVisitIdMap.size > 0 || multiUpdatedProductVisitIdSet.size > 0) {
      // eslint-disable-next-line no-alert
      confirmed = window.confirm(
        'You have unsaved changes. Would you like discard changes and apply the current filters?',
      );
    }

    if (confirmed || confirmed === '') {
      await this.filterData();
      await this.setState({
        singleEditProductVisitIdSet: new Set(),
        singleUpdatedProductVisitIdSet: new Set(),
        singleUpdatedProductVisitIdMap: new Map(),
        singleEditProductVisitIdMap: new Map(),
        multiEditProductVisitIdSet: new Set(),
        multiUpdatedProductVisitIdSet: new Set(),
        applyAll: false,
      });
    }

    localStorage.removeItem('unsaved_page');
  };

  handleChange = async (e, propertyName) => {
    let value = '';
    if (propertyName === 'multiOnSaleDate') {
      value = moment(e).format('YYYY-MM-DD');
    } else {
      const { value: targetValue } = e.target;
      value = targetValue;
    }

    await this.setState({
      [propertyName]: value,
    });
  };

  editSingleRow = async (e, row) => {
    const {
      singleEditProductVisitIdSet,
      singleEditProductVisitIdMap,
      singleUpdatedProductVisitIdSet,
      singleUpdatedProductVisitIdMap,
      multiUpdatedProductVisitIdSet,
      multiUpdatedProductVisitIdMap,
    } = this.state;

    const set = singleEditProductVisitIdSet;
    set.add(row.product_visit_id);
    await this.setState({
      singleEditProductVisitIdSet: set,
    });

    const updateSet = singleUpdatedProductVisitIdSet;
    const multiUpdateSet = multiUpdatedProductVisitIdSet;
    const editMap = singleEditProductVisitIdMap;
    let visitMap;

    if (updateSet.has(row.product_visit_id)) {
      visitMap = singleUpdatedProductVisitIdMap.get(row.product_visit_id);
    } else if (multiUpdateSet.has(row.product_visit_id)) {
      visitMap = multiUpdatedProductVisitIdMap.get(row.product_visit_id);
    }

    const updatedVisitMap = {};
    if (visitMap !== undefined) {
      if (visitMap.has('onSaleDate')) {
        updatedVisitMap.onSaleDate = visitMap.onSaleDate;
      }

      if (visitMap.has('currentPrice') && visitMap.currentPrice !== '') {
        updatedVisitMap.currentPrice = visitMap.currentPrice;
      }
      editMap.set(row.product_visit_id, updatedVisitMap);
      updateSet.delete(row.product_visit_id);
      multiUpdateSet.delete(row.product_visit_id);
    }

    const updateMap = singleUpdatedProductVisitIdMap;
    updateMap.delete(row.product_visit_id);

    await this.setState({
      singleEditProductVisitIdMap: editMap,
      singleUpdatedProductVisitIdMap: updateMap,
      singleUpdatedProductVisitIdSet: updateSet,
      multiUpdatedProductVisitIdSet: multiUpdateSet,
    });
  };

  updateSingleRow = async (e, row) => {
    const {
      singleEditProductVisitIdSet,
      singleEditProductVisitIdMap,
      singleUpdatedProductVisitIdSet,
      singleUpdatedProductVisitIdMap,
    } = this.state;

    const visitMap = singleEditProductVisitIdMap.get(row.product_visit_id);
    const updatedVisitMap = {};
    if (visitMap !== undefined) {
      if (visitMap.onSaleDate) {
        updatedVisitMap.onSaleDate = visitMap.onSaleDate;
      }

      if (visitMap.currentPrice && visitMap.currentPrice !== '') {
        updatedVisitMap.currentPrice = visitMap.currentPrice;
      }
    }

    const editMap = singleEditProductVisitIdMap;
    editMap.delete(row.product_visit_id);
    const editSet = singleEditProductVisitIdSet;
    editSet.delete(row.product_visit_id);

    await this.setState({
      singleEditProductVisitIdMap: editMap,
      singleEditProductVisitIdSet: editSet,
    });

    if (Object.keys(updatedVisitMap).length > 0) {
      const set = singleUpdatedProductVisitIdSet;
      set.add(row.product_visit_id);
      const map = singleUpdatedProductVisitIdMap;
      map.set(row.product_visit_id, updatedVisitMap);
      await this.setState({
        singleUpdatedProductVisitIdSet: set,
        singleUpdatedProductVisitIdMap: map,
      });
    }
  };

  handleSingleRowChange = async (e, row, propertyName) => {
    const { singleEditProductVisitIdMap } = this.state;

    const obj = singleEditProductVisitIdMap.get(row.product_visit_id);
    let property = '';
    let value = '';
    if (propertyName === 'onSaleDate') {
      property = propertyName;
      value = moment(e).format('YYYY-MM-DD');
    } else {
      const { name, value: targetValue } = e.target;
      property = name;
      value = targetValue;
    }

    const map = singleEditProductVisitIdMap;

    if (obj === undefined || obj === null) {
      const tempObj = {};
      tempObj[property] = value;
      map.set(row.product_visit_id, tempObj);
    } else {
      obj[property] = value;
      map.set(row.product_visit_id, obj);
    }

    await this.setState({
      singleEditProductVisitIdMap: map,
    });

    localStorage.setItem('unsaved_page', '/overrideCurrentPrices');
  };

  handleSelectChange = async (selectedOption, filterName) => {
    await this.setState({
      [filterName]: selectedOption,
    });
  };

  handleCheck = async (e, row) => {
    const { multiEditProductVisitIdSet } = this.state;
    const set = multiEditProductVisitIdSet;
    if (set.has(row.product_visit_id)) {
      set.delete(row.product_visit_id);
    } else {
      set.add(row.product_visit_id);
    }

    let multiEditDate = '';
    if (set.size !== 0) {
      multiEditDate = new Date(row.start_datetime);
    }

    await this.setState({
      multiEditProductVisitIdSet: set,
      multiEditDate,
    });
  };

  getProductEventDatesAndPrices = async () => {
    const productEventDatesAndPricesResponse = await axios.get(`${KA_API_URL}/productvisit/override`);
    let productEventDatesAndPrices;
    if (productEventDatesAndPricesResponse.data.length > 0) {
      productEventDatesAndPrices = await mapCFBProductDimensions(productEventDatesAndPricesResponse);
      productEventDatesAndPrices = await this.getSortedArray(productEventDatesAndPrices);
    }
    return productEventDatesAndPrices;
  };

  saveUpdates = async e => {
    const { singleUpdatedProductVisitIdMap, multiUpdatedProductVisitIdSet, multiUpdatedProductVisitIdMap } = this.state;

    e.preventDefault();
    const data = [];
    const visitMap = singleUpdatedProductVisitIdMap;
    const visitSet = multiUpdatedProductVisitIdSet;
    const multiUpdatesArray = Array.from(visitSet);

    visitMap.forEach((value, key) => {
      const visit = {};
      const datePriceObj = value;
      visit.product_visit_id = key;
      visit.on_sale_date = datePriceObj.onSaleDate !== undefined ? datePriceObj.onSaleDate : null;
      visit.ovrd_current_price = datePriceObj.currentPrice !== undefined ? parseFloat(datePriceObj.currentPrice) : null;
      data.push(visit);
    });

    for (let i = 0; i < multiUpdatesArray.length; i++) {
      const obj = {};
      obj.product_visit_id = multiUpdatesArray[i];
      obj.on_sale_date =
        multiUpdatedProductVisitIdMap.get(multiUpdatesArray[i]).onSaleDate !== null
          ? multiUpdatedProductVisitIdMap.get(multiUpdatesArray[i]).onSaleDate
          : null;
      obj.ovrd_current_price =
        multiUpdatedProductVisitIdMap.get(multiUpdatesArray[i]).currentPrice !== null
          ? parseFloat(multiUpdatedProductVisitIdMap.get(multiUpdatesArray[i]).currentPrice)
          : null;
      data.push(obj);
    }

    try {
      const response = await axios.put(`${KA_API_URL}/productvisit/override`, data);

      if (response.status === 200) {
        const productEventDatesAndPrices = await this.getProductEventDatesAndPrices();
        const finalProductEventDatesAndPrices = lodash.cloneDeep(productEventDatesAndPrices);
        await this.setState({
          productEventDatesAndPrices,
          finalProductEventDatesAndPrices,
          singleEditProductVisitIdSet: new Set(),
          singleUpdatedProductVisitIdSet: new Set(),
          singleUpdatedProductVisitIdMap: new Map(),
          singleEditProductVisitIdMap: new Map(),
          multiEditProductVisitIdSet: new Set(),
          multiUpdatedProductVisitIdSet: new Set(),
          multiCurrentPrice: '',
          multiOnSaleDate: '',
          applyAll: false,
          error: false,
        });

        if (productEventDatesAndPrices.length === 0) {
          await this.setState({
            loadingMessage: 'No data to display',
          });
        }

        await this.applyFilters();
        localStorage.removeItem('unsaved_page');
      }
    } catch (err) {
      await this.setState({
        singleEditProductVisitIdSet: new Set(),
        singleUpdatedProductVisitIdSet: new Set(),
        singleUpdatedProductVisitIdMap: new Map(),
        singleEditProductVisitIdMap: new Map(),
        multiEditProductVisitIdSet: new Set(),
        multiUpdatedProductVisitIdSet: new Set(),
        multiCurrentPrice: '',
        multiOnSaleDate: '',
        applyAll: false,
        error: true,
        errorMessage: 'Something went wrong. Please try again later.',
      });
      localStorage.removeItem('unsaved_page');
    }
  };

  handleMultiRowChange = async () => {
    const {
      singleUpdatedProductVisitIdSet,
      singleUpdatedProductVisitIdMap,
      multiEditProductVisitIdSet,
      multiUpdatedProductVisitIdSet,
      multiUpdatedProductVisitIdMap,
      displayModal,
      multiCurrentPrice,
      multiOnSaleDate,
    } = this.state;

    const set = multiEditProductVisitIdSet;
    const map = multiUpdatedProductVisitIdMap;

    const currentSet = multiUpdatedProductVisitIdSet;
    const finalSet = new Set();
    const currentSingleSet = singleUpdatedProductVisitIdSet;
    const currentSingleMap = singleUpdatedProductVisitIdMap;
    currentSet.forEach(elem => finalSet.add(elem));

    await this.setState({
      multiEditProductVisitIdSet: new Set(),
    });

    if (multiCurrentPrice !== '' || multiOnSaleDate !== '') {
      set.forEach(elem => {
        finalSet.add(elem);
        map.set(elem, {
          onSaleDate: multiOnSaleDate !== '' ? multiOnSaleDate : null,
          currentPrice: multiCurrentPrice !== '' ? parseFloat(multiCurrentPrice) : null,
        });
        currentSingleMap.delete(elem);
        currentSingleSet.delete(elem);
      });
    }

    await this.setState({
      multiUpdatedProductVisitIdSet: finalSet,
      singleUpdatedProductVisitIdMap: currentSingleMap,
      singleUpdatedProductVisitIdSet: currentSingleSet,
      displayModal: !displayModal,
      multiUpdatedProductVisitIdMap: map,
      applyAll: false,
    });

    localStorage.setItem('unsaved_page', '/overrideCurrentPrices');
  };

  toggleModal = async () => {
    const { displayModal } = this.state;
    await this.setState({
      displayModal: !displayModal,
    });
  };

  handleApplyAll = async () => {
    const { applyAll, productEventDatesAndPrices } = this.state;

    if (applyAll) {
      await this.setState({
        multiEditProductVisitIdSet: new Set(),
        applyAll: !applyAll,
        multiEditDate: '',
      });
    } else {
      const data = productEventDatesAndPrices;
      const set = new Set();
      // eslint-disable-next-line
      data.map(obj => {
        set.add(obj.product_visit_id);
      });
      await this.setState({
        multiEditProductVisitIdSet: set,
        multiEditDate: data.length > 0 ? new Date(data[0].start_datetime) : '',
        applyAll: !applyAll,
      });
    }
  };

  componentDidUpdate = () => {
    const { multiUpdatedProductVisitIdSet, singleUpdatedProductVisitIdSet } = this.state;

    if (multiUpdatedProductVisitIdSet.size > 0 || singleUpdatedProductVisitIdSet.size > 0) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  render() {
    const {
      allLocations,
      selectedLocations,
      allProductClasses,
      selectedProductClasses,
      distinctProducts,
      selectedDistinctProducts,
      productEventDatesAndPrices,
      productClassMap,
      singleEditProductVisitIdSet,
      singleEditProductVisitIdMap,
      singleUpdatedProductVisitIdSet,
      singleUpdatedProductVisitIdMap,
      multiEditProductVisitIdSet,
      multiUpdatedProductVisitIdSet,
      multiUpdatedProductVisitIdMap,
      displayModal,
      multiCurrentPrice,
      multiOnSaleDate,
      error,
      errorMessage,
      applyAll,
      loadingMessage,
      multiEditDate,
      sortObject,
    } = this.state;

    let errorMessageFrag = null;
    if (error) {
      errorMessageFrag = (
        <div
          style={{
            fontSize: '12px',
            color: 'red',
            textAlign: 'center',
            padding: '6px',
          }}
        >
          <h5>{errorMessage}</h5>
        </div>
      );
    }

    return (
      <React.Fragment>
        <React.Fragment>
          <Prompt
            when={multiUpdatedProductVisitIdSet.size > 0 || singleUpdatedProductVisitIdSet.size > 0}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
        <Header />
        <GridWrapper>
          <div id="overide-current-price" style={{ paddingTop: '80px', paddingBottom: '75px' }}>
            <div className="container-fluid">
              <h1 style={{ fontSize: '30px', textAlign: 'center' }}>Product Settings</h1>
              <h5 style={{ textAlign: 'center' }}>Manage Prices & On Sale Dates</h5>
              <div className="row">
                <div className="col-3">
                  <p className="filter-labels" style={{ fontSize: '16px' }}>
                    Location/Business Unit:
                  </p>
                  <Select
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="selectedLocations"
                    value={selectedLocations}
                    onChange={e => this.handleSelectChange(e, 'selectedLocations')}
                    options={allLocations}
                  />
                </div>
                <div className="col-2">
                  <p className="filter-labels" style={{ fontSize: '16px' }}>
                    Product Class:
                  </p>
                  <Select
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="selectedProductClasses"
                    value={selectedProductClasses}
                    onChange={e => this.handleSelectChange(e, 'selectedProductClasses')}
                    options={allProductClasses}
                  />
                </div>
                <div className="col-3">
                  <p className="filter-labels">Product:</p>
                  <Select
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="selectedDistinctProducts"
                    value={selectedDistinctProducts}
                    onChange={e => this.handleSelectChange(e, 'selectedDistinctProducts')}
                    options={distinctProducts}
                  />
                </div>
                <div className="col-2">
                  <p
                    className="filter-labels"
                    style={{ paddingLeft: '7px', textAlign: 'left', paddingBottom: '3px', fontSize: '16px' }}
                  >
                    Event / Visit Date
                  </p>
                  <input
                    name="visitDate"
                    onChange={e => this.handleChange(e, 'visitDate')}
                    style={{ width: '160px', paddingLeft: '5px' }}
                    type="date"
                  />
                </div>
                <div className="col-2">
                  <p className="filter-labels" style={{ paddingBottom: '3px', fontSize: '16px' }}>
                    On Sale Date
                  </p>
                  <input
                    name="onSaleDate"
                    onChange={e => this.handleChange(e, 'onSaleDate')}
                    type="date"
                    style={{ width: '150px' }}
                  />
                </div>
              </div>

              <div style={{ textAlign: 'center', margin: '1vw', display: 'flex', justifyContent: 'center' }}>
                <ProfitRoverPrimaryButton type="submit" onClick={this.applyFilters}>
                  Apply
                </ProfitRoverPrimaryButton>
              </div>

              <br />
              {errorMessageFrag}
              <ReactTable
                data={productEventDatesAndPrices}
                columns={[
                  {
                    Header: (
                      <input
                        type="checkbox"
                        checked={applyAll}
                        style={{ marginTop: '5px' }}
                        onChange={this.handleApplyAll}
                      />
                    ),
                    headerStyle: {
                      height: '40px',
                      background: '#ffc500',
                      fontWeight: 'bold',
                      border: '1px solid white',
                      boxSizing: 'border-box',
                      fontSize: '14px',
                      textAlign: 'left',
                    },
                    id: 'apply_all',
                    accessor: d => d,
                    Cell: row => (
                      <input
                        style={{ textAlign: 'center', verticalAlign: 'middle' }}
                        name="checked"
                        id={row.index}
                        type="checkbox"
                        checked={multiEditProductVisitIdSet.has(row.value.product_visit_id)}
                        onClick={e => this.handleCheck(e, row.value)}
                      />
                    ),
                    sortable: false,
                    resizable: false,
                    width: 25,
                  },
                  {
                    Header: (
                      <div className="row">
                        <div className="col-8" style={{ marginTop: '5px', marginLeft: '15px' }}>
                          {' '}
                          Location / Business Unit
                        </div>
                        <div className="col-1" style={{ marginLeft: '5px' }}>
                          {sortObject.location.is_sorted === true ? (
                            sortObject.location.direction === 'ASC' ? (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                            )
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    ),
                    headerStyle: {
                      fontSize: '14px',
                      height: '40px',
                      background: '#ffc500',
                      fontWeight: 'bold',
                      border: '1px solid white',
                      boxSizing: 'border-box',
                    },
                    id: 'location',
                    accessor: d => d,
                    width: 220,
                    Cell: row => <div style={{ textAlign: 'center' }}>{row.value.location}</div>,
                    sortable: allLocations.length > 1,
                  },
                  {
                    Header: (
                      <div className="row">
                        <div className="col-2" />
                        <div className="col-6" style={{ marginTop: '5px' }}>
                          {' '}
                          Product Class
                        </div>
                        <div className="col-1" style={{ marginLeft: '5px' }}>
                          {sortObject.product_class.is_sorted === true ? (
                            sortObject.product_class.direction === 'ASC' ? (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                            )
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    ),
                    headerStyle: {
                      fontSize: '14px',
                      height: '40px',
                      background: '#ffc500',
                      fontWeight: 'bold',
                      border: '1px solid white',
                      boxSizing: 'border-box',
                    },
                    id: 'product_class',
                    accessor: d => d,
                    width: 170,
                    Cell: row => (
                      <div style={{ textAlign: 'center' }}>
                        {productClassMap.get(row.value.product_class) !== undefined
                          ? productClassMap.get(row.value.product_class)
                          : '-'}
                      </div>
                    ),
                    sortable: true,
                  },
                  {
                    Header: (
                      <div className="row">
                        <div className="col-12" style={{ marginTop: '5px' }}>
                          {' '}
                          Product
                        </div>
                        <div className="col-1" style={{ marginLeft: '12px' }}>
                          {sortObject.product_dimension_value.is_sorted === true ? (
                            sortObject.product_dimension_value.direction === 'ASC' ? (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                            )
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    ),
                    headerStyle: {
                      fontSize: '14px',
                      height: '40px',
                      background: '#ffc500',
                      fontWeight: 'bold',
                      border: '1px solid white',
                      boxSizing: 'border-box',
                    },
                    id: 'product_dimension_value',
                    accessor: d => d,
                    Cell: row => <div style={{ textAlign: 'center' }}>{row.value.product_dimension_value}</div>,
                    sortable: true,
                  },
                  {
                    Header: (
                      <div className="row">
                        <div className="col-8" style={{ marginTop: '5px', marginLeft: '11px' }}>
                          {' '}
                          Event / Visit Date
                        </div>
                        <div className="col-1">
                          {sortObject.start_datetime.is_sorted === true ? (
                            sortObject.start_datetime.direction === 'ASC' ? (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                            )
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    ),
                    headerStyle: {
                      fontSize: '14px',
                      height: '40px',
                      background: '#ffc500',
                      fontWeight: 'bold',
                      border: '1px solid white',
                      boxSizing: 'border-box',
                    },
                    width: 160,
                    id: 'start_datetime',
                    accessor: d => d,
                    Cell: row => <div style={{ textAlign: 'center' }}>{row.value.start_datetime.substring(0, 10)}</div>,
                    sortable: true,
                  },
                  {
                    Header: (
                      <div className="row">
                        <div className="col-2" />
                        <div className="col-5" style={{ marginTop: '5px', marginLeft: '7px' }}>
                          {' '}
                          On Sale Date
                        </div>
                        <div className="col-1">
                          {sortObject.on_sale_date.is_sorted === true ? (
                            sortObject.on_sale_date.direction === 'ASC' ? (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                            )
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    ),
                    headerStyle: {
                      fontSize: '14px',
                      height: '40px',
                      background: '#ffc500',
                      fontWeight: 'bold',
                      border: '1px solid white',
                      boxSizing: 'border-box',
                    },
                    id: 'on_sale_date',
                    accessor: d => d,
                    width: 190,
                    Cell: row => (
                      <div
                        style={{
                          textAlign: 'center',
                          backgroundColor:
                            (singleUpdatedProductVisitIdSet.has(row.value.product_visit_id) &&
                              singleUpdatedProductVisitIdMap.get(row.value.product_visit_id).onSaleDate !==
                                undefined) ||
                            (multiUpdatedProductVisitIdSet.has(row.value.product_visit_id) &&
                              multiUpdatedProductVisitIdMap.get(row.value.product_visit_id).onSaleDate !== null)
                              ? 'rgb(254,255,203)'
                              : '',
                        }}
                      >
                        {multiUpdatedProductVisitIdSet.has(row.value.product_visit_id) &&
                        multiUpdatedProductVisitIdMap.get(row.value.product_visit_id).onSaleDate !== null ? (
                          multiUpdatedProductVisitIdMap.get(row.value.product_visit_id).onSaleDate
                        ) : singleUpdatedProductVisitIdSet.has(row.value.product_visit_id) &&
                          singleUpdatedProductVisitIdMap.get(row.value.product_visit_id).onSaleDate !== undefined ? (
                          singleUpdatedProductVisitIdMap.get(row.value.product_visit_id).onSaleDate
                        ) : singleEditProductVisitIdSet.has(row.value.product_visit_id) ? (
                          <DatePicker
                            selected={
                              row.value.on_sale_date
                                ? new Date(row.value.on_sale_date)
                                : new Date(row.value.start_datetime)
                            }
                            value={
                              singleEditProductVisitIdSet.has(row.value.product_visit_id) &&
                              singleEditProductVisitIdMap.get(row.value.product_visit_id) !== undefined &&
                              singleEditProductVisitIdMap.get(row.value.product_visit_id).onSaleDate !== undefined
                                ? singleEditProductVisitIdMap.get(row.value.product_visit_id).onSaleDate
                                : row.value.on_sale_date
                                ? row.value.on_sale_date.substring(0, 10)
                                : 'MM/DD/YYYY'
                            }
                            onChange={e => this.handleSingleRowChange(e, row.value, 'onSaleDate')}
                          />
                        ) : row.value.on_sale_date !== null ? (
                          row.value.on_sale_date.substring(0, 10)
                        ) : (
                          '-'
                        )}
                      </div>
                    ),
                    sortable: true,
                  },
                  {
                    Header: (
                      <div className="row">
                        <div className="col-8" style={{ marginTop: '5px', marginLeft: '15px' }}>
                          {' '}
                          Current / Latest Price
                        </div>
                        <div className="col-1">
                          {sortObject.ovrd_current_price.is_sorted === true ? (
                            sortObject.ovrd_current_price.direction === 'ASC' ? (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                            )
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    ),
                    headerStyle: {
                      fontSize: '14px',
                      height: '40px',
                      background: '#ffc500',
                      fontWeight: 'bold',
                      border: '1px solid white',
                      boxSizing: 'border-box',
                    },
                    id: 'ovrd_current_price',
                    accessor: d => d,
                    width: 200,
                    Cell: row => (
                      <div
                        style={{
                          textAlign: 'center',
                          backgroundColor:
                            (multiUpdatedProductVisitIdSet.has(row.value.product_visit_id) &&
                              multiUpdatedProductVisitIdMap.get(row.value.product_visit_id).currentPrice !== null) ||
                            (singleUpdatedProductVisitIdSet.has(row.value.product_visit_id) &&
                              singleUpdatedProductVisitIdMap.get(row.value.product_visit_id).currentPrice !== undefined)
                              ? 'rgb(254,255,203)'
                              : '',
                        }}
                      >
                        {multiUpdatedProductVisitIdSet.has(row.value.product_visit_id) &&
                        multiUpdatedProductVisitIdMap.get(row.value.product_visit_id).currentPrice !== null ? (
                          formatCurrency(
                            multiUpdatedProductVisitIdMap.get(row.value.product_visit_id).currentPrice,
                            true,
                          )
                        ) : singleUpdatedProductVisitIdSet.has(row.value.product_visit_id) &&
                          singleUpdatedProductVisitIdMap.get(row.value.product_visit_id).currentPrice !== undefined ? (
                          singleUpdatedProductVisitIdMap.get(row.value.product_visit_id).currentPrice
                        ) : singleEditProductVisitIdSet.has(row.value.product_visit_id) ? (
                          <input
                            name="currentPrice"
                            defaultValue={
                              singleEditProductVisitIdSet.has(row.value.product_visit_id) &&
                              singleEditProductVisitIdMap.get(row.value.product_visit_id) !== undefined &&
                              singleEditProductVisitIdMap.get(row.value.product_visit_id).currentPrice !== undefined
                                ? singleEditProductVisitIdMap.get(row.value.product_visit_id).currentPrice
                                : row.value.ovrd_current_price
                                ? row.value.ovrd_current_price
                                : ''
                            }
                            onChange={e => this.handleSingleRowChange(e, row.value, 'currentprice')}
                            type="number"
                            style={{ width: '130px' }}
                          />
                        ) : row.value.ovrd_current_price !== null ? (
                          formatCurrency(row.value.ovrd_current_price, true)
                        ) : (
                          '-'
                        )}
                      </div>
                    ),
                    sortable: true,
                  },
                  {
                    Header: (
                      <div value="actions" style={{ marginTop: '5px' }}>
                        Actions
                      </div>
                    ),
                    headerStyle: {
                      height: '40px',
                      background: '#ffc500',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      border: '1px solid white',
                      boxSizing: 'border-box',
                    },
                    id: 'setting_actions',
                    accessor: d => d,
                    Cell: row => (
                      <div>
                        {singleEditProductVisitIdSet.has(row.value.product_visit_id) ? (
                          <FontAwesomeIcon
                            className="Icon"
                            name="editClose"
                            icon={faCheck}
                            style={{ width: '30px' }}
                            onClick={e => this.updateSingleRow(e, row.value)}
                          />
                        ) : (
                          <FontAwesomeIcon
                            name="editOpen"
                            className="Icon"
                            icon={faEdit}
                            style={{ width: '30px' }}
                            onClick={e => this.editSingleRow(e, row.value)}
                          />
                        )}
                        <FontAwesomeIcon icon={faUndo} className="Icon" style={{ width: '30px' }} disabled />
                      </div>
                    ),
                    width: 80,
                    sortable: false,
                    resizable: false,
                  },
                ]}
                getTheadThProps={(state, rowInfo, column) => {
                  return {
                    onClick: () => {
                      if (column.sortable) {
                        this.calculateSortData(column.id);
                      }
                    },
                  };
                }}
                getTdProps={(state, rowInfo) => {
                  if (typeof rowInfo !== 'undefined') {
                    return {};
                  }
                  return {};
                }}
                getTrProps={() => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (handleOriginal) {
                        handleOriginal();
                      }
                    },
                    style: {
                      background: '#e8eef6',
                    },
                  };
                }}
                defaultPageSize={7}
                pageSizeOptions={[7, 10, 20, 25, 50, 100]}
                noDataText={loadingMessage}
                showPagination
                PaginationComponent={pagination}
                className="-highlight"
              />
            </div>
            <br />
            <div className="row">
              <div className="col-4" />
              <div className="col-2">
                <ProfitRoverPrimaryButton
                  disabled={singleUpdatedProductVisitIdMap.size === 0 && multiUpdatedProductVisitIdSet.size === 0}
                  onClick={this.saveUpdates}
                >
                  Save Changes
                </ProfitRoverPrimaryButton>
              </div>
              <div className="col-2">
                <ProfitRoverPrimaryButton disabled={multiEditProductVisitIdSet.size === 0} onClick={this.toggleModal}>
                  Edit Multiple
                </ProfitRoverPrimaryButton>
              </div>
            </div>
            <Modal show={displayModal} style={{ marginTop: '15vw', marginLeft: '2vw' }}>
              <div className="modal-content modal-content-centered">
                <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px' }}>
                  Edit {formatNumber(multiEditProductVisitIdSet.size)} Selected Products
                </p>
                <br />
                <div className="row">
                  <div className="col-6">
                    <span
                      style={{
                        margin: '3%',
                        marginLeft: '2em',
                        fontSize: '18px',
                      }}
                    >
                      On Sale Date:
                    </span>
                  </div>
                  <div className="col-5">
                    <DatePicker
                      selected={multiEditDate}
                      value={multiOnSaleDate}
                      onChange={e => this.handleChange(e, 'multiOnSaleDate')}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <span
                      style={{
                        margin: '3%',
                        marginLeft: '2em',
                        fontSize: '18px',
                      }}
                    >
                      Current / Latest Price: &nbsp;
                    </span>
                  </div>
                  <div className="col-5">
                    <input
                      name="multiCurrentPrice"
                      value={multiCurrentPrice}
                      onChange={e => this.handleChange(e, 'multiCurrentPrice')}
                      style={{ width: 210 }}
                    />
                  </div>
                </div>
                <br />
                <div
                  style={{
                    textAlign: 'center',
                    marginBottom: '1%',
                  }}
                >
                  <ProfitRoverPrimaryButton onClick={this.handleMultiRowChange}>Ok</ProfitRoverPrimaryButton>
                  &nbsp;
                  <ProfitRoverCancelButton onClick={this.toggleModal}>Cancel</ProfitRoverCancelButton>
                </div>
              </div>
            </Modal>
          </div>
        </GridWrapper>
      </React.Fragment>
    );
  }
}

export default connect(null, {})(OverRideCurrentPrice);
