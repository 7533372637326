import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCircle } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ProfitRoverHeaderLogo } from '../generic/ProfitRoverLogos';
import { HEADER_ICON_ID } from '../notificationCenter/NotificationCenter';
import { NOTIFICATION_TYPE_BY_PAYLOAD_TYPE } from '../notificationCenter/NotificationCenterConstants';
import { toggleIsOpen } from '../../redux/notificationCenter/actions';
import { gaEmitHelpLinkClick } from '../../google-analytics/header';
import './Header.css';

const Header = props => {
  const {
    menuItem,
    isAuthenticated,
    isOpen,
    toggleNotificationCenterIsOpen,
    notifications,
    headerSubText,
    warning,
  } = props;

  const unreadAlerts = notifications.some(
    notification => !notification.read && NOTIFICATION_TYPE_BY_PAYLOAD_TYPE.ALERTS.includes(notification.payload_type),
  );
  const unreadInfo = notifications.some(
    notification => !notification.read && NOTIFICATION_TYPE_BY_PAYLOAD_TYPE.INFO.includes(notification.payload_type),
  );
  const showUnreadIcon = unreadAlerts || unreadInfo;

  const onHelpLinkClick = () => {
    gaEmitHelpLinkClick();
  };

  return (
    <nav
      className="navbar fixed-top navbar-expand navbar-dark justify-content-between"
      style={{ paddingRight: 3, minHeight: 59.6 }}
    >
      <div className="d-flex align-items-center w-100">
        <Link className="navbar-brand" to="/login-redirect">
          <ProfitRoverHeaderLogo className="profitrover-apps-header-logo" />
        </Link>
        {headerSubText ? (
          <div className="header-sub-text-container">
            <span className="vertical-separator" />
            <div className="nav-bar-header-sub-text">{headerSubText}</div>
          </div>
        ) : (
          warning && <div className="header-warning-container">{warning}</div>
        )}
      </div>

      {menuItem}

      {isAuthenticated && (
        <span className="d-flex">
          <ul className="navbar-nav mr-auto" style={{ flexDirection: 'row', alignItems: 'center' }}>
            <li className="nav-item active">
              <Link className="nav-link" to="/help" onClick={onHelpLinkClick} style={{ marginRight: '20px' }}>
                Help
              </Link>
            </li>
            <li className="nav-item dropdown active">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ marginRight: '20px' }}
              >
                Account
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to="/logout">
                  Sign Out
                </Link>
              </div>
            </li>
          </ul>

          <button id={HEADER_ICON_ID} type="button" onClick={toggleNotificationCenterIsOpen} style={{ margin: 3 }}>
            <span className="notification-bell-stack fa-layers fa-stack">
              <FontAwesomeIcon className={classnames('notification-bell-bg', { open: isOpen })} icon={faCircle} />
              <FontAwesomeIcon className="notification-bell" transform={{ rotate: 45 }} icon={faBell} />
              {showUnreadIcon && (
                <div
                  className={classnames('unread-notifications-icon', {
                    alerts: unreadAlerts,
                    'notification-info': unreadInfo,
                  })}
                >
                  !
                </div>
              )}
            </span>
          </button>
        </span>
      )}
    </nav>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth?.isAuthenticated,
  isOpen: state.notificationCenter?.isOpen,
  notifications: state.notificationCenter?.notifications,
});

const mapDispatchToProps = {
  toggleNotificationCenterIsOpen: toggleIsOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
