import React from 'react';
import { ProfitRoverLogoIcon } from '../generic/ProfitRoverLogos';

const ProfitRoverAppsTitle = () => {
  return (
    <div className="components-container">
      <ProfitRoverLogoIcon className="profitrover-logo" />
    </div>
  );
};

export default ProfitRoverAppsTitle;
