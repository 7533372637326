import axios from 'axios';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { ONE_DAY_IN_MILLISECONDS } from '../constants/cacheTimes';
import { KDL_API_URL } from '../../../config/baseUrl';

/**
 * Requests
 */

const fetchHolidaysForCountry = async (countryCode, { startYear, endYear } = {}) =>
  axios.get(`${KDL_API_URL}/holiday/countries/${countryCode}`, {
    params: { start_year: startYear, end_year: endYear },
  });

const fetchHolidaysForState = async (countryCode, stateCode, { startYear, endYear } = {}) =>
  axios.get(`${KDL_API_URL}/holiday/countries/${countryCode}/${stateCode}`, {
    params: { start_year: startYear, end_year: endYear },
  });

/**
 * Hooks
 */

const HOLIDAYS_FOR_ALL_LOCATIONS_QUERY_KEY_BASE = 'kdlHolidaysForAllLocations';

// eslint-disable-next-line import/prefer-default-export
export const useHolidaysForAllLocations = (locations, { startYear, endYear } = {}) => {
  return useQuery(
    [HOLIDAYS_FOR_ALL_LOCATIONS_QUERY_KEY_BASE, startYear, endYear],
    async () => {
      const countryCodes = new Set(locations.map(location => location.country_code));
      const stateCodes = new Set(locations.map(location => [location.country_code, location.state].join('/')));
      const promises = [];

      countryCodes.forEach(countryCode => {
        if (countryCode) {
          promises.push(fetchHolidaysForCountry(countryCode, { startYear, endYear }));
        }
      });

      stateCodes.forEach(s => {
        const [countryCode, stateCode] = s.split('/');
        if (countryCode && stateCode) {
          promises.push(fetchHolidaysForState(countryCode, stateCode, { startYear, endYear }));
        }
      });

      const responses = await Promise.allSettled(promises);

      const datasets = responses
        .filter(promiseResult => promiseResult.status === 'fulfilled')
        .map(promiseResult => promiseResult.value)
        .map(({ data }) => data ?? []);

      return _.flatten(datasets);
    },
    { staleTime: ONE_DAY_IN_MILLISECONDS },
  );
};
