import React from 'react';
import './settings-page-wrapper.scss';

/**
 * For as long as we render the settings pages' layout "incorrectly", we can use this
 * component as a container for any settings page.
 *
 * It attempts to serve as a generic container that doesn't interfere or overlap with the
 * <Header> or <Sidebar> components.
 */
const SettingsPageWrapper = ({ children, style }) => {
  return (
    <div className="settings-page-wrapper" style={style}>
      {children}
    </div>
  );
};

export default SettingsPageWrapper;
