export default class DimValueMapping {
  constructor(rawDimValue) {
    this.data = rawDimValue;
    this.modifications = {};

    this.isEditing = false;
  }

  /**
   * Supports creating a new mapping from an existing one. This is intended to support
   * the use case in React where you're not supposed to mutate state values directly.
   * Instead, if you want to modify a value, you can copy the original value with this
   * method and mutate the copy before setting the new React state.
   */
  static from(dimValueMapping) {
    const copy = { ...dimValueMapping };
    Object.setPrototypeOf(copy, DimValueMapping.prototype);

    return copy;
  }

  isBeingEdited() {
    return this.isEditing;
  }

  setIsEditing(isEditing) {
    if (typeof isEditing === 'boolean') {
      this.isEditing = isEditing;
    }
  }

  resetToOriginal() {
    this.modifications = {};
    this.setIsEditing(false);
  }

  modificationIsPresent(key) {
    if (this.modifications[key] != null) {
      // Modification exists, so check to see if it's different from the original value,
      // as the user may have modified it but then set it back to the original value
      return this.modifications[key] !== this.data[key];
    }

    // No modification was present for this key, so this key's value must be unchanged
    return false;
  };

  isEdited() {
    if (Object.keys(this.modifications).length === 0) {
      return false;
    }

    const changeDetected = ['is_ignore', 'product_dimension_value_id'].some(key => this.modificationIsPresent(key));

    return changeDetected;
  }

  /**
   * Data accessors/setters
   */

  getValue() {
    return this.data.value;
  }

  getIsIgnore() {
    const modificationIsPresent = this.modifications.is_ignore != null;
    return modificationIsPresent ? this.modifications.is_ignore : this.data.is_ignore;
  }

  setIsIgnore(isIgnore) {
    this.modifications.is_ignore = isIgnore;
  }

  isIgnoreIsEdited() {
    return this.modificationIsPresent('is_ignore');
  }

  getDimensionId() {
    return this.data.product_dimension_id;
  }

  getDimensionValueId() {
    const modificationIsPresent = this.modifications.product_dimension_value_id != null;
    return modificationIsPresent ? this.modifications.product_dimension_value_id : this.data.product_dimension_value_id;
  }

  setDimensionValueId(dimensionValueId) {
    this.modifications.product_dimension_value_id = dimensionValueId;
  }

  dimensionValueIdIsEdited() {
    return this.modificationIsPresent('product_dimension_value_id');
  }

  buildPutBodyRepresentation() {
    if (this.isEdited()) {
      return {
        value: this.getValue(),
        dimension_id: this.getDimensionId(),
        is_ignore: this.getIsIgnore(),
        old_dimension_value_id: this.data.product_dimension_value_id,
        new_dimension_value_id: this.getDimensionValueId(),
      };
    }

    const msg =
      'Developer Error: Should not build a PUT representation for a raw dimension value that has not been changed';
    throw new Error(msg);
  }
}
