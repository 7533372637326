import React from 'react';
import Select from 'react-select';
import moment from 'moment-timezone';
import { Field, useField, useForm } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import ConfigAccordionItem from './ConfigAccordionItem';
import { useConfigTableContext } from './configTableContext';
import { useInitialTransactionDateAccordion } from '../fieldInitializers';
import { useTimeZoneOptions } from '../../data-center/datasetConfig/pages/commonPages/TransactionDatePage';

const DEFAULT_NAME = 'transaction-accordion';
const DEFAULT_TITLE = 'Transaction Date';
const DEFAULT_UUID = 'TRANSACTION';

/**
 * Validates string representation of a date is in ISO 8601 format.
 * Reference: https://en.wikipedia.org/wiki/ISO_8601
 */
const isValidDateValue = dateText => {
  return moment(dateText, 'YYYY-MM-DD', true).isValid();
};

const TransactionDateAccordionItem = ({ title = DEFAULT_TITLE, uuid = DEFAULT_UUID, name = DEFAULT_NAME }) => {
  const { columnNameOptions = [] } = useConfigTableContext();

  const {
    initialFormState,
    checkboxName,
    timeCheckBoxName,
    columnName,
    fixedValue,
    timezone,
    datasetFieldIdName,
  } = useInitialTransactionDateAccordion(name);
  useField(datasetFieldIdName, { initialValue: initialFormState.datasetFieldId });

  const timeZoneOptions = useTimeZoneOptions();

  const formApi = useForm();
  const formState = formApi.getState();

  const data = formState.values[name];
  const isTimeInData = data?.checkbox_time;
  const isFieldInData = data?.checkbox;

  const errors = formState.errors[name] ?? {};

  const borderDividerClass = isTimeInData ? 'field-dotted-divider' : null;
  const hasTimeSelected = isTimeInData ? formState.values[name]?.timezone != null : true;
  const hasFixedValueInput = !isFieldInData ? formState.values[name]?.fixedValue != null : true;
  const isComplete = Object.entries(errors).every(([, value]) => {
    return value == null;
  });

  return (
    <ConfigAccordionItem title={title} uuid={uuid} valid={isComplete && hasTimeSelected && hasFixedValueInput}>
      <div className="dataset-accordion-field-container">
        <div className="accordion-field-container-row">
          <div className="field-content-container">
            <div className={`field-details-container ${borderDividerClass}`}>
              <Field name={checkboxName} type="checkbox" initialValue={initialFormState.checkbox}>
                {props => <input className="mx-2" {...props.input} />}
              </Field>

              <div className="accordion-field-description">Exists in my dataset</div>
            </div>
            {isFieldInData && (
              <div className="field-details-container">
                <Field name={timeCheckBoxName} type="checkbox" initialValue={initialFormState.timeCheckbox}>
                  {props => {
                    return <input className="mx-2" {...props.input} />;
                  }}
                </Field>
                <div className="accordion-field-description">Also includes Time</div>
              </div>
            )}
          </div>
        </div>
        <div style={{ height: '75px', width: '1px', border: '.5px solid black', margin: '10px', opacity: '30%' }} />
        <div className="accordion-field-container-row">
          <div className="field-content-container">
            <div className={`field-selection-column-container ${borderDividerClass}`}>
              {isFieldInData ? (
                <Field
                  name={columnName}
                  initialValue={initialFormState.columnName}
                  validate={(value, allValues) => {
                    const val = allValues[name]?.fromColumn;
                    return val == null ? FORM_ERROR : undefined;
                  }}
                  isEqual={(a, b) => a?.label === b?.label}
                >
                  {props => (
                    <>
                      <span className="field-select-text">Column containing {DEFAULT_TITLE}:</span>
                      <Select
                        className="field-input-select"
                        options={columnNameOptions}
                        onChange={selectedValue => {
                          props.input.onChange(selectedValue);
                        }}
                        placeholder="Start typing..."
                        value={props?.input?.value}
                      />
                    </>
                  )}
                </Field>
              ) : (
                <>
                  <span className="field-select-text">Please give this date a fixed value:</span>
                  <Field
                    name={fixedValue}
                    type="input"
                    initialValue={initialFormState.fixedValue}
                    validate={(value, allValues) => {
                      const val = allValues[name]?.fixedValue;
                      const isValidDateString = isValidDateValue(val);
                      return !isValidDateString || val == null ? FORM_ERROR : undefined;
                    }}
                  >
                    {props => <input className="mx-2, field-input-select" {...props.input} placeholder="YYYY-MM-DD" />}
                  </Field>
                </>
              )}
            </div>
            <div className="field-selection-column-container">
              {isTimeInData && (
                <Field
                  name={timezone}
                  initialValue={initialFormState.timezone}
                  validate={(value, allValues) => {
                    const val = allValues[name]?.fromColumn;
                    return val == null ? FORM_ERROR : undefined;
                  }}
                >
                  {props => (
                    <>
                      <div className="field-time-zone-text">
                        <span>
                          In which <strong>Time Zone</strong> is your data recorded?
                        </span>
                      </div>
                      <Select
                        options={timeZoneOptions}
                        className="field-input-select"
                        onChange={selectedValue => {
                          props.input.onChange(selectedValue);
                        }}
                        placeholder="Select an option..."
                        value={props?.input?.value}
                      />
                    </>
                  )}
                </Field>
              )}
            </div>
          </div>
        </div>
      </div>
    </ConfigAccordionItem>
  );
};

export default TransactionDateAccordionItem;
