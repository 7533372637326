import React from 'react';

const cfbHeader = props => {
  const {
    logoLoaded,
    homeSchoolRank,
    homeTeamLogo,
    errorMessage,
    workflowId,
  } = props;

  return (
    <div>
      {logoLoaded ? (
        <div>
          <div
            id="qr"
            style={{
              display: 'inline-block',
              align: 'center',
              fontSize: '22px',
              fontWeight: 'bold',
            }}
          >
            {homeSchoolRank !== '' ? <span style={{ fontSize: '22px' }}> #{homeSchoolRank}&nbsp;</span> : <div />}
            {homeTeamLogo !== null && homeTeamLogo !== undefined && homeTeamLogo !== '' ? (
              <img src={homeTeamLogo} alt={localStorage.getItem('team')} height="70px" align="middle" />
            ) : (
              <div>{localStorage.getItem('team')}</div>
            )}
          </div>
          <div
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              fontSize: '32px',
              fontWeight: 'bold',
              marginBottom: '5px',
            }}
          >
            &nbsp; Football Tickets Dashboard
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            fontSize: '32px',
            fontWeight: 'bold',
            marginTop: '10px',
            marginBottom: '15px',
          }}
        >
          &nbsp; Football Tickets Dashboard
        </div>
      )}
      <div />
      {errorMessage}
      <p>
        <span
          className="btn"
          style={{
            border: '1px solid black',
            backgroundColor: 'rgb(0,175,79,0.7)',
          }}
        >
          <span
            style={{
              cursor: 'pointer',
            }}
          >
            <a
              href={`/cfbkaizenprice/${workflowId}`}
              style={{
                color: 'black',
              }}
            >
              {' '}
              KaizenPrice
            </a>{' '}
          </span>{' '}
        </span>
        &nbsp;
        <span
          className="btn"
          style={{
            border: '1px solid black',
          }}
        >
          <span style={{ cursor: 'pointer' }}>
            <a
              href={`/cfbkaizenexplore/${workflowId}`}
              style={{
                color: 'black',
              }}
            >
              KaizenExplore
            </a>
          </span>
        </span>
      </p>
    </div>
  );
};

export default cfbHeader;
