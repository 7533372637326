import React, { useRef } from 'react';
import * as d3 from 'd3';
import { ERROR, FONT_BLACK, SUCCESS_GREEN } from '../../../../../colors';

const MARGIN = {
  TOP: 10,
  RIGHT: 60,
  LEFT: 0,
  BOTTOM: 0,
};

const PADDING = 0;
const TICK_SPACING = 70;
const BAR_HEIGHT = 30;
const BAR_SPACING = 24;

const BreakdownPlot = ({ data, width, height, formatNumber }) => {
  const svgRef = useRef();

  React.useLayoutEffect(() => {
    const svg = d3.select(svgRef.current);
    const innerWidth = width - MARGIN.LEFT - MARGIN.RIGHT;

    // Calculate the total height needed based on the number of bars
    const totalHeight = data.length * (BAR_HEIGHT + BAR_SPACING) + MARGIN.TOP + MARGIN.BOTTOM;

    // Update the height of the SVG
    svg.attr('height', totalHeight);

    const innerHeight = totalHeight - MARGIN.TOP - MARGIN.BOTTOM;

    // Clear previous plot
    svg.selectAll('*').remove();

    const maxValue = Math.max(d3.max(data.map(d => d.diff)), 0);
    const minValue = Math.min(d3.min(data.map(d => d.diff)), 0);

    const xScale = d3
      .scaleLinear()
      .domain([minValue, maxValue * 1.1])
      .range([0, innerWidth]);

    const yScale = d3
      .scaleBand()
      .domain(data.map(d => d.name))
      .range([0, innerHeight])
      .padding(PADDING);

    const g = svg.append('g').attr('transform', `translate(${MARGIN.LEFT},${MARGIN.TOP})`);

    // Add vertical lines
    const zeroPosition = xScale(0);
    const numLinesRight = Math.floor((innerWidth - zeroPosition) / TICK_SPACING);
    const numLinesLeft = Math.floor(zeroPosition / TICK_SPACING);

    const xValuesRight = Array.from({ length: numLinesRight }, (_, i) => zeroPosition + (i + 1) * TICK_SPACING);
    const xValuesLeft = Array.from({ length: numLinesLeft }, (_, i) => zeroPosition - (i + 1) * TICK_SPACING);

    const xValues = [...xValuesLeft, zeroPosition, ...xValuesRight];

    g.selectAll('.vertical-line')
      .data(xValues)
      .enter()
      .append('line')
      .attr('class', 'vertical-line')
      .attr('x1', d => d)
      .attr('y1', 0)
      .attr('x2', d => d)
      .attr('y2', innerHeight)
      .style('stroke', '#ccc')
      .style('stroke-width', 1);

    const bars = g
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', d => `translate(0,${yScale(d.name) + BAR_SPACING / 2})`);

    bars
      .append('rect')
      .attr('x', d => Math.min(xScale(0), xScale(d.diff)))
      .attr('y', 0)
      .attr('width', d => Math.abs(xScale(d.diff) - xScale(0)))
      .attr('height', BAR_HEIGHT)
      .style('fill', d => (d.diff >= 0 ? SUCCESS_GREEN : ERROR));

    bars
      .append('text')
      .attr('x', d => (d.diff >= 0 ? xScale(d.diff) : xScale(0)))
      .attr('dx', '0.3em')
      .attr('y', BAR_HEIGHT / 2)
      .attr('dy', '0.35em')
      .style('text-anchor', 'start')
      .text(d => formatNumber(d.diff))
      .style('fill', d => (d.diff >= 0 ? FONT_BLACK : ERROR))
      .style('font-family', 'URWDIN')
      .style('font-weight', '400')
      .style('font-size', '16px');
  }, [data, width, height, formatNumber]);

  return (
    <div style={{ position: 'relative', overflow: 'visible' }}>
      <svg ref={svgRef} width={width} height={height} />
    </div>
  );
};

export default BreakdownPlot;
