/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ActionButton, CancelButton, DropdownSelect, TextInput } from '../../../commonFormComponents';
import { useCreatableDimensionTypesForIndustry } from '../../../../../accountSettings/dimensionSettingsHooks';
import { ProfitRoverSecondaryButton } from '../../../../../forms/ProfitRoverButtons';
import { tryCreateDimension } from '../../../../../util/mapProductDimensions';
import './dimensionSelection.css';

const DimensionSelector = props => {
  const { dimOptions = [], onClickCreateDim, onSelectDimension } = props;

  return (
    <>
      <div className="ds-config-dim-selection-prompt">
        <strong>Select a Dimension</strong>
      </div>
      <ul>
        {/* TODO: Maybe pass dimensions instead of dimOptions */}
        {dimOptions.map(dimOption => {
          const onSelectDimensionHandler = () => onSelectDimension(dimOption.value);

          const onKeyDown = event => {
            if (event.key === 'Enter') {
              onSelectDimensionHandler();
            }
          };
          return (
            <li key={dimOption.value.product_dimension_id}>
              <div tabIndex="0" role="button" onClick={onSelectDimensionHandler} onKeyDown={onKeyDown}>
                {dimOption.label}
              </div>
            </li>
          );
        })}
      </ul>
      <hr className="dim-selection-divider" />
      <button className="ds-config-dim-selection-create-new-dim" type="button" onClick={onClickCreateDim}>
        <FontAwesomeIcon icon={faPlus} color="green" style={{ marginRight: 5 }} />
        Add New Dimension
      </button>
    </>
  );
};

const mapDimTypeToOption = dimType => {
  return dimType == null ? undefined : { label: dimType.description, value: dimType.dimension_type };
};

const DimensionCreator = props => {
  const { loading, dimensionTypes, onCancel, onSelectDimension } = props;

  const [name, setName] = React.useState('');
  const [dimType, setDimType] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState();
  const [isPersistingDimension, setIsPersistingDimension] = React.useState(false);

  const selectableDimTypeOptions = dimensionTypes.map(mapDimTypeToOption);

  const dimensionNameIsValid = name !== '';
  const dimensionTypeSelected = dimType != null;
  const canSave = dimensionNameIsValid && dimensionTypeSelected;

  const onClickSave = async () => {
    setIsPersistingDimension(true);
    setErrorMessage(false);

    if (name !== '' && dimType != null) {
      try {
        // Create the dimension
        const { data, errors } = await tryCreateDimension(name, dimType);

        if (Array.isArray(errors)) {
          setErrorMessage(errors[0].reason);
        } else if (data && Array.isArray(data) && data.length === 1) {
          // Select it so the user can continue on to select a column or fixed value for this newly created dimension
          onSelectDimension(data[0]);
        }
      } catch (error) {
        // Failed to create the dimension for network reasons
        setErrorMessage('Failed to create dimension. Please try again.');
      }
    }

    setIsPersistingDimension(false);
  };

  return (
    <>
      <ProfitRoverSecondaryButton
        type="button"
        onClick={onCancel}
        style={{
          marginBottom: 15,
          background: 'none',
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          padding: 0,
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: 5 }} />
        <span style={{ fontSize: 14 }}>Back</span>
      </ProfitRoverSecondaryButton>
      <div style={{ marginBottom: 10 }}>
        <strong>Create New Dimension</strong>
      </div>

      {!loading && (
        <form>
          <label>
            <strong>Name</strong>
          </label>
          <TextInput
            style={{ width: '100%', borderRadius: 4, border: '1px solid #A3A3A3' }}
            onChange={e => {
              setErrorMessage(undefined);
              setName(e.target.value);
            }}
          />

          <label>
            <strong>Type</strong>
          </label>
          <DropdownSelect
            placeholder="-- Select a dimension type --"
            options={selectableDimTypeOptions}
            onSelect={option => {
              setErrorMessage(undefined);
              setDimType(option);
            }}
            value={dimType}
            style={{ maxWidth: '100%' }}
          />
        </form>
      )}

      {/* TODO: Make this red and show a warning symbol  */}
      {errorMessage != null && <div>{errorMessage}</div>}

      {isPersistingDimension ? (
        <div>
          <i>Saving dimension...</i>
        </div>
      ) : (
        <ActionButton disabled={!canSave} onClick={onClickSave} style={{ marginBottom: 10, marginTop: 15 }}>
          Save and Select
        </ActionButton>
      )}
    </>
  );
};

const DimensionSelection = props => {
  const { dimOptions, onSelectDimension, onCancelAddingDimension, industry, refetchDimensions } = props;
  const [creatingDimension, setCreatingDimension] = React.useState(false);

  const { loading, dimensionTypes } = useCreatableDimensionTypesForIndustry(industry);

  const onClickCreateDim = () => {
    setCreatingDimension(true);
  };

  return (
    <div className="ds-config-dim-selection-container">
      {!creatingDimension && <CancelButton onClick={onCancelAddingDimension} />}

      {!creatingDimension ? (
        <div style={{ paddingBottom: 13, paddingTop: 24, paddingLeft: 23, paddingRight: 5 }}>
          <DimensionSelector
            onClickCreateDim={onClickCreateDim}
            dimOptions={dimOptions}
            onCancelAddingDimension={onCancelAddingDimension}
            onSelectDimension={onSelectDimension}
          />
        </div>
      ) : (
        <div style={{ padding: '20px 25px', maxWidth: 350 }}>
          <DimensionCreator
            loading={loading}
            dimensionTypes={dimensionTypes}
            onSelectDimension={value => {
              onSelectDimension(value);

              // When the user creates a dimension, we want to update the known list of dimensions
              // so they user can't get in a state where they can't see the dimension they created
              refetchDimensions();
            }}
            onCancel={() => setCreatingDimension(false)}
          />
        </div>
      )}
    </div>
  );
};

export default DimensionSelection;
