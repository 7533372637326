import React from 'react';
import _ from 'lodash';
import { LocationActivationStatus } from '../../generic/subscriptions/SubscriptionConstants';
import { useLocations } from '../../../data-access/query/locations';
import { useNaicsCodes } from '../../../data-access/query/naics';

const { ACTIVE, PENDING_ACTIVATION, PENDING_DEACTIVATION, INACTIVE } = LocationActivationStatus;
export const STATUSES_THAT_APPEAR_AS_ACTIVE = [ACTIVE, PENDING_ACTIVATION];

/**
 * If the location has a non-nullish "stagedStatus", it should overshadow (in the UI) what is
 * currently in the database so that any staged changes made by the user are accurately reflected.
 */
export const getApparentStatus = (stagedStatus, status) => {
  return stagedStatus ?? status;
};

export const computeNextStagedStatus = (status, stagedStatus) => {
  const apparentStatus = getApparentStatus(stagedStatus, status);

  const appearsAsActive = STATUSES_THAT_APPEAR_AS_ACTIVE.includes(apparentStatus);

  if (appearsAsActive) {
    if (status === ACTIVE) {
      // User is attempting to deactivate this currently active location
      return PENDING_DEACTIVATION;
    }

    if (status === PENDING_ACTIVATION) {
      // Shouldn't be possible to interact with a location whose database status is "PENDING_ACTIVATION"
      return undefined;
    }

    if (status === PENDING_DEACTIVATION) {
      /**
       * User has toggled this location to "active", but has changed their mind and is now returning the
       * state back to what it was originally
       */
      return undefined;
    }

    if (status === INACTIVE) {
      // We are returning the apparent state back to the current database status
      return undefined;
    }
  } else {
    if (status === ACTIVE) {
      // We are returning the apparent state back to the current database status
      return undefined;
    }

    if (status === PENDING_ACTIVATION) {
      // Shouldn't be possible to interact with a location whose database status is "PENDING_ACTIVATION"
      return undefined;
    }

    if (status === PENDING_DEACTIVATION) {
      // User is attempting to re-activate this location that is currently pending deactivation
      return PENDING_ACTIVATION;
    }

    if (status === INACTIVE) {
      // User is attempting to activate this currently inactive location
      return PENDING_ACTIVATION;
    }
  }

  // Developer Note: This is a defensive fallback value, but this branch should never occur in practice
  return apparentStatus;
};

/**
 * This is normally NOT a "best practice" or advanced pattern, and should not be replicated
 * anywhere else unless ABSOLUTELY NECESSARY. In this case, the AG Grid Table does not behave
 * with React-like semantics, as the React implementation is simply a wrapper around the
 * grid library, which is written in Vanilla Javascript. As such, whenever we make updates to
 * the data in the table, no re-rendering occurs at the top level, which makes it impossible
 * for the page to "observe" the staged changes in the table so that we can render the
 * "Checkout Panel" or accurately display the number of pending changes. Thus, this Hook serves
 * as an escape hatch to trigger a re-render at the top level so that we can use the Grid API
 * to inspect the user's changes in the table for the aforementioned purposes.
 */
const useForceUpdate = () => {
  const [, setState] = React.useState();

  const forceUpdate = React.useCallback(() => setState({}), []);

  return forceUpdate;
};

const useGridApi = () => {
  const [gridApi, setGridApi] = React.useState();

  const onGridReady = React.useCallback(params => {
    const { api } = params;
    setGridApi(api);

    api.sizeColumnsToFit();
  }, []);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (gridApi != null) {
      const updateTable = () => gridApi.sizeColumnsToFit();

      window.addEventListener('resize', updateTable);
      return () => window.removeEventListener('resize', updateTable);
    }
  }, [gridApi]);

  return { gridApi, onGridReady };
};

export const useLocationsWithSubscriptionStatus = subscriptionId => {
  const { data: locations = [], isLoading: isLoadingLocations } = useLocations();
  const { data: naicsCodes = [], isLoading: isLoadingNaicsCodes } = useNaicsCodes();
  const naicsCodesKeyedByCode = React.useMemo(() => _.keyBy(naicsCodes, 'naicsKaCode'), [naicsCodes]);

  const locationsWithSubscriptionInfo = React.useMemo(() => {
    return locations.map(location => {
      const industryDescription = naicsCodesKeyedByCode[location.naicsKaCode]?.description;
      const { status_by_subscription_id: statusBySubscriptionId } = location;
      const locationStatus = statusBySubscriptionId[subscriptionId];

      const subscriptionStatus = locationStatus?.status ?? INACTIVE;
      const isFreeLocation = locationStatus?.is_free_location ?? false;

      const row = {
        ...location,
        industry_description: industryDescription,
        status: subscriptionStatus,
        isFreeLocation,
      };
      delete row.status_by_subscription_id;

      return row;
    });
  }, [locations, naicsCodesKeyedByCode, subscriptionId]);

  const hasPendingDeactivations = locationsWithSubscriptionInfo.some(loc => loc.status === PENDING_DEACTIVATION);

  return {
    isLoading: isLoadingLocations || isLoadingNaicsCodes,
    locations: locationsWithSubscriptionInfo,
    hasPendingDeactivations,
  };
};

export const useLocationTableState = subscriptionId => {
  const { gridApi, onGridReady } = useGridApi();
  const { data: locations = [], isLoading: isLoadingLocations } = useLocations();
  const { data: naicsCodes = [], isLoading: isLoadingNaicsCodes } = useNaicsCodes();
  const naicsCodesKeyedByCode = React.useMemo(() => _.keyBy(naicsCodes, 'naicsKaCode'), [naicsCodes]);

  const forceUpdate = useForceUpdate();

  const isDataReady = !isLoadingNaicsCodes && !isLoadingLocations;

  const initialRowData = React.useMemo(() => {
    return locations.map(location => {
      const industryDescription = naicsCodesKeyedByCode[location.naicsKaCode]?.description;
      const { status_by_subscription_id: statusBySubscriptionId } = location;
      const locationStatus = statusBySubscriptionId[subscriptionId];

      const subscriptionStatus = locationStatus?.status ?? INACTIVE;
      const isFreeLocation = locationStatus?.is_free_location ?? false;

      const row = {
        ...location,
        industry_description: industryDescription,
        status: subscriptionStatus,
        isFreeLocation,
      };
      delete row.status_by_subscription_id;

      return row;
    });
  }, [locations, naicsCodesKeyedByCode, subscriptionId]);

  const currentRowData = [];
  if (gridApi != null) {
    gridApi.forEachNode(node => {
      currentRowData.push(node.data);
    });
  }

  const resetStatusChanges = () => {
    gridApi.forEachNode(rowNode => {
      delete rowNode.data.stagedStatus;
      rowNode.setData(rowNode.data);
    });

    forceUpdate();
  };

  /**
   * The <CheckoutPanel> is only concerned with "staged changes" the user has made
   */
  const stagedLocationsPendingActivation = currentRowData.filter(
    location => location.stagedStatus === PENDING_ACTIVATION,
  );
  const stagedLocationsPendingDeactivation = currentRowData.filter(
    location => location.stagedStatus === PENDING_DEACTIVATION,
  );

  const hasStagedChanges = stagedLocationsPendingActivation.length > 0 || stagedLocationsPendingDeactivation.length > 0;

  const numberOfActiveLocations = initialRowData.filter(location => {
    const { status } = location;

    return STATUSES_THAT_APPEAR_AS_ACTIVE.includes(status);
  }).length;

  return {
    gridApi,
    onGridReady,
    forceUpdate,
    resetTableState: resetStatusChanges,
    numberOfActiveLocations,
    isDataReady,
    initialRowData,
    stagedLocationsPendingActivation,
    stagedLocationsPendingDeactivation,
    hasStagedChanges,
  };
};
