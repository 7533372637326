import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { ProfitRoverPrimaryButton } from '../../../forms/ProfitRoverButtons';
import { KaizenProductStatus } from '../../../generic/subscriptions/SubscriptionConstants';
import ProfitRoverSpinner from '../../../spinner/ProfitRoverSpinner';

const PAST_DUE_TEXT = `Recent payment failed. Please log into Shopify to resolve the failed payment.`;
const ONE_LOCATION_TRIAL_TEXT = 'You’re all set! You will be automatically charged when your free trial ends.';

const ShopifyStartPlanBanner = ({ onClick, disabled, subscriptionStatus, totalLocations, trialDaysRemaining }) => {
  const isTrialing = subscriptionStatus === KaizenProductStatus.TRIALING;
  const isPastDue = subscriptionStatus === KaizenProductStatus.PAST_DUE;
  const hasMoreThanOneLocation = totalLocations > 1;

  const END_TRIAL_TEXT = `\
  Your paid plan will automatically start in ${
    trialDaysRemaining > 1 ? `${trialDaysRemaining} days` : `${trialDaysRemaining} day`
  }. \
  Want to start your plan today so you can activate more locations?\
`;

  let text;
  if (isTrialing) {
    text = hasMoreThanOneLocation ? END_TRIAL_TEXT : ONE_LOCATION_TRIAL_TEXT;
  } else if (isPastDue) {
    text = PAST_DUE_TEXT;
  }

  return (
    <div className={`${isTrialing ? 'end-trial-early-box' : 'trial-has-expired'}`}>
      <span className="unlock-text">{text}</span>
      {isTrialing && hasMoreThanOneLocation && (
        <ProfitRoverPrimaryButton className="end-trial-early-button" onClick={onClick} disabled={disabled}>
          Start My Plan Today{' '}
          {disabled ? (
            <ProfitRoverSpinner className="ml-2" />
          ) : (
            <FontAwesomeIcon icon={faCaretRight} style={{ marginLeft: '0.5rem' }} />
          )}
        </ProfitRoverPrimaryButton>
      )}
    </div>
  );
};

export default ShopifyStartPlanBanner;
