import React from 'react';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProfitRoverPrimaryButton, ProfitRoverCancelButton } from '../../forms/ProfitRoverButtons';
import ProfitRoverSpinner from '../../spinner/ProfitRoverSpinner';
import './checkout-panel.css';

const BigNumber = ({ amount }) => <span className="big-number">{amount}</span>;

const CheckoutPanel = ({ numActivating = 0, numDeactivating = 0, onContinue, onCancel, isLoading }) => {
  return (
    <footer className="checkout-panel-container">
      <div className="checkout-panel">
        <div className="shopping-cart-container">
          <FontAwesomeIcon icon={faShoppingCart} />
        </div>
        {numActivating > 0 && (
          <div className="changed-status">
            Activating <BigNumber amount={numActivating} /> Location(s)
          </div>
        )}
        {numDeactivating > 0 && (
          <div className="changed-status">
            Deactivating <BigNumber amount={numDeactivating} /> Location(s)
          </div>
        )}
        <div className="action-buttons">
          <ProfitRoverPrimaryButton small onClick={onContinue} disabled={isLoading}>
            Continue
          </ProfitRoverPrimaryButton>
          <ProfitRoverCancelButton small onClick={onCancel} disabled={isLoading}>
            Cancel
          </ProfitRoverCancelButton>
          {isLoading && <ProfitRoverSpinner />}
        </div>
      </div>
    </footer>
  );
};

export default CheckoutPanel;
