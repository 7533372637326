/* eslint-disable no-await-in-loop */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import axios from 'axios';
import lodash from 'lodash';
import EventFilters from './CustomEventsFilters';
import EventModal from './CustomEventsModal';
import CustomEventsTable from './CustomEventsTable';
import NAVIGATION_SEPARATOR from '../settingsNavigationMenuConstants';
import SettingsNavigationMenu from '../SettingsNavigationMenu';
import SettingsPageWrapper from '../SettingsPageWrapper';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import Header from '../../header/header';
import { KA_API_URL } from '../../../config/baseUrl';
import {
  gaEmitAddNewCustomEventCheckmarkClick,
  gaEmitDeleteSelectedButtonClick,
  gaEmitSaveChangesButtonClick,
  gaEmitUpdateCustomEventCheckmarkClick,
} from '../../../google-analytics/customEventSettings';
import './CustomEvents.css';

const moment = require('moment');

class CustomEvents extends Component {
  state = {
    customerLocations: [],
    locationsMap: new Map(),
    customEvents: [],
    finalCustomEvents: [],
    customEventsTypes: [],
    customEventsTypesMap: new Map(),
    allFilterLocations: [],
    allFilterImpactTypes: [],
    selectedLocations: null,
    selectedImpactTypes: null,
    eventDescriptionValue: '',
    focusedInput: null,
    filterStartDate: null,
    filterEndDate: null,
    updateCustomEventsMap: new Map(),
    multiDescription: '',
    multiImpactType: '',
    multiStartDate: '',
    multiEndDate: '',
    dataAvailable: true,
    error: false,
    modalError: false,
    errorMessage: '',
    singleRowEditSet: new Set(),
    singleRowUpdateMap: new Map(),
    multiRowEditMap: new Map(),
    openEditModal: false,
    isAllSelected: false,
    multiRowDeleteSet: new Set(),
    openFooter: false,
    defaultSortArray: ['location_description', 'event_start_date', 'event_end_date'],
    currentSortProp: '',
    newLocationId: '',
    newDescription: '',
    newImpactType: '',
    newStartDate: '',
    newEndDate: '',
    createEventsArray: [],
    sortObject: {
      location_description: {
        is_sorted: false,
        direction: 'ASC',
      },
      description: {
        is_sorted: false,
        direction: 'ASC',
      },
      event_impact_type_description: {
        is_sorted: false,
        direction: 'ASC',
      },
      event_start_date: {
        is_sorted: false,
        direction: 'ASC',
      },
      event_end_date: {
        is_sorted: false,
        direction: 'ASC',
      },
      is_closed: {
        is_sorted: false,
        direction: 'ASC',
      },
    },
  };

  async componentDidMount() {
    await this.renderTableAndFilters();
  }

  calculateSortData = async id => {
    const { currentSortProp, sortObject, customEvents } = this.state;
    if (currentSortProp !== '' && currentSortProp !== id) {
      sortObject[currentSortProp].is_sorted = false;
    }
    if (sortObject[id].is_sorted === false) {
      sortObject[id].is_sorted = true;
      sortObject[id].direction = 'ASC';
    } else if (sortObject[id].direction === 'ASC') {
      sortObject[id].direction = 'DESC';
    } else {
      sortObject[id].direction = 'ASC';
    }

    await this.setState({
      sortObject,
      currentSortProp: id,
    });

    const sortedCustomEvents = await this.getSortedArray(customEvents);

    await this.setState({
      customEvents: sortedCustomEvents,
    });
  };

  getSortedArray = async data => {
    const { currentSortProp, sortObject, defaultSortArray } = this.state;
    const sortBy = [];

    if (currentSortProp !== '') {
      const { direction } = sortObject[currentSortProp];
      sortBy.push({
        prop: currentSortProp,
        direction: direction === 'ASC' ? 1 : -1,
      });
    }

    const clonedSortArray = lodash.cloneDeep(defaultSortArray);
    let modifiedSortArray = [];
    if (currentSortProp !== '') {
      modifiedSortArray = clonedSortArray.filter(d => d !== currentSortProp);
    } else {
      modifiedSortArray = clonedSortArray;
    }
    modifiedSortArray.forEach(d => {
      sortBy.push({
        prop: d,
        direction: d === 'event_start_date' || d === 'event_end_date' ? -1 : 1,
      });
    });

    const sortedResult = data.sort((a, b) => {
      let i = 0;
      let result = 0;
      while (i < sortBy.length && result === 0) {
        const aModified = a[sortBy[i].prop];
        const bModified = b[sortBy[i].prop];

        result = sortBy[i].direction * (aModified < bModified ? -1 : aModified > bModified ? 1 : 0);
        i += 1;
      }
      return result;
    });

    return sortedResult;
  };

  renderTableAndFilters = async () => {
    try {
      const customEventsResponse = await axios.get(`${KA_API_URL}/customevents`);
      const customEventsTypesResponse = await axios.get(`${KA_API_URL}/customevents/types`);
      const customEventsTypes = customEventsTypesResponse.data;

      const locationsResponse = await axios.get(`${KA_API_URL}/locations`);

      const locationsMap = new Map();
      const customEventsTypesMap = new Map();
      const locations = locationsResponse.data;

      customEventsTypes.map(customEvent => {
        customEventsTypesMap.set(customEvent.event_impact_type, customEvent.description);
        return true;
      });
      locations.map(location => {
        locationsMap.set(location.location_id, location.location_description);
        return true;
      });

      const customEvents = customEventsResponse.data;

      customEvents.map(event => {
        event.event_impact_type_description = customEventsTypesMap.get(event.event_impact_type);

        return true;
      });

      const sortedCustomEvents = await this.getSortedArray(customEvents);
      const finalCustomEvents = lodash.cloneDeep(sortedCustomEvents);

      await this.setState({
        customEvents: sortedCustomEvents,
        finalCustomEvents,
        customEventsTypesMap,
        locationsMap,
        customEventsTypes,
        customerLocations: locations,
        dataAvailable: customEvents.length > 0,
      });
      this.calculateFilters(customEvents);
    } catch (err) {
      await this.setState({
        error: true,
        errorMessage: 'Problem fetching price rules. Please try later',
      });
    }
  };

  calculateFilters = async events => {
    const locationsSet = new Set();
    const impactTypesSet = new Set();
    const locations = [];
    const impactTypes = [];

    events.forEach(event => {
      if (!locationsSet.has(event.location_id)) {
        locations.push({ value: event.location_id, label: event.location_description });
        locationsSet.add(event.location_id);
      }

      if (!impactTypesSet.has(event.event_impact_type)) {
        impactTypes.push({ value: event.event_impact_type, label: event.event_impact_type_description });
        impactTypesSet.add(event.event_impact_type);
      }
    });

    locations.sort((a, b) => (a.label > b.label ? 1 : -1));
    impactTypes.sort((a, b) => (a.label > b.label ? 1 : -1));

    await this.setState({
      allFilterLocations: locations,
      allFilterImpactTypes: impactTypes,
    });
  };

  handleFilterChange = async (selectedOption, propName) => {
    await this.setState({
      [propName]: selectedOption,
    });
  };

  handleEventDescriptionChange = async e => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFocusedInput = async focusedInput => {
    await this.setState({
      focusedInput,
    });
  };

  handleDatesChange = async ({ startDate, endDate }) => {
    await this.setState({
      filterStartDate: startDate,
      filterEndDate: endDate,
    });
  };

  applyFilters = async e => {
    e.preventDefault();
    const { finalCustomEvents, selectedLocations, selectedImpactTypes, eventDescriptionValue } = this.state;

    let { filterStartDate, filterEndDate } = this.state;

    let customEvents = lodash.cloneDeep(finalCustomEvents);

    if (selectedLocations !== null && selectedLocations.length !== 0) {
      const locationsSet = new Set();
      selectedLocations.map(item => locationsSet.add(item.value));

      customEvents = customEvents.filter(item => {
        return locationsSet.has(item.location_id);
      });
    }

    if (selectedImpactTypes !== null && selectedImpactTypes.length !== 0) {
      const impactTypesSet = new Set();
      selectedImpactTypes.map(item => impactTypesSet.add(item.value));

      customEvents = customEvents.filter(item => {
        return impactTypesSet.has(item.event_impact_type);
      });
    }

    if (eventDescriptionValue !== '') {
      const query = eventDescriptionValue.toLowerCase();
      customEvents = customEvents.filter(item => {
        return item.description.toLowerCase().includes(query);
      });
    }

    if (filterStartDate != null) {
      filterStartDate = moment(filterStartDate._d).format('YYYY-MM-DD');
      customEvents = customEvents.filter(item => {
        return item.event_start_date >= filterStartDate;
      });
    }

    if (filterEndDate != null) {
      filterEndDate = moment(filterEndDate._d).format('YYYY-MM-DD');
      customEvents = customEvents.filter(item => {
        return item.event_end_date <= filterEndDate;
      });
    }

    const sortedCustomEvents = await this.getSortedArray(customEvents);

    await this.setState({
      customEvents: sortedCustomEvents,
      dataAvailable: customEvents.length > 0,
    });
  };

  openSingleRow = async (e, data) => {
    e.preventDefault();
    const { singleRowEditSet } = this.state;
    singleRowEditSet.add(data.event_id);

    await this.setState({
      singleRowEditSet,
    });
  };

  handleSingleEditEventChange = async (e, data, dateFilterType, date) => {
    const { singleRowUpdateMap } = this.state;

    let editObject = {};
    if (singleRowUpdateMap.get(data.original.event_id)) {
      editObject = singleRowUpdateMap.get(data.original.event_id);
    }

    if (e) {
      if (e.target.name === 'editDescription') {
        editObject.description = e.target.value;
      } else if (e.target.name === 'closedEvent') {
        editObject.is_closed = e.target.checked;
      } else {
        editObject.event_impact_type = e.target.value;
      }
    } else if (dateFilterType === 'editStartDate') {
      editObject.event_start_date = moment(date).format('YYYY-MM-DD');
    } else {
      editObject.event_end_date = moment(date).format('YYYY-MM-DD');
    }

    singleRowUpdateMap.set(data.original.event_id, editObject);
    await this.setState({
      singleRowUpdateMap,
    });
  };

  updateSingleRow = async (e, data) => {
    gaEmitUpdateCustomEventCheckmarkClick();
    e.preventDefault();
    const { singleRowUpdateMap, singleRowEditSet } = this.state;

    if (singleRowUpdateMap.get(data.event_id)) {
      const editObject = singleRowUpdateMap.get(data.event_id);
      let isEmptyValue = false;

      Object.keys(editObject).forEach(key => {
        if (editObject[key] === '' || editObject[key] == null) {
          isEmptyValue = true;
        }
      });

      if (isEmptyValue) {
        await this.setState({
          error: true,
          errorMessage: 'Please fill all the fields',
        });
        return;
      }

      const { updateCustomEventsMap } = this.state;
      updateCustomEventsMap.set(data.event_id, editObject);

      await this.setState({
        updateCustomEventsMap,
        error: false,
        errorMessage: '',
      });
    }

    singleRowEditSet.delete(data.event_id);

    await this.setState({
      singleRowEditSet,
    });
  };

  handleCheck = async data => {
    const { multiRowEditMap, multiRowDeleteSet } = this.state;
    if (multiRowEditMap.get(data.event_id)) {
      multiRowEditMap.delete(data.event_id);
      multiRowDeleteSet.delete(data.event_id);
    } else {
      multiRowEditMap.set(data.event_id, data);
      if (data.event_id !== null) {
        multiRowDeleteSet.add(data.event_id);
      }
    }

    await this.setState({
      multiRowEditMap,
      multiRowDeleteSet,
    });
  };

  toggleModal = async () => {
    const { openEditModal: modal } = this.state;
    await this.setState({
      openEditModal: !modal,
      multiDescription: '',
      multiImpactType: '',
      multiStartDate: '',
      multiEndDate: '',
      modalError: false,
    });
  };

  handleMultiEditEventChange = async (e, dateFilterType, date) => {
    if (e) {
      await this.setState({
        [e.target.name]: e.target.value,
      });
    } else {
      await this.setState({
        [dateFilterType]: moment(date).format('YYYY-MM-DD'),
      });
    }
  };

  updateMultipleRows = async () => {
    const {
      multiDescription,
      multiImpactType,
      multiStartDate,
      multiEndDate,
      updateCustomEventsMap,
      multiRowEditMap,
      singleRowUpdateMap,
    } = this.state;
    const editObject = {};

    if (multiDescription === '' && multiImpactType === '' && multiStartDate === '' && multiEndDate === '') {
      return;
    }

    if (multiEndDate !== '' && multiEndDate < multiStartDate) {
      await this.setState({
        modalError: true,
        errorMessage: 'End Date should be greater than Start Date.',
      });
      return;
    }
    if (multiDescription !== '') {
      editObject.description = multiDescription;
    }

    if (multiImpactType !== '') {
      editObject.event_impact_type = multiImpactType;
    }

    if (multiStartDate !== '') {
      editObject.event_start_date = multiStartDate;
    }

    if (multiEndDate !== '') {
      editObject.event_end_date = multiEndDate;
    }

    const multiCustomEventsArray = Array.from(multiRowEditMap.keys());

    multiCustomEventsArray.forEach(id => {
      singleRowUpdateMap.set(id, lodash.cloneDeep(editObject));
      updateCustomEventsMap.set(id, lodash.cloneDeep(editObject));
    });

    await this.setState({
      updateCustomEventsMap,
      singleRowUpdateMap,
      multiRowEditMap: new Map(),
      multiRowDeleteSet: new Set(),
      multiDescription: '',
      multiImpactType: '',
      multiStartDate: '',
      multiEndDate: '',
      modalError: false,
      errorMessage: '',
      openEditModal: false,
      isAllSelected: false,
    });
  };

  applyAll = async () => {
    const { customEvents, isAllSelected: selected, multiRowEditMap, multiRowDeleteSet } = this.state;

    if (selected) {
      customEvents.forEach(data => {
        multiRowEditMap.delete(data.event_id);
        multiRowDeleteSet.delete(data.event_id);
      });
    } else {
      customEvents.forEach(data => {
        multiRowEditMap.set(data.event_id, data);
        if (data.event_id !== null) {
          multiRowDeleteSet.add(data.event_id);
        }
      });
    }

    await this.setState({
      isAllSelected: !selected,
      multiRowEditMap,
      multiRowDeleteSet,
    });
  };

  handleNewEventChange = async (e, data, dateFilterType, date) => {
    if (e) {
      if (e.target.name === 'newIsClosed') {
        await this.setState({
          [e.target.name]: e.target.checked,
        });
      } else {
        await this.setState({
          [e.target.name]: e.target.value,
        });
      }
    } else {
      await this.setState({
        [dateFilterType]: moment(date).format('YYYY-MM-DD'),
      });
    }
  };

  addNewEvent = async () => {
    gaEmitAddNewCustomEventCheckmarkClick();

    const {
      newLocationId,
      newDescription,
      newImpactType,
      newStartDate,
      newEndDate,
      newIsClosed,
      createEventsArray,
      customEvents,
      locationsMap,
      customEventsTypesMap,
    } = this.state;

    if (
      newLocationId === '' ||
      newDescription === '' ||
      newImpactType === '' ||
      newStartDate === '' ||
      newEndDate === ''
    ) {
      await this.setState({
        error: true,
        errorMessage: 'Please fill all the fields.',
      });
      return;
    }

    if (newEndDate !== '' && newEndDate < newStartDate) {
      await this.setState({
        error: true,
        errorMessage: 'End Date should be greater than Start Date.',
      });
      return;
    }

    const newEvent = {
      event_id: null,
      location_id: newLocationId,
      location_description: locationsMap.get(Number(newLocationId)),
      description: newDescription,
      event_impact_type: newImpactType,
      event_impact_type_description: customEventsTypesMap.get(newImpactType),
      event_start_date: newStartDate,
      event_end_date: newEndDate === '' ? null : newEndDate,
      is_closed: newIsClosed,
    };

    const tempCustomEvents = lodash.cloneDeep(customEvents);
    const tempCreateEventsArray = lodash.cloneDeep(createEventsArray);

    tempCreateEventsArray.push(newEvent);
    tempCustomEvents.push(newEvent);

    await this.setState({
      createEventsArray: tempCreateEventsArray,
      customEvents: tempCustomEvents,
      openFooter: false,
      newIsClosed: false,
    });
  };

  saveChanges = async () => {
    try {
      gaEmitSaveChangesButtonClick();
      const { updateCustomEventsMap, createEventsArray } = this.state;
      const customEventsArray = Array.from(updateCustomEventsMap.values());
      const customEventsKeys = Array.from(updateCustomEventsMap.keys());

      let i = 0;
      customEventsKeys.forEach(key => {
        customEventsArray[i].event_id = key;
        i += 1;
      });

      if (customEventsArray.length > 0) {
        await axios.put(`${KA_API_URL}/customevents`, customEventsArray);
      }

      if (createEventsArray.length > 0) {
        for (let j = 0; j < createEventsArray.length; j++) {
          const event = createEventsArray[j];
          const finalEvent = {
            location_id: Number(event.location_id),
            description: event.description,
            event_impact_type: event.event_impact_type,
            event_start_date: event.event_start_date,
            event_end_date: event.event_end_date,
            is_closed: event.is_closed,
          };

          await axios.post(`${KA_API_URL}/customevents`, finalEvent);
        }

        await this.setState({
          createEventsArray: [],
        });
      }

      await this.renderTableAndFilters();
      await this.setState({
        error: false,
        errorMessage: '',
        multiRowEditMap: new Map(),
        multiRowDeleteSet: new Set(),
        singleRowEditSet: new Set(),
        singleRowUpdateMap: new Map(),
        openFooter: false,
        updateCustomEventsMap: new Map(),
        isAllSelected: false,
      });
    } catch (err) {
      await this.setState({
        error: true,
        errorMessage: 'There was a problem saving the events. Please try later',
      });
    }
  };

  deleteSingle = async rowData => {
    if (rowData.event_id !== null) {
      try {
        const eventId = [rowData.event_id];

        await axios.delete(`${KA_API_URL}/customevents`, {
          data: eventId,
        });

        await this.renderTableAndFilters();
        await this.setState({
          error: false,
          errorMessage: '',
          multiRowEditMap: new Map(),
          multiRowDeleteSet: new Set(),
          singleRowEditSet: new Set(),
          singleRowUpdateMap: new Map(),
          updateCustomEventsMap: new Map(),
          isAllSelected: false,
        });
      } catch (err) {
        await this.setState({
          error: true,
          errorMessage: 'There was a problem deleting the event. Please try later',
        });
      }
    }
  };

  deleteMultiple = async () => {
    try {
      gaEmitDeleteSelectedButtonClick();
      const { multiRowDeleteSet } = this.state;
      const eventIds = Array.from(multiRowDeleteSet);

      await axios.delete(`${KA_API_URL}/customevents`, {
        data: eventIds,
      });

      await this.renderTableAndFilters();
      await this.setState({
        error: false,
        errorMessage: '',
        multiRowEditMap: new Map(),
        multiRowDeleteSet: new Set(),
        singleRowEditSet: new Set(),
        singleRowUpdateMap: new Map(),
        updateCustomEventsMap: new Map(),
        isAllSelected: false,
      });
    } catch (err) {
      await this.setState({
        error: true,
        errorMessage: 'There was a problem deleting the events. Please try later',
      });
    }
  };

  toggleFooterInputs = async () => {
    const { openFooter: footer } = this.state;
    await this.setState({
      openFooter: !footer,
      newDescription: '',
      newLocationId: '',
      newStartDate: '',
      newEndDate: '',
      newImpactType: '',
      modalError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      customerLocations,
      customEvents,
      allFilterLocations,
      allFilterImpactTypes,
      customEventsTypes,
      customEventsTypesMap,
      selectedLocations,
      selectedImpactTypes,
      eventDescriptionValue,
      filterStartDate,
      filterEndDate,
      focusedInput: focusedInputState,
      singleRowEditSet,
      singleRowUpdateMap,
      error,
      errorMessage,
      updateCustomEventsMap,
      dataAvailable,
      multiRowEditMap,
      openEditModal,
      multiStartDate,
      multiEndDate,
      modalError,
      isAllSelected,
      openFooter,
      newStartDate,
      newEndDate,
      createEventsArray,
      multiRowDeleteSet,
      sortObject,
    } = this.state;

    let errorDiv = null;
    if (error) {
      errorDiv = (
        <div
          style={{
            fontSize: '12px',
            color: 'red',
            textAlign: 'center',
            padding: '6px',
          }}
        >
          <h5>{errorMessage}</h5>
        </div>
      );
    }
    return (
      <HeaderAwarePage>
        <Header />
        <SettingsNavigationMenu label={`Settings ${NAVIGATION_SEPARATOR} Custom Events`} tabsId={['settings']} />
        <SettingsPageWrapper>
          <div className="container">
            <h3 className="settings-header">Custom Events</h3>
            <div className="w-100">
              <EventFilters
                locationValue={selectedLocations}
                locationOptions={allFilterLocations}
                impactTypeValue={selectedImpactTypes}
                impactTypeOptions={allFilterImpactTypes}
                eventDescriptionValue={eventDescriptionValue}
                filterStartDate={filterStartDate}
                filterEndDate={filterEndDate}
                handleFilterChange={this.handleFilterChange}
                handleEventDescriptionChange={this.handleEventDescriptionChange}
                applyFilters={this.applyFilters}
                onDatesChange={this.handleDatesChange}
                focusedInput={focusedInputState}
                onFocusChange={focusedInput => this.handleFocusedInput(focusedInput)}
              />
              <br />
              {errorDiv}
              <CustomEventsTable
                customerLocations={customerLocations}
                data={customEvents}
                dataAvailable={dataAvailable}
                openSingleRow={this.openSingleRow}
                singleRowEditSet={singleRowEditSet}
                handleSingleEditEventChange={this.handleSingleEditEventChange}
                singleRowUpdateMap={singleRowUpdateMap}
                updateSingleRow={this.updateSingleRow}
                customEventsTypesMap={customEventsTypesMap}
                customEventsTypes={customEventsTypes}
                multiRowEditMap={multiRowEditMap}
                handleCheck={this.handleCheck}
                applyAll={this.applyAll}
                deleteSingle={this.deleteSingle}
                isAllSelected={isAllSelected}
                openFooter={openFooter}
                toggleFooterInputs={this.toggleFooterInputs}
                handleNewEventChange={this.handleNewEventChange}
                newStartDate={newStartDate}
                newEndDate={newEndDate}
                addNewEvent={this.addNewEvent}
                sortClick={this.calculateSortData}
                sortObject={sortObject}
                updateCustomEventsMap={updateCustomEventsMap}
                createEventsArray={createEventsArray}
                multiRowDeleteSet={multiRowDeleteSet}
                saveChanges={this.saveChanges}
                toggleModal={this.toggleModal}
                deleteMultiple={this.deleteMultiple}
              />

              <EventModal
                openEditModal={openEditModal}
                toggleModal={this.toggleModal}
                multiRowEditMap={multiRowEditMap}
                customEventsTypes={customEventsTypes}
                handleMultiEditEventChange={this.handleMultiEditEventChange}
                multiStartDate={multiStartDate}
                multiEndDate={multiEndDate}
                updateMultipleRows={this.updateMultipleRows}
                error={modalError}
                errorMessage={errorMessage}
              />
            </div>
          </div>
        </SettingsPageWrapper>
      </HeaderAwarePage>
    );
  }
}

export default CustomEvents;
