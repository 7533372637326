import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_DIMENSIONS = `${KA_API_URL}/products/dimensions`;
export const GET_DIMENSION_TYPES = `${KA_API_URL}/products/dimensions/types`;

/**
 * Requests
 */

const fetchDimensionTypes = async (dataType, industry) =>
  axios.get(GET_DIMENSION_TYPES, {
    params: {
      data_type: dataType,
      industry,
    },
  });

export const fetchDimensions = () => axios.get(GET_DIMENSIONS);

/**
 * Hooks
 */

const DIMENSIONS_QUERY_KEY_BASE = 'dimensions';
const DIMENSION_TYPES_QUERY_KEY_BASE = 'dimensionTypes';

export const useDimensions = (includeDerived = true) =>
  useQuery([DIMENSIONS_QUERY_KEY_BASE, includeDerived], async () => {
    const response = await fetchDimensions();
    let dimensions = response?.data ?? [];

    if (!includeDerived) {
      const nonDerivedDimensions = dimensions.filter(dimension => dimension.derived === false);

      dimensions = nonDerivedDimensions;
    }

    return dimensions;
  });

export const useDimensionTypes = (dataType, industry) =>
  useQuery([DIMENSION_TYPES_QUERY_KEY_BASE, dataType, industry], async () => {
    const response = await fetchDimensionTypes(dataType, industry);
    const dimensionTypes = response.data ?? [];

    return dimensionTypes;
  });
