import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SubscriptionStatusContext from './SubscriptionStatusContext';
import { hasFailedPayment, hasFailedForLocationActivation, hasUnpaid } from '../generic/subscriptions/invoiceUtil';
import { KaizenProductStatus } from '../generic/subscriptions/SubscriptionConstants';
import { useSubscriptions, useSubscriptionInvoices } from '../../data-access/query/subscriptions';
import './dashboard-route.css';

export const useSubscriptionStatuses = () => {
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated ?? false);
  const { data: subscriptions, ...rest } = useSubscriptions({ enabled: isAuthenticated });

  const subscriptionsByKaizenProduct = _.keyBy(subscriptions, 'kaizen_product');

  return {
    ...rest,
    subscriptionsByKaizenProduct,
  };
};

export const useSubscriptionStatusContext = () => {
  return React.useContext(SubscriptionStatusContext);
};

export const subscriptionExists = (kaizenProduct, subscriptionsByProduct) => {
  return kaizenProduct in subscriptionsByProduct;
};

export const useTrialingStatusForProduct = kaizenProduct => {
  if (kaizenProduct == null) {
    throw new Error('Developer error: You must pass a valid KAIZEN_PRODUCT as an argument to useTrialingStatus');
  }

  const { isFetching, subscriptionsByKaizenProduct } = React.useContext(SubscriptionStatusContext);

  if (isFetching) {
    return undefined;
  }

  const { kaizen_product_status: subscriptionStatus } = subscriptionsByKaizenProduct[kaizenProduct] ?? {};

  return subscriptionStatus === KaizenProductStatus.TRIALING;
};

const dashboardCanBeSeen = (
  hasSubscription,
  isStripeSubscription,
  isShopifySubscription,
  subscriptionStatus,
  isDelinquent,
  hasLocationActivationPaymentFailures,
  trialHasExpired,
) => {
  if (!hasSubscription) {
    return false;
  }
  if (isStripeSubscription) {
    if (subscriptionStatus === KaizenProductStatus.CANCELED) {
      return false;
    }
    // Stripe invoices come into play
    if (hasLocationActivationPaymentFailures && isDelinquent) {
      return true;
    }
    if (isDelinquent || trialHasExpired) {
      return false;
    }
    return true;
  }

  if (isShopifySubscription) {
    if (subscriptionStatus === KaizenProductStatus.ACTIVE || subscriptionStatus === KaizenProductStatus.TRIALING) {
      return true;
    }
    return false;
  }

  // Customer makes payment entirely outside of Stripe
  // There are only two states: ACTIVE and CANCELED
  return subscriptionStatus === KaizenProductStatus.ACTIVE;
};

export const useSubscriptionStatusForProduct = product => {
  const { isLoading: isLoadingSubscriptions, isSuccess, subscriptionsByKaizenProduct } = useSubscriptionStatusContext();

  const subscription = subscriptionsByKaizenProduct[product];
  const isStripeSubscription = subscription?.payment_channel === 'STRIPE';
  const isShopifySubscription = subscription?.payment_channel === 'SHOPIFY';

  const { isLoading: isLoadingInvoices, data: invoices = [] } = useSubscriptionInvoices(product, {
    enabled: isStripeSubscription,
  });
  const isDelinquent = invoices.filter(hasFailedPayment).length > 0;
  const hasUnpaidInvoices = invoices.filter(hasUnpaid).length > 0;
  const hasLocationActivationPaymentFailures = invoices.filter(hasFailedForLocationActivation).length > 0;
  const hasSubscription = subscription != null;
  const kaizenProductStatus = subscription?.kaizen_product_status;
  const subscriptionType = subscription?.subscription_type;
  const trialHasExpired = kaizenProductStatus === KaizenProductStatus.PAST_DUE && !hasUnpaidInvoices;

  const canViewDashboard = dashboardCanBeSeen(
    hasSubscription,
    isStripeSubscription,
    isShopifySubscription,
    kaizenProductStatus,
    isDelinquent,
    hasLocationActivationPaymentFailures,
    trialHasExpired,
  );

  return {
    isFetching: !isSuccess || isLoadingInvoices || isLoadingSubscriptions,
    subscriptionsByKaizenProduct,
    isShopifySubscription,
    isStripeSubscription,
    subscriptionId: subscription?.subscription_id,
    hasSubscription,
    kaizenProductStatus,
    subscriptionType,
    isDelinquent,
    trialHasExpired,
    canViewDashboard,
    invoices,
  };
};
