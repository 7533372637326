import React from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import HourField from './HourField';
import { IncompleteUnsavedChangesModal, ValidUnsavedChangesModal } from './HoursOfOperationModals';
import { HOURS_OVERLAP_ERROR_CODE, INVALID_HOURS_ERROR_CODE } from './locationHoursOfOperationUtil';
import { CLOSE_TIME, OPEN_TIME, IS_CLOSED, OPEN_24_HOURS } from './locationManagementConstants';
import RevenueUpliftSelect from '../../dashboards/eo/revenueUplift/RevenueUpliftSelect';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import ProfitRoverTooltip from '../../generic/ProfitRoverTooltip';
import { CenteredProfitRoverSpinner } from '../../spinner/ProfitRoverSpinner';
import { ProfitRoverCard } from '../../generic/ProfitRoverCard';
import { gaEmitHoursOfOperationSaveChangesButtonClick } from '../../../google-analytics/locationSettings';

const HOURS_OVERLAP_ERROR_MESSAGE = 'Hours of operation cannot overlap between days';
const INVALID_HOURS_ERROR_MESSAGE = 'Please provide valid hours of operation for all days of the week';

export const getErrorMessage = errorCode => {
  if (errorCode === HOURS_OVERLAP_ERROR_CODE) {
    return HOURS_OVERLAP_ERROR_MESSAGE;
  }

  return INVALID_HOURS_ERROR_MESSAGE;
};

const LocationHoursOfOperation = ({ locationsHoursOfOperationState }) => {
  const {
    locations,
    selectedLocation,
    setSelectedLocation,
    locationHoursOfOperation,
    hoursOfOperationIsLoading,
    setLocationHoursOfOperation,
    isUpdating,
    saveHoursOfOperation,
    error,
    isError,
    showUnsavedIncompleteChangesModal,
    showUnsavedValidChangesModal,
    discardChanges,
    cancelLocationSelection,
    hasPendingChanges,
    onSave,
  } = locationsHoursOfOperationState;

  const [showNavigationConfirmModal, setShowNavigationConfirmModal] = React.useState(false);
  const navigationLocation = React.useRef(null);

  const history = useHistory();

  const showModal = location => {
    navigationLocation.current = location;
    setShowNavigationConfirmModal(true);
  };

  const closeModal = () => {
    navigationLocation.current = null;
    setShowNavigationConfirmModal(false);
  };

  const handleBlockedNavigation = nextLocation => {
    if (hasPendingChanges) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setShowNavigationConfirmModal(false);
    if (navigationLocation.current) {
      history.push(navigationLocation.current.pathname);
    }
  };

  const onSaveChanges = () => {
    gaEmitHoursOfOperationSaveChangesButtonClick();
    saveHoursOfOperation();
  };

  if (selectedLocation == null || selectedLocation.location_id == null) {
    return null;
  }

  if (hoursOfOperationIsLoading) {
    return (
      <div className="hours-of-operation-container mt-4">
        <CenteredProfitRoverSpinner />
      </div>
    );
  }

  return (
    <ProfitRoverCard className="p-3 mt-4">
      <div className="hours-of-operation-container">
        <Prompt when={hasPendingChanges && !navigationLocation.current} message={handleBlockedNavigation} />
        <IncompleteUnsavedChangesModal
          show={showNavigationConfirmModal && error != null}
          locationDescription={selectedLocation.location_description}
          onClose={handleConfirmNavigationClick}
          onFinish={closeModal}
        />
        <ValidUnsavedChangesModal
          show={showNavigationConfirmModal && error == null}
          locationDescription={selectedLocation.location_description}
          onClose={handleConfirmNavigationClick}
          onSave={() => {
            saveHoursOfOperation();
            closeModal();
          }}
        />
        <IncompleteUnsavedChangesModal
          show={showUnsavedIncompleteChangesModal && !isUpdating}
          locationDescription={selectedLocation.location_description}
          onClose={discardChanges}
          onFinish={cancelLocationSelection}
        />
        <ValidUnsavedChangesModal
          show={showUnsavedValidChangesModal && !isUpdating}
          locationDescription={selectedLocation.location_description}
          onClose={discardChanges}
          onSave={onSave}
        />
        <div className="summary">
          <h4>Set Hours of Operation for</h4>
          <div style={{ minWidth: '180px' }}>
            <RevenueUpliftSelect
              options={locations}
              onChange={setSelectedLocation}
              getOptionLabel={location => location.location_description}
              getOptionValue={location => location.location_id}
              value={selectedLocation}
            />
          </div>
        </div>
        <div className="hours-of-operation-table-container">
          <table className="hours-of-operation-table">
            <thead>
              <tr>
                <td>Closed</td>
                <td>Day</td>
                <td>Open</td>
                <td>Close</td>
              </tr>
            </thead>
            <tbody>
              {locationHoursOfOperation.map(dayHoursOfOperation => {
                return (
                  <tr key={dayHoursOfOperation.day}>
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={dayHoursOfOperation[IS_CLOSED]}
                        onChange={() => {
                          const newValue = !dayHoursOfOperation[IS_CLOSED];

                          setLocationHoursOfOperation(dayHoursOfOperation.day, {
                            [IS_CLOSED]: newValue,
                            [OPEN_24_HOURS]: newValue ? false : dayHoursOfOperation[OPEN_24_HOURS],
                            [OPEN_TIME]: newValue ? '' : dayHoursOfOperation[OPEN_TIME],
                            [CLOSE_TIME]: newValue ? '' : dayHoursOfOperation[CLOSE_TIME],
                          });
                        }}
                        disabled={isUpdating}
                      />
                    </td>
                    <td>{dayHoursOfOperation.day}</td>
                    <td>
                      <HourField
                        dayHoursOfOperation={dayHoursOfOperation}
                        isLoading={isUpdating}
                        field={OPEN_TIME}
                        setLocationHoursOfOperation={setLocationHoursOfOperation}
                        errorFields={error?.fields ?? []}
                      />
                    </td>
                    <td>
                      <HourField
                        dayHoursOfOperation={dayHoursOfOperation}
                        isLoading={isUpdating}
                        field={CLOSE_TIME}
                        setLocationHoursOfOperation={setLocationHoursOfOperation}
                        errorFields={error?.fields ?? []}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="error-message">
            {isError && error?.code !== INVALID_HOURS_ERROR_CODE ? getErrorMessage(error?.code) : ''}
          </div>
          <div className="save-btn-container">
            <ProfitRoverTooltip
              shouldDisplayTooltip={isError}
              tooltipText={INVALID_HOURS_ERROR_MESSAGE}
              placement="top"
            >
              <ProfitRoverPrimaryButton
                className="save-button"
                onClick={onSaveChanges}
                disabled={isUpdating || isError || !hasPendingChanges}
              >
                {isUpdating ? <CenteredProfitRoverSpinner style={{ margin: '0 45px' }} /> : 'Save Changes'}
              </ProfitRoverPrimaryButton>
            </ProfitRoverTooltip>
          </div>
        </div>
      </div>
    </ProfitRoverCard>
  );
};

export default LocationHoursOfOperation;
