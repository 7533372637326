import axios from 'axios';
import _ from 'lodash';
import React from 'react';
import { eachDayOfInterval, parse, startOfToday } from 'date-fns';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */
export const GET_CUSTOM_EVENTS_BASE = `${KA_API_URL}/customevents`;

/**
 * Requests
 */

const fetchCustomEvents = async () => axios.get(GET_CUSTOM_EVENTS_BASE);

/**
 * Hooks
 */

const CUSTOM_EVENTS_QUERY_KEY = ['customEvents'];

export const useCustomEvents = () =>
  useQuery(CUSTOM_EVENTS_QUERY_KEY, async () => {
    const response = await fetchCustomEvents();
    return response.data;
  });

const EXPANSION_DATE_KEY = 'eventDate';
const parseDateStr = (dateStr, fillInDate) => parse(dateStr, 'yyyy-MM-dd', fillInDate);

/**
 * Some custom events are multi-day. This Hook will return a list of custom events
 * but where multi-day events are represented by multiple Array elements, one for each
 * calendar date included in the multi-day range.
 */
export const useCustomEventsWithFullRanges = () => {
  const query = useCustomEvents();
  const { data = [] } = query;

  const expandedListOfEvents = React.useMemo(() => {
    const fillInDate = startOfToday();

    const expansions = data.map(event => {
      const { event_start_date: startDate, event_end_date: endDate } = event;

      const isSingleDayEvent = startDate === endDate;
      if (isSingleDayEvent) {
        return { ...event, [EXPANSION_DATE_KEY]: parseDateStr(startDate, fillInDate) };
      }

      const start = parseDateStr(startDate, fillInDate);
      const end = parseDateStr(endDate, fillInDate);

      const datesRange = eachDayOfInterval({ start, end }).map(date => ({ ...event, [EXPANSION_DATE_KEY]: date }));

      return datesRange;
    });

    return _.flatten(expansions);
  }, [data]);

  query.data = expandedListOfEvents;

  return query;
};
