import axios from 'axios';
import React from 'react';
import { isMobileSafari, isSafari } from 'react-device-detect';
import { useMutation, useQuery } from 'react-query';
import { useDebounce, useDeepCompareEffect, useUnmount } from 'react-use';
import { KA_API_URL } from '../../../../config/baseUrl';

const SAVE_DEBOUNCE_TIME = 3333;

const DASHBOARD_SETTINGS_QUERY_KEY_BASE = 'dashboardSettings';

/**
 * TODO: Extract this fully generic Hook somewhere else
 */
const usePersistDashboardState = (dashboard, mutationConfig) =>
  useMutation(
    async ({ workflowId, key, value }) => {
      return axios.put(`${KA_API_URL}/dashboard/${dashboard}/settings/${key}`, value, {
        params: { workflow_id: workflowId },
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
        },
      });
    },
    { mutationKey: [DASHBOARD_SETTINGS_QUERY_KEY_BASE, dashboard], ...mutationConfig },
  );

/**
 * TODO: Extract this fully generic Hook somewhere else
 */
export const useSavedDashboardState = (workflowId, dashboard, queryConfig) => {
  return useQuery(
    [DASHBOARD_SETTINGS_QUERY_KEY_BASE, dashboard, workflowId],
    async () => {
      const response = await axios.get(`${KA_API_URL}/dashboard/${dashboard}/settings`, {
        params: { workflow_id: workflowId },
      });

      return response?.data ?? {};
    },
    queryConfig,
  );
};

export const useSavedRevenueUpliftState = (workflowId, queryConfig) =>
  useSavedDashboardState(workflowId, 'revenueuplift', queryConfig);

const usePersistRevenueUpliftState = mutationConfig => usePersistDashboardState('revenueuplift', mutationConfig);

export const useSaveOnChange = (workflowId, key, state) => {
  const persistStateMutation = usePersistRevenueUpliftState();

  const save = async () => persistStateMutation.mutateAsync({ workflowId, key, value: state });

  useDeepCompareEffect(() => {
    save();
  }, [state]);
};

export const useSaveWithDebounce = (workflowId, key, state) => {
  const persistStateMutation = usePersistRevenueUpliftState();

  useDebounce(
    async () => {
      if (state != null) {
        await persistStateMutation.mutateAsync({ workflowId, key, value: state });
      }
    },
    SAVE_DEBOUNCE_TIME,
    [state],
  );
};

const IS_SAFARI_BROWSER = isSafari || isMobileSafari;

const buildEffectHandlers = onSaveStateCallback => {
  const handleTabHide = async () => {
    // Fires when user switches tabs, apps, goes to homescreen, etc.
    if (document.visibilityState === 'hidden') {
      await onSaveStateCallback();
    }
  };

  const handlePageDisappearance = async () => {
    await onSaveStateCallback();
  };

  const registerListeners = () => {
    document.addEventListener('visibilitychange', handleTabHide);

    if (IS_SAFARI_BROWSER) {
      /**
       * This handler is only needed for Safari user agents, which do not reliably fire the "visibilitychange"
       * event in certain scenarios
       */
      window.addEventListener('pagehide', handlePageDisappearance);
    }
  };

  const removeListeners = () => {
    document.removeEventListener('visibilitychange', handleTabHide);

    if (IS_SAFARI_BROWSER) {
      window.removeEventListener('pagehide', handlePageDisappearance);
    }
  };

  return { registerListeners, removeListeners };
};

export const useSaveUponLeave = (workflowId, key, state) => {
  const { mutateAsync } = usePersistRevenueUpliftState();

  const onSaveState = React.useCallback(async () => mutateAsync({ workflowId, key, value: state }), [
    mutateAsync,
    workflowId,
    key,
    state,
  ]);

  // Save the state when the user navigates away from the dashboard but remains in the application
  useUnmount(onSaveState);

  // Save the state when the user leaves the application
  const { registerListeners, removeListeners } = React.useMemo(() => buildEffectHandlers(onSaveState), [onSaveState]);
  React.useEffect(() => {
    registerListeners();
    return removeListeners;
  }, [registerListeners, removeListeners]);
};
