import React from 'react';
import ReactGA from 'react-ga4';
import { useHistory } from 'react-router';

const RouteChangeTracker = () => {
  const history = useHistory();

  const registerLocationChangeListener = () => {
    const unlisten = history.listen(location => {
      ReactGA.set({ page: location.pathname });
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    });

    return unlisten;
  };

  React.useEffect(registerLocationChangeListener, [history]);

  return null;
};

export default RouteChangeTracker;
