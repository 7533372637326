import _ from 'lodash';
import { useQueryClient, useMutation } from 'react-query';
import { createLocation } from './locations';
import { CREDENTIALS_QUERY_KEY } from '../query/credentials';
import { getWeatherLocationInfo } from '../query/kdl/weather';
import { KA_API_ROOT_URL } from '../../config/baseUrl';
import axios from '../../utils/axios-client';

/**
 * Requests
 */

const obtainPartnerAccessToken = async (partner, authCode, state) =>
  axios.get(`${KA_API_ROOT_URL}/auth/${_.toLower(partner)}/token`, {
    params: {
      auth_code: authCode,
      state,
    },
  });

const createPartnerLocation = async location => {
  const { id, name, address, city, country, country_name: countryName, state, zip, naicsCode, kaCode } = location;

  const kdlWeatherLocation = await getWeatherLocationInfo(city, state, zip, countryName);
  const { weatherLocationId, timezone, latitude, longitude } = kdlWeatherLocation;

  const createLocationResponse = await createLocation({
    naics_code: naicsCode,
    ka_code: kaCode,
    location_identifier: id,
    location_description: name,
    address,
    city,
    state,
    country_code: country,
    zip,
    timezone,
    latitude,
    longitude,
    kdl_weather_location_id: weatherLocationId,
  });

  return createLocationResponse;
};

const revokePartnerAccessTokens = partner => axios.put(`${KA_API_ROOT_URL}/auth/${_.toLower(partner)}/token/revoke`);

/**
 * Hooks
 */

export const useObtainPartnerAccessToken = (partner, mutationConfig) =>
  useMutation(async ({ authCode, state }) => {
    const response = await obtainPartnerAccessToken(partner, authCode, state);

    return response?.data;
  }, mutationConfig);

export const useCreatePartnerLocation = mutationConfig =>
  useMutation(async location => {
    const response = await createPartnerLocation(location);

    return response?.data;
  }, mutationConfig);

export const useRevokePartnerAccessTokens = (partner, mutationConfig) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const response = await revokePartnerAccessTokens(partner);

      return response?.data;
    },
    {
      ...mutationConfig,
      onSuccess: async () => {
        await queryClient.refetchQueries(CREDENTIALS_QUERY_KEY);

        if (_.isFunction(mutationConfig?.onSuccess)) {
          await mutationConfig.onSuccess();
        }
      },
    },
  );
};
