import React from 'react';
import Select from 'react-select';
import { DateRangePicker } from 'react-dates';
import { ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';

const filters = props => {
  const {
    locationValue,
    locationOptions,
    impactTypeValue,
    impactTypeOptions,
    eventDescriptionValue,
    filterStartDate,
    filterEndDate,
    focusedInput,
    onFocusChange,
    onDatesChange,
    handleEventDescriptionChange,
    handleFilterChange,
    applyFilters,
  } = props;
  return (
    <div>
      <div className="row pb-1 filters-main">
        <div className="col-3">
          <p className="filter-labels">Location / Business Unit</p>
          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={locationValue}
            options={locationOptions}
            onChange={selectedOption => handleFilterChange(selectedOption, 'selectedLocations')}
          />
        </div>
        <div className="col-3">
          <p className="filter-labels">Event Name / Description</p>
          <input
            type="text"
            name="eventDescriptionValue"
            className="fileNameSelect"
            placeholder="Contains..."
            value={eventDescriptionValue}
            onChange={handleEventDescriptionChange}
            autoComplete="off"
          />
        </div>

        <div className="col-2">
          <p className="filter-labels">Impact Type</p>
          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={impactTypeValue}
            options={impactTypeOptions}
            onChange={selectedOption => handleFilterChange(selectedOption, 'selectedImpactTypes')}
          />
        </div>

        <div className="col-3 d-flex flex-column align-items-center">
          <p className="filter-labels">Date Range</p>
          <DateRangePicker
            startDate={filterStartDate}
            startDateId="startDate"
            endDate={filterEndDate}
            small
            endDateId="endDate"
            onDatesChange={onDatesChange}
            focusedInput={focusedInput}
            onFocusChange={onFocusChange}
            isOutsideRange={() => false}
          />
        </div>

        <div className="col-1" style={{ paddingTop: 25.5 }}>
          <ProfitRoverSecondaryButton onClick={applyFilters}>Apply</ProfitRoverSecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default filters;
