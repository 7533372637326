import _ from 'lodash';
import {
  PARTNER_CONFIG_NAME,
  PARTNER_DATATYPE_NO_REPLACE,
  PARTNER_DIM_ALIASES,
  PARTNER_DIM_FROM_COLUMN,
  PARTNER_DIMS,
  PARTNER_DIMS_DATATYPE,
  PARTNER_FIELDS,
  INDUSTRY_DATATYPE_HAS_WORKFLOW,
} from './datasetConfigFields';
import { useNewDataSourceNaicsData } from './pointOfSaleSetupHooks';
import { DataType, Industry } from '../../data-center/datasetConfig/DatasetConfigFormConstants';
import { DataSource, DataSourceDisplayName } from '../../dataSources/dataSourceConstants';
import {
  API_REPRESENTATION_FOR_STATIC_PRICE_CHANGE_FREQUENCY,
  HOUR_DIMENSION_TYPE,
  Objective,
  WorkflowDescription,
} from '../../workflow/workflowConstants';
import { useCreateDatasetConfig } from '../../../data-access/mutation/datasetConfigs';
import { useCreateDataset } from '../../../data-access/mutation/datasets';
import { useCreateDimensions } from '../../../data-access/mutation/dimensions';
import { useCreateWorkflow } from '../../../data-access/mutation/workflows';
import { useDatasets } from '../../../data-access/query/datasets';
import { useDatasetConfigs } from '../../../data-access/query/datasetConfigs';
import { useDimensions } from '../../../data-access/query/dimensions';
import { useGuidedSetup } from '../../../data-access/query/guidedSetup';
import { useWorkflows } from '../../../data-access/query/workflows';

// eslint-disable-next-line no-unused-vars
export const convertItemClassesToIndustryDataTypes = (itemClasses, partner) => {
  // Hard-coded for now (will be refactored by KP-3112)
  switch (partner) {
    case DataSource.SQUARE:
      return [
        { industry: Industry.EO, dataType: DataType.TRAN },
        { industry: Industry.ANY, dataType: DataType.GRATUITY },
        { industry: Industry.ANY, dataType: DataType.CATALOG },
      ];
    case DataSource.GOTAB:
      return [
        { industry: Industry.EO, dataType: DataType.TRAN },
        { industry: Industry.ANY, dataType: DataType.GRATUITY },
        { industry: Industry.ANY, dataType: DataType.CATALOG },
      ];
    case DataSource.LIGHTSPEED_K:
      return [
        { industry: Industry.EO, dataType: DataType.TRAN },
        { industry: Industry.ANY, dataType: DataType.GRATUITY },
        { industry: Industry.ANY, dataType: DataType.CATALOG },
      ];
    case DataSource.SHOPIFY:
      return [{ industry: Industry.EO, dataType: DataType.TRAN }];
    default:
      return [{ industry: Industry.EO, dataType: DataType.TRAN }];
  }
};

const datasetConfigDataForManual = ({ configName, selectedItemClasses, naicsCode, kaCode }) => {
  const industryDataTypes = convertItemClassesToIndustryDataTypes(selectedItemClasses);
  const [industryDataType] = industryDataTypes; // assume only 1 for now

  return {
    dataset_config_name: configName,
    data_type: industryDataType.dataType,
    dataset_fields: [],
    naics_code: naicsCode,
    ka_code: kaCode,
    is_editable: true,
  };
};

export const useCreateDatasetConfigForManual = () => {
  const createDatasetConfig = useCreateDatasetConfig();

  return async properties => {
    const datasetConfig = datasetConfigDataForManual(properties);
    const { id: datasetConfigId } = await createDatasetConfig.mutateAsync(datasetConfig);

    return { datasetConfigId };
  };
};

const datasetConfigDataForPartner = (partner, itemClasses, naicsCode, kaCode, dimensionFields) => {
  const industryDataTypes = convertItemClassesToIndustryDataTypes(itemClasses, partner);

  const datasetConfigs = {};

  industryDataTypes.forEach(industryDataType => {
    const dataTypeDimensionFields = [];

    PARTNER_DIMS_DATATYPE[partner][industryDataType.dataType].forEach(dim => {
      dataTypeDimensionFields.push(
        dimensionFields.find(field => field.dim_name === dim.name && field.dim_type === dim.dimension_type),
      );
    });

    const datasetFields = dataTypeDimensionFields.map(field => ({
      dataset_field_name: field.dataset_field_name,
      from_column: field.from_column,
      product_dimension_id: field.product_dimension_id,
    }));

    datasetConfigs[industryDataType.dataType] = {
      dataset_config_name: PARTNER_CONFIG_NAME[partner][industryDataType.dataType],
      industry: industryDataType.industry,
      data_type: industryDataType.dataType,
      dataset_fields: [...PARTNER_FIELDS[partner][industryDataType.dataType], ...datasetFields],
      is_no_replace: PARTNER_DATATYPE_NO_REPLACE[partner][industryDataType.dataType],
      naics_code: naicsCode,
      ka_code: kaCode,
      partner,
    };
  });

  return datasetConfigs;
};

const shouldCreateTipForecast = partner => PARTNER_DIMS_DATATYPE[partner][DataType.GRATUITY] !== undefined;
const shouldCreateCatalogRecords = partner => PARTNER_DIMS_DATATYPE[partner][DataType.CATALOG] !== undefined;
export const shouldHaveWorkflow = (industry, dataType) => INDUSTRY_DATATYPE_HAS_WORKFLOW[industry][dataType];

/**
 * Our original design assumed exactly one configuration for a partner dataset/config/workflow. KP-3906 seeks to add an
 * additional configuration to support fetching another dataset for a different workflow. Ideally, this should suppport
 * an arbitrary number of "ancillary" configurations. However, to keep the impact to other surrounding logic as low as
 * possible we will create the other configuration records and add in the additional ids to the return object.
 *
 * Note that this is "hard-coded" for very specific data type / objectives for simplicity.
 */
export const useCreatePartnerRecords = () => {
  const createDimension = useCreateDimensions();
  const createDatasetConfig = useCreateDatasetConfig();
  const createDataset = useCreateDataset();
  const createWorkflow = useCreateWorkflow();

  const { data: dimensions = [], isLoading: isLoadingDimensions } = useDimensions();
  const { data: datasetConfigs = [], isLoading: isLoadingDatasetConfigs } = useDatasetConfigs();
  const { data: datasets = [], isLoading: isLoadingDatasets } = useDatasets();
  const { data: workflows = [], isLoading: isLoadingWorkflows } = useWorkflows();
  const { data: guidedSetup = {}, isLoading: isLoadingGuidedSetup } = useGuidedSetup();
  const { naicsCode: backupNaicsCode, kaCode: backupKaCode } = useNewDataSourceNaicsData();

  const partnerDims = {};
  const datasetConfigTran = {};
  const datasetConfigGratuity = {};
  const datasetConfigCatalog = {};
  const datasetTran = {};
  const datasetGratuity = {};
  const datasetCatalog = {};
  const workflowPriceOpt = {};

  const workflowsPriceOpt = workflows.filter(e => e.objective === Objective.PRICEOPT);
  const workflowTipForecast = workflows.find(e => e.objective === Objective.TIPFORECAST);

  // eslint-disable-next-line no-restricted-syntax
  for (const [partner, dims] of Object.entries(PARTNER_DIMS)) {
    partnerDims[partner] = _.cloneDeep(dims);

    partnerDims[partner].forEach(dim => {
      const equivalentDim = PARTNER_DIM_ALIASES[dim.name];
      let existingDim = dimensions.find(e => e.name === dim.name && e.dimension_type === dim.dimension_type);

      if (existingDim != null) {
        dim.product_dimension_id = existingDim.product_dimension_id;
      } else if (equivalentDim) {
        existingDim = dimensions.find(
          e => e.name === equivalentDim.name && e.dimension_type === equivalentDim.dimension_type,
        );
        if (existingDim != null) {
          dim.product_dimension_id = existingDim.product_dimension_id;
        }
      }
    });

    datasetConfigTran[partner] = datasetConfigs.find(
      config => config.partner === partner && config.data_type === DataType.TRAN,
    );
    datasetTran[partner] = datasetConfigTran[partner]
      ? datasets.find(e => e.dataset_config_id === datasetConfigTran[partner].dataset_config_id)
      : null;

    datasetConfigGratuity[partner] = datasetConfigs.find(
      config => config.partner === partner && config.data_type === DataType.GRATUITY,
    );
    datasetGratuity[partner] = datasetConfigGratuity[partner]
      ? datasets.find(e => e.dataset_config_id === datasetConfigGratuity[partner].dataset_config_id)
      : null;

    datasetConfigCatalog[partner] = datasetConfigs.find(
      config => config.partner === partner && config.data_type === DataType.CATALOG,
    );
    datasetCatalog[partner] = datasetConfigCatalog[partner]
      ? datasets.find(e => e.dataset_config_id === datasetConfigCatalog[partner].dataset_config_id)
      : null;

    // There can be one or more PRICEOPT workflows that already exist for manual data sources.
    // We do not want to disturb those, but instead create an additional workflow to support this partner.
    // We will check the dims to see if they satisfy the partner required ones.

    const allPartnerDimsExist = _.every(partnerDims[partner], dim => dim.product_dimension_id != null);

    workflowPriceOpt[partner] = null;

    if (allPartnerDimsExist) {
      workflowsPriceOpt.forEach(workflow => {
        const { dimensions: workflowDimIds } = workflow;
        const workflowDimFields = workflowDimIds.map(dimId =>
          partnerDims[partner].find(dim => dim.product_dimension_id === dimId),
        );

        if (
          _.some(PARTNER_DIMS_DATATYPE[partner][DataType.TRAN], partnerDim =>
            _.some(
              workflowDimFields,
              workflowDimField =>
                workflowDimField &&
                partnerDim.name === workflowDimField.name &&
                partnerDim.dimension_type === workflowDimField.dimension_type,
            ),
          )
        ) {
          // TODO: think about how to support multiple partner integrations (dims must match)
          // found a workflow where the dimensions all match for this partner
          workflowPriceOpt[partner] = workflow;
        }
      });
    }
  }

  const isInitializing = _.some([
    isLoadingDimensions,
    isLoadingDatasetConfigs,
    isLoadingDatasets,
    isLoadingWorkflows,
    isLoadingGuidedSetup,
  ]);

  const tranRecordsMissing = partner =>
    _.some([datasetConfigTran[partner], datasetTran[partner], workflowPriceOpt[partner]], _.isNil);

  const gratuityRecordsMissing = partner =>
    shouldCreateTipForecast(partner) &&
    _.some([datasetConfigGratuity[partner], datasetGratuity[partner], workflowTipForecast], _.isNil);

  const catalogRecordsMissing = partner =>
    shouldCreateCatalogRecords(partner) && _.some([datasetConfigCatalog[partner], datasetCatalog[partner]], _.isNil);

  const allPartnerRecordsExist = partner => {
    return (
      !isInitializing &&
      !tranRecordsMissing(partner) &&
      !gratuityRecordsMissing(partner) &&
      !catalogRecordsMissing(partner)
    );
  };

  const datasetConfigRecordId = async (datasetConfigRecord, datasetConfigData) => {
    let configId;

    if (datasetConfigRecord == null) {
      ({ id: configId } = await createDatasetConfig.mutateAsync(datasetConfigData));
    } else {
      configId = datasetConfigRecord.dataset_config_id;
    }

    return configId;
  };

  const datasetRecordId = async (partner, datasetRecord, datasetConfigId, dataType) => {
    let datasetId;

    if (datasetRecord == null) {
      const userId = localStorage.getItem('id');
      ({ dataset_id: datasetId } = await createDataset.mutateAsync({
        user_id: userId,
        config_id: datasetConfigId,
        description: `${DataSourceDisplayName[partner]} Integration Dataset for ${dataType}`,
        location_type: 'API',
      }));
    } else {
      datasetId = datasetRecord.dataset_id;
    }

    return datasetId;
  };

  const workflowRecordId = async (
    partner,
    workflowRecord,
    naicsCode,
    kaCode,
    dataType,
    industry,
    objective,
    description,
    dimensionFields,
  ) => {
    let workflowId;
    const dataTypeDimFields = [];

    PARTNER_DIMS_DATATYPE[partner][dataType].forEach(dim => {
      dataTypeDimFields.push(
        dimensionFields.find(field => field.dim_name === dim.name && field.dim_type === dim.dimension_type),
      );
    });

    const pricingDimFields = dataTypeDimFields.filter(dim => dim.dim_type !== HOUR_DIMENSION_TYPE);

    if (workflowRecord == null) {
      ({ id: workflowId } = await createWorkflow.mutateAsync({
        industry,
        objective,
        description,
        time_granularity: 'DAY',
        price_changes: API_REPRESENTATION_FOR_STATIC_PRICE_CHANGE_FREQUENCY,
        naics_code: naicsCode,
        ka_code: kaCode,
        dimensions: dataTypeDimFields.map(dimField => dimField.product_dimension_id),
        pricing_dimensions: pricingDimFields.map(dimField => dimField.product_dimension_id),
      }));
    } else {
      workflowId = workflowRecord.workflow_id;
    }

    return workflowId;
  };

  const createPartnerRecords = async properties => {
    const { partner } = properties;
    let { itemClasses, naicsCode, kaCode } = properties;

    const [existingDims, dimsToCreate] = _.partition(partnerDims[partner], dim => dim.product_dimension_id != null);

    const dimCreatePromises = dimsToCreate.map(async dim => {
      const fromColumn = PARTNER_DIM_FROM_COLUMN[partner][dim.name];
      const [createdDimension] = await createDimension.mutateAsync({ dimensions: [dim] });

      return {
        dim_name: dim.name,
        dim_type: dim.dimension_type,
        from_column: fromColumn,
        product_dimension_id: createdDimension.product_dimension_id,
        dataset_field_name: 'DIM',
      };
    });
    const createdDimFields = await Promise.all(dimCreatePromises);

    const existingDimFields = existingDims.map(dim => {
      const fromColumn = PARTNER_DIM_FROM_COLUMN[partner][dim.name];

      return {
        dim_name: dim.name,
        dim_type: dim.dimension_type,
        from_column: fromColumn,
        product_dimension_id: dim.product_dimension_id,
        dataset_field_name: 'DIM',
      };
    });

    const dimFields = [...createdDimFields, ...existingDimFields];

    // if we are trying to add (or finish) a partner data source after we completed guided setup
    if (itemClasses == null || naicsCode == null || kaCode == null) {
      const {
        naics_industry: { naics_code: GSnaicsCode, ka_code: GSkaCode } = {},
        data_source: { item_classes: GSitemClasses } = {},
      } = guidedSetup;

      itemClasses = GSitemClasses;
      naicsCode = GSnaicsCode;
      kaCode = GSkaCode;
    }

    // some legacy customers may have an older version of guided setup
    if (itemClasses == null || naicsCode == null || kaCode == null) {
      itemClasses = [];
      naicsCode = backupNaicsCode;
      kaCode = backupKaCode;
    }

    const datasetConfigData = datasetConfigDataForPartner(partner, itemClasses, naicsCode, kaCode, dimFields);
    const primaryConfig = { industry: Industry.EO, dataType: DataType.TRAN, objective: Objective.PRICEOPT };
    const ancillaryConfig = { industry: Industry.ANY, dataType: DataType.GRATUITY, objective: Objective.TIPFORECAST };
    const extraConfig = { industry: Industry.ANY, dataType: DataType.CATALOG, objective: null };

    // create datasets, configs, workflows and return the ids in the partnerData object
    const partnerData = {};

    partnerData.primaryConfig = primaryConfig;
    partnerData.datasetConfigId = await datasetConfigRecordId(
      datasetConfigTran[partner],
      datasetConfigData[primaryConfig.dataType],
    );
    partnerData.datasetId = await datasetRecordId(
      partner,
      datasetTran[partner],
      partnerData.datasetConfigId,
      primaryConfig.dataType,
    );

    // If there will be more than one workflow, add in the partner name to distinguish home page tiles in the web UI
    const workflowDescPartner =
      (!workflowPriceOpt[partner] && workflowsPriceOpt.length > 0) || workflowsPriceOpt.length > 1
        ? ` for ${DataSourceDisplayName[partner]}`
        : '';

    partnerData.workflowId = await workflowRecordId(
      partner,
      workflowPriceOpt[partner],
      naicsCode,
      kaCode,
      primaryConfig.dataType,
      primaryConfig.industry,
      primaryConfig.objective,
      `${WorkflowDescription.PRICEOPT}${workflowDescPartner}`,
      dimFields,
    );

    if (shouldCreateTipForecast(partner)) {
      partnerData.ancillaryConfig = ancillaryConfig;
      partnerData.datasetConfigAncillaryId = await datasetConfigRecordId(
        datasetConfigGratuity[partner],
        datasetConfigData[ancillaryConfig.dataType],
      );
      partnerData.datasetAncillaryId = await datasetRecordId(
        partner,
        datasetGratuity[partner],
        partnerData.datasetConfigAncillaryId,
        ancillaryConfig.dataType,
      );
      partnerData.workflowAncillaryId = await workflowRecordId(
        partner,
        workflowTipForecast,
        naicsCode,
        kaCode,
        ancillaryConfig.dataType,
        ancillaryConfig.industry,
        ancillaryConfig.objective,
        `${WorkflowDescription.TIPFORECAST}${workflowDescPartner}`,
        dimFields,
      );
    }

    if (shouldCreateCatalogRecords(partner)) {
      partnerData.extraConfig = extraConfig;
      partnerData.datasetConfigExtraId = await datasetConfigRecordId(
        datasetConfigCatalog[partner],
        datasetConfigData[extraConfig.dataType],
      );
      partnerData.datasetExtraId = await datasetRecordId(
        partner,
        datasetCatalog[partner],
        partnerData.datasetConfigExtraId,
        extraConfig.dataType,
      );
    }

    return partnerData;
  };

  return {
    isInitializing,
    allPartnerRecordsExist,
    tranRecordsMissing,
    gratuityRecordsMissing,
    catalogRecordsMissing,
    createPartnerRecords,
  };
};
