import React from 'react';
import { Modal } from 'react-bootstrap';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';
import { ProfitRoverModalCard, ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';

const DeleteUserModal = ({ show, onHide, onContinue }) => {
  return (
    <Modal centered size="md" show={show} onHide={onHide}>
      <ProfitRoverModalCard>
        <ProfitRoverModalMain className="pl-3 pb-5">
          <h3>Delete user</h3>
          <p>This action cannot be reversed. Are you sure?</p>
        </ProfitRoverModalMain>
        <ProfitRoverModalFooter>
          <div className="w-100 d-flex justify-content-end">
            <ProfitRoverPrimaryButton onClick={onContinue}>Delete</ProfitRoverPrimaryButton>
            <ProfitRoverSecondaryButton onClick={onHide}>Cancel</ProfitRoverSecondaryButton>
          </div>
        </ProfitRoverModalFooter>
      </ProfitRoverModalCard>
    </Modal>
  );
};

export default DeleteUserModal;
