import React from 'react';
import { useQueryClient } from 'react-query';
import { Redirect, useHistory, useRouteMatch } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import AdminRoleCheck from './AdminRoleCheck';
import {
  BubbleLabel,
  CheckoutHeaderBar,
  ConfirmChangesButton,
  GoBackButton,
  PageTitle,
  PaymentFailedMessage,
  PaymentMethodSection,
  SpreadText,
} from './common/CommonSubscriptionComponents';
import ManagePaymentMethodModal from './ManagePaymentMethodModal';
import { usePayInvoiceState } from './payInvoiceHooks';
import PaymentSuccessDetails from './PaymentSuccessDetails';
import LoadingIndicator from '../common/LoadingIndicator';
import { ProfitRoverModalCard, ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';
import ProfitRoverTermsOfUseLink from '../../generic/ProfitRoverTermsOfUseLink';
import { KaizenProduct } from '../../generic/subscriptions/SubscriptionConstants';
import { useSubscriptionStatusForProduct } from '../../routing/subscriptionStatusHooks';
import { nullSafeFormatCurrency } from '../../util/format';
import { BACKGROUND_GRAY, LIGHT_GREEN } from '../../../colors';
import {
  BILLING_INFO_QUERY_KEY_BASE,
  useBillingInfo,
  useSubscriptionInvoices,
} from '../../../data-access/query/subscriptions';
import './pay-invoice.css';

const SUCCESS_TITLE = 'Successfully Paid Invoice';

const computeDisplayInfo = (invoiceToPay = {}) => {
  const { number } = invoiceToPay;
  let { subtotal, total, tax } = invoiceToPay;

  subtotal = nullSafeFormatCurrency(subtotal / 100);
  tax = nullSafeFormatCurrency(tax / 100);
  total = nullSafeFormatCurrency(total / 100);

  return {
    number,
    subtotal,
    total,
    tax,
  };
};

const PayInvoice = ({ invoiceId, subscriptionId }) => {
  const queryClient = useQueryClient();

  const {
    data: invoices = [],
    isLoading: isLoadingInvoices,
    isSuccess: invoicesLoaded,
    refetch,
  } = useSubscriptionInvoices(KaizenProduct.KP, {
    enabled: false,
  });
  React.useEffect(() => {
    // Initiate data fetch only if cached data isn't available
    if (!invoicesLoaded && !isLoadingInvoices) {
      refetch();
    }
  }, [invoicesLoaded, isLoadingInvoices, refetch]);

  const invoiceToPay = React.useMemo(() => invoices.find(inv => inv.id === invoiceId), [invoices, invoiceId]);

  const defaultPaymentMethodState = useBillingInfo(subscriptionId, 'defaultPaymentMethod');

  const { isLoading: isLoadingPaymentMethod } = defaultPaymentMethodState;

  const {
    attemptToPayInvoice,
    payInvoiceMutation,
    paymentCompletionDetails,
    showPaymentFailed,
    setShowPaymentFailed,
    submissionError,
  } = usePayInvoiceState(invoiceId, subscriptionId);

  const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] = React.useState(false);
  const onHide = () => setShowUpdatePaymentMethodModal(false);

  // Clear the requestEndTrialEarly flag if the user clicks the cancel button to prevent accidentally navigating them
  // back to the end trial early checkout page
  const history = useHistory();
  const unlisten = history.listen((location, action) => {
    if (action === 'POP') {
      history.replace(location.pathname, { requestEndTrialEarly: false });
    }
  });

  const onCancel = () => {
    history.goBack();
    unlisten();
  };

  const invoiceIsNotCollectible =
    invoiceToPay == null || invoiceToPay?.paid === true || invoiceToPay?.invoice_status !== 'open';
  if (invoicesLoaded && invoiceIsNotCollectible) {
    // Prevent attempting to pay an invoice that has already been paid or is otherwise uncollectible
    onCancel();
    return null;
  }

  const { number, subtotal, total, tax } = computeDisplayInfo(invoiceToPay);

  const dataIsFetching = isLoadingInvoices || !invoicesLoaded || isLoadingPaymentMethod;
  const changesInProgress = payInvoiceMutation.isLoading;
  const buttonDisabled = isLoadingInvoices || changesInProgress || showPaymentFailed;

  const blurStyles = { filter: showUpdatePaymentMethodModal ? 'blur(8px)' : undefined };

  return (
    <>
      <CheckoutHeaderBar />
      <div className="checkout-subscription-changes" style={{ backgroundColor: BACKGROUND_GRAY, ...blurStyles }}>
        <div className="pay-invoice">
          <ManagePaymentMethodModal
            show={showUpdatePaymentMethodModal}
            subscriptionId={subscriptionId}
            onHide={onHide}
            onSuccess={async () => {
              await queryClient.refetchQueries([
                ...BILLING_INFO_QUERY_KEY_BASE,
                subscriptionId,
                'defaultPaymentMethod',
              ]);
              setShowPaymentFailed(false);
              onHide();
            }}
          />
          <PageTitle>Pay Past-Due Invoice</PageTitle>
          <div className="labeled-bubble-container">
            {paymentCompletionDetails != null ? (
              <PaymentSuccessDetails
                title={SUCCESS_TITLE}
                subscriptionId={subscriptionId}
                paymentCompletionDetails={paymentCompletionDetails}
              />
            ) : (
              <>
                <BubbleLabel>Payment Due Today</BubbleLabel>
                <ProfitRoverModalCard>
                  <ProfitRoverModalMain>
                    {showPaymentFailed && (
                      <PaymentFailedMessage onClick={() => setShowUpdatePaymentMethodModal(true)} />
                    )}
                    <p className="ssl-text">
                      <FontAwesomeIcon icon={faLock} color={LIGHT_GREEN} />
                      This is a secure SSL Encrypted Payment
                    </p>
                    {defaultPaymentMethodState.data != null && (
                      <PaymentMethodSection
                        defaultPaymentMethodState={defaultPaymentMethodState}
                        paymentFailed={showPaymentFailed}
                        onClick={() => setShowUpdatePaymentMethodModal(true)}
                      />
                    )}
                    <div className="padded-container invoice-number">
                      <SpreadText className="align-items-center">
                        <p className="label">Invoice Number</p>
                        <p className="value">{dataIsFetching ? <LoadingIndicator /> : <i>{number}</i>}</p>
                      </SpreadText>
                    </div>
                    <div className="padded-container emphasis-area">
                      <SpreadText>
                        <p>Invoice Subtotal</p>
                        <p>{dataIsFetching ? <LoadingIndicator /> : subtotal}</p>
                      </SpreadText>
                      <SpreadText className="sales-tax">
                        <p>Sales Tax</p>
                        <p>{dataIsFetching ? <LoadingIndicator /> : tax}</p>
                      </SpreadText>
                      <SpreadText className="due-today">
                        <h5>Due Today</h5>
                        <h5>{dataIsFetching ? <LoadingIndicator /> : total}</h5>
                      </SpreadText>
                    </div>
                  </ProfitRoverModalMain>
                  <ProfitRoverModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                    <GoBackButton
                      onClick={onCancel}
                      paymentFailed={showPaymentFailed}
                      subscriptionId={subscriptionId}
                      disabled={changesInProgress}
                    />
                    <ConfirmChangesButton
                      customerWillBeCharged
                      changesInProgress={changesInProgress}
                      disabled={buttonDisabled}
                      onConfirmChanges={attemptToPayInvoice}
                      onTryAgain={attemptToPayInvoice}
                      submissionError={submissionError}
                    />
                  </ProfitRoverModalFooter>
                </ProfitRoverModalCard>
              </>
            )}
          </div>
          <div className="explanatory-text-container">
            <p>
              Payment of all your past due invoices will reinstate access to your insights. Paying today will not
              disturb your original payment schedule, so your next billing will be unaffected.
            </p>
            <p>
              By paying your invoice, you authorize us to charge your credit card for the amount shown. Your next
              billing period will continue as scheduled. For more information regarding billing, please review the{' '}
              <ProfitRoverTermsOfUseLink>Terms of Use</ProfitRoverTermsOfUseLink>.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

// Ensure invoiceId is present in the URL
const PayInvoiceRedirect = () => {
  const {
    params: { invoiceId },
  } = useRouteMatch();

  const {
    isFetching,
    subscriptionsByKaizenProduct,
    isStripeSubscription,
    hasSubscription,
  } = useSubscriptionStatusForProduct(KaizenProduct.KP);

  if (!invoiceId) {
    return <Redirect to="/invoices" />;
  }

  if (isFetching) {
    return null;
  }

  if (hasSubscription && isStripeSubscription) {
    const { subscription_id: kaizenPriceSubscriptionId } = subscriptionsByKaizenProduct[KaizenProduct.KP];

    return <PayInvoice invoiceId={invoiceId} subscriptionId={kaizenPriceSubscriptionId} />;
  }

  return <Redirect to="/invoices" />;
};

const RoleCheck = props => (
  <AdminRoleCheck>
    <PayInvoiceRedirect {...props} />
  </AdminRoleCheck>
);

export default RoleCheck;
