import axios from '../../../../utils/axios-client';
import { KA_API_URL } from '../../../../config/baseUrl';
import { arrayParamSerialization } from '../../../util/format';

const moment = require('moment');

export const calculateUpliftData = async (workflowId, locations, products, startDate, endDate) => {
  let locationUplift = [];
  let productUplift = [];
  let monthsUplift = [];
  const productClass = ['GEN'];
  const calendarEndDate = new Date();
  endDate = moment(endDate).format('YYYY-MM-DD');

  const params = arrayParamSerialization({
    locations,
    products,
    productClass
  });

  locations = params.locations.length > 0 ? params.locations : [];
  products = params.products.length > 0 ? params.products : [];

  try {
    const locationsResponse = await axios.get(`${KA_API_URL}/productvisit/attendance`, {
      params: {
        workflow_id: workflowId,
        group_by: 'location',
        product_classes: productClass,
        location_ids: locations,
        product_ids: products,
        start_date: startDate,
        end_date: endDate,
      },
    });

    const productsResponse = await axios.get(`${KA_API_URL}/productvisit/attendance`, {
      params: {
        workflow_id: workflowId,
        group_by: 'product',
        product_classes: productClass,
        location_ids: locations,
        product_ids: products,
        start_date: startDate,
        end_date: endDate,
      },
    });

    const monthsUpliftResponse = await axios.get(`${KA_API_URL}/productvisit/attendance`, {
      params: {
        workflow_id: workflowId,
        group_by: 'month',
        product_classes: productClass,
        location_ids: locations,
        product_ids: products,
        start_date: startDate,
        end_date: endDate,
      },
    });

    locationUplift = locationsResponse.data;
    productUplift = productsResponse.data;
    monthsUplift = monthsUpliftResponse.data;

    return {
      locationUplift,
      productUplift,
      monthsUplift,
      calendarEndDate,
    };
  } catch (err) {
    return {
      locationUplift,
      productUplift,
      monthsUplift,
    };
  }
};

export const calculateProductsPane = async data => {
  if (data === undefined || data === null) {
    return {
      productAttendanceArray: [],
      distinctProducts: [],
    };
  }
  const set = new Set();
  const distinctProducts = [];
  for (let i = 0; i < data.length; i++) {
    if (!set.has(data[i].product_dimension_value)) {
      set.add(data[i].product_dimension_value);
      distinctProducts.push({ value: data[i].product_dimension_value, label: data[i].product_dimension_value });
    }
  }

  const productAttendanceArray = [];
  for (let i = 0; i < distinctProducts.length; i++) {
    const productDataUplift = data
      .filter(d => d.product_dimension_value === distinctProducts[i].value)
      .reduce((accumulator, currentValue) => accumulator + currentValue.attendance_uplift, 0);

    productAttendanceArray.push({
      product_dimension_value: distinctProducts[i].value,
      attendance_uplift: productDataUplift,
    });
  }

  productAttendanceArray.sort((a, b) => b.attendance_uplift - a.attendance_uplift);

  return {
    productAttendanceArray,
    distinctProducts,
  };
};

export const calculateDailyTableData = async (
  workflowId,
  locations,
  products,
  startDate,
  endDate,
  pageNumber,
  sort,
  sortDirection,
) => {
  const productClasses = ['ATTEND'];
  const dailySummaryStats = [];

  try {
    const params = arrayParamSerialization({
      locations,
      products,
      productClasses,
      sort,
      sort_direction: sortDirection,
    });

    const dailySummary = await axios.get(`${KA_API_URL}/productvisit/dailysummary`, {
      params: {
        workflow_id: workflowId,
        page_number: pageNumber,
        limit: 100,
        location_ids: params.locations,
        product_ids: params.products,
        productClasses: params.productClasses,
        sort: params.sort,
        sort_direction: params.sort_direction,
        start_date: startDate,
        end_date: endDate,
      },
    });

    return dailySummary.data;
  } catch (err) {
    return dailySummaryStats;
  }
};

export const calculateDetailTableData = async (
  workflowId,
  locations,
  products,
  startDate,
  endDate,
  pageNumber,
  sort,
  sortDirection,
) => {
  const productClasses = ['GEN'];
  const forecastStats = [];

  try {
    const params = arrayParamSerialization({
      locations,
      products,
      productClasses,
      sort,
      sort_direction: sortDirection,
    });
    const forecast = await axios.get(`${KA_API_URL}/productvisit/forecaststats`, {
      params: {
        workflow_id: workflowId,
        page_number: pageNumber,
        limit: 100,
        location_ids: params.locations,
        product_ids: params.products,
        productClasses: params.productClasses,
        sort: params.sort,
        sort_direction: params.sort_direction,
        start_date: startDate,
        end_date: endDate,
      },
    });

    return forecast.data;
  } catch (err) {
    return forecastStats;
  }
};
