import React from 'react';
import classnames from 'classnames';
import { DataType, Industry } from './DatasetConfigFormConstants';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import ProfitRoverAlphaNumericLabel from '../../generic/ProfitRoverAlphaNumericLabel';

export function PageHeader(props) {
  const { children } = props;
  return <h2 className="ds-config-header">{children}</h2>;
}

export function PageSubHeader(props) {
  const { children } = props;
  return <div className="ds-config-subheader">{children}</div>;
}

export function ConfigTypeSubHeader(props) {
  let text = '';
  switch (props.industry) {
    case Industry.CFB:
      text = 'College Football';
      break;
    case Industry.EO:
      text = 'Entertainment Operator';
      break;
    case Industry.MEDIA:
      text = 'Media';
      break;
    default:
      text = '';
  }

  text += ' ';

  switch (props.dataType) {
    case DataType.ADMIT:
      text += 'Attendance';
      break;
    case DataType.REVENUE:
      text += 'Revenue';
      break;
    case DataType.ADVTRAN:
    case DataType.TRAN:
      text += 'Transactions';
      break;
    default:
      text += '';
  }

  return <PageSubHeader>{text}</PageSubHeader>;
}

export function SectionHeader(props) {
  const { children, label, style } = props;
  return (
    <div className="ds-config-section-header" style={style}>
      {label && <ProfitRoverAlphaNumericLabel text={label} />}
      <div>{children}</div>
    </div>
  );
}

/**
 * Just applies "indentation" so that prompts are indented underneath
 * the Section Header.
 */
export function ContentArea(props) {
  const { children } = props;

  return <div style={{ paddingLeft: 30 }}>{children}</div>;
}

export function PromptText(props) {
  const { label, children } = props;
  return (
    <div className="ds-config-prompt-text">
      {label && (
        <span>
          <strong>{`${label} `}</strong>
        </span>
      )}
      {children}
    </div>
  );
}

export function RadioButtonRow(props) {
  const { children } = props;
  return <div className="ds-config-radio-row">{children}</div>;
}

export function RadioButtonColumn(props) {
  const { children } = props;
  return <div className="ds-config-radio-column">{children}</div>;
}

export function LargeRadioButton(props) {
  const { onClick, selected = false, children } = props;

  return (
    <div
      role="button"
      tabIndex="0"
      className={classnames('ds-config-lg-radio', { selected })}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
    >
      <div style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <input
          checked={selected}
          readOnly
          tabIndex={null}
          type="radio"
          style={{
            marginRight: 5,
            width: 14,
            height: '100%',
          }}
          value={undefined}
        />
        <span style={{ fontSize: 18, fontWeight: 'bold' }}>{children}</span>
      </div>
    </div>
  );
}

export function SmallRadioButton(props) {
  const { onClick, selected = false, children } = props;

  return (
    <div
      role="button"
      tabIndex="0"
      className={classnames('ds-config-sm-radio', { selected })}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
    >
      <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <input
          checked={selected}
          readOnly
          tabIndex={null}
          type="radio"
          style={{
            marginRight: 5,
          }}
        />
        <span style={{ fontSize: 14 }}>{children}</span>
      </div>
    </div>
  );
}

const isPlainObject = obj => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export function DropdownSelect(props) {
  const { disabled, options, onSelect, placeholder, style, value } = props;
  const valuesAreObjects = options.length > 0 ? isPlainObject(options[0]?.value) : false;

  let selectedValue = value;
  if (selectedValue != null) {
    selectedValue = valuesAreObjects ? JSON.stringify(value) : value;
  }

  return (
    <select
      value={selectedValue ?? 'NONE'}
      onChange={e => {
        let val = e.target.value;

        if (val === 'NONE') {
          return;
        }
        if (valuesAreObjects) {
          val = JSON.parse(val);
        }

        onSelect(val);
      }}
      onBlur={e => {
        let val = e.target.value;

        if (val === 'NONE') {
          return;
        }
        if (valuesAreObjects) {
          val = JSON.parse(val);
        }

        onSelect(val);
      }}
      className="ds-config-dropdown-select"
      style={style}
      disabled={disabled}
    >
      <option disabled value="NONE">
        {placeholder != null ? placeholder : ' -- Select an Option -- '}
      </option>

      {options.map(({ disabled: isDisabled = false, label, value: optionValue }) => (
        <option key={label} disabled={isDisabled} value={valuesAreObjects ? JSON.stringify(optionValue) : optionValue}>
          {label}
        </option>
      ))}
    </select>
  );
}

export function TextInput(props) {
  const { onChange, placeholder, style, value } = props;
  return (
    <input
      className="ds-config-text-input"
      style={{ ...style }}
      type="text"
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  );
}

export function CancelButton(props) {
  const { className, onClick, style, children } = props;

  return (
    <div
      role="button"
      tabIndex="0"
      className={classnames('ds-config-cancel-button', className)}
      onClick={onClick}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      style={style}
    >
      <span className="ds-config-cancel" />
      {children && <span style={{ marginLeft: 5 }}>{children}</span>}
    </div>
  );
}

export function ActionButton(props) {
  const { children, disabled, onClick, style } = props;

  const buttonRef = React.useRef();

  const buttonOnClick = e => {
    if (buttonRef.current) {
      // This prevents the user from clicking the button twice, which guards against the creation
      // of duplicate dataset fields if the user were to click the button multiple times while the
      // corresponding HTTP request was still in-flight
      buttonRef.current.setAttribute('disabled', 'disabled');
    }

    onClick(e);

    if (buttonRef.current) {
      // Let the user click the button again because whatever happened onClick has finished now. Note
      // that if onClick has caused this button to unmount, buttonRef.current will be null and this
      // code block will not execute (which prevents a React error)
      buttonRef.current.setAttribute('disabled', 'enabled');
    }
  };

  return (
    <ProfitRoverPrimaryButton ref={buttonRef} type="button" disabled={disabled} onClick={buttonOnClick} style={style}>
      {children}
    </ProfitRoverPrimaryButton>
  );
}
