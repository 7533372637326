import React, { forwardRef } from 'react';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProfitRoverPrimaryButton } from './ProfitRoverButtons';
import ProfitRoverAlphaNumericLabel from '../generic/ProfitRoverAlphaNumericLabel';
import './generic-form-components.scss';

/**
 * Generic text input field component.
 */
export function GenericTextInput(props) {
  const { className, onChange, placeholder, style, spellCheck, value } = props;

  return (
    <input
      className={classnames('generic-text-input', className)}
      style={style}
      type="text"
      placeholder={placeholder}
      onChange={onChange}
      spellCheck={spellCheck}
      value={value}
    />
  );
}

/**
 * Renders child text with a "soft" underline.
 *
 * Generally used as the header that tells the user what page they are currently viewing.
 */
export const PageHeader = props => {
  const { children, className } = props;
  return (
    <h1 className={classnames('generic-page-header', className)}>
      <div className="underline-container">
        <span>{children}</span>
        <div className="underline" />
      </div>
    </h1>
  );
};

export const PageSubHeader = props => {
  const { children, className } = props;
  return (
    <h3 className={classnames('generic-form-subheader', className)} {...props}>
      {children}
    </h3>
  );
};

export function SectionHeader(props) {
  const { children, label, style } = props;
  return (
    <div className="generic-form-section-header" style={style}>
      {label && <ProfitRoverAlphaNumericLabel text={label} />}
      <div>{children}</div>
    </div>
  );
}

export const VerticalSeparator = props => {
  const { style, className } = props;
  return (
    <span className={classnames('generic-vertical-separator', className)} style={style}>
      |
    </span>
  );
};

/**
 * Just applies "indentation" so that prompts are indented underneath
 * the Section Header.
 */
export function ContentArea(props) {
  const { children } = props;

  return <div style={{ paddingLeft: 30 }}>{children}</div>;
}

export function PromptText(props) {
  const { label, children } = props;
  return (
    <div className="generic-form-prompt-text">
      {label && (
        <span>
          <strong>{`${label} `}</strong>
        </span>
      )}
      {children}
    </div>
  );
}

export function RadioButtonRow(props) {
  const { children, className } = props;
  return <div className={classnames('generic-form-radio-row', className)}>{children}</div>;
}

export function RadioButtonColumn(props) {
  const { children } = props;
  return <div className="generic-form-radio-column">{children}</div>;
}

export function LargeRadioButton(props) {
  const { onClick, selected = false, children } = props;

  return (
    <div
      role="button"
      tabIndex="0"
      className={classnames('generic-form-lg-radio', { selected })}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
    >
      <div style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <input
          checked={selected}
          readOnly
          tabIndex={null}
          type="radio"
          style={{
            marginRight: 5,
            width: 14,
            height: '100%',
          }}
          value={undefined}
        />
        <span style={{ fontSize: 18, fontWeight: 'bold' }}>{children}</span>
      </div>
    </div>
  );
}

export function SimpleRadioButton(props) {
  const { onClick, selected = false, divClassName, buttonClassName, children } = props;

  return (
    <div
      className={classnames('simple-radio-button', divClassName)}
      role="button"
      tabIndex="0"
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
    >
      <input type="radio" checked={selected} tabIndex={null} className={buttonClassName} />
      {children}
    </div>
  );
}

// eslint-disable-next-line react/display-name
const DatePickerCustomInput = forwardRef((props, ref) => (
  <button type="button" className="input-wrapper" onClick={props.onClick}>
    <input ref={ref} type="text" {...props} style={{ border: 'none', maxWidth: '100px' }} />
    <FontAwesomeIcon
      name="calendarIcon"
      className="Icon"
      icon={faCalendarAlt}
      style={{
        width: '24px',
      }}
    />
  </button>
));

export function CustomDatePicker(props) {
  const { value, ...rest } = props;
  return (
    <div className="generic-form-custom-date-picker">
      <DatePicker {...rest} selected={value} customInput={<DatePickerCustomInput />} />
    </div>
  );
}

export function SmallRadioButton(props) {
  const { onClick, selected = false, children } = props;

  return (
    <div
      role="button"
      tabIndex="0"
      className={classnames('generic-form-sm-radio', { selected })}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
    >
      <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <input
          checked={selected}
          readOnly
          tabIndex={null}
          type="radio"
          style={{
            marginRight: 5,
          }}
        />
        <span style={{ fontSize: 14 }}>{children}</span>
      </div>
    </div>
  );
}

const isPlainObject = obj => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export function DropdownSelect(props) {
  const { className, disabled, options, onSelect, placeholder, showPlaceholder = true, style, value } = props;
  const valuesAreObjects = options.length > 0 ? isPlainObject(options[0]?.value) : false;

  let selectedValue = value;
  if (selectedValue != null) {
    selectedValue = valuesAreObjects ? JSON.stringify(value) : value;
  }

  return (
    <select
      value={selectedValue ?? 'NONE'}
      onChange={e => {
        let val = e.target.value;

        if (val === 'NONE') {
          return;
        }
        if (valuesAreObjects) {
          val = JSON.parse(val);
        }
        onSelect(val);
      }}
      onBlur={e => {
        let val = e.target.value;

        if (val === 'NONE') {
          return;
        }
        if (valuesAreObjects) {
          val = JSON.parse(val);
        }

        onSelect(val);
      }}
      className={className || 'generic-form-dropdown-select'}
      style={style}
      disabled={disabled}
    >
      {showPlaceholder && (
        <option disabled value="NONE">
          {placeholder != null ? placeholder : ' -- Select an Option -- '}
        </option>
      )}
      {options.map(option => (
        <option key={option.label} value={valuesAreObjects ? JSON.stringify(option.value) : option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export function TextInput(props) {
  const { className, onChange, placeholder, icon, style, value } = props;

  const outerRef = React.useRef(null);

  const focusOuterRef = React.useCallback(() => {
    if (outerRef.current != null) {
      outerRef.current.focus();
    }
  }, [outerRef]);

  return (
    <div ref={outerRef} className={classnames('generic-form-text-input', className)} style={style}>
      {icon}
      <input
        tabIndex="0"
        type="text"
        placeholder={placeholder}
        onFocus={focusOuterRef}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

export function TextArea(props) {
  const { className, onChange, placeholder, icon, style, value } = props;

  return (
    <div className={classnames('generic-form-textarea', className)} style={style}>
      {icon}
      <textarea tabIndex="0" placeholder={placeholder} onChange={onChange} value={value} />
    </div>
  );
}

export function PasswordRuleList(props) {
  const { rules } = props;
  const ruleListItems = rules.map(rule => (
    <li key={rule.id}>
      <span style={{ color: rule.isSatisfied ? 'green' : 'red' }}>{rule.description}</span>
    </li>
  ));
  return <ul>{ruleListItems}</ul>;
}

export function CancelButton(props) {
  const { className, onClick, style, children } = props;

  return (
    <div
      role="button"
      tabIndex="0"
      className={classnames('generic-form-cancel-button', className)}
      onClick={onClick}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      style={style}
    >
      <span className="generic-form-cancel" />
      {children && <span style={{ marginLeft: 5 }}>{children}</span>}
    </div>
  );
}

export function ActionButton(props) {
  const { children, disabled, onClick } = props;

  const buttonRef = React.useRef();

  const buttonOnClick = e => {
    if (buttonRef.current) {
      // This prevents the user from clicking the button twice, which guards against the creation
      // of duplicate dataset fields if the user were to click the button multiple times while the
      // corresponding HTTP request was still in-flight
      buttonRef.current.setAttribute('disabled', 'disabled');
    }

    onClick(e);

    if (buttonRef.current) {
      // Let the user click the button again because whatever happened onClick has finished now. Note
      // that if onClick has caused this button to unmount, buttonRef.current will be null and this
      // code block will not execute (which prevents a React error)
      buttonRef.current.setAttribute('disabled', 'enabled');
    }
  };

  return (
    <ProfitRoverPrimaryButton ref={buttonRef} disabled={disabled} onClick={buttonOnClick}>
      {children}
    </ProfitRoverPrimaryButton>
  );
}
