import { KaizenProduct } from '../generic/subscriptions/SubscriptionConstants';
import { useSubscriptionStatusForProduct } from '../routing/subscriptionStatusHooks';
import { useDatasets } from '../../data-access/query/datasets';
import { useWorkflows } from '../../data-access/query/workflows';

export const useUserData = () => {
  return {
    userName: localStorage.getItem('name'),
  };
};

export const useWelcomePageData = () => {
  const { isLoading: loadingWorkflows, data: workflows = [] } = useWorkflows();
  const { isLoading: loadingDatasets, data: datasets = [] } = useDatasets();

  const {
    isFetching: isFetchingSubscriptionStatus,
    subscriptionsByKaizenProduct,
    isStripeSubscription,
    isShopifySubscription,
    hasSubscription,
    kaizenProductStatus,
    canViewDashboard,
  } = useSubscriptionStatusForProduct(KaizenProduct.KP);

  return {
    loading: loadingWorkflows || loadingDatasets || isFetchingSubscriptionStatus,
    workflows,
    datasets,
    subscriptionsByKaizenProduct,
    isStripeSubscription,
    isShopifySubscription,
    hasSubscription,
    kaizenProductStatus,
    canViewDashboard,
  };
};
