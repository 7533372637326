import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Data Center';

const ACTIONS = {
  SYNC_NOW_BUTTON_CLICK: 'Sync Now button click',
  DISCONNECT_BUTTON_CLICK: 'Disconnect button click',
};

const LABELS = {
  OAUTH_DATA_SOURCE: 'OAuth Data Source',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitSyncNowButtonClick = () => {
  emitEvent({ action: ACTIONS.SYNC_NOW_BUTTON_CLICK });
};

export const gaEmitDisconnectButtonClick = () => {
  emitEvent({ action: ACTIONS.DISCONNECT_BUTTON_CLICK, label: LABELS.OAUTH_DATA_SOURCE });
};
