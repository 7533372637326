import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_WORKFLOWS_BASE = `${KA_API_URL}/workflows`;
export const GET_WORKFLOW_BATCHES_BASE = `${KA_API_URL}/workflows/batch`;

/**
 * Requests
 */

export const fetchWorkflow = async workflowId => axios.get(`${GET_WORKFLOWS_BASE}/${workflowId}`);
export const fetchWorkflows = async () => axios.get(GET_WORKFLOWS_BASE);
export const fetchWorkflowBatches = async workflowId => axios.get(`${GET_WORKFLOW_BATCHES_BASE}/${workflowId}`);
export const fetchWorkflowIndustryDataTypeBatches = async (industry, dataType) =>
  axios.get(`${GET_WORKFLOW_BATCHES_BASE}/industry/${industry}/${dataType}`);

/**
 * Hooks
 */

const WORKFLOW_QUERY_KEY_BASE = ['workflow'];
export const WORKFLOWS_QUERY_KEY = ['workflows'];
const WORKFLOW_BATCH_QUERY_KEY_BASE = ['workflow_batch'];

export const useWorkflow = (workflowId, queryConfig) =>
  useQuery(
    [...WORKFLOW_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchWorkflow(workflowId);

      return response.data;
    },
    queryConfig,
  );

export const useWorkflows = queryConfig =>
  useQuery(
    WORKFLOWS_QUERY_KEY,
    async () => {
      const response = await fetchWorkflows();
      const workflows = response.data ?? [];

      return workflows;
    },
    queryConfig,
  );

const useWorkflowBatches = (workflowId, queryConfig) =>
  useQuery(
    [...WORKFLOW_BATCH_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchWorkflowBatches(workflowId);

      return response.data;
    },
    queryConfig,
  );

const useWorkflowIndustryDataTypeBatches = (industry, dataType, queryConfig) =>
  useQuery(
    [...WORKFLOW_BATCH_QUERY_KEY_BASE, industry, dataType],
    async () => {
      const response = await fetchWorkflowIndustryDataTypeBatches(industry, dataType);

      return response.data;
    },
    queryConfig,
  );

const composeResultWithCompleteness = queryResult => {
  const { data: batches = [] } = queryResult;

  const batchComplete = batches.every(batch => batch.batch_complete);
  const progressComplete = batches.every(
    batch => batch.progress_complete && batch.batch_progress.is_progress_unit_creation_complete,
  );

  // in case there were errors in a batch we do not want to misleadingly say that the primary forecast
  // is not complete when all of the batches are complete, hence the "or batchComplete"
  const primaryForecastComplete = batches.every(batch => batch.primary_forecast_complete) || batchComplete;

  // TODO: handle case where there is more than one batch
  // Note that it is possible for a batch to be complete but progress is not
  // this is most likely due to an error mid-batch (i.e. before progress units are started and/or finished)
  const batchInProgress = batches.find(batch => !batch.progress_complete && !batch.batch_complete);
  const batchInProgressHasResults = batchInProgress?.batch_progress.output_results > 0;

  return {
    ...queryResult,
    primaryForecastComplete,
    batchComplete,
    progressComplete,
    batchInProgress,
    batchInProgressHasResults,
  };
};

/**
 * Use these hooks to get status across multiple batches.
 *
 * About workflow batches:
 *   Batches are created to track activity for workflows. In addition to progress statistics a batch also has
 *   status flags to indicate overall "completeness". At any given time there can be any number of batches
 *   per workflow (in various states). It is also possible that there are no batches. No batches present indicates
 *   a new workflow that has never been processed or a workflow whose batches have been automatically deleted
 *   due to expiration (batches are assigned a TTL once they reach a final complete state).
 */
export const useWorkflowBatchesWithStatus = (workflowId, queryConfig) => {
  const queryResult = useWorkflowBatches(workflowId, queryConfig);

  return composeResultWithCompleteness(queryResult);
};

export const useWorkflowIndustryDataTypeBatchesWithStatus = (industry, dataType, queryConfig) => {
  const queryResult = useWorkflowIndustryDataTypeBatches(industry, dataType, queryConfig);

  return composeResultWithCompleteness(queryResult);
};
