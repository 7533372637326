import axios from 'axios';
import { useMutation } from 'react-query';
import { fetchUserInfo } from '../query/users';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const PUT_USER_INFO_BASE = `${KA_API_URL}/users`;
export const PUT_CUSTOMER_INFO_BASE = `${KA_API_URL}/customer`;

/**
 * Requests
 */

const updateUserInfo = async (userId, properties) => axios.put(`${PUT_USER_INFO_BASE}/${userId}`, properties);
const updateCompanyInfo = async properties => axios.put(PUT_CUSTOMER_INFO_BASE, properties);

/**
 * Hooks
 */

export const useUpdateUserInfo = mutationConfig =>
  useMutation(async ({ userId, email, firstName, lastName }) => {
    userId = userId ?? localStorage.getItem('id');

    const response = await updateUserInfo(userId, { email_address: email, first_name: firstName, last_name: lastName });

    if (email) {
      localStorage.setItem('email', email);
    }

    if (firstName || lastName) {
      const userInfo = await fetchUserInfo(userId);

      const { first_name: first, last_name: last } = userInfo?.data ?? {};
      const fullName = `${first} ${last}`;

      if (first) {
        localStorage.setItem('name', first);
      }
      if (fullName) {
        localStorage.setItem('full_name', fullName);
      }
    }

    return response.data;
  }, mutationConfig);

export const useUpdateCompanyInfo = mutationConfig =>
  useMutation(async ({ companyName, website, phone }) => {
    const response = await updateCompanyInfo({
      name: companyName,
      website,
      phone,
    });

    return response.data;
  }, mutationConfig);
