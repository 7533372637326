import _ from 'lodash';
import axios from 'axios';
import camelcase from 'camelcase';
import React from 'react';
import { readString } from 'react-papaparse';
import { useQuery, useMutation } from 'react-query';
import { KA_API_URL } from '../../../config/baseUrl';

/**
 *  URIs
 */

export const GET_ANOMALY_STATS_RAW = `${KA_API_URL}/stats/anomaly/raw`;
export const GET_ANOMALY_STATS_META = `${KA_API_URL}/stats/anomaly/meta`;
export const GET_ANOMALY_SEGMENT = `${KA_API_URL}/stats/anomaly/segment`;
export const GET_ANOMALY_SEGMENT_META = `${KA_API_URL}/stats/anomaly/segment/meta`;

export const GET_PROCESSED_HISTORY_META = `${KA_API_URL}/processed_history/meta`;
export const GET_PROCESSED_HISTORY_STATUS = `${KA_API_URL}/processed_history/status`;
export const GET_PROCESSED_HISTORY_RAW = `${KA_API_URL}/processed_history/raw`;

/**
 * Intended to convert something like "your_special_metric" => "Your Special Metric"
 */
export const convertToHumanFriendlyLabel = columnName => {
  const withSpaces = columnName.replace(/_/g, ' ');

  return withSpaces
    .split(' ')
    .map(substr => _.capitalize(substr))
    .join(' ');
};

const fetchAnomalyDetectionMeta = async workflowId =>
  axios.get(GET_ANOMALY_STATS_META, {
    params: { workflow_id: workflowId },
  });

const fetchRawAnomalyDetectionOutput = async workflowId =>
  axios.get(GET_ANOMALY_STATS_RAW, {
    params: { workflow_id: workflowId },
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/octet-stream, application/json',
    },
  });

const transformAnomalyStats = (value, header, metricColumn) => {
  let finalValue = value;

  switch (header) {
    case metricColumn:
    case 'kaizenAnomalyBaseline':
    case 'kaizenAnomalyScore':
      finalValue = parseFloat(value);
      break;
    default:
      break;
  }

  return finalValue;
};

const RAW_ANOMALY_STATS_QUERY_KEY_BASE = 'rawAnomalyStats';
const RAW_ANOMALY_STATS_META_QUERY_KEY_BASE = 'rawAnomalyStatsMeta';

/**
 * Fetches the CSV containing the list of identified anomalies, if they exist
 */
export const useAnomalyStats = (workflowId, anomalyMeta, queryConfig) => {
  const variableColumnNames = Object.values(anomalyMeta);

  return useQuery(
    [RAW_ANOMALY_STATS_QUERY_KEY_BASE, workflowId, ...variableColumnNames],
    async () => {
      const response = await fetchRawAnomalyDetectionOutput(workflowId);
      const { data } = response;

      // Convert raw bytes to a string
      const decoder = new TextDecoder('utf-8');
      const csvData = decoder.decode(data);

      // Parse the data into Array<Object>
      const parseResults = readString(csvData, {
        header: true,
        transformHeader: header => (variableColumnNames.includes(header) ? header : camelcase(header)),
        transform: (value, header) => transformAnomalyStats(value, header, anomalyMeta.anomaly_target),
      });
      const {
        data: rows,
        errors,
        meta: { fields: columns },
      } = parseResults;

      // Remove any rows that contained too many or two few columns
      errors.forEach(error => {
        rows.splice(error.row, 1);
      });

      return { rows: _.orderBy(rows, 'kaizenAnomalyScore', 'desc'), columns };
    },
    queryConfig,
  );
};

/**
 * The output document meta defines what the column names are for each variably-named
 * field in the CSV.
 */
export const useAnomalyStatsMeta = (workflowId, queryConfig) =>
  useQuery(
    [RAW_ANOMALY_STATS_META_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchAnomalyDetectionMeta(workflowId);
      const { data } = response;

      return data;
    },
    queryConfig,
  );

const fetchProcessedHistoryMeta = async (workflowId, locationGroupId) =>
  axios.get(GET_PROCESSED_HISTORY_META, {
    params: { workflow_id: workflowId, location_group_id: locationGroupId },
  });

const fetchProcessedHistoryRowsForAnomaly = async (
  workflowId,
  productDimIds,
  productDimValues,
  date,
  timeGranularity,
  locationGroupId,
) =>
  axios.get(GET_PROCESSED_HISTORY_RAW, {
    params: {
      workflow_id: workflowId,
      product_dimension_ids: productDimIds,
      product_dimension_values: productDimValues,
      date,
      time_granularity: timeGranularity,
      location_group_id: locationGroupId,
    },
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/octet-stream, application/json',
    },
  });

/**
 * For now, these possibilities are specific to the Anomaly Detection dashboard and are not generically
 * applicable to all dashboards.
 *
 * TODO: Extract these and related methods to their own module and make them as generic as possible.
 */
export const NUMERIC_FIELD_TYPES = Object.freeze(['REV', 'MEASURE']);
export const DATELIKE_FIELD_TYPES = Object.freeze(['TRAN', 'DATE']);

const transformAnomalyHistory = (value, header, legend) => {
  let finalValue = value;

  const { columnsKeyedByColumnName } = legend;
  const fieldType = columnsKeyedByColumnName[header]?.field;

  if (NUMERIC_FIELD_TYPES.includes(fieldType)) {
    finalValue = parseFloat(value);
  }

  return finalValue;
};

const ANOMALY_HISTORY_QUERY_KEY_BASE = 'rawAnomalyHistory';
const ANOMALY_HISTORY_META_QUERY_KEY_BASE = 'rawAnomalyHistoryMeta';

export const useAnomalyHistoryDetail = (params, anomalyHistoryLegend, queryConfig) => {
  const { workflowId, productDimIds, productDimValues, date, timeGranularity, locationGroupId } = params;

  return useQuery(
    [
      ANOMALY_HISTORY_QUERY_KEY_BASE,
      workflowId,
      productDimIds,
      productDimValues,
      date,
      timeGranularity,
      locationGroupId,
    ],
    async () => {
      const { data } = await fetchProcessedHistoryRowsForAnomaly(
        workflowId,
        productDimIds,
        productDimValues,
        date,
        timeGranularity,
        locationGroupId,
      );

      // Convert raw bytes to a string
      const decoder = new TextDecoder('utf-8');
      const csvData = decoder.decode(data);

      // Parse the data into Array<Object>
      const parseResults = readString(csvData, {
        header: true,
        transform: (value, header) => transformAnomalyHistory(value, header, anomalyHistoryLegend),
      });
      const { data: rows, errors } = parseResults;

      // Remove any rows that contained too many or two few columns
      errors.forEach(error => {
        rows.splice(error.row, 1);
      });

      return rows;
    },
    queryConfig,
  );
};

export const useAnomalyHistoryMeta = (workflowId, locationGroupId) =>
  useQuery([ANOMALY_HISTORY_META_QUERY_KEY_BASE, workflowId, locationGroupId], async () => {
    const response = await fetchProcessedHistoryMeta(workflowId, locationGroupId);

    return response.data;
  });

/**
 * Methods/Hooks related to rows' "reviewed" and "exclusion" statuses
 */

const fetchHistoryRowStatuses = workflowId =>
  axios.get(GET_PROCESSED_HISTORY_STATUS, { params: { workflow_id: workflowId } });

const createHistoryRowStatuses = (workflowId, statusesToInsert) =>
  axios.post(GET_PROCESSED_HISTORY_STATUS, { insert: statusesToInsert }, { params: { workflow_id: workflowId } });

const beginProcessingExclusions = workflowId =>
  axios.post(GET_PROCESSED_HISTORY_STATUS, null, {
    params: { workflow_id: workflowId, operation: 'BEGIN_PROCESSING_EXCLUSIONS' },
  });

const updateHistoryRowStatuses = (workflowId, statusesToUpdate) =>
  axios.put(GET_PROCESSED_HISTORY_STATUS, { update: statusesToUpdate }, { params: { workflow_id: workflowId } });

const deleteHistoryRowStatuses = (workflowId, primaryKeys) =>
  axios.delete(GET_PROCESSED_HISTORY_STATUS, {
    params: { workflow_id: workflowId },
    data: {
      delete: primaryKeys,
    },
  });

const PROCESSED_HISTORY_ROW_STATUS_QUERY_KEY = ['processedHistoryRowStatuses'];

const useProcessedHistoryRowStatuses = (workflowId, queryConfig) =>
  useQuery(
    PROCESSED_HISTORY_ROW_STATUS_QUERY_KEY,
    async () => {
      const response = await fetchHistoryRowStatuses(workflowId);

      return response?.data;
    },
    queryConfig,
  );

const useInsertRowStatusesMutation = (workflowId, mutationConfig) =>
  useMutation(async ({ statusesToInsert }) => createHistoryRowStatuses(workflowId, statusesToInsert), mutationConfig);

const useUpdateRowStatusesMutation = (workflowId, mutationConfig) =>
  useMutation(async ({ statusesToUpdate }) => updateHistoryRowStatuses(workflowId, statusesToUpdate), mutationConfig);

const useDeleteRowStatusesMutation = (workflowId, mutationConfig) =>
  useMutation(async ({ primaryKeys }) => deleteHistoryRowStatuses(workflowId, primaryKeys), mutationConfig);

export const useBeginProcessingExclusionsMutation = mutationConfig =>
  useMutation(async ({ workflowId }) => beginProcessingExclusions(workflowId), mutationConfig);

const ExcludeState = {
  NO: null,
  STAGED: 'STAGED',
  IN_PROGRESS: 'IN_PROGRESS',
  YES: 'YES',
};

const EXCLUDED_STATUSES = Object.freeze(new Set([ExcludeState.YES]));

export const useProcessedHistoryRowStatusState = workflowId => {
  const [statusesByPrimaryKey, setStatusesByPrimaryKey] = React.useState(new Map());
  useProcessedHistoryRowStatuses(workflowId, {
    onSuccess: initialDatabaseRowStatuses => {
      const keyedStatuses = new Map();

      initialDatabaseRowStatuses.forEach(status => {
        keyedStatuses.set(status.primary_key, status);
      });

      setStatusesByPrimaryKey(keyedStatuses);
    },
  });

  const insertRowStatusesMutation = useInsertRowStatusesMutation(workflowId);
  const updateRowStatusesMutation = useUpdateRowStatusesMutation(workflowId);
  const deleteRowStatusesMutation = useDeleteRowStatusesMutation(workflowId);

  const isStagedForExclusion = primaryKey =>
    statusesByPrimaryKey.get(primaryKey)?.exclude_state === ExcludeState.STAGED;
  const isExclusionInProgress = primaryKey =>
    statusesByPrimaryKey.get(primaryKey)?.exclude_state === ExcludeState.IN_PROGRESS;

  /**
   * If true, this row should not be shown in any lists
   */
  const isExcluded = primaryKey => EXCLUDED_STATUSES.has(statusesByPrimaryKey.get(primaryKey)?.exclude_state);

  /**
   * Corresponds to the "number of pending changes" that will be processed if the user
   * clicks the "Process Pending Changes" button
   */
  const numStagedForExclusion = Array.from(statusesByPrimaryKey.keys()).filter(key => isStagedForExclusion(key)).length;

  /**
   * If true, at least one row is currently in the process of being excluded by a fresh recomputation of anomalies
   */
  const changesAreInProgress = Array.from(statusesByPrimaryKey.keys()).some(key => isExclusionInProgress(key));

  const isReviewed = React.useCallback(
    primaryKey => {
      const status = statusesByPrimaryKey.get(primaryKey);
      return status != null && status.reviewed;
    },
    [statusesByPrimaryKey],
  );

  const updateStatus = (primaryKey, value) =>
    setStatusesByPrimaryKey(statuses => {
      statuses = new Map(statuses);
      statuses.set(primaryKey, value);

      return statuses;
    });

  const deleteStatus = primaryKey =>
    setStatusesByPrimaryKey(statuses => {
      statuses = new Map(statuses);
      statuses.delete(primaryKey);

      return statuses;
    });

  const toggleRowExcluded = async primaryKey => {
    const status = statusesByPrimaryKey.get(primaryKey);
    const statusExists = statusesByPrimaryKey.has(primaryKey);

    const isDeleteable = status != null && !status.reviewed && status.exclude_state !== ExcludeState.NO;

    if (statusExists) {
      if (isDeleteable) {
        deleteStatus(primaryKey);
        await deleteRowStatusesMutation.mutateAsync({ primaryKeys: [primaryKey] });
      } else {
        const shouldStage = !isStagedForExclusion(primaryKey);

        const value = {
          primary_key: primaryKey,
          reviewed: shouldStage ? true : isReviewed(primaryKey),
          exclude_state: shouldStage ? ExcludeState.STAGED : ExcludeState.NO,
        };

        updateStatus(primaryKey, value);
        await updateRowStatusesMutation.mutateAsync({
          statusesToUpdate: [value],
        });
      }
    } else {
      const value = { primary_key: primaryKey, reviewed: true, exclude_state: ExcludeState.STAGED };

      updateStatus(primaryKey, value);
      await insertRowStatusesMutation.mutateAsync({
        statusesToInsert: [value],
      });
    }
  };

  const toggleRowReviewed = async primaryKey => {
    const status = statusesByPrimaryKey.get(primaryKey);
    const statusExists = statusesByPrimaryKey.has(primaryKey);

    const isDeleteable = status != null && status.reviewed && status.exclude_state === ExcludeState.NO;

    if (statusExists) {
      if (isReviewed(primaryKey) && isStagedForExclusion(primaryKey)) {
        // Do not allow the user to toggle to "unreviewed" if the row is marked for exclusion
        return;
      }

      if (isDeleteable) {
        deleteStatus(primaryKey);
        await deleteRowStatusesMutation.mutateAsync({ primaryKeys: [primaryKey] });
      } else {
        const value = {
          primary_key: primaryKey,
          reviewed: !isReviewed(primaryKey),
          exclude_state: status?.exclude_state,
        };

        updateStatus(primaryKey, value);
        await updateRowStatusesMutation.mutateAsync({
          statusesToUpdate: [value],
        });
      }
    } else {
      // exclude_state becomes null since the status doesn't currently exist
      const value = { primary_key: primaryKey, reviewed: true, exclude_state: ExcludeState.NO };

      updateStatus(primaryKey, value);
      await insertRowStatusesMutation.mutateAsync({
        statusesToInsert: [value],
      });
    }
  };

  const transitionFromStagedToInProgress = () =>
    setStatusesByPrimaryKey(statuses => {
      statuses = new Map(statuses);
      statuses.forEach(status => {
        if (status.exclude_state === ExcludeState.STAGED) {
          status.exclude_state = ExcludeState.IN_PROGRESS;
        }
      });

      return statuses;
    });

  return {
    statusesByPrimaryKey,
    numStagedForExclusion,
    changesAreInProgress,
    isExcluded,
    isStagedForExclusion,
    isExclusionInProgress,
    isReviewed,
    toggleRowExcluded,
    toggleRowReviewed,
    transitionFromStagedToInProgress,
  };
};

const fetchAnomalySegmentGraphData = async (workflowId, productDimIds, productDimValues, timeGranularity) =>
  axios.get(GET_ANOMALY_SEGMENT, {
    params: {
      workflow_id: workflowId,
      product_dimension_ids: productDimIds,
      product_dimension_values: productDimValues,
      time_granularity: timeGranularity,
    },
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/octet-stream, application/json',
    },
  });

const fetchAnomalySegmentMeta = async (workflowId, productDimIds, productDimValues, timeGranularity) =>
  axios.get(GET_ANOMALY_SEGMENT_META, {
    params: {
      workflow_id: workflowId,
      product_dimension_ids: productDimIds,
      product_dimension_values: productDimValues,
      time_granularity: timeGranularity,
    },
  });

const transformAnomalyGraphData = (value, header) => {
  let finalValue = value;

  switch (header) {
    case 'segmentActual':
    case 'segmentPredicted':
      finalValue = parseFloat(value);
      break;
    default:
      if (_.startsWith(header, 'slice')) {
        finalValue = parseFloat(value);
      }
      break;
  }

  return finalValue;
};

const ANOMALY_SEGMENT_GRAPH_DATA_QUERY_KEY_BASE = 'anomalySegmentGraphData';
const ANOMALY_SEGMENT_GRAPH_DATA_META_QUERY_KEY_BASE = 'anomalySegmentGraphMeta';

export const useAnomalyGraphData = (params, queryConfig) => {
  const { workflowId, productDimIds, productDimValues, timeGranularity } = params;

  return useQuery(
    [ANOMALY_SEGMENT_GRAPH_DATA_QUERY_KEY_BASE, workflowId, productDimIds, productDimValues, timeGranularity],
    async () => {
      const { data } = await fetchAnomalySegmentGraphData(workflowId, productDimIds, productDimValues, timeGranularity);

      // Convert raw bytes to a string
      const decoder = new TextDecoder('utf-8');
      const csvData = decoder.decode(data);

      // Parse the data into Array<Object>
      const parseResults = readString(csvData, {
        header: true,
        transformHeader: camelcase,
        transform: (value, header) => transformAnomalyGraphData(value, header),
      });
      const { data: rows, errors } = parseResults;

      // Remove any rows that contained too many or two few columns
      errors.forEach(error => {
        rows.splice(error.row, 1);
      });

      return rows;
    },
    queryConfig,
  );
};

export const useAnomalySegmentStatsMeta = (params, queryConfig) => {
  const { workflowId, productDimIds, productDimValues, timeGranularity } = params;

  return useQuery(
    [ANOMALY_SEGMENT_GRAPH_DATA_META_QUERY_KEY_BASE, workflowId, productDimIds, productDimValues, timeGranularity],
    async () => {
      const { data } = await fetchAnomalySegmentMeta(workflowId, productDimIds, productDimValues, timeGranularity);
      return data;
    },
    queryConfig,
  );
};

export const INVALID_QUERY_KEYS_AFTER_RESULTS_REFRESH = [
  RAW_ANOMALY_STATS_QUERY_KEY_BASE,
  RAW_ANOMALY_STATS_META_QUERY_KEY_BASE,
  PROCESSED_HISTORY_ROW_STATUS_QUERY_KEY,
  ANOMALY_HISTORY_QUERY_KEY_BASE,
  ANOMALY_HISTORY_META_QUERY_KEY_BASE,
  ANOMALY_SEGMENT_GRAPH_DATA_QUERY_KEY_BASE,
  ANOMALY_SEGMENT_GRAPH_DATA_META_QUERY_KEY_BASE,
];
