import _ from 'lodash';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useNavigateToPOSGuidedSetupStep } from './commonStepControl';
import IntegrationInstructionsPage from './IntegrationInstructionsPage';
import PreviousStep from './PreviousStep';
import { RedirectBehavior } from '../../dataSources/dataSourceConstants';
import { useGuidedSetup } from '../../../data-access/query/guidedSetup';
import { PartnerInstructions } from './PartnerInstructions';

const GuidedSetupPartnerInstructions = props => {
  const { partner } = props.location.state;

  const { data: { is_complete: isComplete } = {}, isLoading } = useGuidedSetup();
  const { onClick: goBack } = useNavigateToPOSGuidedSetupStep();

  if (!isLoading && isComplete) {
    return <Redirect to="/login-redirect" />;
  }

  localStorage.setItem(`${_.toLower(partner)}_redirect_behavior`, RedirectBehavior.GUIDED_SETUP);

  return (
    <IntegrationInstructionsPage dataSource={partner} Back={() => <PreviousStep onClick={goBack} />}>
      <PartnerInstructions partner={partner} />
    </IntegrationInstructionsPage>
  );
};

export default GuidedSetupPartnerInstructions;
