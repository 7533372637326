import React from 'react';
import './tooltip.scss';

const Tooltip = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className="pace-tooltip-container"
      style={{
        position: 'absolute',
        left: `${props.x}px`,
        top: `${props.y}px`,
      }}
    >
      <div className="pace-tooltip">{props.content}</div>
    </div>
  );
});

Tooltip.displayName = 'Tooltip';

export default Tooltip;
