import axios from 'axios';
import { useMutation } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

const createDimensionValues = dimensionValues =>
  axios.post(`${KA_API_URL}/products/dimensions/values`, dimensionValues);

const updateDimensionValues = dimensionValues => axios.put(`${KA_API_URL}/products/dimensions/values`, dimensionValues);

const deleteDimensionValues = dimensionValues =>
  axios.delete(`${KA_API_URL}/products/dimensions/values`, { data: dimensionValues });

const updateRawDimensionValues = rawDimensionValues =>
  axios.put(`${KA_API_URL}/products/dimensions/raw/values`, rawDimensionValues);

/**
 * Mutations
 */

export const useEditDimensionValuesMutations = () => {
  const createDimensionValuesMutation = useMutation(async ({ values }) => {
    await createDimensionValues(values);
  });

  const updateDimensionValuesMutation = useMutation(async ({ values }) => {
    await updateDimensionValues(values);
  });

  const deleteDimensionValuesMutation = useMutation(async ({ values }) => {
    await deleteDimensionValues(values);
  });

  return {
    createDimensionValuesMutation,
    updateDimensionValuesMutation,
    deleteDimensionValuesMutation,
  };
};

export const useEditRawDimensionValuesMutations = () => {
  const updateRawDimensionValuesMutation = useMutation(async ({ updates }) => {
    await updateRawDimensionValues(updates);
  });

  return {
    updateRawDimensionValuesMutation,
  };
};
