import React from 'react';
import Select from 'react-select';

const UserSelect = props => {
  return (
    <Select
      {...props}
      getOptionValue={option => option.label}
      isSearchable
      isClearable
      placeholder="Select a user..."
    />
  );
};

export default UserSelect;
