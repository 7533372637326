import { faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useCorporateMarketingParams } from './customerRegistrationHooks';
import ProfitRoverAppsTitle from './ProfitRoverAppsTitle';
import { REGISTRATION_STEPS, useRegistrationContext } from './registrationContext';
import { CheckmarkPasswordRuleList, emailIsValid } from './registrationUtil';
import { PasswordPolicy } from '../auth/password-policy';
import { baseProfitRoverRules } from '../auth/password-rules';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import ProfitRoverTermsOfUseLink from '../generic/ProfitRoverTermsOfUseLink';
import MailToSupportLink from '../generic/MailToSupportLink';
import { useRequestRegistrationCode } from '../../data-access/mutation/stripe';
import { gaEmitSendVerificationCodeButtonClick } from '../../google-analytics/signup';
import './request-code.scss';

const NoHassleBox = () => (
  <div className="no-hassle-box">
    <div className="no-hassle-box-content-container">
      <div className="no-hassle-top-line">No commitment. No hassle.</div>
      <div className="no-hassle-bottom-line">Easily cancel anytime with just a few clicks.</div>
    </div>
  </div>
);

const usePasswordVisibility = () => {
  const [passwordIsVisible, setPasswordIsVisible] = React.useState(false);

  const togglePasswordVisibility = React.useCallback(() => setPasswordIsVisible(!passwordIsVisible), [
    passwordIsVisible,
  ]);

  return [passwordIsVisible, togglePasswordVisibility];
};

const GatherAccountDetails = () => {
  const { setStep, emailAddress, setEmailAddress, password, setPassword } = useRegistrationContext();

  const requestCodeMutation = useRequestRegistrationCode();

  const [passwordIsAllowed, setPasswordIsAllowed] = React.useState(false);
  const [agreeToTermsAndPrivacy, setAgreeToTermsAndPrivacy] = React.useState(false);
  const [passwordIsVisible, togglePasswordVisibility] = usePasswordVisibility();
  const [activeInputField, setActiveInputField] = React.useState(null);
  const [errorRequestingCode, setErrorRequestingCode] = React.useState(false);
  const [initialPasswordFocus, setInitialPasswordFocus] = React.useState(false);

  const passwordPolicy = React.useMemo(() => new PasswordPolicy(baseProfitRoverRules), []);

  const passwordChangeHandler = inputPassword => setPasswordIsAllowed(passwordPolicy.checkPassword(inputPassword));

  const { emailParam: incomingEmailParam } = useCorporateMarketingParams();
  const incomingEmailParamIsSpecified = incomingEmailParam != null && incomingEmailParam !== '';
  const incomingEmailParamIsNotSpecified = !incomingEmailParamIsSpecified;

  const emailInputRef = React.useCallback(
    emailInput => {
      if (emailInput && (incomingEmailParamIsNotSpecified || errorRequestingCode)) {
        emailInput.focus();
      }
    },
    [incomingEmailParamIsNotSpecified, errorRequestingCode],
  );

  const passwordInputRef = React.useCallback(
    passwordInput => {
      if (emailAddress == null || password === '') {
        passwordPolicy.resetRules();
      }
      if (passwordInput && !initialPasswordFocus && incomingEmailParamIsSpecified) {
        passwordInput.focus();
        setInitialPasswordFocus(true);
      }
    },
    [emailAddress, password, initialPasswordFocus, incomingEmailParamIsSpecified, passwordPolicy],
  );

  const enableNextStepButton = passwordIsAllowed && agreeToTermsAndPrivacy && emailIsValid(emailAddress);

  const submitFormButton = async () => {
    gaEmitSendVerificationCodeButtonClick();

    try {
      await requestCodeMutation.mutateAsync({ emailAddress, password });
      setStep(REGISTRATION_STEPS.REDEEM_CODE);
    } catch (error) {
      setErrorRequestingCode(true);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="request-code">
        <div className="text-center my-5">
          <ProfitRoverAppsTitle />
          <div>
            <span className="profitrover-text-subtitle">
              You&apos;re just a few steps away from profit-enhancing insights.
            </span>
          </div>
        </div>
        <div className="content-container">
          <div className="create-account-container">
            <p className="title">Create an Account</p>
            <form>
              <label className="input-field" htmlFor="user-registration-email" style={{ marginBottom: 20 }}>
                {activeInputField === 'user-registration-email' && (
                  <span className="input-field-dynamic-label">Email</span>
                )}
                <input
                  type="email"
                  id="user-registration-email"
                  value={emailAddress}
                  onChange={e => {
                    setEmailAddress(e.target.value);
                    setErrorRequestingCode(false);
                  }}
                  onFocus={e => setActiveInputField(e.target.id)}
                  onBlur={() => setActiveInputField(null)}
                  placeholder="Email"
                  ref={emailInputRef}
                />
              </label>
              <label className="input-field" htmlFor="user-registration-password" style={{ marginBottom: 10 }}>
                {activeInputField === 'user-registration-password' && (
                  <span className="input-field-dynamic-label">Password</span>
                )}
                <input
                  type={passwordIsVisible ? 'text' : 'password'}
                  id="user-registration-password"
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    passwordChangeHandler(e.target.value);
                  }}
                  onFocus={e => setActiveInputField(e.target.id)}
                  onBlur={() => setActiveInputField(null)}
                  placeholder="Password"
                  ref={passwordInputRef}
                />
                <FontAwesomeIcon
                  className="password-visibility-icon"
                  onClick={togglePasswordVisibility}
                  icon={passwordIsVisible ? faEye : faEyeSlash}
                />
              </label>

              <CheckmarkPasswordRuleList rules={passwordPolicy.rules} />

              <label htmlFor="agree" className="agree-checkbox-container">
                <input type="checkbox" id="agree" onChange={() => setAgreeToTermsAndPrivacy(!agreeToTermsAndPrivacy)} />
                <span className="checkmark" />
                <div style={{ lineBreak: 'loose' }}>
                  By clicking &quot;Send Me a Verification Code&quot;, I agree to the ProfitRover&nbsp;
                  <ProfitRoverTermsOfUseLink>Terms</ProfitRoverTermsOfUseLink>
                  &nbsp;&amp;&nbsp;
                  <a href="https://profitrover.com/privacy/" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                </div>
              </label>
            </form>

            <ProfitRoverPrimaryButton
              className="send-code-button"
              disabled={!enableNextStepButton}
              onClick={submitFormButton}
            >
              <FontAwesomeIcon icon={faLock} style={{ marginRight: '9px', fontSize: '19px' }} />
              Send Me a Verification Code
            </ProfitRoverPrimaryButton>

            {errorRequestingCode ? (
              <div className="error-text">
                An error occurred sending you a verification code. Please enter your information again or
                <MailToSupportLink
                  subject="Registration Assistance Required"
                  body="I am having trouble creating an account. 
                  There is an error when I press the Send Me A Verification Code button."
                >
                  &nbsp;email our support team
                </MailToSupportLink>
                .
              </div>
            ) : (
              <div className="email-explanation-text">
                We will send you an email with a verification code to validate your account.
              </div>
            )}
          </div>

          <NoHassleBox />
        </div>
      </div>
    </div>
  );
};

export default GatherAccountDetails;
