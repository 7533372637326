/* eslint-disable react/no-danger */
/* eslint-disable react/display-name */
import React, { Fragment } from 'react';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { formatCurrency } from '../../../util/format';

const moment = require('moment');

const homeGamesTable = props => {
  const {
    gameArray,
    homeGamesSortObject,
    selectedGameIndex,
    intitialHighLightGame,
    intitialHighLightGameIndex,
    productVisits,
    futurePriceDemand,
    renderGameInfo,
    renderGameInfoLogo,
    renderKickoffTime,
    renderRevenueSummary,
    handleTableDataClick,
    handleRowClick,
    calculateHomeGamesSortData,
  } = props;
  return (
    <Fragment>
      <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>Upcoming Home Games</h6>
      <ReactTable
        data={gameArray}
        columns={[
          {
            Header: (
              <div className="row">
                <div className="col-3" />
                <div className="col-2" style={{ paddingTop: '12px' }}>
                  Date
                </div>
                <div className="col-1" style={{ marginLeft: '5px', paddingTop: '5px' }}>
                  {' '}
                  {homeGamesSortObject.date.is_sorted === true ? (
                    homeGamesSortObject.date.direction === 'ASC' ? (
                      <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                    )
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            ),
            headerStyle: {
              background: '#ffc500',
              height: '55px',
              fontWeight: 'bold',
              border: '1px solid white',
            },
            id: 'date',
            sortable: true,
            accessor: d => (
              <div
                style={{
                  cursor: 'pointer',
                }}
                dangerouslySetInnerHTML={{
                  __html: moment.utc(d.date).format('YYYY-MM-DD'),
                }}
              />
            ),
          },
          {
            Header: (
              <div className="row">
                <div className="col-2" />
                <div className="col-4" style={{ paddingTop: '12px', marginLeft: '9px' }}>
                  Opponent
                </div>
                <div className="col-1" style={{ marginLeft: '15px', paddingTop: '5px' }}>
                  {' '}
                  {homeGamesSortObject.opponent.is_sorted === true ? (
                    homeGamesSortObject.opponent.direction === 'ASC' ? (
                      <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                    )
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            ),
            headerStyle: {
              background: '#ffc500',
              height: '55px',
              fontWeight: 'bold',
              border: '1px solid white',
            },
            id: 'opponent',
            accessor: d => d.opponent,
            sortable: true,
            Cell: row => (
              <div>
                <img src={row.original.logo} height="30px" alt="" align="left" />{' '}
                {row.original.rank != null ? (
                  <span style={{ verticalAlign: 'middle' }}>#{row.original.rank}&nbsp;</span>
                ) : (
                  <div />
                )}
                <span
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  {row.value}
                </span>
              </div>
            ),
            width: 170,
          },
          {
            Header: (
              <div className="row" style={{ paddingTop: '2px' }}>
                <div className="col-3" />
                <div className="col-4" style={{ marginLeft: '5px' }}>
                  <div className="row">Revenue</div>
                  <div className="row" style={{ paddingLeft: '10px' }}>
                    Uplift
                  </div>
                </div>
                <div className="col-1" style={{ paddingTop: '2px' }}>
                  {' '}
                  {homeGamesSortObject.revenue.is_sorted === true ? (
                    homeGamesSortObject.revenue.direction === 'ASC' ? (
                      <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                    )
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            ),
            headerStyle: {
              background: '#ffc500',
              height: '55px',
              fontWeight: 'bold',
              border: '1px solid white',
            },
            id: 'revenue',
            sortable: true,
            accessor: d => formatCurrency(d.revenue, false),
            Cell: row => (
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  background: row.original.revenue === 0 ? '' : `rgb(0,175,79,${row.original.color})`,
                  color: 'black',
                }}
              >
                {row.value}
              </div>
            ),
          },
        ]}
        getTheadThProps={(state, rowInfo, column) => {
          return {
            onClick: () => {
              if (column.sortable) {
                calculateHomeGamesSortData(column.id);
              }
            },
          };
        }}
        getTdProps={(state, rowInfo) => {
          if (typeof rowInfo !== 'undefined') {
            return {
              style: {
                borderRight: '1px solid white',
                cursor: 'pointer',
              },

              onClick: (e, handleOriginal) => {
                renderGameInfoLogo(rowInfo.original.opponent);
                renderGameInfo(rowInfo.original);
                renderKickoffTime(rowInfo.original.date);
                handleTableDataClick(rowInfo.original);
                renderRevenueSummary(rowInfo.original, productVisits, futurePriceDemand);

                if (handleOriginal) {
                  handleOriginal();
                }
              },
            };
          }
          return {};
        }}
        getTrProps={(state, rowInfo) => {
          if (typeof rowInfo !== 'undefined') {
            return {
              onClick: (e, handleOriginal) => {
                handleRowClick(rowInfo.index);
                if (handleOriginal) {
                  handleOriginal();
                }
              },
              style: {
                background:
                  rowInfo.index === selectedGameIndex ||
                  (intitialHighLightGame && rowInfo.index === intitialHighLightGameIndex)
                    ? '#c0c0c0'
                    : '#e8eef6',
              },
            };
          }
          return {
            onClick: (e, handleOriginal) => {
              if (handleOriginal) {
                handleOriginal();
              }
            },
            style: {
              background: '#e8eef6',
            },
          };
        }}
        noDataText="Loading Schedule"
        pageSize={gameArray.length > 0 ? gameArray.length : 7}
        showPagination={false}
        className="-highlight"
      />
    </Fragment>
  );
};

export default homeGamesTable;
