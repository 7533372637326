import axios from 'axios';

/**
 * This particular Axios instance is to be used only during the user registration flow to help prevent
 * any potential abuse of the public endpoints exposed.
 *
 * It allows us to intercept the requests needing to handle the specialized access token - mostly unknown
 * to the React component hierarchy of the page during the user registration UI flows.
 */

const accessTokenInstance = axios.create();

export default accessTokenInstance;
