import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ProfitRoverAppsTitle from './ProfitRoverAppsTitle';
import { useRedeemRegistrationCode, useRequestRegistrationCode } from '../../data-access/mutation/stripe';
import { gaEmitSubmitRegistrationCodeButtonClick } from '../../google-analytics/signup';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import { CenteredProfitRoverSpinner } from '../spinner/ProfitRoverSpinner';
import { REGISTRATION_STEPS, useRegistrationContext } from './registrationContext';
import './redeem-code.scss';

const NotificationToDisplay = props => {
  const { notification } = props;

  if (notification === 'resent') {
    return <div className="resent-email-text">We sent another code to your email!</div>;
  }

  let notificationMessage;
  switch (notification) {
    case 'expired':
      notificationMessage = 'Your registration code has expired! Please resend a new code below.';
      break;
    case 'expired-retry':
      notificationMessage = 'Your registration code has expired! A new one has been emailed to you.';
      break;
    case 'invalid':
      notificationMessage = 'Your registration code is invalid! Please check your code or request a new one below.';
      break;
    case 'resent-request-error':
      notificationMessage =
        'There was an error resending your registration code. Please try again or refresh the page.';
      break;
    case 'request-error':
      notificationMessage =
        'There was an unexpected error while verifying your registration code. Please try again or refresh the page.';
      break;
    default:
      notificationMessage = '';
  }

  return <div className="registration-code-error-text">{notificationMessage}</div>;
};

// TODO: Figure out what to do about this
const PROMO_CODE = null;

const RedeemCode = () => {
  const { setStep, emailAddress, password, selectedPlan } = useRegistrationContext();

  const [registrationCode, setRegistrationCode] = React.useState('');
  const [notificationToDisplay, setNotificationToDisplay] = React.useState('');

  const requestCodeMutation = useRequestRegistrationCode();
  const redeemCodeMutation = useRedeemRegistrationCode();
  const { isLoading: isSubmitting } = redeemCodeMutation;

  const handleResend = async () => {
    try {
      await requestCodeMutation.mutateAsync({ emailAddress, password });
      setNotificationToDisplay('resent');
    } catch (e) {
      setNotificationToDisplay('resent-request-error');
    }
  };

  const onSubmitRegistrationCode = async () => {
    gaEmitSubmitRegistrationCodeButtonClick();

    try {
      const selectedPriceId = selectedPlan.id;

      await redeemCodeMutation.mutateAsync({
        registrationCode,
        emailAddress,
        selectedPriceId,
        promoCode: PROMO_CODE,
      });

      setStep(REGISTRATION_STEPS.AUTO_LOGIN);
    } catch (error) {
      const {
        response: { status: statusCode },
      } = error;

      if (statusCode === 403) {
        /**
         * If the server reponds with 403, it should have automatically sent the user an email
         * with a new registration code.
         */
        setNotificationToDisplay('expired-retry');
      } else if (statusCode === 400) {
        setNotificationToDisplay('invalid');
      } else {
        setNotificationToDisplay('request-error');
      }

      setRegistrationCode('');
    }
  };

  const isRegistrationCodeValid = !(registrationCode.length === 6);
  const submitButtonDisabled = isRegistrationCodeValid || isSubmitting;

  return (
    <div className="d-flex justify-content-center">
      <div className="redeem-code d-flex flex-column align-items-center">
        <div className="mt-5 text-center">
          <ProfitRoverAppsTitle />
          <div>
            <span className="profitrover-text-subtitle">
              You&apos;re just a few steps away from profit-enhancing insights.
            </span>
          </div>
        </div>
        <div className="mt-5 redeem-code-container">
          <p className="title">Enter Your Verification Code</p>
          <p className="subtitle">
            We just sent a code to <b style={{ fontWeight: '700' }}>{emailAddress}</b>
          </p>
          <input
            type="text"
            onChange={e => setRegistrationCode(e.target.value)}
            value={registrationCode}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />

          {notificationToDisplay && <NotificationToDisplay notification={notificationToDisplay} />}

          <div className="d-flex justify-content-center">
            <button type="button" onClick={handleResend} className="resend-code-button">
              Send me another code
            </button>
          </div>

          <ProfitRoverPrimaryButton
            className="redeem-code-button"
            disabled={submitButtonDisabled}
            onClick={onSubmitRegistrationCode}
          >
            {isSubmitting ? (
              <CenteredProfitRoverSpinner />
            ) : (
              <>
                <FontAwesomeIcon icon={faLock} style={{ marginRight: '9px', fontSize: '19px' }} />
                Verify My Account
              </>
            )}
          </ProfitRoverPrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default RedeemCode;
