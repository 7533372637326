import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import moment from 'moment-timezone';
import React from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { PaymentFailedError } from '../checkoutErrors';
import EmptyPage from '../../common/EmptyPage';
import LoadingIndicator from '../../common/LoadingIndicator';
import { ProfitRoverCancelButton, ProfitRoverPrimaryButton } from '../../../forms/ProfitRoverButtons';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import { ProfitRoverHeaderLogo, ProfitRoverLinearLogo } from '../../../generic/ProfitRoverLogos';
import { CenteredProfitRoverSpinner } from '../../../spinner/ProfitRoverSpinner';
import { BILLING_INFO_QUERY_KEY_BASE, SUBSCRIPTIONS_QUERY_KEY } from '../../../../data-access/query/subscriptions';

export const CheckoutHeaderBar = ({ className }) => (
  <div className="checkout-header-bar">
    <div className={classnames('header-container', className)}>
      <ProfitRoverHeaderLogo className="profitrover-apps-header-logo" />
      <span className="checkout-text">Checkout</span>
    </div>
  </div>
);

export const PageTitle = ({ children }) => (
  <h3 className="page-title">
    <span className="page-title-box">{children}</span>
  </h3>
);

export const PaymentFailedMessage = ({ onClick }) => {
  return (
    <div className="payment-failed-message">
      <FontAwesomeIcon icon={faTimesCircle} size="3x" />
      <div className="payment-failed-notice">Payment Failed</div>
      <button type="button" className="confirm-payment-method-text" onClick={onClick}>
        <span className="link-text">Please confirm your payment method</span> and try again.
      </button>
    </div>
  );
};

export const ChangePaymentCardButton = ({ onClick }) => {
  return (
    <button type="button" className="change-payment-method-text" onClick={onClick}>
      Change
    </button>
  );
};

export const PaymentMethodSection = ({ defaultPaymentMethodState, paymentFailed, onClick }) => {
  const { data, error: billingInfoError, isFetching } = defaultPaymentMethodState;
  const { default_payment_method: defaultPaymentMethod } = data ?? {};
  const hasPaymentMethod = defaultPaymentMethod != null;

  let paymentMethodDisplay;
  if (isFetching) {
    paymentMethodDisplay = <LoadingIndicator />;
  } else if (billingInfoError) {
    paymentMethodDisplay = 'Unknown';
  } else if (!hasPaymentMethod) {
    paymentMethodDisplay = (
      <button onClick={onClick} type="button" className="payment-method-add-text">
        Add Payment Method
      </button>
    );
  } else {
    const { last4: last4Digits } = defaultPaymentMethod;
    let { brand } = defaultPaymentMethod;
    brand = brand?.toUpperCase();

    paymentMethodDisplay = `${brand}........${last4Digits}`;
  }

  return (
    <>
      <SpreadText
        className={classnames('payment-method-section', {
          'payment-failed': paymentFailed,
          'missing-payment': !hasPaymentMethod,
        })}
      >
        {!isFetching && hasPaymentMethod && <div>Payment Method</div>}
        <div>{paymentMethodDisplay}</div>
      </SpreadText>
      {!hasPaymentMethod && <div style={{ margin: '10px 0px', border: '.25px solid black', opacity: '20%' }} />}
      {hasPaymentMethod && <ChangePaymentCardButton onClick={onClick} />}
    </>
  );
};

export const SpreadText = ({ children, className }) => (
  <div className={classnames('d-flex justify-content-between', className)}>{children}</div>
);

export const BubbleLabel = ({ children }) => <h5>{children}</h5>;

export const ConfirmChangesButton = ({
  disabled,
  changesInProgress,
  customerWillBeCharged,
  onConfirmChanges,
  onTryAgain,
  submissionError,
}) => {
  let props;
  let buttonText;

  const paymentFailedAtLeastOnce = submissionError instanceof PaymentFailedError;

  if (!paymentFailedAtLeastOnce) {
    buttonText = customerWillBeCharged ? 'Pay Amount Due' : 'Confirm Changes';
    props = {
      onClick: onConfirmChanges,
      style: { minWidth: 187.175 },
    };
  } else {
    buttonText = 'Try Payment Again';
    props = {
      onClick: onTryAgain,
      style: { minWidth: 150 },
    };
  }

  return (
    <ProfitRoverPrimaryButton {...props} disabled={disabled}>
      {!changesInProgress ? (
        <>
          <FontAwesomeIcon icon={faLock} /> {buttonText}
        </>
      ) : (
        <CenteredProfitRoverSpinner />
      )}
    </ProfitRoverPrimaryButton>
  );
};

export const NotFound = () => (
  <EmptyPage>
    <h2 style={{ textAlign: 'center' }}>
      Please <Link to="/help">contact us</Link> to update/manage your subscription.
    </h2>
  </EmptyPage>
);

/*
 * A ProfitRoverCancelButton with some special behavior in the case of a failed payment. Intended for use on our various
 * checkout screens.
 */
export const GoBackButton = ({ onClick, paymentFailed, subscriptionId, disabled }) => {
  const buttonLabel = paymentFailed ? 'Go Back' : 'Cancel';
  const queryClient = useQueryClient();

  const cancelOrGoBackWithRefetch = async () => {
    if (paymentFailed) {
      await Promise.allSettled([
        queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId]),
        queryClient.refetchQueries([...SUBSCRIPTIONS_QUERY_KEY]),
      ]);
    }

    onClick();
  };

  return (
    <ProfitRoverCancelButton onClick={cancelOrGoBackWithRefetch} disabled={disabled}>
      {buttonLabel}
    </ProfitRoverCancelButton>
  );
};

export const SubscriptionInfoCard = ({ children }) => (
  <ProfitRoverCard className="subscription-info-card">
    <div className="card-layout">{children}</div>
  </ProfitRoverCard>
);

export const Branding = () => (
  <div className="left-side">
    <ProfitRoverLinearLogo className="product-logo" />
    <div className="product-description-text">AI-Driven Insights to Make Your Business More Profitable.</div>
  </div>
);

export const BillingDate = ({ isFetching, billingInfoError, currentBillingCycleEnd, autoRenewIsOn = true }) => {
  if (isFetching) {
    return <LoadingIndicator />;
  }

  if (billingInfoError) {
    return 'Scheduled for (Unknown)';
  }

  const dateString = moment.unix(currentBillingCycleEnd).format('MMM Do, YYYY');

  if (dateString === 'Invalid date') {
    return '';
  }

  if (!autoRenewIsOn) {
    return `Paid Location insights expire on ${dateString}`;
  }

  return `Scheduled for ${dateString}`;
};
