import React from 'react';
import './profitrover-alpha-numeric-label.css';

const ProfitRoverAlphaNumericLabel = props => {
  const { text } = props;

  return <div className="profitrover-alpha-numeric-label">{text}</div>;
};

export default ProfitRoverAlphaNumericLabel;
