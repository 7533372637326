/* eslint-disable react/no-danger */
/* eslint-disable react/display-name */
import React from 'react';
import ReactTable from 'react-table';
import { formatNumber } from '../../../util/format';

const gameInfoSection = props => {
  const {
    currentGameInfoArray,
    selectedGameLogo,
    selectedGame,
    homeTeam,
    homeTeamLogo,
    seasonTicketsFlag,
    kickOff,
    selectedGameTicketsSold,
    selectedGameTicketsRemaining,
  } = props;
  return (
    <div>
      <div
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          fontSize: '21px',
          fontWeight: 'bold',
          marginBottom: '5px',
        }}
      >
        &nbsp; {selectedGame.opponent} Game Info
      </div>
      <div>
        {!seasonTicketsFlag ? <h6 style={{ fontSize: '16px' }}>Kickoff: {kickOff}</h6> : <div />}
        <h6 style={{ fontSize: '16px' }}>Tickets Sold: {formatNumber(selectedGameTicketsSold)}</h6>

        <h6 style={{ fontSize: '16px' }}>Remaining Capacity: {formatNumber(selectedGameTicketsRemaining)}</h6>
      </div>

      {!seasonTicketsFlag ? (
        <ReactTable
          data={currentGameInfoArray}
          columns={[
            {
              Header: <div />,
              id: 'product',
              headerStyle: {
                background: '#ffc500',
                height: '55px',
                paddingTop: '15px',
                fontWeight: 'bold',
                border: '1px solid white',
              },

              accessor: d => (
                <div
                  style={{
                    height: '45px',
                    fontWeight: 'bold',
                    paddingTop: '10px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: d.metric,
                  }}
                />
              ),
            },
            {
              Header: (
                <div>
                  {selectedGameLogo !== '' && selectedGameLogo !== undefined ? (
                    <div
                      id="qr"
                      style={{
                        display: 'inline-block',
                        align: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      <img src={selectedGameLogo} alt={selectedGame.opponent} height="50px" align="middle" />
                    </div>
                  ) : (
                    <div style={{ paddingTop: '9%' }}>{selectedGame.opponent}</div>
                  )}
                </div>
              ),
              headerStyle: {
                background: '#ffc500',
                height: '55px',
                fontWeight: 'bold',
                border: '1px solid white',
              },
              id: 'cur_price',
              accessor: d => d.awayTeam,

              Cell: row =>
                row.original.metric === 'Prior Game' ? (
                  <div style={{}}>
                    {row.value}
                    <div>{row.original.awayTeamScore}</div>
                  </div>
                ) : (
                  <div
                    style={{
                      paddingTop: '10px',
                    }}
                  >
                    {row.value}
                  </div>
                ),
            },
            {
              Header: (
                <div>
                  {homeTeamLogo !== '' && homeTeamLogo !== undefined ? (
                    <div
                      id="qr"
                      style={{
                        display: 'inline-block',
                        align: 'center',

                        fontWeight: 'bold',
                      }}
                    >
                      <img src={homeTeamLogo} alt={localStorage.getItem('team')} height="50px" align="middle" />
                    </div>
                  ) : (
                    <div style={{ paddingTop: '9%' }}>{homeTeam}</div>
                  )}
                </div>
              ),
              headerStyle: {
                background: '#ffc500',
                height: '55px',
                fontWeight: 'bold',
                border: '1px solid white',
              },
              id: 'rec_price',
              accessor: d => d.homeTeam,
              Cell: row =>
                row.original.metric === 'Prior Game' ? (
                  <div style={{}}>
                    {row.value}
                    <div>{row.original.homeTeamScore}</div>
                  </div>
                ) : (
                  <div
                    style={{
                      paddingTop: '10px',
                    }}
                  >
                    {row.value}
                  </div>
                ),
            },
          ]}
          getTdProps={() => {
            return {
              style: {
                borderRight: '1px solid white',
              },
            };
          }}
          getTrProps={(state, rowInfo) => {
            if (typeof rowInfo !== 'undefined') {
              return {
                style: {
                  background: '#e8eef6',
                },
              };
            }
            return {
              onClick: (e, handleOriginal) => {
                if (handleOriginal) {
                  handleOriginal();
                }
              },
              style: {
                background: '#e8eef6',
              },
            };
          }}
          sortable={false}
          getNoDataProps={() => {
            return { style: { marginTop: '40px' } };
          }}
          noDataText="Loading Last Game Results"
          defaultPageSize={3}
          showPagination={false}
          className="-highlight"
        />
      ) : null}
    </div>
  );
};

export default gameInfoSection;
