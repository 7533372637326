const SQUARE_ENVIRONMENT = process.env.REACT_APP_SQUARE_ENVIRONMENT;
const SQUARE_APPLICATION_ID = process.env.REACT_APP_SQUARE_APPLICATION_ID;
const SQUARE_OAUTH_PARTNER_NAME = process.env.REACT_APP_SQUARE_OAUTH_PARTNER_NAME;
const SQUARE_PERMISSION_SCOPES = process.env.REACT_APP_SQUARE_PERMISSION_SCOPES;

/**
 * for production add in the path params for tracking (per Square directive) and require the user to
 * force the seller to log in to their Square account before authorizing our app by setting the session
 * query param to false
 */
let baseURL;

if (SQUARE_ENVIRONMENT === 'sandbox') {
  baseURL = 'https://connect.squareupsandbox.com/oauth2/authorize?session=true';
} else if (SQUARE_ENVIRONMENT === 'production') {
  baseURL = 'https://squareup.com/t/cmtp_performance/pr_cross_product/d_partnerships/p_'
    .concat(SQUARE_OAUTH_PARTNER_NAME)
    .concat('/cn_oauth?route=oauth2/authorize&session=false');
} else {
  throw new Error(`Invalid Square environment: ${SQUARE_ENVIRONMENT}`);
}

const scopes = SQUARE_PERMISSION_SCOPES.split(' ').join('+');
const squareOauthURL = baseURL.concat(`&client_id=${SQUARE_APPLICATION_ID}&response_type=code&scope=${scopes}`);

export default squareOauthURL;
