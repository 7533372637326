/* eslint-disable no-await-in-loop */
/* eslint-disable camelcase */
import React from 'react';
import { useHistory } from 'react-router';
import { PaymentFailedError, UpdateForbiddenError } from './checkoutErrors';
import { ChargedPaymentSuccess } from './PaymentSuccessDetails';
import { LocationActivationStatus } from '../../generic/subscriptions/SubscriptionConstants';
import { changeLocationStatus } from '../../../data-access/mutation/subscriptions';
import { fetchLocations } from '../../../data-access/query/locations';
import { useRefreshSubscriptionData } from '../../../data-access/query/subscriptions';

const POLLING_DELAYS = [1000, 1000, 1000, 1000, 1000, 2500, 2500];

const delay = async delayLength => new Promise(resolve => setTimeout(() => resolve(), delayLength));

// eslint-disable-next-line import/prefer-default-export
export const useUpdateSubscription = (subscriptionId, locationsToActivate, locationsToDeactivate) => {
  const history = useHistory();

  const [showPaymentFailed, setShowPaymentFailed] = React.useState(false);
  const [submissionError, setSubmissionError] = React.useState();
  const [verificationError, setVerificationError] = React.useState();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [pollingForFinalizedChanges, setPollingForFinalizedChanges] = React.useState(false);
  const [paymentCompletionDetails, setPaymentCompletionDetails] = React.useState();

  const returnToSubMgmtSummaryPage = () => history.push('/subscription-management');
  const refreshSubscriptionData = useRefreshSubscriptionData(subscriptionId);

  /**
   * This method is used to determine if any activated locations have been properly updated by a webhook event
   */
  const checkForPendingChanges = async () => {
    const locations = await fetchLocations();

    const subscriptionStatusForLocation = location => location.status_by_subscription_id[subscriptionId]?.status;

    const locationsPendingActivation = locations.filter(
      location => subscriptionStatusForLocation(location) === LocationActivationStatus.PENDING_ACTIVATION,
    );
    const hasPendingChanges = locationsPendingActivation.length > 0;

    return hasPendingChanges;
  };

  // eslint-disable-next-line consistent-return
  const onConfirmChanges = async () => {
    setIsSubmitting(true);

    let paidInvoice;
    let paymentEmailAddress;
    let numInactiveToActive;
    try {
      const response = await changeLocationStatus(subscriptionId, locationsToActivate, locationsToDeactivate);
      const data = response?.data ?? {};
      const paymentDetails = data.payment_details;

      const { unpaid_invoice_id: unpaidInvoiceId } = paymentDetails;
      numInactiveToActive = data.num_inactive_to_active;

      const paymentChargeSucceeded = unpaidInvoiceId == null;
      if (!paymentChargeSucceeded) {
        setShowPaymentFailed(true);
        return setSubmissionError(new PaymentFailedError('Payment attempt unsuccessful', unpaidInvoiceId));
      }

      paidInvoice = paymentDetails.paid_invoice;
      paymentEmailAddress = paymentDetails.payment_email_address;
    } catch (error) {
      // TODO: Distinguish network errors from 403 errors
      return setSubmissionError(new UpdateForbiddenError());
    } finally {
      setIsSubmitting(false);
    }

    if (numInactiveToActive === 0) {
      // No polling necessary
      await refreshSubscriptionData();
      return returnToSubMgmtSummaryPage();
    }

    setPollingForFinalizedChanges(true);

    // Begin polling to see that locations have been activated
    const delays = [...POLLING_DELAYS];
    let delayLength = delays.shift();

    let hasPendingChanges = true;
    while (delayLength && hasPendingChanges) {
      await delay(delayLength);

      try {
        hasPendingChanges = await checkForPendingChanges();
      } finally {
        delayLength = delays.shift();
      }
    }

    const noAttemptsRemaining = delayLength === undefined;
    const waitTimeHasExpired = hasPendingChanges && noAttemptsRemaining;

    if (waitTimeHasExpired) {
      setPollingForFinalizedChanges(false);
      setVerificationError(new Error('Failed to verify changes to a subscription in a timely manner'));
    } else {
      setPaymentCompletionDetails(new ChargedPaymentSuccess({ paidInvoice, paymentEmailAddress }));
    }
  };

  return {
    onConfirmChanges,
    isSubmitting,
    paymentCompletionDetails,
    setPaymentCompletionDetails,
    pollingForFinalizedChanges,
    showPaymentFailed,
    setShowPaymentFailed,
    submissionError,
    verificationError,
  };
};
