export const PAYLOAD_TYPE = {
  BATCH_COMPLETE: 'batch_complete',
  BATCH_PROGRESS: 'batch_progress',
};

export const BATCH_PROGRESS_STAGE = {
  INITIAL: 'initial',
  PROCESS: 'process',
  PREPARE: 'prepare',
  FORECAST: 'forecast',
};

export const FETCHING_DATA_STEP = 'fetching data';

export const TOTAL_NUMBER_OF_EVENT_STEPS = 5;
export const TOTAL_NUMBER_OF_APP_PROGRESS_STEPS = 7;

export const EVENT_PREPARE_STEP = {
  PREPROCESS: 'pre-processing historical data',
  PROCESS: 'processing historical data',
  PREPARE: 'final preparation of historical data',
  INITIALIZE: 'initializing AI engines',
  LEARN: 'AI engines are learning',
};

export const APP_PROGRESS_STEP = {
  1: 'Initializing',
  2: 'Fetching Your Data',
  3: 'Interpreting Your Data',
  4: 'Adding Market Data',
  5: 'Summarizing Data',
  6: 'Preparing Data For AI Engines',
  7: 'AI Engines Running',
};

export const EVENT_PREPARE_STEP_TO_APP_PROGRESS_STEP = {
  [EVENT_PREPARE_STEP.PREPROCESS]: 3,
  [EVENT_PREPARE_STEP.PROCESS]: 4,
  [EVENT_PREPARE_STEP.PREPARE]: 5,
  [EVENT_PREPARE_STEP.INITIALIZE]: 6,
  [EVENT_PREPARE_STEP.LEARN]: 7,
};

export const APP_PROGRESS_STAGE = {
  PREPARING_DATA: 1,
  UPDATING_RESULTS: 2,
  COMPLETE: 3,
};

export const DISPLAYABLE_PROGRESS_STAGES = [
  { value: APP_PROGRESS_STAGE.PREPARING_DATA, label: 'Preparing Data' },
  { value: APP_PROGRESS_STAGE.UPDATING_RESULTS, label: 'Updating Results' },
];
