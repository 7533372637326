import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Personal Settings';

const ACTIONS = {
  USER_INFO_SAVE_CHANGES_BUTTON_CLICK: 'User Info Save Changes button click',
  CHANGE_PASSWORD_BUTTON_CLICK: 'Change Password button click',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitUserInfoSaveChangesButtonClick = () => {
  emitEvent({ action: ACTIONS.USER_INFO_SAVE_CHANGES_BUTTON_CLICK });
};

export const gaEmitChangePasswordButtonClick = () => {
  emitEvent({ action: ACTIONS.CHANGE_PASSWORD_BUTTON_CLICK });
};
