import axios from 'axios';
import { useQuery } from 'react-query';
import { KaizenProduct } from '../../components/generic/subscriptions/SubscriptionConstants';
import { KA_API_URL } from '../../config/baseUrl';

const OPTION_PARAM = {
  DESCRIPTIONS: 'descriptions',
  MAPPING: 'mapping',
  MAPPING_HIERARCHY: 'mapping_hierarchy',
  MAPPING_ITEM_CLASS: 'mapping_item_class',
};

/**
 * URIs
 */

export const GET_NAICS_CODES_BASE = `${KA_API_URL}/naics`;

/**
 * Requests
 */

export const fetchNaicsCodeData = async params => axios.get(GET_NAICS_CODES_BASE, { params });

/**
 * Hooks
 */

const NAICS_QUERY_KEY_BASE = ['naics'];

export const useNaicsCodes = (kaizenProduct = KaizenProduct.KP) =>
  useQuery([...NAICS_QUERY_KEY_BASE, kaizenProduct, OPTION_PARAM.MAPPING], async () => {
    const response = await fetchNaicsCodeData({ kaizen_product: kaizenProduct, option: OPTION_PARAM.MAPPING });

    const naicsKaCodes = response.data.map(naicsCode => {
      return {
        naicsKaCode: naicsCode.naics_code.concat(naicsCode.ka_code),
        description: naicsCode.description,
        industry: naicsCode.industry,
      };
    });

    return naicsKaCodes;
  });

export const useNaicsCodesHierarchy = (kaizenProduct = KaizenProduct.KP) =>
  useQuery([...NAICS_QUERY_KEY_BASE, kaizenProduct, OPTION_PARAM.MAPPING_HIERARCHY], async () => {
    const response = await fetchNaicsCodeData({
      kaizen_product: kaizenProduct,
      option: OPTION_PARAM.MAPPING_HIERARCHY,
    });

    return response.data;
  });

export const useNaicsCodesItemClass = (naicsCode, kaizenProduct = KaizenProduct.KP) =>
  useQuery([...NAICS_QUERY_KEY_BASE, naicsCode, kaizenProduct, OPTION_PARAM.MAPPING_ITEM_CLASS], async () => {
    const response = await fetchNaicsCodeData({
      kaizen_product: kaizenProduct,
      option: OPTION_PARAM.MAPPING_ITEM_CLASS,
      naics_code: naicsCode,
    });

    /**
     * the 'OTHER' class is not a 'real' class in that it does not have a corresponding record in dim_item_class so we
     * manually add it to the list
     */
    response.data.forEach(itemClassMapping => {
      itemClassMapping.item_classes.push('OTHER');
    });

    return response.data;
  });
