/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import Copyright from './Copyright';
import Header from '../header/header';
import axios from '../../utils/axios-client';
import { KA_API_ROOT_URL } from '../../config/baseUrl';
import { PasswordRuleList } from '../forms/GenericFormComponents';
import { PasswordPolicy, Rule } from './password-policy';
import { baseProfitRoverRules } from './password-rules';
import { ProfitRoverLogoIcon } from '../generic/ProfitRoverLogos';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import './auth.css';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      passwordIsAllowed: false,
      error: null,
    };
    // Bind the handlers to this class
    this.newPasswordChangeHandler = this.newPasswordChangeHandler.bind(this);
    this.confirmPasswordChangeHandler = this.confirmPasswordChangeHandler.bind(this);
    this.providedPasswordIsValid = this.providedPasswordIsValid.bind(this);

    const {
      location: { search },
    } = this.props;
    const urlParams = new URLSearchParams(search);
    const username = urlParams.get('email_address');

    const ruleUsername = password => {
      return password !== username;
    };

    this.passwordPolicy = new PasswordPolicy(baseProfitRoverRules);
    this.passwordPolicy.addRule(new Rule('Must not match your username', ruleUsername, 'username'));
  }

  submitResetPassword = async e => {
    e.preventDefault();
    const { confirmPassword } = this.state;
    const {
      history,
      location: { search },
    } = this.props;
    const urlParams = new URLSearchParams(search);
    const data = {
      confirmPassword,
      jwt: urlParams.get('jwt'),
    };

    try {
      await axios.put(`${KA_API_ROOT_URL}/resetforgottenpassword`, { data });
      history.push('/login');
    } catch {
      this.setState({ error: 'Password reset has failed' });
    }
  };

  newPasswordChangeHandler = e => {
    if (this.passwordPolicy.checkPassword(e.target.value)) {
      this.setState({
        passwordIsAllowed: true,
      });
    } else {
      this.setState({
        passwordIsAllowed: false,
      });
    }

    this.setState({
      newPassword: e.target.value,
    });
  };

  confirmPasswordChangeHandler = e => {
    this.setState({
      confirmPassword: e.target.value,
    });
  };

  providedPasswordIsValid = () => {
    const { newPassword, confirmPassword, passwordIsAllowed } = this.state;
    const passwordMatch = newPassword === confirmPassword;
    return passwordMatch && passwordIsAllowed;
  };

  render() {
    const redirectVar = null;

    return (
      <React.Fragment>
        <Header />
        {redirectVar}
        <div style={{ paddingTop: '100px', textAlign: 'center' }}>
          <ProfitRoverLogoIcon className="forgot-page-profitrover-logo" />

          <div id="forgot">
            <form className="form-signin">
              <h1 className="h3 mb-3 font-weight-normal" style={{ margin: '30px' }}>
                Reset Password
              </h1>
              <label htmlFor="newPassword" className="sr-only">
                New Password
              </label>
              <input
                type="Password"
                id="newPassword"
                className="form-control"
                placeholder="New Password"
                required=""
                onChange={this.newPasswordChangeHandler}
                onFocus={this.handleFocus}
              />
              <label htmlFor="newPassword" className="sr-only">
                Confirm Password
              </label>
              <input
                type="Password"
                id="confirmPassword"
                className="form-control"
                placeholder="Confirm Password"
                required=""
                onChange={this.confirmPasswordChangeHandler}
                onFocus={this.handleFocus}
              />
              <ProfitRoverPrimaryButton
                type="submit"
                disabled={!this.providedPasswordIsValid()}
                onClick={this.submitResetPassword}
              >
                Submit
              </ProfitRoverPrimaryButton>

              {this.state.error && <div className="warning pt-3">{this.state.error}</div>}

              <div style={{ marginTop: '1rem', fontSize: '20px', textAlign: 'left' }}>
                Password policy:
                <PasswordRuleList rules={this.passwordPolicy.rules} />
              </div>
              <p className="mt-5 mb-3 text-muted" style={{ textAlign: 'center' }}>
                <Copyright />
              </p>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ResetPassword;
