/* eslint-disable array-callback-return */
import axios from '../../../../utils/axios-client';
import { KA_API_URL } from '../../../../config/baseUrl';
import { formatNumber, arrayParamSerialization } from '../../../util/format';

export const calculateDemandGraphData = async data => {
  if (data === null || data === undefined) {
    return [];
  }

  for (let i = 0; i < data.length; i++) {
    data[i].driver_name = data[i].driver_name.toLowerCase().startsWith('is_dow')
      ? 'Day of Week'
      : data[i].driver_name.toLowerCase().startsWith('month')
      ? 'Month'
      : data[i].driver_name.toLowerCase().startsWith('intercept')
      ? 'Baseline'
      : data[i].driver_name.toLowerCase().startsWith('is_holiday')
      ? 'Holiday'
      : data[i].driver_name.toLowerCase().startsWith('is_precip')
      ? 'Precipitation'
      : data[i].driver_name.toLowerCase().startsWith('is_rain')
      ? 'Rain'
      : data[i].driver_name.toLowerCase().startsWith('indexed_rain')
      ? 'Rain'
      : data[i].driver_name.toLowerCase().startsWith('indexed_temp')
      ? 'Temperature'
      : data[i].driver_name.toLowerCase().startsWith('is_too_hot') ||
        data[i].driver_name.toLowerCase().startsWith('is_too_cold')
      ? 'Extreme Temperature'
      : data[i].driver_name.toLowerCase().startsWith('unit_price')
      ? 'Price'
      : data[i].driver_name;
  }
  const tempArray = [];
  for (let i = 0; i < data.length; i++) {
    tempArray.push(data[i].driver_name);
  }
  const set = new Set();
  const finalVariablesArray = [];
  for (let i = 0; i < tempArray.length; i++) {
    if (!set.has(tempArray[i])) {
      set.add(tempArray[i]);
      finalVariablesArray.push(tempArray[i]);
    }
  }

  let factorAggregatedSum = 0;
  const factorArray = [];

  for (let j = 0; j < finalVariablesArray.length; j++) {
    for (let k = 0; k < data.length; k++) {
      if (finalVariablesArray[j] === data[k].driver_name) {
        factorAggregatedSum += data[k].driver_value;
      }
    }
    if (Math.abs(factorAggregatedSum) >= 0.05) {
      factorArray.push({
        name: finalVariablesArray[j],
        value: factorAggregatedSum,
      });
    }
    factorAggregatedSum = 0;
  }
  factorArray.sort((a, b) => (a.value < b.value ? 1 : -1));
  let total = 0;
  for (let l = 0; l < factorArray.length; l++) {
    total += factorArray[l].value;
  }
  total = total > 1 ? Math.round(total) : total;
  factorArray.push({ name: 'Total', value: total });
  let tempHigh = 0;
  const lowHighCalculatingArray = [];
  for (let z = 0; z < factorArray.length; z++) {
    tempHigh += factorArray[z].value;
    lowHighCalculatingArray.push(tempHigh);
  }
  let tempLow = 0;
  const finalOutputArray = [];

  for (let y = 0; y < factorArray.length; y++) {
    if (factorArray[y].name === 'Total') {
      finalOutputArray.push({
        name: factorArray[y].name,
        value: factorArray[y].value,
        low: 0,
        high: factorArray[y].value,
        display: formatNumber(factorArray[y].value),
      });
    } else {
      finalOutputArray.push({
        name: factorArray[y].name,
        value: factorArray[y].value,
        low: tempLow,
        high: lowHighCalculatingArray[y],
        display: formatNumber(factorArray[y].value.toFixed(1)),
      });
      tempLow = lowHighCalculatingArray[y];
    }
  }

  return finalOutputArray;
};

const getDay = date => {
  const dayOfWeek = new Date(date).getDay();
  return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][dayOfWeek];
};

const getGraphDetails = async (date, holidayMap, weatherMap) => {
  let graphDetails = [];

  const dateOnly = date.substring(0, 10);
  const weather = weatherMap.get(dateOnly);
  const day = await getDay(dateOnly);
  const holiday = holidayMap.get(dateOnly);

  if (weather !== undefined) {
    weather.precip =
      (weather.rain_amt === null ? 0 : weather.rain_amt) + (weather.snow_amt === null ? 0 : weather.snow_amt);
    if (weather.precip === 0) {
      weather.precip = 'None';
    }
    weather.temp_max = Math.round(weather.temp_max);
  }

  graphDetails = [
    { driver: 'Day of Week', value: day },
    { driver: 'Holiday', value: holiday === undefined ? 'None' : holiday },
    { driver: 'Forecasted High Temp', value: weather === undefined ? 'NA' : weather.temp_max },
    { driver: 'Rain (mm)', value: weather === undefined ? 'NA' : weather.precip },
  ];

  return graphDetails;
};

export const calculateDemandData = async (
  date,
  locationId,
  currentLocationHolidayMap,
  currentLocationWeather,
  productClass,
  productId,
  workflowId,
) => {
  let demandData = [];
  let graphDetails = [];

  try {
    const demandForecastResponse = await axios.get(`${KA_API_URL}/productvisit/forecastgraphdetails`, {
      params: {
        product_classes: [productClass],
        location_ids: [locationId],
        product_ids: [productId],
        start_date: date,
        workflow_id: workflowId,
      },
    });

    if (demandForecastResponse.status === 200) {
      graphDetails = await getGraphDetails(date, currentLocationHolidayMap, currentLocationWeather);
      demandData = await calculateDemandGraphData(demandForecastResponse.data);
    }
    return { demandData, graphDetails };
  } catch (err) {
    return null;
  }
};

export const calculateDailySummaryDemandData = async (
  workflowId,
  productClasses,
  currentLocationHolidayMap,
  currentLocationWeather,
  date,
  locations,
  products,
) => {
  const params = arrayParamSerialization({
    locations,
    products,
    productClasses,
  });
  let demandData = [];
  let graphDetails = [];

  try {
    const demandForecastResponse = await axios.get(`${KA_API_URL}/productvisit/forecastgraphdetails`, {
      params: {
        workflow_id: workflowId,
        product_classes: params.productClasses,
        location_ids: params.locations,
        product_ids: params.products,
        start_date: date,
      },
    });

    if (demandForecastResponse.status === 200) {
      graphDetails = await getGraphDetails(date, currentLocationHolidayMap, currentLocationWeather);
      demandData = await calculateDemandGraphData(demandForecastResponse.data);
    }
    return { demandData, graphDetails };
  } catch (err) {
    return null;
  }
};
