import React from 'react';
import _ from 'lodash';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useEndTrialEarly } from './CheckoutEndTrialEarlyHooks';
import { PaymentFailedError } from './checkoutErrors';
import { SubmissionErrorModal } from './CheckoutModals';
import {
  BubbleLabel,
  CheckoutHeaderBar,
  ConfirmChangesButton,
  GoBackButton,
  PageTitle,
  PaymentFailedMessage,
  PaymentMethodSection,
  SpreadText,
} from './common/CommonSubscriptionComponents';
import EndTrialEarlyOrderDetails from './EndTrialEarlyOrderDetails';
import ManagePaymentMethodModal from './ManagePaymentMethodModal';
import PaymentSuccessDetails, { ChargedPaymentSuccess } from './PaymentSuccessDetails';
import LoadingIndicator from '../common/LoadingIndicator';
import { ProfitRoverModalCard, ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';
import ProfitRoverTermsOfUseLink from '../../generic/ProfitRoverTermsOfUseLink';
import { nullSafeFormatCurrency } from '../../util/format';
import { BACKGROUND_GRAY, LIGHT_GREEN } from '../../../colors';
import { usePayInvoice } from '../../../data-access/mutation/subscriptions';
import { useProfitRoverSubscriptionPrices } from '../../../data-access/query/stripe';
import {
  BILLING_INFO_QUERY_KEY_BASE,
  SUBSCRIPTION_TRIAL_TO_PAID_PREVIEW_INVOICE_QUERY_KEY_BASE,
  useBillingInfo,
  useEndTrialEarlyPreviewInvoice,
} from '../../../data-access/query/subscriptions';

const computeDisplayInfo = (previewInvoice, previewInvoiceError) => {
  if (previewInvoiceError || _.isEmpty(previewInvoice)) {
    return {};
  }

  const { lines: lineItems } = previewInvoice;
  let nextBillingDate;
  if (lineItems && lineItems.length > 0) {
    nextBillingDate = lineItems[0].period?.end;
  }

  let { amount_due: dueToday } = previewInvoice;
  dueToday = nullSafeFormatCurrency(dueToday / 100);

  let subTotal = previewInvoice?.subtotal;
  subTotal = nullSafeFormatCurrency(subTotal / 100);

  let tax = previewInvoice?.tax;
  tax = nullSafeFormatCurrency(tax / 100);

  nextBillingDate = moment.unix(nextBillingDate).format('MMM Do, YYYY');

  return {
    subTotal,
    tax,
    dueToday,
    nextBillingDate,
  };
};

const SUCCESS_TITLE = 'Successfully Activated Subscription';

const CheckoutEndTrialEarly = ({ subscriptionId, onCancel }) => {
  const location = useLocation();
  const { preSelectedBillingInterval } = location?.state ?? {};
  const [selectedPlan, setSelectedPlan] = React.useState();

  const queryClient = useQueryClient();

  const {
    onConfirmChanges,
    isSubmitting,
    paymentCompletionDetails,
    setPaymentCompletionDetails,
    showPaymentFailed,
    setShowPaymentFailed,
    submissionError,
  } = useEndTrialEarly(subscriptionId, selectedPlan?.id);

  const defaultPaymentMethodState = useBillingInfo(subscriptionId, 'defaultPaymentMethod');
  const { default_payment_method: defaultPaymentMethod } = defaultPaymentMethodState?.data ?? {};
  const hasPaymentMethod = defaultPaymentMethod != null;

  const currentBillingInfoState = useBillingInfo(subscriptionId);
  const { data: currentBillingInfo, isLoading: isLoadingCurrentBillingInfo } = currentBillingInfoState;

  const {
    data: previewInvoice = {},
    isLoading: isLoadingPreviewInvoice,
    error: previewInvoiceError,
  } = useEndTrialEarlyPreviewInvoice(subscriptionId, selectedPlan?.id);

  const { price_nickname: priceName, coupon } = currentBillingInfo?.plan_info ?? {};

  const { data: priceMap = {}, isLoading: isLoadingPrices } = useProfitRoverSubscriptionPrices(priceName, {
    enabled: !!priceName,
  });
  const prices = priceMap[priceName];

  const reattemptPaymentMutation = usePayInvoice({
    onSuccess: ({ payment_email_address: paymentEmailAddress, paid_invoice: paidInvoice }) =>
      setPaymentCompletionDetails(new ChargedPaymentSuccess({ paidInvoice, paymentEmailAddress })),
  });

  const reattemptPayment = async () =>
    reattemptPaymentMutation.mutateAsync({ subscriptionId, invoiceId: submissionError.invoiceId });

  React.useEffect(() => {
    const userHasPreselectedPlan = preSelectedBillingInterval != null;

    if (!isLoadingPrices && prices != null && selectedPlan == null && !isLoadingCurrentBillingInfo) {
      let initiallySelectedPlan;
      if (userHasPreselectedPlan) {
        // The user came in from the Manage Plan page and already specified what plan they want to switch to
        initiallySelectedPlan = preSelectedBillingInterval === 'year' ? prices.annual : prices.monthly;
      } else {
        initiallySelectedPlan =
          // eslint-disable-next-line camelcase
          currentBillingInfo?.plan_info.billing_interval === 'month' ? prices.monthly : prices.annual;
      }
      setSelectedPlan(initiallySelectedPlan);
    }
  }, [
    prices,
    isLoadingPrices,
    currentBillingInfo,
    isLoadingCurrentBillingInfo,
    preSelectedBillingInterval,
    selectedPlan,
  ]);

  const price = nullSafeFormatCurrency(selectedPlan?.unit_amount / 100);
  const couponPercentOff = coupon?.percent_off ?? 0;
  const discount = nullSafeFormatCurrency((selectedPlan?.unit_amount / 100) * (couponPercentOff / 100));
  const priceWithDiscount = nullSafeFormatCurrency((selectedPlan?.unit_amount / 100) * (1 - couponPercentOff / 100));
  const couponLastMoreThanOneMonth = coupon && coupon.duration_in_months > 1;
  const selectedMonthlyPlan = selectedPlan?.recurring?.interval === 'month';
  const interval = selectedMonthlyPlan ? 'Monthly' : 'Yearly';

  const {
    subTotal = price,
    tax = nullSafeFormatCurrency(0),
    dueToday = priceWithDiscount,
    nextBillingDate = `1 ${selectedMonthlyPlan ? 'month' : 'year'} from today`,
  } = computeDisplayInfo(previewInvoice, previewInvoiceError);

  const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] = React.useState(false);
  const onHide = () => setShowUpdatePaymentMethodModal(false);

  const blurStyles = { filter: showUpdatePaymentMethodModal ? 'blur(8px)' : undefined };

  const changesInProgress = isSubmitting || reattemptPaymentMutation.isLoading;
  const buttonDisabled =
    isLoadingPreviewInvoice ||
    isLoadingPrices ||
    isLoadingCurrentBillingInfo ||
    changesInProgress ||
    showPaymentFailed ||
    !hasPaymentMethod;

  return (
    <>
      <CheckoutHeaderBar className="end-trial-early-header" />
      <div className="checkout-subscription-changes" style={{ backgroundColor: BACKGROUND_GRAY, ...blurStyles }}>
        <div className="end-trial-early">
          <SubmissionErrorModal show={submissionError != null && !(submissionError instanceof PaymentFailedError)} />
          <ManagePaymentMethodModal
            show={showUpdatePaymentMethodModal}
            subscriptionId={subscriptionId}
            onHide={onHide}
            onSuccess={async () => {
              await Promise.allSettled([
                queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId, 'defaultPaymentMethod']),
                queryClient.refetchQueries([
                  ...SUBSCRIPTION_TRIAL_TO_PAID_PREVIEW_INVOICE_QUERY_KEY_BASE,
                  subscriptionId,
                  prices.annual?.id,
                ]),
                queryClient.refetchQueries([
                  ...SUBSCRIPTION_TRIAL_TO_PAID_PREVIEW_INVOICE_QUERY_KEY_BASE,
                  subscriptionId,
                  prices.monthly?.id,
                ]),
              ]);
              setShowPaymentFailed(false);
              onHide();
            }}
          />
          <PageTitle>Start Your Plan</PageTitle>
          <div className="section-layout">
            <div className="labeled-bubble-container">
              {paymentCompletionDetails != null ? (
                <PaymentSuccessDetails
                  title={SUCCESS_TITLE}
                  subscriptionId={subscriptionId}
                  paymentCompletionDetails={paymentCompletionDetails}
                />
              ) : (
                <>
                  <BubbleLabel>Payment Due Today</BubbleLabel>
                  <ProfitRoverModalCard>
                    <ProfitRoverModalMain
                      style={{ backgroundColor: '#f2f2f2', paddingTop: '0px', paddingBottom: '0px' }}
                    >
                      {isLoadingPrices ? (
                        <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 150 }}>
                          <LoadingIndicator />
                        </div>
                      ) : (
                        <EndTrialEarlyOrderDetails
                          prices={prices}
                          selectedPlan={selectedPlan}
                          setSelectedPlan={setSelectedPlan}
                          coupon={coupon}
                        />
                      )}
                    </ProfitRoverModalMain>
                    <ProfitRoverModalMain className="left-main">
                      {showPaymentFailed && (
                        <PaymentFailedMessage onClick={() => setShowUpdatePaymentMethodModal(true)} />
                      )}
                      <p className="ssl-text">
                        <FontAwesomeIcon icon={faLock} color={LIGHT_GREEN} />
                        This is a secure SSL Encrypted Payment
                      </p>
                      <PaymentMethodSection
                        defaultPaymentMethodState={defaultPaymentMethodState}
                        paymentFailed={showPaymentFailed}
                        onClick={() => setShowUpdatePaymentMethodModal(true)}
                      />
                      <div className="padded-container">
                        <SpreadText className="billing-per-location">
                          <p>Billing Per Location</p>
                          <p>{price}</p>
                        </SpreadText>
                        <SpreadText className="billing-per-location">
                          <p>Locations</p>
                          <p>x 1</p>
                        </SpreadText>
                      </div>
                      <div className="padded-container emphasis-area">
                        <SpreadText>
                          <>
                            <p>Subtotal</p>
                            <span>{isLoadingPreviewInvoice ? <LoadingIndicator /> : <span>{subTotal}</span>}</span>
                          </>
                        </SpreadText>
                        {coupon && (
                          <SpreadText>
                            <>
                              <p>Promo Applied</p>
                              <span>
                                {isLoadingPreviewInvoice || isLoadingCurrentBillingInfo ? (
                                  <LoadingIndicator />
                                ) : (
                                  <span>- {discount}</span>
                                )}
                              </span>
                            </>
                          </SpreadText>
                        )}
                        <SpreadText className="sales-tax">
                          <p>Sales Tax</p>
                          <>
                            <span>{isLoadingPreviewInvoice ? <LoadingIndicator /> : <span>{tax}</span>}</span>
                          </>
                        </SpreadText>
                        <SpreadText>
                          <h5>Due Today</h5>
                          <h5>
                            <>{isLoadingPreviewInvoice ? <LoadingIndicator /> : <span>{dueToday}</span>}</>
                          </h5>
                        </SpreadText>
                      </div>
                    </ProfitRoverModalMain>
                    <ProfitRoverModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                      <GoBackButton
                        onClick={onCancel}
                        paymentFailed={showPaymentFailed}
                        subscriptionId={subscriptionId}
                        disabled={isSubmitting}
                      />
                      <ConfirmChangesButton
                        customerWillBeCharged
                        changesInProgress={changesInProgress}
                        disabled={buttonDisabled}
                        onConfirmChanges={onConfirmChanges}
                        onTryAgain={reattemptPayment}
                        submissionError={submissionError}
                      />
                    </ProfitRoverModalFooter>
                  </ProfitRoverModalCard>
                </>
              )}
            </div>
            <div className="labeled-bubble-container">
              <BubbleLabel>Next Billing Details</BubbleLabel>
              <ProfitRoverModalCard className="next-billing-details">
                <ProfitRoverModalMain className="right-main">
                  <SpreadText>
                    <p>{interval} Billing Per Location</p>
                    <p>
                      {isLoadingPreviewInvoice || isLoadingCurrentBillingInfo ? (
                        <LoadingIndicator />
                      ) : couponLastMoreThanOneMonth && selectedMonthlyPlan ? (
                        <>
                          <span className="discount-price">{price}</span>
                          <span>{priceWithDiscount}</span>
                        </>
                      ) : (
                        <span>{price}</span>
                      )}
                    </p>
                  </SpreadText>
                  <div className="line-item-changes">
                    <SpreadText className="change-in-billing-amount">
                      <p>Locations</p>
                      <p>x 1</p>
                    </SpreadText>
                  </div>

                  <hr />

                  <SpreadText className="next-billing-amount">
                    <p>
                      <strong>Next Billing Amount</strong>
                    </p>
                    <span>
                      {isLoadingPreviewInvoice || isLoadingCurrentBillingInfo ? (
                        <LoadingIndicator />
                      ) : (
                        <p style={{ marginRight: -29 }}>
                          {couponLastMoreThanOneMonth && selectedMonthlyPlan ? (
                            <>
                              <strong className="discount-price">{price}</strong>
                              <strong>{priceWithDiscount}</strong>
                            </>
                          ) : (
                            <strong>{price}</strong>
                          )}
                          <span className="plus-tax-text"> + tax</span>
                        </p>
                      )}
                    </span>
                  </SpreadText>
                  <SpreadText className="scheduled-for">
                    <p>
                      <i>Scheduled for</i>
                    </p>
                    <p>
                      <span>
                        <i>{isLoadingPreviewInvoice ? <LoadingIndicator /> : nextBillingDate}</i>
                      </span>
                    </p>
                  </SpreadText>
                </ProfitRoverModalMain>
              </ProfitRoverModalCard>
            </div>
            <div className="explanatory-text-container">
              <p>
                You are electing to end your trial early and activate your subscription. You authorize us to make
                regularly scheduled charges to your credit card. You will be charged each billing period for the amount
                due for that period. For more information regarding billing, please review the{' '}
                <ProfitRoverTermsOfUseLink>Terms of Use</ProfitRoverTermsOfUseLink>.
              </p>
              <p>
                Your selected subscription plan will go into effect immediately after your payment is processed, and
                your new billing period will begin. If you turn off auto-renew on your subscription, you will lose
                insights when your current billing period ends. You can always turn this back on.
              </p>
              <p>
                You can activate and add more locations to your subscription plan at any point. A prorated amount will
                be charged for activating any inactive locations before your current billing period ends. Your next
                billing period will update to reflect the total cost of any added locations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutEndTrialEarly;
