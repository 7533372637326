import React from 'react';
import classnames from 'classnames';
import './profitrover-card.css';

/**
 * These components are intended to be used like this:
 *
 * <ProfitRoverCard>
 *   (Content)
 * </ProfitRoverCard>
 *
 * <ProfitRoverModalCard>
 *   <ProfitRoverModalMain>
 *     (Main Content)
 *   </ProfitRoverCardMain>
 *
 *   <ProfitRoverModalFooter>
 *     (Footer Content)
 *   </ProfitRoverModalFooter>
 * </ProfitRoverModalCard>
 */

export const ProfitRoverCard = ({ children, className, ...props }) => {
  return (
    <div className={classnames('profitrover-card', className)} {...props}>
      {children}
    </div>
  );
};

export const ProfitRoverModalCard = ({ children, className, ...props }) => {
  return (
    <div className={classnames('profitrover-modal-card', className)} {...props}>
      {children}
    </div>
  );
};

export const ProfitRoverModalMain = ({ children, className, ...props }) => {
  return (
    <div className={classnames('profitrover-modal-main', className)} {...props}>
      {children}
    </div>
  );
};

export const ProfitRoverModalFooter = ({ children, className, ...props }) => {
  return (
    <div className={classnames('profitrover-modal-footer', className)} {...props}>
      {children}
    </div>
  );
};
