import React from 'react';
import ApprovedPriceSelect from './ApprovedPriceSelect';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import { formatCurrency } from '../../../util/format';

const ApprovedPriceRenderer = params => {
  const { primaryForecastComplete, context, currencySymbol } = params;
  const { approvedPricesApi } = context;

  const approvedPrice = approvedPricesApi.selectors.getApprovedPrice(params.data);
  const priceIcon =
    approvedPrice === params.data.newPrice ? 'kaizen' : approvedPrice === params.data.baselinePrice ? 'price' : null;
  const formattedPrice = formatCurrency(approvedPrice, true, currencySymbol);
  const tooltipText =
    'Your prices are updating. You will receive a notification in the Notification Center when they are ready.';
  return (
    <ProfitRoverTooltip
      shouldDisplayTooltip={!primaryForecastComplete}
      tooltipId="batches-incomplete"
      tooltipClass="disabled-approved-price-select-tooltip"
      placement="left"
      tooltipText={tooltipText}
    >
      <div className="price-rec-approved-price-container">
        <ApprovedPriceSelect
          autoFocus
          onFocus={() => {
            const thisCell = {
              rowIndex: params.node.rowIndex,
              colKey: 'approvedPrice',
            };
            params.api.startEditingCell(thisCell);
          }}
          menuIsOpen={false}
          isDisabled={!primaryForecastComplete}
          value={{ value: approvedPrice, label: formattedPrice, icon: priceIcon }}
        />
      </div>
    </ProfitRoverTooltip>
  );
};

export default ApprovedPriceRenderer;
