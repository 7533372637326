/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import pagination from '../dashboards/eo/eoPagination';
import { formatNumber } from '../util/format';

function actionComponent() {
  return (
    <div>
      <FontAwesomeIcon name="editOpen" className="Icon" icon={faEdit} disabled />
      <FontAwesomeIcon icon={faTrash} className="Icon" disabled />
    </div>
  );
}
actionComponent.displayName = 'Action';

const dataConfigurationTable = props => {
  const { dataConfigurations, sortObject, onSortClick, dataConfigurationsAvailable } = props;

  const headerStyleObj = {
    fontSize: '14px',
    height: '40px',
    background: '#ffc500',
    fontWeight: 'bold',
    boxSizing: 'border-box',
  };

  const tableDataStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };

  const renderSortIcon = columnKey => {
    const isSorted = sortObject[columnKey].is_sorted === true;
    const isAscendingSort = sortObject[columnKey].direction === 'ASC';

    return (
      isSorted &&
      (isAscendingSort ? (
        <FontAwesomeIcon className="datacenter-icon" name="editClose" icon={faArrowUp} />
      ) : (
        <FontAwesomeIcon className="datacenter-icon" name="editClose" icon={faArrowDown} />
      ))
    );
  };

  return (
    <div>
      <h5>Dataset Configurations</h5>
      <ReactTable
        data={dataConfigurations}
        columns={[
          {
            Header: (
              <div className="column-header column-header-center-align">
                Data Type
                {renderSortIcon('data_type_description')}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'data_type_description',
            style: { whiteSpace: 'unset' },
            accessor: d => d.data_type_description,
            sortable: true,
          },
          {
            Header: (
              <div className="column-header column-header-center-align">
                Dataset Configuration
                {renderSortIcon('name')}
              </div>
            ),
            headerStyle: headerStyleObj,
            style: { whiteSpace: 'unset' },
            id: 'name',
            accessor: d => d.name,
            sortable: true,
          },
          {
            Header: (
              <div className="column-header column-header-center-align">
                Total Datasets
                {renderSortIcon('number_of_datasets')}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'number_of_datasets',
            style: { whiteSpace: 'unset' },
            accessor: d => (d.number_of_datasets == null ? '-' : d.number_of_datasets),
            sortable: false,
          },
          {
            Header: (
              <div className="column-header column-header-center-align">
                Total Records Processed
                {renderSortIcon('total_record_count')}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'total_record_count',
            style: { whiteSpace: 'unset' },
            accessor: d => (d.total_record_count === 0 ? '-' : formatNumber(d.total_record_count)),
            sortable: true,
          },
          {
            Header: <div className="column-header column-header-center-align">Actions</div>,
            headerStyle: headerStyleObj,
            id: 'actions',
            accessor: d => d,
            width: 70,
            sortable: false,
            Cell: () => actionComponent(),
          },
        ]}
        getTheadThProps={(state, rowInfo, column) => {
          return {
            onClick: () => {
              if (column.sortable) {
                onSortClick(column.id);
              }
            },
          };
        }}
        getTdProps={(state, rowInfo) => {
          if (typeof rowInfo !== 'undefined') {
            return {
              style: tableDataStyleObject,
            };
          }
          return {};
        }}
        noDataText={dataConfigurationsAvailable ? 'Loading Data' : 'No Data Configurations Found'}
        defaultPageSize={4}
        pageSizeOptions={[4, 10, 20, 25, 50, 100]}
        PaginationComponent={pagination}
        showPagination={dataConfigurations.length > 4}
        className="-striped -highlight"
      />
    </div>
  );
};

export default dataConfigurationTable;
