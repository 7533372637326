import React from 'react';
import _ from 'lodash';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import buttonRenderer from './buttonRenderer';
import dropDownRenderer from './dropDownRenderer';
import { Country, CountryDisplay, getRegionSpecificData } from '../../generic/countryConstants';
import LinkWithDirectionalArrow from '../../generic/LinkWithDirectionalArrow';
import { getLocationTableLabels } from '../../generic/locationTableLabels';
import ProfitRoverSpinner from '../../spinner/ProfitRoverSpinner';
import { countryDropdownOptions } from '../../util/countryOptions';
import { provinceDropdownOptions, stateDropdownOptions } from '../../util/stateAndProvinceOptions';
import { useUpdateLocation } from '../../../data-access/mutation/locations';
import { useNaicsCodes } from '../../../data-access/query/naics';
import { getWeatherLocationInfo } from '../../../data-access/query/kdl/weather';
import {
  gaEmitGoToSubscriptionManagementLinkClick,
  gaEmitUpdateLocationCheckmarkClick,
} from '../../../google-analytics/locationSettings';

const LocationManagementTable = ({ locationTableState, locationsHoursOfOperationState }) => {
  const { onGridReady, numberOfLocations, isDataReady, initialRowData, gridApi } = locationTableState;
  const { selectedLocation, setSelectedLocation } = locationsHoursOfOperationState;
  const loadingTable = !isDataReady;
  const [editingRowIdx, setEditingRowIdx] = React.useState(null);

  const getRowNodeId = React.useCallback(data => data.location_id, []);
  const { regionLabel, postcodeLabel } = getLocationTableLabels(initialRowData);

  const onCellClicked = params => {
    // handle click event for action cells
    if (params.column.colId === 'action' && params.event.target.dataset.action) {
      const { action } = params.event.target.dataset;
      if (action === 'edit') {
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          // gets the first columnKey
          colKey: params.columnApi.getDisplayedCenterColumns()[0].colId,
        });
        setEditingRowIdx(params.node.rowIndex);
      } else if (action === 'update') {
        gaEmitUpdateLocationCheckmarkClick();
        setEditingRowIdx(null);
        params.api.stopEditing();
      } else if (action === 'cancel') {
        setEditingRowIdx(null);
        params.api.stopEditing(true);
      }
    }

    setSelectedLocation(params.data);
  };

  const toggleRowEditing = params => {
    params.api.refreshCells({
      columns: ['action'],
      rowNodes: [params.node],
      force: true,
    });
  };

  const applyCellStyle = params =>
    editingRowIdx !== null && params.node.rowIndex !== editingRowIdx
      ? { 'pointer-events': 'none' }
      : { 'pointer-events': 'auto' };

  const { data: naicsCodes = [] } = useNaicsCodes();
  const naicsKaOptions = _.sortBy(
    naicsCodes.map(code => {
      return { value: code.description, label: code.description };
    }),
    ['label'],
  );

  const updateLocation = useUpdateLocation();
  const locationRowChanged = async params => {
    const { data } = params;
    const { location_id: locationId, city, state, zip, country_code: countryCode } = data;
    const updatedValues = _.clone(data.updated_values);

    if (_.isEmpty(updatedValues)) {
      return;
    }

    const checkWeatherLocation = ['city', 'state', 'zip', 'country_code'].some(e => e in updatedValues);

    if (checkWeatherLocation) {
      const countryName = CountryDisplay[countryCode];
      const weatherLocationResponse = await getWeatherLocationInfo(city, state, zip, countryName);

      if (!_.isEmpty(weatherLocationResponse)) {
        const { weatherLocationId, timezone, latitude, longitude } = weatherLocationResponse;

        updatedValues.timezone = timezone;
        updatedValues.latitude = latitude;
        updatedValues.longitude = longitude;
        updatedValues.kdl_weather_location_id = weatherLocationId;
      }
    }

    // potentially slow operation so let this go un-awaited
    updateLocation.mutate({ locationId, location: updatedValues });

    // clear the grid row changes in case the user makes another change to the same row
    data.updated_values = {};

    if ('country_code' in updatedValues) {
      const columnDefs = gridApi.getColumnDefs();
      const locations = [];
      gridApi.forEachNode(node => {
        locations.push(node.data);
      });
      const { regionLabel: newRegionLabel, postcodeLabel: newPostcodeLabel } = getLocationTableLabels(locations);
      columnDefs.forEach(colDef => {
        if (colDef.field === 'state') {
          colDef.headerName = newRegionLabel;
        } else if (colDef.field === 'zip') {
          colDef.headerName = newPostcodeLabel;
        }
      });

      gridApi.setColumnDefs(columnDefs);
    }
  };

  const countryFormatter = params => {
    return CountryDisplay[params.value];
  };

  const getRegionDropdownOptions = params => ({
    values: params.data.country_code === Country.CA ? provinceDropdownOptions : stateDropdownOptions,
  });

  const onCountryClick = async params => {
    const selectedCountry = params.event.target.value; // will be undefined for clicks on the cell when not in edit mode

    if (selectedCountry) {
      const countryIsCanada = params.event.target.value === Country.CA;
      const { regionDropdownOptions } = getRegionSpecificData(countryIsCanada);

      const cellEditors = params.api.getCellEditorInstances();
      const regionEditor = cellEditors.find(editor => editor.params.colDef.field === 'state');
      const regionDropdownComponent = regionEditor.getFrameworkComponentInstance();
      regionDropdownComponent.onCountrySelection(regionDropdownOptions);
    }
  };

  const locationEditValueSetter = params => {
    if (params.colDef) {
      const { field } = params.colDef;
      const { newValue } = params;
      if (newValue) {
        params.data.updated_values[field] = newValue;
        params.data[field] = newValue;
      }
    }
  };

  React.useEffect(() => {
    if (selectedLocation == null || gridApi == null) {
      return;
    }

    gridApi.forEachNode(node => {
      if (node.data.location_id === selectedLocation.location_id) {
        node.setSelected(true);
      } else {
        node.setSelected(false);
      }
    });
  }, [selectedLocation, gridApi]);

  const frameworkComponents = { buttonRenderer, dropDownRenderer };

  return loadingTable ? (
    <div className="locations-status-loading">
      <ProfitRoverSpinner />
    </div>
  ) : (
    <div className="location-table-container">
      <div className="top">
        <div className="locations-summary">
          <h3 style={{ fontSize: 30 }}>{numberOfLocations}</h3>
          <h3>Location(s)</h3>
        </div>

        <LinkWithDirectionalArrow
          target="/subscription-management"
          text="Manage Subscription"
          direction="right"
          arrowRelativeToText="right"
          onClick={gaEmitGoToSubscriptionManagementLinkClick}
        />
      </div>

      <div>
        <AgGridReact
          suppressDragLeaveHidesColumns
          onRowEditingStopped={toggleRowEditing}
          onRowEditingStarted={toggleRowEditing}
          onGridReady={onGridReady}
          rowData={initialRowData}
          getRowNodeId={getRowNodeId}
          headerHeight={50}
          frameworkComponents={frameworkComponents}
          className="ag-theme-alpine locations-table"
          domLayout="autoHeight"
          onCellClicked={onCellClicked}
          editType="fullRow"
          rowSelection="single"
          onRowValueChanged={locationRowChanged}
          stopEditingWhenCellsLoseFocus={false}
          suppressRowClickSelection
          suppressClickEdit
        >
          <AgGridColumn
            headerName="Location Name"
            field="location_description"
            resizable
            valueSetter={locationEditValueSetter}
            sortable
            autoHeight
            wrapText
            editable
            cellStyle={applyCellStyle}
            minWidth={225}
          />
          <AgGridColumn
            headerName="Industry"
            sortable
            resizable
            field="industry_description"
            cellEditor="dropDownRenderer"
            cellClass="location-select-dropdown"
            cellEditorParams={{ values: naicsKaOptions }}
            wrapText
            autoHeight
            editable
            cellStyle={applyCellStyle}
            minWidth={250}
          />
          <AgGridColumn
            headerName="Country"
            sortable
            field="country_code"
            cellEditor="dropDownRenderer"
            cellEditorParams={{ values: countryDropdownOptions }}
            cellClass="location-select-dropdown"
            valueFormatter={countryFormatter}
            valueSetter={locationEditValueSetter}
            onCellClicked={onCountryClick}
            editable
            cellStyle={applyCellStyle}
            autoHeight
            minWidth={90}
            resizable
          />
          <AgGridColumn
            headerName="Address"
            sortable
            field="address"
            valueSetter={locationEditValueSetter}
            autoHeight
            editable
            wrapText
            resizable
            cellStyle={applyCellStyle}
            minWidth={150}
          />
          <AgGridColumn
            headerName="City"
            valueSetter={locationEditValueSetter}
            sortable
            field="city"
            editable
            wrapText
            autoHeight
            cellStyle={applyCellStyle}
            minWidth={90}
            resizable
          />
          <AgGridColumn
            headerName={regionLabel}
            sortable
            field="state"
            cellEditor="dropDownRenderer"
            cellEditorParams={getRegionDropdownOptions}
            cellClass="location-select-dropdown"
            valueSetter={locationEditValueSetter}
            editable
            cellStyle={applyCellStyle}
            autoHeight
            minWidth={90}
            resizable
          />
          <AgGridColumn
            valueSetter={locationEditValueSetter}
            headerName={postcodeLabel}
            sortable
            field="zip"
            editable
            cellStyle={applyCellStyle}
            minWidth={90}
            autoHeight
            resizable
          />
          <AgGridColumn
            headerClass="action-column-header"
            cellClass="action-button-container"
            headerName="Action"
            cellStyle={params =>
              editingRowIdx !== null && params.node.rowIndex !== editingRowIdx
                ? { 'pointer-events': 'none', padding: '0px' }
                : { 'pointer-events': 'auto' }
            }
            editable={false}
            cellRenderer="buttonRenderer"
            minWidth={90}
            field="action"
            resizable
          />
        </AgGridReact>
      </div>
    </div>
  );
};

export default LocationManagementTable;
