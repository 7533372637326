import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const fetchCustomerUsers = async () => axios.get(`${KA_API_URL}/customer/users`);
export const fetchCustomerRoles = async () => axios.get(`${KA_API_URL}/customer/roles`);

/**
 * Hooks
 */

export const CUSTOMER_USERS_QUERY_KEY_BASE = 'customerUsers';
export const CUSTOMER_ROLES_QUERY_KEY_BASE = 'customerRoles';

export const useCustomerUsers = queryConfig =>
  useQuery(
    [CUSTOMER_USERS_QUERY_KEY_BASE],
    async () => {
      const response = await fetchCustomerUsers();

      return response.data;
    },
    queryConfig,
  );

export const useCustomerRoles = queryConfig =>
  useQuery(
    [CUSTOMER_ROLES_QUERY_KEY_BASE],
    async () => {
      const response = await fetchCustomerRoles();

      return response.data;
    },
    queryConfig,
  );
