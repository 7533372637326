import React from 'react';
import { Modal } from 'react-bootstrap';
import Confetti from 'react-confetti';
import {
  BACKGROUND_GRAY,
  MID_GREEN,
  FONT_BLACK,
  FONT_GRAY,
  LIGHT_GREEN,
  LIGHT_BLUE,
  LIGHT_GREEN_SHADOW,
} from '../../colors';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverModalCard, ProfitRoverModalFooter, ProfitRoverModalMain } from '../generic/ProfitRoverCard';
import { ProfitRoverLinearLogo } from '../generic/ProfitRoverLogos';

const CONFETTI_COLORS = [
  LIGHT_GREEN,
  LIGHT_GREEN,
  LIGHT_GREEN_SHADOW,
  MID_GREEN,
  MID_GREEN,
  LIGHT_BLUE,
  FONT_BLACK,
  FONT_BLACK,
  FONT_BLACK,
  FONT_GRAY,
  FONT_GRAY,
  BACKGROUND_GRAY,
];

const GuidedSetupCompleteModal = ({ onClose }) => {
  return (
    <>
      {/* z-index is used to make this float above the Bootstrap Modal backdrop  */}
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        colors={CONFETTI_COLORS}
        recycle={false}
        confettiSource={{ x: 0, y: 0, w: window.innerWidth, h: 100 }}
        friction={1.01}
        gravity={-0.07}
        initialVelocityY={-10}
        numberOfPieces={1000}
        tweenDuration={6000}
      />

      <Modal show centered size="lg">
        <ProfitRoverModalCard>
          <ProfitRoverModalMain className="pl-3 pb-5">
            <div className="d-flex">
              <span
                role="img"
                aria-label="Celebrate"
                className="d-flex mx-2 justify-content-center"
                style={{ fontSize: 48, width: 80 }}
              >
                🎉
              </span>
              <div>
                <h3>Congratulations!</h3>
                <div className="mb-3" style={{ fontSize: 18, fontWeight: 500 }}>
                  <span className="mr-2">You have successfully set up</span>
                  <ProfitRoverLinearLogo style={{ width: 200 }} />
                </div>
                <p>You will receive a notification in the Notification Center when your insights are ready!</p>
              </div>
            </div>
          </ProfitRoverModalMain>
          <ProfitRoverModalFooter className="d-flex justify-content-center">
            <ProfitRoverPrimaryButton onClick={onClose}>OK</ProfitRoverPrimaryButton>
          </ProfitRoverModalFooter>
        </ProfitRoverModalCard>
      </Modal>
    </>
  );
};

export default GuidedSetupCompleteModal;
