export const INCOME_METRIC = 'GROSS_SALES';
export const DEMAND_METRIC = 'DEMAND';
export const TIPS_METRIC = 'TIPS';
export const GROSS_SALES_PLUS_TIPS_METRIC = 'GROSS_SALES_PLUS_TIPS';

export const METRIC_TO_LABEL = {
  [INCOME_METRIC]: 'Gross Sales',
  [DEMAND_METRIC]: 'Sales Qty',
  [TIPS_METRIC]: 'Tips',
  [GROSS_SALES_PLUS_TIPS_METRIC]: 'Gross Sales + Tips',
};

export const METRIC_OPTIONS = [
  {
    value: INCOME_METRIC,
    label: METRIC_TO_LABEL[INCOME_METRIC],
  },
  {
    value: DEMAND_METRIC,
    label: METRIC_TO_LABEL[DEMAND_METRIC],
  },
  {
    value: TIPS_METRIC,
    label: METRIC_TO_LABEL[TIPS_METRIC],
  },
  {
    value: GROSS_SALES_PLUS_TIPS_METRIC,
    label: METRIC_TO_LABEL[GROSS_SALES_PLUS_TIPS_METRIC],
  },
];

export const ACTUAL_INTERVAL = 'last';
export const PRIOR_INTERVAL = 'prior';
export const STLY_INTERVAL = 'last_stly';

export const YESTERDAY = 'yesterday';
export const LAST_WEEK = 'week';
export const LAST_MONTH = 'month';
export const LAST_QUARTER = 'quarter';
export const PERIOD_OPTIONS = [
  { label: 'Yesterday', value: YESTERDAY },
  { label: 'Last Week', value: LAST_WEEK },
  { label: 'Last Month', value: LAST_MONTH },
  { label: 'Last Quarter', value: LAST_QUARTER },
];

export const PERIOD_TO_INTERVAL_LABEL = {
  [YESTERDAY]: 'Yesterday',
  [LAST_WEEK]: 'Last Week',
  [LAST_MONTH]: 'Last Month',
  [LAST_QUARTER]: 'Last Quarter',
};
