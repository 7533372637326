import { DAY_OF_WEEK_DIMENSION_ID, WEEKS_SINCE_RELEASE_DIMENSION_ID } from './revenueUpliftConstants';

const DAY_OF_WEEK_LABEL_TO_ORDER = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
};

export const dayOfWeekOrder = dimValue => {
  return DAY_OF_WEEK_LABEL_TO_ORDER[dimValue.label];
};
export const caseInsensitiveAlphabeticalOrder = dimValue => {
  return (dimValue.label ?? '').trim().toLocaleLowerCase();
};
export const weeksSinceReleaseOrder = ({ label }) => label;

const DEFAULT_ORDER_BY_CRITERIA = caseInsensitiveAlphabeticalOrder;

const dimIdToUniqueOrderByCriteria = {
  [DAY_OF_WEEK_DIMENSION_ID]: dayOfWeekOrder,
  [WEEKS_SINCE_RELEASE_DIMENSION_ID]: weeksSinceReleaseOrder,
};

export const getOrderByCriteria = dimId => {
  return dimIdToUniqueOrderByCriteria[dimId] ?? DEFAULT_ORDER_BY_CRITERIA;
};
