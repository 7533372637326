import React from 'react';
import { DataType, Industry } from './DatasetConfigFormConstants';
import { createBaselineDatasetConfig, setDatasetConfigIsAssignedSeating } from './DatasetConfigFunctions';
import { useAddFieldsToDatasetConfig } from '../../../data-access/mutation/datasetConfigs';

export const Pages = {
  BASIC_INFO: 'BASIC_INFO',
  LOCATIONS: 'LOCATIONS',
  ATTENDANCE_DATE: 'ATTENDANCE_DATE',
  TRANSACTION_DATE: 'TRANSACTION_DATE',
  QUANTITY: 'QUANTITY',
  PRICE: 'PRICE',
  DIMENSIONS: 'DIMENSIONS',
  CFB_PREREQUISITES: 'CFB_PREREQUISITES',
  TEAM: 'TEAM',
  GAME_DATE: 'GAME_DATE',
  SEASON_YEAR: 'SEASON_YEAR',
  TICKET_PRICE: 'TICKET_PRICE',
  MANDATORY_ADDTL_FEES: 'MANDATORY_ADDTL_FEES',
  SEAT_ROW_SECTION: 'SEAT_ROW_SECTION',
  SEAT_BLOCK: 'SEAT_BLOCK',
  // REVIEW: 'REVIEW', // To be added later
};

const ActionTypes = {
  NAVIGATE_TO_PAGE: 'NAVIGATE_TO_PAGE',
  SET_BASIC_INFO: 'SET_BASIC_INFO',
  ADD_CONFIG_FIELD: 'ADD_CONFIG_FIELD',
  SET_ASSIGNED_SEATING: 'SET_ASSIGNED_SEATING',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.NAVIGATE_TO_PAGE:
      return { ...state, page: action.payload };
    case ActionTypes.SET_BASIC_INFO:
      return { ...state, ...action.payload };
    case ActionTypes.ADD_CONFIG_FIELD:
      return { ...state, configFields: [...state.configFields, action.payload] };
    case ActionTypes.SET_ASSIGNED_SEATING:
      return { ...state, isAssignedSeating: action.payload };
    default:
      return state;
  }
};

export const useFormPageApi = dsConfigIdToEdit => {
  const [state, dispatch] = React.useReducer(reducer, {
    page: Pages.BASIC_INFO,
    industry: null,
    dataType: null,
    timeGranularity: undefined,
    datasetConfigName: null,
    datasetConfigId: dsConfigIdToEdit,
    isAssignedSeating: false,
    configFields: [],
  });

  const addFieldsToDatasetConfig = useAddFieldsToDatasetConfig();

  const { datasetConfigId } = state;

  const navigateToPage = React.useCallback(page => {
    dispatch({ type: ActionTypes.NAVIGATE_TO_PAGE, payload: page });
  }, []);

  const setBasicInfo = React.useCallback(async (naicsKaCode, industry, dataType, timeGranularity, configName) => {
    // Insert a new dataset config in the database
    const response = await createBaselineDatasetConfig(configName, dataType, naicsKaCode, timeGranularity);

    const { id } = response?.data;
    dispatch({
      type: ActionTypes.SET_BASIC_INFO,
      payload: {
        industry,
        dataType,
        datasetConfigName: configName,
        datasetConfigId: id,
        timeGranularity,
      },
    });
  }, []);

  const addDatasetFields = React.useCallback(
    async datasetFields => {
      const fields = Array.isArray(datasetFields) ? datasetFields : [datasetFields];
      await addFieldsToDatasetConfig.mutateAsync({ datasetConfigId, datasetFields: fields });

      fields.forEach(field => dispatch({ type: ActionTypes.ADD_CONFIG_FIELD, payload: field }));
    },
    [addFieldsToDatasetConfig, datasetConfigId],
  );

  const setAssignedSeating = React.useCallback(
    async isAssignedSeating => {
      await setDatasetConfigIsAssignedSeating(datasetConfigId, isAssignedSeating);

      dispatch({ type: ActionTypes.SET_ASSIGNED_SEATING, payload: isAssignedSeating });
    },
    [datasetConfigId],
  );

  return {
    state,
    actions: {
      addDatasetFields,
      navigateToPage,
      setAssignedSeating,
      setBasicInfo,
    },
  };
};

export const computeNextPageForBasicInfo = (industry, dataType) => {
  if (industry === Industry.CFB) {
    if (dataType === DataType.ADVTRAN) {
      return Pages.CFB_PREREQUISITES;
    }
  }

  if (industry === Industry.EO) {
    if (dataType === DataType.ADMIT || dataType === DataType.TRAN) {
      return Pages.LOCATIONS;
    }
  }

  throw new Error('Developer Error: Unable to compute the next page to navigate to after "BasicInfo".');
};
