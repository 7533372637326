import React from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import Toggle from 'react-toggle';
import { computeNextStagedStatus, getApparentStatus, STATUSES_THAT_APPEAR_AS_ACTIVE } from './LocationsTableFunctions';
import { CountryDisplay } from '../../generic/countryConstants';
import LinkWithDirectionalArrow from '../../generic/LinkWithDirectionalArrow';
import { getLocationTableLabels } from '../../generic/locationTableLabels';
import ProfitRoverTooltip from '../../generic/ProfitRoverTooltip';
import { LocationActivationStatus } from '../../generic/subscriptions/SubscriptionConstants';
import ProfitRoverSpinner from '../../spinner/ProfitRoverSpinner';
import { SIMPLE_GRAY } from '../../../colors';

const { PENDING_ACTIVATION, PENDING_DEACTIVATION } = LocationActivationStatus;

const rowClassRules = {
  'pending-activation': params => {
    const { status, stagedStatus } = params.data;

    const apparentStatus = getApparentStatus(stagedStatus, status);

    return apparentStatus === PENDING_ACTIVATION;
  },
  'pending-deactivation': params => {
    const { status, stagedStatus } = params.data;

    const apparentStatus = getApparentStatus(stagedStatus, status);

    return apparentStatus === PENDING_DEACTIVATION;
  },
  unchangeable: params => {
    const { status } = params.data;

    /**
     * Any locations whose database status is "pending activation" should not be interactable,
     * as this means that the user has already confirmed their activation, but we're currently
     * waiting on the Stripe webhook to make it so. Hopefully, the user will never see this happen,
     * but we want to protect against the possibility nonetheless.
     */
    const isPendingActivation = status === PENDING_ACTIVATION;

    return isPendingActivation;
  },
};

const PENDING_DEACTIVATION_AUTO_RENEW_OFF_TOOLTIP = `This location is pending deactivation. You will lose any \
associated insights when your current billing cycle ends and will no longer be billed for it. You will have \
access to insights from this location until then.`;
const INACTIVE_AUTO_RENEW_OFF_TOOLTIP = `You cannot activate any locations at this time because your subscription \
plan is set to expire. Please turn Auto Renew back on to activate more locations.`;

const LocationToggle = props => {
  const {
    api,
    context: { autoRenewIsOn, locationTogglesEnabled: togglesEnabled, forceUpdate },
    data: { stagedStatus, status, isFreeLocation },
  } = props;
  const location = props.data;

  const apparentStatus = getApparentStatus(stagedStatus, status);

  const appearsAsActive = STATUSES_THAT_APPEAR_AS_ACTIVE.includes(apparentStatus);

  const toggleDisabled = !togglesEnabled || status === PENDING_ACTIVATION;

  const onClick = () => {
    if (!toggleDisabled) {
      const newStagedStatus = computeNextStagedStatus(status, stagedStatus);

      const rowNode = api.getRowNode(location.location_id);

      rowNode.setData({ ...location, stagedStatus: newStagedStatus });

      // Allows us to respond (at the top level) to changes the user makes
      forceUpdate();
    }
  };

  let toggle = <Toggle onClick={onClick} checked={appearsAsActive} disabled={toggleDisabled} readOnly />;

  if (isFreeLocation) {
    toggle = (
      <ProfitRoverTooltip
        tooltipClass="toggle-auto-renew-disabled-tooltip"
        shouldDisplayTooltip
        placement="right"
        tooltipText="This is the location that you chose as your free location. You will always have access to this."
      >
        <i style={{ color: SIMPLE_GRAY, fontSize: 16 }}>Free</i>
      </ProfitRoverTooltip>
    );
  } else if (!autoRenewIsOn) {
    const tooltipText =
      status === PENDING_DEACTIVATION ? PENDING_DEACTIVATION_AUTO_RENEW_OFF_TOOLTIP : INACTIVE_AUTO_RENEW_OFF_TOOLTIP;

    toggle = (
      <ProfitRoverTooltip
        tooltipClass="toggle-auto-renew-disabled-tooltip"
        shouldDisplayTooltip
        placement="right"
        tooltipText={tooltipText}
      >
        <div className="d-flex align-items-center">{toggle}</div>
      </ProfitRoverTooltip>
    );
  }

  return toggle;
};

const frameworkComponents = { locationToggle: LocationToggle };

const defaultColDef = {
  sortable: true,
  resizable: true,
  autoHeight: true,
  wrapText: true,
};

const LocationsTable = ({
  locationTableState,
  locationTogglesEnabled,
  autoRenewIsOn,
  loadingBillingInfo = false,
  showEditLocation = true,
}) => {
  const { onGridReady, forceUpdate, numberOfActiveLocations, isDataReady, initialRowData } = locationTableState;
  const loadingTable = !isDataReady;

  const getRowNodeId = React.useCallback(data => data.location_id, []);
  const { regionLabel, postcodeLabel } = getLocationTableLabels(initialRowData);

  const countryFormatter = React.useCallback(params => CountryDisplay[params.value], []);

  return loadingTable || loadingBillingInfo ? (
    <div className="locations-status-loading">
      <ProfitRoverSpinner />
    </div>
  ) : (
    <div className="locations-status">
      <div className="top">
        <div className="locations-status-summary">
          <h3 style={{ fontSize: 30 }}>{numberOfActiveLocations}</h3>

          <h3 style={{ fontSize: 24, position: 'relative', bottom: 2 }}>Active Locations</h3>
        </div>

        {showEditLocation && (
          <LinkWithDirectionalArrow
            target="/location-settings"
            text="Edit Location Info"
            direction="right"
            arrowRelativeToText="right"
            linkClassName="edit-location-info-link"
          />
        )}
      </div>

      {/* This wrapper div is necessary to prevent extra "whitespace" at the bottom of the table */}
      <div>
        <AgGridReact
          rowData={initialRowData}
          onGridReady={onGridReady}
          getRowNodeId={getRowNodeId}
          rowClassRules={rowClassRules}
          suppressDragLeaveHidesColumns
          defaultColDef={defaultColDef}
          className="ag-theme-alpine subscription-page-location-table"
          frameworkComponents={frameworkComponents}
          domLayout="autoHeight"
          headerHeight={50}
          context={{ autoRenewIsOn, locationTogglesEnabled, forceUpdate }}
        >
          <AgGridColumn
            width={80}
            suppressSizeToFit
            headerName="Active"
            headerClass="toggle-header"
            cellClass="toggle-cell"
            cellRenderer="locationToggle"
          />
          <AgGridColumn headerName="Location Name" field="location_description" minWidth={225} />
          <AgGridColumn
            headerName="Industry"
            width={225}
            field="industry_description"
            initialWidth={150}
            minWidth={150}
          />
          <AgGridColumn headerName="Country" field="country_code" valueFormatter={countryFormatter} initialWidth={90} />
          <AgGridColumn headerName="Address" width={225} field="address" initialWidth={300} minWidth={150} />
          <AgGridColumn headerName="City" field="city" initialWidth={120} />
          <AgGridColumn headerName={regionLabel} field="state" initialWidth={90} />
          <AgGridColumn headerName={postcodeLabel} field="zip" initialWidth={90} />
        </AgGridReact>
      </div>
    </div>
  );
};

export default LocationsTable;
