import React from 'react';
import { DataSource } from './dataSourceConstants';
import { getIntegrationTimestampLabel, DataSourceBase, ResyncDataSourceButton } from './DataSourceRowCommon';
import { CREDENTIAL_TYPE } from '../setup/IntegrationConstants';
import { DATA_SOURCE_TO_DATA_CENTER_LABEL } from '../setup/modern/DataSourceLogos';
import { usePartnerCredentials } from '../../data-access/query/credentials';

const ShopifyDataSourceRow = ({ datasetConfig, datasets }) => {
  const { data: shopifyCredentials = [], isLoading: isLoadingCredentials } = usePartnerCredentials(DataSource.SHOPIFY);
  const { partner, name } = datasetConfig;

  const validShopifyCredentials = shopifyCredentials.filter(cred => cred.revoked_gmt_datetime == null);
  const shopifyCredentialsRevocationTime = shopifyCredentials.find(
    credential => credential.credential_type === CREDENTIAL_TYPE.ACCESS_KEY,
  )?.revoked_gmt_datetime;

  // only 1 dataset per integration config is supported
  const [dataset] = datasets;
  const { fetched_gmt_datetime: datasetFetched } = dataset ?? {};

  const setupIncomplete = validShopifyCredentials.length === 0;

  const label = DATA_SOURCE_TO_DATA_CENTER_LABEL[partner];
  const timestamp = getIntegrationTimestampLabel(datasetFetched, setupIncomplete, shopifyCredentialsRevocationTime);
  const actionButtonIsDisabled = !validShopifyCredentials || isLoadingCredentials;

  // TODO: needs logic/action for the case where we do not have any credentials

  return (
    <DataSourceBase
      label={label}
      name={name}
      timestamp={timestamp}
      actions={<ResyncDataSourceButton datasetConfig={datasetConfig} disabled={actionButtonIsDisabled} />}
    />
  );
};

export default ShopifyDataSourceRow;
