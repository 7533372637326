/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toggle from 'react-toggle';
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { Link, Redirect } from 'react-router-dom';
import { faStar, faLongArrowAltRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import AdminRoleCheck from './AdminRoleCheck';
import CheckoutSwitchToAnnualPlan from './CheckoutSwitchToAnnualPlan';
import { NotFound } from './common/CommonSubscriptionComponents';
import { useLocationsWithSubscriptionStatus } from './LocationsTableFunctions';
import NAVIGATION_SEPARATOR from '../settingsNavigationMenuConstants';
import SettingsNavigationMenu from '../SettingsNavigationMenu';
import SettingsPageWrapper from '../SettingsPageWrapper';
import LoadingPage from '../common/LoadingPage';
import LoadingIndicator from '../common/LoadingIndicator';
import {
  ProfitRoverCancelButton,
  ProfitRoverDestructiveButton,
  ProfitRoverPrimaryButton,
  ProfitRoverSecondaryButton,
} from '../../forms/ProfitRoverButtons';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import StartPlanBanner from '../../generic/subscriptions/StartPlanBanner';
import {
  ProfitRoverCard,
  ProfitRoverModalCard,
  ProfitRoverModalMain,
  ProfitRoverModalFooter,
} from '../../generic/ProfitRoverCard';
import LinkWithDirectionalArrow from '../../generic/LinkWithDirectionalArrow';
import {
  KaizenProduct,
  KaizenProductStatus,
  StripeBillingScheme,
} from '../../generic/subscriptions/SubscriptionConstants';
import { ProfitRoverLinearLogo } from '../../generic/ProfitRoverLogos';
import ProfitRoverTooltip from '../../generic/ProfitRoverTooltip';
import Header from '../../header/header';
import { useProductPrices } from '../../registration/customerRegistrationHooks';
import { useSubscriptionStatusForProduct } from '../../routing/subscriptionStatusHooks';
import { CenteredProfitRoverSpinner } from '../../spinner/ProfitRoverSpinner';
import { BACKGROUND_GRAY } from '../../../colors';
import {
  cancelPendingIntervalChange,
  changePlanInterval,
  toggleAutoRenew,
} from '../../../data-access/mutation/subscriptions';
import { LOCATIONS_QUERY_KEY } from '../../../data-access/query/locations';
import { useProfitRoverSubscriptionPrices } from '../../../data-access/query/stripe';
import { BILLING_INFO_QUERY_KEY_BASE, useBillingInfo } from '../../../data-access/query/subscriptions';
import {
  gaEmitTurnOffAutoRenewButtonClick,
  gaEmitTurnOnAutoRenewToggleClick,
} from '../../../google-analytics/subscriptionSettings';
import priceTag from '../../../images/best-value-price-tag.png';
import './manage-plan.css';

const firstLocationFreeText = '1st Location Free';

const AnnualPlanCard = ({ prices, pricesLoading, isTieredBilling, pendingPlanChange = false, coupon }) => {
  const {
    annualPriceDollarAmt,
    annualPriceWithDiscount,
    savingsPerYear,
    savingsPerYearWithDiscount,
    savingsPerYearPct,
    savingsPerYearWithDiscountPct,
  } = useProductPrices(prices, coupon);

  if (pricesLoading) {
    return (
      <div className="plan-container annual-plan-container">
        <img src={priceTag} alt="best value price tag" className="price-tag-icon" />
        <div className="plan-name">Annual Plan</div>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="plan-container annual-plan-container">
      <img
        src={priceTag}
        alt="best value price tag"
        className={classnames('price-tag-icon', { 'price-tag-icon-plan-change': pendingPlanChange })}
      />
      <div className="plan-name">Annual Plan</div>
      {isTieredBilling && <div className="pb-1">{firstLocationFreeText}</div>}
      <p className="plan-price">
        {coupon ? (
          <>
            <span className="discount-price">${annualPriceDollarAmt}</span>
            <span>${annualPriceWithDiscount}</span>
          </>
        ) : (
          <span>${annualPriceDollarAmt}</span>
        )}
        <span> per Location per Year</span>
      </p>
      <div className="plan-savings-container">
        <div className="savings-per-year-text-container">
          <FontAwesomeIcon icon={faStar} color="#f6be00" />
          <p>You are saving {coupon ? savingsPerYearWithDiscountPct : savingsPerYearPct}%!</p>
          <FontAwesomeIcon icon={faStar} color="#f6be00" />
        </div>
        <div className="savings-per-location-text-container">
          <p className="savings-per-location-text">
            That&apos;s ${coupon ? savingsPerYearWithDiscount : savingsPerYear} per location!
          </p>
        </div>
      </div>
    </div>
  );
};

const MonthlyPlanCard = ({ prices, pricesLoading, isTieredBilling, coupon }) => {
  const { pricePerMonthDollarAmt, pricePerMonthWithDiscount } = useProductPrices(prices, coupon);

  if (pricesLoading) {
    return (
      <div className="plan-container monthly-plan-container">
        <div className="plan-name">Monthly Plan</div>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="plan-container monthly-plan-container">
      <div className="plan-name">Monthly Plan</div>
      {isTieredBilling && <div className="pb-1">{firstLocationFreeText}</div>}
      <p className="plan-price">
        {coupon ? (
          <>
            <span className="discount-price">${pricePerMonthDollarAmt}</span>
            <span>${pricePerMonthWithDiscount}</span>
          </>
        ) : (
          <span>${pricePerMonthDollarAmt}</span>
        )}
        <span> per Location per Month</span>
      </p>
      <div className="plan-savings-container">No commitment, pay month to month</div>
    </div>
  );
};

const PlanSwitchConfirmedModal = ({ show, toggleOpen }) => {
  return (
    <Modal
      show={show}
      onHide={toggleOpen}
      animation={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      className="plan-switch-confirmed-modal"
      centered
    >
      <ProfitRoverModalMain className="manage-plan-modal confirmed">
        <Modal.Title id="contained-modal-title-vcenter">Plan Switch Confirmed</Modal.Title>
        <div>
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#5fad56', fontSize: '6rem', marginBottom: '3rem' }} />
        </div>
      </ProfitRoverModalMain>
    </Modal>
  );
};

const TrialSwitchPlanIntervalModal = ({
  show,
  toggleOpen,
  currentBillingCycleEndDateString,
  billingInterval,
  prices,
  subscriptionId,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorSwitchInterval, setErrorSwitchInterval] = React.useState(false);
  const queryClient = useQueryClient();

  const newBillingInterval = billingInterval === 'month' ? prices?.annual?.id : prices?.monthly?.id;
  const newBillingIntervalString = billingInterval === 'month' ? 'Annual Plan' : 'Monthly Plan';

  const submitConfirm = async () => {
    try {
      setIsSubmitting(true);
      await Promise.all([
        new Promise(resolve => setTimeout(() => resolve(), 2000)), // show the 'Confirmed' modal for at least two seconds
        changePlanInterval(subscriptionId, newBillingInterval),
      ]);
      toggleOpen(); // if the async call above fails, the modal won't hide and user can see the error message on the modal
    } catch (error) {
      setErrorSwitchInterval(true);
    } finally {
      setIsSubmitting(false);
      await queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId]);
    }
  };

  if (isSubmitting) {
    return <PlanSwitchConfirmedModal show={show} toggleOpen={toggleOpen} />;
  }

  return (
    <Modal
      show={show}
      onHide={toggleOpen}
      animation={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      className="manage-plan-modal manage-plan-container"
      centered
    >
      <ProfitRoverModalCard>
        <div className="profitrover-modal-header">
          <div className="profitrover-modal-title" id="contained-modal-title-vcenter">
            Confirm Your Changes
          </div>
        </div>
        <ProfitRoverModalMain>
          {errorSwitchInterval ? (
            <div className="submit-error">
              An error has occurred. Please try again later or reach out to our <Link to="/help">support team</Link> to
              switch your plan interval period.
            </div>
          ) : (
            <>
              <div className="modal-scheduled-date-trial">
                <p>
                  You have selected the <b>{newBillingIntervalString}</b>.
                </p>
                <p>
                  Your paid plan begins on <b>{currentBillingCycleEndDateString}</b>.
                </p>
              </div>
              <div className="modal-details-text">
                You are continuing your trial until the date above, then you will automatically start your plan and be
                charged.
              </div>
            </>
          )}
        </ProfitRoverModalMain>
        <ProfitRoverModalFooter>
          <ProfitRoverCancelButton small onClick={toggleOpen}>
            Cancel
          </ProfitRoverCancelButton>
          <ProfitRoverPrimaryButton small className="confirm-button" onClick={submitConfirm}>
            Confirm Changes
          </ProfitRoverPrimaryButton>
        </ProfitRoverModalFooter>
      </ProfitRoverModalCard>
    </Modal>
  );
};

const AnnualToMonthlyConfirmationModal = ({
  show,
  toggleOpen,
  currentBillingCycleEndDateString,
  prices,
  pricesLoading,
  setPendingAnnualToMonthlyChange,
  subscriptionId,
  isTieredBilling,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorAnnualToMonthlySwitch, setErrorAnnualToMonthlySwitch] = React.useState(false);
  const queryClient = useQueryClient();

  const submitConfirm = async () => {
    try {
      setIsSubmitting(true);
      await Promise.all([
        new Promise(resolve => setTimeout(() => resolve(), 2000)), // show the 'Confirmed' modal for at least two seconds
        changePlanInterval(subscriptionId, prices.monthly.id),
      ]);
      toggleOpen();
    } catch (error) {
      setErrorAnnualToMonthlySwitch(true);
    } finally {
      await queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId]);
      setPendingAnnualToMonthlyChange(true);
      setIsSubmitting(false);
    }
  };

  if (isSubmitting) {
    return <PlanSwitchConfirmedModal show={show} toggleOpen={toggleOpen} />;
  }

  return (
    <Modal
      show={show}
      onHide={toggleOpen}
      animation={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      className="manage-plan-modal manage-plan-container"
      centered
    >
      <ProfitRoverModalCard>
        <div className="profitrover-modal-header">
          <div className="profitrover-modal-title" id="contained-modal-title-vcenter">
            Confirm Your Plan Switch
          </div>
        </div>
        <ProfitRoverModalMain>
          {errorAnnualToMonthlySwitch && (
            <div className="submit-error">
              An error has occurred. Please try again later or reach out to our <Link to="/help">support team</Link> to
              switch your billing period.
            </div>
          )}
          <div className="modal-scheduled-date">
            Scheduling for <b>{currentBillingCycleEndDateString}</b>
          </div>
          <div className="plans-container">
            <div className="plan-card">
              <div className="plan-card-title">Current Plan</div>
              <AnnualPlanCard prices={prices} pricesLoading={pricesLoading} isTieredBilling={isTieredBilling} />
            </div>
            <FontAwesomeIcon icon={faLongArrowAltRight} className="plan-arrow" />
            <div className="plan-card">
              <div className="plan-card-title">New Plan on {currentBillingCycleEndDateString}</div>
              <MonthlyPlanCard prices={prices} pricesLoading={pricesLoading} isTieredBilling={isTieredBilling} />
            </div>
          </div>
          <div className="modal-details-text">
            You will continue with your annual plan until the end of your current billing period. You will then
            automatically switch from the <b>annual plan</b> to the <b>monthly plan</b>.
          </div>
        </ProfitRoverModalMain>
        <ProfitRoverModalFooter>
          <ProfitRoverCancelButton small onClick={toggleOpen}>
            Cancel
          </ProfitRoverCancelButton>
          <ProfitRoverPrimaryButton small onClick={submitConfirm}>
            Confirm Changes
          </ProfitRoverPrimaryButton>
        </ProfitRoverModalFooter>
      </ProfitRoverModalCard>
    </Modal>
  );
};

const CancelPendingPlanChangeModal = ({
  show,
  toggleOpen,
  currentBillingCycleEndDateString,
  setPendingAnnualToMonthlyChange,
  billingInterval,
  prices,
  subscriptionId,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorCancelPendingPlanChange, setErrorCancelPendingPlanChange] = React.useState(false);
  const queryClient = useQueryClient();

  const billingIntervalToRestore = billingInterval === 'month' ? prices?.monthly?.id : prices?.annual?.id;

  const submitConfirm = async () => {
    try {
      setIsSubmitting(true);
      await Promise.all([
        new Promise(resolve => setTimeout(() => resolve(), 2000)), // show the 'Confirmed' modal for at least two seconds
        cancelPendingIntervalChange(subscriptionId, billingIntervalToRestore),
      ]);
      toggleOpen();
    } catch (error) {
      setErrorCancelPendingPlanChange(true);
    } finally {
      setIsSubmitting(false);
      await queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId]);
      setPendingAnnualToMonthlyChange(false);
    }
  };

  if (isSubmitting) {
    return <PlanSwitchConfirmedModal show={show} toggleOpen={toggleOpen} />;
  }

  return (
    <Modal show={show} onHide={toggleOpen} animation={false} centered className="plan-switch-cancel-modal">
      <ProfitRoverModalCard>
        <div className="profitrover-modal-header">
          <div className="profitrover-modal-title" id="contained-modal-title-vcenter">
            Cancel Scheduled Plan Switch
          </div>
        </div>
        <ProfitRoverModalMain>
          {errorCancelPendingPlanChange ? (
            <div className="modal-error">
              An error has occurred. Please try again later or reach out to our <Link to="/help">support team</Link> to
              cancel your scheduled plan interval period change.
            </div>
          ) : (
            <>
              You will no longer switch to the <b>monthly plan</b> on <b>{currentBillingCycleEndDateString}</b>. You
              will proceed with your current plan.
            </>
          )}
        </ProfitRoverModalMain>
        <ProfitRoverModalFooter>
          <ProfitRoverCancelButton small onClick={toggleOpen}>
            Nevermind
          </ProfitRoverCancelButton>
          <ProfitRoverPrimaryButton small className="confirm-button" onClick={submitConfirm}>
            Confirm Changes
          </ProfitRoverPrimaryButton>
        </ProfitRoverModalFooter>
      </ProfitRoverModalCard>
    </Modal>
  );
};

const TurnOffAutoRenewModal = ({ show, toggleOpen, subscriptionId }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorToggling, setErrorToggling] = React.useState(false);
  const queryClient = useQueryClient();

  const submitConfirm = async () => {
    gaEmitTurnOffAutoRenewButtonClick();
    setIsSubmitting(true);
    setErrorToggling(false);
    try {
      await toggleAutoRenew(subscriptionId);
      await Promise.allSettled([
        queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId]),
        queryClient.refetchQueries(LOCATIONS_QUERY_KEY),
      ]);
      toggleOpen();
    } catch {
      setErrorToggling(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={toggleOpen} animation={false} centered className="auto-renew-modal">
      <ProfitRoverModalCard>
        <div className="profitrover-modal-header">
          <div className="profitrover-modal-title" id="contained-modal-title-vcenter">
            Turn off auto renew?
          </div>
        </div>
        <ProfitRoverModalMain>
          {errorToggling ? (
            <div className="submit-error">
              An error has occurred. Please try again later or reach out to our <Link to="/help">support team</Link> to
              disable auto-renew.
            </div>
          ) : (
            <>
              <p>You will lose access to any paid locations at the end of your current billing period.</p>
              <p>You can always turn it back on before then.</p>
            </>
          )}
        </ProfitRoverModalMain>
        <ProfitRoverModalFooter>
          <ProfitRoverPrimaryButton small onClick={toggleOpen}>
            Nevermind
          </ProfitRoverPrimaryButton>
          <ProfitRoverDestructiveButton small onClick={submitConfirm} disabled={isSubmitting}>
            {isSubmitting ? <CenteredProfitRoverSpinner /> : 'Turn Off Auto Renew'}
          </ProfitRoverDestructiveButton>
        </ProfitRoverModalFooter>
      </ProfitRoverModalCard>
    </Modal>
  );
};

const CANNOT_TOGGLE_TOOLTIP_TEXT = (
  <span>
    You cannot turn off Auto Renew due to changes you have made to your subscription. You have paid locations that are
    <i> pending deactivation</i> when your next billing cycle begins or you have <i>scheduled a plan switch</i> from the
    annual plan to the monthly plan. You will need to undo those changes before you can turn off Auto Renew.
  </span>
);

const AutoRenewToggleSection = ({
  subscriptionId,
  billingInfoLoading,
  isTieredBilling,
  autoRenewIsOn,
  isBillingIntervalChangeScheduled = false,
  toggleAutoRenewModal,
  currentBillingCycleEndDateString,
  isTrialing = false,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorToggling, setErrorToggling] = React.useState(false);
  const queryClient = useQueryClient();

  const { isLoading, hasPendingDeactivations } = useLocationsWithSubscriptionStatus(subscriptionId);
  const hasScheduledChanges = isLoading || hasPendingDeactivations || isBillingIntervalChangeScheduled;

  const submitEnableAutoRenew = async () => {
    gaEmitTurnOnAutoRenewToggleClick();
    setIsSubmitting(true);
    setErrorToggling(false);
    try {
      await toggleAutoRenew(subscriptionId);
      await Promise.allSettled([
        queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId]),
        queryClient.refetchQueries(LOCATIONS_QUERY_KEY),
      ]);
    } catch {
      setErrorToggling(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  let autoRenewDetailString = '';
  if (!autoRenewIsOn && isTrialing) {
    autoRenewDetailString = `Your paid subscription will NOT begin when your trial ends.`;
  } else if (!autoRenewIsOn && !isTrialing) {
    autoRenewDetailString = `Your plan will NOT renew on ${currentBillingCycleEndDateString}.`;
    if (isTieredBilling) {
      autoRenewDetailString += ' You will still have access to your Free Location.';
    }
  } else if (autoRenewIsOn && isTrialing) {
    autoRenewDetailString = `You will automatically start your selected plan when your trial ends.`;
  } else {
    autoRenewDetailString = `Your selected plan will renew on ${currentBillingCycleEndDateString}`;
  }

  const cannotTurnOff = autoRenewIsOn && hasScheduledChanges;

  let toggle = (
    <Toggle
      checked={autoRenewIsOn}
      disabled={isSubmitting || cannotTurnOff}
      onClick={autoRenewIsOn ? toggleAutoRenewModal : submitEnableAutoRenew}
      readOnly
    />
  );
  if (cannotTurnOff) {
    toggle = (
      <ProfitRoverTooltip
        tooltipClass="toggle-auto-renew-disabled-tooltip"
        shouldDisplayTooltip
        placement="right"
        tooltipText={CANNOT_TOGGLE_TOOLTIP_TEXT}
      >
        <div className="d-flex align-items-center">{toggle}</div>
      </ProfitRoverTooltip>
    );
  }

  return (
    <div className="auto-renew-container">
      <div className="auto-renew-toggle">
        <div className="auto-renew-text">Auto Renew</div>
        {isSubmitting || billingInfoLoading ? <LoadingIndicator /> : toggle}
      </div>
      <div className="auto-renew-details">{autoRenewDetailString}</div>

      <div className="auto-renew-contact-us">
        {errorToggling ? (
          <span>
            An error has occurred. Please try again later or reach out to our <Link to="/help">support team</Link> to
            re-enable auto-renew.
          </span>
        ) : (
          <span>
            Questions? <Link to="/help">Contact Us</Link>
          </span>
        )}
      </div>
    </div>
  );
};

const ManagePlan = ({ subscriptionId, setCheckingOutAnnualPlan }) => {
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [showAutoRenewModal, setShowAutoRenewModal] = React.useState(false);
  const [pendingAnnualToMonthlyChange, setPendingAnnualToMonthlyChange] = React.useState();
  const billingInfoState = useBillingInfo(subscriptionId);
  const { data: billingInfo = {}, isFetching: billingInfoLoading } = billingInfoState;

  const {
    billing_interval: billingInterval,
    is_billing_interval_change_scheduled: isBillingIntervalChangeScheduled,
    current_billing_cycle_end: currentBillingCycleEnd,
    auto_renew: autoRenewIsOn,
    price_nickname: priceName,
    billing_scheme: billingScheme,
    coupon,
  } = billingInfo?.plan_info ?? {};

  const isTieredBilling = billingScheme === StripeBillingScheme.TIERED;

  React.useEffect(() => {
    if (!billingInfoLoading) {
      setPendingAnnualToMonthlyChange(isBillingIntervalChangeScheduled);
    }
  }, [billingInfoLoading, isBillingIntervalChangeScheduled]);

  const { data: priceMap = {}, isFetching: pricesLoading } = useProfitRoverSubscriptionPrices(priceName, {
    enabled: !!priceName,
  });
  const prices = priceMap[priceName];

  const toggleConfirmModal = () => setShowConfirmModal(!showConfirmModal);
  const toggleCancelModalOpen = () => setShowCancelModal(!showCancelModal);
  const toggleAutoRenewModal = () => setShowAutoRenewModal(!showAutoRenewModal);
  const toggleAnnualPlanCheckout = () => setCheckingOutAnnualPlan(true);

  const currentBillingCycleEndDateString = currentBillingCycleEnd
    ? moment.unix(currentBillingCycleEnd).format('MMM Do, YYYY')
    : '';

  const showingModal = showConfirmModal || showCancelModal || showAutoRenewModal;

  const blurStyles = { filter: showingModal ? 'blur(8px)' : undefined };

  return (
    <HeaderAwarePage>
      <Header />
      <SettingsNavigationMenu label={`Settings ${NAVIGATION_SEPARATOR} Subscription`} />
      <SettingsPageWrapper style={{ backgroundColor: BACKGROUND_GRAY, ...blurStyles }}>
        <AnnualToMonthlyConfirmationModal
          show={showConfirmModal}
          toggleOpen={toggleConfirmModal}
          currentBillingCycleEndDateString={currentBillingCycleEndDateString}
          prices={prices}
          pricesLoading={pricesLoading}
          setPendingAnnualToMonthlyChange={setPendingAnnualToMonthlyChange}
          subscriptionId={subscriptionId}
          isTieredBilling={isTieredBilling}
        />
        <CancelPendingPlanChangeModal
          show={showCancelModal}
          toggleOpen={toggleCancelModalOpen}
          currentBillingCycleEndDateString={currentBillingCycleEndDateString}
          setPendingAnnualToMonthlyChange={setPendingAnnualToMonthlyChange}
          billingInterval={billingInterval}
          prices={prices}
          subscriptionId={subscriptionId}
        />
        <TurnOffAutoRenewModal
          show={showAutoRenewModal}
          toggleOpen={toggleAutoRenewModal}
          subscriptionId={subscriptionId}
        />
        <div className="manage-plan-container">
          <div>
            <LinkWithDirectionalArrow
              target="/subscription-management"
              text="Back to Subscription"
              direction="left"
              arrowRelativeToText="left"
              linkClassName="back-to-subscription-link"
            />
            <div className="manage-plan-header">Manage Your Plan</div>
          </div>
          <div className="summary">
            <ProfitRoverCard>
              <ProfitRoverLinearLogo className="profitrover-logo" />
              {pendingAnnualToMonthlyChange && (
                <div className="pending-plan-change-container">
                  You have scheduled a plan switch to occur at the end of your current billing period.
                  <ProfitRoverSecondaryButton
                    className="cancel-pending-plan-change-button"
                    onClick={toggleCancelModalOpen}
                  >
                    Cancel Plan Switch
                  </ProfitRoverSecondaryButton>
                </div>
              )}
              {pendingAnnualToMonthlyChange ? (
                <div className="plans-container pending-plan-change">
                  <div className="container" style={{ paddingRight: '0px' }}>
                    <div className="plan-title">Current Plan</div>
                    <AnnualPlanCard
                      prices={prices}
                      pricesLoading={pricesLoading}
                      isTieredBilling={isTieredBilling}
                      pendingPlanChange={pendingAnnualToMonthlyChange}
                      coupon={coupon}
                    />
                  </div>
                  <FontAwesomeIcon
                    icon={faLongArrowAltRight}
                    className="plan-arrow"
                    style={{ fontSize: '25px', marginLeft: '20px' }}
                  />
                  <div className="container" style={{ paddingLeft: '20px' }}>
                    <div className="plan-title">New Plan on {currentBillingCycleEndDateString}</div>
                    <MonthlyPlanCard
                      prices={prices}
                      pricesLoading={pricesLoading}
                      isTieredBilling={isTieredBilling}
                      coupon={coupon}
                    />
                  </div>
                </div>
              ) : (
                <div className="plans-container">
                  <div
                    className="container"
                    onClick={billingInterval === 'month' ? toggleAnnualPlanCheckout : undefined}
                    role="button"
                  >
                    <AnnualPlanCard
                      prices={prices}
                      pricesLoading={pricesLoading}
                      isTieredBilling={isTieredBilling}
                      coupon={coupon}
                    />
                    <input
                      type="radio"
                      name="radio-plan"
                      checked={pricesLoading ? false : billingInterval === 'year'}
                      readOnly
                    />
                    <span className="checkmark" />
                  </div>

                  <div
                    className="container"
                    onClick={billingInterval === 'year' ? toggleConfirmModal : undefined}
                    role="button"
                  >
                    <MonthlyPlanCard
                      prices={prices}
                      pricesLoading={pricesLoading}
                      isTieredBilling={isTieredBilling}
                      coupon={coupon}
                    />
                    <input
                      type="radio"
                      name="radio-plan"
                      checked={pricesLoading ? false : billingInterval === 'month'}
                      readOnly
                    />
                    <span className="checkmark" />
                  </div>
                </div>
              )}
              <div className="plan-details">
                <AutoRenewToggleSection
                  subscriptionId={subscriptionId}
                  isBillingIntervalChangeScheduled={isBillingIntervalChangeScheduled}
                  isTieredBilling={isTieredBilling}
                  billingInfoLoading={billingInfoLoading}
                  autoRenewIsOn={autoRenewIsOn}
                  toggleAutoRenewModal={toggleAutoRenewModal}
                  currentBillingCycleEndDateString={currentBillingCycleEndDateString}
                />
              </div>
            </ProfitRoverCard>
          </div>
        </div>
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

const ManagePlanTrial = ({ subscriptionId }) => {
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showAutoRenewModal, setShowAutoRenewModal] = React.useState(false);
  const isTrialing = true;
  const billingInfoState = useBillingInfo(subscriptionId);
  const { data: billingInfo = {}, isFetching: billingInfoLoading } = billingInfoState;

  const {
    billing_interval: billingInterval,
    trial_days_remaining: trialDaysRemaining,
    current_billing_cycle_end: currentBillingCycleEnd,
    auto_renew: autoRenewIsOn,
    price_nickname: priceName,
  } = billingInfo?.plan_info ?? {};

  const { data: priceMap = {}, isFetching: pricesLoading } = useProfitRoverSubscriptionPrices(priceName, {
    enabled: !!priceName,
  });
  const prices = priceMap[priceName];

  const toggleConfirmModal = () => setShowConfirmModal(!showConfirmModal);
  const toggleAutoRenewModal = () => setShowAutoRenewModal(!showAutoRenewModal);
  const daysText = trialDaysRemaining === 1 ? 'day' : 'days'; // pluralize if necessary

  const currentBillingCycleEndDateString = currentBillingCycleEnd
    ? moment.unix(currentBillingCycleEnd).format('MMM Do, YYYY')
    : '';

  return (
    <HeaderAwarePage>
      <Header />
      <SettingsNavigationMenu label={`Settings ${NAVIGATION_SEPARATOR} Subscription`} />
      <SettingsPageWrapper style={{ backgroundColor: BACKGROUND_GRAY }}>
        <TrialSwitchPlanIntervalModal
          show={showConfirmModal}
          toggleOpen={toggleConfirmModal}
          currentBillingCycleEndDateString={currentBillingCycleEndDateString}
          billingInterval={billingInterval}
          prices={prices}
          pricesLoading={pricesLoading}
          subscriptionId={subscriptionId}
        />
        <TurnOffAutoRenewModal
          show={showAutoRenewModal}
          toggleOpen={toggleAutoRenewModal}
          subscriptionId={subscriptionId}
        />
        <div className="manage-plan-container">
          <div>
            <LinkWithDirectionalArrow
              target="/subscription-management"
              text="Back to Subscription"
              direction="left"
              arrowRelativeToText="left"
              linkClassName="back-to-subscription-link"
            />
            <div className="manage-plan-header">Manage Your Plan</div>
          </div>
          <div className="summary">
            <ProfitRoverCard>
              <ProfitRoverLinearLogo className="profitrover-logo" />
              <div className="days-remaining-container">
                <span className="days-remaining">
                  You have{' '}
                  <span className="days-remaining-value">
                    {trialDaysRemaining} {daysText}
                  </span>{' '}
                  remaining on your free trial.
                </span>
              </div>
              <div className="start-my-plan-banner-container">
                <StartPlanBanner />
                <hr />
              </div>
              <span className="selected-plan">When my trial ends, I want to be on the selected plan:</span>

              <div className="plans-container">
                <div
                  className="container"
                  onClick={billingInterval === 'month' ? toggleConfirmModal : undefined}
                  role="button"
                >
                  <AnnualPlanCard prices={prices} pricesLoading={pricesLoading} />
                  <input
                    type="radio"
                    name="radio-plan"
                    checked={pricesLoading ? false : billingInterval === 'year'}
                    readOnly
                  />
                  <span className="checkmark" />
                </div>

                <div
                  className="container"
                  onClick={billingInterval === 'year' ? toggleConfirmModal : undefined}
                  role="button"
                >
                  <MonthlyPlanCard prices={prices} pricesLoading={pricesLoading} />
                  <input
                    type="radio"
                    name="radio-plan"
                    checked={pricesLoading ? false : billingInterval === 'month'}
                    readOnly
                  />
                  <span className="checkmark" />
                </div>
              </div>

              <div className="plan-details">
                <AutoRenewToggleSection
                  subscriptionId={subscriptionId}
                  billingInfoLoading={billingInfoLoading}
                  autoRenewIsOn={autoRenewIsOn}
                  isTieredBilling={false}
                  toggleAutoRenewModal={toggleAutoRenewModal}
                  billingInterval={billingInterval}
                  currentBillingCycleEndDateString={currentBillingCycleEndDateString}
                  isTrialing={isTrialing}
                />
              </div>
            </ProfitRoverCard>
          </div>
        </div>
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

const ManagePlanHandler = () => {
  const {
    isFetching,
    subscriptionsByKaizenProduct,
    isStripeSubscription,
    hasSubscription,
    kaizenProductStatus,
    isDelinquent,
  } = useSubscriptionStatusForProduct(KaizenProduct.KP);

  const [checkingOutAnnualPlan, setCheckingOutAnnualPlan] = React.useState(false);

  if (isFetching) {
    return <LoadingPage />;
  }

  if (checkingOutAnnualPlan) {
    const kaizenPriceSubscriptionId = subscriptionsByKaizenProduct[KaizenProduct.KP].subscription_id;

    return (
      <CheckoutSwitchToAnnualPlan
        setCheckingOutAnnualPlan={setCheckingOutAnnualPlan}
        subscriptionId={kaizenPriceSubscriptionId}
      />
    );
  }

  if (hasSubscription && isStripeSubscription) {
    const subscription = subscriptionsByKaizenProduct[KaizenProduct.KP];
    const { subscription_id: kaizenPriceSubscriptionId } = subscription;

    if (isDelinquent) {
      return <Redirect to="/invoices" />;
    }

    if (kaizenProductStatus === KaizenProductStatus.TRIALING) {
      return <ManagePlanTrial subscriptionId={kaizenPriceSubscriptionId} />;
    }
    return (
      <ManagePlan subscriptionId={kaizenPriceSubscriptionId} setCheckingOutAnnualPlan={setCheckingOutAnnualPlan} />
    );
  }
  return <NotFound />;
};

const RoleCheck = props => (
  <AdminRoleCheck>
    <ManagePlanHandler {...props} />
  </AdminRoleCheck>
);

export default RoleCheck;
