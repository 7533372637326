import { addDays, isSunday, isValid, nextMonday, nextSunday, startOfToday } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { getDimColumnKey } from './revenueUpliftDashboardComputations';
import { LOCATIONS_DIMENSION_ID } from './revenueUpliftConstants';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import {
  DAY_START_HOUR,
  DEFAULT_STAFFING_ROLE,
  HOURS_METRIC,
  INCOME_METRIC,
  INCOME_PER_EMPLOYEE_HOUR_METRIC,
  MAX_SHIFT_LENGTH,
  MIN_HOURLY_REVENUE_PER_EMPLOYEE,
  MIN_HOURLY_STAFF,
  MIN_SHIFT_LENGTH,
  PERIOD_OPTIONS,
  SHIFTS_METRIC,
  STAFFING_ROLE_KEYS,
  TIPS_METRIC,
  TIPS_PER_EMPLOYEE_HOUR_METRIC,
  TIPS_PER_EMPLOYEE_METRIC,
  TOTAL_HEADCOUNT_METRIC,
  TOTAL_INCOME_METRIC,
  TOTAL_INCOME_PER_EMPLOYEE_METRIC,
  TOTAL_TIPS_METRIC,
} from './staffingTabConstants';
import { formatAsDatasetDate, getDayOfWeek, parseDatasetDate } from './timePeriod';
import { compactFormatNumber, formatNumber as simpleFormatNumber } from '../../../util/format';
import { HOUR_DIMENSION_TYPE } from '../../../workflow/workflowConstants';
import { useUpdateStaffingRoles } from '../../../../data-access/mutation/staffingRoles';
import { useStaffingRoles } from '../../../../data-access/query/staffingRoles';
import { generateDatesBetween } from '../../../../utils/date-handling';

const areRolesDifferent = (role1, role2) => {
  return !_.isEqual(role1, role2);
};

const roleIsValid = role => {
  return (
    role[STAFFING_ROLE_KEYS.STAFFING_ROLE_NAME].length >= 1 &&
    role[STAFFING_ROLE_KEYS.MIN_HOURLY_STAFF_DAY] >= MIN_HOURLY_STAFF &&
    role[STAFFING_ROLE_KEYS.MIN_HOURLY_STAFF_CLOSE] >= MIN_HOURLY_STAFF &&
    role[STAFFING_ROLE_KEYS.MIN_HOURLY_REVENUE_PER_EMPLOYEE] >= 0 &&
    role[STAFFING_ROLE_KEYS.MAX_HOURLY_REVENUE_PER_EMPLOYEE] >=
      Math.max(role[STAFFING_ROLE_KEYS.MIN_HOURLY_REVENUE_PER_EMPLOYEE], MIN_HOURLY_REVENUE_PER_EMPLOYEE) &&
    role[STAFFING_ROLE_KEYS.MIN_HOURLY_TIPS_PER_EMPLOYEE] >= 0 &&
    role[STAFFING_ROLE_KEYS.MAX_HOURLY_TIPS_PER_EMPLOYEE] >= role[STAFFING_ROLE_KEYS.MIN_HOURLY_TIPS_PER_EMPLOYEE] &&
    role[STAFFING_ROLE_KEYS.MIN_SHIFT_LENGTH] >= MIN_SHIFT_LENGTH &&
    role[STAFFING_ROLE_KEYS.MIN_SHIFT_LENGTH] <= role[STAFFING_ROLE_KEYS.MAX_SHIFT_LENGTH] &&
    role[STAFFING_ROLE_KEYS.MAX_SHIFT_LENGTH] >= role[STAFFING_ROLE_KEYS.MIN_SHIFT_LENGTH] &&
    role[STAFFING_ROLE_KEYS.MAX_SHIFT_LENGTH] <= MAX_SHIFT_LENGTH
  );
};

// Returns > 0 if hour1 is after hour2, < 0 if hour1 is before hour2, 0 if they are the same
export const compareHours = (hour1, hour2, hoursRange) => {
  return hoursRange.indexOf(hour1) - hoursRange.indexOf(hour2);
};

export const addHours = (hour, hoursToAdd, hoursRange) => {
  const hourIndex = hoursRange.indexOf(hour);

  return hoursRange[hourIndex + hoursToAdd] ?? hoursRange[hoursToAdd > 0 ? hoursRange.length - 1 : 0];
};

export const useStaffingRolesApi = ({ currentLocationId, locations, staffingEnabled }) => {
  const [staffingRoleChanges, setStaffingRoleChanges] = React.useState(() => {
    const initialRoles = [];

    locations.forEach(({ location_id: locationId }) => {
      initialRoles.push({
        location_id: locationId,
        staffing_roles: [DEFAULT_STAFFING_ROLE],
      });
    });

    return [initialRoles];
  });

  const { mutate: updateStaffingRoles, isLoading: isLoadingStaffingRolesUpdate } = useUpdateStaffingRoles();

  const { isLoading: isLoadingStaffingRoles } = useStaffingRoles({
    onSuccess: data => {
      const currentRoles = [...data];
      let additions = 0;

      locations.forEach(({ location_id: locationId }) => {
        const locationRoles = currentRoles.find(({ location_id: currentId }) => currentId === locationId);

        if (!locationRoles) {
          currentRoles.push({
            location_id: locationId,
            staffing_roles: [DEFAULT_STAFFING_ROLE],
          });
          additions += 1;
        } else if (!locationRoles.staffing_roles || locationRoles.staffing_roles.length === 0) {
          locationRoles.staffing_roles = [DEFAULT_STAFFING_ROLE];
          additions += 1;
        }
      });

      if (additions > 0) {
        // Update the staffing roles in the backend
        updateStaffingRoles(currentRoles);
      }

      // Overwrite the initial roles with the fetched ones
      const [, ...rest] = staffingRoleChanges;
      setStaffingRoleChanges([currentRoles, ...rest]);
    },
    enabled: staffingEnabled,
  });

  const getCurrentStaffingRoleFromChanges = React.useCallback((locationId, changes) => {
    const currentStaffingRolesChanges = changes[changes.length - 1];

    const currentLocationRoles =
      currentStaffingRolesChanges.find(({ location_id: currentId }) => currentId === locationId)?.staffing_roles ?? [];

    return currentLocationRoles.find(({ name }) => name === 'Default') ?? DEFAULT_STAFFING_ROLE;
  }, []);

  const [currentStaffingRole, setCurrentStaffingRole] = React.useState(() =>
    getCurrentStaffingRoleFromChanges(currentLocationId, staffingRoleChanges),
  );

  React.useEffect(() => {
    setCurrentStaffingRole(getCurrentStaffingRoleFromChanges(currentLocationId, staffingRoleChanges));
  }, [staffingRoleChanges, currentLocationId, getCurrentStaffingRoleFromChanges]);

  const saveCurrentStaffingRole = () => {
    const previousRole = getCurrentStaffingRoleFromChanges(currentLocationId, staffingRoleChanges);

    if (areRolesDifferent(previousRole, currentStaffingRole) && roleIsValid(currentStaffingRole)) {
      const previousStaffingRoleChanges = staffingRoleChanges[staffingRoleChanges.length - 1];

      const currentStaffingRolesChanges = previousStaffingRoleChanges.map(locationStaffingRoles => {
        if (locationStaffingRoles.location_id === currentLocationId) {
          return {
            ...locationStaffingRoles,
            staffing_roles: locationStaffingRoles.staffing_roles.map(role => {
              if (role.name === currentStaffingRole.name) {
                return currentStaffingRole;
              }

              return role;
            }),
          };
        }

        return locationStaffingRoles;
      });

      updateStaffingRoles(currentStaffingRolesChanges);
      setStaffingRoleChanges(prev => [...prev, currentStaffingRolesChanges]);
    }
  };

  const undoLastChange = () => {
    const previousStaffingRoles = staffingRoleChanges[staffingRoleChanges.length - 1];

    if (previousStaffingRoles == null) {
      return;
    }

    setStaffingRoleChanges(staffingRoleChanges.slice(0, -1));
    updateStaffingRoles(previousStaffingRoles);
  };

  const canUndoLastChange = staffingRoleChanges.length > 1;

  return {
    isLoadingStaffingRoles,
    staffingRoleChanges,
    currentStaffingRole,
    setCurrentStaffingRole,
    saveCurrentStaffingRole,
    undoLastChange,
    canUndoLastChange,
    isLoadingStaffingRolesUpdate,
  };
};

const PERIOD_TO_LABEL = {
  [PERIOD_OPTIONS.NEXT_7_DAYS]: 'Next 7 Days',
  [PERIOD_OPTIONS.NEXT_14_DAYS]: 'Next 14 Days',
  [PERIOD_OPTIONS.NEXT_28_DAYS]: 'Next 28 Days',
  [PERIOD_OPTIONS.THIS_WEEK]: 'This Week',
  [PERIOD_OPTIONS.NEXT_WEEK]: 'Next Week',
};

export const usePeriodOptions = (getHoursOfOperationByDate, selectedLocation) => {
  const { minAndMaxPriceStatsDates } = React.useContext(DashboardContext);

  const getStartAndEndDates = React.useCallback(
    periodValue => {
      const [minDatasetDateString, maxDatasetDateString] = minAndMaxPriceStatsDates;
      const minDatasetDate = parseDatasetDate(minDatasetDateString);
      const maxDatasetDate = parseDatasetDate(maxDatasetDateString);
      const today = startOfToday();

      if (periodValue === PERIOD_OPTIONS.NEXT_7_DAYS) {
        const end = addDays(today, 6);

        return [_.max([today, minDatasetDate]), _.min([end, maxDatasetDate])];
      }

      if (periodValue === PERIOD_OPTIONS.NEXT_14_DAYS) {
        const end = addDays(today, 13);

        return [_.max([today, minDatasetDate]), _.min([end, maxDatasetDate])];
      }

      if (periodValue === PERIOD_OPTIONS.NEXT_28_DAYS) {
        const end = addDays(today, 27);

        return [_.max([today, minDatasetDate]), _.min([end, maxDatasetDate])];
      }

      if (periodValue === PERIOD_OPTIONS.THIS_WEEK) {
        const end = isSunday(today) ? today : nextSunday(today);

        return [_.max([today, minDatasetDate]), _.min([end, maxDatasetDate])];
      }

      if (periodValue === PERIOD_OPTIONS.NEXT_WEEK) {
        const start = nextMonday(today);
        const end = nextSunday(start);

        return [_.max([start, minDatasetDate]), _.min([end, maxDatasetDate])];
      }

      return [null, null];
    },
    [minAndMaxPriceStatsDates],
  );

  const periodOptions = React.useMemo(
    () =>
      Object.values(PERIOD_OPTIONS)
        .filter(p => {
          const [start, end] = getStartAndEndDates(p);

          return isValid(start) && isValid(end) && start <= end;
        })
        .map(option => ({
          label: PERIOD_TO_LABEL[option],
          value: option,
        })),
    [getStartAndEndDates],
  );

  const [selectedPeriod, setSelectedPeriod] = React.useState(periodOptions[0]);
  const startAndEndDates = React.useMemo(() => getStartAndEndDates(selectedPeriod.value), [
    selectedPeriod.value,
    getStartAndEndDates,
  ]);
  const [selectedDate, setSelectedDate] = React.useState(startAndEndDates[0]);

  const dates = React.useMemo(() => {
    const [startDate, endDate] = startAndEndDates;
    if (!isValid(startDate) || !isValid(endDate)) {
      return [];
    }

    const startDateString = formatAsDatasetDate(startDate);
    const endDateString = formatAsDatasetDate(endDate);

    return generateDatesBetween(startDateString, endDateString).map(date => ({
      date,
      isClosed: getHoursOfOperationByDate(date).length === 0,
    }));
  }, [startAndEndDates, getHoursOfOperationByDate]);

  React.useEffect(() => {
    let newDate = selectedDate;
    const [start, end] = startAndEndDates;

    if (newDate < start) {
      newDate = start;
    }
    if (newDate > end) {
      newDate = end;
    }

    const dateStr = formatAsDatasetDate(newDate);
    const isClosed = dates.find(({ date }) => date === dateStr)?.isClosed ?? false;
    if (isClosed) {
      const openDay = dates.find(({ isClosed: closed }) => !closed)?.date;
      if (openDay) {
        newDate = parseDatasetDate(openDay);
      }
    }

    setSelectedDate(newDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAndEndDates, selectedLocation, dates, getHoursOfOperationByDate]);

  return {
    periodOptions,
    selectedPeriod,
    setSelectedPeriod,
    startAndEndDates,
    selectedDate,
    setSelectedDate,
    dates,
  };
};

export const usePeriodDates = period => {
  const { minAndMaxPriceStatsDates } = React.useContext(DashboardContext);

  const getStartAndEndDates = React.useCallback(
    periodValue => {
      const [minDatasetDateString, maxDatasetDateString] = minAndMaxPriceStatsDates;
      const minDatasetDate = parseDatasetDate(minDatasetDateString);
      const maxDatasetDate = parseDatasetDate(maxDatasetDateString);
      const today = startOfToday();

      if (periodValue === PERIOD_OPTIONS.NEXT_7_DAYS) {
        const end = addDays(today, 6);

        return [_.max([today, minDatasetDate]), _.min([end, maxDatasetDate])];
      }

      if (periodValue === PERIOD_OPTIONS.NEXT_14_DAYS) {
        const end = addDays(today, 13);

        return [_.max([today, minDatasetDate]), _.min([end, maxDatasetDate])];
      }

      if (periodValue === PERIOD_OPTIONS.NEXT_28_DAYS) {
        const end = addDays(today, 27);

        return [_.max([today, minDatasetDate]), _.min([end, maxDatasetDate])];
      }

      if (periodValue === PERIOD_OPTIONS.THIS_WEEK) {
        const end = isSunday(today) ? today : nextSunday(today);

        return [_.max([today, minDatasetDate]), _.min([end, maxDatasetDate])];
      }

      if (periodValue === PERIOD_OPTIONS.NEXT_WEEK) {
        const start = nextMonday(today);
        const end = nextSunday(start);

        return [_.max([start, minDatasetDate]), _.min([end, maxDatasetDate])];
      }

      return [null, null];
    },
    [minAndMaxPriceStatsDates],
  );

  const startAndEndDates = React.useMemo(() => getStartAndEndDates(period), [period, getStartAndEndDates]);

  const dates = React.useMemo(() => {
    const [startDate, endDate] = startAndEndDates;
    if (!isValid(startDate) || !isValid(endDate)) {
      return [];
    }

    const startDateString = formatAsDatasetDate(startDate);
    const endDateString = formatAsDatasetDate(endDate);

    return generateDatesBetween(startDateString, endDateString);
  }, [startAndEndDates]);

  return {
    startAndEndDates,
    dates,
  };
};

export const useLocationOptions = (selectionState, locations) => {
  const locationOptions = React.useMemo(() => {
    const locationsFilterState = selectionState[LOCATIONS_DIMENSION_ID];
    const locationsOptions = locations.map(l => ({
      value: l.location_id,
      label: l.location_description,
    }));

    const isUnselected = ({ isSelected }) => !isSelected;
    if (_.every(locationsFilterState, isUnselected)) {
      // If all locations are filtered out, show all of them in the dropdown
      return locationsOptions;
    }

    // Otherwise, only show locations in the dropdown that are not filtered out
    const selectedLocations = locationsOptions.filter(location => {
      const filterStateForLoc = locationsFilterState.find(
        ({ dimensionValueId }) => location.value === dimensionValueId,
      );

      return filterStateForLoc != null && filterStateForLoc.isSelected;
    });

    return _.sortBy(selectedLocations, 'label');
  }, [locations, selectionState]);

  const [selectedLocation, setSelectedLocation] = React.useState(locationOptions[0]);

  React.useEffect(() => {
    if (!locationOptions.some(({ value }) => value === selectedLocation.value)) {
      setSelectedLocation(locationOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationOptions]);

  return { locationOptions, selectedLocation, setSelectedLocation };
};

// Returns an array of all the hours in the day ordered starting from DAY_START_HOUR
const getHoursOrdered = () => {
  const hours = [];
  let currentHour = DAY_START_HOUR; // '04:00'

  for (let i = 0; i < 24; i++) {
    hours.push(currentHour);
    const [hour] = currentHour.split(':');
    const newHour = parseInt(hour, 10) + 1;
    if (newHour >= 24) {
      currentHour = '00:00';
    } else {
      currentHour = `${newHour.toString().padStart(2, '0')}:00`;
    }
  }

  return hours;
};

const useHoursOfOperationByLocationByDay = () => {
  const { shiftHoursPerDayPerLocation } = React.useContext(DashboardContext);

  const getHoursOfOperationByDate = React.useCallback(
    (dateStr, locationId) => {
      const locationHours = shiftHoursPerDayPerLocation[locationId];

      if (locationHours == null) {
        return getHoursOrdered();
      }

      const dayOfWeek = getDayOfWeek(parseDatasetDate(dateStr));
      const dayHours = locationHours[dayOfWeek];

      return dayHours?.map(({ hour }) => hour) ?? [];
    },
    [shiftHoursPerDayPerLocation],
  );

  return { getHoursOfOperationByDate };
};

export const useHoursOfOperationByDay = locationId => {
  const { shiftHoursPerDayPerLocation } = React.useContext(DashboardContext);

  const getHoursOfOperationByDate = React.useCallback(
    dateStr => {
      const locationHours = shiftHoursPerDayPerLocation[locationId];

      if (locationHours == null) {
        return getHoursOrdered();
      }

      const dayOfWeek = getDayOfWeek(parseDatasetDate(dateStr));
      const dayHours = locationHours[dayOfWeek];

      return dayHours?.map(({ hour }) => hour) ?? [];
    },
    [locationId, shiftHoursPerDayPerLocation],
  );

  const hasHoursOfOperationDefined = React.useMemo(() => {
    const locationHours = shiftHoursPerDayPerLocation[locationId];

    return locationHours != null;
  }, [locationId, shiftHoursPerDayPerLocation]);

  return { getHoursOfOperationByDate, hasHoursOfOperationDefined };
};

// For example return '2024-03-06' if the date is '2024-03-06' and the hour is between '04:00' and '23:00'
// or if the date is '2024-03-07' and the hour is between '00:00' and '03:00'
const getDayKey = (
  row,
  startDateString,
  endDateString,
  locationId,
  hourDimId,
  dimensionValues,
  locationHoursPerDay,
) => {
  const { tranDate, locationId: rowLocationId, [getDimColumnKey(hourDimId)]: hour } = row;

  if (rowLocationId !== locationId) {
    return null;
  }

  const hourValue = dimensionValues.find(dimValue => dimValue.dimension_id === hourDimId && dimValue.id === hour)
    ?.value;

  if (locationHoursPerDay == null) {
    // No hours of operation defined, use the default DAY_START_HOUR
    const dayBefore = addDays(parseDatasetDate(tranDate), -1);
    const dayBeforeString = formatAsDatasetDate(dayBefore);

    const dayKey = hourValue < DAY_START_HOUR ? dayBeforeString : tranDate;

    return dayKey >= startDateString && dayKey <= endDateString ? dayKey : null;
  }

  const currentDay = parseDatasetDate(tranDate);
  const dayOfWeek = getDayOfWeek(currentDay);
  const dayHours = locationHoursPerDay[dayOfWeek];

  if (dayHours.find(dayHour => dayHour.hour === hourValue && dayHour.isNextDay === false) != null) {
    return tranDate;
  }

  const dayBefore = addDays(currentDay, -1);
  const dayOfWeekBefore = getDayOfWeek(dayBefore);
  const dayBeforeHours = locationHoursPerDay[dayOfWeekBefore];

  if (dayBeforeHours.find(dayHour => dayHour.hour === hourValue && dayHour.isNextDay === true) != null) {
    return formatAsDatasetDate(dayBefore);
  }

  return null;
};

export const useRowsByDay = (startAndEndDates, locationId) => {
  const { dimensions, dimensionValues } = React.useContext(DomainContext);
  const { weeklyForecastStatsRows, weeklyForecastRows, shiftHoursPerDayPerLocation } = React.useContext(
    DashboardContext,
  );

  const [rowsByDay, rowsByDayAndHour] = React.useMemo(() => {
    const [start, end] = startAndEndDates;
    const startDateString = formatAsDatasetDate(start);
    const endDateString = formatAsDatasetDate(end);
    const hourDimId = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;
    const locationHoursPerDay = shiftHoursPerDayPerLocation[locationId];
    const rows = {};
    const hourRows = {};

    weeklyForecastRows.forEach(row => {
      const dayKey = getDayKey(
        row,
        startDateString,
        endDateString,
        locationId,
        hourDimId,
        dimensionValues,
        locationHoursPerDay,
      );

      const { [getDimColumnKey(hourDimId)]: hour } = row;
      const hourValue = dimensionValues.find(dimValue => dimValue.dimension_id === hourDimId && dimValue.id === hour)
        ?.value;

      if (dayKey != null) {
        if (!rows[dayKey]) {
          rows[dayKey] = [row];
        } else {
          rows[dayKey].push(row);
        }

        if (!hourRows[dayKey]) {
          hourRows[dayKey] = {
            [hourValue]: [row],
          };
        } else if (!hourRows[dayKey][hourValue]) {
          hourRows[dayKey][hourValue] = [row];
        } else {
          hourRows[dayKey][hourValue].push(row);
        }
      }
    });

    return [rows, hourRows];
  }, [startAndEndDates, locationId, weeklyForecastRows, dimensions, dimensionValues, shiftHoursPerDayPerLocation]);

  const gratuityRowsByDay = React.useMemo(() => {
    const [start, end] = startAndEndDates;
    const startDateString = formatAsDatasetDate(start);
    const endDateString = formatAsDatasetDate(end);
    const hourDimId = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;
    const locationHoursPerDay = shiftHoursPerDayPerLocation[locationId];
    const rows = {};

    weeklyForecastStatsRows.forEach(row => {
      const dayKey = getDayKey(
        row,
        startDateString,
        endDateString,
        locationId,
        hourDimId,
        dimensionValues,
        locationHoursPerDay,
      );

      if (dayKey != null && row.prediction >= 0.01) {
        if (!rows[dayKey]) {
          rows[dayKey] = [row];
        } else {
          rows[dayKey].push(row);
        }
      }
    });

    return rows;
  }, [startAndEndDates, locationId, weeklyForecastStatsRows, dimensions, dimensionValues, shiftHoursPerDayPerLocation]);

  return {
    rowsByDay,
    rowsByDayAndHour,
    gratuityRowsByDay,
  };
};

export const useRowsByDayMultipleLocations = () => {
  const { dimensions, dimensionValues } = React.useContext(DomainContext);
  const { weeklyForecastStatsRows, weeklyForecastRows, shiftHoursPerDayPerLocation, locationsApi } = React.useContext(
    DashboardContext,
  );
  const { locationOptions } = locationsApi;
  const { startAndEndDates } = usePeriodDates(PERIOD_OPTIONS.NEXT_28_DAYS);

  const [rowsByLocationByDay, rowsByLocationByDayAndHour] = React.useMemo(() => {
    const [start, end] = startAndEndDates;
    const startDateString = formatAsDatasetDate(start);
    const endDateString = formatAsDatasetDate(end);
    const hourDimId = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;

    const allRows = {};
    const allHourRows = {};

    locationOptions.forEach(location => {
      const locId = location.value;
      const locationHoursPerDay = shiftHoursPerDayPerLocation[locId];

      weeklyForecastRows.forEach(row => {
        const dayKey = getDayKey(
          row,
          startDateString,
          endDateString,
          locId,
          hourDimId,
          dimensionValues,
          locationHoursPerDay,
        );

        const { [getDimColumnKey(hourDimId)]: hour } = row;
        const hourValue = dimensionValues.find(dimValue => dimValue.dimension_id === hourDimId && dimValue.id === hour)
          ?.value;

        if (dayKey != null) {
          if (!allRows[locId]) {
            allRows[locId] = {};
          }
          if (!allRows[locId][dayKey]) {
            allRows[locId][dayKey] = [row];
          } else {
            allRows[locId][dayKey].push(row);
          }

          if (!allHourRows[locId]) {
            allHourRows[locId] = {};
          }
          if (!allHourRows[locId][dayKey]) {
            allHourRows[locId][dayKey] = { [hourValue]: [row] };
          } else if (!allHourRows[locId][dayKey][hourValue]) {
            allHourRows[locId][dayKey][hourValue] = [row];
          } else {
            allHourRows[locId][dayKey][hourValue].push(row);
          }
        }
      });
    });

    return [allRows, allHourRows];
  }, [startAndEndDates, dimensions, locationOptions, shiftHoursPerDayPerLocation, weeklyForecastRows, dimensionValues]);

  const gratuityRowsByLocationByDay = React.useMemo(() => {
    const [start, end] = startAndEndDates;
    const startDateString = formatAsDatasetDate(start);
    const endDateString = formatAsDatasetDate(end);
    const hourDimId = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;

    const allGratuityRows = {};

    locationOptions.forEach(location => {
      const locId = location.value;
      const locationHoursPerDay = shiftHoursPerDayPerLocation[locId];

      weeklyForecastStatsRows.forEach(row => {
        const dayKey = getDayKey(
          row,
          startDateString,
          endDateString,
          locId,
          hourDimId,
          dimensionValues,
          locationHoursPerDay,
        );

        if (dayKey != null && row.prediction >= 0.01) {
          if (!allGratuityRows[locId]) {
            allGratuityRows[locId] = {};
          }
          if (!allGratuityRows[locId][dayKey]) {
            allGratuityRows[locId][dayKey] = [row];
          } else {
            allGratuityRows[locId][dayKey].push(row);
          }
        }
      });
    });

    return allGratuityRows;
  }, [
    startAndEndDates,
    dimensions,
    locationOptions,
    shiftHoursPerDayPerLocation,
    weeklyForecastStatsRows,
    dimensionValues,
  ]);

  return {
    rowsByLocationByDay,
    rowsByLocationByDayAndHour,
    gratuityRowsByLocationByDay,
  };
};

export const useStatistics = (
  rowsByDay,
  gratuityRowsByDay,
  shiftsByDay,
  shiftPlansByDay,
  getHoursOfOperationByDate,
  dates,
) => {
  const { isPriceOptimizationProfit } = React.useContext(DomainContext);
  const { staffingPlannerApi, locationsApi } = React.useContext(DashboardContext);
  const { selectedLocation } = locationsApi;
  const { value: locationId } = selectedLocation;
  const { staffingPlanner = {} } = staffingPlannerApi;

  const dayStatistics = React.useMemo(() => {
    const incomeMetricKey = isPriceOptimizationProfit ? 'currentProfit' : 'currentRevenue';
    const metrics = {};
    const planMetrics = {};

    dates.forEach(({ date }) => {
      const rows = rowsByDay[date] || [];
      const gratuityRows = gratuityRowsByDay[date] || [];
      const dayShifts = shiftsByDay[date]?.shifts ?? [];
      const planShiftsByDay = shiftPlansByDay[date] ?? [];
      const staffingPlannerShifts = staffingPlanner[locationId]?.[date] ?? [];
      // use staffingPlanner saved shifts if available, otherwise use the shifts from the forecast
      const planDayShifts = staffingPlannerShifts.length > 0 ? staffingPlannerShifts : planShiftsByDay;
      const dayHoursOfOperation = getHoursOfOperationByDate(date);

      const dayIncome = _.sumBy(rows, incomeMetricKey);
      const dayTips = _.sumBy(gratuityRows, 'prediction') || 0;
      const employeeHours = dayShifts.reduce(
        (acc, { start, end }) => acc + compareHours(end, start, dayHoursOfOperation) + 1,
        0,
      );
      const planEmployeeHours = planDayShifts.reduce(
        (acc, { start, end }) => acc + compareHours(end, start, dayHoursOfOperation) + 1,
        0,
      );

      metrics[date] = {
        [INCOME_METRIC]: dayIncome,
        [TIPS_METRIC]: dayTips,
        [SHIFTS_METRIC]: dayShifts.length,
        [HOURS_METRIC]: employeeHours,
        [INCOME_PER_EMPLOYEE_HOUR_METRIC]: employeeHours > 0 ? dayIncome / employeeHours : undefined,
        [TIPS_PER_EMPLOYEE_HOUR_METRIC]: employeeHours > 0 ? dayTips / employeeHours : undefined,
      };
      planMetrics[date] = {
        [SHIFTS_METRIC]: planDayShifts.length,
        [HOURS_METRIC]: planEmployeeHours,
        [INCOME_PER_EMPLOYEE_HOUR_METRIC]: planEmployeeHours > 0 ? dayIncome / planEmployeeHours : undefined,
        [TIPS_PER_EMPLOYEE_HOUR_METRIC]: planEmployeeHours > 0 ? dayTips / planEmployeeHours : undefined,
      };
    });

    return { statisticsByDay: metrics, planStatisticsByDay: planMetrics };
  }, [
    isPriceOptimizationProfit,
    dates,
    rowsByDay,
    gratuityRowsByDay,
    shiftsByDay,
    shiftPlansByDay,
    staffingPlanner,
    locationId,
    getHoursOfOperationByDate,
  ]);

  const periodTotalStatistics = React.useMemo(() => {
    const { statisticsByDay, planStatisticsByDay } = dayStatistics;

    const totalIncome = _.sumBy(Object.values(statisticsByDay), INCOME_METRIC);
    const totalTips = _.sumBy(Object.values(statisticsByDay), TIPS_METRIC);
    const totalShifts = _.sumBy(Object.values(statisticsByDay), SHIFTS_METRIC);
    const totalHours = _.sumBy(Object.values(statisticsByDay), HOURS_METRIC);
    const totalIncomePerEmployeeHour = totalHours > 0 ? totalIncome / totalHours : undefined;
    const totalTipsPerEmployeeHour = totalHours > 0 ? totalTips / totalHours : undefined;

    const planTotalShifts = _.sumBy(Object.values(planStatisticsByDay), SHIFTS_METRIC);
    const planTotalHours = _.sumBy(Object.values(planStatisticsByDay), HOURS_METRIC);
    const planTotalIncomePerEmployeeHour = planTotalHours > 0 ? totalIncome / planTotalHours : undefined;
    const planTotalTipsPerEmployeeHour = planTotalHours > 0 ? totalTips / planTotalHours : undefined;

    return {
      periodStatistics: {
        [INCOME_METRIC]: totalIncome,
        [TIPS_METRIC]: totalTips,
        [SHIFTS_METRIC]: totalShifts,
        [HOURS_METRIC]: totalHours,
        [INCOME_PER_EMPLOYEE_HOUR_METRIC]: totalIncomePerEmployeeHour,
        [TIPS_PER_EMPLOYEE_HOUR_METRIC]: totalTipsPerEmployeeHour,
      },
      planPeriodStatistics: {
        [INCOME_METRIC]: totalIncome,
        [TIPS_METRIC]: totalTips,
        [SHIFTS_METRIC]: planTotalShifts,
        [HOURS_METRIC]: planTotalHours,
        [INCOME_PER_EMPLOYEE_HOUR_METRIC]: planTotalIncomePerEmployeeHour,
        [TIPS_PER_EMPLOYEE_HOUR_METRIC]: planTotalTipsPerEmployeeHour,
      },
    };
  }, [dayStatistics]);

  return {
    ...periodTotalStatistics,
    ...dayStatistics,
  };
};

export const useLocationDayStatistics = (rowsByLocationByDay, gratuityRowsByLocationByDay, shiftsByLocationByDay) => {
  const { isPriceOptimizationProfit } = React.useContext(DomainContext);
  const { locationsApi } = React.useContext(DashboardContext);
  const { locationOptions } = locationsApi;

  const { getHoursOfOperationByDate } = useHoursOfOperationByLocationByDay();
  const { dates } = usePeriodDates(PERIOD_OPTIONS.NEXT_28_DAYS);

  const dayStatisticsList = React.useMemo(() => {
    const incomeMetricKey = isPriceOptimizationProfit ? 'currentProfit' : 'currentRevenue';
    const statisticsList = [];

    locationOptions.forEach(location => {
      const locId = location.value;

      dates.forEach(date => {
        const rows = rowsByLocationByDay[locId]?.[date] || [];
        const gratuityRows = gratuityRowsByLocationByDay[locId]?.[date] || [];
        const dayShifts = shiftsByLocationByDay[locId]?.[date]?.shifts || [];
        const dayHoursOfOperation = getHoursOfOperationByDate(date, locId);

        const dayIncome = _.sumBy(rows, incomeMetricKey);
        const dayTips = _.sumBy(gratuityRows, 'prediction') || 0;
        const employeeHours = dayShifts.reduce(
          (acc, { start, end }) => acc + compareHours(end, start, dayHoursOfOperation) + 1,
          0,
        );

        statisticsList.push({
          locationId: locId,
          date,
          isClosed: dayHoursOfOperation.length === 0,
          [INCOME_METRIC]: dayIncome,
          [TIPS_METRIC]: dayTips,
          [SHIFTS_METRIC]: dayShifts.length,
          [HOURS_METRIC]: employeeHours,
          [INCOME_PER_EMPLOYEE_HOUR_METRIC]: employeeHours > 0 ? dayIncome / employeeHours : undefined,
          [TIPS_PER_EMPLOYEE_HOUR_METRIC]: employeeHours > 0 ? dayTips / employeeHours : undefined,
        });
      });
    });

    return statisticsList;
  }, [
    isPriceOptimizationProfit,
    locationOptions,
    dates,
    rowsByLocationByDay,
    gratuityRowsByLocationByDay,
    shiftsByLocationByDay,
    getHoursOfOperationByDate,
  ]);

  return dayStatisticsList;
};

export const useHourlyStatistics = (rows, gratuityRows, dayShifts, hoursRange) => {
  const { isPriceOptimizationProfit, dimensions, dimensionValues } = React.useContext(DomainContext);

  return React.useMemo(() => {
    const hourDimId = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;
    const incomeMetricKey = isPriceOptimizationProfit ? 'currentProfit' : 'currentRevenue';
    const recommendedPlan = {
      [TOTAL_INCOME_METRIC]: {},
      [TOTAL_TIPS_METRIC]: {},
      [TOTAL_INCOME_PER_EMPLOYEE_METRIC]: {},
      [TIPS_PER_EMPLOYEE_METRIC]: {},
      [TOTAL_HEADCOUNT_METRIC]: {},
    };

    rows.forEach(row => {
      const hour = row[getDimColumnKey(hourDimId)];
      const hourValue = dimensionValues.find(dimValue => dimValue.dimension_id === hourDimId && dimValue.id === hour)
        ?.value;

      const previous = recommendedPlan[TOTAL_INCOME_METRIC][hourValue] ?? 0;
      recommendedPlan[TOTAL_INCOME_METRIC][hourValue] = previous + row[incomeMetricKey];
    });

    gratuityRows.forEach(row => {
      const hour = row[getDimColumnKey(hourDimId)];
      const hourValue = dimensionValues.find(dimValue => dimValue.dimension_id === hourDimId && dimValue.id === hour)
        ?.value;

      const previous = recommendedPlan[TOTAL_TIPS_METRIC][hourValue] ?? 0;
      recommendedPlan[TOTAL_TIPS_METRIC][hourValue] = previous + row.prediction;
    });

    hoursRange.forEach(hour => {
      const headcount = dayShifts.filter(
        ({ start, end }) => compareHours(hour, start, hoursRange) >= 0 && compareHours(end, hour, hoursRange) >= 0,
      ).length;

      const hourIncome = recommendedPlan[TOTAL_INCOME_METRIC][hour] ?? 0;
      const hourTips = recommendedPlan[TOTAL_TIPS_METRIC][hour] ?? 0;

      recommendedPlan[TOTAL_HEADCOUNT_METRIC][hour] = headcount;
      recommendedPlan[TOTAL_INCOME_PER_EMPLOYEE_METRIC][hour] = headcount > 0 ? hourIncome / headcount : 0;
      recommendedPlan[TIPS_PER_EMPLOYEE_METRIC][hour] = headcount > 0 ? hourTips / headcount : 0;
    });

    return recommendedPlan;
  }, [rows, gratuityRows, isPriceOptimizationProfit, dimensions, dimensionValues, dayShifts, hoursRange]);
};

export const usePlannerHourlyStatistics = (forecast, dayShifts, hoursRange) => {
  return React.useMemo(() => {
    const plan = {
      [TOTAL_INCOME_PER_EMPLOYEE_METRIC]: {},
      [TIPS_PER_EMPLOYEE_METRIC]: {},
      [TOTAL_HEADCOUNT_METRIC]: {},
    };

    hoursRange.forEach(hour => {
      const headcount = dayShifts.filter(
        ({ start, end }) => compareHours(hour, start, hoursRange) >= 0 && compareHours(end, hour, hoursRange) >= 0,
      ).length;

      const hourIncome = forecast[TOTAL_INCOME_METRIC][hour] ?? 0;
      const hourTips = forecast[TOTAL_TIPS_METRIC][hour] ?? 0;

      plan[TOTAL_HEADCOUNT_METRIC][hour] = headcount;
      plan[TOTAL_INCOME_PER_EMPLOYEE_METRIC][hour] = headcount > 0 ? hourIncome / headcount : 0;
      plan[TIPS_PER_EMPLOYEE_METRIC][hour] = headcount > 0 ? hourTips / headcount : 0;
    });

    return plan;
  }, [forecast, dayShifts, hoursRange]);
};

// Returns a slice of hoursOrdered that includes all the hours between the first and last hours
// that have forecasted revenue or gratuity for the given day
const getDayHoursRange = (rowsByHour, dayGratuityRows, hourDimId, dimensionValues, hoursOrdered) => {
  const gratuityHours = dayGratuityRows.map(row => {
    const hour = row[getDimColumnKey(hourDimId)];
    const hourValue = dimensionValues.find(dimValue => dimValue.dimension_id === hourDimId && dimValue.id === hour);
    return hourValue?.value;
  });

  const minRevHour = _.minBy(Object.keys(rowsByHour), hour => hoursOrdered.indexOf(hour));
  const minGratuityHour = _.minBy(gratuityHours, hour => hoursOrdered.indexOf(hour));
  const minHours = [];
  if (minRevHour != null) {
    minHours.push(minRevHour);
  }
  if (minGratuityHour != null) {
    minHours.push(minGratuityHour);
  }
  const minHour = _.minBy(minHours, hour => hoursOrdered.indexOf(hour)) ?? hoursOrdered[0];

  const maxRevHour = _.maxBy(Object.keys(rowsByHour), hour => hoursOrdered.indexOf(hour));
  const maxGratuityHour = _.maxBy(gratuityHours, hour => hoursOrdered.indexOf(hour));
  const maxHours = [];
  if (maxRevHour != null) {
    maxHours.push(maxRevHour);
  }
  if (maxGratuityHour != null) {
    maxHours.push(maxGratuityHour);
  }
  const maxHour = _.maxBy(maxHours, hour => hoursOrdered.indexOf(hour)) ?? hoursOrdered[hoursOrdered.length - 1];

  return hoursOrdered.slice(hoursOrdered.indexOf(minHour), hoursOrdered.indexOf(maxHour) + 1);
};

// A day's hour range can be incomplete, e.g. ['09:00', '10:00', '11:00'],
// in which case it returns ['09:00', '10:00', '11:00', '12:00', ... '08:00']
export const getAllDayHoursFromHourRange = hoursRange => {
  const allHours = getHoursOrdered();

  const startHourIndex = allHours.indexOf(hoursRange[0]);
  const endHourIndex = allHours.indexOf(hoursRange[hoursRange.length - 1]);

  return [...hoursRange, ...allHours.slice(endHourIndex + 1), ...allHours.slice(0, startHourIndex)];
};

// e.g. if the shift ends at '18:00' then the last shift hour is '17:00'
export const getLastShiftHour = (shiftEndHour, hoursRange) => {
  const allHours = getAllDayHoursFromHourRange(hoursRange);
  return allHours[allHours.indexOf(shiftEndHour) - 1] ?? allHours[allHours.length - 1];
};

// e.g. if the last working hour of the shift is '17:00' then '18:00' is the hour the shift ends
export const getShiftEndTime = (lastWorkingHour, hoursRange) => {
  const allHours = getAllDayHoursFromHourRange(hoursRange);
  return allHours[allHours.indexOf(lastWorkingHour) + 1] ?? allHours[0];
};

export const getShiftDuration = (start, end, hoursRange) => compareHours(end, start, hoursRange) + 1;

const getShiftStartHour = (end, shiftLength, hoursRange) => addHours(end, 1 - shiftLength, hoursRange);

const getShiftEndHour = (start, shiftLength, hoursRange) => addHours(start, shiftLength - 1, hoursRange);

// Returns the difference between the minimun needed headcount and the current headcount
// based on the forecasted revenue for the given hour, the minimum headcount and maximum
// revenue per employee per hour.
// Is > 0 if the current headcount is less than the minimum needed headcount
// and < 0 if the current headcount is greater than the minimum needed headcount.
const calculateEmployeeGapForHour = (
  hour,
  hoursRange,
  rows,
  shifts,
  maxRevenuePerEmployeePerHour,
  minDayHeadcount,
  minClosingHeadcount,
  incomeMetricKey,
) => {
  const endHour = hoursRange[hoursRange.length - 1];
  const minHeadcount = hour === endHour ? minClosingHeadcount : minDayHeadcount;
  const hourRevenue = _.sumBy(rows, incomeMetricKey) ?? 0;
  const hourHeadcount = shifts.filter(
    ({ start, end }) => compareHours(hour, start, hoursRange) >= 0 && compareHours(end, hour, hoursRange) >= 0,
  ).length;

  return Math.max(Math.ceil(hourRevenue / maxRevenuePerEmployeePerHour), minHeadcount) - hourHeadcount;
};

const calculateEmployeeGaps = (
  hoursRange,
  rowsByHour,
  shifts,
  maxRevenuePerEmployeePerHour,
  minDayHeadcount,
  minClosingHeadcount,
  incomeMetricKey,
) => {
  return hoursRange.map(hour =>
    calculateEmployeeGapForHour(
      hour,
      hoursRange,
      rowsByHour[hour] ?? [],
      shifts,
      maxRevenuePerEmployeePerHour,
      minDayHeadcount,
      minClosingHeadcount,
      incomeMetricKey,
    ),
  );
};

const consolidateShifts = (shifts, hoursRange, maxShiftLength) => {
  // Look for opportunities to consolidate shifts
  for (let firstShiftIndex = 0; firstShiftIndex < shifts.length; firstShiftIndex++) {
    for (let secondShiftIndex = 0; secondShiftIndex < shifts.length; secondShiftIndex++) {
      const { start: firstShiftStart, end: firstShiftEnd } = shifts[firstShiftIndex];
      const { start: secondShiftStart, end: secondShiftEnd } = shifts[secondShiftIndex];

      // If first shift ends when the second starts
      if (firstShiftIndex !== secondShiftIndex && compareHours(secondShiftStart, firstShiftEnd, hoursRange) === 1) {
        // If both combined are less than the max shift length; combine them
        if (getShiftDuration(firstShiftStart, secondShiftEnd, hoursRange) <= maxShiftLength) {
          shifts[firstShiftIndex].end = secondShiftEnd;
          shifts.splice(secondShiftIndex, 1);

          return consolidateShifts(shifts, hoursRange, maxShiftLength);
        }

        // Else if the two are longer than max shift; check for some other conditions
        for (let thirdShiftIndex = 0; thirdShiftIndex < shifts.length; thirdShiftIndex++) {
          const { start: thirdShiftStart, end: thirdShiftEnd } = shifts[thirdShiftIndex];

          // If third shift ends when first shift starts and can be extended so the first shift can be shortened
          // and first+second is less than max; then extend third shift, shorten first and combine with second
          if (
            thirdShiftIndex !== firstShiftIndex &&
            thirdShiftIndex !== secondShiftIndex &&
            compareHours(firstShiftStart, thirdShiftEnd, hoursRange) === 1 &&
            getShiftDuration(thirdShiftStart, secondShiftEnd, hoursRange) <= 2 * maxShiftLength
          ) {
            shifts[thirdShiftIndex].end = addHours(thirdShiftStart, maxShiftLength - 1, hoursRange);
            shifts[firstShiftIndex].start = addHours(thirdShiftStart, maxShiftLength, hoursRange);
            shifts[firstShiftIndex].end = secondShiftEnd;
            shifts.splice(secondShiftIndex, 1);

            return consolidateShifts(shifts, hoursRange, maxShiftLength);
          }

          // If third shift is during first shift and the start of third shift to end of second shift
          // is <= maxshift length; combine second & third and shorten first
          if (
            thirdShiftIndex !== firstShiftIndex &&
            thirdShiftIndex !== secondShiftIndex &&
            getShiftDuration(thirdShiftStart, secondShiftEnd, hoursRange) <= maxShiftLength &&
            compareHours(secondShiftStart, thirdShiftEnd, hoursRange) >= 1
          ) {
            shifts[firstShiftIndex].end = thirdShiftEnd;
            shifts[thirdShiftIndex].end = secondShiftEnd;
            shifts.splice(secondShiftIndex, 1);

            return consolidateShifts(shifts, hoursRange, maxShiftLength);
          }

          // If third shift is during second shift and the start of first shift to end of third shift
          // is <= maxshift length; combine first & third and shorten second
          if (
            thirdShiftIndex !== firstShiftIndex &&
            thirdShiftIndex !== secondShiftIndex &&
            getShiftDuration(firstShiftStart, thirdShiftEnd, hoursRange) <= maxShiftLength &&
            compareHours(thirdShiftStart, firstShiftEnd, hoursRange) >= 1
          ) {
            shifts[secondShiftIndex].start = thirdShiftStart;
            shifts[firstShiftIndex].end = thirdShiftEnd;
            shifts.splice(thirdShiftIndex, 1);

            return consolidateShifts(shifts, hoursRange, maxShiftLength);
          }
        }

        // Else if first shift starts when second shift ends
      } else if (
        firstShiftIndex !== secondShiftIndex &&
        compareHours(firstShiftStart, secondShiftEnd, hoursRange) === 1
      ) {
        // If both combined are less than the max shift length, combine them
        if (getShiftDuration(secondShiftStart, firstShiftEnd, hoursRange) <= maxShiftLength) {
          shifts[firstShiftIndex].start = secondShiftStart;
          shifts.splice(secondShiftIndex, 1);

          return consolidateShifts(shifts, hoursRange, maxShiftLength);
        }

        // Else if the two are longer than max shift BUT there's a third shift ending when second shift
        // starts that can be extended so second shift can be shortened and first+second is less than max;
        // extend third shift, shorten second shift and combine with first shift
        for (let thirdShiftIndex = 0; thirdShiftIndex < shifts.length; thirdShiftIndex++) {
          const { start: thirdShiftStart, end: thirdShiftEnd } = shifts[thirdShiftIndex];

          // If third shift ends when second shift starts that can be extended so second shift can be shortened and
          // first+second is less than max; then extend third shift, shorten second shift and combine with first shift
          if (
            thirdShiftIndex !== firstShiftIndex &&
            thirdShiftIndex !== secondShiftIndex &&
            compareHours(secondShiftStart, thirdShiftEnd, hoursRange) === 1 &&
            getShiftDuration(thirdShiftStart, firstShiftEnd, hoursRange) <= 2 * maxShiftLength
          ) {
            shifts[thirdShiftIndex].end = addHours(thirdShiftStart, maxShiftLength - 1, hoursRange);
            shifts[secondShiftIndex].start = addHours(thirdShiftStart, maxShiftLength, hoursRange);
            shifts[secondShiftIndex].end = firstShiftEnd;
            shifts.splice(firstShiftIndex, 1);

            return consolidateShifts(shifts, hoursRange, maxShiftLength);
          }

          // If third shift is during second shift and the start of third shift to end of first shift is
          // <= maxshift length; combine first & third and shorten second shift
          if (
            thirdShiftIndex !== firstShiftIndex &&
            thirdShiftIndex !== secondShiftIndex &&
            getShiftDuration(thirdShiftStart, firstShiftEnd, hoursRange) <= maxShiftLength &&
            compareHours(firstShiftStart, thirdShiftEnd, hoursRange) >= 1
          ) {
            shifts[secondShiftIndex].end = thirdShiftEnd;
            shifts[thirdShiftIndex].end = firstShiftEnd;
            shifts.splice(firstShiftIndex, 1);

            return consolidateShifts(shifts, hoursRange, maxShiftLength);
          }

          // If third shift is during first shift and the start of second shift to end of third shift
          // is <= maxshift length; combine second & third and shorten first shift
          if (
            thirdShiftIndex !== firstShiftIndex &&
            thirdShiftIndex !== secondShiftIndex &&
            getShiftDuration(secondShiftStart, thirdShiftEnd, hoursRange) <= maxShiftLength &&
            compareHours(thirdShiftStart, secondShiftEnd, hoursRange) >= 1
          ) {
            shifts[firstShiftIndex].start = thirdShiftStart;
            shifts[secondShiftIndex].end = thirdShiftEnd;
            shifts.splice(thirdShiftIndex, 1);

            return consolidateShifts(shifts, hoursRange, maxShiftLength);
          }
        }
      }
    }
  }

  return shifts;
};

const MAX_SHIFT_GENERATION_ITERATIONS = 1000;
const INITIAL_ADDITIONAL_HOURS = 100; // A number large enough to be replaced by the first shift found

const getShiftsForDay = (hoursRange, currentStaffingRole, rowsByHour, incomeMetricKey) => {
  const {
    [STAFFING_ROLE_KEYS.MIN_HOURLY_STAFF_DAY]: minDayHeadcount,
    [STAFFING_ROLE_KEYS.MIN_HOURLY_STAFF_CLOSE]: minClosingHeadcount,
    [STAFFING_ROLE_KEYS.MIN_SHIFT_LENGTH]: minShiftLength,
    [STAFFING_ROLE_KEYS.MAX_SHIFT_LENGTH]: maxShiftLength,
    [STAFFING_ROLE_KEYS.MAX_HOURLY_REVENUE_PER_EMPLOYEE]: maxRevenuePerEmployee,
  } = currentStaffingRole;

  const endHour = hoursRange[hoursRange.length - 1]; // last work hour, e.g. '17:00' if store closes at '18:00'
  let shifts = [];

  let empGaps = calculateEmployeeGaps(
    hoursRange,
    rowsByHour,
    shifts,
    maxRevenuePerEmployee,
    minDayHeadcount,
    minClosingHeadcount,
    incomeMetricKey,
  );
  let maxEmpGap = _.max(empGaps);

  let additionalHours; // The number or hours to be added to a shift to include the current hour
  let bestShift = null; // A shift that can include the current hour and still satisfy the shift length constraints
  let beforeCurrentHour = false; // Whether the best shift is before or after the current hour
  let n = 0; // Prevent endless loop

  // Satisfy max revenue per employee per hour and min headcount constraints
  // by adding and adjusting shifts until the employee gap of every hour is <= 0
  while (maxEmpGap > 0 && n < MAX_SHIFT_GENERATION_ITERATIONS) {
    const currentHourIndex = empGaps.indexOf(maxEmpGap);
    const currentHour = hoursRange[currentHourIndex]; // the hour with the largest employee gap

    if (maxEmpGap > 1) {
      additionalHours = INITIAL_ADDITIONAL_HOURS;
      bestShift = null;
      beforeCurrentHour = false;

      // Look through all shifts for opportunities to extend or shorten a shift
      // to include the current hour
      for (let currentShiftIndex = 0; currentShiftIndex < shifts.length; currentShiftIndex++) {
        const { start, end } = shifts[currentShiftIndex];

        // If the current hour is after the end of the shift and the shift can be
        // extended to include the current hour and still satisfy the max shift length
        if (
          compareHours(currentHour, end, hoursRange) > 0 &&
          getShiftDuration(start, currentHour, hoursRange) <= maxShiftLength
        ) {
          const hoursToShiftEnd = currentHourIndex - hoursRange.indexOf(end);
          if (hoursToShiftEnd < additionalHours && hoursToShiftEnd <= minShiftLength) {
            additionalHours = hoursToShiftEnd;
            bestShift = currentShiftIndex;
            beforeCurrentHour = true;
          }

          // If the current hour is before the start of the shift and the shift can start
          // at the current hour and still satisfy the max shift length
        } else if (
          compareHours(start, currentHour, hoursRange) > 0 &&
          getShiftDuration(currentHour, end, hoursRange) <= maxShiftLength
        ) {
          const hoursToShiftStart = hoursRange.indexOf(start) - currentHourIndex;
          if (hoursToShiftStart < additionalHours && hoursToShiftStart <= minShiftLength) {
            additionalHours = hoursToShiftStart;
            bestShift = currentShiftIndex;
            beforeCurrentHour = false;
          }
        }
      }

      const newShiftStartHour = getShiftStartHour(endHour, minShiftLength, hoursRange);

      // If a shift was found that can be extended to include the current hour
      if (bestShift !== null && beforeCurrentHour) {
        shifts[bestShift].end = currentHour;

        // If a shift was found that can start at the current hour
      } else if (bestShift !== null) {
        shifts[bestShift].start = currentHour;

        // No shift was found that can be extended or shortened to include the current hour,
        // create a new shift with the minimum shift length that includes the current hour
      } else if (compareHours(currentHour, newShiftStartHour, hoursRange) > 0) {
        shifts.push({ start: newShiftStartHour, end: endHour });
      } else {
        shifts.push({ start: currentHour, end: getShiftEndHour(currentHour, minShiftLength, hoursRange) });
      }

      // Only one more employee needed for the current hour
    } else {
      bestShift = null;
      beforeCurrentHour = false;
      let aggAdditionalHours = 0;
      // Temp variables to modify shifts temporarily and restore them after
      let tempBestShift = null;
      let tempBeforeCurrentHour = false;
      const tempShifts = [...shifts];

      // Look for hours whithin the current hour and the next minShiftLength hours
      // that also need one more employee
      const lastHourIndex = Math.min(currentHourIndex + minShiftLength, hoursRange.length);
      for (
        let understaffedHourIndex = currentHourIndex;
        understaffedHourIndex < lastHourIndex;
        understaffedHourIndex++
      ) {
        const understaffedHour = hoursRange[understaffedHourIndex];

        if (empGaps[understaffedHourIndex] > 0) {
          additionalHours = INITIAL_ADDITIONAL_HOURS;
          tempBestShift = null;
          tempBeforeCurrentHour = false;

          // Look through all shifts for opportunities to extend or shorten a shift
          // to include the understaffed hour
          for (let currentShiftIndex = 0; currentShiftIndex < shifts.length; currentShiftIndex++) {
            const { start, end } = shifts[currentShiftIndex];

            // If the current hour is after the end of the shift and the shift can be
            // extended to include the understaffed hour and still satisfy the max shift length
            if (
              compareHours(understaffedHour, end, hoursRange) > 0 &&
              getShiftDuration(start, understaffedHour, hoursRange) <= maxShiftLength
            ) {
              const hoursToShiftEnd = understaffedHourIndex - hoursRange.indexOf(end);

              if (hoursToShiftEnd < additionalHours && hoursToShiftEnd <= minShiftLength) {
                additionalHours = hoursToShiftEnd;
                tempBestShift = currentShiftIndex;
                tempBeforeCurrentHour = true;
                if (understaffedHourIndex === currentHourIndex) {
                  bestShift = currentShiftIndex;
                  beforeCurrentHour = true;
                }
              }

              // If the understaffed hour is before the start of the shift and the shift can start
              // at the understaffed hour and still satisfy the max shift length
            } else if (
              compareHours(start, understaffedHour, hoursRange) > 0 &&
              getShiftDuration(understaffedHour, end, hoursRange) <= maxShiftLength
            ) {
              const hoursToShiftStart = hoursRange.indexOf(start) - understaffedHourIndex;

              if (hoursToShiftStart < additionalHours && hoursToShiftStart <= minShiftLength) {
                additionalHours = hoursToShiftStart;
                tempBestShift = currentShiftIndex;
                tempBeforeCurrentHour = false;
                if (understaffedHourIndex === currentHourIndex) {
                  bestShift = currentShiftIndex;
                  beforeCurrentHour = false;
                }
              }
            }
          }

          if (additionalHours !== INITIAL_ADDITIONAL_HOURS) {
            aggAdditionalHours += additionalHours;
          } else {
            aggAdditionalHours += minShiftLength;
          }

          const newShiftStartHour = getShiftStartHour(endHour, minShiftLength, hoursRange);

          // If a shift was found that can be extended to include the understaffed hour,
          // temporarily extend the shift
          if (tempBestShift !== null && tempBeforeCurrentHour && aggAdditionalHours <= minShiftLength) {
            shifts[tempBestShift].end = understaffedHour;

            // If a shift was found that can start at the understaffed hour,
            // temporarily shorten the shift
          } else if (tempBestShift !== null && aggAdditionalHours <= minShiftLength) {
            shifts[tempBestShift].start = understaffedHour;

            // No shift was found that can be extended or shortened to include the understaffed hour,
            // create a new temporarily shift with the minimum shift length that includes the understaffed hour
          } else if (compareHours(understaffedHour, newShiftStartHour, hoursRange) > 0) {
            shifts.push({ start: newShiftStartHour, end: endHour });
          } else {
            shifts.push({ start: understaffedHour, end: addHours(understaffedHour, minShiftLength - 1, hoursRange) });
          }

          empGaps = calculateEmployeeGaps(
            hoursRange,
            rowsByHour,
            shifts,
            maxRevenuePerEmployee,
            minDayHeadcount,
            minClosingHeadcount,
            incomeMetricKey,
          );
          maxEmpGap = _.max(empGaps);
        }

        if (maxEmpGap !== 0) {
          understaffedHourIndex = lastHourIndex;
        }
      }

      // Restore shifts
      shifts = [...tempShifts];

      const newShiftStartHour = getShiftStartHour(endHour, minShiftLength, hoursRange);

      // If a shift was found that can be extended to include the current hour
      if (bestShift !== null && beforeCurrentHour && aggAdditionalHours <= minShiftLength) {
        shifts[bestShift].end = currentHour;

        // If a shift was found that can start at the current hour
      } else if (bestShift !== null && aggAdditionalHours <= minShiftLength) {
        shifts[bestShift].start = currentHour;

        // No shift was found that can be extended or shortened to include the current hour,
        // create a new shift with the minimum shift length that includes the current hour
      } else if (compareHours(currentHour, newShiftStartHour, hoursRange) > 0) {
        shifts.push({ start: newShiftStartHour, end: endHour });
      } else {
        shifts.push({ start: currentHour, end: getShiftEndHour(currentHour, minShiftLength, hoursRange) });
      }
    }

    empGaps = calculateEmployeeGaps(
      hoursRange,
      rowsByHour,
      shifts,
      maxRevenuePerEmployee,
      minDayHeadcount,
      minClosingHeadcount,
      incomeMetricKey,
    );
    maxEmpGap = _.max(empGaps);
    n += 1;
  }

  // Try to trim unnecessary overstaffings
  for (let currentHourIndex = 0; currentHourIndex < hoursRange.length; currentHourIndex++) {
    const currentHour = hoursRange[currentHourIndex];

    // Find overstaffed hours
    if (empGaps[currentHourIndex] < 0) {
      for (let currentShiftIndex = 0; currentShiftIndex < shifts.length; currentShiftIndex++) {
        const { start, end } = shifts[currentShiftIndex];
        let changedCurrentShift = false;

        // For an overstaffed hour, if there's a shift starting that hour that could
        // start an hour later and still meet min shift length threshold, trim it
        if (
          compareHours(start, currentHour, hoursRange) === 0 &&
          getShiftDuration(start, end, hoursRange) > minShiftLength
        ) {
          shifts[currentShiftIndex].start = addHours(start, 1, hoursRange);
          changedCurrentShift = true;

          // For an overstaffed hour, if there's a shift ending that hour that can
          // be shortened by an hour and still meet min shift length threshold, trim it
        } else if (
          compareHours(end, currentHour, hoursRange) === 0 &&
          getShiftDuration(start, end, hoursRange) > minShiftLength
        ) {
          shifts[currentShiftIndex].end = addHours(end, -1, hoursRange);
          changedCurrentShift = true;

          // Else if current shift overlaps with the overstaffed hour:
        } else if (
          compareHours(currentHour, start, hoursRange) >= 0 &&
          compareHours(end, currentHour, hoursRange) >= 0
        ) {
          // If all the hours after the overstaffed hour during a given shift are overstaffed, trim the end hour
          if (
            _.max(empGaps.slice(currentHourIndex, hoursRange.indexOf(end) + 1)) < 0 &&
            getShiftDuration(start, currentHour, hoursRange) > minShiftLength
          ) {
            shifts[currentShiftIndex].end = addHours(currentHour, -1, hoursRange);
            changedCurrentShift = true;

            // Else if all the hours before the overstaffed hour during a given shift are overstaffed,
            // trim the start hour
          } else if (
            _.max(empGaps.slice(currentHourIndex, hoursRange.indexOf(start) + 1)) < 0 &&
            getShiftDuration(currentHour, end, hoursRange) > minShiftLength
          ) {
            shifts[currentShiftIndex].start = addHours(currentHour, 1, hoursRange);
            changedCurrentShift = true;

            // Else look for opportunties to manipulate start/end times to cut back on overstaffing (e.g., shorten
            // shift A by 3 hours and extend shift B by 2 hours and still meet staffing needs)
          } else {
            // Look through all shifts
            for (let secondShiftIndex = 0; secondShiftIndex < shifts.length; secondShiftIndex++) {
              const { start: firstShiftStart, end: firstShiftEnd } = shifts[currentShiftIndex];
              const { start: secondShiftStart, end: secondShiftEnd } = shifts[secondShiftIndex];
              let changedSecondShift = false;

              // If second shift also overlaps the overstaffed hour
              if (
                secondShiftIndex !== currentShiftIndex &&
                compareHours(currentHour, secondShiftStart, hoursRange) >= 0 &&
                compareHours(secondShiftEnd, currentHour, hoursRange) >= 0
              ) {
                // If first shift starts before the second shift and shortening first shift to start after the
                // overstaffed hour would still satisfy min shift length; move second shift to start with first shift,
                // and then move first shift to start after the overstaffed hour
                if (
                  compareHours(secondShiftStart, firstShiftStart, hoursRange) > 0 &&
                  compareHours(currentHour, secondShiftStart, hoursRange) >= 0 &&
                  getShiftDuration(currentHour, firstShiftEnd, hoursRange) > minShiftLength &&
                  getShiftDuration(firstShiftStart, secondShiftEnd, hoursRange) <= maxShiftLength &&
                  _.max(empGaps.slice(currentHourIndex, hoursRange.indexOf(secondShiftStart) + 1)) < 0
                ) {
                  shifts[secondShiftIndex].start = firstShiftStart;
                  shifts[currentShiftIndex].start = addHours(currentHour, 1, hoursRange);
                  changedSecondShift = true;
                } else if (
                  compareHours(firstShiftEnd, secondShiftEnd, hoursRange) > 0 &&
                  compareHours(secondShiftEnd, currentHour, hoursRange) >= 0 &&
                  getShiftDuration(firstShiftStart, currentHour, hoursRange) > minShiftLength &&
                  getShiftDuration(secondShiftStart, firstShiftEnd, hoursRange) <= maxShiftLength &&
                  _.max(empGaps.slice(currentHourIndex, hoursRange.indexOf(secondShiftEnd) + 1)) < 0
                ) {
                  shifts[secondShiftIndex].end = firstShiftEnd;
                  shifts[currentShiftIndex].end = addHours(currentHour, -1, hoursRange);
                  changedSecondShift = true;
                }

                // Else if the overstaffed hour is the final hour of first shift, second shift ends at the beginning
                // of first shift, and second shift could be extended to cover up until the overstaffed hour, extend
                // second shift and delete first shift
              } else if (
                secondShiftIndex !== currentShiftIndex &&
                compareHours(currentHour, firstShiftEnd, hoursRange) === 0 &&
                compareHours(firstShiftStart, secondShiftEnd, hoursRange) === 1 &&
                getShiftDuration(secondShiftStart, currentHour, hoursRange) - 1 <= maxShiftLength
              ) {
                shifts[secondShiftIndex].end = addHours(currentHour, -1, hoursRange);
                shifts.splice(currentShiftIndex, 1);
                currentShiftIndex -= 1;
                changedSecondShift = true;

                // Else if the overstaffed hour is the first hour of first shift, second shift starts at the end of
                // first shift, and second shift could be extended to cover up until the overstaffed hour, extend
                // second shift and delete shift first shift
              } else if (
                secondShiftIndex !== currentShiftIndex &&
                compareHours(currentHour, firstShiftStart, hoursRange) === 0 &&
                compareHours(secondShiftStart, firstShiftEnd, hoursRange) === 1 &&
                getShiftDuration(currentHour, firstShiftEnd, hoursRange) - 1 <= maxShiftLength
              ) {
                shifts[secondShiftIndex].start = addHours(currentHour, 1, hoursRange);
                shifts.splice(currentShiftIndex, 1);
                currentShiftIndex -= 1;
                changedSecondShift = true;
              }

              if (changedSecondShift) {
                empGaps = calculateEmployeeGaps(
                  hoursRange,
                  rowsByHour,
                  shifts,
                  maxRevenuePerEmployee,
                  minDayHeadcount,
                  minClosingHeadcount,
                  incomeMetricKey,
                );
                maxEmpGap = _.max(empGaps);
              }

              if (empGaps[currentHourIndex] >= 0) {
                secondShiftIndex = shifts.length;
              }
            }
          }
        }

        if (changedCurrentShift) {
          empGaps = calculateEmployeeGaps(
            hoursRange,
            rowsByHour,
            shifts,
            maxRevenuePerEmployee,
            minDayHeadcount,
            minClosingHeadcount,
            incomeMetricKey,
          );
          maxEmpGap = _.max(empGaps);
        }

        if (empGaps[currentHourIndex] >= 0) {
          currentShiftIndex = shifts.length;
        }
      }
    }
  }

  shifts = consolidateShifts(shifts, hoursRange, maxShiftLength);

  shifts.sort((a, b) =>
    compareHours(a.start, b.start, hoursRange) === 0
      ? compareHours(a.end, b.end, hoursRange)
      : compareHours(a.start, b.start, hoursRange),
  );

  return shifts.map((s, i) => ({
    ...s,
    shift: i + 1,
  }));
};

export const useShifts = (
  rowsByDayAndHour,
  gratuityRowsByDay,
  getHoursOfOperationByDate,
  hasHoursOfOperationDefined,
) => {
  const { isPriceOptimizationProfit, dimensions, dimensionValues } = React.useContext(DomainContext);
  const { staffingRolesApi } = React.useContext(DashboardContext);
  const { currentStaffingRole } = staffingRolesApi;

  return React.useMemo(() => {
    const incomeMetricKey = isPriceOptimizationProfit ? 'currentProfit' : 'currentRevenue';
    const hourDimId = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;

    const shiftsByDay = {};

    Object.entries(rowsByDayAndHour).forEach(([date, rowsByHour]) => {
      const dayGratuityRows = gratuityRowsByDay[date] ?? [];

      let hoursRange;
      if (hasHoursOfOperationDefined) {
        hoursRange = getHoursOfOperationByDate(date);
      } else {
        const hoursOrdered = getHoursOrdered();
        hoursRange = getDayHoursRange(rowsByHour, dayGratuityRows, hourDimId, dimensionValues, hoursOrdered);
      }

      const shifts = getShiftsForDay(hoursRange, currentStaffingRole, rowsByHour, incomeMetricKey);

      shiftsByDay[date] = {
        shifts,
        hoursRange,
      };
    });

    return shiftsByDay;
  }, [
    currentStaffingRole,
    rowsByDayAndHour,
    gratuityRowsByDay,
    isPriceOptimizationProfit,
    dimensions,
    dimensionValues,
    getHoursOfOperationByDate,
    hasHoursOfOperationDefined,
  ]);
};

export const useShiftsByLocation = (rowsByLocationByDayAndHour, gratuityRowsByLocationByDay) => {
  const { isPriceOptimizationProfit, dimensions, dimensionValues } = React.useContext(DomainContext);
  const { staffingRolesApi, shiftHoursPerDayPerLocation } = React.useContext(DashboardContext);
  const { staffingRoleChanges } = staffingRolesApi;
  const { getHoursOfOperationByDate } = useHoursOfOperationByLocationByDay();

  return React.useMemo(() => {
    const incomeMetricKey = isPriceOptimizationProfit ? 'currentProfit' : 'currentRevenue';
    const hourDimId = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;

    const shiftsByLocationByDay = {};

    Object.entries(rowsByLocationByDayAndHour).forEach(([locationId, rowsByDayAndHour]) => {
      const locId = Number(locationId);
      shiftsByLocationByDay[locId] = {};

      Object.entries(rowsByDayAndHour).forEach(([date, rowsByHour]) => {
        const dayGratuityRows = gratuityRowsByLocationByDay[locId]?.[date] ?? [];

        let hoursRange;
        const hasHoursDefined = shiftHoursPerDayPerLocation[locId] != null;
        if (hasHoursDefined) {
          hoursRange = getHoursOfOperationByDate(date, locId);
        } else {
          const hoursOrdered = getHoursOrdered();
          hoursRange = getDayHoursRange(rowsByHour, dayGratuityRows, hourDimId, dimensionValues, hoursOrdered);
        }

        const currentLocationRoles =
          staffingRoleChanges.flat().find(({ location_id: currentId }) => currentId === locId)?.staffing_roles ?? [];
        const currentStaffingRole =
          currentLocationRoles.find(({ name }) => name === 'Default') ?? DEFAULT_STAFFING_ROLE;

        const shifts = getShiftsForDay(hoursRange, currentStaffingRole, rowsByHour, incomeMetricKey);

        shiftsByLocationByDay[locId][date] = {
          shifts,
          hoursRange,
        };
      });
    });

    return shiftsByLocationByDay;
  }, [
    isPriceOptimizationProfit,
    dimensions,
    rowsByLocationByDayAndHour,
    gratuityRowsByLocationByDay,
    shiftHoursPerDayPerLocation,
    staffingRoleChanges,
    getHoursOfOperationByDate,
    dimensionValues,
  ]);
};

export const useShiftPlans = (selectedDate, shiftsByDay, dayShifts, hoursRange, hourlyStatistics) => {
  const { staffingRolesApi, staffingPlannerApi, locationsApi } = React.useContext(DashboardContext);
  const { currentStaffingRole } = staffingRolesApi;
  const { selectedLocation } = locationsApi;
  const { value: locationId } = selectedLocation;
  const { staffingPlanner, addShift, addShifts, editShift, deleteShift, deleteDay } = staffingPlannerApi;

  const [shiftPlansByDay, setShiftPlansByDay] = React.useState(_.mapValues(shiftsByDay, 'shifts') ?? {});
  const editsByDay = React.useRef({});

  React.useEffect(() => {
    setShiftPlansByDay(
      prev =>
        _.mapValues(shiftsByDay, (value, key) =>
          editsByDay.current[key] ? prev[key] ?? value.shifts : value.shifts,
        ) ?? {},
    );
  }, [shiftsByDay]);

  const staffingPlannerShifts = React.useMemo(
    () => staffingPlanner?.[locationId]?.[formatAsDatasetDate(selectedDate)] ?? [],
    [staffingPlanner, locationId, selectedDate],
  );
  const staffingPlannerDefined = React.useMemo(
    () => staffingPlanner?.[locationId]?.[formatAsDatasetDate(selectedDate)] != null,
    [staffingPlanner, locationId, selectedDate],
  );
  const dayShiftPlans = React.useMemo(() => {
    const shiftPlans = shiftPlansByDay[formatAsDatasetDate(selectedDate)] ?? [];
    // If there are saved shifts in the staffing planner, use those instead of the RoverRecs
    return staffingPlannerShifts.length > 0 || staffingPlannerDefined ? staffingPlannerShifts : shiftPlans;
  }, [shiftPlansByDay, selectedDate, staffingPlannerShifts, staffingPlannerDefined]);

  const addDayShift = React.useCallback(() => {
    const { [STAFFING_ROLE_KEYS.MIN_SHIFT_LENGTH]: minShiftLength } = currentStaffingRole;

    const dateKey = formatAsDatasetDate(selectedDate);
    const newShift = {
      start: hoursRange[0],
      end: addHours(hoursRange[0], minShiftLength - 1, hoursRange),
      shift: dayShiftPlans.length + 1,
    };

    if (staffingPlannerShifts.length === 0) {
      // save previous shifts and new shift to dashboard settings
      addShifts(locationId, dateKey, [...dayShiftPlans, newShift]);
    } else {
      // save shift to dashboard settings
      addShift(locationId, dateKey, newShift);
    }

    setShiftPlansByDay(prevShiftPlansByDay => {
      return {
        ...prevShiftPlansByDay,
        [dateKey]: [...dayShiftPlans, newShift],
      };
    });

    editsByDay.current = {
      ...editsByDay.current,
      [dateKey]: true,
    };
  }, [
    currentStaffingRole,
    selectedDate,
    hoursRange,
    dayShiftPlans,
    staffingPlannerShifts,
    addShifts,
    locationId,
    addShift,
  ]);

  const removeDayShift = React.useCallback(
    shiftNumber => {
      const dateKey = formatAsDatasetDate(selectedDate);

      if (staffingPlannerShifts.length === 0) {
        // save shifts to dashboard settings without the deleted shift
        const newShifts = dayShiftPlans
          .filter(({ shift }) => shift !== shiftNumber)
          .map((shift, index) => ({
            ...shift,
            shift: index + 1,
          }));
        addShifts(locationId, dateKey, newShifts);
      } else {
        // delete shift from dashboard settings
        const shiftIndex = staffingPlannerShifts.findIndex(({ shift }) => shift === shiftNumber);
        if (shiftIndex !== -1) {
          deleteShift(locationId, dateKey, shiftIndex);
        }
      }

      setShiftPlansByDay(prevShiftPlansByDay => {
        return {
          ...prevShiftPlansByDay,
          [dateKey]: dayShiftPlans
            .filter(({ shift }) => shift !== shiftNumber)
            .map((shift, i) => {
              return {
                ...shift,
                shift: i + 1,
              };
            }),
        };
      });

      editsByDay.current = {
        ...editsByDay.current,
        [dateKey]: true,
      };
    },
    [addShifts, dayShiftPlans, deleteShift, locationId, selectedDate, staffingPlannerShifts],
  );

  const resetDayShifts = React.useCallback(() => {
    const dateKey = formatAsDatasetDate(selectedDate);

    // delete the day shifts from dashboard settings
    deleteDay(locationId, dateKey);

    setShiftPlansByDay(prevShiftPlansByDay => {
      return {
        ...prevShiftPlansByDay,
        [dateKey]: dayShifts,
      };
    });

    editsByDay.current = {
      ...editsByDay.current,
      [dateKey]: false,
    };
  }, [dayShifts, deleteDay, locationId, selectedDate]);

  const setDayShiftPlans = React.useCallback(
    (newShiftPlans, editedShift) => {
      const dateKey = formatAsDatasetDate(selectedDate);

      if (staffingPlannerShifts.length === 0) {
        // save shifts to dashboard settings
        addShifts(locationId, dateKey, newShiftPlans);
      } else {
        // edit shift
        const newShift = newShiftPlans.find(({ shift }) => shift === editedShift);
        const shiftIndex = staffingPlannerShifts.findIndex(({ shift }) => shift === editedShift);
        editShift(locationId, dateKey, shiftIndex, newShift);
      }

      setShiftPlansByDay(prevShiftPlansByDay => {
        return {
          ...prevShiftPlansByDay,
          [dateKey]: newShiftPlans,
        };
      });

      editsByDay.current = {
        ...editsByDay.current,
        [dateKey]: true,
      };
    },
    [addShifts, editShift, locationId, selectedDate, staffingPlannerShifts],
  );

  const hourlyPlanStatistics = usePlannerHourlyStatistics(hourlyStatistics, dayShiftPlans, hoursRange);

  return {
    shiftPlansByDay,
    dayShiftPlans,
    setDayShiftPlans,
    addDayShift,
    removeDayShift,
    resetDayShifts,
    hourlyPlanStatistics,
  };
};

export const formatNumber = (value, { currencySymbol = null, showCents = false } = {}) => {
  if (value == null) {
    return '-';
  }

  if (value < 10000) {
    return `${currencySymbol ?? ''}${simpleFormatNumber(Math.round(value))}`;
  }

  const fixedDecimalDigits = value < 100 ? 2 : 1;

  if (currencySymbol != null) {
    return compactFormatNumber(value, { formatAsCurrency: true, currencySymbol, fixedDecimalDigits, showCents });
  }

  return compactFormatNumber(value, { formatAsCurrency: false, fixedDecimalDigits, showCents });
};
