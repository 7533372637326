import { Objective, PriceChangeFrequency, PriceChangeFrequencyCombos } from './workflowConstants';
import { Industry } from '../data-center/datasetConfig/DatasetConfigFormConstants';

/**
 * Multiple price change frequencies can sometimes be selected depending on the specific combination. This method
 * ensures that incompatible options are disabled based on the currently selected option(s).
 */
export const applyCompatiblePriceChangeFrequencyOptions = (checkedState, disabledState) => {
  // enable all options if none are checked
  if (Object.values(checkedState).every(frequency => !frequency)) {
    Object.keys(disabledState).forEach(frequency => {
      disabledState[frequency] = false;
    });

    return;
  }

  const checkedFrequencies = Object.keys(checkedState).filter(frequency => checkedState[frequency]);

  checkedFrequencies.forEach(checkedFrequency => {
    const validComboFrequencies = PriceChangeFrequencyCombos[checkedFrequency] ?? [];

    Object.keys(disabledState).forEach(frequency => {
      if (checkedFrequency === frequency) {
        disabledState[frequency] = false;

        return;
      }

      disabledState[frequency] = !validComboFrequencies.includes(frequency);
    });
  });

  // enforce special-case 'chained' price change frequency combinations
  if (checkedState[PriceChangeFrequency.MONTH] === true) {
    // month implies year and excludes quarter
    checkedState[PriceChangeFrequency.QUARTER] = false;
    checkedState[PriceChangeFrequency.YEAR] = true;
    disabledState[PriceChangeFrequency.QUARTER] = true;
    disabledState[PriceChangeFrequency.YEAR] = true;
  } else if (checkedState[PriceChangeFrequency.QUARTER] === true) {
    // quarter implies year and excludes month
    checkedState[PriceChangeFrequency.MONTH] = false;
    checkedState[PriceChangeFrequency.YEAR] = true;
    disabledState[PriceChangeFrequency.MONTH] = true;
    disabledState[PriceChangeFrequency.YEAR] = true;
  }
};

export const getWorkflowTitle = workflow => {
  const { objective, description, workflow_id: workflowId } = workflow;

  if (description == null) {
    return objective === Objective.ANOMALY
      ? 'Anomaly Detection'
      : objective === Objective.PRICEELASTICITY
      ? 'Price Elasticity'
      : `Workflow ID: ${workflowId}`;
  }
  return description;
};

export const isEoPriceoptWorkflow = ({ objective, industry }) =>
  objective === Objective.PRICEOPT && industry === Industry.EO;

export const isMediaPriceElasticityWorkflow = ({ objective, industry }) =>
  objective === Objective.PRICEELASTICITY && industry === Industry.MEDIA;
