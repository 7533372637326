import React from 'react';
import Select, { components } from 'react-select';
import { BACKGROUND_GRAY, FONT_GRAY, LIGHT_GREEN_SHADOW } from '../../../colors';
import { useNaicsCodesHierarchy } from '../../../data-access/query/naics';

const DEFAULT_HEIGHT = 35;

const selectStyles = {
  container: provided => ({
    ...provided,
    borderRadius: '4px',
    boxShadow: `0px 1px 4px ${LIGHT_GREEN_SHADOW}`,
    fontSize: '14px',
  }),
  control: provided => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    boxShadow: 'none',
    minHeight: DEFAULT_HEIGHT,
    height: DEFAULT_HEIGHT,
  }),
  dropDownIndicator: provided => ({
    ...provided,
    padding: '0px',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: provided => ({ ...provided, paddingRight: '2px', height: '100%' }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
    boxShadow: `0px 1px 8px ${LIGHT_GREEN_SHADOW};`,
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    color: FONT_GRAY,
    background: isSelected || isFocused ? BACKGROUND_GRAY : provided.background,
    padding: '6px 10px',
  }),
  singleValue: (provided, state) => ({ ...provided, opacity: state.selectProps.isLoading ? 0 : 1 }),
  valueContainer: provided => ({
    ...provided,
    padding: '0px 8px',
    height: '100%',
  }),
};

/**
 * Paints bold highlighting around any matching search text.
 *
 * KNOWN LIMITATION: This only highlights the first text found in the label.
 */
const buildHighlitLabel = (inputValue, label) => {
  const lowercaseInput = inputValue.toLocaleLowerCase();
  const lowercaseLabel = label.toLocaleLowerCase();

  const index = lowercaseLabel.indexOf(lowercaseInput);

  const [before, match, after] = [
    label.substring(0, index),
    label.substring(index, index + inputValue.length),
    label.substring(index + inputValue.length),
  ];

  return (
    <>
      <span>{before}</span>
      <strong>{match}</strong>
      <span>{after}</span>
    </>
  );
};

const TextHighlightingOption = props => {
  const {
    selectProps: { inputValue },
    data: { label },
  } = props;

  return <components.Option {...props}>{buildHighlitLabel(inputValue, label)}</components.Option>;
};

export const mapCodeToOption = code => {
  const {
    description,
    levels: { code_3: code3 },
  } = code;

  const label = `${description} | ${code3.description}`;

  return { label, value: code };
};

const IndustrySelect = props => {
  const { data: naicsHierarchyCodes = [], isLoading } = useNaicsCodesHierarchy();

  const options = React.useMemo(() => naicsHierarchyCodes.map(mapCodeToOption), [naicsHierarchyCodes]);

  return (
    <Select
      {...props}
      components={{ Option: TextHighlightingOption }}
      styles={selectStyles}
      options={options}
      getOptionValue={option => option.label}
      isLoading={isLoading}
      isClearable
      placeholder="Select an industry..."
    />
  );
};

export default IndustrySelect;
