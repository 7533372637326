import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_CUSTOMER_CONFIG_BASE = `${KA_API_URL}/config`;

/**
 * Requests
 */

export const fetchCustomerConfigSection = async section => axios.get(`${GET_CUSTOMER_CONFIG_BASE}/${section}`);

/**
 * Hooks
 */

export const CUSTOMER_CONFIG_QUERY_KEY_BASE = 'customerConfig';

export const useCustomerConfigSection = (section, queryConfig) =>
  useQuery(
    [...CUSTOMER_CONFIG_QUERY_KEY_BASE, section],
    async () => {
      const response = await fetchCustomerConfigSection(section);
      const configSection = response.data ?? {};

      return configSection;
    },
    queryConfig,
  );
