import _ from 'lodash';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { mutateNaicsKaCode } from './common';
import { WORKFLOWS_QUERY_KEY } from '../query/workflows';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const createWorkflow = async workflow => {
  mutateNaicsKaCode(workflow);

  return axios.post(`${KA_API_URL}/workflows`, workflow);
};

export const updateWorkflow = async (workflowId, workflow, shouldRefreshResults = false) => {
  let url = `${KA_API_URL}/workflows/${workflowId}`;

  if (shouldRefreshResults) {
    url += `?refresh_results=${shouldRefreshResults}`;
  }

  return axios.put(url, {
    objective: workflow.objective,
    description: workflow.description,
    time_granularity: workflow.time_granularity,
    model_input_time_granularity: workflow.model_input_time_granularity,
    dimensions: workflow.dimensions,
    pricing_dimensions: workflow.pricing_dimensions,
    price_changes: workflow.price_changes,
  });
};

/**
 * Hooks
 */

export const useCreateWorkflow = mutationConfig => {
  const queryClient = useQueryClient();

  return useMutation(
    async workflow => {
      const response = await createWorkflow(workflow);

      return response.data;
    },
    {
      ...mutationConfig,
      onSuccess: async () => {
        await queryClient.invalidateQueries(WORKFLOWS_QUERY_KEY);

        if (_.isFunction(mutationConfig?.onSuccess)) {
          await mutationConfig.onSuccess();
        }
      },
    },
  );
};
