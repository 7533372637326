import React from 'react';
import classnames from 'classnames';
import './profitrover-buttons.css';

const ProfitRoverButtonBase = ({ children, className, small = false, type = 'button', ...props }) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={classnames('profitrover-button-base', { 'small-button': small }, className)}
    {...props}
  >
    {children}
  </button>
);

export const ProfitRoverPrimaryButton = ({ className, small, ...props }) => (
  <ProfitRoverButtonBase className={classnames('profitrover-primary-button', className)} small={small} {...props} />
);

export const ProfitRoverSecondaryButton = ({ className, small, ...props }) => (
  <ProfitRoverButtonBase className={classnames('profitrover-secondary-button', className)} small={small} {...props} />
);

export const ProfitRoverCancelButton = ({ className, small, ...props }) => (
  <ProfitRoverButtonBase className={classnames('profitrover-cancel-button', className)} small={small} {...props} />
);

export const ProfitRoverDestructiveButton = ({ className, small, ...props }) => (
  <ProfitRoverButtonBase className={classnames('profitrover-destructive-button', className)} small={small} {...props} />
);

export const ProfitRoverAddButton = ({ className, type = 'button', ...props }) => (
  // eslint-disable-next-line react/button-has-type
  <button type={type} className={classnames('profitrover-add-button', className)} {...props}>
    <div className="alignment-container">
      <div className="plus-container">
        <span className="plus">+</span>
      </div>
      <div>{props.children}</div>
    </div>
  </button>
);
