import axios from 'axios';
import { useMutation } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';
import { KA_QUEUE } from '../../components/setup/IntegrationConstants';
import {
  PARTNER_SCHEDULE_PROCESS_RUN_AT_HOUR_GMT,
  PARTNER_SCHEDULE_PROCESS_REFRESH_PERIOD,
} from '../../config/schedule';

const runAt = () =>
  `${PARTNER_SCHEDULE_PROCESS_RUN_AT_HOUR_GMT}:${String(Math.floor(Math.random() * 20)).padStart(2, '0')}:00`;

/**
 * Requests
 */

export const createSchedule = async (datasetId, industry, dataType, description) =>
  axios.post(`${KA_API_URL}/schedule`, [
    {
      refresh_period: PARTNER_SCHEDULE_PROCESS_REFRESH_PERIOD,
      refresh_day: 0,
      run_at_time_gmt: runAt(),
      ka_queue: KA_QUEUE.PROCESS,
      description,
      message: {
        dataset_ids: datasetId,
        industry,
        data_type: dataType,
      },
    },
  ]);

/**
 * Hooks
 */

export const useCreatePartnerSchedule = mutationConfig =>
  useMutation(async ({ datasetId, industry, dataType, description }) => {
    const response = await createSchedule(datasetId, industry, dataType, description);

    return response?.data;
  }, mutationConfig);
