import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import AnomalyComparisons, { useAnomalyComparisonLegend } from './AnomalyComparisons';
import { useAnomalyDetectionContext } from './AnomalyDetectionContext';
import { useAnomalyGraphData } from './anomalyDetectionHooks';
import AnomalyLineGraph from './AnomalyLineGraph';
import { ACTUALS_LINE_COLOR, EXPECTED_LINE_COLOR, STROKE_OPACITY } from './graphConstants';

const ActualsVsExpectedLegend = () => {
  return (
    <div className="actuals-vs-expected-legend">
      <svg height="6" width="20" strokeOpacity={STROKE_OPACITY}>
        <g fill="none" stroke={ACTUALS_LINE_COLOR} strokeWidth="5">
          <path d="M0 3 l16 0" />
        </g>
      </svg>
      <span className="mr-3">Actuals</span>

      <svg height="6" width="18" strokeOpacity={STROKE_OPACITY}>
        <g fill="none" stroke={EXPECTED_LINE_COLOR} strokeWidth="5">
          <path strokeDasharray="2 2" d="M0 3 l16 0" />
        </g>
      </svg>
      <span className="mr-3">Expected</span>
    </div>
  );
};

const GraphsView = ({ date, productDimIds, productDimValues, timeGranularity, anomalyScore }) => {
  const { workflowId, metricColumn: metricColumnLabel } = useAnomalyDetectionContext();

  const { width, height, ref: divRef } = useResizeDetector();

  const graphData = useAnomalyGraphData(
    { workflowId, productDimIds, productDimValues, timeGranularity },
    {
      enabled: workflowId != null,
      retry: 1,
    },
  );

  const { comparisons, toggleSelectionState } = useAnomalyComparisonLegend({
    workflowId,
    productDimIds,
    productDimValues,
    timeGranularity,
  });

  if (graphData.data?.length === 0 && graphData.isSuccess) {
    return (
      <div className="graphs-view h-100 d-flex align-items-center justify-content-center">
        <i>There is no data to view at this time...</i>
      </div>
    );
  }

  return (
    <div className="graphs-view d-flex flex-column px-5 pt-3 pb-2">
      <div className="d-flex">
        <h5 className="title mb-1 mr-3">{metricColumnLabel} Over Time</h5>
        <ActualsVsExpectedLegend />
      </div>
      <div ref={divRef} style={{ minHeight: 400, height: 400 }}>
        <AnomalyLineGraph
          data={graphData.data ?? []}
          comparisons={comparisons}
          width={width ?? 0}
          height={height ?? 0}
          anomalyDate={date}
          timeGranularity={timeGranularity}
          anomalyScore={anomalyScore}
        />
      </div>
      <AnomalyComparisons comparisons={comparisons} toggleSelectionState={toggleSelectionState} />
    </div>
  );
};

export default GraphsView;
