import React from 'react';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import Header from '../header/header';
import { onLogout } from '../../utils/axios-client';

const Logout = () => {
  /**
   * Executes the logout sequence.
   *
   * Developer Note: Navigating to this page is the "graceful" way to log the user out, as it allows
   * the application to complete any "onUnmount" effects that make API requests before we invalidate
   * the JWT and clear any session state. Otherwise, any such outstanding requests might receive
   * undesirable 401 responses.
   */
  React.useEffect(() => {
    onLogout();
  }, []);

  return (
    <HeaderAwarePage scrollable={false}>
      <Header />
      <h3 className="pb-5 w-100 h-100 d-flex align-items-center justify-content-center">Logging out...</h3>
    </HeaderAwarePage>
  );
};

export default Logout;
