import React from 'react';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { hasFailedPayment } from './invoiceUtil';
import { KaizenProduct, KaizenProductStatus } from './SubscriptionConstants';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import { useSubscriptionStatusForProduct } from '../../routing/subscriptionStatusHooks';
import { useSubscriptionInvoices } from '../../../data-access/query/subscriptions';
import './unpaid-invoice-banner.scss';

const MULTIPLE_UNPAID_BANNER_TEXT = 'Please pay your past due invoices to regain access to your insights.';
const ONE_UNPAID_BANNER_TEXT = 'Please pay your past due invoice to regain access to your insights.';
const SHOPIFY_UNPAID_BANNER_TEXT =
  'Please pay your past due invoices in your Shopify Dashboard to regain access to your insights.';

const UnpaidInvoiceBanner = ({ spacingClassName }) => {
  const { isStripeSubscription, isShopifySubscription, kaizenProductStatus } = useSubscriptionStatusForProduct(
    KaizenProduct.KP,
  );
  const { data: invoices = [], isFetching, isError } = useSubscriptionInvoices(KaizenProduct.KP, {
    enabled: isStripeSubscription,
  });

  const history = useHistory();

  if (isFetching || isError) {
    return null;
  }

  const failedPaymentInvoices = invoices.filter(hasFailedPayment);
  const hasMultipleFailedPayments = failedPaymentInvoices.length > 1;

  if (
    isShopifySubscription &&
    kaizenProductStatus !== KaizenProductStatus.PAST_DUE &&
    kaizenProductStatus !== KaizenProductStatus.UNPAID
  ) {
    return null;
  }

  if (!isShopifySubscription && failedPaymentInvoices.length === 0) {
    return null;
  }

  const onClick = () => {
    let pathname;
    if (hasMultipleFailedPayments) {
      pathname = '/invoices';
    } else {
      const [delinquentInvoice] = failedPaymentInvoices;
      pathname = `/pay-invoice/${delinquentInvoice.id}`;
    }

    history.push(pathname);
  };

  const getTexts = () => {
    if (isShopifySubscription) {
      return ['Invoices Past Due', '', SHOPIFY_UNPAID_BANNER_TEXT];
    }

    return hasMultipleFailedPayments
      ? ['Invoices Past Due', 'View Invoices', MULTIPLE_UNPAID_BANNER_TEXT]
      : ['Invoice Past Due', 'Pay Invoice', ONE_UNPAID_BANNER_TEXT];
  };

  const [bannerTitle, buttonText, bannerText] = getTexts();

  return (
    <div className="unpaid-invoice-banner">
      <div className={classnames('spacing-container', spacingClassName)}>
        <h2 className="heading">
          <FontAwesomeIcon icon={faExclamationTriangle} /> {bannerTitle}
        </h2>
        <div className="remark">Payment was unsuccessful.</div>
        <div className="emphasis-area">
          <div className="unpaid-invoice-message">{bannerText}</div>
          {isStripeSubscription && (
            <ProfitRoverPrimaryButton className="end-trial-early-button" onClick={onClick}>
              {buttonText} <FontAwesomeIcon icon={faCaretRight} style={{ marginLeft: '0.5rem' }} />
            </ProfitRoverPrimaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnpaidInvoiceBanner;
