import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_STAFFING_ROLES = `${KA_API_URL}/config/staffing_roles`;

/**
 * Requests
 */

const fetchStaffingRoles = async () => axios.get(GET_STAFFING_ROLES);

/**
 * Hooks
 */

const STAFFING_ROLES_QUERY_KEY_BASE = 'staffingRoles';

export const useStaffingRoles = queryConfig =>
  useQuery(
    [STAFFING_ROLES_QUERY_KEY_BASE],
    async () => {
      const response = await fetchStaffingRoles();

      return response?.data ?? [];
    },
    queryConfig,
  );
