import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './password-rules-list.scss';

export const emailIsValid = emailAddress => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);
};

const generateDescriptionByRuleId = id => {
  switch (id) {
    case 'minlength':
      return '8 or more characters';
    case 'lowercase':
      return 'Lowercase';
    case 'uppercase':
      return 'Uppercase';
    case 'digit':
      return 'Number(s)';
    case 'special':
      return 'Special Characters (!,$,#,@,*)';
    case 'forbidden':
      return "No forbidden words (e.g. 'password')";
    default:
      return '';
  }
};

export const CheckmarkPasswordRuleList = ({ rules }) => (
  <ul className="password-requirements-list">
    {rules.map(rule => (
      <li key={rule.id} className={rule.isSatisfied ? 'satisfied' : 'notSatisfied'}>
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> {generateDescriptionByRuleId(rule.id)}
      </li>
    ))}
  </ul>
);
