const {
  REACT_APP_LIGHTSPEED_K_CLIENT_ID: LIGHTSPEED_K_CLIENT_ID,
  REACT_APP_LIGHTSPEED_K_SCOPES: LIGHTSPEED_K_SCOPES,
  REACT_APP_LIGHTSPEED_K_REDIRECT_URI: LIGHTSPEED_K_REDIRECT_URI,
  REACT_APP_LIGHTSPEED_K_BASE_URL: LIGHTSPEED_K_BASE_URL,
} = process.env;

const lightspeedKOauthURL = `${LIGHTSPEED_K_BASE_URL}/oauth/authorize?response_type=code`
  .concat('&client_id=')
  .concat(LIGHTSPEED_K_CLIENT_ID)
  .concat('&scope=')
  .concat(LIGHTSPEED_K_SCOPES)
  .concat('&redirect_uri=')
  .concat(encodeURIComponent(LIGHTSPEED_K_REDIRECT_URI));

export default lightspeedKOauthURL;
