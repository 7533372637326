import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { OAUTH_DATASOURCES } from '../../dataSources/dataSourceConstants';

export const STEPS = {
  WELCOME: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  /**
   * Does not correspond to a real Component; instead, this step refers to a DATA_SOURCE-dependent
   * UI flow that comes after the common flow (which consists of the previous steps listed above)
   */
  COMPLETE: 4,
};

/**
 * For each DataSource, there is a point where the user can no longer return to previous screens and
 * is thus "locked in" to setting up that particular DataSource type.
 */
const isPastThePointOfNoReturn = guidedSetup => {
  const { data_source: dataSource } = guidedSetup;

  if (!dataSource) {
    return false;
  }

  if (OAUTH_DATASOURCES.includes(dataSource.source)) {
    return guidedSetup.schedule_id != null;
  }

  // For manual setups...
  return 'dataset_id' in guidedSetup;
};

const computeCurrentStep = (guidedSetup, desiredStep) => {
  if (Object.values(STEPS).includes(desiredStep)) {
    return desiredStep;
  }

  const guidedSetupKeys = Object.keys(guidedSetup);
  const hasCompletedStepOne = guidedSetupKeys.includes('naics_industry');
  const hasScheduledFreeCall = guidedSetup.scheduled_free_call;
  const hasCompletedStepThree = guidedSetupKeys.includes('data_source');

  // Check the scheduled free call first so that the user keeps getting reminded each time they sign in
  if (hasCompletedStepOne && !hasScheduledFreeCall && !isPastThePointOfNoReturn(guidedSetup)) {
    return STEPS.TWO;
  }

  if (hasCompletedStepThree) {
    return STEPS.COMPLETE;
  }

  if (hasCompletedStepOne) {
    // skip over step 2 since we checked for completeness above
    return STEPS.THREE;
  }

  return STEPS.WELCOME;
};

export const useStepControl = guidedSetup => {
  /**
   * Any step can be returned to from later steps (i.e. steps that exist at a different URL)
   * in the Guided Setup by setting this value in the React Router location state
   */
  const { state: { desiredStep } = {} } = useLocation();

  const [step, setStep] = React.useState(() => computeCurrentStep(guidedSetup, desiredStep));

  const goToNextStep = () => {
    setStep(currentStep => currentStep + 1);
  };

  const goToPreviousStep = () => {
    setStep(currentStep => currentStep - 1);
  };

  const completeCommonFlow = () => {
    setStep(STEPS.COMPLETE);
  };

  return {
    step,
    setStep,
    goToNextStep,
    goToPreviousStep,
    completeCommonFlow,
  };
};

const POS_SETUP_STEP = STEPS.THREE;

export const useNavigateToPOSGuidedSetupStep = () => {
  const history = useHistory();

  return {
    onClick: () => history.push('/guided-setup', { desiredStep: POS_SETUP_STEP }),
  };
};
