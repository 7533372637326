/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import { ProfitRoverCancelButton, ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import './CustomEvents.css';

const eventModal = props => {
  const {
    openEditModal,
    multiRowEditMap,
    toggleModal,
    multiStartDate,
    multiEndDate,
    customEventsTypes,
    handleMultiEditEventChange,
    updateMultipleRows,
    error,
    errorMessage,
  } = props;

  let errorDiv = null;
  if (error) {
    errorDiv = (
      <div
        style={{
          fontSize: '12px',
          color: 'red',
          textAlign: 'center',
          padding: '6px',
        }}
      >
        <h5>{errorMessage}</h5>
      </div>
    );
  }

  return (
    <Modal show={openEditModal} className="file-upload-modal">
      <div className="modal-content modal-content-centered">
        <p className="modal-heading">Edit {multiRowEditMap.size} Selected Products</p>
        <br />

        <div className="row">
          <div className="col-1" />
          <div className="col-4">
            <h6>Event Name / Description: &nbsp;</h6>
          </div>
          <div className="col-5">
            <input name="multiDescription" type="text" onChange={e => handleMultiEditEventChange(e, null, null)} />
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-1" />
          <div className="col-4">
            <h6>Start Date: &nbsp;</h6>
          </div>
          <div className="col-5">
            <DatePicker
              value={multiStartDate}
              onChange={date => handleMultiEditEventChange(null, 'multiStartDate', date)}
            />
          </div>
        </div>

        <br />
        <div className="row">
          <div className="col-1" />
          <div className="col-4">
            <h6>End Date: &nbsp;</h6>
          </div>
          <div className="col-5">
            <DatePicker
              value={multiEndDate}
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'], // don't allow it to flip to be above
                },
              }}
              onChange={date => handleMultiEditEventChange(null, 'multiEndDate', date)}
            />
          </div>
        </div>

        <br />
        <div className="row">
          <div className="col-1" />
          <div className="col-4">
            <h6>Impact Type: &nbsp;</h6>
          </div>
          <div className="col-5">
            <select name="multiImpactType" className="select" onChange={e => handleMultiEditEventChange(e, null, null)}>
              <option value="" />
              {customEventsTypes.map(event => {
                return (
                  <option key={event.impact_type} value={event.impact_type}>
                    {event.description}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <br />
        {errorDiv}
        <div className="custom-events-button-footer">
          <ProfitRoverPrimaryButton onClick={updateMultipleRows}>Ok</ProfitRoverPrimaryButton>
          &nbsp;
          <ProfitRoverCancelButton onClick={toggleModal}>Cancel</ProfitRoverCancelButton>
        </div>
      </div>
    </Modal>
  );
};

export default eventModal;
