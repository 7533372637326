import {
  APP_PROGRESS_STAGE,
  BATCH_PROGRESS_STAGE,
  EVENT_PREPARE_STEP_TO_APP_PROGRESS_STEP,
  FETCHING_DATA_STEP,
  PAYLOAD_TYPE,
} from './batchProgressConstants';

export const eventToAppProgressStage = (payloadType, payload) => {
  if (payloadType === PAYLOAD_TYPE.BATCH_COMPLETE) {
    return { stage: APP_PROGRESS_STAGE.COMPLETE };
  }

  if (payloadType === PAYLOAD_TYPE.BATCH_PROGRESS) {
    const {
      stage,
      step,
      completion_pct: completionPct,
      is_progress_unit_creation_complete: isProgressUnitCreationComplete,
      output_results: outputResults,
    } = payload;

    if (isProgressUnitCreationComplete) {
      if (completionPct >= 0 && completionPct <= 100) {
        return { stage: APP_PROGRESS_STAGE.UPDATING_RESULTS, completionPct, canShowDashboard: outputResults > 0 };
      }

      return { stage: APP_PROGRESS_STAGE.UPDATING_RESULTS, completionPct: 0, canShowDashboard: outputResults > 0 };
    }

    if (
      stage === BATCH_PROGRESS_STAGE.INITIAL ||
      (stage === BATCH_PROGRESS_STAGE.PROCESS && step !== FETCHING_DATA_STEP)
    ) {
      return { stage: APP_PROGRESS_STAGE.PREPARING_DATA, step: 1 };
    }

    if (stage === BATCH_PROGRESS_STAGE.PROCESS && step === FETCHING_DATA_STEP) {
      return { stage: APP_PROGRESS_STAGE.PREPARING_DATA, step: 2 };
    }

    if (stage === BATCH_PROGRESS_STAGE.PREPARE) {
      const appProgressStage = EVENT_PREPARE_STEP_TO_APP_PROGRESS_STEP[step];

      return { stage: APP_PROGRESS_STAGE.PREPARING_DATA, step: appProgressStage ?? 3 };
    }
  }

  return { stage: APP_PROGRESS_STAGE.PREPARING_DATA, step: 0 };
};

export const batchToAppProgressStage = batchInProgress => {
  const { current_stage: stage, batch_progress: batchProgress } = batchInProgress ?? {};

  if (batchInProgress == null) {
    return { stage: APP_PROGRESS_STAGE.COMPLETE };
  }

  const {
    progress_units: progressUnits,
    is_progress_units_creation_complete: isProgressUnitCreationComplete,
    output_results: outputResults,
    output_errors: outputErrors,
  } = batchProgress ?? { is_progress_units_creation_complete: false, output_results: 0, output_errors: 0 };

  if (stage === BATCH_PROGRESS_STAGE.FORECAST) {
    return { stage: APP_PROGRESS_STAGE.UPDATING_RESULTS, completionPct: 0, canShowDashboard: false };
  }

  let step = 1;

  if (stage === BATCH_PROGRESS_STAGE.PROCESS) {
    step = 2;
  }

  if (stage === BATCH_PROGRESS_STAGE.PREPARE) {
    const totalResults = outputResults + outputErrors;
    if (isProgressUnitCreationComplete || totalResults > 0) {
      step = 7;
    } else if (progressUnits > 0) {
      step = 6;
    } else {
      step = 3;
    }
  }

  return { stage: APP_PROGRESS_STAGE.PREPARING_DATA, step };
};
