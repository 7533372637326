import React from 'react';
import { Redirect } from 'react-router-dom';
import AutomaticLogin from './AutomaticLogin';
import { useCorporateMarketingParams, usePromoPriceName } from './customerRegistrationHooks';
import ProductDescription from './ProductDescription';
import RedeemCode from './RedeemCode';
import { RegistrationContext, REGISTRATION_STEPS, useRegistrationContext } from './registrationContext';
import RequestCode from './RequestCode';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import Header from '../header/header';
import { PR_COUPON, PR_REGULAR_PRICE } from '../../config/stripe';
import accessTokenInstance from '../../data-access/axios-instances/userRegistrationInstance';
import {
  useProfitRoverCoupon,
  useProfitRoverSubscriptionPrices,
  useRegistrationAccessToken,
} from '../../data-access/query/stripe';
import './customer-registration.scss';

const useRegisteredAccessToken = () => {
  // Acquire an access token to use for the rest of the user registration process
  const { data: accessToken, isFetching, refetch: refetchToken } = useRegistrationAccessToken();

  // Automatically include this token on requests made with this special Axios instance
  React.useEffect(() => {
    let accessTokenInterceptor;

    if (!isFetching && accessToken && accessTokenInterceptor == null) {
      accessTokenInterceptor = accessTokenInstance.interceptors.request.use(
        async request => {
          request.headers['x-api-key'] = accessToken;
          return request;
        },
        error => {
          /**
           * TODO: Fix the issue where the token expires while the user is completing the page,
           * which will prevent the user from completing the registration process unless they
           * refresh the page.
           */

          return Promise.reject(error);
        },
      );
    }

    return () => {
      if (accessTokenInterceptor) {
        accessTokenInstance.interceptors.request.eject(accessTokenInterceptor);
      }
    };
  }, [accessToken, isFetching, refetchToken]);

  return { accessToken, isFetching };
};

const useCustomerRegistration = isFetchingAccessToken => {
  const { gotoStep: initialStep, emailParam } = useCorporateMarketingParams(isFetchingAccessToken);
  const [step, setStep] = React.useState(initialStep ?? REGISTRATION_STEPS.PRODUCT_DESCRIPTION);

  const [emailAddress, setEmailAddress] = React.useState(emailParam ?? '');
  const [password, setPassword] = React.useState('');

  const promoPriceName = usePromoPriceName();
  const { data: prices = {}, isFetching: pricesFetching } = useProfitRoverSubscriptionPrices([
    PR_REGULAR_PRICE,
    promoPriceName,
  ]);
  const { data: coupon, isFetching: couponFetching } = useProfitRoverCoupon(PR_COUPON);

  const selectedPlan = prices[PR_REGULAR_PRICE]?.monthly ?? {};
  const annualPlan = prices[PR_REGULAR_PRICE]?.annual ?? {};

  return {
    step,
    setStep,
    emailAddress,
    setEmailAddress,
    password,
    setPassword,
    selectedPlan,
    annualPlan,
    pricesFetching,
    coupon,
    couponFetching,
  };
};

const CustomerRegistration = () => {
  const { step } = useRegistrationContext();

  if (step === REGISTRATION_STEPS.PRODUCT_DESCRIPTION) {
    return <ProductDescription />;
  }
  if (step === REGISTRATION_STEPS.REQUEST_CODE) {
    return <RequestCode />;
  }
  if (step === REGISTRATION_STEPS.REDEEM_CODE) {
    return <RedeemCode />;
  }
  if (step === REGISTRATION_STEPS.AUTO_LOGIN) {
    return <AutomaticLogin />;
  }

  return <Redirect to="/login-redirect" />;
};

const RegistrationContainer = () => {
  const { accessToken, isFetching } = useRegisteredAccessToken();

  const contextValue = useCustomerRegistration(isFetching);

  if (isFetching) {
    return null;
  }

  if (!isFetching && !accessToken) {
    return 'Something went wrong: please refresh the page';
  }

  return (
    <HeaderAwarePage className="background-container" scrollable>
      <Header />
      <RegistrationContext.Provider value={contextValue}>
        <CustomerRegistration />
      </RegistrationContext.Provider>
    </HeaderAwarePage>
  );
};

export default RegistrationContainer;
