import React, { useRef } from 'react';
import * as d3 from 'd3';
import { FONT_GRAY, FONT_BLACK } from '../../../../../colors';

const BAR_RADIUS = 3;
const PADDING_TOP = 1;
const PADDING_BOTTOM = 1;

const ComparePlot = ({ data, width, height, formatNumber, barColors }) => {
  const svgRef = useRef();

  React.useLayoutEffect(() => {
    const svg = d3.select(svgRef.current);
    const innerWidth = Math.max(width - 70, 0);

    const maxValue = Math.max(d3.max(data.map(d => d.value)), 0);
    const minValue = Math.min(d3.min(data.map(d => d.value)), 0);

    // Clear previous plot
    svg.selectAll('*').remove();

    // Create a diagonal hatch pattern
    const defs = svg.append('defs');

    defs
      .append('pattern')
      .attr('id', 'diagonal-hatch')
      .attr('width', 6)
      .attr('height', 6)
      .attr('patternTransform', 'rotate(50 0 0)')
      .attr('patternUnits', 'userSpaceOnUse')
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', 10)
      .attr('stroke', barColors.stly)
      .attr('stroke-width', 2);

    const xScale = d3
      .scaleLinear()
      .domain([minValue, maxValue])
      .range([0, innerWidth]);

    const yScale = d3
      .scaleBand()
      .domain(data.map(d => d.key))
      .range([PADDING_TOP, height - PADDING_BOTTOM])
      .padding(0.2);

    const g = svg.append('g');

    g.append('line')
      .attr('x1', xScale(0))
      .attr('y1', 0)
      .attr('x2', xScale(0))
      .attr('y2', height)
      .style('stroke', FONT_GRAY)
      .style('stroke-width', 1);

    const bars = g
      .selectAll('.metric-group')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'metric-group')
      .attr('transform', d => `translate(0, ${yScale(d.key)})`);

    bars
      .append('rect')
      .attr('width', d => Math.abs(xScale(d.value) - xScale(0)))
      .attr('height', yScale.bandwidth())
      .attr('rx', BAR_RADIUS)
      .attr('ry', BAR_RADIUS)
      .style('fill', d => (d.key === 'stly' ? 'url(#diagonal-hatch)' : barColors[d.key]));

    bars
      .append('text')
      .attr('x', d => xScale(d.value))
      .attr('dx', '0.3em')
      .attr('y', yScale.bandwidth() / 2)
      .attr('dy', '0.35em')
      .style('text-anchor', 'start')
      .text(d => formatNumber(d.value))
      .style('fill', FONT_BLACK)
      .style('font-family', 'URWDIN')
      .style('font-weight', d => (d.key === 'new_prices' ? '600' : '400'))
      .style('font-size', '13px');
  }, [data, width, height, formatNumber, barColors]);

  return (
    <div style={{ position: 'relative', overflow: 'visible' }}>
      <svg ref={svgRef} width={width} height={height} />
    </div>
  );
};

export default ComparePlot;
