import { createContext, useContext } from 'react';

const LineGraphContext = createContext();

export const LineGraphProvider = LineGraphContext.Provider;

export const useLineGraphContext = () => {
  const context = useContext(LineGraphContext);

  if (context === undefined) {
    throw new Error(' context must be used with the LineGrapg Provider ');
  }
  return context;
};
