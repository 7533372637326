import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_GUIDED_SETUP_BASE = `${KA_API_URL}/guidedsetup`;

/**
 * Requests
 */

const fetchGuidedSetup = async () => axios.get(GET_GUIDED_SETUP_BASE);

/**
 * Hooks
 */

export const GUIDED_SETUP_QUERY_KEY = ['guidedSetup'];

export const useGuidedSetup = queryConfig =>
  useQuery(
    GUIDED_SETUP_QUERY_KEY,
    async () => {
      const response = await fetchGuidedSetup();

      return response.data;
    },
    queryConfig,
  );
