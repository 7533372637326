import { useQuery } from 'react-query';
import { KA_API_ROOT_URL } from '../../config/baseUrl';
import axios from '../../utils/axios-client';

/**
 * Requests
 */

const fetchShopifyPrices = async product =>
  axios.get(`${KA_API_ROOT_URL}/shopify/prices`, {
    params: {
      product,
    },
  });

/**
 * Hooks
 */

export const SHOPIFY_PRICES_QUERY_KEY_BASE = 'shopifyPrices';

export const useShopifyPrices = (product, queryConfig) =>
  useQuery(
    [SHOPIFY_PRICES_QUERY_KEY_BASE, product],
    async () => {
      const response = await fetchShopifyPrices(product);
      return response.data;
    },
    queryConfig,
  );
