export default class DimValueSetting {
  constructor({ id, dimensionId, value }) {
    this.id = id;
    this.dimension_id = dimensionId;
    this.value = value;
    this.isEditing = false;
    this.created = false;
    this.deleted = false;
  }

  /**
   * Supports creating a new setting from an existing one. This is intended to support
   * the use case in React where you're not supposed to mutate state values directly.
   * Instead, if you want to modify a value, you can copy the original value with this
   * method and mutate the copy before setting the new React state.
   */
  static from(dimValuesSetting) {
    const copy = { ...dimValuesSetting };
    Object.setPrototypeOf(copy, DimValueSetting.prototype);

    return copy;
  }

  isNew() {
    return this.isCreated;
  }

  setIsNew() {
    // No new DimValueSetting should be allowed to be set back to isNew = false
    this.isCreated = true;
  }

  isBeingEdited() {
    return this.isEditing;
  }

  setIsEditing(isEditing) {
    if (typeof isEditing === 'boolean') {
      this.isEditing = isEditing;
    }
  }

  getValue() {
    return this.value;
  }

  getEditedValue() {
    return this.editedValue;
  }

  isValueEdited() {
    const editedValueExists = this.editedValue != null;

    return editedValueExists ? this.value !== this.editedValue : false;
  }

  setEditedValue(editedValue) {
    this.editedValue = editedValue;
  }

  clearEditedValue() {
    delete this.editedValue;
  }

  isDeleted() {
    return this.deleted === true;
  }

  setDeleted(deleted) {
    this.deleted = deleted;
  }

  isMapped(mappedDimensionValueIdsSet) {
    try {
      return mappedDimensionValueIdsSet.has(this.id);
    } catch (error) {
      throw new Error('Developer Error: isMapped requires set of mapped dimension value ids to be passed.');
    }
  }
}
