/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCheck, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import pagination from '../../dashboards/eo/eoPagination';

function actionComponent(openSingleRow, rowData, singleRowEditSet, updateSingleRow, deleteSingle) {
  return (
    <div>
      {rowData.isSaved !== undefined ? (
        ''
      ) : (
        <div>
          {singleRowEditSet.has(`${rowData.location_id}${rowData.product_id}`) ? (
            <FontAwesomeIcon
              name="editOpen"
              className="Icon"
              icon={faCheck}
              onClick={e => updateSingleRow(e, rowData)}
            />
          ) : (
            <FontAwesomeIcon name="editOpen" className="Icon" icon={faEdit} onClick={e => openSingleRow(e, rowData)} />
          )}

          <FontAwesomeIcon icon={faTrash} className="Icon" onClick={() => deleteSingle(rowData)} />
        </div>
      )}
    </div>
  );
}
actionComponent.displayName = 'Action';

const priceRuleTable = props => {
  const {
    data,
    dataAvailable,
    priceRuleTypes,
    openSingleRow,
    singleRowEditSet,
    handleSingleEditPriceRuleChange,
    updateSingleRow,
    singleRowUpdateMap,
    priceRuleTypesMap,
    multiRowEditMap,
    handleCheck,
    applyAll,
    isAllSelected,
    deleteSingle,
    sortClick,
    sortObject,
  } = props;

  const headerStyleObj = {
    fontSize: '14px',
    height: '40px',
    background: '#ffc500',
    fontWeight: 'bold',
    boxSizing: 'border-box',
  };

  const tableDataStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };
  return (
    <div>
      <ReactTable
        data={data}
        columns={[
          {
            Header: <input type="checkbox" checked={isAllSelected} onChange={applyAll} style={{ marginTop: '5px' }} />,
            headerStyle: headerStyleObj,
            id: 'apply_all',
            accessor: d => d,
            Cell: row => (
              <div>
                <input
                  style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  name="checked"
                  id={row.index}
                  type="checkbox"
                  checked={multiRowEditMap.get(`${row.value.location_id}${row.value.product_id}`)}
                  onChange={() => handleCheck(row.original)}
                />
              </div>
            ),
            sortable: false,
            resizable: false,
            width: 25,
          },

          {
            Header: (
              <div>
                {!sortObject.location_description.is_sorted ? (
                  <div className="column-header">Location / Business Unit</div>
                ) : (
                  <div className="row">
                    <div className="col-10 column-header column-header-align">
                      Location / Business Unit
                    </div>
                    <div>
                      {sortObject.location_description.is_sorted === true ? (
                        sortObject.location_description.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'location_description',
            style: { whiteSpace: 'unset' },
            accessor: d => d,
            Cell: row => <div>{row.value.location_description}</div>,
            sortable: true,
          },
          {
            Header: (
              <div>
                {!sortObject.product_description.is_sorted ? (
                  <div className="column-header">Product</div>
                ) : (
                  <div className="row">
                    <div className="col-8 column-header column-header-align">Product</div>
                    <div>
                      {sortObject.product_description.is_sorted === true ? (
                        sortObject.product_description.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
            headerStyle: headerStyleObj,
            style: { whiteSpace: 'unset' },
            id: 'product_description',
            accessor: d => d,
            Cell: row => <div>{row.value.product_description}</div>,
            sortable: true,
          },

          {
            Header: (
              <div>
                {!sortObject.price_rule_description.is_sorted ? (
                  <div className="column-header">Price Rule</div>
                ) : (
                  <div className="row">
                    <div className="col-8 column-header column-header-align">Price Rule</div>
                    <div>
                      {sortObject.price_rule_description.is_sorted === true ? (
                        sortObject.price_rule_description.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'price_rule_description',
            style: { whiteSpace: 'unset' },
            accessor: d => d,
            Cell: row => (
              <div>
                {singleRowEditSet.has(`${row.value.location_id}${row.value.product_id}`) ? (
                  <select
                    className="footer-form-values"
                    name="editPriceType"
                    onChange={e => handleSingleEditPriceRuleChange(e, row)}
                  >
                    <option
                      key={1}
                      value={
                        singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`) !== undefined &&
                        singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceType !==
                          undefined
                          ? singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceType
                          : row.value.price_rule !== null
                          ? row.value.price_rule
                          : ''
                      }
                    >
                      {singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`) !== undefined &&
                      singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceType !== undefined
                        ? priceRuleTypesMap.get(
                            singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceType,
                          )
                        : row.value.price_rule_description !== null
                        ? row.value.price_rule_description
                        : ''}
                    </option>
                    {singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`) !== undefined &&
                    singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceType !== undefined
                      ? priceRuleTypes
                          .filter(
                            priceRule =>
                              priceRule.price_rule !==
                              singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceType,
                          )
                          .map(priceRule => {
                            return (
                              <option key={priceRule.price_rule} value={priceRule.price_rule}>
                                {priceRule.description}
                              </option>
                            );
                          })
                      : priceRuleTypes
                          .filter(
                            priceRule =>
                              priceRule.price_rule !== (row.value.price_rule !== null ? row.value.price_rule : ''),
                          )
                          .map(priceRule => {
                            return (
                              <option key={priceRule.price_rule} value={priceRule.price_rule}>
                                {priceRule.description}
                              </option>
                            );
                          })}
                  </select>
                ) : (
                  <div
                    className={
                      singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`) !== undefined &&
                      singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceType !== undefined
                        ? 'table-column-yellow'
                        : ''
                    }
                  >
                    {singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`) !== undefined &&
                    singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceType !== undefined
                      ? priceRuleTypesMap.get(
                          singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceType,
                        )
                      : row.value.price_rule_description === null
                      ? '-'
                      : row.value.price_rule_description}
                  </div>
                )}
              </div>
            ),
            sortable: true,
          },
          {
            Header: (
              <div>
                {!sortObject.value.is_sorted ? (
                  <div className="column-header">Rule value</div>
                ) : (
                  <div className="row">
                    <div className="col-8 column-header column-header-align">Rule Value</div>
                    <div>
                      {sortObject.value.is_sorted === true ? (
                        sortObject.value.direction === 'ASC' ? (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon className="Icon" name="editClose" icon={faArrowDown} />
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
            headerStyle: headerStyleObj,
            id: 'value',
            style: { whiteSpace: 'unset' },
            accessor: d => d,
            Cell: row => (
              <div>
                {singleRowEditSet.has(`${row.value.location_id}${row.value.product_id}`) ? (
                  <input
                    type="number"
                    name="editPriceValue"
                    onChange={e => handleSingleEditPriceRuleChange(e, row)}
                    value={
                      singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`) !== undefined &&
                      singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceValue !== undefined
                        ? singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceValue
                        : row.value.value !== null
                        ? row.value.value
                        : ''
                    }
                  />
                ) : (
                  <div
                    className={
                      singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`) !== undefined &&
                      singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceValue !== undefined
                        ? 'table-column-yellow'
                        : ''
                    }
                  >
                    {singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`) !== undefined &&
                    singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceValue !== undefined
                      ? singleRowUpdateMap.get(`${row.value.location_id}${row.value.product_id}`).priceValue
                      : row.value.value === null
                      ? '-'
                      : row.value.value}
                  </div>
                )}
              </div>
            ),
            sortable: true,
          },
          {
            Header: <div className="column-header">Actions</div>,
            headerStyle: headerStyleObj,
            id: 'actions',
            accessor: d => d,
            width: 70,
            sortable: false,
            Cell: rowData =>
              actionComponent(openSingleRow, rowData.original, singleRowEditSet, updateSingleRow, deleteSingle),
          },
        ]}
        getTheadThProps={(state, rowInfo, column) => {
          return {
            onClick: () => {
              if (column.sortable) {
                sortClick(column.id);
              }
            },
          };
        }}
        getTdProps={(state, rowInfo) => {
          if (typeof rowInfo !== 'undefined') {
            return {
              style: tableDataStyleObject,
            };
          }
          return {};
        }}
        noDataText={dataAvailable ? 'Loading Data' : 'No Price Rules Found'}
        defaultPageSize={4}
        pageSizeOptions={[4, 10, 20, 25, 50, 100]}
        PaginationComponent={pagination}
        showPagination={data.length > 4}
        className="-striped -highlight"
      />
    </div>
  );
};

export default priceRuleTable;
