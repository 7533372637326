import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Signup';

const ACTIONS = {
  START_TRIAL_SIGNUP_BUTTON_CLICK: 'Start free trial signup button click',
  TOGGLE_MONTHLY_ANNUAL_RADIO: 'Toggle monthly/annual plan radio buttons',
  SEND_VERIFICATION_CODE_BUTTON_CLICK: 'Send verification code button click',
  SUBMIT_REGISTRATION_CODE_BUTTON_CLICK: 'Submit registration code button click',
  BEGIN_TRIAL_BUTTON_CLICK: 'Begin free trial button click',
};

const LABELS = {
  PRODUCT_DESCRIPTION_PAGE: 'Product description page (step 1)',
  CREATE_ACCOUNT_PAGE: 'Create account page (step 2)',
  ENTER_CODE_PAGE: 'Enter verification code page (step 3)',
  PAYMENT_DETAILS_PAGE: 'Payment details page (step 4)',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitStartTrialSignupButtonClick = () => {
  emitEvent({ action: ACTIONS.START_TRIAL_SIGNUP_BUTTON_CLICK, label: LABELS.PRODUCT_DESCRIPTION_PAGE });
};

export const gaEmitToggleMonthlyAnnual = () => {
  emitEvent({ action: ACTIONS.TOGGLE_MONTHLY_ANNUAL_RADIO, label: LABELS.CREATE_ACCOUNT_PAGE });
};

export const gaEmitSendVerificationCodeButtonClick = () => {
  emitEvent({ action: ACTIONS.SEND_VERIFICATION_CODE_BUTTON_CLICK, label: LABELS.CREATE_ACCOUNT_PAGE });
};

export const gaEmitSubmitRegistrationCodeButtonClick = () => {
  emitEvent({ action: ACTIONS.SUBMIT_REGISTRATION_CODE_BUTTON_CLICK, label: LABELS.ENTER_CODE_PAGE });
};

export const gaEmitBeginTrialButtonClick = () => {
  emitEvent({ action: ACTIONS.BEGIN_TRIAL_BUTTON_CLICK, label: LABELS.PAYMENT_DETAILS_PAGE });
};
