import React from 'react';
import { DataSourceType } from './DatasetConfigFormConstants';
import DatasetSampleTable from './DatasetSampleTable';
import { GenericTextInput } from '../../forms/GenericFormComponents';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';
import './columnBasedFieldSelection.css';

const SelectionReview = props => {
  const { dataSourceType, onClickEdit, value } = props;

  const labelText =
    dataSourceType === DataSourceType.FROM_COLUMN ? 'You selected this column:' : 'You entered this formula:';

  return (
    <div style={{ marginLeft: 18, marginBottom: 25 }}>
      <div>{labelText}</div>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div className="ds-config-selection-box">{value}</div>
        <div className="ds-config-edit-btn" tabIndex="0" role="button" onKeyDown={() => null} onClick={onClickEdit}>
          <em>Edit</em>
        </div>
      </div>
    </div>
  );
};

const FormulaEditor = props => {
  const { formula, onSetFormula, onCancel } = props;

  const [formulaText, setFormulaText] = React.useState(formula ?? '');

  const canApply = formulaText !== '';

  return (
    <div className="formula-container">
      <GenericTextInput
        onChange={event => setFormulaText(event.target.value)}
        value={formulaText}
        placeholder="Enter Formula Here"
        spellCheck={false}
      />
      <ProfitRoverSecondaryButton type="button" tabIndex="0" onClick={onCancel}>
        Cancel
      </ProfitRoverSecondaryButton>
      <ProfitRoverPrimaryButton
        type="submit"
        tabIndex="0"
        disabled={!canApply}
        onClick={() => onSetFormula(formulaText)}
      >
        Apply
      </ProfitRoverPrimaryButton>
    </div>
  );
};

const ColumnBasedFieldSelection = props => {
  const {
    dataSamples,
    columnName,
    setColumnName,
    formula,
    setFormula,
    dataSourceType,
    setDataSourceType,
    allowFormula = true,
  } = props;

  const [isEditing, setIsEditing] = React.useState(true);
  const [isCreatingFormula, setIsCreatingFormula] = React.useState(dataSourceType === DataSourceType.FORMULA);

  const reviewValue = dataSourceType === DataSourceType.FORMULA ? formula : columnName;

  const openFormulaEditor = React.useCallback(() => {
    if (allowFormula) {
      setIsCreatingFormula(true);
      setDataSourceType(DataSourceType.FORMULA);
    }
  }, [allowFormula, setDataSourceType]);

  // TODO: Figure out how to create "columns" based on the formula and inject those to the DatasetSampleTable

  return (
    <>
      {isEditing ? (
        // "inline-grid" makes the formula editor as wide as the DatasetSampleTable
        <div style={{ display: 'inline-grid' }}>
          {allowFormula && (
            <>
              {!isCreatingFormula ? (
                <div
                  role="button"
                  tabIndex="0"
                  className="formula-suggestion"
                  onClick={openFormulaEditor}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      openFormulaEditor();
                    }
                  }}
                >
                  <i>Do you need to add a formula to make your data work?</i>
                </div>
              ) : (
                <FormulaEditor
                  formula={formula}
                  onCancel={() => {
                    setIsCreatingFormula(false);
                    setColumnName(null);
                    setDataSourceType(DataSourceType.FROM_COLUMN);
                  }}
                  onSetFormula={formulaText => {
                    setIsEditing(false);
                    setFormula(formulaText);
                    setDataSourceType(DataSourceType.FORMULA);
                  }}
                />
              )}
            </>
          )}
          <DatasetSampleTable
            dataSamples={dataSamples}
            disableColumnSelection={dataSourceType === DataSourceType.FORMULA}
            onSelectColumn={selectedColumn => {
              setColumnName(selectedColumn);
              setDataSourceType(DataSourceType.FROM_COLUMN);
              setIsEditing(false);
            }}
          />
        </div>
      ) : (
        <SelectionReview
          onClickEdit={() => {
            setIsEditing(true);

            if (dataSourceType === DataSourceType.FROM_COLUMN) {
              setColumnName(null);
            }
          }}
          value={reviewValue}
          dataSourceType={dataSourceType}
        />
      )}
    </>
  );
};

export default ColumnBasedFieldSelection;
