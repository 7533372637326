import _ from 'lodash';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { CUSTOMER_CONFIG_QUERY_KEY_BASE } from '../query/config';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const updateCustomerConfigSection = async (section, config) => {
  const url = `${KA_API_URL}/config/${section}`;

  return axios.put(url, config);
};

/**
 * Hooks
 */

export const useUpdateCustomerConfigSection = mutationConfig => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ section, config }) => {
      const response = await updateCustomerConfigSection(section, config);

      return response.data;
    },
    {
      ...mutationConfig,
      onSuccess: async () => {
        await queryClient.invalidateQueries(CUSTOMER_CONFIG_QUERY_KEY_BASE);

        if (_.isFunction(mutationConfig?.onSuccess)) {
          await mutationConfig.onSuccess();
        }
      },
    },
  );
};
