import axios from 'axios';
import { useMutation } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * Requests
 */

export const createDimensions = async dimensions => axios.post(`${KA_API_URL}/products/dimensions`, dimensions);

const deleteDimensions = async dimensionIds =>
  axios.delete(`${KA_API_URL}/products/dimensions`, { data: dimensionIds });

/**
 * Hooks
 */

export const useCreateDimensions = mutationConfig =>
  useMutation(async data => {
    const response = await createDimensions(data);

    return response.data;
  }, mutationConfig);

export const useDeleteDimensions = mutationConfig =>
  useMutation(async ({ dimensionIds }) => {
    const response = await deleteDimensions(dimensionIds);

    return response?.data;
  }, mutationConfig);
