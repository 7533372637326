import React from 'react';
import { Modal } from 'react-bootstrap';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';
import { ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';

export const ValidUnsavedChangesModal = ({ show, onClose, onSave, locationDescription }) => {
  return (
    <Modal show={show} centered>
      <ProfitRoverModalMain>
        You have unsaved hours of operation changes for {locationDescription}. Would you like to save?
      </ProfitRoverModalMain>
      <ProfitRoverModalFooter className="d-flex justify-content-end">
        <ProfitRoverPrimaryButton onClick={onSave}>Save</ProfitRoverPrimaryButton>
        <ProfitRoverSecondaryButton onClick={onClose}>Discard</ProfitRoverSecondaryButton>
      </ProfitRoverModalFooter>
    </Modal>
  );
};

export const IncompleteUnsavedChangesModal = ({ show, onClose, onFinish, locationDescription }) => {
  return (
    <Modal show={show} centered>
      <ProfitRoverModalMain>
        You have incomplete hours of operation changes for {locationDescription}. Would you like to finish?
      </ProfitRoverModalMain>
      <ProfitRoverModalFooter className="d-flex justify-content-end">
        <ProfitRoverPrimaryButton onClick={onFinish}>Finish</ProfitRoverPrimaryButton>
        <ProfitRoverSecondaryButton onClick={onClose}>Discard</ProfitRoverSecondaryButton>
      </ProfitRoverModalFooter>
    </Modal>
  );
};
