/* eslint-disable import/prefer-default-export */
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { fetchStripePublishableKey } from '../../data-access/query/stripe';

let storedStripePromise = null;

export const useStripePromise = () => {
  const [stripePromise, setStripePromise] = React.useState(storedStripePromise);

  React.useEffect(() => {
    const initializeStripe = async () => {
      const response = await fetchStripePublishableKey();
      const publishableKey = response.data;

      const promise = loadStripe(publishableKey);

      setStripePromise(promise);
      storedStripePromise = promise; // Cache for future reuse
    };

    if (stripePromise == null) {
      initializeStripe();
    }
  }, [stripePromise]);

  return stripePromise;
};
