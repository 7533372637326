import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Staffing Tab';

const ACTIONS = {
  LOCATION_DROPDOWN_CLICK: 'Location dropdown click',

  DATE_RANGE_DROPDOWN_CLICK: 'Date range dropdown click',
  METRIC_DROPDOWN_CLICK: 'Metric dropdown click',
  DATE_FORECAST_TABLE_CLICK: 'Date forecast table click',

  DATE_PICKER_CLICK: 'Date picker click',

  HOUR_BREAKDOWN_TOTAL_INCOME_METRIC_CLICK: 'Hour Breakdown Total Revenue metric click',
  HOUR_BREAKDOWN_TOTAL_TIPS_METRIC_CLICK: 'Hour Breakdown Total Tips metric click',
  HOUR_BREAKDOWN_INCOME_PER_EMPLOYEE_METRIC_CLICK: 'Hour Breakdown Total Revenue per Employee metric click',
  HOUR_BREAKDOWN_TIPS_PER_EMPLOYEE_METRIC_CLICK: 'Hour Breakdown Total Tips per Employee metric click',
  HOUR_BREAKDOWN_TOTAL_HEADCOUNT_METRIC_CLICK: 'Hour Breakdown Total Headcount metric click',

  ADD_SHIFT_BUTTON_CLICK: 'Staffing Planner Add Shift button click',
  DELETE_SHIFT_CLICK: 'Staffing Planner Delete Shift click',

  COPY_TO_PLANNER_BUTTON_CLICK: 'RoverRecs Copy to Planner button click',

  STAFFING_MIN_HOURLY_STAFF_DAY_CLICK: 'Min Hourly Staff Day click',
  STAFFING_MIN_HOURLY_STAFF_CLOSE_CLICK: 'Min Hourly Staff Close click',
  STAFFING_MIN_HOURLY_REVENUE_PER_EMPLOYEE_CLICK: 'Min Hourly Revenue per Employee click',
  STAFFING_MAX_HOURLY_REVENUE_PER_EMPLOYEE_CLICK: 'Max Hourly Revenue per Employee click',
  STAFFING_MIN_HOURLY_TIPS_PER_EMPLOYEE_CLICK: 'Min Hourly Tips per Employee click',
  STAFFING_MAX_HOURLY_TIPS_PER_EMPLOYEE_CLICK: 'Max Hourly Tips per Employee click',
  STAFFING_MIN_SHIFT_LENGTH_CLICK: 'Min Shift Length click',
  STAFFING_MAX_SHIFT_LENGTH_CLICK: 'Max Shift Length click',
};

const LABELS = {
  SUMMARY: 'Summary',
  PLANNER: 'Planner',
  SETTINGS: 'Settings',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitLocationDropdownClick = () => {
  emitEvent({ action: ACTIONS.LOCATION_DROPDOWN_CLICK });
};

export const gaEmitDateRangeDropdownClick = () => {
  emitEvent({ action: ACTIONS.DATE_RANGE_DROPDOWN_CLICK, label: LABELS.SUMMARY });
};

export const gaEmitMetricDropdownClick = () => {
  emitEvent({ action: ACTIONS.METRIC_DROPDOWN_CLICK, label: LABELS.SUMMARY });
};

export const gaEmitDateForecastTableClick = () => {
  emitEvent({ action: ACTIONS.DATE_FORECAST_TABLE_CLICK, label: LABELS.SUMMARY });
};

export const gaEmitDatePickerClick = () => {
  emitEvent({ action: ACTIONS.DATE_PICKER_CLICK, label: LABELS.PLANNER });
};

export const gaEmitHourBreakdownMetricClick = metricValue => {
  const metric = metricValue.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase(); // e.g. 'totalTips' -> 'TOTAL_TIPS'
  const action = ACTIONS[`HOUR_BREAKDOWN_${metric}_METRIC_CLICK`];
  emitEvent({ action, label: LABELS.PLANNER });
};

export const gaEmitAddShiftButtonClick = () => {
  emitEvent({ action: ACTIONS.ADD_SHIFT_BUTTON_CLICK, label: LABELS.PLANNER });
};

export const gaEmitDeleteShiftClick = () => {
  emitEvent({ action: ACTIONS.DELETE_SHIFT_CLICK, label: LABELS.PLANNER });
};

export const gaEmitCopyToPlannerButtonClick = () => {
  emitEvent({ action: ACTIONS.COPY_TO_PLANNER_BUTTON_CLICK, label: LABELS.PLANNER });
};

export const gaEmitStaffingSettingClick = settingName => {
  const setting = settingName.toUpperCase();
  const action = ACTIONS[`STAFFING_${setting}_CLICK`];
  emitEvent({ action, label: LABELS.SETTINGS });
};
