import { faCheckCircle, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { REGISTRATION_STEPS, useRegistrationContext } from './registrationContext';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverLogoIcon } from '../generic/ProfitRoverLogos';
import MailToSupportLink from '../generic/MailToSupportLink';
import ProfitRoverSpinner from '../spinner/ProfitRoverSpinner';
import { useSubscriptionTrialLengths } from '../../data-access/query/stripe';
import './product-description.scss';

/**
 * Developer Note: This component may need to say something different based on what
 * kind of pricing scheme is "on offer" according to the server. For now, we will only
 * indicate pricing info for "regular free trial" pricing. In the future, we may require
 * additional logic.
 */
const CostPerLocation = ({ monthlyPrice, coupon, per }) => {
  const couponDiscount = coupon?.percent_off ?? 0;
  const couponDuration = coupon?.duration_in_months ?? 0;

  const pricePerUnitStr = (monthlyPrice / 100).toFixed(2);
  const couponPrice = pricePerUnitStr * (1 - couponDiscount / 100);

  const [dollars = '0'] = pricePerUnitStr.split('.');
  const [couponDollars = '0'] = couponPrice.toFixed(2).split('.');

  return (
    <div className="cost-per-location">
      <div className="row">
        {coupon && (
          <div className="dollars strikethrough-price">
            <span>$</span>
            <span>{dollars ?? '0'}</span>
          </div>
        )}

        <div className="dollars">
          <span>$</span>
          <span>{coupon ? couponDollars : dollars}</span>
        </div>
        <div className="slash">&nbsp;/&nbsp;</div>
        <div className="per">{per} Per Location</div>
      </div>
      {coupon && (
        <div className="coupon">
          {couponDiscount}% Promo Applied for First {couponDuration} Months
        </div>
      )}
    </div>
  );
};

const FIRST_FREE_BUTTON_TEXT = "Let's get started for free";

const PricingInfo = () => {
  const { setStep, annualPlan = {}, pricesFetching, coupon, couponFetching } = useRegistrationContext();
  const isFirstFreePricing = annualPlan?.tiers_mode != null;

  const { data: trialLengths = {} } = useSubscriptionTrialLengths();
  const { trialLength } = trialLengths;

  const goToNextStep = () => setStep(REGISTRATION_STEPS.REQUEST_CODE);

  if (!isFirstFreePricing) {
    const unitPrice = annualPlan?.unit_amount ?? 0;
    const monthlyPrice = unitPrice / 12;

    if (pricesFetching || couponFetching) {
      return (
        <div className="mt-3 d-flex flex-column align-items-center">
          <ProfitRoverSpinner />
        </div>
      );
    }

    return (
      <p className="mt-3 d-flex flex-column align-items-center">
        <div className="pricing-text">Get started with</div>

        <div className="highlight-container">
          <CostPerLocation monthlyPrice={monthlyPrice} coupon={coupon} per="Month" />
        </div>

        <p className="pricing-text">With Annual billing</p>

        <ProfitRoverPrimaryButton className="start-trial-button mt-2" onClick={goToNextStep}>
          Start My {trialLength}-Day Free Trial
          <FontAwesomeIcon icon={faLongArrowAltRight} style={{ fontSize: '30px', marginLeft: '10px' }} />
        </ProfitRoverPrimaryButton>
      </p>
    );
  }

  const [, nonFreeTier = {}] = annualPlan?.tiers ?? [];

  const { unit_amount: unitPrice = 0 } = nonFreeTier;

  const monthlyPrice = unitPrice / 12;

  return (
    <p className="mt-3 d-flex flex-column align-items-center">
      {isFirstFreePricing && (
        <>
          <div className="pricing-text">Get started with</div>

          <div className="highlight-container">1 Free Location</div>

          <div className="pricing-text">Then each additional location at</div>

          <CostPerLocation monthlyPrice={monthlyPrice} coupon={coupon} per="Month" />

          <div className="pricing-text">With Annual Billing</div>
        </>
      )}

      <ProfitRoverPrimaryButton className="start-trial-button mt-2" onClick={goToNextStep}>
        {FIRST_FREE_BUTTON_TEXT}
        <FontAwesomeIcon icon={faLongArrowAltRight} style={{ fontSize: '30px', marginLeft: '10px' }} />
      </ProfitRoverPrimaryButton>
    </p>
  );
};

const LEARN_MORE_URL = process.env.REACT_APP_LEARN_MORE_URL;

const WhatYouAreGetting = () => {
  return (
    <div className="what-you-are-getting">
      <h6>What You&apos;re Getting:</h6>
      <p>Data-Driven Insights to Help Grow Your Profitability</p>
      <ul>
        <li>Staffing Recommendations</li>
        <li>Pricing Recommendations</li>
        <li>Hours of Operation Recommendations</li>
        <li>Detailed Forecasts</li>
      </ul>

      <a href={LEARN_MORE_URL} target="_blank" rel="noopener noreferrer" className="learn-more-link">
        Learn more
      </a>
    </div>
  );
};

const IndustryItem = ({ children }) => {
  return (
    <li className="product-industries-supported">
      <FontAwesomeIcon className="product-industries-supported-checkbox" icon={faCheckCircle} />
      <span>{children}</span>
    </li>
  );
};

const SupportedIndustries = () => {
  return (
    <>
      <p className="product-description-bottom-section-title">Industries Supported:</p>
      <MailToSupportLink
        className="product-description-bottom-section-link"
        subject="Registration Assistance Required"
        body="I require additional assistance with the registration process."
      >
        Don&apos;t see your industry? Let us know!
      </MailToSupportLink>

      <ul style={{ listStyle: 'none', padding: '0px' }}>
        <IndustryItem>Amusement &amp; Recreation</IndustryItem>

        <IndustryItem>Automotive Maintenance Services</IndustryItem>

        <IndustryItem>Museums, Educational &amp; Historical Sites</IndustryItem>

        <IndustryItem>Personal Care &amp; Laundry Services</IndustryItem>

        <IndustryItem>Restaurants, Bars, Breweries &amp; Cafés</IndustryItem>

        <IndustryItem>Retail Stores</IndustryItem>
      </ul>
    </>
  );
};

const ProductDescription = () => {
  return (
    <div className="d-flex justify-content-center py-5">
      <div className="product-description">
        <div className="d-flex justify-content-center pt-2">
          <ProfitRoverLogoIcon className="profitrover-logo" />
        </div>

        <PricingInfo />

        <hr />

        <WhatYouAreGetting />

        <hr />

        <SupportedIndustries />
      </div>
    </div>
  );
};

export default ProductDescription;
