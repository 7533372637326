import classnames from 'classnames';
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './revenue-uplift-select-theme.scss';

const commonProps = {
  classNamePrefix: 'k-theme',
  theme: theme => {
    return {
      ...theme,
      spacing: {
        baseUnit: 0.5,
        controlHeight: 21,
        menuGutter: 6,
      },
      borderRadius: 4,
      colors: {
        ...theme.colors,
      },
    };
  },
};

const RevenueUpliftSelect = props => {
  return <Select {...props} className={classnames('revenue-uplift-select-theme', props.className)} {...commonProps} />;
};

export const CreatableKaizenSelect = props => {
  return (
    <CreatableSelect
      {...props}
      className={classnames('revenue-uplift-select-theme', props.className)}
      {...commonProps}
    />
  );
};

export default RevenueUpliftSelect;
