import React from 'react';
import { ReactComponent as CheckIcon } from '../../../images/check-circle-fill.svg';

// eslint-disable-next-line import/prefer-default-export
export const CheckedBullet = ({ children }) => (
  <li>
    <CheckIcon className="mr-2" style={{ position: 'relative', bottom: 1.5 }} />
    {children}
  </li>
);
