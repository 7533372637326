export class Rule {
  constructor(description, method, id) {
    this.description = description;
    this.method = method;
    this.id = id;
    this.isSatisfied = false;
  }
}

export class PasswordPolicy {
  constructor(rules) {
    // array of rules or empty array if no rules provided
    this.rules = [...(rules || [])];
  }

  addRule(newRule) {
    // don't add the same rule twice
    if (!this.rules.some(rule => rule.id === newRule.id)) {
      this.rules.push(newRule);
    }
  }

  checkPassword(password) {
    this.rules.forEach(rule => {
      rule.isSatisfied = rule.method(password);
    });

    if (this.rules.some(rule => !rule.isSatisfied)) {
      return false;
    }

    return true;
  }

  resetRules() {
    this.rules.forEach(rule => {
      rule.isSatisfied = false;
    });
  }
}
