import React from 'react';
import ColumnBasedFieldSelection from '../../ColumnBasedFieldSelection';
import {
  ActionButton,
  ConfigTypeSubHeader,
  ContentArea,
  PageHeader,
  PromptText,
  RadioButtonRow,
  SectionHeader,
  SmallRadioButton,
  TextInput,
} from '../../commonFormComponents';
import { DataSourceType, unknownDataSourceTypeError } from '../../DatasetConfigFormConstants';
import DatasetFieldBuilder from '../../DatasetFieldBuilder';
import { DATASET_FIELD_DISPLAY } from '../../../../generic/datasetFieldDisplay';

export const DATASET_FIELD_NAME = {
  PRICE_PER_ITEM: 'PPU',
  TOTAL_PRICE: 'TTP',
};
// PPU and TTP have the same field display text
const FIELD_NAME = DATASET_FIELD_DISPLAY[DATASET_FIELD_NAME.PRICE_PER_ITEM];

export default function PricePage(props) {
  const { formPageApi, dsConfigMetaData, label, nextPage } = props;

  const [fieldInData, setFieldInData] = React.useState();
  const [isPricePerItem, setIsPricePerItem] = React.useState();
  const [columnName, setColumnName] = React.useState();
  const [fixedValue, setFixedValue] = React.useState('');
  const [formula, setFormula] = React.useState('');
  const [dataSourceType, setDataSourceType] = React.useState();

  // TODO: Do better validation for a dollar value here...
  const fixedValueIsValid = !Number.isNaN(parseFloat(fixedValue));

  const fixedValueSatisfied = dataSourceType === DataSourceType.FIXED_VALUE && fixedValueIsValid;
  const columnNameSatisfied = dataSourceType === DataSourceType.FROM_COLUMN && columnName != null;
  const formulaSatisfied = dataSourceType === DataSourceType.FORMULA && formula != null && formula !== '';
  const userHasSpecifiedValue = fixedValueSatisfied || columnNameSatisfied || formulaSatisfied;
  const canContinue = fieldInData != null && userHasSpecifiedValue;

  const onClickNext = async () => {
    const datasetFieldBuilder = new DatasetFieldBuilder();

    if (dataSourceType === DataSourceType.FIXED_VALUE) {
      datasetFieldBuilder.setFixedValue(fixedValue).setDatasetFieldName(DATASET_FIELD_NAME.PRICE_PER_ITEM);
    } else if (dataSourceType === DataSourceType.FROM_COLUMN) {
      datasetFieldBuilder
        .setFromColumn(columnName)
        .setDatasetFieldName(isPricePerItem ? DATASET_FIELD_NAME.PRICE_PER_ITEM : DATASET_FIELD_NAME.TOTAL_PRICE);
    } else if (dataSourceType === DataSourceType.FORMULA) {
      datasetFieldBuilder
        .setFormula(formula)
        .setDatasetFieldName(isPricePerItem ? DATASET_FIELD_NAME.PRICE_PER_ITEM : DATASET_FIELD_NAME.TOTAL_PRICE);
    } else {
      throw unknownDataSourceTypeError(dataSourceType);
    }

    await formPageApi.actions.addDatasetFields(datasetFieldBuilder.build());
    formPageApi.actions.navigateToPage(nextPage);
  };

  return (
    <div className="ds-config-container">
      <PageHeader>New Data Configuration</PageHeader>

      <ConfigTypeSubHeader industry={formPageApi.state.industry} dataType={formPageApi.state.dataType} />

      <hr />

      <SectionHeader label={label}>{FIELD_NAME}</SectionHeader>

      <ContentArea>
        <PromptText label="1.">Does this field exist in your data set?</PromptText>
        <RadioButtonRow>
          <SmallRadioButton
            selected={fieldInData === true}
            onClick={() => {
              setFieldInData(true);

              if (fieldInData === false) {
                // Don't set to undefined if the user clicks this button while it's already selected
                setDataSourceType(undefined);
              }
            }}
          >
            I think so!
          </SmallRadioButton>
          <SmallRadioButton
            selected={fieldInData === false}
            onClick={() => {
              setFieldInData(false);
              setDataSourceType(DataSourceType.FIXED_VALUE);
            }}
          >
            No
          </SmallRadioButton>
        </RadioButtonRow>

        {fieldInData != null && (
          <>
            {fieldInData ? (
              <>
                <PromptText label="2.">
                  Does your data show the <strong>Price Per Item</strong> OR <strong>Total Price</strong> for all items
                  combined?
                </PromptText>
                <RadioButtonRow>
                  <SmallRadioButton selected={isPricePerItem === true} onClick={() => setIsPricePerItem(true)}>
                    Price Per Item
                  </SmallRadioButton>
                  <SmallRadioButton selected={isPricePerItem === false} onClick={() => setIsPricePerItem(false)}>
                    Total Price
                  </SmallRadioButton>
                </RadioButtonRow>

                {isPricePerItem != null && (
                  <>
                    <PromptText label="3.">
                      Look through your data and select the column that matches up with <strong>{FIELD_NAME}</strong>.
                    </PromptText>

                    <ColumnBasedFieldSelection
                      dataSamples={dsConfigMetaData.dataSample}
                      columnName={columnName}
                      setColumnName={setColumnName}
                      formula={formula}
                      setFormula={setFormula}
                      dataSourceType={dataSourceType}
                      setDataSourceType={setDataSourceType}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <PromptText label="2.">What is the fixed price for every item sold in your data?</PromptText>

                <TextInput value={fixedValue} onChange={e => setFixedValue(e.target.value)} />
              </>
            )}
          </>
        )}

        {canContinue && (
          <ActionButton onClick={onClickNext} style={{ marginTop: 30 }}>
            Continue
          </ActionButton>
        )}
      </ContentArea>
    </div>
  );
}
