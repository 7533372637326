export const ContactMethod = {
  EMAIL: 'email',
  PHONE: 'phone',
};

export const PreferredTime = {
  NONE: 'none',
  MORNING: 'morning',
  AFTERNOON: 'afternoon',
  EVENING: 'evening',
};

