import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_LOCATIONS_BASE = `${KA_API_URL}/locations`;

/**
 * Requests
 */

const fetchLocationsDetail = async () => axios.get(GET_LOCATIONS_BASE);

export const fetchLocations = async () => {
  const response = await fetchLocationsDetail();
  const locations = response.data ?? [];

  return locations.map(location => {
    let naicsKaCode = 'none';
    if (location.naics_code !== null && location.ka_code !== null) {
      naicsKaCode = location.naics_code.concat(location.ka_code);
    }
    location.naicsKaCode = naicsKaCode;

    return location;
  });
};

export const fetchLocation = async locationId => axios.get(`${KA_API_URL}/locations/${locationId}`);

/**
 * Hooks
 */

export const LOCATIONS_QUERY_KEY = ['locations'];

export const useLocations = queryConfig => useQuery(LOCATIONS_QUERY_KEY, fetchLocations, queryConfig);

export const useLocationNaicsCodes = (locations, naicsCodes) => {
  return React.useMemo(() => {
    const locationNaicsCodes = new Set(locations.map(location => location.naicsKaCode));

    return naicsCodes.filter(code => locationNaicsCodes.has(code.naicsKaCode));
  }, [locations, naicsCodes]);
};
