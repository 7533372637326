import React from 'react';
import classnames from 'classnames';
import styled, { css } from 'styled-components';
import ProfitRoverTooltip from '../generic/ProfitRoverTooltip';

/**
 * Implements an "on hover" effect for the Workflow tile element.
 */
const StyledOutlineDiv = styled.div`
  transform: translate(0%);
  transition: 0.2s ease-out;
  box-shadow: 0px 0px 18px #eee;

  ${props =>
    props.animated &&
    css`
      &:hover {
        transform: translate(0%, 3px) scale(1.025, 1.025);
        transition: 0.05s ease-in-out;
        box-shadow: 0px 4px 12px #444444;
      }

      &:focus {
        transform: translate(0%, 3px) scale(1.025, 1.025);
        transition: 0.05s ease-in-out;
        box-shadow: 0px 4px 12px #444444;
        outline: none;
      }
    `}
`;

const gridCss = `col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 p-3 d-flex justify-content-center justify-content-sm-start`;

const AnimatedTileOutline = ({ children, className, style, onClick, tooltipText }) => {
  const tileIsDisabled = tooltipText != null;

  return (
    <div className={gridCss}>
      <ProfitRoverTooltip
        shouldDisplayTooltip={tileIsDisabled}
        tooltipId="animated-tile"
        placement="bottom"
        tooltipText={tooltipText}
      >
        <StyledOutlineDiv
          animated={!tileIsDisabled}
          role="button"
          className={classnames('welcome-page-animated-outline', className)}
          style={style}
          onClick={onClick}
          onKeyPress={e => {
            if (onClick && e.key === 'Enter') {
              onClick();
            }
          }}
          tabIndex="0"
        >
          {children}
        </StyledOutlineDiv>
      </ProfitRoverTooltip>
    </div>
  );
};

export default AnimatedTileOutline;
