import React from 'react';
import { useResizeDetector } from 'react-resize-detector';

const Measured = ({ children, ...props }) => {
  const { ref, height, width } = useResizeDetector();

  return (
    <div ref={ref} {...props}>
      {children({ height, width })}
    </div>
  );
};

export default Measured;
