/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ProfitRoverCancelButton, ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';

const priceModal = props => {
  const {
    openEditModal,
    multiRowEditMap,
    toggleModal,
    priceRuleTypes,
    multiPriceValue,
    handleMultiEditPriceRuleChange,
    updateMultipleRows,
    error,
    errorMessage,
  } = props;

  let errorDiv = null;
  if (error) {
    errorDiv = (
      <div
        style={{
          fontSize: '12px',
          color: 'red',
          textAlign: 'center',
          padding: '6px',
        }}
      >
        <h5>{errorMessage}</h5>
      </div>
    );
  }

  return (
    <Modal show={openEditModal} className="file-upload-modal">
      <div className="modal-content modal-content-centered">
        <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px' }}>
          Edit {multiRowEditMap.size} Selected Products
        </p>
        <br />
        <div className="row">
          <div className="col-1" />
          <div className="col-4">
            {' '}
            <h6>Price Type: &nbsp;</h6>
          </div>
          <div className="col-5">
            <select name="multiPriceType" onChange={handleMultiEditPriceRuleChange}>
              <option value="" />
              {priceRuleTypes.map(priceRule => {
                return (
                  <option key={priceRule.price_rule} value={priceRule.price_rule}>
                    {priceRule.description}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <br />
        <div className="row">
          <div className="col-1" />
          <div className="col-4">
            <h6>Price Value: &nbsp;</h6>
          </div>
          <div className="col-5">
            <input
              name="multiPriceValue"
              type="number"
              min="0"
              value={multiPriceValue}
              onChange={handleMultiEditPriceRuleChange}
            />
          </div>
        </div>
        <br />
        {errorDiv}
        <div className="price-filters-button-footer">
          <ProfitRoverPrimaryButton onClick={updateMultipleRows}>Ok</ProfitRoverPrimaryButton>
          &nbsp
          <ProfitRoverCancelButton onClick={toggleModal}> Cancel </ProfitRoverCancelButton>
        </div>
      </div>
    </Modal>
  );
};

export default priceModal;
