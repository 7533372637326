import React from 'react';
import { useLocation } from 'react-router-dom';
import { DataSource } from '../dataSources/dataSourceConstants';

/**
 * This module is concerned with interpreting routes' UrlSearchParams to implement UX that involves
 * linking to our application from another data provider's marketplace to initiate fully-fledged
 * data integrations (not just our file-based data import from files generated by Square, Clover, etc)
 */

const SQUARE_APP_MARKETPLACE = 'square_am';

const resolveIntegrationProvider = urlSearchParams => {
  /**
   * Integration providers that automatically include keyword parameters
   * we expect to be unique among these kinds of providers
   */
  if (urlSearchParams.has('merchant_id')) {
    return DataSource.CLOVER;
  }

  /**
   * Integration providers where we manually add query params to an App Store's
   * link to this application to uniquely identify them
   */
  const integrationProvider = urlSearchParams.get('int_source');
  if (integrationProvider === SQUARE_APP_MARKETPLACE) {
    return DataSource.SQUARE;
  }

  return undefined;
};

const extractIntegrationDetails = (integrationProvider, urlSearchParams) => {
  switch (integrationProvider) {
    case DataSource.CLOVER:
      return {
        merchant_id: urlSearchParams.get('merchant_id'),
        auth_code: urlSearchParams.get('code'),
      };
    default:
      return {};
  }
};

const extractUrlIntegrationState = search => {
  if (search === '' || search == null) {
    return undefined;
  }

  const urlSearchParams = new URLSearchParams(search);
  const integrationProvider = resolveIntegrationProvider(urlSearchParams);

  const providerCouldNotBeDetermined = integrationProvider == null;
  if (providerCouldNotBeDetermined) {
    return undefined;
  }

  const integrationDetails = extractIntegrationDetails(integrationProvider, urlSearchParams);

  return { integrationProvider, integrationDetails };
};

// eslint-disable-next-line import/prefer-default-export
export const useUrlIntegrationDetails = () => {
  const location = useLocation();
  const { search } = location;

  const integrationState = React.useMemo(() => {
    // Integration details may be currently present as query params...
    return extractUrlIntegrationState(search);
  }, [search]);

  return { ...integrationState, search };
};
