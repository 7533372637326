import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Notification Center';

const ACTIONS = {
  HEADER_BELL_CLICK: 'Header bell click',
  TOGGLE_SHOW_ONLY_UNREAD: 'Toggle show only unread',
  SWITCH_TO_INFO_TAB_CLICK: 'Switch to info tab click',
  SWITCH_TO_ALERT_TAB_CLICK: 'Switch to alert tab click',
  MARK_ALL_AS_READ_CLICK: 'Mark all as read click',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitHeaderBellClick = () => {
  emitEvent({ action: ACTIONS.HEADER_BELL_CLICK });
};

export const gaEmitToggleShowOnlyUnread = () => {
  emitEvent({ action: ACTIONS.TOGGLE_SHOW_ONLY_UNREAD });
};

export const gaEmitSwitchToInfoTabClick = () => {
  emitEvent({ action: ACTIONS.SWITCH_TO_INFO_TAB_CLICK });
};

export const gaEmitSwitchToAlertTabClick = () => {
  emitEvent({ action: ACTIONS.SWITCH_TO_ALERT_TAB_CLICK });
};

export const gaEmitMarkAllAsReadClick = () => {
  emitEvent({ action: ACTIONS.MARK_ALL_AS_READ_CLICK });
};
