import axios from 'axios';
import { useQuery } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const GET_HOURS_OF_OPERATION_SETTINGS = `${KA_API_URL}/config/hours_of_operation_settings`;

/**
 * Requests
 */

const fetchHoursOfOperationSettings = async () => axios.get(GET_HOURS_OF_OPERATION_SETTINGS);

/**
 * Hooks
 */

const HOURS_OF_OPERATION_SETTINGS_QUERY_KEY_BASE = 'hoursOfOperationSettings';

export const useHoursOfOperationSettings = queryConfig =>
  useQuery(
    [HOURS_OF_OPERATION_SETTINGS_QUERY_KEY_BASE],
    async () => {
      const response = await fetchHoursOfOperationSettings();

      return response?.data ?? [];
    },
    queryConfig,
  );
