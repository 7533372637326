export const PATHS = {
  home: '/welcome',
  personal: '/personal-settings',
  company: '/company-settings',
  locations: '/location-settings',
  manageDimensions: '/dimensions-settings',
  manageDimensionValues: '/dimension-values-settings',
  dimensionValueMapping: '/dimension-values-mappings',
  customEvents: '/custom-events',
  users: '/user-management',
  subscription: '/subscription-management',
  shopifySubscription: '/subscription-management/shopify',
  preferences: '/preferences',
  dataSources: '/data-sources',
};

export const PATH_LABELS = {
  home: 'Home',
  personal: 'Personal',
  company: 'Company',
  locations: 'Locations & Hours',
  manageDimensions: 'Manage Dimensions',
  manageDimensionValues: 'Manage Dimension Values',
  dimensionValueMapping: 'Dimension Value Mapping',
  customEvents: 'Custom Events',
  users: 'Users & Roles',
  subscription: 'Subscription',
  shopifySubscription: 'Subscription',
  preferences: 'Preferences',
  dataSources: 'Data Center',
};
