import _ from 'lodash';
import camelcase from 'camelcase';
import moment from 'moment-timezone';
import React from 'react';
import { useQueryClient } from 'react-query';
import { DEMAND_METRIC, INCOME_METRIC } from './atAGlanceTabConstants';
import DimensionValueSelection from './dimensionValueSelection';
import { convertFrequencyToColumnName } from './priceChangeFrequencyUtil';
import { BUCKETS, BUCKET_TO_FILTER_LABEL, PRICE_CHANGE_BUCKET_ID } from './pricingTabConstants';
import { useSaveWithDebounce, useSaveUponLeave, useSaveOnChange } from './statePersistence';
import {
  DAYS_OF_WEEK,
  DAY_OF_WEEK_DIMENSION_ID,
  LOCATIONS_DIMENSION_ID,
  SEASON_DIMENSION_ID,
  WEEKPART_DIMENSION_ID,
  WEEKS_SINCE_RELEASE_DIMENSION_ID,
} from './revenueUpliftConstants';
import { DomainContext } from './revenueUpliftContexts';
import {
  isLocationActive,
  KaizenProduct,
  KaizenProductStatus,
  SubscriptionType,
} from '../../../generic/subscriptions/SubscriptionConstants';
import { useSubscriptionStatusForProduct } from '../../../routing/subscriptionStatusHooks';
import { fetchProductDimensionUnits } from '../../../util/mapProductDimensions';
import { useUpdateLocation } from '../../../../data-access/mutation/locations';
import { useActivateTrialOrFirstFreeLocationOneTimeOnly } from '../../../../data-access/mutation/subscriptions';
import { getWeatherLocationInfo, useHighAndLowTemps } from '../../../../data-access/query/kdl/weather';
import { LOCATIONS_QUERY_KEY } from '../../../../data-access/query/locations';
import { DATASET_DATE_FORMAT } from '../../../../utils/date-handling';

/**
 * @deprecated - If you need these, refactor this Hook to use React Query
 */
export const useProductDimensionUnitsForWorkflow = workflowId => {
  const [loading, setLoading] = React.useState(true);
  const [productDimensionUnits, setProductDimensionUnits] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetchProductDimensionUnits();

      if (response.data) {
        const unitsForWorkflow = response.data.filter(unit => unit.workflow_id === workflowId);
        setProductDimensionUnits(unitsForWorkflow);
        setLoading(false);
      }
    };

    fetchData();
  }, [workflowId]);

  return { productDimensionUnits, loading };
};

/**
 * Represents the selection state value each dimension value is initialized to when opening the dashboard
 * for the very first time.
 *
 * Value is false because a previously unseen Dimension should have all its values selected (not filtered out),
 * and the initial way we represent that is by having all dimension value selections in the <FiltersMenu>
 * visually unchecked.
 */
const FIRST_LOAD_IS_SELECTED_STATE = false;

const initializeState = ({
  dimensions,
  dimensionValues,
  locations,
  configTimeDimState,
  initialSavedSelectionState,
}) => {
  const shouldRehydrate = initialSavedSelectionState != null && Object.keys(initialSavedSelectionState).length > 0;
  if (shouldRehydrate) {
    // Reinitialize with the saved state (after cleaning out any unknown state)
    const sanitizedSelectionState = {
      [LOCATIONS_DIMENSION_ID]: [],
      [DAY_OF_WEEK_DIMENSION_ID]: [],
      [PRICE_CHANGE_BUCKET_ID]: [],
    };

    const currentDimIds = dimensions.map(dim => dim.product_dimension_id);
    currentDimIds.forEach(dimId => {
      let selections;

      const knownDimValues = dimensionValues.filter(dimValue => dimValue.dimension_id === dimId);

      const previousStateForDimExists = dimId in initialSavedSelectionState;
      if (previousStateForDimExists) {
        // Merge in saved states
        const savedDimState = initialSavedSelectionState[dimId];
        const keyedDimValues = _.keyBy(savedDimState, 'dimensionValueId');

        selections = knownDimValues.map(dimValue => {
          /**
           * Default value of "false" is so that any newly introduced dimension values don't change the
           * dashboard's results from the last saved state
           */
          const isSelected = keyedDimValues[dimValue.id]?.isSelected ?? false;
          return new DimensionValueSelection(dimValue.id, dimValue.value, dimId, isSelected);
        });
      } else {
        /**
         * Create new states for this dimension's values since this dimension is "new" to us now
         *
         * Initial value of isSelected is false (all should be visually unselected since this will have the initial
         * effect of filtering out none of this dimension's values)
         */
        selections = knownDimValues.map(
          dimValue => new DimensionValueSelection(dimValue.id, dimValue.value, dimId, FIRST_LOAD_IS_SELECTED_STATE),
        );
      }

      sanitizedSelectionState[dimId] = selections;
    });

    let keyedExistingLocationState = initialSavedSelectionState?.[LOCATIONS_DIMENSION_ID];
    if (keyedExistingLocationState != null) {
      keyedExistingLocationState = _.keyBy(keyedExistingLocationState, 'dimensionValueId');
    }
    locations.forEach(location => {
      const { location_id: locationId, location_description: label } = location;
      const isSelected = keyedExistingLocationState?.[locationId]?.isSelected ?? false;

      sanitizedSelectionState[LOCATIONS_DIMENSION_ID].push(
        new DimensionValueSelection(locationId, label, LOCATIONS_DIMENSION_ID, isSelected),
      );
    });

    let keyedExistingDayOfWeekState = initialSavedSelectionState?.[DAY_OF_WEEK_DIMENSION_ID];
    if (keyedExistingDayOfWeekState != null) {
      keyedExistingDayOfWeekState = _.keyBy(keyedExistingDayOfWeekState, 'dimensionValueId');
    }
    DAYS_OF_WEEK.forEach(day => {
      const isSelected = keyedExistingDayOfWeekState?.[day]?.isSelected ?? true;

      sanitizedSelectionState[DAY_OF_WEEK_DIMENSION_ID].push(
        new DimensionValueSelection(day, day, DAY_OF_WEEK_DIMENSION_ID, isSelected),
      );
    });

    let keyedExistingPriceChangeState = initialSavedSelectionState?.[PRICE_CHANGE_BUCKET_ID];
    if (keyedExistingPriceChangeState != null) {
      keyedExistingPriceChangeState = _.keyBy(keyedExistingPriceChangeState, 'dimensionValueId');
    }
    BUCKETS.forEach(bucket => {
      const isSelected = keyedExistingPriceChangeState?.[bucket]?.isSelected ?? true;

      sanitizedSelectionState[PRICE_CHANGE_BUCKET_ID].push(
        new DimensionValueSelection(bucket, BUCKET_TO_FILTER_LABEL[bucket], PRICE_CHANGE_BUCKET_ID, isSelected),
      );
    });

    const { shouldIncludeDim } = configTimeDimState;

    // for season
    if (shouldIncludeDim(SEASON_DIMENSION_ID)) {
      sanitizedSelectionState[SEASON_DIMENSION_ID] = [];
      let keyedExistingSeasonState = initialSavedSelectionState?.[SEASON_DIMENSION_ID];
      const seasonList = configTimeDimState[SEASON_DIMENSION_ID].values;
      if (keyedExistingSeasonState != null) {
        keyedExistingSeasonState = _.keyBy(keyedExistingSeasonState, 'dimensionValueId');
      }

      seasonList.forEach(season => {
        const isSelected = keyedExistingSeasonState?.[season]?.isSelected ?? false;

        sanitizedSelectionState[SEASON_DIMENSION_ID].push(
          new DimensionValueSelection(season, season, SEASON_DIMENSION_ID, isSelected),
        );
      });
    }

    // for weekparts
    if (shouldIncludeDim(WEEKPART_DIMENSION_ID)) {
      sanitizedSelectionState[WEEKPART_DIMENSION_ID] = [];
      let keyedExistingWeekPartState = initialSavedSelectionState?.[WEEKPART_DIMENSION_ID];

      const weekPartList = configTimeDimState[WEEKPART_DIMENSION_ID].values;

      if (keyedExistingWeekPartState != null) {
        keyedExistingWeekPartState = _.keyBy(keyedExistingWeekPartState, 'dimensionValueId');
      }

      weekPartList.forEach(weekpart => {
        const isSelected = keyedExistingWeekPartState?.[weekpart]?.isSelected ?? false;

        sanitizedSelectionState[WEEKPART_DIMENSION_ID].push(
          new DimensionValueSelection(weekpart, weekpart, WEEKPART_DIMENSION_ID, isSelected),
        );
      });
    }

    // for weeks since release
    if (shouldIncludeDim(WEEKS_SINCE_RELEASE_DIMENSION_ID)) {
      sanitizedSelectionState[WEEKS_SINCE_RELEASE_DIMENSION_ID] = [];
      let keyedExistingWeeksSinceReleaseState = initialSavedSelectionState?.[WEEKS_SINCE_RELEASE_DIMENSION_ID];

      const weeksSinceReleaseList = configTimeDimState[WEEKS_SINCE_RELEASE_DIMENSION_ID].values;

      if (keyedExistingWeeksSinceReleaseState != null) {
        keyedExistingWeeksSinceReleaseState = _.keyBy(keyedExistingWeeksSinceReleaseState, 'dimensionValueId');
      }

      weeksSinceReleaseList.forEach(weeksSinceReleaseValue => {
        const isSelected = keyedExistingWeeksSinceReleaseState?.[weeksSinceReleaseValue]?.isSelected ?? false;

        sanitizedSelectionState[WEEKS_SINCE_RELEASE_DIMENSION_ID].push(
          new DimensionValueSelection(
            weeksSinceReleaseValue,
            weeksSinceReleaseValue,
            WEEKS_SINCE_RELEASE_DIMENSION_ID,
            isSelected,
          ),
        );
      });
    }

    return sanitizedSelectionState;
  }

  // Initialize all dimension/pseudo-dimension values to being visually "unselected"
  const cleanSelectionState = {};

  cleanSelectionState[LOCATIONS_DIMENSION_ID] = locations.map(location => {
    return new DimensionValueSelection(
      location.location_id,
      location.location_description,
      LOCATIONS_DIMENSION_ID,
      FIRST_LOAD_IS_SELECTED_STATE,
    );
  });

  cleanSelectionState[DAY_OF_WEEK_DIMENSION_ID] = DAYS_OF_WEEK.map(day => {
    return new DimensionValueSelection(day, day, DAY_OF_WEEK_DIMENSION_ID, FIRST_LOAD_IS_SELECTED_STATE);
  });

  cleanSelectionState[PRICE_CHANGE_BUCKET_ID] = BUCKETS.map(bucket => {
    return new DimensionValueSelection(
      bucket,
      BUCKET_TO_FILTER_LABEL[bucket],
      PRICE_CHANGE_BUCKET_ID,
      FIRST_LOAD_IS_SELECTED_STATE,
    );
  });

  const realDimensionIds = dimensions.map(dim => dim.product_dimension_id);
  realDimensionIds.forEach(dimensionId => {
    cleanSelectionState[dimensionId] = dimensionValues
      .filter(dimValue => dimValue.dimension_id === dimensionId)
      .map(dimValue => {
        return new DimensionValueSelection(dimValue.id, dimValue.value, dimensionId, FIRST_LOAD_IS_SELECTED_STATE);
      });
  });

  // weekpart, seasons, weeks since release
  const { shouldIncludeDim } = configTimeDimState;

  if (shouldIncludeDim(WEEKPART_DIMENSION_ID)) {
    const weekPartList = configTimeDimState[WEEKPART_DIMENSION_ID].values;
    cleanSelectionState[WEEKPART_DIMENSION_ID] = weekPartList.map(weekpart => {
      return new DimensionValueSelection(weekpart, weekpart, WEEKPART_DIMENSION_ID, FIRST_LOAD_IS_SELECTED_STATE);
    });
  }

  if (shouldIncludeDim(SEASON_DIMENSION_ID)) {
    const seasonList = configTimeDimState[SEASON_DIMENSION_ID].values;
    cleanSelectionState[SEASON_DIMENSION_ID] = seasonList.map(season => {
      return new DimensionValueSelection(season, season, SEASON_DIMENSION_ID, FIRST_LOAD_IS_SELECTED_STATE);
    });
  }

  if (shouldIncludeDim(WEEKS_SINCE_RELEASE_DIMENSION_ID)) {
    const weeksSinceReleaseList = configTimeDimState[WEEKS_SINCE_RELEASE_DIMENSION_ID].values;
    cleanSelectionState[WEEKS_SINCE_RELEASE_DIMENSION_ID] = weeksSinceReleaseList.map(weeksSinceReleaseValue => {
      return new DimensionValueSelection(
        weeksSinceReleaseValue,
        weeksSinceReleaseValue,
        WEEKS_SINCE_RELEASE_DIMENSION_ID,
        FIRST_LOAD_IS_SELECTED_STATE,
      );
    });
  }

  return cleanSelectionState;
};

const updateSelection = (state, action, isSelected) => {
  const { dimensionId, dimensionValueLabel } = action.payload;

  const originalArray = state[dimensionId];

  const indexToUpdate = originalArray.findIndex(
    dimValueSelection => dimValueSelection.dimensionValue === dimensionValueLabel,
  );

  const updatedArray = [...originalArray]; // Duplicate the original array

  // Update the selection value
  const newDimValueSelection = DimensionValueSelection.from(originalArray[indexToUpdate]);
  newDimValueSelection.setIsSelected(isSelected);

  // Replace it in the new array
  updatedArray.splice(indexToUpdate, 1, newDimValueSelection);

  return { ...state, [dimensionId]: updatedArray };
};

const updateSelections = (state, action, isSelected) => {
  const { dimensionId, dimensionValueLabels } = action.payload;

  const originalArray = state[dimensionId];
  if (originalArray) {
    const updatedArray = [];
    originalArray.forEach(dimValueSelection => {
      let selection = dimValueSelection;

      const selectionShouldBeUpdated = dimensionValueLabels.includes(dimValueSelection.dimensionValue);
      if (selectionShouldBeUpdated) {
        selection = DimensionValueSelection.from(dimValueSelection);
        selection.setIsSelected(isSelected);
      }

      updatedArray.push(selection);
    });

    return { ...state, [dimensionId]: updatedArray };
  }

  // No change if dimension doesn't exist
  return state;
};

const applyIsSelectedToAll = (state, action, isSelected) => {
  const { dimensionId } = action.payload;

  const originalArray = state[dimensionId];
  if (originalArray) {
    // Set all values the same value of "isSelected"
    const updatedArray = originalArray.map(existingSelection => {
      const updatedValue = DimensionValueSelection.from(existingSelection);
      updatedValue.setIsSelected(isSelected);

      return updatedValue;
    });

    return { ...state, [dimensionId]: updatedArray };
  }

  // No change if dimension doesn't exist
  return state;
};

const resetAllValuesToUnselected = state => {
  const newState = {};

  // Set all dimension values (across dimensions) back to being selected
  Object.keys(state).forEach(key => {
    const originalArray = state[key];

    const updatedArray = originalArray.map(existingSelection => {
      const updatedValue = DimensionValueSelection.from(existingSelection);
      updatedValue.setIsSelected(false);

      return updatedValue;
    });

    newState[key] = updatedArray;
  });

  return newState;
};

const SelectionActionTypes = {
  SELECT_VALUE: 'SELECT_VALUE',
  UNSELECT_VALUE: 'UNSELECT_VALUE',
  SELECT_SET_OF_VALUES: 'SELECT_SET_OF_VALUES',
  UNSELECT_SET_OF_VALUES: 'UNSELECT_SET_OF_VALUES',
  SELECT_ALL_VALUES: 'SELECT_ALL_VALUES',
  UNSELECT_ALL_VALUES: 'UNSELECT_ALL_VALUES',
  RESET_ALL_VALUES_TO_UNSELECTED: 'RESET_ALL_VALUES_TO_UNSELECTED',
};

const selectionStateReducer = (state, action) => {
  switch (action.type) {
    case SelectionActionTypes.SELECT_VALUE:
      return updateSelection(state, action, true);
    case SelectionActionTypes.UNSELECT_VALUE:
      return updateSelection(state, action, false);
    case SelectionActionTypes.SELECT_SET_OF_VALUES:
      return updateSelections(state, action, true);
    case SelectionActionTypes.UNSELECT_SET_OF_VALUES:
      return updateSelections(state, action, false);
    case SelectionActionTypes.SELECT_ALL_VALUES:
      return applyIsSelectedToAll(state, action, true);
    case SelectionActionTypes.UNSELECT_ALL_VALUES:
      return applyIsSelectedToAll(state, action, false);
    case SelectionActionTypes.RESET_ALL_VALUES_TO_UNSELECTED:
      return resetAllValuesToUnselected(state);
    default:
      return state;
  }
};

export const useStaffingPlanner = (workflowId, initialState) => {
  const [staffingPlanner, setStaffingPlanner] = React.useState(initialState);

  useSaveOnChange(workflowId, 'staffing_planner', staffingPlanner);

  const addShift = React.useCallback((locationId, date, shift) => {
    setStaffingPlanner(prev => {
      const updated = _.cloneDeep(prev);
      if (!updated[locationId]) {
        updated[locationId] = {};
      }
      if (!updated[locationId][date]) {
        updated[locationId][date] = [];
      }
      updated[locationId][date].push(shift);
      return updated;
    });
  }, []);

  const addShifts = React.useCallback((locationId, date, shifts) => {
    setStaffingPlanner(prev => {
      let updated = _.cloneDeep(prev);
      if (!updated) {
        updated = {};
      }
      if (!updated[locationId]) {
        updated[locationId] = {};
      }
      if (!updated[locationId][date]) {
        updated[locationId][date] = [];
      }
      updated[locationId][date] = updated[locationId][date].concat(shifts);
      return updated;
    });
  }, []);

  const editShift = React.useCallback((locationId, date, shiftIndex, newShift) => {
    setStaffingPlanner(prev => {
      let updated = _.cloneDeep(prev);
      if (!updated) {
        updated = {};
      }
      if (updated[locationId] && updated[locationId][date] && updated[locationId][date][shiftIndex]) {
        updated[locationId][date][shiftIndex] = newShift;
      }
      return updated;
    });
  }, []);

  const deleteShift = React.useCallback((locationId, date, shiftIndex) => {
    setStaffingPlanner(prev => {
      let updated = _.cloneDeep(prev);
      if (!updated) {
        updated = {};
      }
      if (updated[locationId] && updated[locationId][date]) {
        updated[locationId][date].splice(shiftIndex, 1);

        updated[locationId][date] = updated[locationId][date].map((shift, index) => ({
          ...shift,
          shift: index + 1,
        }));
      }
      return updated;
    });
  }, []);

  const deleteDay = React.useCallback((locationId, date) => {
    setStaffingPlanner(prev => {
      let updated = _.cloneDeep(prev);
      if (!updated) {
        updated = {};
      }
      if (updated[locationId] && updated[locationId][date]) {
        delete updated[locationId][date];
        if (Object.keys(updated[locationId]).length === 0) {
          delete updated[locationId];
        }
      }
      return updated;
    });
  }, []);

  return { staffingPlanner, addShift, addShifts, editShift, deleteShift, deleteDay };
};

export const useNewHOO = (workflowId, initialState) => {
  const [newHOO, setNewHOO] = React.useState(initialState);

  useSaveOnChange(workflowId, 'new_hours', newHOO);

  const addNewHours = React.useCallback((locationId, newHours) => {
    setNewHOO(prev => {
      let updated = _.cloneDeep(prev);
      if (!updated) {
        updated = {};
      }

      if (!updated[locationId]) {
        updated[locationId] = [];
      }
      updated[locationId] = updated[locationId].concat(newHours);
      return updated;
    });
  }, []);

  const editNewHours = React.useCallback((locationId, newHour) => {
    setNewHOO(prev => {
      let updated = _.cloneDeep(prev);
      if (!updated) {
        updated = {};
      }

      if (!updated[locationId]) {
        updated[locationId] = [];
      }

      const dayIndex = updated[locationId].findIndex(day => day.day === newHour.day);

      if (dayIndex !== -1) {
        updated[locationId][dayIndex] = { ...updated[locationId][dayIndex], ...newHour };
      } else {
        updated[locationId].push(newHour);
      }

      return updated;
    });
  }, []);

  const deleteNewHours = React.useCallback(locationId => {
    setNewHOO(prev => {
      let updated = _.cloneDeep(prev);
      if (!updated) {
        updated = {};
      }

      if (updated[locationId]) {
        delete updated[locationId];
      }
      return updated;
    });
  }, []);

  return { newHOO, addNewHours, editNewHours, deleteNewHours };
};

/**
 * This reducer keeps track of which dimension values are "selected" in the UI and exposes
 * methods for mutating those values according to the needs of the designed UI elements
 */
export const useDashboardSelectionState = (
  workflowId,
  initialSavedSelectionState,
  dimensions,
  dimensionValues,
  locations,
  configTimeDimState,
) => {
  const [selectionState, dispatch] = React.useReducer(
    selectionStateReducer,
    { dimensions, dimensionValues, locations, configTimeDimState, initialSavedSelectionState },
    initializeState,
  );

  useSaveWithDebounce(workflowId, 'selection', selectionState);
  useSaveUponLeave(workflowId, 'selection', selectionState);

  // Which dimensions have values filtered out, and thus must show an indicator in the FiltersMenu
  const { filteredDimensionsSet, visuallyUnfilteredDimsSet } = React.useMemo(() => {
    const filteredDims = new Set();
    const visuallyUnfilteredDims = new Set();
    Object.keys(selectionState).forEach(dimId => {
      const selectionStateForDim = selectionState[dimId];

      // If nothing is selected, the filters should behave as if all values are selected
      const noValuesSelected = selectionStateForDim.every(({ isSelected }) => !isSelected);

      if (!noValuesSelected) {
        const someValueIsFilteredOut = selectionStateForDim.some(({ isSelected }) => !isSelected);

        if (someValueIsFilteredOut) {
          filteredDims.add(dimId);
        }
      } else {
        visuallyUnfilteredDims.add(dimId);
      }
    });

    return { filteredDimensionsSet: filteredDims, visuallyUnfilteredDimsSet: visuallyUnfilteredDims };
  }, [selectionState]);

  // Keyed by dimId, each value is a Set that contains the values that have been selected for that dimension
  const {
    selectedDatasetDimValuesSets,
    selectedDimValuesByDimensionValueId,
    visuallySelectedDatasetDimValuesSets,
  } = React.useMemo(() => {
    // Represents values that should be included in analysis but may not be visually selected
    const setsByDimId = {};
    const dimValuesByDimAndDimensionValueId = {};
    Object.keys(selectionState).forEach(dimId => {
      /**
       * Serves as a performant way to identify dimensions in this special-case state without having to
       * repeatedly iterate through all dimension values to check if they're all unselected or all selected
       */
      const noFiltersApplied = !filteredDimensionsSet.has(dimId);

      let selectedDatasetDimValues;
      if (noFiltersApplied) {
        // All values should be considered selected
        selectedDatasetDimValues = selectionState[dimId];
      } else {
        // Find only the visually selected values
        selectedDatasetDimValues = selectionState[dimId].filter(({ isSelected }) => isSelected);
      }

      const selectedDatasetDimValueIds = selectedDatasetDimValues.map(({ dimensionValueId }) => dimensionValueId);

      setsByDimId[dimId] = new Set(selectedDatasetDimValueIds);
      dimValuesByDimAndDimensionValueId[dimId] = _.keyBy(selectedDatasetDimValues, 'dimensionValueId');
    });

    // Represents values that are actually visually selected in the <FiltersMenu UI>
    const visuallySelectedSetsByDimId = {};
    Object.keys(selectionState).forEach(dimId => {
      /**
       * Serves as a performant way to identify dimensions in this special-case state without having to
       * repeatedly iterate through all dimension values to check if they're all unselected or all selected
       */
      let selectedDatasetDimValues = [];
      const allVisuallyUnselected = visuallyUnfilteredDimsSet.has(dimId);

      if (!allVisuallyUnselected) {
        // Find only the visually selected values
        selectedDatasetDimValues = selectionState[dimId].filter(({ isSelected }) => isSelected);
      }

      const selectedDatasetDimValueIds = selectedDatasetDimValues.map(({ dimensionValueId }) => dimensionValueId);

      visuallySelectedSetsByDimId[dimId] = new Set(selectedDatasetDimValueIds);
    });

    return {
      selectedDatasetDimValuesSets: setsByDimId,
      selectedDimValuesByDimensionValueId: dimValuesByDimAndDimensionValueId,
      visuallySelectedDatasetDimValuesSets: visuallySelectedSetsByDimId,
    };
  }, [filteredDimensionsSet, selectionState, visuallyUnfilteredDimsSet]);

  const getDimValue = React.useCallback((dimId, dimValueId) => selectedDimValuesByDimensionValueId[dimId][dimValueId], [
    selectedDimValuesByDimensionValueId,
  ]);

  const getDimValueId = (dimId, dimValueId) => {
    const dimValue = getDimValue(dimId, dimValueId);
    return dimValue?.dimensionValueId;
  };
  const getDimValueLabel = React.useCallback(
    (dimId, dimValueId) => {
      const dimValue = getDimValue(dimId, dimValueId);

      return dimValue?.dimensionValue;
    },
    [getDimValue],
  );

  /**
   * Component Methods for updating the state
   */

  const selectDimensionValue = (dimensionId, dimensionValueLabel) => {
    dispatch({ type: SelectionActionTypes.SELECT_VALUE, payload: { dimensionId, dimensionValueLabel } });
  };

  const unselectDimensionValue = (dimensionId, dimensionValueLabel) => {
    dispatch({ type: SelectionActionTypes.UNSELECT_VALUE, payload: { dimensionId, dimensionValueLabel } });
  };

  const selectDimensionValues = (dimensionId, dimensionValueLabels) => {
    dispatch({ type: SelectionActionTypes.SELECT_SET_OF_VALUES, payload: { dimensionId, dimensionValueLabels } });
  };

  const unselectDimensionValues = (dimensionId, dimensionValueLabels) => {
    dispatch({ type: SelectionActionTypes.UNSELECT_SET_OF_VALUES, payload: { dimensionId, dimensionValueLabels } });
  };

  const selectAllValues = dimensionId => {
    dispatch({ type: SelectionActionTypes.SELECT_ALL_VALUES, payload: { dimensionId } });
  };

  const unselectAllValues = dimensionId => {
    dispatch({ type: SelectionActionTypes.UNSELECT_ALL_VALUES, payload: { dimensionId } });
  };

  const resetSelectedState = () => {
    dispatch({ type: SelectionActionTypes.RESET_ALL_VALUES_TO_UNSELECTED });
  };

  return {
    selectDimensionValue,
    unselectDimensionValue,
    selectDimensionValues,
    unselectDimensionValues,
    selectAllValues,
    unselectAllValues,
    resetSelectedState,
    selectionState,
    filteredDimensionsSet,
    selectedDatasetDimValuesSets,
    visuallyUnfilteredDimsSet,
    visuallySelectedDatasetDimValuesSets,
    getDimValueId,
    getDimValueLabel,
  };
};

export const useSelectedLocationKdlId = (locations, selectionState, selectedDatasetDimValuesSets) => {
  const selectedLocationsState = selectionState[LOCATIONS_DIMENSION_ID];
  const selectedLocationsSet = selectedDatasetDimValuesSets[LOCATIONS_DIMENSION_ID];
  const oneLocationIsSelected = selectedLocationsSet.size === 1;

  let selectedLocationKdlWeatherId;
  if (oneLocationIsSelected) {
    const [selectedLocationState] = selectedLocationsState;
    const selectedLocationId = selectedLocationState.dimensionValueId;

    const selectedLocation = locations.find(loc => loc.location_id === selectedLocationId);

    selectedLocationKdlWeatherId = selectedLocation.kdl_weather_location_id;
  }

  return selectedLocationKdlWeatherId;
};

export const useWeatherDataPoints = selectedLocationKdlWeatherId => {
  const weatherData = useHighAndLowTemps(selectedLocationKdlWeatherId);

  const dateTimeParsingFormat = 'YYYY-MM-DD hh:mm';
  let weatherDataPoints = weatherData.data;

  // Format to match the format of the datapoints generated from the Dashboard's dataset
  weatherDataPoints = weatherDataPoints.map(({ highTemp, lowTemp, dateTime }) => {
    return {
      highTemp,
      lowTemp,
      // TODO: Pass a timezone here (from the selected location)
      date: moment(dateTime, dateTimeParsingFormat).format(DATASET_DATE_FORMAT),
    };
  });

  return weatherDataPoints;
};

const prunePath = (priceMap, fullPath) => {
  const steps = [...fullPath];
  let targetKey = steps.pop(); // productId should be first

  do {
    const target = _.get(priceMap, steps, priceMap); // select current level
    delete target[targetKey];

    const noMoreKeysInTarget = Object.keys(target).length === 0;
    if (noMoreKeysInTarget) {
      targetKey = steps.pop(); // go to next available level in obj
    } else {
      break;
    }
  } while (targetKey != null);
};

const createProductIdKey = productId => `productId_${productId}`;

export const ActionTypes = {
  SET_PRICE_POINT: 'SET_PRICE_POINT',
  DELETE_PRICE_POINT: 'DELETE_PRICE_POINT',
  RESET: 'RESET',
};

export const approvedPricesReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_PRICE_POINT: {
      const nextState = { ...state };
      const { price, pricePoints, accessKeys } = payload;

      _.set(nextState.priceMap, accessKeys, { price, pricePointsByDate: _.keyBy(pricePoints, 'tranDate') });
      return nextState;
    }
    case ActionTypes.DELETE_PRICE_POINT: {
      const nextState = { ...state };
      const { accessKeys } = payload;

      prunePath(nextState.priceMap, accessKeys);

      return nextState;
    }
    case ActionTypes.RESET: {
      return { ...state, priceMap: {} };
    }
    default:
      return state;
  }
};

const initializeApprovedPricesState = ({ priceFrequencyDetails }) => {
  return {
    priceChangeFrequencyColumns: priceFrequencyDetails.map(convertFrequencyToColumnName),
    priceMap: {},
  };
};

const getDatasetKeys = (isPriceOptimizationProfit, metric, range) => {
  if (metric === DEMAND_METRIC) {
    return [camelcase(`baseline-${range}-demand`), camelcase(`new-${range}-demand`)];
  }

  if (metric === INCOME_METRIC) {
    return isPriceOptimizationProfit
      ? [camelcase(`baseline-${range}-profit`), camelcase(`new-${range}-profit`)]
      : [camelcase(`baseline-${range}-revenue`), camelcase(`new-${range}-revenue`)];
  }

  return ['', ''];
};

export const useApprovedPricesApi = selectedRange => {
  const { priceFrequencyDetails, isPriceOptimizationProfit } = React.useContext(DomainContext);

  const [approvedPricesState, dispatch] = React.useReducer(
    approvedPricesReducer,
    { priceFrequencyDetails },
    initializeApprovedPricesState,
  );

  const hasOverrides = Object.keys(approvedPricesState.priceMap).length > 0;

  const setApprovedPricePoint = (price, pricePoints, row) => {
    const { priceChangeFrequencyColumns, priceMap } = approvedPricesState;
    const { productId, newPrice } = row;

    const timePeriodKeys = priceChangeFrequencyColumns.map(columnName => row[columnName]);
    const accessKeys = [...timePeriodKeys, createProductIdKey(productId)];

    if (price === newPrice && Object.keys(priceMap).length > 0) {
      dispatch({ type: ActionTypes.DELETE_PRICE_POINT, payload: { accessKeys } });
    } else {
      dispatch({
        type: ActionTypes.SET_PRICE_POINT,
        payload: { price, pricePoints, accessKeys },
      });
    }
  };

  const getApprovedPrice = row => {
    const { priceChangeFrequencyColumns, priceMap } = approvedPricesState;
    const { productId, newPrice } = row;

    const timePeriodKeys = priceChangeFrequencyColumns.map(columnName => row[columnName]);
    const accessKeys = [...timePeriodKeys, createProductIdKey(productId), 'price'];

    const approvedPriceOverride = _.get(priceMap, accessKeys);
    if (approvedPriceOverride == null) {
      return newPrice;
    }

    return approvedPriceOverride;
  };

  const getApprovedPricePoint = row => {
    const { priceChangeFrequencyColumns, priceMap } = approvedPricesState;

    const [, newDemandKey] = getDatasetKeys(isPriceOptimizationProfit, DEMAND_METRIC, selectedRange);
    const [baselineIncomeKey, newIncomeKey] = getDatasetKeys(isPriceOptimizationProfit, INCOME_METRIC, selectedRange);

    const {
      productId,
      newPrice,
      [newDemandKey]: newDemand,
      [newIncomeKey]: newIncomeMetric,
      [baselineIncomeKey]: currentIncomeMetric,
    } = row;
    const uplift = newIncomeMetric - currentIncomeMetric;

    const newPricePoint = { price: newPrice, demand: newDemand, uplift, revenue: newIncomeMetric };

    const timePeriodKeys = priceChangeFrequencyColumns.map(columnName => row[columnName]);
    const accessKeys = [...timePeriodKeys, createProductIdKey(productId), 'pricePointsByDate'];

    const approvedPricePoint = _.get(priceMap, accessKeys);
    if (approvedPricePoint == null) {
      return newPricePoint;
    }

    const { revenue: incomeMetricValue } = approvedPricePoint;
    approvedPricePoint.uplift = incomeMetricValue - currentIncomeMetric;

    return approvedPricePoint;
  };

  const resetOverrides = React.useCallback(() => dispatch({ type: ActionTypes.RESET }), []);

  return {
    actions: { setApprovedPricePoint, resetOverrides },
    selectors: { getApprovedPricePoint, getApprovedPrice },
    hasOverrides,
  };
};

export const useSelectTrialOrFirstFreeLocation = (isFetchingLocations, locations) => {
  const [selectedLocation, setSelectedLocation] = React.useState();
  const [showPickLocationToActivateModal, setShowPickLocationToActivateModal] = React.useState(false);
  const [showAddressDetailsModal, setShowAddressDetailsModal] = React.useState(false);
  const { location_id: locationId } = selectedLocation ?? {};

  const { subscriptionId, kaizenProductStatus, subscriptionType } = useSubscriptionStatusForProduct(KaizenProduct.KP);
  const isTrialing = kaizenProductStatus === KaizenProductStatus.TRIALING;
  const isFirstFree = subscriptionType === SubscriptionType.FIRST_FREE;
  const isTrialingOrFirstFree = isTrialing || isFirstFree;

  const hasAnyLocations = locations.length !== 0;
  const hasAnyActiveLocations = locations.some(location => {
    const locationStatus = location.status_by_subscription_id[subscriptionId]?.status;
    return isLocationActive(locationStatus);
  });

  const queryClient = useQueryClient();

  React.useEffect(() => {
    const canActivateFirstLocation =
      isTrialingOrFirstFree && !isFetchingLocations && hasAnyLocations && !hasAnyActiveLocations;
    const hasSelectedALocation = selectedLocation == null;

    if (hasSelectedALocation && canActivateFirstLocation) {
      setShowPickLocationToActivateModal(true);
    }
  }, [isTrialingOrFirstFree, selectedLocation, isFetchingLocations, hasAnyLocations, hasAnyActiveLocations]);

  const onSelectLocation = () => {
    setShowPickLocationToActivateModal(false);
    setShowAddressDetailsModal(true);
  };

  const onHideAddressDetailsModal = () => {
    setShowAddressDetailsModal(false);
    setShowPickLocationToActivateModal(true);
  };

  const activateTrialOrFirstFreeLocationMutation = useActivateTrialOrFirstFreeLocationOneTimeOnly({
    onSuccess: async () => {
      await queryClient.refetchQueries(LOCATIONS_QUERY_KEY);
      setShowAddressDetailsModal(false);
    },
  });

  const onActivateTrialLocation = async () => {
    await activateTrialOrFirstFreeLocationMutation.mutateAsync({ subscriptionId, locationId });
  };

  const updateLocationDetailsMutation = useUpdateLocation();
  const onUpdateLocation = async locationDetails => {
    await onActivateTrialLocation();

    // get weather information for the updated location
    const { city, state, country_name: countryName, zip } = locationDetails;
    const weatherLocationResponse = await getWeatherLocationInfo(city, state, zip, countryName);

    if (!_.isEmpty(weatherLocationResponse)) {
      const { weatherLocationId, timezone, latitude, longitude } = weatherLocationResponse;

      locationDetails = {
        ...locationDetails,
        kdl_weather_location_id: weatherLocationId,
        timezone,
        latitude,
        longitude,
      };
    }

    await updateLocationDetailsMutation.mutateAsync({ locationId, location: locationDetails });
  };

  const { isLoading: isSubmittingActivateTrialLocation } = activateTrialOrFirstFreeLocationMutation;
  const { isLoading: isSubmittingUpdateLocationDetails } = updateLocationDetailsMutation;
  const isSubmittingTrialLocationSelection = isSubmittingActivateTrialLocation || isSubmittingUpdateLocationDetails;

  return {
    selectedLocation,
    setSelectedLocation,
    onSelectLocation,
    onHideAddressDetailsModal,
    onActivateTrialLocation,
    onUpdateLocation,
    showPickLocationToActivateModal,
    showAddressDetailsModal,
    isSubmittingTrialLocationSelection,
  };
};
