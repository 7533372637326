import React from 'react';
import { PaymentFailedError } from './checkoutErrors';
import { ChargedPaymentSuccess } from './PaymentSuccessDetails';
import { usePayInvoice } from '../../../data-access/mutation/subscriptions';

// eslint-disable-next-line import/prefer-default-export
export const usePayInvoiceState = (invoiceId, subscriptionId) => {
  const [showPaymentFailed, setShowPaymentFailed] = React.useState(false);
  const [submissionError, setSubmissionError] = React.useState();
  const [paymentCompletionDetails, setPaymentCompletionDetails] = React.useState();

  const payInvoiceMutation = usePayInvoice({
    onSuccess: ({ payment_email_address: paymentEmailAddress, paid_invoice: paidInvoice }) =>
      setPaymentCompletionDetails(new ChargedPaymentSuccess({ paidInvoice, paymentEmailAddress })),
    onError: () => {
      setShowPaymentFailed(true);
      setSubmissionError(new PaymentFailedError('Payment attempt unsuccessful', invoiceId));
    },
  });

  const attemptToPayInvoice = async () => payInvoiceMutation.mutateAsync({ subscriptionId, invoiceId });

  return {
    attemptToPayInvoice,
    paymentCompletionDetails,
    payInvoiceMutation,
    setPaymentCompletionDetails,
    showPaymentFailed,
    setShowPaymentFailed,
    submissionError,
  };
};
