import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'Revenue Uplift Dashboard V2';

const ACTIONS = {
  // TABS
  TAB_PRICING_CLICK: 'Pricing tab click',
  TAB_STAFFING_CLICK: 'Staffing tab click',
  TAB_HOURS_CLICK: 'Hours tab click',
  TAB_AT_A_GLANCE_CLICK: 'At a Glance tab click',

  APPROVED_PRICE_DROPDOWN_CLICK: 'Approved price dropdown click',
  UPDATE_WORKFLOW_GEAR_CLICK: 'Update workflow dimension gear click',

  // FILTERS
  OPEN_FILTERS_MENU_BUTTON_CLICK: 'Open filters menu button click',
  DIMENSION_PRODUCT_FILTER_CLICK: 'Product filter applied click',
  DIMENSION_CATEGORY_FILTER_CLICK: 'Category filter applied click',
  DIMENSION_OPTIONS_FILTER_CLICK: 'Options filter applied click',
  DIMENSION_HOUR_FILTER_CLICK: 'Hour filter applied click',
  DIMENSION_DAY_OF_WEEK_FILTER_CLICK: 'Day of Week filter applied click',
  DIMENSION_LOCATION_FILTER_CLICK: 'Location filter applied click',
  DIMENSION_PRICE_CHANGE_BUCKETS_FILTER_CLICK: 'Price Change Buckets filter applied click',
};

const LABELS = {
  DIM_FILTERS: 'Dimension filters',
  PRICE_REC_TABLE: 'Price recommendations table',
  EXPAND_FILTERS_MENU: 'Expand filters menu',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitApprovedPriceDropdownClick = () => {
  emitEvent({ action: ACTIONS.APPROVED_PRICE_DROPDOWN_CLICK, label: LABELS.PRICE_REC_TABLE });
};

export const gaEmitDimensionFilterAppliedClick = dimensionLabel => {
  const specialDimLabel = dimensionLabel.toUpperCase().replaceAll(' ', '_'); // e.g. 'Day of Week' -> 'DAY_OF_WEEK'
  const action = ACTIONS[`DIMENSION_${specialDimLabel}_FILTER_CLICK`];
  emitEvent({ action, label: LABELS.DIM_FILTERS });
};

export const gaEmitOpenFiltersMenuButtonClick = () => {
  emitEvent({ action: ACTIONS.OPEN_FILTERS_MENU_BUTTON_CLICK, label: LABELS.EXPAND_FILTERS_MENU });
};

export const gaEmitUpdateWorkflowGearClick = () => {
  emitEvent({ action: ACTIONS.UPDATE_WORKFLOW_GEAR_CLICK });
};

export const gaEmitTabClick = tabKey => {
  const action = ACTIONS[`TAB_${tabKey}_CLICK`];
  emitEvent({ action });
};
