import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import ReactTable from 'react-table';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ProfitRoverCancelButton, ProfitRoverDestructiveButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverCard, ProfitRoverModalCard, ProfitRoverModalMain } from '../generic/ProfitRoverCard';
import { formatNumber } from '../util/format';
import { FONT_GRAY } from '../../colors';
import { useMessageDatasetRemoveData } from '../../data-access/mutation/datasets';
import { useDatasets } from '../../data-access/query/datasets';
import './manage-datasets-table.scss';

const formatTimestamp = date => {
  return moment(date).format('MMM D, YYYY h:mm A');
};

const ConfirmDeleteDatasetModal = ({ fileName, onHide, onDelete }) => (
  <Modal show onHide={onHide} size="md" centered>
    <ProfitRoverModalCard>
      <ProfitRoverModalMain>
        <div>Permanently Delete Dataset?</div>
        <b className="dataset-modal-sub-header">Deleting: {fileName}</b>
        <p className="dataset-delete-modal-message">
          All insights and values associated with this dataset will be removed from your dashboard.
        </p>
      </ProfitRoverModalMain>
      <Modal.Footer>
        <ProfitRoverCancelButton onClick={onHide}>Cancel</ProfitRoverCancelButton>
        &nbsp;
        <ProfitRoverDestructiveButton onClick={onDelete}>Delete Dataset</ProfitRoverDestructiveButton>
      </Modal.Footer>
    </ProfitRoverModalCard>
  </Modal>
);

const ICON_FONT_SIZE_PX = 18;

const useConfirmDatasetDeletion = datasetConfigId => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [datasetForDelete, setDatasetForDelete] = React.useState();

  const { refetch: refetchDatasets } = useDatasets();
  const messageDatasetRemoveData = useMessageDatasetRemoveData();

  const history = useHistory();

  const onSelectDataset = dataset => {
    setDatasetForDelete(dataset);
    setShowDeleteModal(true);
  };

  const onHide = () => {
    setShowDeleteModal(false);
    setDatasetForDelete(undefined);
  };

  const onDelete = async () => {
    const datasetId = datasetForDelete?.dataset_id;
    if (!datasetId) {
      onHide();
      return;
    }

    await messageDatasetRemoveData.mutateAsync(datasetId);
    const { data: datasets } = await refetchDatasets();

    const noRemainingDatasets = datasets.filter(dataset => dataset.dataset_config_id === datasetConfigId).length === 0;

    if (noRemainingDatasets) {
      history.push('/data-sources');
    } else {
      onHide();
    }
  };

  return { showDeleteModal, onSelectDataset, datasetForDelete, onHide, onDelete };
};

const ManageDatasetsTable = ({ datasetConfigId }) => {
  const { data: datasets = [] } = useDatasets();

  const datasetsForConfig = React.useMemo(
    () => datasets.filter(({ dataset_config_id: configId }) => datasetConfigId === configId),
    [datasetConfigId, datasets],
  );

  const { showDeleteModal, onSelectDataset, datasetForDelete, onHide, onDelete } = useConfirmDatasetDeletion(
    datasetConfigId,
  );

  return (
    <>
      {showDeleteModal && (
        <ConfirmDeleteDatasetModal fileName={datasetForDelete?.file_name} onHide={onHide} onDelete={onDelete} />
      )}

      <ProfitRoverCard className="manage-datasets-table">
        <ReactTable
          className="profitrover-react-table -striped -highlight"
          data={datasetsForConfig}
          columns={[
            {
              id: 'file_name',
              Header: 'Dataset',
              headerClassName: 'left-align',
              className: 'left-align',
              accessor: d => d.file_name,
              sortable: true,
              minWidth: 225,
            },
            {
              id: 'uploaded_gmt_datetime',
              Header: 'Date Uploaded',
              headerClassName: 'left-align',
              className: 'left-align centered',
              accessor: d => formatTimestamp(d.uploaded_gmt_datetime),
              sortable: true,
            },
            {
              id: 'records',
              Header: 'Total Records Processed',
              accessor: ({ record_count: recordCount }) => recordCount,
              Cell: ({ value: recordCount }) => (recordCount != null ? formatNumber(recordCount) : '- - -'),
              headerClassName: 'centered',
              className: 'centered',
              sortable: true,
            },
            {
              id: 'actions',
              Header: 'Action',
              headerClassName: 'centered',
              className: 'centered',
              accessor: ({ is_marked_for_delete: isMarkedForDelete }) => isMarkedForDelete,
              Cell: ({ original, value: isMarkedForDelete }) => {
                const deleteIconProps = isMarkedForDelete
                  ? {
                      icon: faExclamationTriangle,
                      color: '#f6be00',
                      title: 'This dataset has been marked for deletion',
                      onClick: undefined,
                    }
                  : {
                      icon: faTrash,
                      color: FONT_GRAY,
                      title: 'Delete dataset',
                      onClick: () => onSelectDataset(original),
                    };

                return (
                  <>
                    <FontAwesomeIcon
                      icon={faDownload}
                      style={{ fontSize: ICON_FONT_SIZE_PX }}
                      color={FONT_GRAY}
                      title="Download (not implemented)"
                    />
                    <FontAwesomeIcon style={{ fontSize: ICON_FONT_SIZE_PX }} {...deleteIconProps} />
                  </>
                );
              },
            },
          ]}
          minRows={1}
          showPagination={false}
          noDataText="No datasets available"
        />
      </ProfitRoverCard>
    </>
  );
};

export default ManageDatasetsTable;
