import React from 'react';
import Select from 'react-select';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';

const filters = props => {
  const {
    dataTypeValue,
    dataTypeOptions,
    dataConfigurationValue,
    dataConfigurationOptions,
    sourceValue,
    sourceOptions,
    datasetDescriptionValue,
    onDataTypeChange,
    onDataConfigurationChange,
    onSourceChange,
    onButtonClick,
    onDatasetDescriptionChange,
  } = props;
  return (
    <div>
      <div className="row pb-1 filters-main">
        <div className="col-1" />
        <div className="col-2">
          <p className="filter-labels">Data Type</p>
          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={dataTypeValue}
            onChange={onDataTypeChange}
            options={dataTypeOptions}
          />
        </div>
        <div className="col-3">
          <p className="filter-labels">Dataset Configuration</p>
          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={dataConfigurationValue}
            onChange={onDataConfigurationChange}
            options={dataConfigurationOptions}
          />
        </div>
        <div className="col-2">
          <p className="filter-labels">Dataset Description</p>
          <input
            type="text"
            name="datasetDescriptionValue"
            className="fileNameSelect"
            placeholder="Contains..."
            value={datasetDescriptionValue}
            onChange={onDatasetDescriptionChange}
            autoComplete="off"
          />
        </div>
        <div className="col-2">
          <p className="filter-labels">Source</p>
          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={sourceValue}
            onChange={onSourceChange}
            options={sourceOptions}
          />
        </div>

        <div>
          <ProfitRoverPrimaryButton id="datacenter-apply-filters" onClick={onButtonClick}>
            Apply
          </ProfitRoverPrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default filters;
