import axios from 'axios';
import { useMutation } from 'react-query';
import { KA_API_URL } from '../../config/baseUrl';

/**
 * URIs
 */

export const PUT_GUIDED_SETUP_BASE = `${KA_API_URL}/guidedsetup`;

/**
 * Requests
 */

export const putGuidedSetup = async fields => axios.put(PUT_GUIDED_SETUP_BASE, fields);

/**
 * Hooks
 */

export const useUpdateGuidedSetup = mutationConfig =>
  useMutation(async fields => {
    const response = await putGuidedSetup(fields);

    return response?.data ?? {};
  }, mutationConfig);
