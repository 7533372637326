import { TOGGLE_IS_OPEN, HIDE_MENU, ADD_NOTIFICATION, MARK_NOTIFICATIONS_READ, TOGGLE_NOTIFICATION } from './types';

export const toggleIsOpen = () => ({
  type: TOGGLE_IS_OPEN,
});

export const hideMenu = () => ({
  type: HIDE_MENU,
});

export const addNotification = notification => ({
  type: ADD_NOTIFICATION,
  payload: notification,
});

export const markNotificationsReadByType = notificationType => ({
  type: MARK_NOTIFICATIONS_READ,
  payload: notificationType,
});

export const toggleNotificationStatus = notificationId => ({
  type: TOGGLE_NOTIFICATION,
  payload: notificationId,
});
