import _ from 'lodash';
import { TOGGLE_IS_OPEN, HIDE_MENU, ADD_NOTIFICATION, MARK_NOTIFICATIONS_READ, TOGGLE_NOTIFICATION } from './types';
import { LOGOUT } from '../auth/types';
import {
  NOTIFICATION_TYPES,
  NOTIFICATION_TYPE_BY_PAYLOAD_TYPE,
} from '../../components/notificationCenter/NotificationCenterConstants';

const initialState = {
  isOpen: false,
  notifications: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_IS_OPEN: {
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    }
    case HIDE_MENU: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications, payload],
      };
    }
    case MARK_NOTIFICATIONS_READ: {
      const [alertNotifications, infoNotifications] = _.partition(state.notifications, notification => {
        return NOTIFICATION_TYPE_BY_PAYLOAD_TYPE.ALERTS.includes(notification.payload_type);
      });
      let notificationsToMutate;
      let others;
      if (payload === NOTIFICATION_TYPES.ALERTS) {
        notificationsToMutate = alertNotifications;
        others = infoNotifications;
      } else {
        notificationsToMutate = infoNotifications;
        others = alertNotifications;
      }
      notificationsToMutate = notificationsToMutate.map(n => ({ ...n, read: true }));
      return {
        ...state,
        notifications: [...notificationsToMutate, ...others],
      };
    }
    case TOGGLE_NOTIFICATION: {
      const [notification] = state.notifications.splice(
        state.notifications.findIndex(elm => elm.id === payload),
        1,
      );
      notification.read = !notification.read;
      return {
        ...state,
        notifications: [...state.notifications, notification],
      };
    }
    case LOGOUT: {
      return {
        ...state,
        notifications: [],
      };
    }
    default:
      return state;
  }
}
