import _ from 'lodash';
import axios from 'axios';
import store from '../store';
import { logout, refreshJWT } from '../redux/auth/actions';
import { KA_API_ROOT_URL, KA_API_URL, KDL_API_URL } from '../config/baseUrl';
import { IN_DEVELOPMENT_BUILD } from '../config/build';
import queryClient from '../queryClient';

const KDL_URL_REGEX = new RegExp(KDL_API_URL);
const KA_API_URL_REGEX = new RegExp(KA_API_URL);

const isKDLRequest = requestUrl => requestUrl.search(KDL_URL_REGEX) === 0;
const isKaAppApiRequest = requestUrl => requestUrl.search(KA_API_URL_REGEX) === 0;

/**
 * Tells the server that the access token included in the request should be considered "expired", and
 * thus the session corresponding to this token should be invalidated after a successful response is
 * received.
 *
 * A timeout is set to ensure that this request does not last "forever", which would cause the <Logout>
 * page to hang and prevent us from clearing application state like React Query Cache, LocalStorage,
 * Redux, etc.
 */
const invalidateSession = async () => axios.post(`${KA_API_ROOT_URL}/auth/logout`, undefined, { timeout: 3000 });

const LOCAL_STORAGE_KEY_WHITELIST = Object.freeze(['kp_app_version']);

const clearLocalStorage = () => {
  /**
   * These lines have been kept around since they help itemize all the known
   * values we are/have been storing in LocalStorage, officially
   */
  localStorage.removeItem('token');
  localStorage.removeItem('renewal_token');
  localStorage.removeItem('email');
  localStorage.removeItem('name');
  localStorage.removeItem('id');
  localStorage.removeItem('customer_id');
  localStorage.removeItem('customer_name');
  localStorage.removeItem('full_name');
  localStorage.removeItem('fileId');
  localStorage.removeItem('pageSize');
  localStorage.removeItem('kdl_api_key');
  localStorage.removeItem('square_state');
  localStorage.removeItem('square_redirect_behavior');
  localStorage.removeItem('gotab_state');
  localStorage.removeItem('gotab_redirect_behavior');
  localStorage.removeItem('lightspeed_k_state');
  localStorage.removeItem('lightspeed_k_redirect_behavior');
  localStorage.removeItem('_grecaptcha');
  localStorage.removeItem('login_redirect_path');
  localStorage.removeItem('login_redirect_search');
  localStorage.removeItem('has_alert_notifications');

  const removableKeysStillPresent = Object.keys(localStorage).filter(key => !LOCAL_STORAGE_KEY_WHITELIST.includes(key));

  if (removableKeysStillPresent.length > 0 && IN_DEVELOPMENT_BUILD) {
    const warningMessage =
      'Minor developer error: Add key-value pair(s) to be deleted from LocalStorage in clearLocalStorage().';
    // eslint-disable-next-line no-console
    console.warn(warningMessage, '\nKeys still present:', removableKeysStillPresent);
  }
};

export const resetState = () => {
  // Clear React Query client-side cache
  queryClient.clear();

  // Clean up any sensitive information cached in the browser
  clearLocalStorage();

  // Update the Redux state
  store.dispatch(logout());
};

/**
 * Any part of the application that wants to programmatically trigger the official "logout"
 * sequence must call this function.
 */
export const onLogout = async () => {
  /**
   * Prevent the JWT token from being useable after the user has logged out.
   *
   * Note that this must be awaited so that any soon-to-be-launched requests have the opportunity to be
   * issued before we remove the JWT from LocalStorage.
   */
  await invalidateSession().catch(() => _.noop());

  resetState();
};

// Request interceptor
// Used to add header values required for access to either the app or kdl api
axios.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('token');
    const kdlApiKey = localStorage.getItem('kdl_api_key');

    if (token) {
      config.headers['x-auth-token'] = token;
    }

    if (isKDLRequest(config.url)) {
      if (kdlApiKey) {
        config.headers['x-api-key'] = kdlApiKey;
      }
    } else if (kdlApiKey) {
      config.headers['x-kdl-key'] = kdlApiKey;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// Define an array to keep track of the requests that are waiting for a new token
let isRefreshing = false;
let refreshSubscribers = [];

// Response interceptor
axios.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    const requestUrl = originalRequest.url;

    // If the response status is 401 and the request hasn't already been retried
    const isUnauthorizedError = error.response.status === 401;
    const shouldAttemptRefresh = !originalRequest.retry;

    const {
      auth: { isAuthenticated },
    } = store.getState();

    if (isKaAppApiRequest(requestUrl) && isAuthenticated && isUnauthorizedError && shouldAttemptRefresh) {
      if (isRefreshing) {
        // If we're currently refreshing the token, we'll add this additional request to the subscribers Array
        return new Promise(resolve => {
          refreshSubscribers.push(() => {
            resolve(axios(originalRequest));
          });
        });
      }

      // Set the flag to indicate that we're refreshing the token
      isRefreshing = true;

      try {
        // Call the refresh token endpoint to get a new token
        const storedRenewalToken = localStorage.getItem('renewal_token');
        const refreshResponse = await axios.post(`${KA_API_ROOT_URL}/auth/refresh`, {
          renewal_token: storedRenewalToken,
        });

        const { token, renewalToken } = refreshResponse.data;

        // Set the new tokens and mark this request as the one that initiated the refresh
        localStorage.setItem('token', token);
        localStorage.setItem('renewal_token', renewalToken);
        originalRequest.retry = true;

        // Retry the original request that first detected an unauthorized state
        const responseAfterRenewal = await axios(originalRequest);

        // If the 401 error has been resolved...
        store.dispatch(refreshJWT()); // ...ensure any SSE event listeners resubscribe with the new JWT
        refreshSubscribers.forEach(callback => callback()); // ...retry any other queued requests with the updated token

        return Promise.resolve(responseAfterRenewal);
      } catch (err) {
        // If the refresh token is invalid or expired, we'll log the user out
        await onLogout();

        return Promise.reject(error);
      } finally {
        // Once the token is refreshed, we'll clear the subscribers array and reset the flag
        refreshSubscribers = [];
        isRefreshing = false;
      }
    }

    // If the error status is not 401, we'll just return the error
    return Promise.reject(error);
  },
);

export default axios;
