/* eslint-disable no-await-in-loop */
import axios from '../../../../utils/axios-client';
import { KDL_API_URL } from '../../../../config/baseUrl';

export const getHolidayMap = async (locations, endDate) => {
  const holidayMap = new Map();
  const startYear = new Date().getFullYear();
  const endYear = endDate.substring(0, 4);

  for (let i = 0; i < locations.length; ++i) {
    let statePath = '';
    if (locations[i].state_code !== null && locations[i].state_code !== '') {
      statePath = `/${locations[i].state_code}`;
    }
    const holidayResponse = await axios.get(
      `${KDL_API_URL}/holiday/countries/US${statePath}?start_year=${startYear}&end_year=${endYear}`);
    if (holidayResponse.status === 200) {
      holidayMap.set(locations[i].value, new Map(holidayResponse.data.map(x => [x.holiday_date, x.holiday_name])));
    }
  }
  return holidayMap;
};

export const getWeatherMap = async locations => {
  const weatherMap = new Map();

  for (let i = 0; i < locations.length; ++i) {
    const weatherResponse = await axios.get(`${KDL_API_URL}/weather/forecast/daily/${locations[i].weather_id}`);
    if (weatherResponse.status === 200) {
      weatherMap.set(
        locations[i].value,
        new Map(weatherResponse.data.map(x => [x.forecast_datetime.substring(0, 10), x])),
      );
    }
  }
  return weatherMap;
};
