import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const buttonRenderer = params => {
  const editingCells = params.api.getEditingCells();
  // checks if the rowIndex matches in at least one of the editing cells
  const isCurrentRowEditing = editingCells.some(cell => cell.rowIndex === params.node.rowIndex);

  if (isCurrentRowEditing) {
    return (
      <div className="location-edit-cancel-container">
        <button className="location-action-button" type="submit" data-action="update">
          <FontAwesomeIcon
            name="editOpen"
            icon={faCheck}
            style={{ pointerEvents: 'none', width: '30px', color: 'green' }}
          />
        </button>
        <button className="location-action-button" type="submit" data-action="cancel">
          <FontAwesomeIcon
            name="editOpen"
            icon={faTimes}
            style={{ pointerEvents: 'none', width: '30px', color: 'red' }}
          />
        </button>
      </div>
    );
  }
  return (
    <button className="location-action-button location-edit-button" type="submit" data-action="edit">
      <FontAwesomeIcon
        name="editOpen"
        className="Icon"
        icon={faEdit}
        style={{ pointerEvents: 'none', width: '30px' }}
      />
    </button>
  );
};

export default buttonRenderer;
