import React from 'react';
import classnames from 'classnames';

const SettingsInput = ({
  field,
  label,
  value,
  onChange,
  type = 'text',
  autoComplete = 'on',
  required = false,
  className = '',
}) => {
  return (
    <div className="input-container">
      <label htmlFor={field} className="sr-only">
        {label}
      </label>
      <label htmlFor={field} style={{ position: 'relative', display: 'block' }}>
        <span className="input-field-dynamic-label">{label}</span>
        <input
          required={required}
          type={type}
          id={field}
          name={field}
          className={classnames('form-control', className)}
          value={value}
          onChange={onChange}
          autoComplete={autoComplete}
        />
      </label>
    </div>
  );
};

export default SettingsInput;
